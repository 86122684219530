import React from "react";
import StepLayOut from "../stepLayout";
import Step1Left from "./left/Step1Left";
import Step1Right from "./right/Step1Right";

function SurveyStep1() {
  return <StepLayOut left={<Step1Left />} right={<Step1Right />} />;
}

export default SurveyStep1;
