import { makeStyles } from '@material-ui/core';

const esgPlanRightStyles = makeStyles((theme) => ({
  // root: {
  //   '& .MuiAutocomplete-listbox': {
  //     border:'12px solid grey',
  //     fontSize:14,
  //   },
  // },
  unitSelect:{
    height:'52px'
  },

  addKpiBtn: {
    width: '100%',
    marginRight: '35px',
  },
  cardContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    height: '100%',
  },
  homeHeadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emptyContainer: {
    backgroundColor: '#F7F9FC',
    height: 50,
    width: '100%',
    borderRadius: 10,
    margin: '15px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  relavanceBarContainer: {
    width: '100%',
    padding: 3,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#DADBE655',
    },
  },
  topicBarContents: {
    display: 'flex',
    alignItems: 'center',
  },
  relavanceAvatar: {
    width: 35,
    height: 35,
    color: '#000',
    backgroundColor: '#fff',
    border: '1px solid #DADBE6',
    fontSize: 14,
    marginRight: 15,
  },
  input: {
    margin: '10px 0px',
    padding: 5,
    flex: 1,
    border: '1px solid #F1F3F4',
    '& .MuiChip-root': {
      color: '#77787B',
      backgroundColor: '#FFFFFF',
      borderRadius: '5%',
      border: '2px solid #e5e5e5',
    },
  },
  placeholder: {
    '&::placeholder': {
      color: '#3374B9',
    },
  },

  user: {
    '&:hover': {
      borderRadius: '50px 0px 0px 50px',
      backgroundColor: ' #d8e7f7',
    },
  },

  assignMetricContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 5px',
  },
  assignMetricText: {
    fontSize: 13,
    whiteSpace: 'nowrap',
    fontWeight: '600',
  },
  metricDivider: {
    width: 2,
    height: 22,
    backgroundColor: '#9FA1AF',
    borderRadius: 1,
    margin: '0px 10px',
  },
  suggestionChip: {
    justifyContent: 'flex-start',
    color: '#77787B',
    backgroundColor: '#F5F6F8',
    borderRadius: '5%',
    border: '0px',
    margin: '4px 8px 4px 0px',
    '&&:hover': {
      '& $removeChipIcon': {
        display: 'inline',
      },
    },
    '&&:focus': {
      color: '#77787B',
      backgroundColor: '#F5F6F8',
    },
  },
  iconColor: { color: '#000' },
  CardContent: { padding: 10 },
  size: { fontSize: 46 },
  Typography: {
    overflowWrap: 'anywhere',
    fontWeight: '400',
  },
  Typography1: {
    overflowWrap: 'break-word',
    fontWeight: '400',
  },
  grid1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainGrid: { borderBottom: '1px solid #DADBE6' },
  Typography2: {
    paddingBottom: 5,
    borderBottom: '3px solid green',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  div1: { width: 500 },
  Typography3: { padding: '35px' },
  Typography4: { fontWeight: '600' },
  DialogActions: { display: 'flex', justifyContent: 'right' },
  DialogActions1: { display: 'flex', justifyContent: 'center' },
  CustomButton: { padding: '6px 20px' },
  Paper: { margin: '10px 0px' },
  Box: {
    maxHeight: '500px',
    overflow: 'scroll',
    scrollbarWidth: 'none',
  },
  Box1: { display: 'flex', alignItems: 'center' },
  Avatar: { marginLeft: 20 },
  Box2: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  Box3: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 5,
  },
  div2: { width: 850 },
  CustomButton1: {
    color: '#9FA1AF',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    textTransform: 'none',
  },
  CardContent1: { paddingBottom: '40px' },
  grid2: { marginTop: 25 },
  Typography5: { fontWeight: '600', textWrap: 'no-wrap' },
  Typography6: {
    whiteSpace: 'normal',
    fontSize: 11,
  },
  Box4: {
    border: '1px solid #00000050',
    padding: 12,
    borderRadius: 5,
    cursor: 'pointer',
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
  BoxG: { borderBottom: '2px solid gray' },
  Box6: { borderBottom: '2px solid #3374B9' },
  CustomTextfield: { marginLeft: '15px' },
  input1: { display: 'none' },
  pointer: { cursor: 'pointer' },
  Typography7: {
    whiteSpace: 'normal',
    fontSize: 11,
  },
  Typography8: { marginLeft: 20, color: '#3374B9' },
  Typography9: { fontWeight: 600, fontSize: 18 },
  Typography10: { color: '#9FA1AF' },
  Card: { marginTop: 10 },
  cardContent2: { padding: '30px 15px' },
  CardContent3: { padding: '20px 15px' },
  Box5: {
    border: '1px solid #00000050',
    padding: 12,
    borderRadius: 5,
    cursor: 'pointer',
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
  Box7: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default esgPlanRightStyles;
