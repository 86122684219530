import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import General from "../../../images/general.svg";
import Plan from "../../../images/plan.svg";
import Company from "../../../images/company.svg";
import Profile from "../../../images/profile.svg";
import User from "../../../images/user_setting.svg";
import { makeStyles } from "@material-ui/core";

export default function GeneralSettingMenu(props) {
  const useStyles = makeStyles(() => ({
    img: { height: 35, width: 35 },
  }));
  const classes = useStyles();

  const { generalSettings, setSelectedMenu, login } = props;
  const userType = localStorage.getItem("current_organisation_user_type");

  return (
    <div>
      <List>
        <ListItem
          button
          disabled={
            !login.isSubscriptionActive &&
            localStorage.getItem("status") !== "TRIAL"
          }
          style={{
            borderBottom:
              generalSettings.selectedMenu === "profile-details" &&
              "2px solid #3374B9",
          }}
          onClick={() => {
            setSelectedMenu("profile-details");
          }}
        >
          <ListItemIcon>
            <img src={Profile} alt="icon" className={classes.img} />
          </ListItemIcon>
          <ListItemText primary="Profile Details" />
        </ListItem>
        {(userType === "SA" || userType === "SADMIN") && (
          <ListItem
            button
            disabled={
              !login.isSubscriptionActive &&
              localStorage.getItem("status") !== "TRIAL"
            }
            style={{
              borderBottom:
                generalSettings.selectedMenu === "company-details" &&
                "2px solid #3374B9",
            }}
            onClick={() => {
              setSelectedMenu("company-details");
            }}
          >
            <ListItemIcon>
              <img src={Company} alt="icon" className={classes.img} />
            </ListItemIcon>
            <ListItemText primary="Company Details" />
          </ListItem>
        )}
        {userType === "SA" && (
          <ListItem
            button
            style={{
              borderBottom:
                generalSettings.selectedMenu === "plans" && "2px solid #3374B9",
            }}
            onClick={() => {
              setSelectedMenu("plans");
            }}
          >
            <ListItemIcon>
              <img src={Plan} alt="icon" className={classes.img} />
            </ListItemIcon>
            <ListItemText primary="Plans" />
          </ListItem>
        )}
        <ListItem
          button
          disabled={
            !login.isSubscriptionActive &&
            localStorage.getItem("status") === "EXPIRED"
          }
          style={{
            borderBottom:
              generalSettings.selectedMenu === "general-settings" &&
              "2px solid #3374B9",
          }}
          onClick={() => {
            setSelectedMenu("general-settings");
          }}
        >
          <ListItemIcon>
            <img src={General} alt="icon" className={classes.img} />
          </ListItemIcon>
          <ListItemText primary="General Settings" />
        </ListItem>
        {(userType === "SA" || userType === "SADMIN") && (
          <ListItem
            button
            // disabled={!login.isSubscriptionActive}
            style={{
              borderBottom:
                generalSettings.selectedMenu === "user-settings" &&
                "2px solid #3374B9",
            }}
            onClick={() => {
              setSelectedMenu("user-settings");
            }}
          >
            <ListItemIcon>
              <img src={User} alt="icon" className={classes.img} />
            </ListItemIcon>
            <ListItemText primary="User Settings" />
          </ListItem>
        )}
      </List>
    </div>
  );
}
