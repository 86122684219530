// Step 1 Basic Information
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_COMPANY_ADDRESS = "SET_COMPANY_ADDRESS";
export const SET_COMPANY_ADDRESS_ARRAY = "SET_COMPANY_ADDRESS_ARRAY";
export const SET_BUSINESS_TYPE = "SET_BUSINESS_TYPE";
export const SET_START_DATE = "SET_START_DATE";
// export const SET_GEOGRAPHIC = "SET_GEOGRAPHIC";
// export const SET_WEBSITE_ADDRESS = "SET_WEBSITE_ADDRESS";
// export const SET_BUSINESS_CAPACITY_TYPE = "SET_BUSINESS_CAPACITY_TYPE";
// export const SET_BUSINESS_GEOGRAPHIC = "SET_BUSINESS_GEOGRAPGIC";
export const SET_COMPANY_DESCRIPTION = "SET_COMPANY_DESCRIPTION";
export const SET_SECTOR_TYPE = "SET_SECTOR_TYPE";
export const SET_ASSESSMENT_DATE = "SET_ASSESSMENT_DATE";
export const SET_CMP_NAME = "SET_CMP_NAME";
export const SET_CMP_ADDRESS = "SET_CMP_ADDRESS";
export const SET_CMP_PINCODE = "SET_CMP_PINCODE";
export const SET_INDUSTRY_TYPE = "SET_INDUSTRY_TYPE";
export const SET_LOCATION_INDUSTRY_TYPE = "SET_LOCATION_INDUSTRY_TYPE";
export const SET_NUMBER_OF_PERMANENT_EMPLOYEE =
  "SET_NUMBER_OF_PERMANENT_EMPLOYEE";
export const SET_NUMBER_OF_CONTRACT_EMPLOYEE =
  "SET_NUMBER_OF_CONTRACT_EMPLOYEE";
export const SET_CITY = "SET_CITY";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_SECTORS = "SET_SECTORS";
export const SET_INDUSTRIES = "SET_INDUSTRIES";

export const SET_DEFINE_MATERIALITY = "SET_DEFINE_MATERIALITY";
export const SET_DEFINE_OBJECTIVE = "SET_DEFINE_OBJECTIVE";
export const SET_DEFINE_MATERIALITY_ARRAY = "SET_DEFINE_MATERIALITY_ARRAY";
export const SET_DEFINE_OBJECTIVE_ARRAY = "SET_DEFINE_OBJECTIVE_ARRAY";
export const BASIC_COMPANY_FILE = "BASIC_COMPANY_FILE";
export const SET_ADDRESS = "SET_ADDRESS";
export const UNSET_ADDRESS = "UNSET_ADDRESS";
export const SET_UPDATE_DEFINE_MATERIALITY = "SET_UPDATE_DEFINE_MATERIALITY";
export const UNSET_DEFINE_MATERIALITY = "UNSET_DEFINE_MATERIALITY";
export const SET_UPDATE_DEFINE_OBJECTIVE = "SET_UPDATE_DEFINE_OBJECTIVE";
export const UNSET_UPDATE_DEFINE_OBJECTIVE = "UNSET_UPDATE_DEFINE_OBJECTIVE";
export const SET_ASSESSMENT_DATA = "SET_ASSESSMENT_DATA";
export const UPLOAD_LOGO = "UPLOAD_LOGO";
export const SET_FRAMEWORD_TEST = "SET_FRAMEWORD_TEST";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const UNSET_LOCATION = "UNSET_LOCATION";

// Step 2 Materiality Assessment
export const SET_SEARCH_METRIC = "SET_SEARCH_METRIC";
export const SET_FILTER = "SET_FILTER";
export const SET_CHIP = "SET_CHIP";
export const SET_SELECT_BOX = "SET_SELECT_BOX";
export const SET_SHOW_ASSEST = "SET_SHOW_ASSEST";
export const SET_METRICS = "SET_METRICS";
export const SET_SELECTED_METRICS = "SET_SELECTED_METRICS";
export const SET_ASSIGN = "SET_ASSIGN";
export const SET_TOPIC_RELEVANCE = "SET_TOPIC_RELEVANCE";
export const SET_WEIGHTAGE = "SET_WEIGHTAGE";
export const SET_RISKS = "SET_RISKS";
export const SET_OPPORTUNITY = "SET_OPPORTUNITY";
export const SET_FILE = "SET_FILE";
export const SET_MAIN_TOPICS = "SET_MAIN_TOPICS";
export const SET_TOPICS = "SET_TOPICS";
export const SET_TOPICS_ARRAY = "SET_TOPICS_ARRAY";
export const SET_FILES_ARRAY = "SET_FILES_ARRAY";
export const SET_CURRENT_TOPIC_ID = "SET_CURRENT_TOPIC_ID";
export const RESET_TOPICS = "RESET_TOPICS";
export const SET_SEARCH_KEYWORD = "SET_SEARCH_KEYWORD";
export const SET_MEMBERS_ARRAY = "SET_MEMBERS_ARRAY";
export const SET_ADD_EMAIL = "SET_ADD_EMAIL";
export const RESET_ADD_EMAIL = "RESET_ADD_EMAIL";
export const BASIC_FILE = "BASIC_FILE";
export const FRAMEWORK = "FRAMEWORK";
export const VALUE = "VALUE";
export const SET_RELEVANCE = "SET_RELEVANCE";
export const SET_TOPIC_NAME = "SET_TOPIC_NAME";

//ACTIVE STEP
export const SET_ACTIVE_BUTTON = "SET_ACTIVE_BUTTON";

// Activity Log
export const SET_ACTIVITY_LOG = "SET_ACTIVITY_LOG";
export const SET_ASSIGNED_MEMBERS = "SET_ASSIGNED_MEMBERS";

export const RESET_BASIC_INFORMATION = "RESET_BASIC_INFORMATION";
export const RESET_MATERIALITY_ASSESSMENT = "RESET_MATERIALITY_ASSESSMENT";

export const SET_ASSESSMENT_REPORT = "SET_ASSESSMENT_REPORT";
export const SET_SELECTED_TOPICS = "SET_SELECTED_TOPICS";

export const SET_IS_MATERIALITY_COMPLETED = "SET_IS_MATERIALITY_COMPLETED";

export const SET_COMPANY_STATUS = "SET_COMPANY_STATUS";

export const SET_VOTE_DATA = "SET_VOTE_DATA";
export const SET_CURRENT_MAIN_TOPIC_OBJECT = "SET_CURRENT_MAIN_TOPIC_OBJECT";
export const SET_CURRENT_TOPIC_OBJECT = "SET_CURRENT_TOPIC_OBJECT";

export const SET_IS_UPSERT = "SET_IS_UPSERT";
export const SET_APPROVERS_REVIEWERS = "SET_APPROVERS_REVIEWERS";
export const SET_BLOB_URL = "SET_BLOB_URL";
export const SET_ASSESSMENT_SKELETON = "SET_ASSESSMENT_SKELETON";
export const SET_JWT_HAS = "SET_JWT_HAS";
export const SET_SUSTAINABILITY_PDF_PROGRESS =
  "SET_SUSTAINABILITY_PDF_PROGRESS";
export const SET_SUSTAINABILITY_TOC = "SET_SUSTAINABILITY_TOC";
export const SET_SUSTAINABILITY_PROGRESS_COUNTER =
  "SET_SUSTAINABILITY_PROGRESS_COUNTER";
