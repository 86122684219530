import React from "react";
import { connect } from "react-redux";
import { viewMemberAssign } from "../../actions/reportListing/reportListingAction";
import ESGReportListing from "./../../components/screen/esgReportListing/esgReportListing";
import {
  getSelectedTopic,
  getAssignedMaterialityTopics,
} from "../../actions/materialityAction/materialityAction";
import { getAssignedTopics } from "../../actions/esgStrategy/esgStrategyAction";
import { setReportDataTask } from "../../actions/fielddata/fieldDataAction";
import { setAssessmentsPaginationPage } from "../../actions/surveyListing/surveyListingAction";

class ESGReportListingCont extends React.PureComponent {
  componentDidMount(){
    this.props.setPaginationPage(this.props.surveyListing?.paginationPage);
  }
  componentWillUnmount() {
    this.props.setPaginationPage(this.props.surveyListing?.paginationPage);
  }
  render() {
    return <ESGReportListing {...this.props} />;
  }
}

const mapStateToProps = (store) => ({
  login: store.login,
  reportlisting: store.reportlisting,
  esgStrategy: store.esgStrategy,
  surveyListing: store.surveyListing,
});

const mapDispatchToProps = (dispatch) => ({
  viewMemberAssign: (token, assessment_id) => {
    dispatch(viewMemberAssign(token, assessment_id));
  },
  getSelectedTopic: (token, assessment_id, analysis) => {
    dispatch(getSelectedTopic(token, assessment_id, analysis));
  },
  getAssignedTopics: (token, assessment_id) => {
    dispatch(getAssignedTopics(token, assessment_id));
  },
  getAssignedMaterialityTopics: (token, assessment_id) => {
    dispatch(getAssignedMaterialityTopics(token, assessment_id));
  },
  setReportDataTask: (payload) => {
    dispatch(setReportDataTask(payload));
  },
  setPaginationPage: (payload) => {
    dispatch(setAssessmentsPaginationPage(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ESGReportListingCont);
