import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ReportDataLeft from '../widgets/reportdataleft/reportDataLeft';
import ReportDataRight from '../widgets/reportdataright/reportDataRight';

function FieldData(props) {
  const { assessment_id, basicInfo, login, fieldData } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleWidth() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWidth);

    return () => {
      // cancel the subscription
      window.removeEventListener('resize', handleWidth);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 992) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [windowWidth]);
  // memo example
  const ReportDataLefts = React.useMemo(
    () => <ReportDataLeft {...props} />,
    [
      fieldData.topicMetric,
      fieldData.reportTab,
      fieldData.reviewData,
      fieldData.currentReviewMetric,
      basicInfo.templateType,
      assessment_id,
      login,
    ] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Grid
      spacing={2}
      container
      style={{
        display: 'flex',
        // padding: 10,
        height: windowWidth >= 992 ? '76vh' : '90%',
      }}
    >
      <Grid
        item
        xs={11}
        md={6}
        style={{ height: windowWidth >= 992 ? '100%' : '50%' }}
      >
        {/* <ReportDataLeft {...props} /> */}
        {ReportDataLefts}
      </Grid>
      <Grid
        item
        xs={11}
        md={6}
        style={{ height: windowWidth >= 992 ? '100%' : '50%' }}
      >
        <ReportDataRight {...props} />
      </Grid>
    </Grid>
  );
}
export default React.memo(FieldData);
