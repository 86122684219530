import React from "react";
import { connect } from "react-redux";
import Onboarding from "../../components/widgets/onboarding/onboarding";

import {
  getSectorData,
  setSideImage,
  setSideMessage,
  updateOrganisationUnit,
  getTopics,
  viewFrameworks,
  addOrganisationUnit,
  setCompanyName,
  setIsNewCompany,
  setLogin,
  setSignupProcessNo,
} from "../../actions/login/loginActions";
import {
  getAllCountries,
  getSectorsByFramework,
} from "../../actions/basicinfoAction/basicinfoAction";
import { viewFilteredFrameworks } from "../../actions/frameworks/frameworkAction";
// import { getTopics } from "../../actions/materialityAction/materialityAction";

export class BasicDetailsCont extends React.PureComponent {
  render() {
    return <Onboarding {...this.props} />;
  }
}

export const mapStateToProps = (store) => ({
  login: store.login,
  basicInfo: store.basicInfo,
  materialityAssessment: store.materialityAssessment,
});

export const mapDispatchToProps = (dispatch) => ({
  setSideImage: (payload) => {
    dispatch(setSideImage(payload));
  },
  getAllCountries: (token) => {
    dispatch(getAllCountries(token));
  },
  getSectorsByFramework: (token, framework_id) => {
    dispatch(getSectorsByFramework(token, framework_id));
  },
  // getTopics: (token, assessment_id, limit, starting_after, filter,framework_id) => {
  //   dispatch(getTopics(token, assessment_id, limit, starting_after, filter,framework_id));
  // },
  viewFilteredFrameworks: (token, country_id, organisation_id) => {
    dispatch(viewFilteredFrameworks(token, country_id, organisation_id));
  },
  setCompanyName: (payload) => {
    dispatch(setCompanyName(payload));
  },
  setSideMessage: (payload) => {
    dispatch(setSideMessage(payload));
  },
  setIsNewCompany: (payload) => {
    dispatch(setIsNewCompany(payload));
  },
  setLogin: (payload) => {
    dispatch(setLogin(payload));
  },
  setSignupProcessNo: (payload) => {
    dispatch(setSignupProcessNo(payload));
  },
  updateOrganisationUnit: (
    token,
    organisation_id,
    framework_id,
    topics,
    sector
  ) => {
    dispatch(
      updateOrganisationUnit(
        token,
        organisation_id,
        framework_id,
        topics,
        sector
      )
    );
  },
  getSectorData: (token) => {
    dispatch(getSectorData(token));
  },
  getTopics: (token, framework_id, sector) => {
    dispatch(getTopics(token, framework_id, sector));
  },
  viewFrameworks: (token) => {
    dispatch(viewFrameworks(token));
  },
  addOrganisationUnit: (token, company_name, company_type, employee_count) => {
    dispatch(
      addOrganisationUnit(token, company_name, company_type, employee_count)
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetailsCont);
