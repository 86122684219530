export const SET_MASTER_TOPICS = "SET_MASTER_TOPICS";
export const SET_TOPICS_DATA = "SET_TOPICS_DATA";
export const SET_SELECTED_TOPIC_DATA = "SET_SELECTED_TOPIC_DATA";
export const SET_ASSIGNED_MATERIALITY_TOPIC = "SET_ASSIGNED_MATERIALITY_TOPIC";
export const SET_SELECTED_TOPIC_DETAILS = "SET_SELECTED_TOPIC_DETAILS";
export const SET_STAKEHOLDER_DATA = "SET_STAKEHOLDER_DATA";
export const SET_ASSIGNED_STAKEHOLDER_DATA = "SET_ASSIGNED_STAKEHOLDER_DATA";
export const SET_GRAPH_DATA = "SET_GRAPH_DATA";

export const SET_TOPIC_TAB = "SET_TOPIC_TAB";

export const SET_RELEVANCE = "SET_RELEVANCE";
export const SET_WEIGHTAGE = "SET_WEIGHTAGE";
export const SET_FILE = "SET_FILE";
export const SET_RISK = "SET_RISK";
export const SET_OPPORTUNITY = "SET_OPPORTUNITY";
export const SET_MATERIALITY_MAP_TOTAL_PART = "SET_MATERIALITY_MAP_TOTAL_PART";
export const SET_MATERIALITY_MAP_RESPONDED = "SET_MATERIALITY_MAP_RESPONDED";
export const SET_TOTAL_TOPICS_COUNT = "SET_TOTAL_TOPICS_COUNT";
export const SET_TOPIC_FILTER = "SET_TOPIC_FILTER";
export const SET_IS_SURVEY_COMPLETED = "SET_IS_SURVEY_COMPLETED";
export const SET_TOPICS_COMPLETED_UPTO = "SET_TOPICS_COMPLETED_UPTO";
export const SET_OBJECT_DIFFERENCE = "SET_OBJECT_DIFFERENCE";

export const relevance = [
  "Very Important",
  "Somewhat important",
  "Neutral",
  "Not Very Important",
  "Not at all important"
];

export const topicImpactData = [
  "No significant Impact",
  "Minor Impact",
  "Moderate Impact",
  "Major Impact",
  "Catastrophic Impact"
];

export const mainTopicArray = [
  "Social Capita",
  "Human Capital",
  "Environmental",
  "Environment",
  "Business Model & Innovation",
  "Leadership & Governance",
  "General Disclosure"
];
export const stakeHolderCategoryList = [
  "Board of Directors",
  "Executive Team",
  "Employees",
  "Investors & Shareholders",
  "Suppliers & Vendors",
  "Consultants",
  "Customers",
  "Government Agencies"
];

export const topicRelevanceData = [
  {
    id: 5,
    name: "Very Important"
  },
  {
    id: 4,
    name: "Somewhat Important"
  },
  {
    id: 3,
    name: "Neutral"
  },
  {
    id: 2,
    name: "Not Very Important"
  },
  {
    id: 1,
    name: "Not At All Important"
  }
];
