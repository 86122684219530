import React from "react";
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import NavCustomDrawer from "../UI/drawer/drawer";
import CustomAppBar from "../UI/appBar/appBar";
import LoginCon from "../../containers/login/login-cont";
import SurveyListingCont from "../../containers/surveyListing/surveyListingCont";
import ReportListingCont from "../../containers/reportListing/reportListingCont";
import ESGReportListingCont from "../../containers/esrReportListing/esgReportListingCont";
import SingleESGReportCont from "../../containers/singleESGReport/sigleESGReportCont";
import MetricReport from "../screen/singleESGReport/metricReport";
import SustainabilityReportCont from "../../containers/SustainabilityReportMain/SustainabilityReportMainCont";
import "./router.css";
import MobileDrawer from "../UI/menuBar/menuBar";
import OnboaringCon from "../../containers/onboarding/onboarding-cont";
import signupContainer from "../../containers/signup/signupContainer";
import Controller_con from "../../containers/router/controllerCont";
import LoaderCon from "../../containers/loader/loader_cont";
import SnackbarCont from "../../containers/snackbar/snackbarCont";
import EsgReport from "../widgets/esgReport";
import Drawer from "../widgets/policies/drawer";
import FAQ from "../widgets/policies/faq";
import GeneralSettingsCont from "../../containers/generalSettings/generalSettingsCont";
import ResetPasswordCon from "./../../containers/resetPasswordCont/resetPasswordCont";
import OnboardinTour from "../screen/onboardinTour/onboardinTour";
import BasicDetailsCont from "../../containers/basicDetails/basicDetailsCont";
import GetStarted from "../screen/getStarted/getStarted";
import SurveyUp from "../UI/maturityMatrix/SurveyUp";
import Survey from "../UI/maturityMatrix/Survey";
import GetStartedCont from "../../containers/getStarted/getStartedCont";
import TnC from "../widgets/policies/T&C";
import Privacy from "../widgets/policies/privacy";
import DataHubCont from "../../containers/dataHub/dataHubCont";
import DataHubSurveyCont from "../../containers/dataHub/dataHubSurveyCont";
import AppDashBoard from "../UI/dataHubCalculatedCharts/AppDashBoard";
import { DashBoardScreen } from "../screen/dashboard/dashboard";
import DashboardCont from "../../containers/dashboard/dashboardCont";
import StaticdashboardScreen from "../screen/dashboardStatic/StaticdashboardScreen";
import SupplierMain from "../screen/supplier/SupplierMain";
import SupplierSurveyDetails from "../screen/supplier/SupplierSurveyDetails";
import BrmDetails from "../widgets/riskAssessment/brmDetaails";
import VendorAdmin from "../../containers/vendorAdminCont/vendorAdmin";
import SurveyMain from "../widgets/vendorAdmin/selectedSurvey";
import SurveySteps from "../widgets/vendorAdmin/surveySteps";
import SurveyCompletedSteps from "../screen/vendorAdminScreen/SurveyCompletedSteps";
import TaskContainer from "../../containers/taskCont/taskContainer";
import SelectedTaskMain from "../widgets/taskSections/SelectedTaskMain";
import TaskSurveyMain from "../widgets/taskSections/TaskSurveyMain";
import DataHubSelectedSurveyCont from "../../containers/dataHub/dataHubSelectedSurveyCont";
import NoData from "components/widgets/NoData/NoData";
import { Button } from "@material-ui/core";
export const MContext = React.createContext();

class Router extends React.Component {

  autoMateLogout = () => {
    const { setLogOut, set_snack_bar } = this.props;
    localStorage.removeItem("user_token");
    setLogOut();
    set_snack_bar(true, "Please login again");
    return <Redirect to="/login" />;
  };

  state = {
    isOpen: false,
    updatePath: false,
    addNewCompany: false,
  };

  cb = (setIsOpen) => {
    this.setState({ isOpen: setIsOpen });
  };

  componentDidUpdate() {
    if (
      this.props.login.isLoggedIn === true &&
      window.location.pathname === "/add_company" &&
      !this.state.addNewCompany
    ) {
      this.setState({
        addNewCompany: true,
      });
    }
  }

  redirectHandle = (route) => {
    <Redirect to={route} />;
  };

  render() {
    const {
      login,
      logOut,
      snackbar,
      viewAssessments,
      viewAssignedAssessments,
      surveyListing,
      setCurrentOrganzation,
      getNotifications,
      setNotification,
      setTotalNotification,
      setCurrentOrganisationUserType,
      setIsNewCompany,
      setSelectedOraganization,
      setSignupProcessNo,
      setIsSubscriptionActive,
      getOrganizations,
      setOrganizationCount
    } = this.props;
    const userType = localStorage.getItem("current_organisation_user_type");
    if (snackbar.response_received && snackbar.message === "Invalid user") {
      this.autoMateLogout();
    }
    let body;

    if (login.isLoggedIn === false || !localStorage.getItem("user_token")) {
      body = (
        <div>
          {/* {(window.location.pathname !== "/signup" && window.location.pathname !== "/login") && (
            <CustomAppBar
              login={login.isLoggedIn}
              logOut={logOut}
              userName={login.name}
              userEmail={login.email}
              isOpen={this.state.isOpen}
              organisation_details={surveyListing.organisation_details}
              parent_organisation={login.parent_organisation}
              viewAssessments={viewAssessments}
              surveyListing={surveyListing}
              setUpdatePath={() => {
                this.setState({ updatePath: true });
              }}
            />
          )} */}
          <main
            style={{
              marginTop:
                window.location.pathname !== "/" &&
                window.location.pathname !== "/login" &&
                window.location.pathname !== "/reset_password" &&
                window.location.pathname !== "/basicDetails"
                  ? 60
                  : "",
            }}
          >
            <Route path="/" component={Controller_con} />
            <Route exact path="/auto_login" component={LoginCon} />
            <Route exact path="/login" component={LoginCon} />
            <Route exact path="/" component={signupContainer} />
            <Route exact path="/drawer" component={Drawer} />
            <Route
              exact
              path="/faq"
              render={() => <Drawer {...this.props} />}
            />
            <Route
              exact
              path="/privacy_policy"
              render={() => <Drawer {...this.props} />}
            />
            <Route
              exact
              path="/tnc"
              render={() => <Drawer {...this.props} />}
            />
            <Route exact path="/reset_password" component={ResetPasswordCon} />
            <Route exact path="/basicDetails" component={BasicDetailsCont} />
            <Route exact path="/maturity_matrix" component={SurveyUp} />
          </main>
        </div>
      );
    } else if (login.signupProcessNo !== 4) {
      console.log("run 2");

      body = (
        <div>
          <main>
            <Route exact path="/login" component={LoginCon} />
            <Route exact path="/auto_login" component={LoginCon} />
            <Route exact path="/basicDetails" component={BasicDetailsCont} />
            <Route path="/" component={Controller_con} />
            <Route exact path="/" component={signupContainer} />
            <Route exact path="/drawer" component={Drawer} />
            <Route
              exact
              path="/privacy_policy"
              render={() => <Drawer {...this.props} />}
            />
            <Route
              exact
              path="/faq"
              render={() => <Drawer {...this.props} />}
            />
            <Route
              exact
              path="/tnc"
              render={() => <Drawer {...this.props} />}
            />
            <Route exact path="/onboarding_tour" component={OnboardinTour} />
            {/* <Route exact path="/get_started" component={GetStarted} /> */}
            <Route exact path="/maturity_matrix" component={SurveyUp} />
          </main>
        </div>
      );
    } else if (login.signupProcessNo === 4 && login.noOfOrganizations === 0) {

      body = (
        <div>
          <main>
            <Route path="/" component={Controller_con} />

            {/* <Route exact path="/login" component={LoginCon} /> */}
           
            <Route exact path="/reset_password" component={ResetPasswordCon} />
            <Route exact path="/basicDetails" component={BasicDetailsCont} />
            <Route exact path="/maturity_matrix" component={SurveyUp} />

            <Route
              exact
              path="/no_company"
              component={() => (
                <div style={{ height: "90vh" }}>
                  <NoData message={"No Company Found"}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 4,
                        marginTop: "20px",
                      }}
                    >
                      <Link to="/login">
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            logOut(login?.token);
                            // this.redirectHandle("/login");
                          }}
                        >
                          Log Out
                        </Button>
                      </Link>

                      <Link to="/basicDetails">
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setIsNewCompany(true);
                            setSignupProcessNo(2);
                            setOrganizationCount(1)
                            this.redirectHandle("/basicDetails");
                          }}
                        >
                          Add New Company
                        </Button>
                      </Link>
                    </div>
                  </NoData>
                </div>
              )}
            />

            {login.token && (
              <Route render={() => <Redirect to="/no_company" />} />
            )}
          </main>
        </div>
      );
    } else if (login.signupProcessNo === 4 && login.noOfOrganizations > 0) {

      body = (
        <Switch>
          <React.Fragment>
            <div className="div-post-login">
              <NavCustomDrawer
                isOpen={this.state.isOpen}
                setIsOpen={this.cb}
                logOut={logOut}
                token={login.token}
                user_type={login.user_type}
                login={login}
                redirect={surveyListing.redirect_value}
                setRedirectValue={this.props.setRedirectValue}
                current_organisation={login.current_organisation}
              />
              <CustomAppBar
                logOut={logOut}
                token={login.token}
                userName={login.name}
                userEmail={login.email}
                isOpen={this.state.isOpen}
                login={login.isLoggedIn}
                organisation_details={surveyListing.organisation_details}
                parent_organisation={login.parent_organisation}
                viewAssessments={viewAssessments}
                surveyListing={surveyListing}
                viewAssignedAssessments={viewAssignedAssessments}
                user_type={login.user_type}
                setCurrentOrganzation={setCurrentOrganzation}
                loginDetails={login}
                getNotifications={getNotifications}
                setNotification={setNotification}
                setTotalNotification={setTotalNotification}
                setCurrentOrganisationUserType={setCurrentOrganisationUserType}
                setIsNewCompany={setIsNewCompany}
                setSelectedOraganization={setSelectedOraganization}
                setSignupProcessNo={setSignupProcessNo}
                setIsSubscriptionActive={setIsSubscriptionActive}
                getOrganizations={getOrganizations}
              />
              <MobileDrawer
                logOut={logOut}
                token={login.token}
                user_type={login.user_type}
                current_organisation={login.current_organisation}
              />
              <Route path="/" component={Controller_con} />
              <Route exact path="/onboarding_tour" component={OnboardinTour} />
              <Route
                exact
                path="/survey_listing"
                component={SurveyListingCont}
              />
              <Route exact path="/maturity_matrix" component={SurveyUp} />
              <Route
                exact
                path="/report_listing"
                component={ReportListingCont}
              />
              <Route
                exact
                path="/faq"
                render={() => <Drawer {...this.props} />}
              />
              <Route
                exact
                path="/tnc"
                render={() => <Drawer {...this.props} />}
              />
              <Route
                exact
                path="/privacy_policy"
                render={() => <Drawer {...this.props} />}
              />
              <Route exact path="/get_started" component={GetStartedCont} />

              <Route
                exact
                path="/report_listing/esg_report"
                component={ESGReportListingCont}
              />
              <Route
                exact
                path="/report_listing/esg_report/single_report"
                component={SingleESGReportCont}
              />
              {/* <Route
              exact
              path="/dashboard_static/single_report"
              component={SingleESGReportCont}
            /> */}
              <Route
                exact
                path="/report_listing/esg_report/metric_report"
                component={MetricReport}
              />
              <Route
                exact
                path="/sustainability_report"
                component={SustainabilityReportCont}
              />
              <Route exact path="/dashboard" component={DashboardCont} />
              {/* <Route exact path="/dashboard_static"  component = {StaticdashboardScreen}  /> */}
              <Route
                exact
                path="/dashboard_static"
                render={(props) => <StaticdashboardScreen {...props} />}
              />

              <Switch>
                <Route
                  exact
                  path="/data_hub/:page?"
                  render={(props) => <DataHubCont {...props} />}
                />

                <Route
                  exact
                  path="/data_hub/survey/selected_survey"
                  render={(props) => <DataHubSelectedSurveyCont {...props} />}
                />

                <Route
                  exact
                  path="/data_hub/sheets_import/:page?"
                  render={(props) => <DataHubSurveyCont {...props} />}
                />
              </Switch>

              <Route
                exact
                path="/supplier"
                render={(props) => <SupplierMain />}
              />

              <Route
                exact
                path="/supplier/supplier_details"
                render={(props) => <BrmDetails />}
              />

              <Route
                exact
                path="/supplier/survey_details"
                render={(props) => <SupplierSurveyDetails />}
              />

              <Route
                exact
                path="/supplier_admin"
                render={(props) => <VendorAdmin />}
              />

              <Route
                exact
                path="/supplier_admin/:survey"
                render={() => <SurveyMain />}
              />
              <Route
                exact
                path="/supplier_admin/:survey/:assigned"
                render={() => <SurveySteps />}
              />
              <Route
                exact
                path="/supplier_admin/:survey/:assigned/completed"
                render={() => <SurveyCompletedSteps />}
              />
              <Route exact path="/supplier_task" component={TaskContainer} />
              <Route
                exact
                path="/supplier_task/:selected"
                render={() => <SelectedTaskMain />}
              />
              <Route
                exact
                path="/supplier_task/:selected/:survey"
                render={() => <TaskSurveyMain />}
              />

              <Route
                exact
                path="/general_settings"
                component={GeneralSettingsCont}
              />
              <Route exact path="/basicDetails" component={BasicDetailsCont} />
              <Route exact path="/esg_report" component={EsgReport} />

              {!login.isSubscriptionActive &&
                localStorage.getItem("location") === "/general_settings" && (
                  <Route render={() => <Redirect to="/general_settings" />} />
                )}
              {userType === "A" &&
                localStorage.getItem("location") !== "/general_settings" && (
                  <Route render={() => <Redirect to="/report_listing" />} />
                )}
              {userType === "SA" &&
                localStorage.getItem("location") !== "/general_settings" && (
                  <Route render={() => <Redirect to="/survey_listing" />} />
                )}
              {window.location.pathname === "/privacy_policy" && (
                <Route render={() => <Redirect to="/privacy_policy" />} />
              )}
              {window.location.pathname === "/tnc" && (
                <Route render={() => <Redirect to="/tnc" />} />
              )}
              {window.location.pathname === "/faq" && (
                <Route render={() => <Redirect to="/faq" />} />
              )}
              {/* <Route exact path="/drawer" component={Drawer} /> */}
              {/* <Route exact path="/tnc" component={FAQ} /> */}
            </div>
          </React.Fragment>
        </Switch>
      );
    }

    // Add company

    return (
      <BrowserRouter>
        <SnackbarCont />
        <LoaderCon />
        {body}
      </BrowserRouter>
    );
  }
}
export default Router;
