import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  publishDataStream,
  checkPubishDataExist,
  deleteUnPublishedDatastream,
  unPublishedDatastream
} from "../../../actions/datahubActions/datahubActions";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";

import moment from "moment";

import CustomButton from "../../UI/button/button";
import CustomChip from "../../UI/chip/chip";
import { Avatar, Box, Icon } from "@material-ui/core";
import { Link } from "react-router-dom";
import SideIndication from "../../../images/SideIndication.png";

import PublishErrorDialog from "./PublishErrorDialog";
import MoreActionDrawer from "./MoreAcitonDrawer";

function createData(name, calories, fat, carbs, protein, status, action) {
  return { name, calories, fat, carbs, protein, status, action };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3, 54, 2),
  createData("Donut", 452, 25.0, 51, 4.9, 54, 99),

  createData("Oreo", 437, 18.0, 63, 4.0, 62, 87),
];

function descendingComparator(a, b, orderBy) {
  const handleOrderless =
    orderBy === "data_duration"
      ? b[orderBy][0].startDate < a[orderBy][0].startDate
      : b[orderBy] < a[orderBy];
  const handleOrdergreater =
    orderBy === "data_duration"
      ? b[orderBy][0].startDate > a[orderBy][0].startDate
      : b[orderBy] > a[orderBy];
  if (handleOrderless) {
    return -1;
  }
  if (handleOrdergreater) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "data_stream_name",
    numeric: false,
    disablePadding: true,
    label: "Spreadsheet Name",
  },
  {
    id: "data_duration",
    numeric: false,
    disablePadding: false,
    label: "Duration of Data",
  },
  {
    id: "uploaded_by",
    numeric: false,
    disablePadding: false,
    label: "Uploaded by",
  },
  {
    id: "carbs",
    numeric: false,
    disablePadding: false,
    label: "Date of uploading",
  },
  {
    id: "functional_tag",
    numeric: false,
    disablePadding: false,
    label: "Tags",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Upload status",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#EBF1F8" }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function SheetTable({
  datahubFiles,
  getImportFileData,
  token,
  getMatatikaJobStatus,
  restartMatatikaJob,
  currentOrganisation,
}) {
  const dispatch = useDispatch();
  const { hasPublisError, overlappingFiles, publishFileStatus } = useSelector(
    (state) => state.dataHub
  );
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [hasDeleted, setIsDeleted] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [rowId, setRowId] = React.useState("");
  const [isPublished, setIsPublished] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleDelete = () => {
    if (isPublished) {
      dispatch(unPublishedDatastream(token, rowId));
    } else {
      dispatch(deleteUnPublishedDatastream(token, rowId));
    }
  
    setFileName("");
    setRowId("");
    setIsPublished(false)
    setDrawerOpen((preValue) => !preValue);
  };

  const handleDrawer = (file_name, row_id, is_published) => {
    setFileName(typeof file_name === "object" ? "" : file_name);
    setRowId(row_id);
    setIsPublished(is_published)
    setDrawerOpen((preValue) => !preValue);
  };

  const handleDeleteDialog = () => {
    setIsDeleted((preValue) => !preValue);
  };

  return (
    <div className={classes.root}>
      {(hasPublisError || hasDeleted) && (
        <PublishErrorDialog
          hasPublisError={hasPublisError || hasDeleted}
          overlappingFiles={overlappingFiles}
          isDeleteDialog={hasDeleted}
          handleDeleteDialog={handleDeleteDialog}
          token={token}
          fileName={fileName}
          handleDelete={handleDelete}
          currentOrganisation={currentOrganisation}
          isPublished={isPublished}
        />
      )}
      {isDrawerOpen && (
        <MoreActionDrawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={handleDrawer}
          handleDeleteDialog={handleDeleteDialog}
          fileName={fileName}
          isPublished={isPublished}
        />
      )}
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {
              // stableSort(datahubFiles, getComparator(order, orderBy)).map(
              datahubFiles.map((row, index, arr) => {
                const isItemSelected = isSelected(row.data_stream_name);
                const labelId = `enhanced-table-checkbox-${index}`;

                const { functional_tag, location_tag, value_chain_tag } = row;

                const totalTagLength =
                  functional_tag.length +
                  location_tag.length +
                  value_chain_tag.length;

                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={(event) =>
                          handleClick(event, row.data_stream_name)
                        }
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      onClick={() => getImportFileData(token, row._id)}
                    >
                      <Link
                        to={{
                          pathname: `/data_hub/sheets_import/${row.data_stream_name}`,
                          date: row.created_on,
                          state: {id:row._id, frameWorkType:row?.frameWorkType},
                        }}
                      >
                        <Icon
                          component={"img"}
                          src={SideIndication}
                          style={{ marginRight: "10px" }}
                        />
                        {row.data_stream_name}
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      {moment(new Date(row.data_duration[0].startDate)).format(
                        "DD MMM yy"
                      )}{" "}
                      -{" "}
                      {moment(new Date(row.data_duration[0].endDate)).format(
                        "DD MMM yy"
                      )}
                    </TableCell>
                    <TableCell align="left">{row.uploaded_by}</TableCell>
                    <TableCell align="left">
                      {moment(row.created_on).format("DD MMM yy")}
                    </TableCell>
                    <TableCell align="left">{totalTagLength}+ tags</TableCell>
                    <TableCell align="left">
                      <Box style={{ display: "flex" }}>
                        <CustomChip
                          variant="outlined"
                          label={
                            row.status === null ||
                            row.status === undefined ||
                            row.status === "STOPPED" ||
                            row.status === "QUEUED" ||
                            row.status === "RUNNING"
                              ? "In Progress"
                              : row.status
                          }
                          color={
                            row.status === null ||
                            row.status === undefined ||
                            row.status === "STOPPED" ||
                            row.status === "QUEUED" ||
                            row.status === "RUNNING"
                              ? "secondary"
                              : "primary"
                          }
                          avatar={
                            <Avatar
                              style={{
                                color:
                                  row.status === null ||
                                  row.status === undefined ||
                                  row.status === "STOPPED" ||
                                  row.status === "QUEUED" ||
                                  row.status === "RUNNING"
                                    ? "#FFC250"
                                    : "#32A05F",
                                backgroundColor:
                                  row.status === null ||
                                  row.status === undefined ||
                                  row.status === "STOPPED" ||
                                  row.status === "QUEUED" ||
                                  row.status === "RUNNING"
                                    ? "#FFC250"
                                    : "#32A05F",
                                height: "12px",
                                width: "12px",
                                marginLeft: "10px",
                              }}
                            ></Avatar>
                          }
                        />
                        {row.status !== "COMPLETE" &&
                          row.status !== "STOPPED" && (
                            <CustomButton
                              style={{
                                backgroundColor: "#FEFEFE",
                                borderRadius: "8px",
                                marginLeft: "5px",
                              }}
                              onClick={() =>
                                getMatatikaJobStatus(token, row._id)
                              }
                            >
                              <CachedOutlinedIcon
                                style={{ color: "#3374B9" }}
                              />
                            </CustomButton>
                          )}
                        {row.status === "STOPPED" && (
                          <CustomButton
                            style={{
                              backgroundColor: "#FEFEFE",
                              borderRadius: "8px",
                              marginLeft: "5px",
                            }}
                            onClick={() =>
                              restartMatatikaJob(
                                token,
                                row._id,
                                currentOrganisation,
                                "first_template"
                              )
                            }
                          >
                            <CachedOutlinedIcon style={{ color: "#3374B9" }} />
                          </CustomButton>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <CustomButton
                        variant={"text"}
                        disabled={row.status !== "COMPLETE" || row.is_published}
                        style={{
                          backgroundColor: "#FEFEFE",
                          color: "#3374B9",
                          borderRadius: "8px",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          // dispatch(publishDataStream(token, row._id));
                          dispatch(
                            checkPubishDataExist(
                              token,
                              currentOrganisation,
                              row._id
                            )
                          );
                        }}
                      >
                        {row.is_published ? "Published" : "Publish"}
                      </CustomButton>

                      
                        <MoreVertIcon
                          // onClick={() => handleDelete(row._id)}
                          onClick={() =>
                            handleDrawer(row.data_stream_name, row._id, row.is_published)
                          }
                          color="action"
                          style={{ cursor: "pointer",  }}
                        />
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

SheetTable.propTypes = {
  datahubFiles: PropTypes.array.isRequired,
};
