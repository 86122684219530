import { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import { IoIosArrowForward } from "react-icons/io";
import SpecificQuestion from "../TaskSurveyComps/RightPanelComps/RightComps/SpecificQuestion";
import CustomButton from "../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateSelectedQuestion,
  getTaskApprovalChat,
} from "../../../../actions/taskActions/TaskAction";
import Threads from "../../viewThreads/sampleViewThread";

// ---------- Stylings ----------

export const statusStyle = (status) => {
  let colorToUse = "#8C7038";
  let bgColorToUse = "#FFEFD0";

  if (status === "Approved") {
    colorToUse = "#5BB37F";
    bgColorToUse = "#EBF6EF";
  }

  return {
    width: "max-content",
    minWidth: "9em",
    height: "32px",
    borderRadius: 10,
    textTransform: "capitalize",
    // padding: "8px 12px 8px 12px",
    display: "flex",
    alignItems: "center",

    color: colorToUse,
    backgroundColor: bgColorToUse,
    gap: "4px",
    textAlign: "center",
    justifyContent: "space-around",
  };
};

const headerStyle = () => {
  return {
    color: "#15314E",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  };
};

const questionsStyle = () => {
  return {
    color: "#242424",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    maxWidth: "72em",
  };
};

// ------------------------------

// ---------- Main Render ----------
const ApprovalRightMain = ({ questions = [], activeTab, setRedoSpecific }) => {
  const dispatch = useDispatch();
  const { selectedQuestion, surveyChats, selectedTaskAssignedSurvey } =
    useSelector((state) => state.taskReducer);
  const { supplier_id, token, current_organisation } = useSelector(
    (state) => state?.login
  );
  const [openApproveDrawer, setOpenApproveDrawer] = useState(false);
  const [selectQuestion, setSelectQuestion] = useState(null);
  // console.log(openApproveDrawer, selectQuestion, activeTab);

  const handleSelectQuestion = async (quesId, action) => {
    setOpenApproveDrawer(true);
    await dispatch(
      UpdateSelectedQuestion(
        quesId,
        activeTab?._id,
        action === "select" ? "add" : "remove"
      )
    );
  };

  useEffect(() => {
    const removeSelectedQuestion = async () => {
      await dispatch(
        UpdateSelectedQuestion(
          selectedQuestion?._id || null,
          activeTab?._id,
          "remove"
        )
      );
    };

    removeSelectedQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  

  

  return (
    <Box display={"flex"} flexDirection={"column"} sx={{ gap: 20 }}>
      {selectedQuestion ? (
        <SpecificQuestion
          question={selectedQuestion}
          activeTab={activeTab}
          handleSelectQuestion={handleSelectQuestion}
        />
      ) : (
        <>
          {/* header */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p={2}
            style={{ borderBottom: "1px solid #EBF1F8" }}
          >
            <Box display={"flex"} sx={{ gap: 20 }}>
              <Typography style={headerStyle()}>Que No.</Typography>
              <Typography style={headerStyle()}>Question</Typography>
            </Box>
            <Typography
              style={{
                ...headerStyle(),
                marginLeft: "auto",
                marginRight: "40px",
              }}
            >
              Status
            </Typography>
            <Typography style={headerStyle()}>Explore</Typography>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            style={{
              padding: "0px 18px 0px 35px",
              gap: 20,
              overflowY: "auto",
              height: "56vh",
              scrollBehavior: "smooth",
            }}
          >
            {/* main questions */}
            {questions && questions?.length > 0 && questions.map((e, index) => (
              <Box
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
                key={e?.question_id}
              >
                <Box display={"flex"} sx={{ gap: 50 }}>
                  <Typography style={questionsStyle()}>{index + 1}</Typography>
                  <Typography style={questionsStyle()}>{e.question}</Typography>
                </Box>
                <Box
                  style={{
                    ...statusStyle(
                      e?.status
                    ),
                    marginLeft: "auto",
                    marginRight: "20px",
                  }}
                >
                  <Typography style={{ textAlign: "center", fontSize: "12px" }}>
                    {e?.status}
                  </Typography>
                </Box>
                <IconButton
                  size="small"
                  // onClick={() => handleSelectQuestion(e._id, "select")}
                  onClick={() => {
                    setOpenApproveDrawer(true);
                    setSelectQuestion(e);
                  }}
                >
                  <IoIosArrowForward color={"#3374B9"} />
                </IconButton>
              </Box>
            ))}
          </Box>

          {openApproveDrawer && (
            <Threads
              open={openApproveDrawer}
              setOpen={() => {
                setOpenApproveDrawer(false);
                setSelectQuestion(null);
              }}
              question={selectQuestion}
              surveyChats={surveyChats}
              questionId={selectQuestion?.question_id}
              frequency_id={selectedTaskAssignedSurvey?.frequency_id}
              addChatParam={{
                token,
                supplier_id,
                organization_id: current_organisation,
                survey_id: selectedTaskAssignedSurvey?.survey_id,
                frequency_id: selectedTaskAssignedSurvey?.frequency_id,
                section_id: activeTab?._id,
                question_id: selectQuestion?.question_id,
                role:selectedTaskAssignedSurvey?.role,
                version:selectedTaskAssignedSurvey?.version
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};
export default ApprovalRightMain;
