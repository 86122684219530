import React, { useEffect, useState } from "react";
import CustomTextField from "../../UI/textfield/textfield";
import { Typography, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSection } from "../../../actions/supplier/supplierAction";
import QuestionBody from "../surveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";
import ResponseSectionList, {
  BlueBackgroundCard,
} from "../surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionList";
import ResponseSectionTopCard from "../surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionTopCard";
import { getCompletedQuestions } from "actions/surveyListing/surveyListingAction";
import DrawerLeft from "../surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/DrawerLeft";

const responseStyles = makeStyles((theme) => ({
  questionCont: {
    border: "none",
    borderRadius: 5,

    width: "100%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    padding: "16px 0px",
  },
  question: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    border: "1px solid #E9E9E9",
    backgroundColor: "#FCFCFC",
    borderRadius: "6px",
    marginLeft: 5,
    width: "80%",
  },
  weightage: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    color: "#3374B9",
    border: "1px solid #EBF1F8",
    backgroundColor: "#EBF1F8",
    borderRadius: "16px",
    marginLeft: 5,
    width: "18%",
    textAlign: "center",
    height: "fit-content",
  },
  userName: {
    padding: 6,
    margin: "8px 5px",
    fontSize: 13,

    borderRadius: 5,
    cursor: "pointer",
  },
}));
export const Wrapper = ({
  users,
  children,
  hidesearch = false,
  width = "22%",
  selected,
  setSelected,
}) => {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    setUsersList(users);
  }, [users]);

  const classes = responseStyles();
  const searchUsers = (e) => {
    if (e.target.value.trim() === "") setUsersList(users);
    else {
      const filtered = users.filter((user) =>
        user.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setUsersList(filtered);
    }
  };
  return (
    <div style={{ display: "flex", padding: "10px 0px", width: "100%" }}>
      <div style={{ width: width, backgroundColor: "#FBFBFB" }}>
        {!hidesearch && (
          <CustomTextField
            variant="outlined"
            size="small"
            placeholder="search"
            fullwidth
            onChange={searchUsers}
          />
        )}
        <div
          className="scroll"
          style={{
            height: hidesearch ? "56vh" : "48vh",
            marginTop: 8,
            paddingBottom: 12,
          }}
        >
          {usersList?.length ? (
            usersList.map((user, i) => (
              <Typography
                className={classes.userName}
                style={{
                  fontWeight:
                    selected === i || selected === user?.title ? 500 : 400,
                  backgroundColor:
                    (selected === i || selected === user?.title) && "#EBF1F8",
                }}
                role="button"
                onClick={() => {
                  setSelected(user?.title);
                  dispatch(setSelectedSection(user?.title));
                }}
              >
                {user?.title}
              </Typography>
            ))
          ) : (
            <Typography>No Users Found</Typography>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
export const survey = [
  { name: "Self Assessment", isSelected: true },
  { name: "Controversies", isSelected: false },
  { name: "Banking Policy", isSelected: false },
];

export const Question = ({
  question,
  type,
  options,
  weightage = 0,
  submittedAnswer,
  hideWeightage = false,
}) => {
  const classes = responseStyles();
  return (
    <div className={classes.questionCont}>
      <div style={{ display: "flex", width: "100%" }}>
        <Typography className={classes.question}>{question}</Typography>
        {!hideWeightage && (
          <Typography className={classes.weightage}>
            {weightage} Weightage
          </Typography>
        )}
      </div>
      <div
        style={{
          width: "100%",
          padding:
            type === "Textfield" || type === "Number" || type === "Currency"
              ? "25px 10px"
              : "25px 25px",
          pointerEvents: "none",
        }}
      >
        <QuestionBody
          questionType={type}
          answers={type === "Multichoice" ? null : options}
          responseAnswer={submittedAnswer}
          submittedAnswer={
            type === "Table" ||
            type === "Comment box" ||
            type === "Document Upload"
              ? submittedAnswer
              : ""
          }
          removeEditOption={true}
        />
      </div>
    </div>
  );
};

export const Tile = ({ text, value }) => {
  return (
    <div
      style={{
        border: "none",
        borderRadius: 5,
        backgroundColor: "#F6F6F6",
        width: "25%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
      }}
    >
      <Typography
        style={{
          fontSize: 12,
          fontWeight: 400,
          padding: "3px 0px",
          wordBreak: "break-all",
        }}
      >
        {text}
      </Typography>
      {text === "Survey Status" ? (
        <div
          style={{
            backgroundColor: "#BFE2CD",
            borderRadius: "13px",
            display: "flex",
            alignItems: "center",
            padding: "3px 5px",
          }}
        >
          <div
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "50%",
              marginRight: 2,
              backgroundColor: "#154328",
            }}
          />
          <Typography
            style={{ fontSize: 11, fontWeight: 600, color: "#154328" }}
          >
            Complete
          </Typography>
        </div>
      ) : (
        <Typography
          style={{ fontSize: 12, fontWeight: 600, padding: "3px 0px" }}
        >
          {value}
        </Typography>
      )}
    </div>
  );
};

function SelfAssessmentResponse({ defaultSurveyData, organization_id }) {
  // const [selected, setSelected] = useState(0);

  // from redux
  const dispatch = useDispatch();
  const { token, current_organisation } = useSelector((state) => state.login);
  const { completedQuestions } = useSelector((state) => state.surveyListing);
  const { supplierOrganizationDetails } = useSelector(
    (state) => state.brmReducer
  );

  // states
  const [currentFrequency, setCurrentFrequency] = useState(
    defaultSurveyData?.frequency && defaultSurveyData?.frequency[0]
  );
  const [selectedSection, setSelectedSection] = useState(
    completedQuestions.length > 0 && completedQuestions[0]
  );

  // ------------ useStates ------------

  useEffect(() => {
    if (defaultSurveyData?.frequency?.length > 0) {
      setCurrentFrequency(defaultSurveyData?.frequency[0]);
    }
  }, [defaultSurveyData]);

  // ------------------------------------

  useEffect(() => {
    if (currentFrequency?.frequency_id) {
      dispatch(
        getCompletedQuestions(
          token,
          defaultSurveyData?.survey_id, // survey_id
          current_organisation, // organization_id
          currentFrequency?.frequency_id, // frequency_id
          supplierOrganizationDetails?._id, // supplier_id
          currentFrequency?.version // version
        )
      );
    }
  }, [currentFrequency?.frequency_id]);

  // useState for selecting section when frequency changes
  useEffect(() => {
    if (currentFrequency?.frequency_id) {
      setSelectedSection(completedQuestions[0]);
    }
  }, [completedQuestions, currentFrequency?.frequency_id]);

  const totalQues = completedQuestions?.reduce((total, section) => {
    return total + (section.questions?.length || 0);
  }, 0);

  return (
    // <Wrapper users={survey} hidesearch width="13%">
    // <Wrapper selected={selected} setSelected={setSelected} /*users={users}*/>
    <div style={{ display: "flex", justifyContent: "space-between", gap: 10 }}>
      <DrawerLeft
        selectedResponse={selectedSection}
        completedQuestions={completedQuestions}
        setSelectedSection={setSelectedSection}
      />

      <div
        className="scroll"
        style={{ width: "100%", height: "55vh", overflowY: "auto" }}
      >
        <div
          style={{
            backgroundColor: "#FBFBFB",
            padding: "0px 0px",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <div style={{ width: "100%" }}>
            <ResponseSectionTopCard
              selectedResponse={defaultSurveyData}
              currentFrequency={currentFrequency}
              setCurrentFrequency={setCurrentFrequency}
              totalSections={completedQuestions?.length}
              totalQues={totalQues}
            />
          </div>
        </div>
        <div>
          <ResponseSectionList completedQuestions={[selectedSection]} />
          {/* {Object.keys(response).map((data) => (
      <>
        <BlueBackgroundCard heading={data} />
        {response[data].map((question) => (
          <Question
            question={question.question}
            options={question.options}
            type={question.question_type}
            weightage={question.weightage}
          />
        ))}
      </>
    ))} */}
        </div>
      </div>
    </div>

    // </Wrapper>
    // </Wrapper>
  );
}

export default SelfAssessmentResponse;

// {!type || type === "radio" ? (
//   <RadioGroup
//     value={"order"}
//     style={{ marginTop: 10 }}
//     // onChange={(e) => handleChange(e, que?.order)}
//     // name={que?._id + ""}
//   >
//     {options &&
//       options.map((ans, j) => {
//         return (
//           <div key={j} style={{ width: "100%" }}>
//             <div
//               key={j}
//               style={{ display: "flex", alignItems: "center" }}
//             >
//               <RadioButton value={ans[1]} id={ans[0] + ""} />
//               {/* <CustomCheckBox
//                 color="tertiary"
//                 checked={opt[1]}
//                 disabled={opt[1]}
//               /> */}
//               <Typography
//                 style={{
//                   fontSize: 13,
//                   fontWeight: 400,
//                   padding: "3px 0px",
//                 }}
//               >
//                 {ans[0]}
//               </Typography>
//             </div>
//             {/* <FormControlLabel
//               style={{
//                 width: "100%",
//                 padding: type === "textField" && "15px 0px",
//               }}
//               value={ans[0]}
//               control={
//                 <>
//                   {(!type || type === "radio") && (
//                     // <TextField
//                     //   // className={clsx(classes.root, classes.checked)}
//                     //   onChange={(e) => {
//                     //     // checkBoxChange(e, i, j);
//                     //   }}
//                     //   name={que.id + ""}
//                     //   id={ans[1] + ""}
//                     // />
//                     <RadioButton value={ans[1]} id={ans[0] + ""} />
//                     //  <FormControlLabel value="female" control={<Radio />} label="Female" />
//                   )}
//                 </>
//               }
//               label={ans[0]}
//               // onChange={(e) => {
//               //   addSurvey(e, i);
//               // }}
//             /> */}
//           </div>
//         );
//       })}
//   </RadioGroup>
// ) : (
//   <>
//     {type === "textField" && (
//       <div style={{ padding: "15px 0px" }}>
//         <CustomTextfield
//           style={{
//             width: "50vw",
//           }}
//           name="userName"
//           label="Answer"
//           variant="outlined"
//           size="small"
//           fullWidth
//           // value={data.question}
//         />
//       </div>
//     )}
//     {type === "multi select" && (
//       <Grid item xs={12} md={12} style={{ padding: "15px 0px" }}>
//         {/* <Typography
//                                 style={{
//                                   fontSize: "14px",
//                                   marginBottom: "5px",
//                                   color: "#9A9A9A",
//                                 }}
//                               >
//                                 Select File
//                               </Typography> */}

//         <Select
//           defaultValue={"Select an Option"}
//           style={{
//             border: "1px solid #C0D4E9",
//             backgroundColor: "#FEFEFE",
//             borderRadius: "8px",
//             height: "40px",
//             width: "50vw",
//             marginTop: "15px",
//           }}
//           // value={collect.value}
//           onChange={(event) => {
//             const {
//               target: { value },
//             } = event;

//             // setCollect((prev) => {
//             //   return {
//             //     ...prev,
//             //     question_id: que?._id,
//             //     value: value,
//             //   };
//             // });
//             // setDisclosureSort(value);
//           }}
//           input={<OutlinedInput />}
//         >
//           {options.map((item) => {
//             return (
//               <MenuItem key={item[0]} value={item[0]}>
//                 {item[0]}
//               </MenuItem>
//             );
//           })}
//         </Select>
//       </Grid>
//     )}
//     {type === "location" && (
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           height: `400px`,
//           width: "800px",
//         }}
//       >
//         <Typography> latitude: , longitude: </Typography>
//         {/* <GoogleMaps {...props}/> */}
//       </div>
//     )}
//     {type === "date" && (
//       <Grid item xs={12} md={12} className={classes.grid4}>
//         <Typography
//           style={{
//             fontSize: "14px",
//             marginBottom: "5px",
//             color: "#9A9A9A",
//           }}
//         >
//           Select File
//         </Typography>
//         <CustomTextfield
//           name="date"
//           variant="outlined"
//           type="date"
//           size="small"
//           fullWidth
//           value={""}
//         />
//       </Grid>
//     )}
//     {type === "file" && (
//       <Grid item xs={12} md={12} style={{ padding: "15px 0px" }}>
//         <Typography
//           style={{
//             fontSize: "14px",
//             marginBottom: "5px",
//             color: "#9A9A9A",
//           }}
//         >
//           Select File
//         </Typography>
//         <CustomTextfield
//           name="userName"
//           variant="outlined"
//           type="file"
//           size="small"
//           fullWidth
//           // value={collect.value}
//           onChange={(event) => {
//             const {
//               target: { value },
//             } = event;

//             // setDisclosureSort(value);
//           }}
//         />
//       </Grid>
//     )}
//   </>
// )}
// {/* {options?.map((opt) => (
//   <div style={{ display: "flex", alignItems: "center" }}>
//     <CustomCheckBox color="tertiary" checked={opt[1]} disabled={opt[1]} />
//     <Typography
//       style={{ fontSize: 13, fontWeight: 400, padding: "3px 0px" }}
//     >
//       {opt[0]}
//     </Typography>
//   </div>
// ))} */}
