import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AssessmentTop from "../widgets/assessmentTop/assessmentTop";
import AssessmentApprovalPDF from "../widgets/assesmentApproval/assessmentApprovalPDF";

export default function AssesmentApprovalScreen(props) {
	useEffect(() => {
		props.getAssessmentData(props.login.token, props.assessment_id);
		document.body.style.overflow = "scroll";
	}, [props.assessment_id]);

	return (
		<Grid container>
			<Grid item xs={11}>
				<Box mt={2} mx={1}>
					<AssessmentTop {...props} />
				</Box>
			</Grid>
			<Grid item xs={11} id="divToPrint">
				<Box mt={2} mx={1}>
					<AssessmentApprovalPDF {...props} />
				</Box>
			</Grid>
		</Grid>
	);
}
