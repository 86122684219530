export const SET_SELECTED_SECTION = "SET_SELECTED_SECTION";

export const SET_SELECTED_TASK_QUESTION = "SET_SELECTED_TASK_QUESTION";
export const SET_ORIGINAL_SELECTED_TASK_QUESTION =
  "SET_ORIGINAL_SELECTED_TASK_QUESTION";

export const SET_TASK_TYPE = "SET_TASK_TYPE";
export const SET_TASK_ASSIGNED_ORGANIZATIONS =
  "SET_TASK_ASSIGNED_ORGANIZATIONS";
export const SET_TASK_ORGS_TOTAL = "SET_TASK_ORGS_TOTAL";
export const SET_TASK_ASSIGNED_SURVEY = "SET_TASK_ASSIGNED_SURVEY";
export const SET_TASK_SELECTED_ASSIGNED_SURVEY =
  "SET_TASK_SELECTED_ASSIGNED_SURVEY";

export const SET_SELECTED_QUESTION_REDO = "SET_SELECTED_QUESTION_REDO";

export const SET_SURVEY_CHAT = "SET_SURVEY_CHAT";

export const SET_CONTRIBUTED_QUESTION_DETAILS =
  "SET_CONTRIBUTED_QUESTION_DETAILS";

export const SET_QUESTION_COMMENT = "SET_QUESTION_COMMENT";
export const SET_MAIN_QUESTION_COMMENT = "SET_MAIN_QUESTION_COMMENT";

export const SET_SURVEY_CHAT_MEMBERS = "SET_SURVEY_CHAT_MEMBERS";
