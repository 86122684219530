import { makeStyles } from "@material-ui/core";

const SustainabilityTopStyles = makeStyles(() => ({
	mainGrid: {
		backgroundColor: "#3374B933",
		height: 60,
		margin: 10,
		borderRadius: 10,
	},
	subGrid: {
		marginTop: 13,
		paddingLeft: 20,
		paddingRight: 20,
		display: "flex",
	},
	listDiv: {
		backgroundColor: "#ffffff",
		borderRadius: "50%",
		marginRight: 15,
		width: 35,
		height: 35,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	boxWidth: { width: "100%" },
}));

export default SustainabilityTopStyles;
