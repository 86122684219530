import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import SectionComplete from "./RightPanelComps/SectionComplete";
import RightSections from "./RightPanelComps/RightSections";
import CustomButton from "../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateSurveyComplete,
  setOriginalSelectedTaskQuestion,
  submitAnswerTask,
  uploadAllTableDocs,
} from "../../../../actions/taskActions/TaskAction";
import RedoDialog from "./RightPanelComps/RedoDialog";
import RedoSpecific from "./RightPanelComps/RedoSpecific";
import { DisableSaveNext } from "./QuestionsUtils/DisableButtons";

const TaskRightPanel = ({
  selectedSection,
  activeSurvey,
  setActiveSurvey,
  activeTab,
  completedTab,
  setCompletedTab,
  setActiveTab,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { supplier_id, token, current_organisation } = useSelector(
    (state) => state?.login
  );

  const {
    selectedTaskQuestion,
    originalSelectedTaskQuestion,
    selectedTaskAssignedSurvey,
    questionComment,
  } = useSelector((state) => state.taskReducer);

  const { selectedCorporate } = useSelector((state) => state?.supplierAdmin);
  const { tableTypeDocs } = useSelector((state) => state.surveyListing);

  const { answers_submitted, questions, total_questions } =
    selectedTaskQuestion || {};

  // const total_questions = questions?.length; // Total number of questions
  const changeProgressBy = (1 / total_questions) * 100;

  const [surveyCompelte, setSurveyComplete] = useState(false);
  const [redoSpecific, setRedoSpecific] = useState(false);

  const [currentQues, setCurrentQues] = useState(1);
  const [progress, setProgress] = useState(changeProgressBy);

  // disable save & next
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  // State to manage the dialog open/close
  const [openRedoDialog, setOpenRedoDialog] = useState(false);

  useEffect(() => {
    // if survey is complete
    if (answers_submitted === total_questions) {
      setSurveyComplete(true);
      setProgress(99.9);
      setCurrentQues(total_questions);
    }

    // if survey is incomplete
    else if (answers_submitted > 0 && answers_submitted <= total_questions) {
      setSurveyComplete(false);
      setProgress(((answers_submitted + 1) / total_questions) * 100);
      setCurrentQues(answers_submitted + 1);
    } else {
      setSurveyComplete(false);
      setProgress((1 / total_questions) * 100);
      setCurrentQues(1);
    }

    return () => {
      setSurveyComplete(false);
      setProgress((1 / total_questions) * 100);
      setCurrentQues(1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab?._id, total_questions, selectedTaskQuestion?.section_id]);

  const handleNextQuestion = async () => {
    let submitted_data =
      selectedTaskQuestion?.questions[currentQues - 1]?.submitted_data;

    // check and upload all table docs if available
    if (
      selectedTaskQuestion?.questions[currentQues - 1]?.question_type ===
      "Table"
    ) {
      if (tableTypeDocs?.length > 0) {
        submitted_data = await dispatch(
          uploadAllTableDocs(submitted_data, tableTypeDocs)
        );
      }
    }

    const currentSelectedQuestion =
      selectedTaskQuestion?.questions[currentQues - 1];

    const originalQuestionData = originalSelectedTaskQuestion?.questions?.find(
      (e) => e?.question_id === currentSelectedQuestion?.question_id
    );

    // Check if submitted data || comment is different before dispatching
    if (
      originalQuestionData?.submitted_data !==
        currentSelectedQuestion?.submitted_data ||
      (originalQuestionData?.comment &&
        currentSelectedQuestion?.comment &&
        originalQuestionData?.comment_value !==
          currentSelectedQuestion?.comment_value)
    ) {
      // save answer
      await dispatch(
        submitAnswerTask(
          token,
          supplier_id,
          selectedCorporate?._id,
          selectedTaskQuestion?.questions[currentQues - 1]?.question_id,
          selectedTaskAssignedSurvey?.frequency_id,
          submitted_data,
          selectedTaskAssignedSurvey?.survey_id,
          activeTab?._id,
          questionComment
        )
      );

      // update original with updated
      dispatch(setOriginalSelectedTaskQuestion(selectedTaskQuestion));
    }

    // changing progress
    if (progress < 99.9) {
      setProgress(progress + changeProgressBy);
      setCurrentQues((prev) => prev + 1);
    }
    // when progress is 100%
    else if (currentQues === total_questions && progress >= 99.9) {
      // if survey is already on complete

      if (surveyCompelte) {
        setSurveyComplete(false);
        setProgress(changeProgressBy);
        setCurrentQues(1);
        setActiveTab(activeTab + 1);
      }
      // if progress is 100% and survey is not complete
      else {
        setCompletedTab([...completedTab, activeTab?._id]);
        setSurveyComplete(true);
        dispatch(UpdateSurveyComplete("add", activeTab?._id));
      }
    }
  };

  const handlePrevQuestion = () => {
    // if progress is more than initial
    if (progress > (1 / total_questions) * 100) {
      setProgress(progress - changeProgressBy);
      setCurrentQues((prev) => prev - 1);
    }
    // if progress becomes initial
    else if (selectedSection[0]?._id !== activeTab?._id) {
      setActiveTab(selectedSection[activeSurvey - 1]);
      setActiveSurvey((prev) => prev - 1);

      setProgress(changeProgressBy);
      setCurrentQues(1);
    }
  };

  const toggleRedoDialog = () => {
    setOpenRedoDialog(!openRedoDialog);
  };

  const handleRedoSection = (proceedAction) => {
    if (proceedAction === "EntireSec") {
      // remove activeTab from completedTab
      setCompletedTab(completedTab.filter((id) => id !== activeTab?._id));
      // Resetting states
      setSurveyComplete(false);
      setProgress(changeProgressBy);
      setCurrentQues(1);

      // reset survey section
      dispatch(UpdateSurveyComplete("remove", activeTab?._id));
    } else {
      setRedoSpecific(true);
    }
    toggleRedoDialog();
  };

  // const activeTabQues = selectedSection[selectedSection.length - 1]?.questions;
  // const activeTabQues =  questions;

  // Check if all surveys are complete
  // selectedSection.every((survey) => survey.isSurveyComplete) ||
  let allSurveysComplete = false;

  if (selectedSection[selectedSection.length - 1]?._id === activeTab?._id) {
    allSurveysComplete =
      questions[currentQues - 1]?.question ===
      questions[questions.length - 1]?.question;
  } else {
    allSurveysComplete = false;
  }

  // hook to manage disable save & next button
  useEffect(() => {
    if (!selectedTaskQuestion || !questions || !questions[currentQues - 1]) {
      setDisableSaveButton(true);
      return;
    }

    const currentQuestionId = questions[currentQues - 1]?.question_id;
    const currentQuestion = selectedTaskQuestion?.questions?.find(
      (question) => question.question_id === currentQuestionId
    );

    if (!currentQuestion) {
      setDisableSaveButton(true);
      return;
    }

    const disableSave = DisableSaveNext(
      currentQuestion.question_type,
      currentQuestion
    );

    setDisableSaveButton(disableSave);
  }, [selectedTaskQuestion, currentQues, questions]);

  // console.log(progress, activeTab._id);

  // console.log(questions, allSurveysComplete, selectedSection[selectedSection.length - 1], selectedSection);
  return (
    <Box width={"100%"} height={"100%"} style={{ position: "relative" }}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxShadow: "0px 0px 8px 0px #3374B940",
        }}
      >
        {redoSpecific ? (
          // Redo Specific Question
          <RedoSpecific
            questions={questions}
            activeTab={activeTab}
            setRedoSpecific={setRedoSpecific}
          />
        ) : !surveyCompelte ? (
          // Company Profile
          <RightSections
            question={questions[currentQues - 1]}
            currentQues={currentQues}
            progress={progress}
            total_questions={total_questions}
            activeTab={activeTab}
            questionComment={questionComment}
          />
        ) : (
          // Survey is complete
          <SectionComplete
            progress={progress}
            total_questions={total_questions}
            currentQues={currentQues}
            activeTab={activeTab}
          />
        )}

        {/* Section Complete */}
      </Box>

      {/* Action Buttons */}
      <Box
        style={{
          display: redoSpecific ? "none" : "flex",
          position: "absolute",
          right: 10,
          bottom: 10,
          gap: 10,
        }}
      >
        {surveyCompelte ? (
          <>
            {/* survey complete actions */}
            <CustomButton
              variant={"text"}
              color={"primary"}
              style={{ textTransform: "capitalize" }}
              onClick={toggleRedoDialog}
            >
              Redo Section
            </CustomButton>
            <CustomButton
              variant={"contained"}
              color={"primary"}
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                if (allSurveysComplete) {
                  history.goBack();
                } else {
                  // Increment activeTab
                  setActiveTab(selectedSection[activeSurvey + 1]);

                  setActiveSurvey((prev) => prev + 1);
                }
              }}
              // disabled={activeTab === 1}
            >
              {allSurveysComplete ? "Save & Exit" : "Continue to next Section"}
            </CustomButton>
          </>
        ) : (
          <>
            <CustomButton
              variant={"text"}
              color={"primary"}
              style={{ textTransform: "capitalize" }}
              onClick={handlePrevQuestion}
              disabled={
                selectedSection[0]?._id === activeTab?._id && currentQues === 1
              }
            >
              Previous
            </CustomButton>
            <CustomButton
              variant={"contained"}
              color={"primary"}
              style={{ textTransform: "capitalize" }}
              onClick={handleNextQuestion}
              disabled={
                questions[currentQues - 1]?.selectedOpt || disableSaveButton
              }
            >
              Submit & Next
            </CustomButton>
          </>
        )}
      </Box>

      <RedoDialog
        open={openRedoDialog}
        handleClose={toggleRedoDialog}
        proceedAction={handleRedoSection}
      />
    </Box>
  );
};
export default TaskRightPanel;
