import React from "react";
import FrameworkContent from "./frameworkContent";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import basicInfoRightStyles from "./basicInfoTabRightStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FrameworksDialog(props) {
  const classes = basicInfoRightStyles();

  return (
    <Dialog open={props.open} TransitionComponent={Transition}>
      <Grid>
        <Grid container justifyContent="center">
          <Grid item xs={8} className={classes.gridDialog}>
            <Typography className={classes.dialogHeading}>
              Report Framework
            </Typography>
            <CloseIcon
              name="closeFramwork"
              className={classes.dialogIcon}
              onClick={() => {
                props.setOpen(false);
              }}
            />
          </Grid>
        </Grid>
        {Object.keys(props.framework).map((key, index) => {
          return (
            props.framework[key].length > 0 && (
              <React.Fragment key={index}>
                <Grid container justifyContent="center">
                  <Grid
                    item
                    sm={11}
                    md={11}
                    lg={11}
                    className={classes.dialogContent}
                  >
                    <Typography
                      variant="body1"
                      className={classes.dialogContentTypo}
                    >
                      {key === "mandatory_data"
                        ? "National Framework"
                        : key === "recommended_data"
                        ? "Recommended frameworks"
                        : "Others FrameWorks"}
                    </Typography>
                  </Grid>
                </Grid>

                <DialogContent style={{ minWidth: "615px", maxWidth: "650px" }}>
                  <FrameworkContent
                    framework={props.framework[key]}
                    setOpen={props.setOpen}
                    open={props.open}
                    onClose={props.onClose}
                  />
                </DialogContent>
              </React.Fragment>
            )
          );
        })}
        {/* {props.framework.map((framework, index) => (
        <>
          <Grid container>
            <Grid sm={11} md={11} lg={11}>
              <Typography
                variant="body1"
                style={{
                  marginBottom: "10px",
                  padding: "20px 20px 20px 40px",
                  fontWeight: "600",
                }}
              >
                {framework.}
              </Typography>
            </Grid>
          </Grid>
          <DialogContent className="scroll">
            <FrameworkContent
              framework={props.framework.recommended_data}
              setOpen={props.setOpen}
              open={props.open}
              onClose={props.onClose}
            />
          </DialogContent>
        </>
      ))} */}
        <div style={{ padding: "5px" }}> </div>
      </Grid>
    </Dialog>
  );
}
