import {
  SET_SELECTED_SECTION,
  SET_TASK_TYPE,
  SET_TASK_ASSIGNED_ORGANIZATIONS,
  SET_TASK_ASSIGNED_SURVEY,
  SET_TASK_SELECTED_ASSIGNED_SURVEY,
  SET_SELECTED_TASK_QUESTION,
  SET_SELECTED_QUESTION_REDO,
  SET_SURVEY_CHAT,
  SET_CONTRIBUTED_QUESTION_DETAILS,
  SET_QUESTION_COMMENT,
  SET_MAIN_QUESTION_COMMENT,
  SET_SURVEY_CHAT_MEMBERS,
  SET_ORIGINAL_SELECTED_TASK_QUESTION,
  SET_TASK_ORGS_TOTAL,
} from "../../constants/task/taskConstants";

const initial_state = {
  completedSections: [],

  selectedSection: [],

  selectedTaskQuestion: null,
  originalSelectedTaskQuestion: null,

  taskType: "",
  taskAssigned: [],
  taskOrgsTotal: 0,
  taskAssignSurveyList: [],
  taskCompletedSurveyList: [],
  taskInActiveSurveyList:[],
  selectedTaskAssignedSurvey: {},
  selectedQuestionRedo: null,
  surveyChats: [],
  contributedQuestionDetails: {},
  mainQuestionComment: null,
  questionComment: null,
  surveyChatMembers: [],
};

export default function taskReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_SELECTED_SECTION:
      return {
        ...state,
        selectedSection: action.payload,
      };

    case SET_SELECTED_TASK_QUESTION:
      return {
        ...state,
        selectedTaskQuestion: action.payload,
      };

    case SET_ORIGINAL_SELECTED_TASK_QUESTION:
      return {
        ...state,
        originalSelectedTaskQuestion: action.payload,
      };

    case SET_SELECTED_QUESTION_REDO:
      return {
        ...state,
        selectedQuestionRedo: action.payload,
      };

    case SET_TASK_TYPE:
      return {
        ...state,
        taskType: action.payload,
      };

    case SET_TASK_ASSIGNED_ORGANIZATIONS:
      return {
        ...state,
        taskAssigned: action.payload,
      };

    case SET_TASK_ORGS_TOTAL:
      return {
        ...state,
        taskOrgsTotal: action.payload,
      };

    case SET_TASK_ASSIGNED_SURVEY:
      return (state = {
        ...state,
        taskAssignSurveyList: action.payload.assigned_surveys,
        taskCompletedSurveyList: action.payload.completed_surveys,
        taskInActiveSurveyList:action.payload.inactive_surveys
      });

    case SET_TASK_SELECTED_ASSIGNED_SURVEY:
      return {
        ...state,
        selectedTaskAssignedSurvey: action.payload,
      };

    case SET_SURVEY_CHAT:
      return {
        ...state,
        surveyChats: action.payload,
      };

    case SET_SURVEY_CHAT_MEMBERS:
      return {
        ...state,
        surveyChatMembers: action.payload,
      };

    case SET_CONTRIBUTED_QUESTION_DETAILS:
      return {
        ...state,
        contributedQuestionDetails: action.payload,
      };

    case SET_QUESTION_COMMENT:
      return {
        ...state,
        questionComment: action.payload,
      };

    case SET_MAIN_QUESTION_COMMENT:
      return {
        ...state,
        mainQuestionComment: action.payload,
      };

    default:
      return state;
  }
}
