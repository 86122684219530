import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

import fileAttach from "../../../images/fileAttach.svg";
import fileLayoutStyles from "./fileLayoutStyles";

 
export default function FileLayout({
  title,
  files,
  currentAssessmentID,
  setActiveStep,
}) {
  const classes = fileLayoutStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: "h6", className: classes.cardHeader }}
      />
      <CardContent className={classes.cardContent}>
        {files.map((file, index) => {
          return (
            <Box className={classes.boxContent} key={index}>
              <img src={fileAttach} alt="file attach" />
              <Link
                to={{
                  pathname: "/sustainability_report",
                  state: { assessment_id: currentAssessmentID },
                }}
                className={classes.link}
              >
                <Typography
                  className={classes.fileName}
                  onClick={() => {
                    setTimeout(() => {
                      if (title === "Assessment Approval") {
                        setActiveStep(3);
                      }
                      if (title === "Sustainability Report") {
                        setActiveStep(5);
                      }
                    }, 100);
                  }}
                >
                  {file}
                </Typography>
              </Link>
            </Box>
          );
        })}
      </CardContent>
    </Card>
  );
}
