import { useHistory } from "react-router-dom";

import { Box, TableRow, TableCell, IconButton } from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";

import GroupDoc from "../../../images/GroupDoc.png";
import DynamicTable, {
  statusStyle,
  textStyle,
  useTableStyles,
} from "../../UI/dynamicTable/DynamicTable";
import { headings } from "./tempData";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getCorporates,
  setSelectedCorporate,
} from "../../../actions/supplierAdmin/supplierAdminActions";
import NoData from "../../widgets/NoData/NoData";
import { Pagination } from "@material-ui/lab";

// -------- main render ---------
const MainTable = ({ searchKeyword }) => {
  const dispatch = useDispatch();
  const { corporatesList, total_corporates } = useSelector(
    (state) => state?.supplierAdmin
  );
  const { supplier_id, token } = useSelector((state) => state?.login);

  const classes = useTableStyles();
  const history = useHistory();

  // states
  const [page, setPage] = useState(1);
  const limit = 6;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const fetchSurvey = (page) => {
    const starting_after = (page - 1) * limit;
    dispatch(
      getCorporates(token, supplier_id, searchKeyword, limit, starting_after)
    );
  };

  useEffect(() => {
    fetchSurvey(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchKeyword]);

  return (
    <Box position={"relative"} height={"80vh"}>
      <Box p={2} maxHeight={"70vh"} overflow={"auto"}>
        {/* Table */}
        {corporatesList.length > 0 && (
          <DynamicTable headings={headings}>
            {corporatesList.map((rowData, rowIndex) => (
              <TableRow
                key={rowIndex}
                className={classes.tableRowHover}
                onClick={() => {
                  dispatch(setSelectedCorporate(rowData));

                  history.push(
                    `/supplier_admin/${rowData.company_name.replace(/ /g, "_")}`
                  );
                }}
              >
                <TableCell style={textStyle("main")}>
                  {rowData.company_name}
                </TableCell>
                <TableCell style={textStyle()}>
                  {rowData.no_of_surveys}
                </TableCell>
                <TableCell style={textStyle()}>{rowData.user_name}</TableCell>
                <TableCell>
                  <Box style={statusStyle(rowData.status.toLowerCase())}>
                    <DotIcon style={{ widht: "10px", height: "10px" }} />
                    {rowData.status}
                  </Box>
                </TableCell>
                {/* <TableCell>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    // console.log(data[rowIndex]);
                  }}
                >
                  <img src={GroupDoc} alt="DocIcon" />
                </IconButton>
              </TableCell> */}
              </TableRow>
            ))}
          </DynamicTable>
        )}
      </Box>
      {corporatesList.length === 0 && <NoData />}

      <Box style={{ position: "absolute", right: 10, bottom: 10 }}>
        <Pagination
          color="primary"
          classes={{ root: classes.root }}
          variant="outlined"
          count={Math.ceil(total_corporates / limit)}
          page={page}
          onChange={handlePageChange}
        />
      </Box>
    </Box>
  );
};
export default MainTable;
