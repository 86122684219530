import { makeStyles } from "@material-ui/core";

const basicInformationLeftStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: '#FFFFFF',
    // marginBottom: '15px',
    borderRadius: 5,
    height: '100%',
  },
  cardContent: {
    padding: 10
  },
  cardTypography: {
    fontWeight: "600"
  },
  box: {
    border: "1px solid #00000050",
    padding: 12,
    borderRadius: 5,
    cursor: "pointer",
    minHeight: 45
  },
  card: {
    backgroundColor: "#F0F6FD",
    cursor: "pointer",
    height: 50,
    borderRadius: 10
  },
  cardContent1: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: 20,
    position: "relative"
  },
  avatar: {
    marginRight: 20,
    width: 35,
    height: 35
  },
  div: {
    position: "absolute",
    right: 10
  },
  AppBar: { boxShadow: "none" },
  tab: {
    fontSize: 12
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins"
  }
}));

export default basicInformationLeftStyles;
