import { Button } from "@material-ui/core";
import React from "react";
import "./CustomMenu.css";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const CustomMenu = ({
	downloadLink,
	showDelete,
	onDelete,
	onDownload,
	handelClose,
}) => {
	return (
		<ClickAwayListener onClickAway={handelClose}>
			<div className="menu">
				<div className="menu-option menu-option-download" onClick={onDownload}>
					<a href={downloadLink}>
						<p className="menu-option-text download">Download File</p>
					</a>
				</div>
				{showDelete ? (
					<div className="menu-option menu-option-delete" onClick={onDelete}>
						<p className="menu-option-text delete">Delete</p>
					</div>
				) : null}
			</div>
		</ClickAwayListener>
	);
};

export default CustomMenu;
