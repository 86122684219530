import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {
        display: false,
      },
      grid: {
        display: true,
        drawOnChartArea: false,
      },
    },

    y: {
      grid: {
        display: true,
        drawBorder: false,
      },
      ticks: {
        maxTicksLimit: 6,
      },
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};
const labels = [10, 20, 30, 40, 50, 60];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [630, 788, 876, 698, 150, 400],
      borderColor: "rgba(85, 144, 238, 1)",
      backgroundColor: "rgba(85, 144, 238, 0.5)",
      tension: 0.4,
      borderWidth: 1,
    },
  ],
};

const LineChart = () => {
  return (
    <Card
      style={{
        height: "100%",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        border: "2px solid #DADBE6",
        
      }}
    >
      <CardContent style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "24px",
              color: " #242424",
            }}
          >
            Total waste Disposal (By Location)
          </Typography>
          <div>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: " #242424",
              }}
            >
              No. of Plants
            </Typography>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "21px",
                lineHeight: "24px",
                color: " #242424",
              }}
            >
              12
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "center",
            alignItems: "center",
            height: "85%",
          }}
        >
          <Line options={options} data={data} />
        </div>
      </CardContent>
    </Card>
  );
};

export default LineChart;
