import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import TableRow from "@material-ui/core/TableRow";
import Icon from "@material-ui/core/Icon";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Link } from "react-router-dom";

import CustomButton from "../UI/button/button";
import CustomChip from "../UI/chip/chip";
import moment from "moment";
const drawerWidth = 400;

const useStyles = makeStyles((theme) => ({
  textFields: {
    width: "80%",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  table: {
    minWidth: 600,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  assignment_row: {
    "&:hover": {
      backgroundColor: "#F0F6FD",
    },
  },
}));

export default function SurveyListing(props) {
  const classes = useStyles();

  const {
    token,
    surveyListing,
    currentOrganisation,
    limit,
    viewAssessments,
    startingAfter,
  } = props;

  const [defaultSortBy, setDefaultSortBy] = useState("assessment");
  const [defaultSortOrder, setDefaultSortOrder] = useState(false);

  const [showAscDecIcon, setShowAscDecIcon] = useState(false);
  const [ascOrDes, setAscOrDes] = useState(true);

  const onButtonClick = (title, id, currentStep) => {
    title !== ""
      ? props.setAssessmentName(title)
      : props.setAssessmentName(
          `Assessment - ${id.replace(/\w(?=\w{4})/g, "")}`
        );
    title !== ""
      ? props.onClickHandler(title)
      : props.onClickHandler(`Assessment - ${id.replace(/\w(?=\w{4})/g, "")}`);
    title !== ""
      ? props.setPreviousTitle(title)
      : props.setPreviousTitle(
          `Assessment - ${id.replace(/\w(?=\w{4})/g, "")}`
        );
    props.viewActivityLog(props.token, id);
    props.setCurrentAssessmentID(id);
    props.setCurrentStep(currentStep);
  };
  // const { result: assessments } = props.assessments;

  const sortData = (sortBy, sortOrder, rows) => {
    let itemsToSort = [...(rows || props.assessments)];
    let sortedItems = [];
    let compareFn = null;
    switch (sortBy) {
      case "assessment":
        compareFn = (i, j) => {
          if (i.assessment_name < j.assessment_name) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.assessment_name > j.assessment_name) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      case "lasstModified":
        compareFn = (i, j) => {
          if (i.modified_on < j.modified_on) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.modified_on > j.modified_on) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      default:
        break;
    }
    sortedItems = itemsToSort.sort(compareFn);
    return sortedItems;
  };

  const [assessmentData, setAssessmentData] = useState(
    sortData(defaultSortBy, defaultSortOrder, props.assessments)
  );

  const requestSort = (pSortBy) => {
    let sortBy = defaultSortBy;
    let sortOrder = defaultSortOrder;
    if (pSortBy === defaultSortBy) {
      sortOrder = sortOrder === "asc" ? "desc" : "asc";
    } else {
      sortBy = pSortBy;
      sortOrder = "asc";
    }
    let sortedItems = sortData(sortBy, sortOrder);
    setDefaultSortBy(sortBy);
    setDefaultSortOrder(sortOrder);
    setAssessmentData(sortedItems);
  };
  const handleSorting = () => {
    setShowAscDecIcon(!showAscDecIcon);
    setAscOrDes(!ascOrDes);
    viewAssessments(
      token,
      limit,
      startingAfter,
      surveyListing,
      currentOrganisation,
      surveyListing.keyword,
      ascOrDes
    );
  };

  useEffect(() => {
    setAssessmentData(
      sortData(defaultSortBy, defaultSortOrder, props.assessments)
    );
  }, [props.assessments]);

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        style={{ marginLeft: "1rem" }}
      >
        <Grid
          item
          xs={10}
          style={{ marginTop: 40 }}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: props.open,
          })}
        >
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Recent documents</TableCell>
                  <TableCell
                    onClick={() => handleSorting()}
                    style={{ cursor: "pointer" }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography
                        style={{
                          fontSize: "0.875rem",
                          fontWeight: "500",
                          paddingRight: "8px",
                        }}
                      >
                        Last Modified
                      </Typography>
                      {showAscDecIcon ? (
                        <ArrowUpwardIcon
                          style={{
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          style={{
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>Edited by</TableCell>
                  <TableCell>Reporting Status</TableCell>
                  <TableCell>Info</TableCell>
                </TableRow>
              </TableHead>

              <TableBody data-tut="reactour__table_row">
                {props.assessments.map((s, index) => {
                  let currentDate = new Date();
                  let myDate = new Date(s.modified_on);
                  let createdDate =
                    myDate.getDate() +
                    "." +
                    (myDate.getMonth() + 1) +
                    "." +
                    myDate.getFullYear();
                  let diff = Math.abs(
                    new Date(moment(currentDate).format("MM/DD/YYYY")) -
                      new Date(moment(myDate).format("MM/DD/YYYY"))
                  );
                  let diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
                  let assignmentIdName = `Assessment - ${s._id.replace(
                    /\w(?=\w{4})/g,
                    ""
                  )}`;
                  return (
                    <TableRow key={index} className={classes.assignment_row}>
                      <TableCell style={{ padding: "0px" }}>
                        <Link
                          to={{
                            pathname: "/sustainability_report",
                            state: { assessment_id: s._id },
                          }}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            cursor: "pointer",
                          }}
                        >
                          <ListItem>
                            <Icon fontSize="small">feed</Icon>
                            <Typography
                              variant="h6"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                                fontSize: 14,
                              }}
                            >
                              {/* Assessment - {s._id.replace(/\w(?=\w{4})/g, "")} */}
                              {s.assessment_name === ""
                                ? assignmentIdName
                                : s.assessment_name}
                            </Typography>
                          </ListItem>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{
                            pathname: "/sustainability_report",
                            state: { assessment_id: s._id },
                          }}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            cursor: "pointer",
                          }}
                        >
                          {s.modified_on !== ""
                            ? createdDate + " - " + diffDays + " day(s) ago"
                            : "NA"}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{
                            pathname: "/sustainability_report",
                            state: { assessment_id: s._id },
                          }}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            cursor: "pointer",
                          }}
                        >
                          {s.Edited_by.length > 0 && s.Edited_by[0].name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{
                            pathname: "/sustainability_report",
                            state: { assessment_id: s._id },
                          }}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            cursor: "pointer",
                          }}
                        >
                          <CustomChip
                            variant="outlined"
                            label={!s.completed ? "In Progress" : "Complete"}
                            color={!s.completed ? "secondary" : "primary"}
                            avatar={
                              <Avatar
                                style={{
                                  color: !s.completed ? "#FFC250" : "#32A05F",
                                  backgroundColor: !s.completed
                                    ? "#FFC250"
                                    : "#32A05F",
                                  height: "12px",
                                  width: "12px",
                                  marginLeft: "10px",
                                }}
                              ></Avatar>
                            }
                          />
                        </Link>
                      </TableCell>
                      <TableCell
                        style={{ padding: "0px" }}
                        data-tut="reactour__three_dots"
                      >
                        <CustomButton
                          onClick={() => {
                            onButtonClick(
                              s.assessment_name,
                              s._id,
                              s.current_step
                            );
                          }}
                          style={{ backgroundColor: "white" }}
                        >
                          <Icon fontSize="small">more_vert</Icon>
                        </CustomButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
