import React from "react";
import { connect } from "react-redux";
import DataHubScreen from "../../components/screen/dataHub/dataHubScreen";
import {
  addTags,
  getTags,
  uploadImportFile,
  getImportFileList,
  deleteTags,
  getImportFileData,
  getMatatikaJobStatus,
  restartMatatikaJob,
} from "../../actions/datahubActions/datahubActions";

export class DataHubCont extends React.PureComponent {
  componentDidMount = () => {
    document.body.style.overflow = "scroll";
  };
  render() {
    return <DataHubScreen {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    login: store.login,
    dataHub: store.dataHub,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    addTags: (token, organisation_id, tag_type, tag_name) => {
      dispatch(addTags(token, organisation_id, tag_type, tag_name));
    },

    getTags: (token, organisation_id) => {
      dispatch(getTags(token, organisation_id));
    },
    deleteTags: (token, organisation_id, tag_type, tag_id) => {
      dispatch(deleteTags(token, organisation_id, tag_type, tag_id));
    },
    getImportFileList: (token, organisation_id, tab_name) => {
      dispatch(getImportFileList(token, organisation_id, tab_name));
    },
    getImportFileData: (token, file_id) => {
      dispatch(getImportFileData(token, file_id));
    },

    uploadImportFile: (
      token,
      organisation_id,
      import_template_name,
      tab_name,
      import_file,
      data_stream_name,
      data_stream_description,
      data_duration,
      functional_tag,
      location_tag,
      value_chain_tag,
      selectedFrameworkId
    ) => {
      dispatch(
        uploadImportFile(
          token,
          organisation_id,
          import_template_name,
          tab_name,
          import_file,
          data_stream_name,
          data_stream_description,
          data_duration,
          functional_tag,
          location_tag,
          value_chain_tag,
          selectedFrameworkId
        )
      );
    },
    getMatatikaJobStatus: (token, datastream_id) => {
      dispatch(getMatatikaJobStatus(token, datastream_id));
    },
    restartMatatikaJob: (token, datastream_id, organisation_id, tab_name) => {
      dispatch(
        restartMatatikaJob(token, datastream_id, organisation_id, tab_name)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataHubCont);
