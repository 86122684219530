import { useState } from "react";

import Box from "@material-ui/core/Box";

import { TaskHeader, TaskMain } from "../../components/screen/taskScreen";

const TaskContainer = () => {
  const [searchKeyword, setSearchKeyword] = useState();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      style={{ padding: "0 20px", gap: 30 }}
    >
      {/* Dynamic Header */}
      <TaskHeader setSearchKeyword={setSearchKeyword} />
      {/* Main Section */}
      <TaskMain searchKeyword={searchKeyword} />
    </Box>
  );
};
export default TaskContainer;
