import StarBorderIcon from '@material-ui/icons/StarBorder';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment';

export const sortList = [
  { icon: <StarBorderIcon />, text: 'Show all' },
  { icon: <ScheduleIcon />, text: 'Awaiting Approval' },
  { icon: <DoubleArrowIcon />, text: 'Yet to be Assigned' },
  { icon: <DoubleArrowIcon />, text: 'Metrics In Progress' },
  { icon: <DoubleArrowIcon />, text: 'Approved' },
  { icon: <DoneIcon />, text: 'Completed' },
];

export const userCategoryList = [
  'Show all',
  'Employees',
  'Board of Directors',
  'Consultants',
  'Executive Team',
  'Suppliers & Vendors',
];

export const assignDisclosure = [
  'View All Disclosures',
  'Selected Disclosures',
];

export const getTimeStamp = (timeStamp) => {
  var FIVE_MINUTES = 5 * 60 * 1000;
  var duration = Date.now() - timeStamp;

  if (duration < FIVE_MINUTES) {
    return 'Just Now';
  } else {
    return moment(timeStamp).format('lll');
  }
};
