import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import AddIcon from "@material-ui/icons/Add";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import trash from "../../../images/trash.png";
import editBlue from "../../../images/editBlue.png";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "./../../UI/button/button";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { deleteTags } from "../../../actions/datahubActions/datahubActions";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    right: "1%",
    top: "32%",
  },
  dialogLabel: {
    position: "relative",
    left: "26%",
    top: "7%",
    background: "#FFFFFF",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    width: " 500px",
    height: " 600px",
  },
});
const DataHubSurveyLabelDialog = (props) => {
  const classes = useStyles();
  const {
    handleLabelDialogClose,
    isLabelDialogOpen,
    tagType,
    token,
    organization,
    addTags,
    functionalTags,
    locationTags,
    valueChainTags,
    functional,
    location,
    valueChain,
    deleteTags,
  } = props;
  const [labelName, setLabelName] = React.useState("");

  const [labelIndex, setLabelIndex] = React.useState("");

  const [selectedLabel, setSelectedLabel] = React.useState([]);

  const defaultThemeBackground =
    tagType === functional
      ? "#EBF1F8"
      : tagType === location
      ? "#EBF6EF"
      : "#FFF9EE";
  const selectedThemeBackground =
    tagType === functional
      ? "#3374B9"
      : tagType === location
      ? "#32A05F"
      : "#FFC250";
  const defaultIconTheme =
    tagType === functional
      ? "#3374B9"
      : tagType === location
      ? "#32A05F"
      : "#FFC250";

  let mapCondition = [];
  if (tagType === functional) {
    mapCondition = functionalTags;
  } else if (tagType === location) {
    mapCondition = locationTags;
  } else {
    mapCondition = valueChainTags;
  }

  const themeCondition = (id) => {
    return selectedLabel.some((selectLabel) => id === selectLabel.tag_id);
  };

  const handleAddLabel = () => {
    if (tagType === functional) {
      addTags(token, organization._id, functional, labelName);
    } else if (tagType === location) {
      addTags(token, organization._id, location, labelName);
    } else {
      addTags(token, organization._id, valueChain, labelName);
    }

    setLabelName("");
  };
  const handleSelectedLabel = (label) => {
    setSelectedLabel((preValue) => [...preValue, label]);
  };

  const handleUnselectLabels = (value, selectedLabel, tagType) => {
    handleLabelDialogClose(value, selectedLabel, tagType);
    setSelectedLabel([]);
    setLabelName("");
    setLabelIndex("");
  };
  return (
    <>
      <Dialog
        maxWidth="lg"
        open={isLabelDialogOpen}
        classes={{
          paper: classes.dialogLabel,
        }}
      >
        <DialogContent style={{ overflow: "hidden" }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "16px",
              textTransform: "capitalize",
              color: "#154328",
              // textAlign: "center",
              display: "flex",
              marginLeft: "35%",
            }}
          >
            Add/Create Labels
            <CloseIcon
              onClick={() => handleUnselectLabels(false, [], tagType)}
              style={{
                color: "#3374B9",
                cursor: "pointer",
                marginLeft: "auto",
              }}
            />
          </Typography>
          <hr
            style={{
              width: "185px",
              borderBottom: "2px solid #32A05F",
              background: "#32A05F",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              id="outlined-basic"
              margin="normal"
              label="Label Name"
              variant="outlined"
              size="normal"
              value={labelName.trimStart()}
              style={{
                borderRadius: " 5px",
                background: " #FFFFFF",
                width: "85%",
                // marginLeft: "2%",
              }}
              onChange={(e) => setLabelName(e.target.value)}
            />
            <CustomButton
              color="primary"
              variant="contained"
              onClick={() => handleAddLabel()}
              disabled={labelName === "" ? true : false}
            >
              <AddIcon />
            </CustomButton>
          </div>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              textTransform: "capitalize",
              color: "#76A2D0",
              linehEight: "20px",
              // textAlign: "center",
            }}
          >
            Create each individual tags as per its functions (Eg) Functional,
            location or value chain
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "16px",
              textTransform: "capitalize",
              color: "#242424",
              linehEight: "20px",
              marginTop: "10px",
              // textAlign: "center",
            }}
          >
            Previously used tags
          </Typography>
          <div
            style={{
              overflowY: "scroll",
              height: "65%",
            }}
          >
            {mapCondition.length > 0 &&
              mapCondition.map((data, index) => {
                return (
                  <div
                    style={{ display: "flex", marginTop: "15px" }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "16px",
                        width: "78%",
                        hight: "56%",
                        background: themeCondition(data.tag_id)
                          ? selectedThemeBackground
                          : defaultThemeBackground,
                        border: "1px solid #9A9A9A",
                        borderRadius: "8px",
                        // justifyContent: "space-evenly",
                      }}
                      onMouseEnter={() =>
                        !themeCondition(data.tag_id) &&
                        setLabelIndex(data.tag_id)
                      }
                      onMouseLeave={() =>
                        !themeCondition(data.tag_id) && setLabelIndex("")
                      }
                    >
                      <Icon>
                        <Brightness1Icon
                          style={{
                            color: themeCondition(data.tag_id)
                              ? "#EBF1F8"
                              : defaultIconTheme,
                          }}
                        />
                      </Icon>
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "16px",
                          // lineHeight: "24px",
                          textAlign: "center",
                          textTransform: "capitalize",
                          color: themeCondition(data.tag_id)
                            ? "#EBF1F8"
                            : "#15314E",
                          marginLeft: "15px",
                        }}
                      >
                        {data.tag_name}
                      </Typography>
                      <Icon
                        style={{
                          marginLeft: "auto",
                          visibility:
                            data.tag_id === labelIndex &&
                            !themeCondition(data.tag_id)
                              ? "visible"
                              : "hidden",
                          transitionDelay: "0.2s",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          !themeCondition(data.tag_id) &&
                          handleSelectedLabel(data)
                        }
                      >
                        <AddCircleIcon
                          style={{
                            color: themeCondition(data.tag_id)
                              ? "#EBF1F8"
                              : defaultIconTheme,
                          }}
                        />
                      </Icon>
                    </div>
                    <Button>
                      <Icon component={"img"} src={editBlue} />
                    </Button>
                    <Button>
                      <Icon
                        component={"img"}
                        src={trash}
                        onClick={() =>
                          deleteTags(
                            token,
                            organization._id,
                            tagType,
                            data.tag_id
                          )
                        }
                      />
                    </Button>
                  </div>
                );
              })}
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <CustomButton
            variant="contained"
            color="primary"
            style={{
              padding: "8px 16px",
              borderRadius: "8px",
              width: "238px",
              height: "40px",
            }}
            onClick={() => handleUnselectLabels(false, selectedLabel, tagType)}
            disabled={selectedLabel.length === 0 ? true : false}
          >
            Add Labels
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const mapStateToProps = (store) => {
  return {};
};

export const mapDispatchToProps = (dispatch) => {
  return {
    deleteTags: (token, organisation_id, tag_type, tag_id) => {
      dispatch(deleteTags(token, organisation_id, tag_type, tag_id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataHubSurveyLabelDialog);

// export default DataHubSurveyLabelDialog;
