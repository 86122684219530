import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import { DialogContent, MenuItem, Select } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Compact } from "@uiw/react-color";
import CloseIcon from "@material-ui/icons/Close";
import useDebounce from "../../../../../customHooks/useDebouncec";
import { CustomDataInput } from "./SelectChartType";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const [value, setValue] = React.useState({
    pointRadius: 15,
    tension: 0.3,
    pointHoverRadius: 10,
    borderWidth: 6,
    pointStyle: "rectRounded",
  });
  // console.log(
  //   "🚀 ~ file: barChartCustomization.js:36 ~ SimpleDialog ~ value",
  //   value
  // );

  const handleChange = (e) => {
    const fieldName = e.target.name;
    setValue((preValue) => ({
      ...preValue,
      [e.target.name]:
        fieldName === "pointStyle" ? e.target.value : Number(e.target.value),
    }));
  };

  useDebounce(
    () => {
      props.setLineChartCustomize(value);
    },
    2000,
    [value]
  );

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const pointOption = [
    "circle",
    "cross",
    "crossRot",
    "dash",
    "line",
    "rect",
    "rectRounded",
    "rectRot",
    "star",
    "triangle",
  ];

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="simple-dialog-title">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ fontWeight: 600, fontSize: 18 }}>
            {" "}
            Customize Line Chart
          </Typography>
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Line Point Radius"
            variant="outlined"
            name="pointRadius"
            value={value.pointRadius}
            onChange={handleChange}
          />
        </form>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Line Tension"
            variant="outlined"
            name="tension"
            value={value.tension}
            onChange={handleChange}
          />
        </form>

        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Line Point Hover Radius"
            variant="outlined"
            name="pointHoverRadius"
            value={value.pointHoverRadius}
            onChange={handleChange}
          />
        </form>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Line Width"
            variant="outlined"
            name="borderWidth"
            value={value.borderWidth}
            onChange={handleChange}
          />
        </form>
        <form
          className={classes.root}
          style={{ width: "25%" }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Set Bar Border Radius"
            variant="outlined"
            name="pointStyle"
            value={value.pointStyle}
            onChange={handleChange}
            select
            fullWidth
          >
            {pointOption.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </form>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function LineChartCustomization({ setLineChartCustomize }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Customize Line Chart
      </Button> */}
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        input={<CustomDataInput />}
        value={"Edit Line Chart"}
        defaultValue={"Edit Line Chart"}
        style={{ width: "100%" }}
      >
        <MenuItem value='Edit Line Chart' onClick={handleClickOpen}>Edit Line Chart</MenuItem>
      </Select>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        setLineChartCustomize={setLineChartCustomize}
      />
    </div>
  );
}
