import { useState } from "react";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

  const Accordions = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          paddingBottom="16px"
          paddingTop="16px"
          justifyContent="space-between"
        >
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              paddingLeft: "10px",
            }}
          >
            {title}
          </Typography>
          {isActive ? (
            <ExpandMoreIcon
              style={{ color: "#242424", opacity: "0.6", cursor: "pointer" }}
              onClick={() => setIsActive(!isActive)}
            />
          ) : (
            <ArrowForwardIosIcon
              style={{
                color: "#242424",
                opacity: "0.6",
                fontSize: 16,
                cursor: "pointer",
              }}
              onClick={() => setIsActive(!isActive)}
            />
          )}
        </Box>
        {isActive && (
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              paddingLeft: "10px",
            }}
          >
            {content}
          </Typography>
        )}
        <Divider />
      </>
    );
  };
  export default Accordions;
  