import React from "react";
import { connect } from "react-redux";
import Router from "../../components/router/router";
import {
  logOut,
  setLogOut,
  setCurrentOrganzation,
  setCurrentOrganisationUserType,
  setIsNewCompany,
  setSelectedOraganization,
  setSignupProcessNo,
  getCompanyLogo,
  setIsSubscriptionActive,
  setOrganizationCountNo,
} from "../../actions/login/loginActions";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";

import {
  viewAssessments,
  viewAssignedAssessments,
  getNotifications,
  setRedirectValue,
  setNotification,
  setTotalNotification,
  getOrganizations,
} from "../../actions/surveyListing/surveyListingAction";
class RouterCon extends React.PureComponent {
  render() {
    return <Router {...this.props} />;
  }
}

export const mapStateToProps = (store) => ({
  login: store.login,
  snackbar: store.snackbar,
  surveyListing: store.surveyListing,
});

export const mapDispatchToProps = (dispatch) => ({
  setOrganizationCount: (count) => {
    dispatch(setOrganizationCountNo(count));
  },
  getCompanyLogo: (domain) => {
    dispatch(getCompanyLogo(domain));
  },
  setSelectedOraganization: (payload) => {
    dispatch(setSelectedOraganization(payload));
  },
  setCurrentOrganzation: (payload) => {
    dispatch(setCurrentOrganzation(payload));
  },
  logOut: (payload) => {
    dispatch(logOut(payload));
  },
  setCurrentOrganisationUserType: (payload) => {
    dispatch(setCurrentOrganisationUserType(payload));
  },
  setLogOut: () => {
    dispatch(setLogOut());
  },
  setIsNewCompany: (payload) => {
    dispatch(setIsNewCompany(payload));
  },
  set_snack_bar: (status, response) => {
    dispatch(set_snack_bar(status, response));
  },
  viewAssessments: (token, surveyListing, current_organisation, keyword) => {
    dispatch(
      viewAssessments(token, surveyListing, current_organisation, keyword)
    );
  },
  viewAssignedAssessments: (
    token,
    surveyListing,
    current_organisation,
    limit
  ) => {
    dispatch(
      viewAssignedAssessments(token, surveyListing, current_organisation, limit)
    );
  },
  getNotifications: (token, organisation_id, limit, starting_after) => {
    dispatch(getNotifications(token, organisation_id, limit, starting_after));
  },
  setNotification: (payload) => {
    dispatch(setNotification(payload));
  },
  setTotalNotification: (payload) => {
    dispatch(setTotalNotification(payload));
  },
  setRedirectValue: () => {
    dispatch(setRedirectValue());
  },
  setSignupProcessNo: (payload) => {
    dispatch(setSignupProcessNo(payload));
  },
  setIsSubscriptionActive: (payload) => {
    dispatch(setIsSubscriptionActive(payload));
  },
  getOrganizations: (token, hostname) => {
    dispatch(getOrganizations(token, hostname));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterCon);
