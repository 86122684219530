import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CustomButton from './../../UI/button/button';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import basicInformationLeftStyles from './basicInformationleftStyle';
import {
  AppBar,
  createTheme,
  MuiThemeProvider,
  Tab,
  Tabs,
} from '@material-ui/core';
import MaturityMatrixLeft from '../maturityMatrix/MaturityMatrixLeft';
import BasicInfoTabLeft from './basicInfoTabLeft';
import TabPanel from "../../UI/tabPannel/tabPannel";
import {tabTheme} from "../../UI/tabPannel/tabPannel";



export default function BasicInformationLeft(props) {
  const classes = basicInformationLeftStyles();
  const {
    basicInfo,
    login,
    assessment_id,
    viewAssignedMembers,
    setYearOfAssessment,
    setNewYearOfAssessment,
    getFrameworkDetails,
    getAllCountries,
    setBasicInfoTab,
    setBasicInfoRightTab,
    getMaturityMatrix,
    viewAllUsers,maturityMatrix,
    viewAssignedMatrixUsers,
    getInternalMaturityMatrix,
    viewFilteredFrameworks
  } = props;
  useEffect(() => {
    getFrameworkDetails(login.token);
    // getAllCountries(login.token);
    // props.basicInfo.country_id &&  viewFilteredFrameworks(login.token,props.basicInfo.country_id)
    getMaturityMatrix(login.token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

//  useEffect(()=>{
//    basicInfo.basicInfoTab===1 && viewAssignedMatrixUsers(login.token, assessment_id,login.current_organisation)
//   },[])
//to set the basic info tab and basic assessment details tab on right side to assessment details on unmount
  
  useEffect(() => {
    return () => {
      setBasicInfoRightTab(0);
      setBasicInfoTab(0);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection',
    },
  ]);

  const levelText = (score) => {
    if (score >= 0 && score <= 33) {
      return 'Beginer';
    } else if (score > 33 && score <= 66) {
      return 'Intermideate';
    } else if (score > 66 && score <= 100) {
      return 'Expert';
    }
  };
  const datePickerDialog = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent>
            <Typography>
              <DateRange
                onChange={(item) => {
                  setState([item.selection]);
                  setYearOfAssessment([item.selection]);
                }}
                ranges={state}
                months={2}
                direction="horizontal"
              />
            </Typography>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
            <CustomButton
              variant="outlined"
              color="primary"
              onClick={() => {
                setIsDatePickerDialogOpen(false);
              }}
            >
              Close
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: '6px 20px' }}
              onClick={() => setIsDatePickerDialogOpen(false)}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleChange = (event, newValue) => {
    setBasicInfoTab(newValue);
  };

  return (
    <>
      {datePickerDialog()}
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__basicLeft"
      >
        <CardContent className={classes.cardContent}>
          <MuiThemeProvider theme={tabTheme}>
            <AppBar
              position="static"
              color="inherit"
              className={classes.AppBar}
            >
              <Tabs
                value={basicInfo.basicInfoTab}
                onChange={handleChange}
                // onChange={handleTabChange}
              >
                <Tab
                  disableRipple
                  className={classes.tab}
                  label={<span className={classes.tabLabel}>Basic Info</span>}
                />
                <Tab
                  disableRipple
                  className={classes.tab}
                  onClick={() => {
                    // viewAllUsers(login.token, login.current_organisation, '');
                    // viewAssignedMembers(login.token, assessment_id, 1);
                    getInternalMaturityMatrix(login.token, assessment_id);
                    viewAssignedMatrixUsers(login.token, assessment_id,login.current_organisation)
                  }}
                  label={
                    <span className={classes.tabLabel}>Maturity Matrix</span>
                  }
                />
              </Tabs>
            </AppBar>
          </MuiThemeProvider>
          {/* <div className="scroll" style={{ marginTop: 18 }}> */}
            <TabPanel value={basicInfo.basicInfoTab} index={0}>
              <BasicInfoTabLeft {...props} />
            </TabPanel>
            <TabPanel value={basicInfo.basicInfoTab} index={1}>
              <MaturityMatrixLeft {...props} />
            </TabPanel>
          {/* </div> */}
        </CardContent>
      </Card>
    </>
  );
}
