import Step1 from '../../images/step1.png';
import Step2 from '../../images/step2.png';
import Step3 from '../../images/step3.png';
import Step4 from '../../images/step4.png';
import Step5 from '../../images/step5.png';
import Step6 from '../../images/step6.png';

const videoOptions = [
    {
      videioId: '6ZZr9riOSls',
      title: 'Step 1 : Basic information',
      thumbnail: Step1
    },
    {
      videioId: 'osB_uNwxQ9o',
      title: 'Step 2 : materiality Assessment',
      thumbnail: Step2
    },
    {
      videioId: 'QIeBCp_d6GE',
      title: 'Step 3 : ESG Strategy',
      thumbnail: Step3
    },
    {
      videioId: '1yzOP0ntKE8',
      title: 'Step 4 : Assessment Report',
      thumbnail: Step4
    },
    {
      videioId: '1yzOP0ntKE8',
      title: 'Step 5 : Reporteing Data',
      thumbnail: Step5
    },
    {
      videioId: '1yzOP0ntKE8',
      title: 'Step 6 : Sustainability Report',
      thumbnail: Step6
    }
  ];

  export default videoOptions;