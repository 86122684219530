const dashboardItems = [
  {
    id: 100,
    name: "Total waste Recycled(in metric tonne) ",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Recycling.Recyled_value"],
        order: {
          "Recycling.Recyled_value": "desc",
        },
        dimensions: ["Recycling.Recycled_quantity"],
        timeDimensions: [],
        filters: [
          {
            member: "Recycling.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "bar",
    },
    layout: {
      x: 0,
      y: 16,
      w: 6,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Recycling.Recycled_quantity"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 1,
    name: "Percentage of Female Board of Directors",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Percentage_Female_BOD.femaleBOD_value"],
        order: {
          "Percentage_Female_BOD.femaleBOD_value": "desc",
        },
        dimensions: ["Percentage_Female_BOD.percentageoffemaleBOD"],
        timeDimensions: [],
        filters: [
          {
            member: "Percentage_Female_BOD.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "pie",
    },
    layout: {
      x: 0,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Percentage_Female_BOD.percentageoffemaleBOD"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 2,
    name: "Waste generated",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Waste.total_waste_generated"],
        order: {
          "Total_Waste.total_waste_generated": "desc",
        },
        dimensions: ["Total_Waste.total_waste"],
        timeDimensions: [],
        filters: [
          {
            member: "Total_Waste.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "bar",
    },
    layout: {
      x: 0,
      y: 0,
      w: 12,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Waste.total_waste"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 3,
    name: "Total Energy Generated",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Energy.Total_Energy"],
        order: {
          "Total_Energy.Total_Energy": "desc",
        },
        dimensions: ["Total_Energy.Energy"],
        timeDimensions: [],
        filters: [
          {
            member: "Total_Energy.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "pie",
    },
    layout: {
      x: 8,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Energy.Energy"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 4,
    name: "Emission Intensity",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Emission.Total_Emission"],
        order: {
          "Total_Emission.Total_Emission": "desc",
        },
        dimensions: ["Total_Emission.Emission"],
        timeDimensions: [],
        filters: [
          {
            member: "Total_Emission.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "bar",
    },
    layout: {
      x: 6,
      y: 16,
      w: 6,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Emission.Emission"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 5,
    name: "Total Energy Consumed (in joules or multiples)",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Energy_Consumption.Total_Energy_Consumption"],
        order: {
          "Total_Energy_Consumption.Total_Energy_Consumption": "desc",
        },
        dimensions: ["Total_Energy_Consumption.Energy_Consumption"],
        timeDimensions: [],
        filters: [
          {
            member: "Total_Energy_Consumption.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "pie",
    },
    layout: {
      x: 4,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Energy_Consumption.Energy_Consumption"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 6,
    name: "Rehabilitation and Resettlement",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Rehabilitated.Total_Rehabilitated"],
        order: {
          "Rehabilitated.Total_Rehabilitated": "desc",
        },
        dimensions: ["Rehabilitated.Rehabilitated"],
        timeDimensions: [],
        filters: [
          {
            member: "Rehabilitated.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "pie",
    },
    layout: {
      x: 0,
      y: 24,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Rehabilitated.Rehabilitated"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 7,
    name: "Minimum Wages Paid",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Average_Wages.Total_Wages"],
        order: {
          "Average_Wages.Total_Wages": "desc",
        },
        dimensions: ["Average_Wages.Wages"],
        timeDimensions: [],
        filters: [
          {
            member: "Average_Wages.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "bar",
    },
    layout: {
      x: 4,
      y: 24,
      w: 8,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Average_Wages.Wages"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 8,
    name: "Total Waste Genrated",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Waste_Generated_for_recycling.Total_Waste_Generated"],
        order: {
          "Total_Waste_Generated_for_recycling.Total_Waste_Generated": "desc",
        },
        dimensions: ["Total_Waste_Generated_for_recycling.Waste_Generated"],
        timeDimensions: [],
        filters: [
          {
            member: "Total_Waste_Generated_for_recycling.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "bar",
    },
    layout: {
      x: 0,
      y: 32,
      w: 12,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Waste_Generated_for_recycling.Waste_Generated"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
];

const dashboardItemsGri = [
  {
    id: 100,
    name: "Total Energy Consumptions",
    vizState: {
      query: {
        limit: 5000,
        measures: [
          "GriDashboard_energy.total_measure1",
          "GriDashboard_energy.total_measure2",
        ],
        order: {
          // "GriDashboard_energy.total_energy": "desc",
        },
        dimensions: [
          "GriDashboard_energy.total_number1",
          "GriDashboard_energy.total_number2",
        ],
        timeDimensions: [],
        filters: [
          {
            member: "GriDashboard_energy.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "area",
    },
    layout: {
      x: 0,
      y: 16,
      w: 6,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      y: [
        "GriDashboard_energy.total_number1",
        "GriDashboard_energy.total_number2",
      ],
      x: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 1,
    name: "Product specification",
    vizState: {
      query: {
        limit: 5000,
        measures: [
          "GriDashboard_businessModel.total_measure1",
          "GriDashboard_businessModel.total_measure2",
        ],
        order: {},
        dimensions: [
          "GriDashboard_businessModel.total_number1",
          "GriDashboard_businessModel.total_number2",
        ],
        timeDimensions: [],
        filters: [
          {
            member: "GriDashboard_businessModel.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "pie",
    },
    layout: {
      x: 0,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["measures"],
      y: [
        "GriDashboard_businessModel.total_number1",
        "GriDashboard_businessModel.total_number2",
      ],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 2,
    name: "GHG Emissions",
    vizState: {
      query: {
        limit: 5000,
        measures: [
          "GriDashboard_ghgEmission.total_measure1",
          "GriDashboard_ghgEmission.total_measure2",
          "GriDashboard_ghgEmission.total_measure3",
        ],
        order: {
          // "GriDashboard_ghgEmission.total_measure1": "desc",
        },
        dimensions: [
          "GriDashboard_ghgEmission.total_number1",
          "GriDashboard_ghgEmission.total_number2",
          "GriDashboard_ghgEmission.total_number3",
        ],
        timeDimensions: [],
        filters: [
          {
            member: "GriDashboard_ghgEmission.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "bar",
    },
    layout: {
      x: 6,
      y: 16,
      w: 6,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["measures"],
      y: [
        "GriDashboard_ghgEmission.total_number1",
        "GriDashboard_ghgEmission.total_number2",
        "GriDashboard_ghgEmission.total_number3",
      ],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 3,
    name: "Company Economic Performance",
    vizState: {
      query: {
        limit: 5000,
        measures: [
          "GriDashboard_economicPerformance.total_measure1",
          "GriDashboard_economicPerformance.total_measure2",
          "GriDashboard_economicPerformance.total_measure3",
        ],
        order: {
          // "Total_Energy.Total_Energy": "desc",
        },
        dimensions: [
          "GriDashboard_economicPerformance.total_number1",
          "GriDashboard_economicPerformance.total_number2",
          "GriDashboard_economicPerformance.total_number3",
        ],
        timeDimensions: [],
        filters: [
          {
            member: "GriDashboard_economicPerformance.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "pie",
    },
    layout: {
      x: 8,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["measures"],
      y: [
        "GriDashboard_economicPerformance.total_number1",
        "GriDashboard_economicPerformance.total_number2",
        "GriDashboard_economicPerformance.total_number3",
      ],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 4,
    name: "Employee Diversity",
    vizState: {
      query: {
        limit: 5000,
        measures: [
          "GriDashboard_employeeDiversity.total_measure1",
          "GriDashboard_employeeDiversity.total_measure2",
          "GriDashboard_employeeDiversity.total_measure3",
          "GriDashboard_employeeDiversity.total_measure4",
          "GriDashboard_employeeDiversity.total_measure5",
          "GriDashboard_employeeDiversity.total_measure6",
          "GriDashboard_employeeDiversity.total_measure7",
        ],
        order: {
          // "Total_Emission.Total_Emission": "desc",
        },
        dimensions: [
          "GriDashboard_employeeDiversity.total_number1",
          "GriDashboard_employeeDiversity.total_number2",
          "GriDashboard_employeeDiversity.total_number3",
          "GriDashboard_employeeDiversity.total_number4",
          "GriDashboard_employeeDiversity.total_number5",
          "GriDashboard_employeeDiversity.total_number6",
          "GriDashboard_employeeDiversity.total_number7",
        ],
        timeDimensions: [],
        filters: [
          {
            member: "GriDashboard_employeeDiversity.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "bar",
    },
    layout: {
      x: 0,
      y: 0,
      w: 12,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      y: [
        "GriDashboard_employeeDiversity.total_number1",
        "GriDashboard_employeeDiversity.total_number2",
        "GriDashboard_employeeDiversity.total_number3",
        "GriDashboard_employeeDiversity.total_number4",
        "GriDashboard_employeeDiversity.total_number5",
        "GriDashboard_employeeDiversity.total_number6",
        "GriDashboard_employeeDiversity.total_number7",
      ],
      x: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 5,
    name: "Training Awareness",
    vizState: {
      query: {
        limit: 5000,
        measures: [
          "GriDashboard_trainingAwareness.total_measure1",
          "GriDashboard_trainingAwareness.total_measure2",
        ],
        order: {
          // "Total_Energy_Consumption.Total_Energy_Consumption": "desc",
        },
        dimensions: [
          "GriDashboard_trainingAwareness.total_number1",
          "GriDashboard_trainingAwareness.total_number2",
        ],
        timeDimensions: [],
        filters: [
          {
            member: "GriDashboard_trainingAwareness.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "pie",
    },
    layout: {
      x: 4,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      y: [
        "GriDashboard_trainingAwareness.total_number1",
        "GriDashboard_trainingAwareness.total_number2",
      ],
      x: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
  {
    id: 8,
    name: "Water Discharge",
    vizState: {
      query: {
        limit: 5000,
        measures: [
          "GriDashboard_water.total_measure1",
          "GriDashboard_water.total_measure2",
          "GriDashboard_water.total_measure3",
          "GriDashboard_water.total_measure4",
        ],
        order: {
          // "Total_Waste_Generated_for_recycling.Total_Waste_Generated": "desc",
        },
        dimensions: [
          "GriDashboard_water.total_number1",
          "GriDashboard_water.total_number2",
          "GriDashboard_water.total_number3",
          "GriDashboard_water.total_number4",
        ],
        timeDimensions: [],
        filters: [
          {
            member: "GriDashboard_water.datastreamid",
            operator: "equals",
            values: [],
          },
        ],
      },
      chartType: "bar",
    },
    layout: {
      x: 0,
      y: 32,
      w: 12,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["measures"],
      y: [
        "GriDashboard_water.total_number1",
        "GriDashboard_water.total_number2",
        "GriDashboard_water.total_number3",
        "GriDashboard_water.total_number4",
      ],
      fillMissingDates: true,
      joinDateRange: false,
    },
  },
];

const cubeSchemaData = { BRSR: dashboardItems, GRI: dashboardItemsGri };

export const handleCubeSchema = (type, dataStream) => {
  const updatedSchema = cubeSchemaData[type].map((schema) => ({
    ...schema,
    vizState: {
      ...schema?.vizState,
      query: {
        ...schema?.vizState?.query,
        filters: [
          {
            member: schema?.vizState?.query?.filters[0]?.member,
            operator: schema?.vizState?.query?.filters[0]?.operator,
            values: [dataStream],
          },
        ],
      },
    },
  }));
  return updatedSchema;
};
