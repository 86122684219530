import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import SustainabilityTop from "../widgets/sustainabilityTop/sustainablilityTop";
import SustainabilityReportPDF from "../widgets/sustainabilityReport/sustainabilityReportPDF";

export default class SustainabilityReport extends React.PureComponent {
	
	componentDidMount = () => {

		this.props.getSustainabilityReport(
			this.props.login.token,
			this.props.assessment_id
		);
		document.body.style.overflow = "scroll";
	};

	render() {
		return (
			<Grid container>
				<Grid item xs={11}>
					<Box mt={2} mx={1}>
						<SustainabilityTop {...this.props} />
					</Box>
				</Grid>
				<Grid item xs={11}>
					<Box mt={2} mx={1}>
						<SustainabilityReportPDF {...this.props} />
					</Box>
				</Grid>
			</Grid>
		);
	}
}
