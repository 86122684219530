import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { FiTrash } from "react-icons/fi";

import CustomButton from "../../../UI/button/button";
import FileUploaded from "../../../../images/FileUploded.png";
import { useDispatch, useSelector } from "react-redux";
import { BlueBackgroundCard } from "components/widgets/surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionList";
import { rest_upload_va_file_from_ba } from "actions/supplier/esgDiagnosisActions";

const DataRequest = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // from redux
  const { selectedTaskAssignedSurvey } = useSelector(
    (state) => state.taskReducer
  );

  const { token, name } = useSelector((state) => state.login);


  // State to store file names and upload status
  const [fileNames, setFileNames] = useState(["", "", ""]);
  const [isFileUploaded, setIsFileUploaded] = useState([false, false, false]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [file, setFile] = useState("");
  const [sourceUrl, setSourceUrl] = useState("")

  const dataRequests = selectedTaskAssignedSurvey?.document_type;

  // Function to handle file upload
  const handleFileUpload = (index) => (event) => {
    const file = event.target.files[0];

    if (file) {
      setFile(file);
      const newFileNames = [...fileNames];
      newFileNames[index] = file.name;
      setFileNames(newFileNames);

      const newIsFileUploaded = [...isFileUploaded];
      newIsFileUploaded[index] = true;
      setIsFileUploaded(newIsFileUploaded);
    }
  };

  // Function to handle file delete
  const handleFileDelete = (index) => () => {
    const newFileNames = [...fileNames];
    newFileNames[index] = "";
    setFileNames(newFileNames);

    const newIsFileUploaded = [...isFileUploaded];
    newIsFileUploaded[index] = false;
    setIsFileUploaded(newIsFileUploaded);
  };

  return (
    <Box width={"80%"} height={"75vh"} style={{ position: "relative" }}>
      {!isSubmitted ? (
        <>
          {/* Header Box */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              style={{
                color: "#15314E",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Data Request
            </Typography>
            {/* <CustomButton
              variant={"contained"}
              color={"primary"}
              style={{ width: "10em", textTransform: "capitalize" }}
              onClick={() => setIsSubmitted(true)}
            >
              Submit
            </CustomButton> */}
          </Box>

          <BlueBackgroundCard
            heading={
              "Please ensure your files are correct before submission as redoing is not possible *"
            }
          />

          {/* Table Box */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type Of Document</TableCell>
                <TableCell>Financial Year</TableCell>
                <TableCell>Upload File/ URL</TableCell>
                <TableCell>Source URL</TableCell>
                <TableCell>File Name</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRequests?.map((e, index) => (
                <TableRow key={index}>
                  <TableCell>{e.category}</TableCell>
                  <TableCell>{e.year}</TableCell>
                  <TableCell>
                    {e.category === "XBRL" ? (
                      <TextField
                        variant="outlined"
                        size="small"
                        placeholder="XBRL URL"
                        onChange={e => setFile(e.target.value)}
                      />
                    ) : (
                      <>
                        <input
                          key={
                            isFileUploaded[index]
                              ? `file-upload-${index}-${fileNames[index]}`
                              : `file-upload-${index}`
                          }
                          type="file"
                          onChange={handleFileUpload(index)}
                          style={{ display: "none" }}
                          id={`file-upload-${index}`}
                        />
                        <label htmlFor={`file-upload-${index}`}>
                          <CustomButton
                            component="span"
                            variant="text"
                            color="primary"
                            style={{ textTransform: "capitalize" }}
                            disabled={isFileUploaded[index] ? true : false}
                          >
                            {isFileUploaded[index] ? "Uploaded" : "Upload"}
                          </CustomButton>
                        </label>
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Source URL"
                      onChange={e => setSourceUrl(e.target.value)}
                    />
                  </TableCell>
                  <TableCell>{fileNames[index] || "-"}</TableCell>
                  <TableCell>
                    {isFileUploaded[index] && (
                      <IconButton onClick={handleFileDelete(index)}>
                        <FiTrash color="#C45446" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <CustomButton
                      variant={"contained"}
                      color={"primary"}
                      style={{ width: "10em", textTransform: "capitalize" }}
                      onClick={() =>
                        dispatch(
                          rest_upload_va_file_from_ba(
                            token,
                            selectedTaskAssignedSurvey?.isin,
                            e.year,
                            file,
                            e.year.split("-")[0],
                            e.year.split("-")[1],
                            e.category,
                            selectedTaskAssignedSurvey?.organization_id,
                            sourceUrl,
                            name
                          )
                        )
                      }
                      disabled={file === "" || sourceUrl === ""}
                      // onClick={() => setIsSubmitted(true)}
                    >
                      Submit
                    </CustomButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          sx={{ gap: 10 }}
        >
          <img
            src={FileUploaded}
            alt="FileUploaded"
            style={{ aspectRatio: "1:1", width: "35%" }}
          />
          <Typography
            style={{
              color: "#0F0F0F",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            This Section has been Saved.
          </Typography>
          <Typography
            style={{
              color: "#0F0F0F",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            If you wish to redo the survey please use the redo button. Or go
            back to the task section.
          </Typography>

          <Box
            style={{
              position: "absolute",
              right: 30,
              bottom: 10,
              display: "flex",
              gap: 10,
            }}
          >
            <CustomButton
              variant="text"
              color="primary"
              style={{
                textTransform: "capitalize",
              }}
              onClick={() => setIsSubmitted(false)}
            >
              Redo Survey
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{
                textTransform: "capitalize",
              }}
              onClick={() => history.goBack()}
            >
              Go To Task Menu
            </CustomButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DataRequest;
