import React, { useCallback, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "../../UI/button/button";
import { makeStyles } from "@material-ui/core";
import Responses from "./responses";
import DataControl from "./dataControl";
import Files from "./files";
import Approvers from "./approvers";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#F6F6F6",
    borderRadius: 10,
    marginTop: 10,
    "&$selected": {
      backgroundColor: "#47BB76",
      "& .MuiListItemText-root": {
        color: "#FFFFFF",
      },
      "&:hover": {
        "& .MuiListItemText-root": {
          color: "#000000",
        },
        backgroundColor: "#c1daed8f",
        borderRadius: 10,
      },
    },
    "&:hover": {
      backgroundColor: "#c1daed8f",
      borderRadius: 10,
    },
  },
  selected: {
    backgroundColor: "#F6F6F6",
    borderRadius: 10,
  },
});

function DisclosureSetting(props) {
  const {
    setIsDisclosureSettingsOpen,
    getDisclosureSettingsResponses,
    login,
    assessment_id,
    metric,
    getDisclosureSettingsFiles,
    fieldData,
    updateSettings,
    getSettingsData,
  } = props;

  const classes = useStyles();
  const [listIndex, setListIndex] = React.useState(0);
  const [sortedApproversList, setSortedApproversList] = useState({});
  useEffect(() => {
    listIndex === 0 &&
      getDisclosureSettingsResponses(login.token, metric._id, assessment_id);
  }, [listIndex]);
  const [defaultDataControl, setDefaultDataControl] = useState("");
  const [defaultApprover, setDefaultApprover] = React.useState("");
  useEffect(() => {
    props.fieldData.disclosureSettingData &&
      setDefaultApprover(
        props.fieldData.disclosureSettingData.approvers.toString()
      );
    props.fieldData.disclosureSettingData &&
      setDefaultDataControl(
        props.fieldData.disclosureSettingData.data_control.toString()
      );
  }, []);

  const handleSetToDefault = () => {
    listIndex === 3 &&
      updateSettings(login.token, metric._id, assessment_id, true, 0);
    listIndex === 1 &&
      updateSettings(login.token, metric._id, assessment_id, false, 0);
  };

  const handleApplyChanges = () => {
    setSortedApproversList({});
    listIndex === 3 &&
      updateSettings(
        login.token,
        metric._id,
        assessment_id,
        true,
        Number(defaultApprover),
        sortedApproversList
      );
    listIndex === 1 &&
      updateSettings(
        login.token,
        metric._id,
        assessment_id,
        false,
        Number(defaultDataControl)
      );
  };

  return (
    <>
      <Box p={1}>
        <Grid container justifyContent="center">
          <Grid item xs={1}>
            <Typography variant="caption" color="textSecondary">
              Disclosure
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="caption" color="textSecondary">
              Description
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption" color="textSecondary">
              Status
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="caption" color="inherit">
              {metric.metric_name && metric.metric_name.split(":")[0]}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="caption" color="inherit">
              {metric.metric_name && metric.metric_name.split(":")[1]}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Grid container>
              <Grid item xs={8}>
                <Box
                  display="flex"
                  alignItems="center"
                  // justifyContent="space-between"
                  bgcolor={
                    metric.is_completed
                      ? "#EBF6EF"
                      : metric.is_approved
                      ? "#EBF6EF"
                      : metric.is_awaiting_approval
                      ? "#EBF6EF"
                      : metric.in_progress
                      ? "#EBF6EF"
                      : "#E9E9E9"
                  }
                  color={
                    metric.is_completed
                      ? "#5BB37F"
                      : metric.is_approved
                      ? "#5BB37F"
                      : metric.is_awaiting_approval
                      ? "#15314E"
                      : metric.in_progress
                      ? "#15314E"
                      : "#9FA1AF"
                  }
                  p={1}
                  borderRadius="8px"
                  maxHeight="32px"
                  maxWidth="167px"
                >
                  <Box
                    borderRadius="50%"
                    height={12}
                    width={12}
                    // bgcolor={
                    //   metric.is_assigned
                    //     ? metric.is_approved
                    //       ? '#5BB37F'
                    //       : '#EBD947'
                    //     : '#9FA1AF'
                    // }
                    bgcolor={
                      metric.is_completed
                        ? "#5BB37F"
                        : metric.is_approved
                        ? "#5BB37F"
                        : metric.is_awaiting_approval
                        ? "#EBD947"
                        : metric.in_progress
                        ? "#EBD947"
                        : "#9FA1AF"
                    }
                  />
                  <Box
                    pl={
                      metric.is_completed
                        ? 3
                        : metric.is_approved
                        ? 3
                        : metric.is_awaiting_approval
                        ? 1
                        : metric.in_progress
                        ? 3
                        : 1
                    }
                    fontSize="13px"
                  >
                    {metric.is_completed
                      ? "Completed"
                      : metric.is_approved
                      ? "Approved"
                      : metric.is_awaiting_approval
                      ? "Awaiting Approval"
                      : metric.in_progress
                      ? "In progress"
                      : "Yet to be assigned"}
                    {/* {metric.is_assigned
                      ? metric.is_approved
                        ? 'Approved'
                        : 'Awaiting Approval'
                      : 'Yet to be Assigned'} */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2} align="center">
                <IconButton size="small">{/* <MoreVertIcon /> */}</IconButton>
              </Grid>
              <Grid item xs={2} align="center">
                <IconButton
                  onClick={() => setIsDisclosureSettingsOpen(false)}
                  size="small"
                >
                  <ExitToAppIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Grid container>
        <Grid item xs={6}>
          <Box p={1}>
            <Box display="flex" alignItems="center">
              <Box>
                <IconButton
                  onClick={() => setIsDisclosureSettingsOpen(false)}
                  size="small"
                >
                  <ArrowBackIcon />
                </IconButton>
              </Box>
              <Box>
                <Typography variant="caption">Disclosure Settings</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          {(listIndex === 1 || listIndex === 3) && (
            <Box
              display="flex"
              alignItems="center"
              width="fit-content"
              ml="auto"
              mr={1}
            >
              <Box my={1} mr={1}>
                <Button
                  onClick={handleSetToDefault}
                  color="secondary"
                  variant="outlined"
                  size="small"
                >
                  Set to default
                </Button>
              </Box>
              <Box my={1}>
                <Button
                  onClick={handleApplyChanges}
                  disabled={
                    listIndex === 1
                      ? defaultDataControl ==
                        fieldData.disclosureSettingData.data_control
                      : listIndex === 3 &&
                        defaultApprover ==
                          fieldData.disclosureSettingData.approvers &&
                        !Object.keys(sortedApproversList).length
                  }
                  color="secondary"
                  variant="contained"
                  size="small"
                >
                  Apply changes
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Box m={2}>
            <List>
              <ListItem
                classes={{ root: classes.root, selected: classes.selected }}
                button
                selected={listIndex === 0}
                onClick={() => {
                  setListIndex(0);
                }}
              >
                <ListItemText primary="Responses" />
              </ListItem>
              <ListItem
                button
                classes={{ root: classes.root, selected: classes.selected }}
                selected={listIndex === 1}
                onClick={() => {
                  getSettingsData(login.token, metric._id, assessment_id);
                  setListIndex(1);
                }}
              >
                <ListItemText primary="Data Control" />
              </ListItem>
              <ListItem
                button
                classes={{ root: classes.root, selected: classes.selected }}
                selected={listIndex === 2}
                onClick={() => {
                  getDisclosureSettingsFiles(
                    login.token,
                    metric._id,
                    assessment_id
                  );

                  setListIndex(2);
                }}
              >
                <ListItemText primary="Files" />
              </ListItem>
              <ListItem
                button
                classes={{ root: classes.root, selected: classes.selected }}
                selected={listIndex === 3}
                onClick={() => {
                  getSettingsData(login.token, metric._id, assessment_id);
                  setListIndex(3);
                }}
              >
                <ListItemText primary="Approvers" />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={9}>
          {listIndex === 0 && <Responses {...props} />}
          {listIndex === 1 && (
            <DataControl
              setDefaultDataControl={setDefaultDataControl}
              defaultDataControl={defaultDataControl}
              {...props}
            />
          )}
          {listIndex === 2 && <Files {...props} />}
          {listIndex === 3 && (
            <Approvers
              setSortedApproversList={setSortedApproversList}
              defaultApprover={defaultApprover}
              setDefaultApprover={setDefaultApprover}
              {...props}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default DisclosureSetting;
