import React from "react";

import { Grid } from "@material-ui/core";

function StepLayOut({ left, right }) {
  return (
    <Grid
      container
      style={{ height: "68vh", padding: "10px" }}
      justifyContent="space-between"
    >
      <Grid item style={{ width: "30%", height: "100%" }}>
        {left}
      </Grid>
      <Grid item style={{ width: "68%", height: "100%" }}>
        {right}
      </Grid>
    </Grid>
  );
}
export default StepLayOut;
