import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import OtpInput from "react-otp-input";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import CustomTextfield from "../textfield/textfield";
import { Redirect } from "react-router-dom";
import {
  emailValidation,
  passwordValidation,
} from "../../../actions/regexValidation/regexValidation";
import CustomButton from "../button/button";
import freeTrial from "../../../images/freetrail.png";
import tickBox from "../../../images/tickbox.png";
import signupCardUseStyles from "./signupCardStyles";
import freeTrialFeatures from "../../../constants/signupCardConst/signupCardConst";
import clsx from "clsx";

function SignupCard(mainProps) {
  const { signupCard, token, signupStepNo } = mainProps.props.login;
  const {
    setSignupCard,
    setShowOnboarding,
    setSideMessage,
    signupWithEmail,
    verifyOtp,
    resendOtp,
    forgetPassword,
    login_with_email,
    setSignupStepNo,
  } = mainProps.props;

  const classes = signupCardUseStyles();
  const initialFormDetails = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const [redirect] = useState(false);
  const [formDetails, setFormDetails] = useState({ ...initialFormDetails });
  const [nameError, setNameError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [passwordError, setPasswordError] = useState(true);
  // const [value, setValue] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [code, setCode] = useState("");
  const [checked, setChecked] = useState(false);
  const [disableSendAgain, setDisableSendAgain] = useState(false);
  const [redirectToDetails, setRedirectToDetails] = useState(false);
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);

  const [isForgetPasswordDialog, setForgetPasswordDialog] = useState(false);

  const handleSubmit = () => {
    const { firstName, lastName, email, password } = formDetails;
    let name = firstName + " " + lastName;
    signupWithEmail(name, email, password);
  };

  const onFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      if (value === "") {
        setNameError(false);
      } else {
        setNameError(true);
      }
    }
    if (name === "lastName") {
      if (value === "") {
        setNameError(false);
      } else {
        setNameError(true);
      }
    }
    if (name === "email") {
      setEmailError(emailValidation(value));
    }
    if (name === "password") {
      setPasswordError(passwordValidation(value));
    }
    if (name === "confirmPassword") {
    }
    setFormDetails((formDetails) => ({
      ...formDetails,
      [name]: value,
    }));
  };

  React.useEffect(() => {
    setTimeout(() => {
      setSignupCard(true);
    }, 700);
  }, [setSignupCard]);

  useEffect(() => {
    if (signupStepNo === 1) {
      setSideMessage("otp");
    } else if (signupStepNo === 2) {
      setSideMessage("plan");
    }
  }, [signupStepNo]);
  //to check and redirect the user to otp page
  const localSignupProcessNo = JSON.parse(
    localStorage.getItem("signup_process_no")
  );

  useEffect(() => {
    if (localSignupProcessNo === 1) {
      setSignupStepNo(1);
    } else if (localSignupProcessNo === 2) {
      setSignupStepNo(2);
    }
  }, [localSignupProcessNo]);

  // if (redirect) {
  //   return <Redirect to="/tnc"></Redirect>;
  // }

  if (redirectToDetails) {
    return <Redirect to="/basicDetails"></Redirect>;
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleOtpChange = (code) => setCode(code);

  if (disableSendAgain) {
    setTimeout(() => {
      setDisableSendAgain(false);
    }, 1000 * 60);
  }

  const forgetPasswordDialog = () => {
    return (
      <Dialog
        open={isForgetPasswordDialog}
        onClose={() => setForgetPasswordDialog(false)}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle>
          Reset Password
          <Typography variant="body2">
            Please enter your email address to reset your password. We will send
            a reset link to your email address.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CustomTextfield
            variant="outlined"
            placeholder="Enter your Email"
            fullWidth
            onKeyPress={(e) =>
              e.key === "Enter" &&
              forgetPassword(email, window.location.hostname)
            }
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              forgetPassword(email, window.location.hostname);
              setForgetPasswordDialog(false);
              setEmail("");
            }}
            disabled={!emailValidation(email)}
          >
            reset password
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const isDisabled =
    formDetails.firstName !== "" &&
    formDetails.lastName !== "" &&
    formDetails.email !== "" &&
    emailError && passwordError &&
    formDetails.password !== "" &&
    confirmPassword !== "" &&
    checked === true &&
    formDetails.password === confirmPassword
      ? false
      : true;
  return (
    <>
      {isForgetPasswordDialog && forgetPasswordDialog()}

      <Slide
        direction="down"
        in={signupCard}
        mountOnEnter
        unmountOnExit
        timeout={700}
      >
        <Card variant="outlined">
          <CardContent className={classes.cardcontent}>
            <Box className={classes.cardBox}>
              <List className={classes.cardList} component="nav">
                {(signupStepNo === 0 || signupStepNo === 1) && (
                  <>
                    <ListItem
                      button
                      selected={selectedIndex === 0}
                      onClick={(event) => handleListItemClick(event, 0)}
                      classes={{
                        root: classes.rootTab,
                        selected: classes.selected,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            className={classes.listTypo}
                            style={{ fontWeight: selectedIndex === 0 && 600 }}
                          >
                            Signup
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      button
                      selected={selectedIndex === 1}
                      onClick={(event) => handleListItemClick(event, 1)}
                      classes={{
                        root: classes.rootTab,
                        selected: classes.selected,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            className={classes.listTypo1}
                            style={{ fontWeight: selectedIndex === 1 && 600 }}
                          >
                            Login
                          </Typography>
                        }
                      />
                    </ListItem>
                  </>
                )}
                {signupStepNo === 2 && (
                  <ListItem
                    button
                    selected
                    onClick={(event) => handleListItemClick(event, 1)}
                    classes={{
                      root: classes.rootTab,
                      selected: classes.selected,
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.listTypo2}>
                          Plan Selection
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
              </List>
            </Box>
            <Box paddingX="16px" paddingTop="80px" paddingBottom="18px">
              {signupStepNo === 0 && selectedIndex === 0 && (
                <>
                  <Typography className={classes.labelText}>
                    First name
                  </Typography>
                  <CustomTextfield
                    type="text"
                    name="firstName"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formDetails.firstName}
                    onChange={onFormChange}
                    className={classes.costumTextField}
                  />

                  <Typography className={classes.labelText}>
                    Last name
                  </Typography>
                  <CustomTextfield
                    type="text"
                    variant="outlined"
                    name="lastName"
                    size="small"
                    fullWidth
                    value={formDetails.lastName}
                    onChange={onFormChange}
                    className={classes.costumTextField}
                  />
                  <Typography className={classes.labelText}>Email</Typography>
                  <CustomTextfield
                    id="email"
                    name="email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="email"
                    value={formDetails.email}
                    error={!emailError}
                    helperText={
                      !emailError ? "Please enter a valid Email." : ""
                    }
                    onChange={onFormChange}
                    className={classes.costumTextField}
                  />
                  <Typography className={classes.labelText}>
                    Password
                  </Typography>
                  <CustomTextfield
                    id="password"
                    name="password"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    error={!passwordError}
                    helperText={
                      !passwordError ? "Please enter a valid password." : ""
                    }
                    value={formDetails.password}
                    onChange={onFormChange}
                    className={classes.costumTextField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          name="passSvg"
                          position="end"
                          className={classes.inputStyle}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <VisibilityIcon fontSize="small" color="disabled" />
                          ) : (
                            <VisibilityOffIcon
                              fontSize="small"
                              color="disabled"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography className={classes.labelText}>
                    Confirm Password
                  </Typography>
                  <CustomTextfield
                    id="confirmpassword"
                    name="confirmpassword"
                    variant="outlined"
                    size="small"
                    error={
                      confirmPassword !== "" &&
                      confirmPassword !== formDetails.password
                    }
                    helperText={
                      confirmPassword !== formDetails.password
                        ? "Password and confirm password should match."
                        : ""
                    }
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type={showConfirmPassword ? "text" : "password"}
                    className={classes.costumTextField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          name="cnfPassSvg"
                          position="end"
                          className={classes.inputStyle}
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <VisibilityIcon fontSize="small" color="disabled" />
                          ) : (
                            <VisibilityOffIcon
                              fontSize="small"
                              color="disabled"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingTop="62px"
                  >
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        name="signupCheckbox"
                        color="primary"
                        disableRipple
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                      />
                      <a href="/tnc" target="_blank" rel="noopener noreferrer">
                        <Typography className={classes.checkboxLabelText}>
                          Agree to the terms and Conditions
                        </Typography>
                      </a>
                    </Box>
                    <CustomButton
                      name="signupBtn"
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      className={classes.signupBtn}
                      disabled={isDisabled}
                      onClick={() => {
                        handleSubmit();
                        setSideMessage("otp");
                      }}
                    >
                      Signup
                    </CustomButton>
                  </Box>
                </>
              )}
              {signupStepNo === 1 && selectedIndex === 0 && (
                <>
                  <Box paddingLeft="37px" maxWidth="272px">
                    <Typography className={classes.codeTitle}>
                      Enter the 4 digit code sent to
                    </Typography>
                    <span className={classes.otpSpan}>
                      {formDetails.email
                        ? formDetails.email
                        : mainProps.props.login.email}
                    </span>
                    <Box paddingTop="56px" paddingBottom="24px">
                      <OtpInput
                        value={code}
                        onChange={handleOtpChange}
                        numInputs={4}
                        separator={
                          <span className={classes.otpSeperator}></span>
                        }
                        isInputNum={true}
                        shouldAutoFocus={true}
                        inputStyle={{
                          borderRadius: "4px",
                          border: "1px solid gray",
                          width: "56px",
                          height: "56px",
                          fontSize: "16px",
                          color: "#000",
                          fontWeight: "400",
                          caretColor: "blue",
                        }}
                        focusStyle={{
                          border: "1px solid #CFD3DB",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography className={classes.didntReceive}>
                        Didn't recieve ?
                      </Typography>
                      <CustomButton
                        color="primary"
                        disabled={disableSendAgain}
                        className={classes.sendAgain}
                        onClick={() => {
                          resendOtp(token);
                          setDisableSendAgain(true);
                        }}
                      >
                        Send again
                      </CustomButton>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    paddingTop="250px"
                  >
                    <CustomButton
                      name="continueBtn"
                      color="primary"
                      variant="contained"
                      size="small"
                      className={clsx(classes.signupBtn, classes.costumBtn)}
                      onClick={() => {
                        setSideMessage("plan");
                        verifyOtp(token, code);
                      }}
                    >
                      Continue
                    </CustomButton>
                  </Box>
                </>
              )}
              {signupStepNo === 2 && selectedIndex === 0 && (
                <Box paddingLeft="16px" paddingRight="16px">
                  <Box display="flex" alignItems="center">
                    <img src={freeTrial} alt="" />
                    <Typography className={classes.freetrial}>
                      Free Trial
                    </Typography>
                  </Box>
                  <Box paddingTop="64px">
                    {freeTrialFeatures.map((feature) => {
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          paddingBottom="40px"
                        >
                          <img src={tickBox} alt="" />
                          <Box paddingLeft="24px">
                            <Typography className={classes.featureTitle}>
                              {feature.title}
                            </Typography>
                            <Typography className={classes.featureSubTitle}>
                              {feature.subTitle}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <CustomButton
                      name="trialBtn"
                      color="primary"
                      variant="contained"
                      size="small"
                      className={clsx(classes.signupBtn, classes.costumBtn)}
                      onClick={() => {
                        setSignupCard(false);
                        setShowOnboarding(true);
                        setSideMessage("onboarding");
                        setRedirectToDetails(true);
                      }}
                    >
                      Start 15 day trial
                    </CustomButton>
                  </Box>
                </Box>
              )}
              {selectedIndex === 1 && (
                <Box>
                  <Typography className={classes.labelText} gutterBottom>
                    Enter Mail ID
                  </Typography>
                  <CustomTextfield
                    id="email"
                    name="email"
                    type="email"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    size="small"
                    className={classes.costumTextField}
                    error={!emailValidation(email)}
                    helperText={
                      !emailValidation(email) ? "Please enter a valid Email." : ""
                    }
                    value={email}
                    onKeyPress={(e) =>
                      e.key === "Enter" && login_with_email(email, password)
                    }
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Typography className={classes.labelText}>
                    Password
                  </Typography>
                  <CustomTextfield
                    id="password"
                    name="password"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    error={!passwordError}
                    helperText={
                      !passwordError ? "Please enter a valid password." : ""
                    }
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={classes.costumTextField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputStyle}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <VisibilityIcon fontSize="small" color="disabled" />
                          ) : (
                            <VisibilityOffIcon
                              fontSize="small"
                              color="disabled"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box>
                    <Button
                      color="primary"
                      onClick={() => setForgetPasswordDialog(true)}
                    >
                      Forget password?
                    </Button>
                  </Box>
                  <Box className={classes.btnBox}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="agreement"
                          color="primary"
                          disableRipple
                          id="agreement"
                          checked={keepMeSignedIn}
                          onChange={() => setKeepMeSignedIn(!keepMeSignedIn)}
                        />
                      }
                      label="Keep me signed in"
                      className={classes.labelCheckbox}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      disabled={email === "" || password === "" || !emailValidation(email)}
                      type="submit"
                      onClick={() => login_with_email(email, password)}
                    >
                      Login
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Slide>
    </>
  );
}
export default React.memo(SignupCard);
