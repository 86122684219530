import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import TextField from "../../UI/textfield/textfield";
import SustainabilityReportTopStyles from "./sustainablityReportTopStyles";

function SustainabilityReportTop(props) {
	const ref = useRef();
	const classes = SustainabilityReportTopStyles();
	const [access, setAccess] = useState(false);
	const [assessment_name, setAssessment] = React.useState(
		props.sustainabilityReport.basicData.sustainability_report_name
	);

	useEffect(() => {
		if (
			props.sustainabilityReport.basicData.sustainability_report_name !== ""
		) {
			setAssessment(
				props.sustainabilityReport.basicData.sustainability_report_name
			);
		} else {
			setAssessment("Untitled");
		}
	}, [props.sustainabilityReport.basicData.sustainability_report_name]);

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (access && ref.current && !ref.current.contains(e.target)) {
				handelUpdate();
			}
		};

		document.addEventListener("mousedown", checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [access]); // eslint-disable-line react-hooks/exhaustive-deps

	const handelUpdate = () => {
		props.updateReportName(
			props.login.token,
			props.assessment_id,
			6,
			assessment_name,
			props.sustainabilityReport.jwt_has
		);
		setAccess(false);
	};

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Box className={classes.subGrid}>
						<div className={classes.listDiv}>
							<Icon>list_alt</Icon>
						</div>
						{access === true ? (
							<>
								{assessment_name ===
								props.sustainabilityReport.sustainability_report_name ? (
									<Box
										display="flex"
										alignItems="center"
										ref={ref}
										className={classes.boxWidth}
									>
										<TextField
											placeholder="Assessment"
											value={assessment_name}
											size="small"
											variant="outlined"
											onChange={(event) => setAssessment(event.target.value)}
											fullwidth
											className={classes.boxWidth}
										/>
									</Box>
								) : (
									<Box
										display="flex"
										alignItems="center"
										onBlur={() => handelUpdate()}
										className={classes.boxWidth}
									>
										<TextField
											name="pdfName"
											placeholder="Assessment"
											value={assessment_name}
											size="small"
											variant="outlined"
											onChange={(event) => setAssessment(event.target.value)}
											fullwidth
											className={classes.boxWidth}
										/>
									</Box>
								)}
							</>
						) : (
							<Typography variant="h6" onClick={() => setAccess(true)}>
								{assessment_name}
							</Typography>
						)}
					</Box>
				</Grid>
			</Grid>
		</>
	);
}

export default SustainabilityReportTop;
