export const SET_ASSESSMENT_NAME = "SET_ASSESSMENT_NAME";
export const SET_YEAR_OF_ASSESSMENT = "SET_YEAR_OF_ASSESSMENT";
export const SET_NEW_YEAR_OF_ASSESSMENT = "SET_NEW_YEAR_OF_ASSESSMENT";
export const SET_SUSTAINIBILITY_TEAM_MESSAGE = "SET_SUSTAINIBILITY_TEAM_MESSAGE";
export const SET_ESG_GOAL = "SET_ESG_GOAL";
export const SET_ABOUT_REPORT = "SET_ABOUT_REPORT";
export const SET_DOCUMENT = "SET_DOCUMENT";

export const SET_DEFAULT = "SET_DEFAULT";
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_COMPANY_LOGO = "SET_COMPANY_LOGO";
export const SET_COMPANY_LOGO_URI = "SET_COMPANY_LOGO_URI";
export const SET_SECTORS = "SET_SECTORS";

export const SET_COMPANY_DESCRIPTION = "SET_COMPANY_DESCRIPTION";
export const SET_NUMBER_OF_EMPLOYEE = "SET_NUMBER_OF_EMPLOYEE";
export const SET_COMPANY_EMAIL = "SET_COMPANY_EMAIL";
export const SET_COMPANY_WEBSITE = "SET_COMPANY_WEBSITE";

export const SET_COMPANY_ADDRESS = "SET_COMPANY_ADDRESS";
export const SET_COMPANY_LANDMARK = "SET_COMPANY_LANDMARK";
export const SET_COMPANY_PINCODE = "SET_COMPANY_PINCODE";
export const SET_COMPANY_STATE = "SET_COMPANY_STATE";
export const SET_COMPANY_COUNTRY = "SET_COMPANY_COUNTRY";

export const SET_BRANCH_ADDRESS_BASIC = "SET_BRANCH_ADDRESS_BASIC";
export const SET_BRANCH_LANDMARK = "SET_BRANCH_LANDMARK";
export const SET_BRANCH_PINCODE = "SET_BRANCH_PINCODE";
export const SET_BRANCH_STATE = "SET_BRANCH_STATE";
export const SET_BRANCH_COUNTRY = "SET_BRANCH_COUNTRY";

export const SET_BRANCH_ADDRESS_ARRAY = "SET_BRANCH_ADDRESS_ARRAY";

export const UNSET_BRANCH_ADDRESS = "UNSET_BRANCH_ADDRESS";
export const SET_ASSESSMENT_DETAILS = "SET_ASSESSMENT_DETAILS";
export const SET_MASTER_SECTOR = "SET_MASTER_SECTOR";

export const SET_FRAMEWORKS = "SET_FRAMEWORKS";
export const SET_FRAMEWORK_ID = "SET_FRAMEWORK_ID";
export const SET_TEMPLATE_TYPE = "SET_TEMPLATE_TYPE";
export const SET_BASIC_INFO_RIGHT_TAB = "SET_BASIC_INFO_RIGHT_TAB";
export const SET_BASIC_INFO_TAB = "SET_BASIC_INFO_TAB";
export const SET_REPORTING_REGION = "SET_REPORTING_REGION";
export const SET_ALL_COUNTRIES = "SET_ALL_COUNTRIES";
