import { makeStyles } from '@material-ui/core';

const drawerWidth = 300;

const surveyListingUseStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    // zIndex: 0,
    marginTop: 60,
    display: 'flex',
    // alignItems: 'center',
    scrollbarWidth: 'none',
    paddingBottom: 100,
    overflowY:'hidden'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: '90%',
    marginLeft: 30,
    marginTop: 90
  },
  appBarShift: {
    justifyContent: 'flex-start',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerHeader: {
    backgroundColor: '#EBF1F8',
    textAlign: 'center',
    width: '100%',
    padding: '25px 0px 50px 10px',
    marginBottom: 37
  },
  tabdiv: {
    background:
      'linear-gradient(91.69deg, rgba(71, 187, 118, 0.1) -14.88%, rgba(51, 116, 185, 0.1) 123.69%)',
    padding: 10,
    width: '90%',
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  margin_padding: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20
    }
  },
  margin_padding1: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20
    },
    textAlign: 'left',
    marginLeft: 10
  },
  changeName: {
    background:
      'linear-gradient(91.69deg, rgba(71, 187, 118, 0.1) -14.88%, rgba(51, 116, 185, 0.1) 123.69%)',
    marginBottom: 10,
    padding: 10,
    color: '#000000',
    paddingLeft: 25,
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      fontSize: '13px',
      fontWeight: 600,
      textTransform: 'initial'
    }
  },
  drawerActions: {
    justifyContent: 'center',
    paddingTop: '22px',
    paddingBottom: '20px',
    '&.MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: '40px'
    }
  },
  dialogTitle: {
    '&.MuiDialogTitle-root': {
      padding: '16px 16px'
    }
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px'
  },
  checkboxCommentContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    paddingLeft: 30
  },

  checkboxLabel: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: '500',
    paddingLeft: '5px'
  },
  typography: {
    paddingBottom: 5,
    borderBottom: '3px solid #47BB76',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold'
  },
  typography1: { color: '#3374B9', fontSize: '14px', lineHeight: '21px' },
  typography2: {
    fontSize: '14px',
    lineHeight: '21px',
    marginLeft: '3px'
  },
  mainBox: { border: '1px solid #D9DADB', marginTop: '48px' },
  BasicCheckbox: {
    transform: 'scale(1.2)'
  },
  typography3: {
    fontSize: '21px',
    lineHeight: '32px',
    fontWeight: '600',
    paddingTop: '12px',
    paddingLeft: '32px'
  },
  closeIcon: { fontSize: 28, color: '#242424' },
  card: {
    borderRadius: 25,
    width: 380,
    height: 200,
    margin: '30px 10px 100px 10px '
  },
  costumButton: {
    textTransform: 'none',
    color: '#3374B9',
    fontFamily: 'Poppins',
    fontWeight: '400'
  },
  typography4: {
    fontSize: '21px',
    lineHeight: '32px',
    textTransform: 'capitalize',
    paddingTop: '30px',
    paddingBottom: '24px',
    paddingLeft: '10px'
  },
  drawerTypography: { fontSize: 14, fontWeight: 600 },
  esgTypo: { fontSize: 18 },
  SearchIcon: { color: '#3374B9' },
  AddnewGrid: { marginLeft: 30 },
  subGrid: { marginTop: 20 },
  mainGrid: { textAlign: 'center', marginTop: 78 },
  emptyDiv: {
    position: 'absolute',
    backgroundColor: '#F0F6FD',
    width: '94%',
    height: 290,
    bottom: 0,
    zIndex: -1
  },
  drawerDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  draweHeadTypo: {
    marginBottom: 20,
    fontSize: 14,
    fontWeight: 600,
    width: 160
  },
  closeIcon1: {
    width: 30,
    height: 20,
    marginRight: 0,
    position: 'absolute',
    right: 10,
    top: 20
  }
}));

export default surveyListingUseStyles;
