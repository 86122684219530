import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { setSelectedTopic } from "../../../../actions/datahubActions/datahubActions";

import getKpiData from "./kpidata";

import getKpiDataGRI from "./kpidataGRI";

function useKpiData() {
  const CURRENT_ORGANIZATION = localStorage.getItem("current_organisation");

  const dataHubStore = useSelector((state) => state.dataHub);

  const dispatch = useDispatch();

  const { selectedTopic, selectedKpiType } = dataHubStore;

  const [kpiCubeData, setKpiCubeData] = useState([]);

  const [displayedCharts, setDisplayedCharts] = useState(10);

  const KPI_DATA =
    selectedKpiType === "BRSR"
      ? getKpiData(CURRENT_ORGANIZATION)
      : getKpiDataGRI(CURRENT_ORGANIZATION);

  useEffect(() => {
    const handleKpiData = KPI_DATA[selectedTopic]
      ? KPI_DATA[selectedTopic]
      : [];
    setDisplayedCharts(10); // set initial number of charts to be displayed back to 10
    selectedTopic !== null && setKpiCubeData(handleKpiData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopic, selectedKpiType]);

  useEffect(() => {
    return () => {
      setKpiCubeData([]);
      dispatch(setSelectedTopic(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChartTimeFilter = (id, selectedTimeLine) => {
    const updateTimeFilter = kpiCubeData.map((d) =>
      d.name === id
        ? {
            ...d,
            vizState: {
              ...d.vizState,
              query: {
                ...d.vizState.query,
                timeDimensions: [
                  {
                    dimension: d.vizState.query.timeDimensions[0].dimension,
                    granularity: selectedTimeLine,
                  },
                ],
              },
            },
          }
        : d
    );

    setKpiCubeData(updateTimeFilter);
  };

  return {
    kpiCubeData,
    handleChartTimeFilter,
    displayedCharts,
    setDisplayedCharts,
  };
}

export default useKpiData;
