import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import Guidelines from "../reportListing/Guidelines";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ExpandableDropdown = ({ guidelines }) => {
  return (
    <div style={{ marginTop: 10 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontSize: 14,
              lineHeight: "24px",
              fontWeight: "500",
              color: "#3374B9",
              //   padding: "16px 0px",
            }}
          >
            Guidelines
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Guidelines guidelines={guidelines} isFrom={"expand"} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ExpandableDropdown;
