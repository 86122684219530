import React, { useEffect, useState } from "react";
import StepLayOut from "../stepLayout";
import Step2Left from "./left/Step2Left";
import Step2Right from "./right/Step2Right";
import { dummyData } from "../step1/right/Step1Right";
import { useDispatch, useSelector } from "react-redux";
import { setSection } from "../../../../../actions/supplier/supplierAction";
import {
  setQuestionWithStatus,
  setSelectedAdminSection,
} from "../../../../../actions/supplierAdmin/supplierAdminActions";
// import { response } from "../../../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
// const userList = [
//   {
//     id: 1,
//     name: "John Doe",
//     email: "john@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 2,
//     name: "Alice Smith",
//     email: "alice@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 3,
//     name: "Bob Johnson",
//     email: "bob@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 4,
//     name: "Emma Wilson",
//     email: "emma@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 5,
//     name: "Michael Brown",
//     email: "michael@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 6,
//     name: "Sara Miller",
//     email: "sara@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 7,
//     name: "Peter Davis",
//     email: "peter@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 8,
//     name: "Linda Moore",
//     email: "linda@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 9,
//     name: "Tom Wilson",
//     email: "tom@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 10,
//     name: "Jane Johnson",
//     email: "jane@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 11,
//     name: "Mark Smith",
//     email: "mark@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 12,
//     name: "Eva Davis",
//     email: "eva@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 13,
//     name: "Chris Brown",
//     email: "chris@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 14,
//     name: "Sophie Miller",
//     email: "sophie@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 15,
//     name: "Alex Moore",
//     email: "alex@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
// ];
function SurveyStep2({ response }) {
  const dispatch = useDispatch();
  const { members } = useSelector((state) => state?.brmReducer);
  const [contributors, setContributors] = useState([]);
  const [approvers, setApprover] = useState([]);
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState("yet to assign");
  useEffect(() => {
    const updated = members.map((user) => ({
      ...user,
      isCheck: false,
    }));
    setUsers(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDuplicateEntry = (selectedUsers, setEntry, entryArr) => {
    selectedUsers.forEach((selectedUser) => {
      // Check if the selected user is already in the contributors array
      const isUserInContributors = entryArr.some(
        (data) => data._id === selectedUser._id
      );

      // If the user is not already in the contributors array, add them
      if (!isUserInContributors) {
        setEntry((prevContributors) => [
          ...prevContributors,
          { ...selectedUser, isCheck: false },
        ]);
      } else {
      }
    });
  };

  const handleContributers = () => {
    const updateList = users.filter((data) => data?.isCheck);
    if (contributors.length > 0) {
      handleDuplicateEntry(updateList, setContributors, contributors);
    } else {
      setContributors([
        ...contributors,
        ...updateList.map((item) => ({
          ...item,
          isCheck: false,
        })),
      ]);
    }

    setUsers((preValue) =>
      preValue.map((item) => ({
        ...item,
        isCheck: false,
      }))
    );
  };

  const handleApprovers = () => {
    const updateList = users.filter((data) => data?.isCheck);
    if (approvers.length > 0) {
      handleDuplicateEntry(updateList, setApprover, approvers);
    } else {
      setApprover([
        ...approvers,
        ...updateList.map((item) => ({
          ...item,
          isCheck: false,
        })),
      ]);
    }

    setUsers((preValue) =>
      preValue.map((item) => ({
        ...item,
        isCheck: false,
      }))
    );
  };

  const handleDeleteContributers = (id) => {
    const updateList = contributors.filter((data) => data?._id !== id);
    setContributors(updateList);
  };

  const handleDeleteApprovers = (id) => {
    const updateList = approvers.filter((data) => data?._id !== id);
    setApprover(updateList);
  };

  const toggleSingleItemUser = (itemId) => {
    const updatedList = users.map((item) =>
      item._id === itemId ? { ...item, isCheck: !item.isCheck } : item
    );

    setUsers(updatedList);
  };

  const handleStatus = (status) => {
    setStatus(status);
  };



  useEffect(() => {
    return () => {
      dispatch(setSection([]));
      dispatch(
        setQuestionWithStatus({
          questions: [],
          total_no_of_questions: 0,
          questions_approved: 0,
          questions_assigned: 0,
          status: "",
        })
      );
      dispatch(setSelectedAdminSection(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StepLayOut
      left={
        <Step2Left
          contributors={contributors}
          approvers={approvers}
          handleContributers={handleContributers}
          handleApprovers={handleApprovers}
          handleDeleteApprovers={handleDeleteApprovers}
          handleDeleteContributers={handleDeleteContributers}
          users={users}
          handleReset={() => {
            setContributors([]);
            setApprover([]);
          }}
          toggleSingleItemUser={toggleSingleItemUser}
          surveyList={Object.keys(response)}
          // handleSelectSection={handleSelectSection}
          handleStatus={handleStatus}
          // sectionQuestions={response[selectedSection] || []}
        />
      }
      right={
        <Step2Right
          contributors={contributors}
          approvers={approvers}
          handleContributers={handleContributers}
          handleApprovers={handleApprovers}
          handleDeleteApprovers={handleDeleteApprovers}
          handleDeleteContributers={handleDeleteContributers}
          users={users}
          handleReset={() => {
            setContributors([]);
            setApprover([]);
          }}
          toggleSingleItemUser={toggleSingleItemUser}
          // sectionQuestions={response[selectedSection] || []}
          status={status}
          handleStatus={handleStatus}
          surveyList={Object.keys(response)}
          // selectedSection={selectedSection}
        />
      }
    />
  );
}

export default SurveyStep2;
