import React from "react";
import { Box } from "@material-ui/core";

// redux imports
import { useDispatch, useSelector } from "react-redux";

// other imports
import ResponseSectionList from "./ResponseSectionList";
import ResponseSectionTopCard from "./ResponseSectionTopCard";
import ResponseSectionTopHeading from "./ResponseSectionTopHeading";

const DrawerRight = ({
  selectedResponse,
  completedQuestions,
  currentFrequency,
  setCurrentFrequency,
}) => {
  // from redux
  const dispatch = useDispatch();

  const totalQues = completedQuestions?.reduce((total, section) => {
    return total + (section.questions?.length || 0);
  }, 0);

  return (
    <Box sx={{ height: "90%", overflow: "hidden", width: "100%" }}>
      <Box sx={{ pl: 2, height: "100%", width: "100%", overflow: "hidden" }}>
        {/* top section */}

        {/* <ResponseSectionTopHeading selectedResponse={selectedResponse} /> */}

        {/* top cards */}
        <ResponseSectionTopCard
          selectedResponse={selectedResponse}
          currentFrequency={currentFrequency}
          setCurrentFrequency={setCurrentFrequency}
          totalQues={totalQues}
          totalSections={completedQuestions?.length}
        />

        {/* question list */}

        <ResponseSectionList completedQuestions={completedQuestions} />

        {/* {isRender ? (
          <ResponseSectionList submittedQuestions={submittedQuestions} />
        ) : (
          <CircularProgress />
        )} */}
      </Box>
    </Box>
  );
};

export default DrawerRight;
