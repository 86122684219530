import {
  SET_TOC,
  SET_COMMENTS,
  SET_SUSTAINABILITY_REPORT,
  SET_LOADING_COMPLETED
} from '../../constants/sustainabilityReport/sustainabilityReportPdfConst';
import UNIVERSAL from '../../config/config';
import encrypt from '../shared/sharedActions';
import { set_loader, unset_loader } from '../loader/loader_action';
import {
  setAssessmentSkeleton,
  setSustainabilityToc
} from './sustainabilityReportAction';
import { set_snack_bar } from "../snackbar/snackbar_action";


export const setToc = (payload) => ({
  type: SET_TOC,
  payload: payload
});

export const setComments = (payload) => ({
  type: SET_COMMENTS,
  payload: payload
});

export const setSustainabilityReport = (payload) => ({
  type: SET_SUSTAINABILITY_REPORT,
  payload: payload
});

export const setLoadingCompleted = (payload) => ({
  type: SET_LOADING_COMPLETED,
  payload: payload
});

export function getCommentsToc(token, assessment_id, step_no) {
  return (dispatch) => {
    // dispatch(set_loader())
    const data = encrypt({
      'user-token': token ? token : localStorage.getItem('user_token'),
      assessment_id: assessment_id,
      step_no: step_no
    });
    return fetch(UNIVERSAL.BASEURL + '/comment/get_toc', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSustainabilityToc(responseJson.result));
        }
        dispatch(unset_loader());
      })

      .catch((error) => {
        console.log(error);
      });
  };
}

export function viewCommentsToc(token, toc_id) {
  return (dispatch) => {
    dispatch(setLoadingCompleted(false));
    const data = encrypt({
      'user-token': token ? token : localStorage.getItem('user_token'),
      toc_id: toc_id
    });
    return fetch(UNIVERSAL.BASEURL + '/comment/view_comments', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setComments(responseJson.results));
        }
        dispatch(setLoadingCompleted(true));
        // dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function addCommentToc(token, toc_id, comment, assessment_topic_id,name,assessment_id) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      'user-token': token ? token : localStorage.getItem('user_token'),
      toc_id: toc_id,
      comment: comment,
      assessment_topic_id: assessment_topic_id,name,assessment_id
    });
    return fetch(UNIVERSAL.BASEURL + '/comment/add_comment', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewCommentsToc(token, toc_id));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function editToc(
  token,
  name,
  toc_id,
  assessment_topic_id,
  step_no,
  visible,
  order,
  assessment_id,
  is_text_changed
) {
  return (dispatch) => {
    // dispatch(set_loader());
    console.log(visible)
    const data = encrypt({
      'user-token': token,
      name,
      toc_id,
      assessment_topic_id,
      step_no,
      visible,
      order,
      assessment_id,
      is_text_changed
    });
    return fetch(UNIVERSAL.BASEURL + '/comment/edit_toc', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(getCommentsToc(token, assessment_id, step_no));
        }else{
          
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

        }

        // dispatch(set_loader());
      })
      .catch((err) => console.log(err));
  };
}

export function getSustainabilityReport(token, assessment_id) {

  return (dispatch) => {
    dispatch(setAssessmentSkeleton(true));
    const data = encrypt({
      'user-token': token,
      assessment_id
    });
    return fetch(UNIVERSAL.BASEURL + '/assessments/get_sustainability_report', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSustainabilityReport(responseJson.result));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
