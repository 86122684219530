import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

function Guidelines({ guidelines, setViewGuideLines, isFrom }) {
  // console.log("Guidelines", guidelines);

  const splitGuidelines = guidelines
    ?.trim()
    ?.split(/\d+\.\s/)
    ?.filter(Boolean);

  //   const sections = splitText.map(
  //     (section, index) => `${index + 1}. ${section.trim()}`
  //   );

  //   console.log(sections);

  const useStyles = makeStyles({
    blueText: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: "600",
      color: "#3374B9",
      padding: "16px 0px",
    },
    grayText: {
      fontSize: 14,
      lineHeight: "21px",
      color: "#9FA1AF",
    },
  });
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {!isFrom && (
          <Typography className={classes.blueText}>Guidelines</Typography>
        )}
        {!isFrom && (
          <IconButton size="small">
            <CloseIcon
              fontSize="small"
              onClick={() => setViewGuideLines(false)}
            />
          </IconButton>
        )}
      </div>
      <div
        className="scroll"
        style={{
          height: isFrom === "expand" ? "fit-content" : "63vh",
        }}
      >
        {splitGuidelines?.length > 0 ? (
          splitGuidelines?.map((guideline, index) => (
            <Grid
              key={index}
              item
              md={12}
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <Grid
                item
                md={1}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "35px",
                  height: "30px",
                  padding: "5px",
                  border: "none",
                  backgroundColor: "#EBF1F8",
                  color: "#3374B9",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    border: "1px solid #3374B9",
                    backgroundColor: "#3374B9",
                    margin: "2px",
                    borderRadius: "5px",
                    width: "3px",
                    height: "18px",
                    fontWeight: "bold",
                  }}
                ></div>
                <Grid item md={6}>
                  <Typography
                    style={{
                      padding: "2px 5px 2px 2px",
                    }}
                  >
                    {index + 1}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={11} style={{ paddingLeft: "10px" }}>
                <Typography className={classes.grayText}>
                  {`${guideline}`}
                </Typography>
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography align="center">
            There are no specific guidelines for this matric.
          </Typography>
        )}
      </div>
    </>
  );
}

export default Guidelines;
