import {
    SET_COMPANY_NAME,
    SET_COMPANY_TYPE,
  SET_EMPLOYEE_COUNT,
  RESET_BUSINESS_UNIT
} from '../../constants/businessUnit/businessUnitConstants'

import UNIVERSAL from '../../config/config'

import { set_snack_bar } from "../../actions/snackbar/snackbar_action";

export function setCompanyName(payload) {
    return {
        type: SET_COMPANY_NAME,
        payload:payload
    }
}

export function setCompanyType(payload) {
    return {
        type: SET_COMPANY_TYPE,
        payload:payload
    }
}

export function setEmployeeCount(payload) {
    return {
        type: SET_EMPLOYEE_COUNT,
        payload:payload
    }
}
export function resetBusinessUnit() {
    return {
      type: RESET_BUSINESS_UNIT
    }
}

export function addOrganisationUnit(token) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + '/add_organiation_unit', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'content-type': 'application/json',
                'user-content':token,
            }
        })
        .then((response) => response.json)
        .then((responseJson) => {
            if (responseJson.status) {
                dispatch(set_snack_bar(responseJson.status, responseJson.message))
            }
        })
    }
}