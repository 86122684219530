import React, { useEffect, useState, useRef, useCallback } from "react";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Box from "@material-ui/core/Box";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import RemoveIcon from "@material-ui/icons/Remove";
import trash from "../../../../../../../images/trashRed.png";

import { nanoid } from "nanoid";

import { Autocomplete } from "@material-ui/lab";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import { debounce } from "lodash";

import {
  radioOption,
  QUESTION_TYPE_SCALE,
  QUESTION_TYPE_MULTICHOICE,
  QUESTION_TYPE_COMMENT_BOX,
  QUESTION_TYPE_LOCATION,
  QUESTION_TYPE_DOCUMENT_UPLOAD,
  QUESTION_TYPE_DATE_PICKER,
  SUB_QUESTION_0_10,
  SUB_QUESTION_LIKERT,
  SUB_QUESTION_SATISFACTION,
  SUB_QUESTION_QUALITY,
  SUB_QUESTION_FREQUENCY,
  SUB_QUESTION_PERFORMANCE,
  SUB_QUESTION_IMPORTANCE,
  SUB_QUESTION_EMOTIONS,
  DATE_PICKER_SINGLE_DATE,
  DATE_PICKER_RANGE_DATE,
  QUESTION_TYPE_TABLE,
  QUESTION_TYPE_NUMBER_DOCUMENT,
  QUESTION_TYPE_URL_FIELD,
  QUESTION_TYPE_EMAIL_FIELD,
  QUESTION_TYPE_TEXT_DOCUMENT,
  QUESTION_TYPE_NUMERIC_FIELD,
  QUESTION_TYPE_CURRENCY_FIELD,
  QUESTION_TYPE_SINGLE_CHOICE,
  QUESTION_TYPE_SINGLE_CHOICE_TEXT,
  QUESTION_TYPE_SINGLE_CHOICE_TEXT_DOC,
  QUESTION_TYPE_SINGLE_CHOICE_DOC,
} from "./QuestionData";
import CustomButton from "../../../../../../UI/button/button";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOption } from "../../../../../../../actions/supplier/supplierAction";
// import { setSelectedOption } from "../../../../../../../redux/actions/corporateSurvey/corporateSurveyActions";
import RadioButton from "../../../../../../UI/radioButton/radioButton";
import {
  UpdateQuestionCommentValue,
  UpdateQuestionOption,
  setMainQuestionComment,
  setQuestionComment,
} from "../../../../../../../actions/taskActions/TaskAction";
import { CustomInput } from "components/screen/dashboardStatic/createCharts/PreviewCharts";
import DynamicTable from "components/UI/dynamicTable/DynamicTable";
import { set_snack_bar } from "actions/snackbar/snackbar_action";
import CreationTable from "./QuestionBodyComps/TableTypesComps/CreationTable";
import ContributionTable from "./QuestionBodyComps/TableTypesComps/ContributionTable";
import RegularTable from "./QuestionBodyComps/TableTypesComps/RegularTable";

function QuestionBody({
  questionType,
  answers,
  checkBoxOption,
  setCheckBoxOption,
  submittedAnswer,
  responseAnswer,
  removeEditOption,
  questionId,
  activeTabId,
  handleQuesAnswer,
  event,
  comment,
  isFrom,
  usingQues,
  is_completed,
  comment_value,

  // for tables
  headers, // for setting default headers
  isFor, // creation, contribution or regular
  handleTableData, // for returning table data
  isEditing,
}) {
  switch (questionType) {
    case QUESTION_TYPE_COMMENT_BOX:
      return (
        <CommentBox
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          questionId={questionId}
          activeTabId={activeTabId}
          removeEditOption={removeEditOption}
          isFrom={isFrom}
          comment_value={comment_value}
        />
      );
    case QUESTION_TYPE_DOCUMENT_UPLOAD:
      return (
        <DocumentUpload
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );
    case QUESTION_TYPE_LOCATION:
      return (
        <Location
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          removeEditOption={removeEditOption}
          event={event}
          comment={comment}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );
    case QUESTION_TYPE_MULTICHOICE:
      return (
        <MultiChoice
          option={answers}
          checkBoxOption={checkBoxOption}
          setCheckBoxOption={setCheckBoxOption}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          removeEditOption={removeEditOption}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );
    case DATE_PICKER_SINGLE_DATE:
      return (
        <DatePicker
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );
    case DATE_PICKER_RANGE_DATE:
      return (
        <DatePicker
          hasRange={true}
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );
    case QUESTION_TYPE_SCALE:
    case SUB_QUESTION_0_10:
    case SUB_QUESTION_LIKERT:
    case SUB_QUESTION_SATISFACTION:
    case SUB_QUESTION_QUALITY:
    case SUB_QUESTION_FREQUENCY:
    case SUB_QUESTION_PERFORMANCE:
    case SUB_QUESTION_IMPORTANCE:
    case SUB_QUESTION_EMOTIONS:
      return (
        <Scale
          questionType={questionType}
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );

    case QUESTION_TYPE_TABLE:
      return (
        // <Table
        //   option={answers}
        //   checkBoxOption={checkBoxOption}
        //   setCheckBoxOption={setCheckBoxOption}
        //   submittedAnswer={submittedAnswer}
        //   responseAnswer={responseAnswer}
        //   removeEditOption={removeEditOption}
        //   questionId={questionId}
        //   activeTabId={activeTabId}
        // />
        <TableType
          questionId={questionId}
          activeTabId={activeTabId}
          headers={answers}
          submittedAnswer={submittedAnswer}
          isFor={isFor}
          handleTableData={handleTableData}
          isEditing={isEditing}
          is_completed={is_completed}
        />
      );

    case QUESTION_TYPE_NUMBER_DOCUMENT:
      return (
        <NumberFieldWithUpload
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );

    case QUESTION_TYPE_URL_FIELD:
      return (
        <UrlField
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );

    case QUESTION_TYPE_EMAIL_FIELD:
      return (
        <EmailQuestion
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );

    case QUESTION_TYPE_CURRENCY_FIELD:
      return (
        <CurrencyField
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );

    case QUESTION_TYPE_NUMERIC_FIELD:
      return (
        <NumberField
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );

    case QUESTION_TYPE_TEXT_DOCUMENT:
      return (
        <TextFieldWithUpload
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );

    case QUESTION_TYPE_SINGLE_CHOICE:
    case QUESTION_TYPE_SINGLE_CHOICE_TEXT:
    case QUESTION_TYPE_SINGLE_CHOICE_TEXT_DOC:
    case QUESTION_TYPE_SINGLE_CHOICE_DOC:
      return (
        <SingleChoiceWithMultiFeatures
          options={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          isFrom={isFrom}
          handleQuesAnswer={handleQuesAnswer}
          questionType={questionType}
          event={event}
          comment={comment}
          checkBoxOption={checkBoxOption}
          setCheckBoxOption={setCheckBoxOption}
          usingQues={usingQues}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );
    // Add more cases as needed
    default:
      return <></>;
  }
}

const SingleChoiceWithMultiFeatures = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom = "surveyCreation",
  handleQuesAnswer,
  questionType,
  event,
  comment,
  checkBoxOption,
  setCheckBoxOption,
  usingQues,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const [justification, setJustification] = useState("");
  const [file, setFile] = useState(null);
  const [commentBox, setCommentBox] = useState("");
  const [option, setOption] = useState({});
  const [newOption, setNewOption] = useState("");
  const [newWeightage, setNewWeightage] = useState("");

  const inputRef = useRef(null);

  const handleOptionChange = (event) => {
    const optionValue = event.target.value;
    setSelectedOption(optionValue);
  };

  const customOptions = [
    // { option: "Option 1", weightage: 2 },
    // { option: "Option 2", weightage: 3 },
  ];

  useEffect(() => {
    // const exceptions = [
    //   ...radioOption[SUB_QUESTION_0_10],
    //   ...radioOption[SUB_QUESTION_QUALITY],
    //   ...radioOption[SUB_QUESTION_SATISFACTION],
    //   ...radioOption[SUB_QUESTION_LIKERT],
    //   ...radioOption[SUB_QUESTION_FREQUENCY],
    //   ...radioOption[SUB_QUESTION_PERFORMANCE],
    //   ...radioOption[SUB_QUESTION_IMPORTANCE],
    //   ...radioOption[SUB_QUESTION_EMOTIONS],
    // ];

    if (checkBoxOption?.length === 0 && !submittedAnswer) {
      if (
        usingQues?.questionType === "ZERO_TO_TEN" ||
        usingQues?.questionType === "LIKERT" ||
        usingQues?.questionType === "SATISFACTION" ||
        usingQues?.questionType === "QUALITY" ||
        usingQues?.questionType === "FREQUENCY" ||
        usingQues?.questionType === "PERFORMANCE" ||
        usingQues?.questionType === "IMPORTANCE" ||
        usingQues?.questionType === "EMOTIONS" ||
        usingQues?.questionType === "TABLE"
      ) {
        setCheckBoxOption(customOptions);
      } else {
        setCheckBoxOption(options || customOptions);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddOption = () => {
    if (newOption.trim() && newWeightage.trim()) {
      setCheckBoxOption([
        ...checkBoxOption,
        {
          option: newOption.trim(),
          weightage: parseFloat(newWeightage),
          justification:
            questionType === "Single Choice with Text" ||
            questionType === "Single Choice with Text & Doc"
              ? "Yes"
              : "No",
          documentUpload:
            questionType === "Single Choice with Document" ||
            questionType === "Single Choice with Text & Doc"
              ? "Yes"
              : "No",
        },
      ]);
      setNewOption("");
      setNewWeightage("");
    }
  };

  const handleWeightageChange = (event) => {
    setNewWeightage(event.target.value);
  };

  const handleNewOption = (event) => {
    setNewOption(event.target.value);
  };

  const handleRemoveOption = (index) => {
    setCheckBoxOption(checkBoxOption?.filter((_, i) => i !== index));
  };

  const handleOption = (opt, just) => {
    setOption(opt);

    dispatch(
      UpdateQuestionOption(
        questionId,
        {
          option: opt?.option,
          justification: just,
          document: file,
        },
        activeTabId
      )
    );
  };

  const handleJustificationChange = (event) => {
    setJustification(event.target.value);
    dispatch(
      UpdateQuestionOption(
        questionId,
        {
          option: option?.option || "",
          justification: event.target.value,
          document: file,
        },
        activeTabId
      )
    );
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);

    dispatch(
      UpdateQuestionOption(
        questionId,
        {
          option: option?.option || "",
          justification: justification,
          document: event.target.files[0],
        },
        activeTabId
      )
    );
  };

  // useEffect(() => {
  //   if (isFrom === "taskSection") {
  //     const obj = {
  //       value: selectedOption,
  //       justification,
  //       document: file,
  //       weightage: option?.weightage,
  //     };

  //     dispatch(UpdateQuestionOption(questionId, obj, activeTabId));

  //     // handleQuesAnswer(obj);
  //   }
  // }, [selectedOption, justification, file]);

  useEffect(() => {
    if (!submittedAnswer) {
      setSelectedOption("");
      setJustification("");
      setFile(null);
    }
    // return () => {
    //   if (isFrom === "taskSection") {
    //     console.log("run")
    //     setFile(null);
    //     setJustification("");
    //   }
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedAnswer]);

  useEffect(() => {
    if (submittedAnswer || responseAnswer) {
      setSelectedOption(submittedAnswer?.option || responseAnswer?.option);
      setJustification(
        submittedAnswer?.justification || responseAnswer?.justification
      );
      setFile(submittedAnswer?.document || responseAnswer?.document);
    }
  }, [submittedAnswer, responseAnswer]);

  return (
    <div>
      <FormControl component="fieldset">
        {(checkBoxOption || options)?.length > 0 ? (
          <RadioGroup value={selectedOption} onChange={handleOptionChange}>
            {(checkBoxOption || options)?.map((option, index) => (
              <Box key={index} display={"flex"}>
                <FormControlLabel
                  key={option?.option}
                  value={option?.option}
                  control={<RadioButton color="primary" />}
                  label={option?.option}
                  style={{ pointerEvents: event }}
                  onClick={() => handleOption(option, justification)}
                />
                {isFrom === "surveyCreation" && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleRemoveOption(index)}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Box>
            ))}
          </RadioGroup>
        ) : (
          <Typography style={{ marginBottom: 10 }}>Add some options</Typography>
        )}
        {isFrom === "surveyCreation" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 10,
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <TextField
              label="New Option"
              variant="outlined"
              value={newOption}
              onChange={handleNewOption}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              type="number"
              value={newWeightage}
              onChange={handleWeightageChange}
            />
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleAddOption}
              disabled={newOption === "" || newWeightage === ""}
            >
              Add Option
            </CustomButton>
          </div>
        )}
      </FormControl>
      {((selectedOption &&
        options?.find((option) => option?.option === selectedOption)
          ?.justification === "Yes") ||
        questionType === "Single Choice with Text" ||
        questionType === "Single Choice with Text & Doc") && (
        <TextField
          id="justification"
          label="Justification"
          variant="outlined"
          value={justification}
          onChange={handleJustificationChange}
          fullWidth
          multiline
          minRows={4}
          margin="normal"
          style={{ pointerEvents: event }}
        />
      )}
      {((selectedOption &&
        options?.find((option) => option?.option === selectedOption)
          ?.documentUpload === "Yes") ||
        questionType === "Single Choice with Document" ||
        questionType === "Single Choice with Text & Doc") && (
        <Box
          width="100%"
          style={{
            margin: "26px 5px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          <AttachFileIcon
            style={{ backgroundColor: "#ced4da", padding: 4 }}
            color="disabled"
          />
          {submittedAnswer?.document ? (
            <Box
              border={1}
              borderRadius={4}
              p={1}
              borderColor={"#cececece"}
              ml={2}
            >
              {submittedAnswer?.document === null ? (
                <Typography variant={"body2"}>
                  No document has been uploaded
                </Typography>
              ) : (
                <a
                  href={submittedAnswer?.document?.name}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#999" }}
                >
                  {submittedAnswer?.document?.name}
                </a>
              )}
            </Box>
          ) : (
            <>
              <input
                name="actUpload"
                id="contained-button-activityFile"
                type="file"
                accept=".pdf"
                ref={inputRef}
                onChange={handleFileChange}
                style={{ display: "none", pointerEvents: event }}
                multiple
              />
              <div
                style={{
                  borderBottom: "2px solid #ced4da",
                  marginLeft: 20,
                  width: "100%",
                }}
              >
                {file?.name ? file.name : "Upload file"}
              </div>
            </>
          )}
        </Box>
      )}

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

const CurrencyField = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [commentBox, setCommentBox] = useState("");

  const handleChange = (event) => {
    // Remove non-numeric characters and format the value as currency
    const formattedValue = event.target.value
      .replace(/[^\d.]/g, "") // Remove non-numeric characters except '.'
      .replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2") // Ensure only one '.' is present
      .replace(/^(\d*)(\.\d{0,2}).*/, "$1$2"); // Allow only up to two decimal places

    setValue(formattedValue);
    dispatch(UpdateQuestionOption(questionId, formattedValue, activeTabId));
  };

  // useEffect(() => {
  //   if (isFrom === "taskSection") {
  //     const obj = {
  //       value,
  //       weightage: 1,
  //     };

  //     dispatch(UpdateQuestionOption(questionId, value, activeTabId));

  //     // handleQuesAnswer(obj);
  //   }
  // }, [value]);

  useEffect(() => {
    if (submittedAnswer || responseAnswer) {
      setValue(submittedAnswer || responseAnswer);
    }
  }, [submittedAnswer, responseAnswer]);

  return (
    <Box>
      <TextField
        style={{ pointerEvents: event }}
        label={"Currency"}
        variant="outlined"
        value={value}
        onChange={handleChange}
        InputProps={{
          inputProps: {
            inputMode: "numeric", // Set input mode to allow numeric input
            pattern: "[0-9]*", // Set pattern to allow only numeric characters
          },
        }}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const NumberField = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [commentBox, setCommentBox] = useState("");

  const handleChange = (event) => {
    // Remove non-numeric characters from the input value
    const formattedValue = event.target.value.replace(/\D/g, "");

    setValue(formattedValue);

    dispatch(UpdateQuestionOption(questionId, formattedValue, activeTabId));
  };

  // useEffect(() => {
  //   if (isFrom === "taskSection") {
  //     const obj = {
  //       value,
  //       weightage: 1,
  //     };

  //     dispatch(UpdateQuestionOption(questionId, value, activeTabId));

  //     // handleQuesAnswer(obj);
  //   }
  // }, [value]);

  useEffect(() => {
    if (submittedAnswer || responseAnswer) {
      setValue(submittedAnswer || responseAnswer);
    }
  }, [submittedAnswer, responseAnswer]);

  return (
    <Box>
      <TextField
        style={{ pointerEvents: event }}
        label={"Numeric Value"}
        variant="outlined"
        value={value}
        onChange={handleChange}
        inputProps={{
          inputMode: "numeric", // Set input mode to allow numeric input
          pattern: "[0-9]*", // Set pattern to allow only numeric characters
        }}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const TextFieldWithUpload = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [file, setFile] = useState(null);
  const [commentBox, setCommentBox] = useState("");

  const inputRef = useRef(null);

  const handleChange = (event) => {
    setValue(event.target.value);
    dispatch(
      UpdateQuestionOption(
        questionId,
        {
          value: event.target.value,
          document: file,
        },
        activeTabId
      )
    );
    dispatch(
      setSelectedOption({
        value: event.target.value,
        document: file,
      })
    );
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    dispatch(
      UpdateQuestionOption(
        questionId,
        {
          value,
          document: event.target.files[0],
        },
        activeTabId
      )
    );
    dispatch(
      setSelectedOption({
        value,
        document: event.target.files[0],
      })
    );
  };

  // useEffect(() => {
  //   if (isFrom === "taskSection") {
  //     const obj = {
  //       value,
  //       document: file,
  //       weightage: 1,
  //     };

  //     dispatch(UpdateQuestionOption(questionId, obj, activeTabId));

  //     // handleQuesAnswer(obj);
  //   }
  // }, [file, value]);

  useEffect(() => {
    if (submittedAnswer || responseAnswer) {
      setValue(submittedAnswer?.value || responseAnswer?.value);
      setFile(submittedAnswer?.document || responseAnswer?.document);
    }
  }, [submittedAnswer, responseAnswer]);

  return (
    <div>
      <TextField
        label={"Type your text here"}
        variant="outlined"
        value={value}
        onChange={handleChange}
        fullWidth
        multiline
        minRows={4}
        margin="normal"
        // style={{ pointerEvents: event }}
      />
      <Box
        width="100%"
        style={{
          margin: "26px 5px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <AttachFileIcon
          style={{ backgroundColor: "#ced4da", padding: 4 }}
          color="disabled"
        />
        {submittedAnswer?.document ? (
          <Box
            border={1}
            borderRadius={4}
            p={1}
            borderColor={"#cececece"}
            ml={2}
          >
            {submittedAnswer?.document === null ? (
              <Typography variant={"body2"}>
                No document has been uploaded
              </Typography>
            ) : (
              <a
                href={submittedAnswer?.document?.name}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#999" }}
              >
                {submittedAnswer?.document?.name}
              </a>
            )}
          </Box>
        ) : (
          <>
            <input
              name="actUpload"
              id="contained-button-activityFile"
              type="file"
              accept=".pdf"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: "none", pointerEvents: event }}
              multiple
            />
            <div
              style={{
                borderBottom: "2px solid #ced4da",
                marginLeft: 20,
                width: "100%",
              }}
            >
              {file?.name ? file.name : "Upload file"}
            </div>
          </>
        )}
      </Box>

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

const EmailQuestion = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [commentBox, setCommentBox] = useState("");

  const validateEmail = (eAddress) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(eAddress);
  };

  const handleEmailChange = (event) => {
    const newValue = event.target.value;
    setEmail(newValue);
    if (validateEmail(newValue)) {
      setError(false);
      dispatch(UpdateQuestionOption(questionId, newValue, activeTabId));
    } else {
      setError(true);
      dispatch(set_snack_bar(true, "Please enter a valid email address"));
    }
  };

  // useEffect(() => {
  //   let obj = {};
  //   if (
  //     validateEmail(email) &&
  //     isFrom === "taskSection" &&
  //     isFrom !== "approvalSection"
  //   ) {
  //     obj = {
  //       value: email,
  //       weightage: 1,
  //       comment: commentBox,
  //     };
  //     setError(false);
  //   } else {
  //     setError(true);
  //     set_snack_bar(true, "Please enter a valid email address");
  //   }

  //   if (isFrom !== "approvalSection" && isFrom === "taskSection") {
  //     dispatch(UpdateQuestionOption(questionId, email, activeTabId));
  //     // handleQuesAnswer(obj);
  //   }
  // }, [email, commentBox]);

  useEffect(() => {
    if (submittedAnswer || responseAnswer) {
      setEmail(submittedAnswer || responseAnswer);
    }
  }, [submittedAnswer, responseAnswer]);

  // const handleSubmit = () => {
  //   if (validateEmail(email)) {
  //     setError(false);
  //     setHelperText("");
  //     onSubmit(email); // Callback to handle submission
  //   } else {
  //     setError(true);
  //     setHelperText("Please enter a valid email address");
  //   }
  // };

  return (
    <Box style={{ pointerEvents: event }}>
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        value={email}
        onChange={handleEmailChange}
        error={isFrom === "taskSection" ? error : false}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const UrlField = ({
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  event,
  comment,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");
  const [urlError, setUrlError] = useState(false);
  const [commentBox, setCommentBox] = useState("");

  const urlPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

  const handleUrlChange = (event) => {
    const newValue = event.target.value;
    setUrl(newValue);
    if (urlPattern.test(newValue)) {
      setUrlError(false);
      dispatch(UpdateQuestionOption(questionId, newValue, activeTabId));
    } else {
      setUrlError(true);
    }
  };

  useEffect(() => {
    if (submittedAnswer || responseAnswer) {
      setUrl(submittedAnswer || responseAnswer);
    }
  }, [submittedAnswer, responseAnswer]);

  return (
    <Box style={{ pointerEvents: event }}>
      <TextField
        placeholder="Url"
        variant="outlined"
        fullWidth
        value={url}
        onChange={handleUrlChange}
        error={urlError}
        helperText={urlError ? "Please enter a valid URL" : ""}
      />
      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const NumberFieldWithUpload = ({
  options,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  questionType,
  event,
  comment,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [commentBox, setCommentBox] = useState("");
  const [file, setFile] = useState(null);

  const inputRef = useRef(null);

  const handleChange = (event) => {
    // Remove non-numeric characters from the input value
    const formattedValue = event.target.value.replace(/\D/g, "");

    setValue(formattedValue);
    dispatch(
      UpdateQuestionOption(
        questionId,
        {
          value: formattedValue,
          document: file,
        },
        activeTabId
      )
    );
    dispatch(
      setSelectedOption({
        value: formattedValue,
        document: file,
      })
    );
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    dispatch(
      UpdateQuestionOption(
        questionId,
        {
          value,
          document: event.target.files[0],
        },
        activeTabId
      )
    );
    dispatch(
      setSelectedOption({
        value,
        document: event.target.files[0],
      })
    );
  };

  // useEffect(() => {
  //   if (value && value?.value && value?.document && isFrom === "taskSection") {
  //     const obj = {
  //       value,
  //       document: file,
  //       weightage: 1,
  //       comment: commentBox,
  //     };

  //     console.log("run ");

  //     // dispatch(UpdateQuestionOption(questionId, obj, activeTabId));
  //     // dispatch(setSelectedOption(obj));

  //     // handleQuesAnswer(obj);
  //   }
  // }, [file, value]);

  useEffect(() => {
    if (submittedAnswer || responseAnswer) {
      setValue(submittedAnswer?.value || responseAnswer?.value);
      setFile(submittedAnswer?.document || responseAnswer?.document);
    }
  }, [submittedAnswer, responseAnswer]);

  return (
    <div>
      <TextField
        style={{ pointerEvents: event }}
        label={"Numeric Value"}
        variant="outlined"
        value={value}
        onChange={handleChange}
        inputProps={{
          inputMode: "numeric", // Set input mode to allow numeric input
          pattern: "[0-9]*", // Set pattern to allow only numeric characters
        }}
      />
      <Box
        width="100%"
        style={{
          margin: "26px 5px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <AttachFileIcon
          style={{ backgroundColor: "#ced4da", padding: 4 }}
          color="disabled"
        />
        {submittedAnswer?.document ? (
          <Box
            border={1}
            borderRadius={4}
            p={1}
            borderColor={"#cececece"}
            ml={2}
          >
            {submittedAnswer?.document === null ? (
              <Typography variant={"body2"}>
                No document has been uploaded
              </Typography>
            ) : (
              <a
                href={submittedAnswer?.document?.name}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#999" }}
              >
                {submittedAnswer?.document?.name}
              </a>
            )}
          </Box>
        ) : (
          <>
            <input
              name="actUpload"
              id="contained-button-activityFile"
              type="file"
              accept=".pdf"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: "none", pointerEvents: event }}
              multiple
            />
            <div
              style={{
                borderBottom: "2px solid #ced4da",
                marginLeft: 20,
                width: "100%",
              }}
            >
              {file?.name ? file.name : "Upload file"}
            </div>
          </>
        )}
      </Box>

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            // defaultValue={}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </div>
  );
};

const Location = ({
  option,
  submittedAnswer,
  responseAnswer,
  isFrom,
  handleQuesAnswer,
  removeEditOption,
  event,
  comment,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentBox, setCommentBox] = useState("");
  const { selectedOption } = useSelector((state) => state?.supplier);

  const fetchLocations = debounce(async (query) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${query}&type=locality&apiKey=6c147cbfca9e4f768c57f8fc6a1e7642`
      );
      const data = await response.json();
      setOptions(data?.features || []);
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setLoading(false);
    }
  }, 500);

  useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer) {
        setInputValue(submittedAnswer);
        dispatch(setSelectedOption(submittedAnswer));
        // setCommentBox(submittedAnswer[0]?.comment);
      } else {
        dispatch(setSelectedOption(null));
      }
    } else {
      dispatch(setSelectedOption(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event, value) => {
    setInputValue(value);
    fetchLocations(value); // Ensure fetchLocations is accessible here
  };

  useEffect(() => {
    if (isFrom === "taskSection") {
      const obj = {
        value: inputValue,
        comment: commentBox,
        weightage: 1,
      };

      dispatch(UpdateQuestionOption(questionId, inputValue, activeTabId));
      // handleQuesAnswer(obj);
    }
  }, [inputValue, commentBox, isFrom, handleQuesAnswer]);

  const formattedLocation = (location) => {
    return `${location?.city}, ${location?.state}, ${location?.country}`;
  };

  let handleValue = "";

  if (isFrom === "taskSection") {
    handleValue = inputValue || submittedAnswer?.option || "";
  } else if (selectedOption) {
    handleValue = inputValue || selectedOption;
  } else if (submittedAnswer) {
    handleValue = submittedAnswer;
  } else if (submittedAnswer?.answer) {
    handleValue = inputValue || submittedAnswer?.answer;
  } else if (responseAnswer) {
    handleValue = inputValue || responseAnswer;
  } else {
    handleValue = inputValue || "";
  }

  return (
    <Box>
      <Autocomplete
        disabled={removeEditOption} // Disable Autocomplete if removeEditOption is true
        style={{ pointerEvents: event }}
        options={options}
        getOptionLabel={(option) => option?.properties?.formatted}
        inputValue={handleValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={removeEditOption} // Disable text input field if removeEditOption is true
            label={submittedAnswer ? "" : "Search Location"}
            variant={"outlined"}
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {submittedAnswer ? null : params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      {comment && (
        <Box style={{ pointerEvents: event, marginTop: "10px" }}>
          <TextField
            fullWidth
            id="comment"
            label="Contributor's comment"
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Contributor's comment"
            style={{ marginTop: "10px" }}
            value={commentBox}
            onChange={(e) => setCommentBox(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

const CommentBox = ({
  submittedAnswer,
  responseAnswer,
  questionId,
  activeTabId,
  removeEditOption,
  isFrom,
  option,
  comment_value,
}) => {
  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state?.supplier);
  const { questionComment, mainQuestionComment } = useSelector(
    (state) => state?.taskReducer
  );

  const handleChange = (e) => {
    if (isFrom === "questionComment") {
      dispatch(setQuestionComment(e.target.value));
      dispatch(
        UpdateQuestionCommentValue(questionId, e.target.value, activeTabId)
      );
    } else {
      dispatch(UpdateQuestionOption(questionId, e.target.value, activeTabId));
      dispatch(setMainQuestionComment(e.target.value));
    }
    // dispatch(setSelectedOption(e.target.value));
    // setValue(parseInt(event?.weightage));
  };

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer) {
        dispatch(setSelectedOption(submittedAnswer || submittedAnswer?.answer));
      } else {
        dispatch(setSelectedOption(null));
      }
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setQuestionComment(null));
      dispatch(setMainQuestionComment(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  let handleValueForQuesCmt = "";
  let handleValueForTaskSec = "";

  if (isFrom === "questionComment") {
    if (questionComment !== null) {
      handleValueForQuesCmt = questionComment;
    } else if (comment_value) {
      handleValueForQuesCmt = comment_value;
    } else {
      handleValueForQuesCmt = "";
    }
  }
  // if is for main taskSection answer
  else {
    if (mainQuestionComment !== null) {
      handleValueForTaskSec = mainQuestionComment;
    } else if (submittedAnswer) {
      handleValueForTaskSec = submittedAnswer;
    } else {
      handleValueForTaskSec = "";
    }
  }

  const setDisabled = isFrom !== "taskSection" && removeEditOption;

  return isFrom === "questionComment" ? (
    <CustomInput
      style={{
        width: "100%",
        pointerEvents: removeEditOption ? "none" : "inherit",
      }}
      aria-label="minimum height"
      minRows={3}
      multiline
      placeholder="Comment"
      value={handleValueForQuesCmt}
      onChange={(e) => handleChange(e)}
      disabled={removeEditOption}
    />
  ) : (
    <CustomInput
      style={{
        width: "100%",
        pointerEvents: setDisabled ? "none" : "inherit",
      }}
      aria-label="minimum height"
      minRows={3}
      multiline
      placeholder="Comment"
      value={handleValueForTaskSec}
      onChange={(e) => handleChange(e)}
      disabled={setDisabled}
    />
  );
};

const DocumentUpload = ({
  submittedAnswer,
  responseAnswer,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state?.supplier);

  const [reset, setReset] = useState(false);

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer) {
        dispatch(setSelectedOption(submittedAnswer || submittedAnswer?.answer));
      } else {
        dispatch(setSelectedOption(null));
      }
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setSelectedOption(null));
      setReset(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  const handleChange = (e) => {
    dispatch(setSelectedOption(e.target.files[0]));
    dispatch(UpdateQuestionOption(questionId, e.target.files[0], activeTabId));
    // setValue(parseInt(event?.weightage));
    setReset(false);
  };

  const handleName = responseAnswer
    ? responseAnswer?.name
    : selectedOption?.name;
  return (
    <>
      {((selectedOption === "" || selectedOption === null) &&
        !responseAnswer &&
        !submittedAnswer) ||
      reset ? (
        <>
          <input
            name="actUpload"
            id="contained-button-activityFile"
            type="file"
            onChange={(event) => handleChange(event)}
            style={{ display: "none" }}
          />
          <label htmlFor="contained-button-activityFile">
            <Box
              display="flex"
              component="span"
              alignItems="center"
              style={{ cursor: "pointer" }}
            >
              <AttachFileIcon color="disabled" />
              <Box
                mx={2}
                style={{ borderBottom: "2px solid gray" }}
                width="100%"
              >
                <Typography variant="caption" color="textSecondary">
                  Upload Document
                </Typography>
              </Box>
            </Box>
          </label>
        </>
      ) : submittedAnswer && !selectedOption ? (
        <Box display="flex" alignItems="center">
          <AttachFileIcon color="disabled" />
          <Box
            mx={2}
            style={{ borderBottom: "2px solid #3374B9" }}
            width="100%"
          >
            <Typography variant="caption" color="primary">
              {submittedAnswer?.document?.name}
            </Typography>
          </Box>
          <ClearIcon
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setReset(true);
              dispatch(setSelectedOption(null));
            }}
          />
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center">
            <AttachFileIcon color="disabled" />
            <Box
              mx={2}
              style={{ borderBottom: "2px solid #3374B9" }}
              width="100%"
            >
              <Typography variant="caption" color="primary">
                {handleName}
              </Typography>
            </Box>
            <ClearIcon
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                // removeFile(
                //     login.token,
                //     assessment_id,
                //     3,
                //     activityFile.url,
                //     "activity_file",
                //     esgPlan.currentTopic.assessment_topic_id,
                //     currentActivityID
                // );
                dispatch(setSelectedOption(null));
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

const MultiChoice = ({
  option,
  checkBoxOption,
  setCheckBoxOption,
  submittedAnswer,
  responseAnswer,
  removeEditOption,
  questionId,
  activeTabId,
}) => {
  // console.log(option, " --------");
  // console.log(checkBoxOption, " --------");
  // console.log(submittedAnswer, " --------");

  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state?.supplier);

  const checkBoxOptionArray = [
    { name: "option 1", checked: false },
    { name: "option 2", checked: false },
  ];

  // use in add / update

  useEffect(() => {
    if (checkBoxOption?.length === 0 && !submittedAnswer && !responseAnswer) {
      setCheckBoxOption(option || checkBoxOptionArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // use in submit answer

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer) {
        dispatch(setSelectedOption(submittedAnswer?.answer || submittedAnswer));
      } else {
        dispatch(setSelectedOption(null));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  // const [checkBoxOption, setCheckBoxOption] =
  //   React.useState(checkBoxOptionArray);

  const [editingIndex, setEditingIndex] = React.useState(null);
  const [editedText, setEditedText] = React.useState("");

  const handleChange = (event) => {
    if (submittedAnswer) {
      dispatch(
        setSelectedOption(
          selectedOption?.map((option) =>
            option.name === event.target.name
              ? { ...option, checked: event.target.checked }
              : option
          )
        )
      );
      dispatch(
        UpdateQuestionOption(
          questionId,
          selectedOption?.map((option) =>
            option.name === event.target.name
              ? { ...option, checked: event.target.checked }
              : option
          ),
          activeTabId
        )
      );
    } else {
      setCheckBoxOption &&
        setCheckBoxOption((preValue) =>
          preValue?.map((option) =>
            option.name === event.target.name
              ? { ...option, checked: event.target.checked }
              : option
          )
        );
      dispatch(
        setSelectedOption(
          option?.map((option) =>
            option.name === event.target.name
              ? { ...option, checked: event.target.checked }
              : option
          )
        )
      );
      dispatch(
        UpdateQuestionOption(
          questionId,
          option?.map((option) =>
            option.name === event.target.name
              ? { ...option, checked: event.target.checked }
              : option
          ),
          activeTabId
        )
      );
    }
  };

  const handleAddOption = () => {
    setCheckBoxOption((preValue) => [
      ...preValue,
      { name: `option ${preValue.length + 1}`, checked: false },
    ]);
  };

  const handleDoubleClick = (index, currentLabel) => {
    setEditingIndex(index);
    setEditedText(currentLabel);
  };

  const handleLabelBlur = (index) => {
    setEditingIndex(null);

    const updatedOptions = checkBoxOption.map((option, i) => {
      if (i === index) {
        return { ...option, name: editedText };
      }
      return option;
    });

    setCheckBoxOption(updatedOptions);
  };

  const handleLabelChange = (event) => {
    setEditedText(event.target.value);
  };

  const [handleOption, setHandleOption] = React.useState([]);

  useEffect(() => {
    if (!editingIndex) {
      if (selectedOption) {
        setHandleOption(selectedOption);
      } else {
        setHandleOption(
          responseAnswer
            ? responseAnswer
            : submittedAnswer
            ? selectedOption || submittedAnswer || []
            : checkBoxOption || option
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, submittedAnswer, checkBoxOption, responseAnswer]);

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <FormControl component="fieldset">
        <FormGroup>
          {Array.isArray(handleOption) &&
            handleOption.length > 0 &&
            handleOption?.map((option, index) => (
              <div key={index}>
                {editingIndex === index ? (
                  <TextField
                    type="text"
                    value={editedText}
                    onChange={handleLabelChange}
                    onBlur={() => handleLabelBlur(index)}
                    autoFocus
                  />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "60vw",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <>
                          <Checkbox
                            // disabled
                            size="small"
                            style={{ color: "#3374B9" }}
                            checked={option.checked}
                            onChange={(event) =>
                              removeEditOption && handleChange(event, index)
                            }
                            name={option.name}
                          />
                        </>
                      }
                      label={
                        <div style={{ wordBreak: "break-all" }}>
                          {option.name}
                        </div>
                      }
                      onDoubleClick={() =>
                        !submittedAnswer &&
                        !removeEditOption &&
                        handleDoubleClick(index, option.name)
                      }
                    />
                    {!submittedAnswer && !removeEditOption && (
                      <IconButton
                        onClick={() =>
                          setCheckBoxOption((preValue) =>
                            preValue.filter((option, i) => i !== index)
                          )
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                    )}
                  </Box>
                )}
              </div>
            ))}
        </FormGroup>
        {!submittedAnswer && !removeEditOption && (
          <FormHelperText>Double Click to Edit Option</FormHelperText>
        )}
      </FormControl>
      {!submittedAnswer && !removeEditOption && (
        <CustomButton
          style={{ width: "15%" }}
          variant="outlined"
          color="primary"
          onClick={() => handleAddOption()}
        >
          add option
        </CustomButton>
      )}
    </Box>
  );
};

const Scale = ({
  questionType,
  option,
  submittedAnswer,
  responseAnswer,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();
  const { selectedOption } = useSelector((state) => state?.supplier);
  // const [value, setValue] = React.useState(submittedAnswer?.answer?.weightage);

  const handleChange = (e, event) => {
    dispatch(setSelectedOption(event));
    dispatch(UpdateQuestionOption(questionId, event, activeTabId));
    // setValue(parseInt(event?.weightage));
  };

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer || submittedAnswer?.option) {
        dispatch(
          setSelectedOption(
            submittedAnswer?.option ||
              submittedAnswer?.answer ||
              submittedAnswer
          )
        );
      } else {
        dispatch(setSelectedOption(null));
      }
    } else {
      dispatch(setSelectedOption(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedAnswer, responseAnswer]);

  const handleOption = option ? option : radioOption[questionType];
  let handleValue = "";

  if (selectedOption?.option) {
    handleValue = selectedOption?.option;
  } else if (submittedAnswer?.option) {
    handleValue = submittedAnswer?.option;
  } else if (submittedAnswer?.answer?.option) {
    handleValue = submittedAnswer?.answer?.option;
  } else if (selectedOption) {
    handleValue = selectedOption;
  } else if (submittedAnswer?.answer) {
    handleValue = submittedAnswer?.answer;
  } else if (responseAnswer?.option) {
    handleValue = responseAnswer?.option;
  } else if (responseAnswer) {
    handleValue = responseAnswer;
  } else if (submittedAnswer) {
    handleValue = submittedAnswer;
  } else {
    handleValue = "";
  }

  return (
    <FormControl component="fieldset">
      {/* <FormLabel component="legend">Gender</FormLabel> */}
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={handleValue}
        // onChange={handleChange}
      >
        {handleOption.map((o, i) => (
          <FormControlLabel
            // defaultValue={selectedOption?.option}
            key={i}
            value={o?.option || o}
            control={
              <RadioButton
                disableRipple
                disableFocusRipple
                size="small"
                style={{ border: "#505050", color: "#3374B9" }}
              />
            }
            label={o?.option || o}
            onClick={(e) => handleChange(e, o)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const DatePicker = ({
  hasRange = false,
  submittedAnswer,
  responseAnswer,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state?.supplier);

  const handleStartDateChange = async (key, date) => {
    await dispatch(
      setSelectedOption(
        selectedOption !== null
          ? { ...selectedOption, [key]: date }
          : { [key]: date }
      )
    );
    dispatch(
      UpdateQuestionOption(
        questionId,
        selectedOption !== null
          ? { ...selectedOption, [key]: date }
          : { [key]: date },
        activeTabId
      )
    );
  };

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer) {
        dispatch(setSelectedOption(submittedAnswer || submittedAnswer?.answer));
      } else {
        dispatch(setSelectedOption(null));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setSelectedOption(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  let startDateValue = null;

  if (selectedOption?.startData) {
    startDateValue = selectedOption?.startData;
  } else if (submittedAnswer?.startDate) {
    startDateValue = submittedAnswer?.startDate;
  } else if (submittedAnswer?.answer?.startDate) {
    startDateValue = submittedAnswer?.answer?.startDate;
  } else if (responseAnswer?.startDate) {
    startDateValue = responseAnswer?.startDate;
  } else {
    startDateValue = null;
  }

  // console.log(submittedAnswer, responseAnswer);

  return (
    <form
      noValidate
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "25px",
      }}
    >
      {!hasRange ? (
        <TextField
          key={`${questionId}-${hasRange}`}
          id="start-date"
          label={"Select Date"}
          type="date"
          //   className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          value={startDateValue}
          onChange={(e) => handleStartDateChange(e.target.name, e.target.value)}
          variant="outlined"
          color="primary"
          name="startDate"
        />
      ) : (
        <>
          <TextField
            id="start-date"
            label={"Start Date"}
            type="date"
            //   className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            value={startDateValue}
            onChange={(e) =>
              handleStartDateChange(e.target.name, e.target.value)
            }
            variant="outlined"
            color="primary"
            name="startDate"
          />
          <TextField
            id="end-date"
            label="End Date"
            type="date"
            //   className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            value={
              selectedOption?.endDate ||
              submittedAnswer?.endDate ||
              submittedAnswer?.answer?.endDate ||
              responseAnswer?.endDate
            }
            onChange={(e) =>
              handleStartDateChange(e.target.name, e.target.value)
            }
            variant="outlined"
            color="primary"
            name="endDate"
          />
        </>
      )}
    </form>
  );
};

const Table = ({
  checkBoxOption: addColumn,
  setCheckBoxOption: setAddColumn,
  option,
  submittedAnswer,
  responseAnswer,
  removeEditOption,
  questionId,
  activeTabId,
}) => {
  // const [addColumn, setAddColumn] = useState([]);

  // console.log("---------------");
  // console.log(option, submittedAnswer, responseAnswer, addColumn);

  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state?.supplier);

  const [handleOption, setHandleOption] = React.useState([]);

  const [inputs, setInputs] = useState({
    text: "",
    number: "",
    url: "",
    email: "",
    document: null,
    documentName: "", // To store the name of the uploaded document
  });

  const cellType = [
    { type: "Text", id: 1 },
    { type: "Number", id: 2 },
    { type: "URL", id: 3 },
    { type: "Email", id: 4 },
    { type: "Document", id: 5 },
  ];

  // use in add / update

  useEffect(() => {
    if (addColumn?.length === 0 && !submittedAnswer && !responseAnswer) {
      setAddColumn(option || []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer) {
        dispatch(setSelectedOption(submittedAnswer?.answer || submittedAnswer));
      } else {
        dispatch(setSelectedOption(null));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setHandleOption(selectedOption);
    } else {
      setHandleOption(
        responseAnswer
          ? responseAnswer
          : submittedAnswer
          ? selectedOption || submittedAnswer
          : addColumn || option
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, submittedAnswer, addColumn, responseAnswer, option]);

  const handleInputChange = (type, index) => (event) => {
    const value =
      type === "Document" ? event.target.files[0] : event.target.value;
    // setInputs((prevInputs) => ({
    //   ...prevInputs,
    //   [type.toLowerCase()]: value,
    //   ...(type === "Document" && { documentName: value.name }), // Store the file name if it's a document
    // }));

    if (submittedAnswer) {
      dispatch(
        setSelectedOption(
          selectedOption.map((p) => ({
            ...p,
            column: p.column.map((column, columnIndex) =>
              columnIndex === index ? { ...column, data: value } : column
            ),
          }))
        )
      );
      dispatch(
        UpdateQuestionOption(
          questionId,
          selectedOption.map((p) => ({
            ...p,
            column: p.column.map((column, columnIndex) =>
              columnIndex === index ? { ...column, data: value } : column
            ),
          })),
          activeTabId
        )
      );
    } else {
      setAddColumn &&
        setAddColumn((pre) =>
          pre.map((p) => ({
            ...p,
            column: p.column.map((column, columnIndex) =>
              columnIndex === index ? { ...column, data: value } : column
            ),
          }))
        );
      dispatch(
        setSelectedOption(
          option.map((p) => ({
            ...p,
            column: p.column.map((column, columnIndex) =>
              columnIndex === index ? { ...column, data: value } : column
            ),
          }))
        )
      );
      dispatch(
        UpdateQuestionOption(
          questionId,
          option.map((p) => ({
            ...p,
            column: p.column.map((column, columnIndex) =>
              columnIndex === index ? { ...column, data: value } : column
            ),
          })),
          activeTabId
        )
      );
    }
  };

  // console.log(handleOption, selectedOption);

  return (
    <>
      {addColumn && (
        <>
          <div style={{ display: "flex", gap: 10, marginBottom: "10px" }}>
            {addColumn?.map((data, index) => (
              <div
                key={`${data?.id} - ${index}`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  // alignItems: "center",
                }}
              >
                <div style={{ display: "flex", gap: 10 }}>
                  {data?.header.map((h, i) => (
                    <TextField
                      key={i}
                      variant="outlined"
                      placeholder={`${index} - ${i}`}
                      onChange={(e) =>
                        setAddColumn((pre) =>
                          pre.map((p) => ({
                            ...p,
                            header: p.header.map((head, headIndex) =>
                              head === h && headIndex === i
                                ? e.target.value
                                : head
                            ),
                          }))
                        )
                      }
                      value={h}
                    />
                  ))}
                </div>

                <div style={{ display: "flex", gap: 10 }}>
                  {data?.column.map((c, i) => (
                    <>
                      <TextField
                        key={i}
                        id="outlined-select-currency"
                        select
                        label="Type"
                        value={c?.type}
                        fullWidth
                        onChange={(e) =>
                          setAddColumn((pre) =>
                            pre.map((p) => ({
                              ...p,
                              column: p.column.map((column, columnIndex) =>
                                columnIndex === i
                                  ? { ...column, type: e.target.value }
                                  : column
                              ),
                            }))
                          )
                        }
                        variant="outlined"
                      >
                        {cellType.map((option, index) => (
                          <MenuItem
                            key={`${option.id} - ${index}`}
                            value={option.type}
                          >
                            {option.type}
                          </MenuItem>
                        ))}
                      </TextField>
                      <img
                        src={trash}
                        style={{
                          height: "20px",
                          alignSelf: "center",
                          // marginLeft: "240px",
                        }}
                        alt="upload"
                        onClick={() =>
                          setAddColumn((pre) =>
                            pre.map((p) => ({
                              ...p,
                              column: p.column.filter(
                                (column, columnIndex) => columnIndex !== i
                              ),
                              header: p.header.filter(
                                (head, headIndex) => headIndex !== i
                              ),
                            }))
                          )
                        }
                      />
                    </>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={() =>
              setAddColumn((prevColumns) => {
                // Ensure prevColumns is an array and has at least one object
                if (prevColumns.length > 0) {
                  const updatedFirstColumn = {
                    ...prevColumns[0],
                    header: [...(prevColumns[0].header || []), "header"],
                    column: [
                      ...(prevColumns[0].column || []),
                      { type: "Text", data: "" },
                    ],
                  };

                  // Return a new array with the updated first column and keep the rest unchanged
                  return [updatedFirstColumn, ...prevColumns.slice(1)];
                } else {
                  // Handle case where prevColumns is initially empty
                  return [
                    {
                      header: ["header"],
                      id: nanoid(5),
                      column: [{ type: "Text", data: "" }],
                    },
                  ];
                }
              })
            }
          >
            Add Column
          </CustomButton>
        </>
      )}
      {!addColumn && (
        <DynamicTable
          headings={
            handleOption && handleOption?.length > 0 && handleOption[0]?.header
          }
        >
          {handleOption &&
            handleOption?.length > 0 &&
            handleOption?.map((rowData, rowIndex) => (
              <TableRow
                key={rowIndex}
                // className={classes.tableRowHover}
              >
                {rowData?.column.map((column, columnIndex) => (
                  <TableCell key={columnIndex}>
                    {column.type.toLowerCase() === "document" ? (
                      <>
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx,.txt"
                          id="contained-button-tableFile"
                          style={{ width: "100%" }}
                          onChange={handleInputChange(column.type, columnIndex)}
                        />
                        <label htmlFor="contained-button-tableFile">
                          <Box
                            display="flex"
                            component="span"
                            alignItems="center"
                            style={{ cursor: "pointer" }}
                          >
                            <AttachFileIcon color="disabled" />
                            <Box
                              mx={2}
                              style={{ borderBottom: "2px solid gray" }}
                              width="100%"
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Upload Document
                              </Typography>
                            </Box>
                          </Box>
                        </label>
                        {inputs.document && (
                          <div style={{ marginTop: 10 }}>
                            <strong>Uploaded Document:</strong> {column.data}
                          </div>
                        )}
                      </>
                    ) : (
                      <TextField
                        label={column.type}
                        variant="outlined"
                        fullWidth
                        size="small"
                        type={
                          column.type.toLowerCase() === "text"
                            ? "text"
                            : column.type.toLowerCase() === "number"
                            ? "number"
                            : column.type.toLowerCase() === "url"
                            ? "url"
                            : column.type.toLowerCase() === "email"
                            ? "email"
                            : "text"
                        }
                        value={column.data || ""}
                        onChange={handleInputChange(column.type, columnIndex)}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </DynamicTable>
      )}
    </>
  );
};

const TableType = ({
  questionId,
  activeTabId,
  headers = [], // submitted headers from creation
  submittedAnswer = [], // rows data
  isFor, // could be creation, contribution or regular
  handleTableData, // sends table data back to function [NOTE: Required]
  isEditing = true,
  is_completed,
}) => {
  const dispatch = useDispatch();

  // console.log(submittedAnswer, headers, " =-=-=-=-=-=-=-=-");

  // console.log(questionId);
  const handleTableContribution = (tableData) => {
    dispatch(UpdateQuestionOption(questionId, tableData, activeTabId));
  };

  switch (isFor) {
    case "creation":
      return (
        <CreationTable
          headers={headers}
          isEditing={isEditing}
          handleTableData={handleTableData}
        />
      );
    case "contribution":
      return (
        <ContributionTable
          headers={headers}
          submittedAnswer={submittedAnswer}
          handleTableData={handleTableContribution}
          is_completed={is_completed}
        />
      );
    default:
      return (
        <RegularTable submittedAnswer={submittedAnswer} headers={headers} />
      );
  }
};

export default QuestionBody;
