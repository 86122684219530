import { makeStyles } from "@material-ui/core";

const basicInfoRightStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    height: "100%"
  },
  cardContent: {
    padding: 10,
  },
  cardTypography: {
    fontWeight: "600",
  },
  box: {
    border: "1px solid #00000050",
    padding: 12,
    borderRadius: 5,
    cursor: "pointer",
    minHeight: 45,
  },
  card: {
    backgroundColor: "#F0F6FD",
    cursor: "pointer",
    height: 50,
    borderRadius: 10,
  },
  cardContent1: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: 20,
    position: "relative",
  },
  avatar: {
    marginRight: 20,
    width: 35,
    height: 35,
  },
  div: {
    position: "absolute",
    right: 10,
  },
  Typography3: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid2: { marginTop: 15 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid4: { paddingLeft: 75 },
  grid5: { paddingRight: 75 },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },
  CustomButton1: { fontSize: 12 },
  CustomButton2: { marginLeft: 15, fontSize: 12 },
  tableHeaderText: {
    color: "#9FA1AF",
    fontWeight: "400",
    fontFamily: "Poppins",
    fontSize: 16,
    lineHeight: "24px",
  },
  tableBodyText: {
    fontWeight: "400",
    fontSize: 14,
    lineHeight: "20px",
    color: "#242424",
    letterSpacing: "0.0025em",
  },
  optionLabelText: {
    fontWeight: 500,
    fontSize: "16px",
  },
  optionLabelImg: {
    Width:  '50px',
    Height: '33.33px',
  },
  gridDialog:{
    marginLeft: "auto",
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between",
  },
  dialogHeading:{
    borderBottom: "3px solid #32A05F",
    textAlign: "center",
    width: "190px",
    fontWeight: 500,
    fontSize: "16px",
  },
  dialogIcon:{
    cursor: "pointer", marginRight: 10, color: "#3374B9" 
  },
  dialogContent:{
    borderBottom: "1px solid #BBBBBB", padding: "16px 8px" 
  },
  dialogContentTypo:{
    fontWeight: "600",
    color: '#6C6C6C'
  }
}));

export default basicInfoRightStyles;
