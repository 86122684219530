import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import CustomButton from "../../../../../../UI/button/button";
import CustomTextfield from "../../../../../../UI/textfield/textfield";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import trash from "../../../../../../../images/trashRed.png";

import {
  createSection,
  deleteSections,
  // getQuestion,
  setQuestions,
  setSection,
  updateSection,
} from "../../../../../../../actions/supplier/supplierAction";
import EditOutlined from "@material-ui/icons/EditOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import QuestionTabDrawer from "./QuestionTabDrawer";
import SaveDetailsDialog from "../../SaveDetailsDialog";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    fontSize: 12,
    marginBottom: 6,
    backgroundColor: theme.palette.background.paper,
    "& .Mui-selected": {
      backgroundColor: "#EBF1F8", // Change this to the desired color
      borderRadius: "8px",
      marginBottom: "5px",
      marginTop: "5px",
    },
    "& .Mui-selected:hover": {
      backgroundColor: "#EBF1F8", // Change this to the desired color on selected hover
      borderRadius: "8px",
    },
    "& :hover": {
      backgroundColor: "#EBF1F8", // Change this to the desired color on selected hover
      borderRadius: "8px",
    },
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
    "& .Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  },
  selected: {
    backgroundColor: "red", // Change this to the desired color
  },
}));

const sectionListArray = [
  {
    primary: "Self Assessment Section",
  },
  {
    primary: "Controversy Survey",
  },
];

const ConditionalListItem = ({ isMenuItem, ...props }) => {
  return isMenuItem ? <MenuItem {...props} /> : <ListItem {...props} />;
};

export const CustomList = ({
  list,
  handleListClick,
  selectedIndex,
  handleSelectList,
  handleListIcon,
  isMenuItem = false,
  value,
  editName,
  handleCloseEditName,
  ...props
}) => {
  const { selectedSection, sectionWiseData } = useSelector(
    (state) => state.supplier
  );

  // console.log(selectedSection);
  const classes = useStyles();

  const handleClick = (event, index, list) => {
    handleSelectList && handleSelectList(event, index, list);
    handleListClick && handleListClick(list, selectedSection);
  };

  return (
    <List
      disablePadding
      classes={{
        root: classes.root, // class name, e.g. `classes-nesting-root-x`
      }}
      component="nav"
      aria-label="main mailbox folders"
    >
      {list.map((listItem, index) => {
        return (
          <ConditionalListItem
            key={index}
            {...props}
            button
            isMenuItem={isMenuItem}
            value={listItem?.primary}
            selected={selectedSection === listItem}
            onClick={(event) =>
              listItem?.primary === "Scale" ||
              listItem?.primary === "Date Picker"
                ? handleListIcon(listItem?.primary)
                : handleClick(event, index, listItem)
            }
            classes={{
              root:
                isMenuItem !== false &&
                listItem?.primary !== "Scale" &&
                listItem?.primary !== "Date Picker" &&
                classes.root,
              selected: classes.selected,
            }}
          >
            {editName && selectedSection === listItem && (
              <TextField
                variant="outlined"
                defaultValue={listItem}
                onBlur={(e) =>
                  handleCloseEditName(
                    e.target.value,
                    sectionWiseData.find(
                      (s) => s?.section_name === selectedSection
                    )
                  )
                }
                onKeyDown={(e) =>
                  e.key === "Enter" &&
                  handleCloseEditName(
                    e.target.value,
                    sectionWiseData.find(
                      (s) => s?.section_name === selectedSection
                    )
                  )
                }
              />
            )}

            {/* list item for non selected list */}

            {selectedSection !== listItem && (
              <>
                <ListItemText
                  primary={
                    <Typography
                      component="div"
                      variant="body1"
                      style={{
                        display: "block",
                        width: "85%",
                        wordWrap: "break-word",
                      }}
                    >
                      {listItem?.primary ? listItem?.primary : listItem}
                    </Typography>
                  }
                  secondary={listItem?.secondary}
                />
                {(listItem?.primary === "Scale" ||
                  listItem?.primary === "Date Picker") && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="more"
                      onClick={() => handleListIcon(listItem?.primary)}
                    >
                      <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
                {!isMenuItem &&
                  sectionWiseData.find((s) => s?.title === listItem)
                    ?.default === false && (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="more"
                        onClick={(e) =>
                          selectedSection === listItem &&
                          handleListIcon(e, listItem?.primary || listItem)
                        }
                      >
                        <MoreVertIcon style={{ fontSize: "16px" }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
              </>
            )}
            {/* list item for  selected list item */}

            {selectedSection === listItem && editName !== true && (
              <>
                <ListItemText
                  primary={
                    <Typography
                      component="div"
                      variant="body1"
                      style={{
                        display: "block",
                        width: "85%",
                        wordWrap: "break-word",
                      }}
                    >
                      {listItem?.primary ? listItem?.primary : listItem}
                    </Typography>
                  }
                  secondary={listItem?.secondary}
                  primaryTypographyProps={{
                    style: { width: "100px", whiteSpace: "pre-wrap" },
                  }}
                />
                {(listItem?.primary === "Scale" ||
                  listItem?.primary === "Date Picker") && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="more"
                      onClick={() => handleListIcon(listItem?.primary)}
                    >
                      <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
                {/* old version */}
                {/* {!isMenuItem &&
                  sectionWiseData.find((s) => s?.section_name === listItem)
                    ?.default === false && (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="more"
                        onClick={(e) =>
                          selectedSection === listItem &&
                          handleListIcon(e, listItem?.primary || listItem)
                        }
                      >
                        <MoreVertIcon style={{ fontSize: "16px" }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )} */}
                {/* new version */}
                {!isMenuItem && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="more"
                      onClick={(e) =>
                        selectedSection === listItem &&
                        handleListIcon(e, listItem?.primary || listItem)
                      }
                    >
                      <MoreVertIcon style={{ fontSize: "16px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </>
            )}
          </ConditionalListItem>
        );
      })}
    </List>
  );
};

function MoreOptionMenu({
  handleClose,
  anchorEl,
  handleClick,
  handleEditName,
  handleDelete,
}) {
  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditName}>
          <ListItemIcon>
            <EditOutlined style={{ color: "#3374B9" }} />
          </ListItemIcon>
          <ListItemText primary="Rename" />
        </MenuItem>
        <MenuItem onClick={() => handleClick(true)}>
          <ListItemIcon>
            <SettingsOutlinedIcon style={{ color: "#3374B9" }} />
          </ListItemIcon>
          <ListItemText primary="Section Settings" />
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <img src={trash} style={{ height: "20px" }} alt="upload" />
          </ListItemIcon>
          <ListItemText primary="Delete Section" />
        </MenuItem>
      </Menu>
    </div>
  );
}

export default function SectionList({ handleSectionSelection }) {
  const { sectionWiseData, selectedSection, selectedSurveyData } = useSelector(
    (state) => state.supplier
  );
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  // const getSectionList = Object.keys(sectionWiseData);
  const getSectionList = sectionWiseData.map((s) => s.section_name);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [OpenDrawer, setOpenDrawer] = React.useState(false);
  const [editName, setEditName] = React.useState(false);
  const [sectionValue, setSectionValue] = React.useState({});
  const [section, setSection] = React.useState({});

  // new supplier
  const [addSectionDialog, setAddSectionDialog] = React.useState(false);

  const handleMoreOption = (event, sectionName) => {
    if (
      sectionName !== "Self Assessment Section" &&
      sectionName !== "Controversy Survey"
    ) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (event, index, section) => {
    setSelectedIndex(index);

    // const getSectionId = sectionWiseData.find(
    //   (s) => s.title === section
    // );

    // dispatch(getQuestion(login?.token, getSectionId?._id));
  };

  const handleDrawer = (value, hasUpdate) => {
    if (value) {
      setSectionValue(
        sectionWiseData.find((s) => s?.section_name === selectedSection)
      );
      setOpenDrawer(value);
    } else {
      hasUpdate &&
        dispatch(
          updateSection(
            login?.token,
            { ...sectionValue, title: sectionValue?.section_name },
            selectedSurveyData?._id,
            selectedSurveyData?.latest_version
          )
        );
      setOpenDrawer(value);
      setSectionValue({});
      setAnchorEl(null);
    }
  };

  const handleEditName = () => {
    setEditName(true);
    handleCloseOption();
  };

  const handleCloseEditName = (value, section) => {
    dispatch(
      updateSection(
        login?.token,
        { ...section, title: value },
        selectedSurveyData?._id,
        selectedSurveyData?.latest_version
      )
    );
    setEditName(false);
  };

  const handleDeleteSection = () => {
    const getSectionId = sectionWiseData.find(
      (s) => s.section_name === selectedSection
    )?._id;
    dispatch(
      deleteSections(
        login?.token,
        getSectionId,
        selectedSurveyData?.latest_version,
        selectedSurveyData?._id
      )
    );
    handleCloseOption();
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}
    >
      <CustomList
        list={getSectionList}
        handleListClick={handleSectionSelection}
        selectedIndex={selectedIndex}
        handleSelectList={handleListItemClick}
        handleListIcon={handleMoreOption}
        editName={editName}
        handleCloseEditName={handleCloseEditName}
      />

      <CustomButton
        style={{
          textTransform: "none",
          marginRight: "auto",
          marginLeft: "auto",
          width: "100%",
          borderRadius: "8px",
        }}
        size="small"
        color="primary"
        variant="text"
        onClick={() => {
          // dispatch(setSection([...sectionWiseData, { title: "section", version:sectionWiseData[0]?.version, order:sectionWiseData.length }]));
          // dispatch(setQuestions([{ _id: "temporary", hasNewlyAdded: true,question_type: "Multichoice" }]));
          // setSelectedIndex(getSectionList.length);
          // handleSectionSelection("section");
          setAddSectionDialog(true);
        }}
      >
        <AddIcon fontSize={"medium"} />
        <Typography>Add Section</Typography>
      </CustomButton>

      {anchorEl && (
        <MoreOptionMenu
          anchorEl={anchorEl}
          handleClose={handleCloseOption}
          handleClick={handleDrawer}
          handleEditName={handleEditName}
          handleDelete={handleDeleteSection}
        />
      )}
      {OpenDrawer && (
        <QuestionTabDrawer
          headerName={"Section Settings"}
          buttonText={"Save settings"}
          open={OpenDrawer}
          handleClose={handleDrawer}
        >
          <FormControl
            style={{ width: "100%", padding: "20px" }}
            component="fieldset"
          >
            <FormGroup style={{ gap: "20px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={sectionValue?.show || false}
                    onChange={(e) =>
                      setSectionValue((preValue) => ({
                        ...preValue,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                    name="show"
                    color="primary"
                  />
                }
                label="Hide/Show Section "
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={sectionValue?.mandatory || false}
                    onChange={(e) =>
                      setSectionValue((preValue) => ({
                        ...preValue,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                    name="mandatory"
                    color="primary"
                  />
                }
                label="Make Section Mandatory"
              />
              <FormControlLabel
                control={
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-static"
                    label="Description about this section "
                    multiline
                    minRows={4}
                    defaultValue=""
                    variant="outlined"
                  />
                }
                // label="Make Section Mandatory"
              />

              <FormControlLabel
                control={
                  <Switch
                    // checked={state.antoine}
                    // onChange={handleChange}
                    name="antoine"
                    color="primary"
                  />
                }
                label="Request Custom Logic for this section"
              />
            </FormGroup>
            <FormHelperText>
              Each sections output will be setup to have an output score on your
              survey response tab. If you would like to see an output score of
              the section end in a request to our team and we'll get back to
              you.
            </FormHelperText>
          </FormControl>
        </QuestionTabDrawer>
      )}
      {addSectionDialog && (
        <SaveDetailsDialog
          open={addSectionDialog}
          handleClose={() => setAddSectionDialog(false)}
          title={"Create Section"}
          contentText={
            <div>
              <Typography variant="body2" style={{ marginBottom: "8px" }}>
                section name
              </Typography>
              <CustomTextfield
                id="surveyName"
                name="surveyName"
                variant="outlined"
                fullWidth
                size="small"
                value={section?.sectionName || ""}
                onChange={(e) => {
                  setSection((prev) => ({
                    ...prev,
                    sectionName: e.target.value,
                  }));
                }}
              />
              <Typography variant="body2" style={{ marginBottom: "8px" }}>
                Describe about the section
              </Typography>
              <CustomTextfield
                id="surveyDescription"
                name="surveyDescription"
                variant="outlined"
                fullWidth
                multiline
                minRows={10}
                size="small"
                value={section?.sectionDescription || ""}
                onChange={(e) => {
                  setSection((prev) => ({
                    ...prev,
                    sectionDescription: e.target.value,
                  }));
                }}
              />
            </div>

            //  "In the default survey section, you can only edit the question's weightage. The question, type, and options are fixed for this section. Customize the weightage as needed"
          }
          leftButtonText={"Cancel"}
          rightButtonText={"create section"}
          // sectionId={sectionId}
          login={login}
          handleSave={() => {
            // handleQuestionSave(id, questionData, false);
            // setOpenDefaultEditAlert(false);
            dispatch(
              createSection(
                login?.token,
                selectedSurveyData?._id,
                section?.sectionName,
                section?.sectionDescription,
                selectedSurveyData?.latest_version
              )
            );
          }}
        ></SaveDetailsDialog>
      )}
    </div>
  );
}
