import { makeStyles } from "@material-ui/core";

const onBoardingUseStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#F5F6F8",
		height: "100vh",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	dialogContent: {
		borderBottom: '1px solid #BBBBBB',
		padding: '16px 8px',
	  },
	  dialogContentTypo: {
		fontWeight: '600',
		color: '#6C6C6C',
	  },
	sideBarTitle: {
		fontSize: 24,
		lineHeight: "36px",
		color: "#FFFFFF",
		fontWeight: "600",
		paddingTop: "16px",
		paddingBottom: "24px",
		textTransform: "capitalize",
	},
	sideBarSubTitle: {
		fontSize: 16,
		lineHeight: "24px",
		fontWeight: "500",
		color: "#FFFFFF",
		textTransform: "capitalize",
	},
	myCard: {
		borderRadius: "10px",
		backgroundColor: "#FFFFFF",
		marginTop: "32px",
		cursor: "pointer",
	},
	activeCard: {
		borderRadius: "10px",
		backgroundColor: "#DDE9F6",
		marginTop: "32px",
		cursor: "pointer",
	},
	cardCompleted: {
		borderRadius: "10px",
		backgroundColor: "#E0EEE8",
		marginTop: "32px",
		cursor: "pointer",
		position: "relative",
	},
	cardImg: {
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	questionCard: {
		borderRadius: "10px",
		paddingTop: "15px",
		backgroundColor: "#ffffff",
		overflow: "hidden",
		height: "500px",
		overflowY: "scroll",
		scrollbarWidth: "none",
	},
	tickImg: {
		position: "absolute",
		top: "15px",
		right: "15px",
		float: "right",
		[theme.breakpoints.down("xs")]: {
			width: "15px",
			top: "10px",
			right: "10px",
		},
	},
	title: {
		paddingLeft: 55,
	},
	progressBar: {
		marginTop: "5px",
		maxHeight: 20,
	},
	img: {
		marginLeft: "40px",
	},
	textInput: {
		display: "flex",
		paddingTop: "192px",
		paddingBottom: "294px",
		justifyContent: "center",
		[theme.breakpoints.down("xs")]: {
			marginLeft: "50px",
			maxWidth: "200px",
		},
	},
	textField: {
		width: "100%",
		maxWidth: "400px",
	},
	addMore: {
		width: "100%",
		marginTop: 20,
		"& .MuiSelect-icon": {
			color: "transparent",
		},
	},
	label: {
		color: "rgba(51, 116, 185, 1)",
	},
	chip: {
		padding: "3px 3px 0 3px",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "flex-end",
		paddingBottom: 10,
	},
	dropdown: {
		backgroundColor: "#F0F6FD",
		borderRadius: "10px",
	},
	hover_card: {
		padding: "1px",
		margin: 5,
		maxHeight: 85,
		width: "100%",
		"&:hover": {
			backgroundImage: "linear-gradient(to right, #47BB76, #3374B9)",
		},
	},
	selectFrame: {
		padding: "1px",
		margin: 5,
		maxHeight: 85,
		width: "100%",
		backgroundImage: "linear-gradient(to right, #47BB76, #3374B9)",
	},
	sectorCard: {
		boxShadow: "0px 0px 24px rgba(51, 116, 185, 0.16)",
		minHeight: 434,
		overflow: "hidden",
		overflowY: "scroll",
		maxHeight: 434,
	},
	topicCard: {
		boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.16)",
		borderRadius: "0px",
	},
	selectedText: {
		fontSize: 16,
		fontWeight: "600",
		lineHeight: "24px",
		textTrasform: "capitalize",
		color: "#3374B9",
	},
	notchedOutline: {
		border: "1px solid #F1F3F4",
	},
	accordion: {
		"&:before": {
			display: "none",
		},
	},
	cardContent: {
		padding: 0,
		paddingLeft: "8px",
		"&:last-child": {
			paddingBottom: 0,
		},
	},
	accordionDetails: {
		padding: 0,
	},
	accordionSummary: {
		margin: "16px 0px",
	},
	accordionHeading: {
		fontSize: 16,
		lineHeight: "24px",
		fontWeight: "600",
		color: "#242424",
	},
	accordionSubtitle: {
		fontSize: 16,
		lineHeight: "24px",
		fontWeight: "500",
		color: "#242424",
		textTrasform: "capitalize",
	},
	topicSubContainer: {
		display: "flex",
		alignItems: "center",
	},
	topicTitle: {
		fontSize: 21,
		lineHeight: "32px",
		fontWeight: "600",
		color: "#242424",
		paddingTop: "24px",
		paddingLeft: "8px",
		paddingBottom: "16px",
	},
	topicSubtitle: {
		fontSize: 16,
		lineHeight: "24px",
		fontWeight: "500",
		color: "#242424",
		paddingLeft: "8px",
	},
	large: {
		fontSize: "56px",
	},
	envAvatar: {
		width: 24,
		height: 24,
		color: "#000",
		backgroundColor: "#DAF1E4",
		boxShadow: "0px 0px 8px rgba(71, 187, 118, 0.16)",
	},
	socialAvatar: {
		width: 24,
		height: 24,
		color: "#000",
		backgroundColor: "#EAD6F1",
		boxShadow: "0px 0px 8px rgba(71, 187, 118, 0.16)",
	},
	govtAvatar: {
		width: 24,
		height: 24,
		color: "#000",
		backgroundColor: "#D6E3F1",
		boxShadow: "0px 0px 8px rgba(71, 187, 118, 0.16)",
	},
	bussinessAvatar: {
		width: 24,
		height: 24,
		color: "#000",
		backgroundColor: "#F1D6D6",
		boxShadow: "0px 0px 8px rgba(71, 187, 118, 0.16)",
	},
	humanAvatar: {
		width: 24,
		height: 24,
		color: "#000",
		backgroundColor: "#F6F3DA",
		boxShadow: "0px 0px 8px rgba(71, 187, 118, 0.16)",
	},
	frameworkText: {
		fontSize: 16,
		lineHeight: "24px",
		fontWeight: "500",
		textTransform: "capitalize",
	},
	hoverCard: {
		boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
		borderRadius: "5px",
		"& .MuiList-padding": {
			paddingTop: 0,
			paddingBottom: 0,
		},
		"&:hover": {
			backgroundImage: "linear-gradient(to right, #47BB76, #3374B9)",
			padding: 2,
		},
	},
	selectedCard: {
		backgroundImage: "linear-gradient(to right, #47BB76, #3374B9)",
		padding: 2,
		boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
		borderRadius: "5px",
		"& .MuiList-padding": {
			paddingTop: 0,
			paddingBottom: 0,
		},
	},
	frameworkTitle: {
		fontSize: 21,
		lineHeight: "32px",
		fontWeight: "600",
		textTransform: "capitalize",
		color: "#242424",
		paddingTop: "25px",
		paddingLeft: "21px",
		paddingBottom: "20px",
	},
	border: {
		padding: "2px",
		backgroundImage: "linear-gradient(to right, #47BB76, #3374B9)",
		marginLeft: 15,
		borderRadius: 50,
		height: 44,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	name: {
		padding: "8px",
		borderRadius: 50,
		display: "flex",
		alignItems: "center",
		background: "white",
		cursor: "pointer",
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	sectorChip: {
		justifyContent: "flex-start",
		color: "#77787B",
		backgroundColor: "#F5F6F8",
		borderRadius: "5%",
		border: "0px",
		margin: "4px 8px 4px 0px",
		"&&:hover": {
			"& $removeChipIcon": {
				display: "inline",
			},
		},
		"&&:focus": {
			color: "#77787B",
			backgroundColor: "#F5F6F8",
		},
	},
	removeChipIcon: {
		display: "none",
	},
	mainDiv: {
		display: "flex",
		alignItems: "center",
		height: 56,
		width: "100%",
		marginBottom: "8px",
	},
	hiddenDiv: {
		display: "flex",
		position: "fixed",
		marginLeft: "25px",
		marginTop: "25px",
		zIndex: "100",
		width:"13%"
	},
	hiddenGrid: {
		overflow: "hidden",
		backgroundColor: "#F5F6F8",
	},
	hiddenGrid1: { width: "22vw" },
	hiddenGrid2: {
		marginTop: "86px",
		flexDirection: "column",
	},
	grid1: {
		marginLeft: "80px",
		alignSelf: "center",
	},
	grid2: { marginTop: "auto" },
	ul: {
		cursor: "pointer",
		display: "flex",
		justifyContent: "space-evenly",
		textDecoration: "none",
		listStyle: "none",
		fontSize: 14,
	},
	link: { textDecoration: "none", color:'#fff !important' },
	grid3: { maxHeight: "35px" },
	typo1: {
		fontSize: "16px",
		fontWeight: "400",
		lineHeight: "24px",
		color: "#242424",
		paddingTop: "12px",
	},
	CustomTextfield: {
		marginBottom: "8px",
		background: "#ffffff",
	},
	div1: { marginTop: "15px" },
	card: { minHeight: 434 },
	chip1: { borderRadius: "5px" },
	ListItem: {
		background: "white",
		cursor: "pointer",
	},
	Avatar1: {
		width: "85px",
		height: "85px",
		marginRight: "23px",
	},
	grid3: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		margin: "auto",
	},
	box1: {
		overflow: "hidden",
		overflowY: "scroll",
		maxHeight: "355px",
		paddingLeft: "16px",
		paddingRight: "16px",
	},
	framebox: {
		overflow: "hidden",
		overflowY: "scroll",
		maxHeight: "450px",
		paddingLeft: "16px",
		paddingRight: "16px",
	},
	grid4: { textAlign: "center" },
	grid5: { color: "#2f2f2f" },
	grid6: { textAlign: "right" },
	typo2: { color: "#666666", marginTop: 50 },
	img1: {
		width: "80%",
	},
}));

export default onBoardingUseStyles;
