import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import SustainabilityReportTop from "../../../widgets/sustainabilityTop/sustainabilityReportTop";
import SustainabilityReportPDF from "./sustainablityReportPDF";

class SustainablityReport extends Component {
  componentDidMount = () => {
    if (this.props.sustainabilityReport.pdfBlob === "") {
      this.props.getSustainabilityReport(
        this.props.login.token,
        this.props.assessment_id
      );
    }
  };
  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <SustainabilityReportTop {...this.props} />
          </Grid>
          <Grid
            item
            xs={12}
            className="scroll"
            style={{ height: "100vh", margin: 10 }}
          >
            <SustainabilityReportPDF {...this.props} />
          </Grid>
        </Grid>
      </>
    );
  }
}
export default SustainablityReport;
