var data = [
    {
      title: "What is ImGrows?",
      desc: "ImGrows is a SaaS platform that helps corporates plan their ESG roadmap and strategy, and enables sustainability reporting based on different global frameworks.",
    },
    {
      title: "What features does ImGrows offer?",
      desc: "ImGrows lets corporates plan their ESG roadmap and execute their ESG strategy. Corporates can also report their sustainability credentials using different global frameworks. The platform helps corporates to collect data on different ESG metrics while ensuring transparency and traceability of the data collected. The progress of ESG strategy execution can also be tracked using the platform and corporates can understand what changes are needed to be made in their strategy to help them meet their targets.",
    },
    {
      title: "Is ImGrows a paid platform?",
      desc: "Yes. Users can opt for a monthly or yearly subscription to the ImGrows platform. Currently we are in a closed beta testing and are offering free access to our platform to our users.",
    },
    {
      title: "Can I cancel my subscription at any time?",
      desc: "Yes, you can cancel your subscription any time. However, depending on your plan (monthly/annual) you can continue using the platform and access all its features till the end of your billing period.",
    },
    {
      title: "How do I subscribe to your service?",
      desc: "We are currently in closed beta testing and you can contact our team at sales@imgrows.in for help in setting up your account and accessing its features.",
    },
    {
      title: "What are the subscription options that you have?",
      desc: `We are currently in closed beta testing and you can get in touch with us at sales@imgrows.in to start using the platform and explore its features for free. We will shortly be launching monthly and annual subscription plans.`,
    },
  ];
  export default data;