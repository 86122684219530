import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Radio from '../../UI/radioButton/radioButton';

function DataControl(props) {
	const{defaultDataControl, setDefaultDataControl}=props;
  const handleChange = (event) => {
    setDefaultDataControl(event.target.value);
	// setDefaultData({...defaultData , dataControl:value})
  };
  useEffect(() => {
	props.fieldData.disclosureSettingData &&  setDefaultDataControl(
      props.fieldData.disclosureSettingData.data_control.toString()
    );
  }, [props.fieldData.disclosureSettingData.data_control]);
  return (
    <>
      <Box m={2}>
        <Box mt={4} mb={1}>
          <Typography variant="body2">Data Control</Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="caption" color="textSecondary">
            The responses tab has the control of how the responses can be seen
            and by whom.
          </Typography>
        </Box>
        <Divider />
        <Box mb={2} />
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={defaultDataControl}
            onChange={handleChange}
          >
            <FormControlLabel
              value='0'
              control={<Radio />}
              label={
                <>
                  <Typography>View by all</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Amoung all the Contributors any one in the list can view the
                    file
                  </Typography>
                </>
              }
            />
            <FormControlLabel
              value='1'
              control={<Radio />}
              label={
                <>
                  <Typography>Only approvers can view contribution</Typography>
                  <Typography variant="caption" color="textSecondary">
                    The contributions can be seen by the approvers and the users who posted it.
                  </Typography>
                </>
              }
            />
            {/* <FormControlLabel
              value="Only approvers can view contribution"
              control={<Radio />}
              label={
                <>
                  <Typography>Only approvers can view contribution</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Only approvers can view the file.
                  </Typography>
                </>
              }
            /> */}
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
}

export default DataControl;
