import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Table from "@material-ui/core/Table";
import Card from "@material-ui/core/Card";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { ListItemText } from "@material-ui/core";
import {
  getGeneralTabReportData,
  setGeneralTabData,
  setGeneralTabTaskData,
  setSelectedAssesment,
} from "../../../actions/datahubActions/datahubActions";
import { viewMemberAssign } from "../../../actions/reportListing/reportListingAction";

import { Redirect } from "react-router-dom";
import CustomChip from "../../UI/chip/chip";
import moment from "moment";
import { useState } from "react";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export function TableAccordian(props) {
  const dispatch = useDispatch();
  const { reportDetails, selectedAssessment } = useSelector(
    (state) => state.dataHub
  );
  const { token } = useSelector((state) => state.login);

  const { row, type, bodyArr } = props;
  const [open, setOpen] = React.useState(false);
  const { assessments } = useSelector((state) => state.reportlisting);

  const classes = useRowStyles();

  const [redirect, setRedirect] = React.useState(false);

  const [redirectState, setRedirectState] = useState({});

  React.useEffect(() => {
    selectedAssessment === row._id &&
      dispatch(getGeneralTabReportData(token, row?._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssessment]);

  const reportSections = [
    {
      name: "Maturity Matrix",
      step_no: 1,
    },
    {
      name: "Materiality assessment",
      step_no: 2,
    },
    {
      name: "ESG Strategy",
      step_no: 3,
    },
    {
      name: "Assessment Approval",
      step_no: 4,
    },
    {
      name: "Field Data",
      step_no: 5,
    },
    {
      name: "Field Data",
      step_no: 5,
    },
    {
      name: "Sustainability Report",
      step_no: 6,
    },
  ];

  const handleOpenAccordian = (id, rowOpen) => {
    if (type === "Task") {
      if (token && !rowOpen) {
        // setOpen(!open);
        // dispatch(setSelectedAssesment(id));
        const filterRow =
          bodyArr &&
          bodyArr.map((t) =>
            t._id === id ? { ...t, hasOpen: true } : { ...t, hasOpen: false }
          );

        dispatch(setGeneralTabTaskData(filterRow));
        dispatch(viewMemberAssign(token, id));
        setOpen(true);

        // dispatch(getAssignedMaterialityTopics(token, id));
      } else {
        setOpen(false);
        dispatch(setSelectedAssesment(false));
        const filterRow =
          bodyArr && bodyArr.map((t) => ({ ...t, hasOpen: false }));

        dispatch(setGeneralTabTaskData(filterRow));
      }
    } else {
      if (id === row._id && !open) {
        dispatch(setSelectedAssesment(id));
        setOpen(true);
      } else {
        setOpen(false);
        dispatch(setSelectedAssesment(false));
      }
    }
  };
  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/report_listing/esg_report/single_report",
          // pathname: "/dashboard_static/single_report",
          state: {
            step_no: redirectState?.step_no,
            assignment_id: row?._id,
            topicsData: redirectState?.topicsData,
            // metricsData: metricsData,
            framework_id: row?.framework_id,
            reportData: redirectState?.reportData,
            assigned_to: redirectState?.assigned_to,
            assigned_by: redirectState?.assigned_by,
            role: redirectState?.role,
            memberId: redirectState?.memberId,
            assessment_name: row.assessment_name,
            title: redirectState?.title,
            fromDashboard: true,
          },
        }}
      />
    );
  }
  const handleRedirect = (
    showSection,
    assignment_id,
    topicsData,
    // metricsData,
    reportData,
    assigned_to,
    assigned_by,
    role,
    assign_member_id,
    title
  ) => {
    setRedirectState({
      step_no: showSection,
      assignment_id,
      topicsData,
      // metricsData,
      reportData,
      assigned_to,
      assigned_by,
      role,
      assign_member_id,
      title,
    });
    setRedirect(true);
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          <ListItemText
            primary={
              row?.assessment_name
                ? row?.assessment_name
                : `Assessment-${row?._id.substring(row?._id.length - 4)} `
            }
            secondary={row?.framework_name}
          />
        </TableCell>
        <TableCell>
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
          {type !== "Task" && (
            <Chip
              style={
                row?.is_completed
                  ? {
                      backgroundColor: "#BFE2CD",
                      color: "#154328",
                    }
                  : {
                      backgroundColor: "#EBF1F8",
                      color: "#15314E",
                    }
              }
              icon={
                <FiberManualRecordIcon
                  style={
                    row?.is_completed
                      ? { fontSize: "10px", color: "#154328" }
                      : { fontSize: "10px", color: "#15314E" }
                  }
                />
              }
              // avatar={<Avatar>M</Avatar>}
              label={row?.is_completed ? "Complete" : "In Progress"}
            />
          )}
        </TableCell>

        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              handleOpenAccordian(row._id, row.hasOpen);
            }}
            // onClick={() => {
            //   setOpen(!open);
            //   !open && token && dispatch(apis(token, row._id));
            // }}
          >
            {(
              type !== "Task" ? selectedAssessment === row._id : row?.hasOpen
            ) ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={3}>
          {type !== "Task" && (
            <Collapse
              in={selectedAssessment === row._id}
              timeout="auto"
              unmountOnExit
            >
              <Box margin={0}>
                <List>
                  {Object.keys(reportDetails).map((topic, i) => {
                    return (
                      <ListItem
                        key={i}
                        style={{ padding: "5px", width: "100%" }}
                      >
                        <ListItemText primary={topic} />
                        <ListItemSecondaryAction>
                          <Chip
                            style={
                              reportDetails[topic] === "Complete"
                                ? {
                                    backgroundColor: "#BFE2CD",
                                    color: "#154328",
                                  }
                                : {
                                    backgroundColor: "#EBF1F8",
                                    color: "#15314E",
                                  }
                            }
                            icon={
                              <FiberManualRecordIcon
                                style={
                                  reportDetails[topic] === "Complete"
                                    ? { fontSize: "10px", color: "#154328" }
                                    : { fontSize: "10px", color: "#15314E" }
                                }
                              />
                            }
                            // avatar={<Avatar>M</Avatar>}
                            label={reportDetails[topic]}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Collapse>
          )}
          {type === "Task" && (
            <Collapse in={row?.hasOpen} timeout="auto" unmountOnExit>
              <Box style={{ padding: "0px" }} margin={0}>
                {assessments.filter((a) => a !== null).length > 0 ? (
                  reportSections?.map((section, index) => {
                    return (
                      assessments[index] &&
                      assessments[index].role.length > 0 && (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleRedirect(
                              section.step_no,
                              assessments[index].assessment_id,
                              assessments[index].topic_data,
                              // assessments[index].metric_data,
                              assessments[index].report_data,
                              assessments[index].assigned_to,
                              assessments[index].assigned_by,
                              assessments[index].role,
                              assessments[index]._id,
                              section.name
                            );
                          }}
                        >
                          <div>
                            <Typography
                              style={{ fontSize: 14, fontWeight: 500 }}
                            >
                              {section.name}
                            </Typography>
                            <Typography style={{ fontSize: 13, color: "grey" }}>
                              sent on{" "}
                              {assessments[index]?.assigned_on &&
                                moment(assessments[index]?.assigned_on).format(
                                  "lll"
                                )}
                            </Typography>
                          </div>
                          <div>
                            <List style={{ padding: "0px" }}>
                              {assessments[index]?.role.map((r, i) => {
                                // console.log('d',section.step_no,r,section.step_no === 4 || section.step_no === 6 ? (r === 1 ? 'Approver': r === 2 ? 'Reviewer':''): r === 0 ? "Contribution" : "Approval" )
                                return (
                                  <ListItem style={{ padding: "0px" }} key={i}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        border: "none",
                                        borderRadius: "12px",
                                        padding: "2px 8px",
                                        backgroundColor: "#EBF1F8",
                                      }}
                                      // variant="outlined"
                                      // color={
                                      //   section.step_no === 4 ||
                                      //   section.step_no === 6
                                      //     ? r === 1
                                      //       ? "primary"
                                      //       : r === 2
                                      //       ? "secondary"
                                      //       : ""
                                      //     : r === 0
                                      //     ? "secondary"
                                      //     : "primary"
                                      // }
                                      // color={r === 0 ? "secondary" : "primary"}
                                    >
                                      <div
                                        style={{
                                          width: "7px",
                                          height: "7px",
                                          borderRadius: "50%",
                                          backgroundColor: "#15314E",
                                        }}
                                      ></div>
                                      <Typography
                                        style={{
                                          color: "#15314E",
                                          fontSize: 13,
                                          fontWeight: 500,
                                          paddingLeft: 4,
                                        }}
                                      >
                                        {section.step_no === 4 ||
                                        section.step_no === 6
                                          ? r === 1
                                            ? "Approver"
                                            : r === 2
                                            ? "Reviewer"
                                            : ""
                                          : r === 0
                                          ? "Contribution"
                                          : "Approval"}
                                      </Typography>
                                    </div>
                                    {/* <CustomChip
                                  variant="outlined"
                                  color={
                                    section.step_no === 4 ||
                                    section.step_no === 6
                                      ? r === 1
                                        ? "primary"
                                        : r === 2
                                        ? "secondary"
                                        : ""
                                      : r === 0
                                      ? "secondary"
                                      : "primary"
                                  }
                                  // color={r === 0 ? "secondary" : "primary"}
                                  label={
                                    section.step_no === 4 ||
                                    section.step_no === 6
                                      ? r === 1
                                        ? "Approver"
                                        : r === 2
                                        ? "Reviewer"
                                        : ""
                                      : r === 0
                                      ? "Contribution"
                                      : "Approval"
                                  }
                                ></CustomChip> */}
                                  </ListItem>
                                );
                              })}
                            </List>
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <div>
                    <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                      No Task Assign
                    </Typography>
                  </div>
                )}
              </Box>
            </Collapse>
          )}
        </TableCell>
        {/* <TableCell>

        </TableCell>
        <TableCell></TableCell> */}
      </TableRow>
    </React.Fragment>
  );
}

TableAccordian.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};
