import React from 'react';
import { connect } from 'react-redux';
import GetStarted from '../../components/widgets/getStarted/getStarted';
import { getFrameworks } from '../../actions/sustainabilityReport/sustainabilityReportAction';
import {
  signup_with_email,
  setSideImage,
  setSideMessage,
  signupValidation,
  setSignupCard,
  setShowOnboarding,
  addOrganisationUnit,
  setLogin,
  updateOrganisationUnit,
  setIsNewCompany,
  signupWithEmail,
  verifyOtp,
  resendOtp,
  setCompanyName,
  getSectorData,
  getTopics,
  viewFrameworks,
  login_with_email,
  forgetPassword,
  setSignupStepNo,
  getCompanyLogo
} from '../../actions/login/loginActions';
import {
  getMainTopics,
  getIndustries
} from '../../actions/sustainabilityReport/sustainabilityReportAction';

class signupContainer extends React.PureComponent {
  render() {
    return <GetStarted {...this.props} />;
  }
}

const mapStateToProps = (store) => ({
  login: store.login,
  basicInfo: store.basicInfo,
  materialityAssessment: store.materialityAssessment
});

const mapDispatchToProps = (dispatch) => ({
  getMainTopics: (token, frameworkId) => {
    dispatch(getMainTopics(token, frameworkId));
  },getCompanyLogo: (domain) => {
    dispatch(getCompanyLogo(domain));
  },
  updateOrganisationUnit: (token, organisation_id, framework_id, topics) => {
    dispatch(
      updateOrganisationUnit(token, organisation_id, framework_id, topics)
    );
  },
  signup_with_email: (
    name,
    email,
    password,
    phone_no,
    company_name,
    company_type,
    employee_count
  ) => {
    dispatch(
      signup_with_email(
        name,
        email,
        password,
        phone_no,
        company_name,
        company_type,
        employee_count
      )
    );
  },
  signupValidation: (
    name,
    email,
    password,
    phone_no,
    checkBox,
    confirmPassword
  ) => {
    dispatch(
      signupValidation(
        name,
        email,
        password,
        phone_no,
        checkBox,
        confirmPassword
      )
    );
  },
  setSideImage: (payload) => {
    dispatch(setSideImage(payload));
  },
  setSideMessage: (payload) => {
    dispatch(setSideMessage(payload));
  },
  setSignupCard: (payload) => {
    dispatch(setSignupCard(payload));
  },
  setShowOnboarding: (payload) => {
    dispatch(setShowOnboarding(payload));
  },
  setLogin: (payload) => {
    dispatch(setLogin(payload));
  },
  addOrganisationUnit: (token, company_name, company_type, employee_count) => {
    dispatch(
      addOrganisationUnit(token, company_name, company_type, employee_count)
    );
  },
  getFrameworks: (token) => {
    dispatch(getFrameworks(token));
  },
  getIndustries: (token) => {
    dispatch(getIndustries(token));
  },
  setIsNewCompany: (payload) => {
    dispatch(setIsNewCompany(payload));
  },
  signupWithEmail: (name, email, password) => {
    dispatch(signupWithEmail(name, email, password));
  },
  verifyOtp: (token, otp) => {
    dispatch(verifyOtp(token, otp));
  },
  resendOtp: (token) => {
    dispatch(resendOtp(token));
  },
  setCompanyName: (payload) => {
    dispatch(setCompanyName(payload));
  },
  getSectorData: (token) => {
    dispatch(getSectorData(token));
  },
  getTopics: (token, organisation_id) => {
    dispatch(getTopics(token, organisation_id));
  },
  viewFrameworks: (token) => {
    dispatch(viewFrameworks(token));
  },
  login_with_email: (email, password) => {
    dispatch(login_with_email(email, password));
  },
  forgetPassword: (email,domain_name) => {
    dispatch(forgetPassword(email,domain_name));
  },
  setSignupStepNo: (payload) => {
    dispatch(setSignupStepNo(payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(signupContainer);
