import React from "react";
import { connect } from "react-redux";
import SustainabilityReport from "../../components/sustainabilityReport/sustainabilityReport";
import {
  getAssessmentReport,
  setAssessmentSkeleton
} from "../../actions/sustainabilityReport/sustainabilityReportAction";
import { assignMembers } from "../../actions/esgStrategy/esgStrategyAction";
import { getStakeholder } from "../../actions/materialityAction/materialityAction";
import { viewAllUsers } from "../../actions/generalSettings/generalSettingsAction";
import {
  updateReportName,
  addCover,
  addHeaderFooter,
  viewAssignedMembers,
  updateUserRole,
  deleteAssignedMember,
  resetPdfProgress
} from "../../actions/assessmentApproval/assessmentApproval";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";
import {
  getCommentsToc,
  viewCommentsToc,
  editToc,
  getSustainabilityReport
} from "../../actions/sustainabilityReport/sustainabilityReportPdfAction";
import {
  setApproversReviewers,
  getSustainabilityReportPdf,
  setPdfBlobs,
  setSustainabilityPdfProgress
} from "../../actions/sustainabilityReport/sustainabilityReportAction";
import {
  viewAllComments,
  viewComments,
  addCommentToc
} from "../../actions/comments/commentsAction";
import { removeFile } from "../../actions/generalSettings/generalSettingsAction";

export class SustainabilityReportCont extends React.PureComponent {
  render() {
    return <SustainabilityReport {...this.props} />;
  }
}

export const mapStateToProps = (store) => ({
  login: store.login,
  sustainabilityReport: store.sustainabilityReport,
  materialityAssessment: store.materialityAssessment,
  generalSettings: store.generalSettings,
  comments: store.comments,
  basicInfo: store.basicInfo,
  loadingBar: store.loadingBar
});

export const mapDispatchToProps = (dispatch) => ({
  updateReportName: (token, assessment_id, step_no, report_name, jwt_hash) => {
    dispatch(
      updateReportName(token, assessment_id, step_no, report_name, jwt_hash)
    );
  },
  getAssessmentReport: (token, assessmentId) => {
    dispatch(getAssessmentReport(token, assessmentId));
  },
  set_snack_bar: (status, message) => {
    dispatch(set_snack_bar(status, message));
  },
  getCommentsToc: (token, assessment_id, step_no) => {
    dispatch(getCommentsToc(token, assessment_id, step_no));
  },
  viewCommentsToc: (token, toc_id) => {
    dispatch(viewCommentsToc(token, toc_id));
  },
  addCommentToc: (token, toc_id, comment, assessment_topic_id, step_no,name,assessment_id) => {
    dispatch(
      addCommentToc(token, toc_id, comment, assessment_topic_id, step_no,name,assessment_id)
    );
  },
  editToc: (
    token,
    name,
    toc_id,
    assessment_topic_id,
    step_no,
    visible,
    order,
    assessment_id,
    is_text_changed
  ) => {
    dispatch(
      editToc(
        token,
        name,
        toc_id,
        assessment_topic_id,
        step_no,
        visible,
        order,
        assessment_id,
        is_text_changed
      )
    );
  },
  getSustainabilityReport: (token, assessment_id) => {
    dispatch(getSustainabilityReport(token, assessment_id));
  },
  setSustainabilityPdfProgress: (payload) => {
    dispatch(setSustainabilityPdfProgress(payload));
  },
  resetPdfProgress: (token, assessment_id, step_no) => {
    dispatch(resetPdfProgress(token, assessment_id, step_no));
  },
  setAssessmentSkeleton: (payload) => {
    dispatch(setAssessmentSkeleton(payload));
  },
  addCover: (
    token,
    assessment_id,
    step_no,
    front_text,
    back_text,
    back_img_front,
    logo_front,
    back_img_back,
    logo_back,
    jwt_hash,
    font,
    color,
    previously_selected_colors
  ) => {
    dispatch(
      addCover(
        token,
        assessment_id,
        step_no,
        front_text,
        back_text,
        back_img_front,
        logo_front,
        back_img_back,
        logo_back,
        jwt_hash,
        font,
        color,
        previously_selected_colors
      )
    );
  },
  assignMembers: (
    token,
    email_ids,
    step_no,
    assessment_id,
    organisation_id,
    topic_id,
    role,
    mailSubject,
    content
  ) => {
    dispatch(
      assignMembers(
        token,
        email_ids,
        step_no,
        assessment_id,
        organisation_id,
        topic_id,
        role,
        mailSubject,
        content
      )
    );
  },
  getStakeholder: (token, assessment_id, step_no) => {
    dispatch(getStakeholder(token, assessment_id, step_no));
  },
  viewAllUsers: (token, organisation_id, keyword) => {
    dispatch(viewAllUsers(token, organisation_id, keyword));
  },
  viewAllComments: (token, assessment_id, step_no) => {
    dispatch(viewAllComments(token, assessment_id, step_no));
  },
  viewComments: (token, toc_id, assessment_topic_id, step_no) => {
    dispatch(viewComments(token, toc_id, assessment_topic_id, step_no));
  },
  addHeaderFooter: (
    token,
    assessment_id,
    step_no,
    header_text,
    footer_text,
    logo,
    jwt_hash,
    page_no,
    is_page_no_position_left
  ) => {
    dispatch(
      addHeaderFooter(
        token,
        assessment_id,
        step_no,
        header_text,
        footer_text,
        logo,
        jwt_hash,
        page_no,
        is_page_no_position_left
      )
    );
  },
  viewAssignedMembers: (token, assessment_id, step_no) => {
    dispatch(viewAssignedMembers(token, assessment_id, step_no));
  },
  deleteAssignedMember: (token, stakeholder_id, assessment_id, step_no) => {
    dispatch(
      deleteAssignedMember(token, stakeholder_id, assessment_id, step_no)
    );
  },
  updateUserRole: (token, stakeholder_id, role, assessment_id, step_no) => {
    dispatch(
      updateUserRole(token, stakeholder_id, role, assessment_id, step_no)
    );
  },
  setApproversReviewers: (payload) => {
    dispatch(setApproversReviewers(payload));
  },
  removeFile: (token, assessment_id, step_no, url, field_name) => {
    dispatch(removeFile(token, assessment_id, step_no, url, field_name));
  },
  getSustainabilityReportPdf: (token, assessment_id, jwt_hash, step_no) => {
    dispatch(
      getSustainabilityReportPdf(token, assessment_id, jwt_hash, step_no)
    );
  },
  setPdfBlob: (payload) => {
    dispatch(setPdfBlobs(payload));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SustainabilityReportCont);
