import UNIVERSAL from "../../config/config";
import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { setActiveStep } from "../sustainabilityReport/sustainabilityReportAction";

export function updateBasicInfo(
  assessment_id,
  basicInfo,
  token,
  activeStep,
  isSave
) {
  var sectorData = [],
    sectorData2 = [];

  basicInfo.masterSectors.map((sector, index) => {
    sectorData.push({
      sector: sector._id,
      industry: []
    });

    sector.industry_data.map((industry, index2) => {
      if (industry.isSelected) {
        sectorData[index].industry.push(industry._id);
      }
      return true;
    });
    return true;
  });

  sectorData.map((sector, index) => {
    if (sector.industry.length > 0) {
      sectorData2.push(sector);
    }
    return true;
  });

  var formData = new FormData();

  var data = encrypt({
    "user-token": token,
    assessment_id: assessment_id,
    year_of_assessment: basicInfo.yearOfAssessment,
    assessment_name: basicInfo.assessmentName,
    sustainability_team_message: basicInfo.sustainibilityTeamMessage,
    esg_goal: basicInfo.esgGoal,
    about_report: basicInfo.aboutReport,
    comapny_name: basicInfo.companyName,
    company_operation: basicInfo.companyDescription,
    employee_count: basicInfo.numberOfEmployees,
    email: basicInfo.companyEmail,
    website: basicInfo.companyWebsite,
    head_office: {
      companyAddress: basicInfo.companyAddress,
      companyLandmark: basicInfo.companyLandmark,
      companyPincode: basicInfo.companyPincode,
      companyState: basicInfo.companyState,
      companyCountry: basicInfo.companyCountry
    },
    branch_office: basicInfo.branchAddressArray,
    sector: sectorData2,
    framework_id: basicInfo.frameworkId,
    organisation_id: localStorage.getItem("current_organisation")
  });
  formData.append("data", data);
  formData.append("logo", basicInfo.companyLogo);
  formData.append("additional_policy_document", basicInfo.basicFile);
  return (dispatch) => {
    if (basicInfo.assessmentName === "") {
      dispatch(set_snack_bar(true, "Enter Assessment Name !"));
    } else if (
      basicInfo.yearOfAssessment.startDate === "" ||
      basicInfo.yearOfAssessment.endDate === ""
    ) {
      dispatch(set_snack_bar(true, "Please select year of assessment"));
    } else if (basicInfo.companyName === "") {
      dispatch(set_snack_bar(true, "Please enter company name"));
    } else {
      dispatch(set_loader());
      return fetch(UNIVERSAL.BASEURL + "/assessments/update_basic_info", {
        method: "POST",
        body: formData
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            if (!isSave) {
              dispatch(setActiveStep(activeStep + 1));
            }
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          } else {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          }
          dispatch(unset_loader());
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
}
