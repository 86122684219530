import React, { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import DataHubSurveyCrumb from "../../components/screen/dataHubSurvey/SelectedSurvey/DataHubSurveyCrumb";
import { useDispatch, useSelector } from "react-redux";
import DataHubTabs from "../../components/screen/dataHub/dataHubTabs";
import SelectedSurveyButtons from "../../components/screen/dataHubSurvey/SelectedSurvey/SelectedSurveyButtons";
import { getSurveyDetails } from "../../actions/datahubActions/datahubActions";
import SelectedSurveyTable from "../../components/screen/dataHubSurvey/SelectedSurvey/SelectedSurveyTable";

const DataHubSelectedSurveyCont = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);
  const { selectedDashboardSurvey, surveyDetails } = useSelector(
    (state) => state.dataHub
  );

  console.log(selectedDashboardSurvey)

  // State for active tab
  const [activeTab, setActiveTab] = useState(0);

  // Combine the crumbTabs array with selectedDashboardSurvey?.survey_name
  const crumbTabs = ["Data Hub", "Survey"];
  if (selectedDashboardSurvey?.survey_name) {
    crumbTabs.push(selectedDashboardSurvey.survey_name);
  }

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Define tabs
  const tabs = [
    { tab: "Raw Data", value: 0 },
    // { tab: "Cube Dashboard", value: 1 },
  ];

  useEffect(() => {
    dispatch(getSurveyDetails(token, selectedDashboardSurvey?.survey_id));
  }, []);

  return (
    <Container maxWidth={false} disableGutters>
      {/* Breadcrumb */}
      <div
        style={{
          backgroundColor: "#F3F4F4",
          minHeight: "91vh",
          marginLeft: "-10px",
        }}
      >
        <DataHubSurveyCrumb
          crumbTabs={crumbTabs}
          back={() => window.history.back()}
        />

        <Grid container>
          <Grid
            item
            md={8}
            xs={12}
            sm={12}
            style={{ paddingLeft: "20px", marginTop: "1%" }}
          >
            {/* Tabs */}
            {/* <DataHubTabs
              value={activeTab}
              handleTabs={handleTabChange}
              tabs={tabs}
            /> */}
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "25px",
            }}
          >
            <SelectedSurveyButtons />
          </Grid>
        </Grid>

        {/* Body */}
        <div style={{ margin: "10px 20px" }}>
          {activeTab === 0 && (
            <SelectedSurveyTable surveyDetails={surveyDetails} />
          )}
          {/* {activeTab === 1 && <div>Content for Tab 2</div>} */}
        </div>
      </div>
    </Container>
  );
};

export default DataHubSelectedSurveyCont;
