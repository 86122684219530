import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CustomTextfield from "../../../UI/textfield/textfield";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";

import "../../../../styles/hidescroll.css";

import SingleMetricReport from "./singleMetricReport";
import Threads from "../../viewThreads/viewThreads";
import CustomButton from "../../../UI/button/button";
import { sortList } from "../../../../utils/reportData/reportDataRightUtils";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MuiTableCell from "@material-ui/core/TableCell";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExploreData from "../../fieldData/exploreTask";
import CloseIcon from "@material-ui/icons/Close";
import NearMeOutlinedIcon from "@material-ui/icons/NearMeOutlined";
import Guidelines from "../Guidelines";
import {
  setAutoFillSuggestions,
  setKpiSuggestions,
} from "../../../../actions/datahubActions/datahubActions";

const useStyles = makeStyles({
  blueText: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "600",
    color: "#3374B9",
    padding: "16px 0px",
  },
  grayText: {
    fontSize: 14,
    lineHeight: "21px",
    color: "#9FA1AF",
  },
  statusCell: {
    padding: "7px 2px",
  },
});

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: 7,
  },
})(MuiTableCell);

function TopicIcon(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_7080_118513)">
        <rect x="8" y="8" width="40" height="40" rx="20" fill="white" />
        <g clipPath="url(#clip0_7080_118513)">
          <path
            d="M32.998 28.834C32.2651 28.8315 31.5518 29.0714 30.9694 29.5164C30.387 29.9614 29.968 30.5865 29.7779 31.2944C29.5877 32.0023 29.6369 32.7532 29.9179 33.4301C30.1989 34.1071 30.6958 34.6722 31.3314 35.0373V37.3982C31.3313 37.517 31.3665 37.6333 31.4325 37.7321C31.4985 37.831 31.5924 37.908 31.7022 37.9535C31.812 37.999 31.9329 38.0109 32.0495 37.9877C32.1661 37.9645 32.2732 37.9073 32.3572 37.8232L32.998 37.1832L33.6389 37.8232C33.7229 37.9073 33.83 37.9645 33.9466 37.9877C34.0631 38.0109 34.184 37.999 34.2938 37.9535C34.4036 37.908 34.4975 37.831 34.5635 37.7321C34.6295 37.6333 34.6647 37.517 34.6647 37.3982V35.0373C35.3002 34.6722 35.7972 34.1071 36.0782 33.4301C36.3591 32.7532 36.4084 32.0023 36.2182 31.2944C36.028 30.5865 35.6091 29.9614 35.0267 29.5164C34.4442 29.0714 33.731 28.8315 32.998 28.834Z"
            fill="#3374B9"
          />
          <path
            d="M28 32.1667C27.999 31.2887 28.2294 30.4259 28.6682 29.6653C29.1069 28.9048 29.7384 28.2734 30.4989 27.8347C31.2595 27.396 32.1223 27.1656 33.0003 27.1667C33.8783 27.1678 34.7405 27.4003 35.5 27.8408V22.1667C35.4987 21.062 35.0593 20.003 34.2781 19.2218C33.497 18.4407 32.438 18.0013 31.3333 18H24.6667C23.562 18.0013 22.503 18.4407 21.7218 19.2218C20.9407 20.003 20.5013 21.062 20.5 22.1667V32.1667C20.5013 33.2713 20.9407 34.3304 21.7218 35.1115C22.503 35.8926 23.562 36.332 24.6667 36.3333H29.6667V35.8833C29.1426 35.4163 28.7231 34.8438 28.4359 34.2032C28.1487 33.5627 28.0001 32.8687 28 32.1667ZM24.6667 21.3333H31.3333C31.5543 21.3333 31.7663 21.4211 31.9226 21.5774C32.0789 21.7337 32.1667 21.9457 32.1667 22.1667C32.1667 22.3877 32.0789 22.5996 31.9226 22.7559C31.7663 22.9122 31.5543 23 31.3333 23H24.6667C24.4457 23 24.2337 22.9122 24.0774 22.7559C23.9211 22.5996 23.8333 22.3877 23.8333 22.1667C23.8333 21.9457 23.9211 21.7337 24.0774 21.5774C24.2337 21.4211 24.4457 21.3333 24.6667 21.3333ZM24.6667 24.6667H31.3333C31.5543 24.6667 31.7663 24.7545 31.9226 24.9107C32.0789 25.067 32.1667 25.279 32.1667 25.5C32.1667 25.721 32.0789 25.933 31.9226 26.0893C31.7663 26.2455 31.5543 26.3333 31.3333 26.3333H24.6667C24.4457 26.3333 24.2337 26.2455 24.0774 26.0893C23.9211 25.933 23.8333 25.721 23.8333 25.5C23.8333 25.279 23.9211 25.067 24.0774 24.9107C24.2337 24.7545 24.4457 24.6667 24.6667 24.6667ZM27.1667 29.6667H24.6667C24.4457 29.6667 24.2337 29.5789 24.0774 29.4226C23.9211 29.2663 23.8333 29.0543 23.8333 28.8333C23.8333 28.6123 23.9211 28.4004 24.0774 28.2441C24.2337 28.0878 24.4457 28 24.6667 28H27.1667C27.3877 28 27.5996 28.0878 27.7559 28.2441C27.9122 28.4004 28 28.6123 28 28.8333C28 29.0543 27.9122 29.2663 27.7559 29.4226C27.5996 29.5789 27.3877 29.6667 27.1667 29.6667Z"
            fill="#3374B9"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7080_118513"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.454902 0 0 0 0 0.72549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7080_118513"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7080_118513"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7080_118513">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(18 18)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default function FieldDataSurveyReport(props) {
  const dispatch = useDispatch();
  const { fieldData, assignMetricIndex, explore, setExplore } = props;
  const classes = useStyles();
  const scrollRef = useRef();
  const [guidelines, setGuidelines] = useState([]);
  const [viewGuidelines, setViewGuideLines] = useState(false);
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   scrollRef.current.focus();
  //   fieldData.assignedMetric &&
  //     Array.isArray(fieldData.assignedMetric.final_data) &&
  //     fieldData.assignedMetric.final_data.map((metric) => {
  //       if (metric.guidelines) {
  //         setGuidelines((prevState) => [...prevState, metric.guidelines]);
  //       }
  //       if (!metric.guidelines) {
  //         if (!metric.sub_metrics) return;
  //         if (metric.sub_metrics.length !== 0) {
  //           return metric.sub_metrics.map((sm) => {
  //             if (sm.guidelines) {
  //               setGuidelines((prevState) => [...prevState, sm.guidelines]);
  //             }
  //           });
  //         }
  //       }
  //     });
  //   return () => {
  //     // clear all the states for task in right side
  //     setGuidelines([]);
  //   };
  // }, [fieldData.assignedMetric]);

  const [filtered, setFiltered] = useState([]);
  const [sort, setSort] = useState("Show all");
  const [finalData, setFinalData] = useState({
    open: false,
    index: null,
    data: null,
  });

  useEffect(() => {
    // to update the state called finalData, drilled to chat thread
    // for updating the metric Status
    fieldData.assignedMetric &&
      fieldData.assignedMetric?.final_data &&
      fieldData.assignedMetric.final_data.map((metric) => {
        if (metric.sub_metrics.length === 0) {
          if (metric._id === finalData?.metric?._id) {
            setFinalData((prev) => {
              return { ...prev, metric: metric };
            });
          }
        } else {
          return metric.sub_metrics.map((submetric) => {
            if (submetric._id === finalData?.metric?._id) {
              setFinalData((prev) => {
                return { ...prev, metric: submetric };
              });
            }
          });
        }
      });
  }, [fieldData.assignedMetric.final_data]);
  // console.log('finalData',finalData)
  const [is_approver, setIs_approver] = useState(
    props.location.state.role[0] === 0 ? false : true
  );

  return (
    <div>
      <Card>
        <CardContent className="scroll" style={{ height: "80vh" }}>
          <div tabIndex="0" ref={scrollRef}></div>
          {fieldData.assignedMetric && fieldData.assignedMetric.final_data && (
            <Box>
              <>
                {!explore && !viewGuidelines ? (
                  <>
                    {fieldData.currentMetric._id && (
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.homeHeadingContainer}>
                          <TopicIcon />
                          <Typography
                            style={{
                              fontWeight: "600",
                              wordBreak: "break-all",
                              hyphens: "auto",
                            }}
                          >
                            {fieldData.currentMetric.metric_name}
                          </Typography>
                        </div>
                        <div>
                          <CustomButton
                            disabled={
                              fieldData.metrics?.metric_data &&
                              fieldData.metrics.metric_data.length === 0
                            }
                            // onClick={() => console.log('click')}
                            // startIcon={<PersonAddOutlinedIcon />}
                          >
                            Assign
                          </CustomButton>
                        </div>
                      </Box>
                    )}
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <TopicIcon style={{ fontSize: 40 }} />
                        {/* <Typography
                          style={{ fontWeight: '600', paddingLeft: 16 }}
                        >
                           {console.log(
                            'reportDataTask',
                            assignMetricIndex,
                            fieldData.reportDataTask
                          )}
                          {
                            fieldData.reportDataTask[assignMetricIndex]
                              .metric_name
                          }
                        </Typography> */}
                      </Box>
                      {/* <Box>
                        {guidelines.length > 0 && (
                          <Button
                            color="primary"
                            style={{ textTransform: "none" }}
                            onClick={() => setViewGuideLines(true)}
                          >
                            <NearMeOutlinedIcon color="primary" />
                            Guidelines
                          </Button>
                        )}
                      </Box> */}
                    </Box>

                    <div
                      className="scroll"
                      style={{ paddingBottom: "25px", height: "70vh" }}
                    >
                      {fieldData.assignedMetric &&
                      Array.isArray(fieldData.assignedMetric.final_data) &&
                      fieldData.assignedMetric.final_data.length > 0 ? (
                        <>
                          <div>
                            <Grid container md={12} sm={12}>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  border: "none",
                                  borderRadius: "8px",
                                  width: "130px",
                                  height: "110px",
                                  backgroundColor: "#EBF1F8",
                                  marginTop: "20px",
                                }}
                              >
                                <Grid style={{ width: "100%" }}>
                                  {" "}
                                  <Typography
                                    style={{
                                      // wordBreak: 'break',
                                      color: "#3374B9",
                                      fontSize: "14px",
                                      textAlign: "left",
                                      padding: "8px 2px 8px 8px",
                                    }}
                                  >
                                    No. Of Discosures
                                  </Typography>
                                </Grid>
                                <Grid style={{ width: "100%" }}>
                                  <Typography
                                    style={{
                                      fontSize: "36px",
                                      alignContent: "bottom",
                                      padding: "0px 12px",
                                      color: "#3374B9",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {fieldData.assignedMetric &&
                                    !fieldData.assignedMetric
                                      .total_no_of_disclosures
                                      ? "-"
                                      : fieldData.assignedMetric &&
                                        fieldData.assignedMetric
                                          .total_no_of_disclosures}
                                  </Typography>
                                </Grid>
                              </Grid>
                              {/* <Grid
                                item
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  border: 'none',
                                  borderRadius: '8px',
                                  width: '130px',
                                  height: '110px',
                                  backgroundColor: '#EBF1F8',
                                  marginTop: '20px',
                                  marginLeft: '15px',
                                }}
                              >
                                <Grid style={{ width: '100%' }}>
                                  {' '}
                                  <Typography
                                    style={{
                                      // wordBreak: 'break',
                                      color: '#3374B9',
                                      fontSize: '14px',
                                      textAlign: 'left',
                                      padding: '8px 2px 8px 8px',
                                    }}
                                  >
                                    Data Points Submitted
                                  </Typography>
                                </Grid>
                                <Grid style={{ width: '100%' }}>
                                  <Typography
                                    style={{
                                      fontSize: '36px',
                                      alignContent: 'bottom',
                                      padding: '0px 12px',
                                      color: '#3374B9',
                                      fontWeight: 500,
                                    }}
                                  >
                                    {fieldData.assignedMetric &&
                                    !fieldData.assignedMetric
                                      .total_data_points_submitted
                                      ? '-'
                                      : fieldData.assignedMetric &&
                                        fieldData.assignedMetric
                                          .total_data_points_submitted}
                                  </Typography>
                                </Grid>
                              </Grid> */}
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  border: "none",
                                  borderRadius: "8px",
                                  width: "130px",
                                  height: "110px",
                                  backgroundColor: "#EBF1F8",
                                  marginTop: "20px",
                                  marginLeft: "15px",
                                }}
                              >
                                <Grid style={{ width: "100%" }}>
                                  {" "}
                                  <Typography
                                    style={{
                                      // wordBreak: 'break',
                                      color: "#3374B9",
                                      fontSize: "14px",
                                      textAlign: "left",
                                      padding: "8px 2px 8px 8px",
                                    }}
                                  >
                                    Data Points Approved
                                  </Typography>
                                </Grid>
                                <Grid style={{ width: "100%" }}>
                                  <Typography
                                    style={{
                                      fontSize: "36px",
                                      alignContent: "bottom",
                                      padding: "0px 12px",
                                      color: "#3374B9",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {fieldData.assignedMetric &&
                                    !fieldData.assignedMetric
                                      .total_data_points_approved
                                      ? "-"
                                      : fieldData.assignedMetric &&
                                        fieldData.assignedMetric
                                          .total_data_points_approved}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                          <Box>
                            {/* {guidelines.length !== 0 && (
                              <>
                                <Typography className={classes.blueText}>
                                  Guidelines
                                </Typography>
                                {guidelines.map((guideline, index) => (
                                  <Typography
                                    key={index}
                                    className={classes.grayText}
                                  >
                                    {`${index + 1}. ${guideline}`}
                                  </Typography>
                                ))}
                              </>
                            )} */}
                            {false && (
                              <>
                                <Typography className={classes.blueText}>
                                  Technical Protocols
                                </Typography>
                                <Typography className={classes.grayText}>
                                  1. Phasellus elementum integer libero, egestas
                                  libero dignissim amet magna et. Mi lectus diam
                                  elementum cras augue enim felis aliquet.
                                </Typography>
                                <Typography className={classes.grayText}>
                                  2. Orci et vitae malesuada augue ultrices.
                                  Accumsan, massa amet dictum vel eget sed duis
                                  nam orci.
                                </Typography>
                                <Typography className={classes.blueText}>
                                  Metric Notes
                                </Typography>
                                <Typography className={classes.grayText}>
                                  1. Phasellus elementum integer libero, egestas
                                  libero dignissim amet magna et. Mi lectus diam
                                  elementum cras augue enim felis aliquet.
                                </Typography>
                                <Typography className={classes.grayText}>
                                  2. Orci et vitae malesuada augue ultrices.
                                  Accumsan, massa amet dictum vel eget sed duis
                                  nam orci.
                                </Typography>
                              </>
                            )}
                          </Box>
                          {/* <TableContainer component={Paper}> */}
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      borderBottom: 0,
                                      padding: "5px 0px",
                                    }}
                                  >
                                    Disclosure
                                  </TableCell>
                                  <TableCell
                                    style={{ borderBottom: 0, padding: 5 }}
                                  >
                                    Description
                                  </TableCell>
                                  <TableCell
                                    style={{ borderBottom: 0, padding: 5 }}
                                    align="center"
                                  >
                                    Status
                                  </TableCell>
                                  <TableCell
                                    style={{ borderBottom: 0, padding: 0 }}
                                    align="center"
                                  >
                                    Explore
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {/* {sort === 'Show all' ? ( */}
                                {sort === "Show all" ? (
                                  fieldData.assignedMetric &&
                                  Array.isArray(
                                    fieldData.assignedMetric.final_data
                                  ) &&
                                  fieldData.assignedMetric.final_data?.map(
                                    (metric, index) => (
                                      <>
                                        {metric.sub_metrics.length > 0 ? (
                                          <>
                                            <TableRow key={index}>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    width: "42px",
                                                    height: "30px",
                                                    padding: "5px",
                                                    border: "none",
                                                    backgroundColor: "#EBF1F8",
                                                    color: "#3374B9",
                                                    borderRadius: "5px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid #3374B9",
                                                      backgroundColor:
                                                        "#3374B9",
                                                      margin: "2px",
                                                      borderRadius: "5px",
                                                      width: "3px",
                                                      height: "18px",
                                                      fontWeight: "bold",
                                                    }}
                                                  ></div>
                                                  <div>
                                                    <Typography
                                                      style={{
                                                        padding:
                                                          "2px 5px 2px 2px",
                                                      }}
                                                    >
                                                      {index + 1}
                                                    </Typography>
                                                  </div>
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                style={{ fontSize: "13px" }}
                                                colSpan={2}
                                              >
                                                {metric.metric_name}
                                                {metric.metric_desc}
                                              </TableCell>
                                              {/* <TableCell></TableCell> */}
                                              {/* <TableCell align="center"> */}
                                              {/* <IconButton
                                          size="small"
                                          onClick={() =>
                                            setFinalData({ open: true, index: index, data: props })
                                          }
                                        >
                                          <ArrowForwardIosIcon fontSize="small" />
                                        </IconButton> */}
                                              {/* </TableCell> */}
                                            </TableRow>

                                            {metric.sub_metrics?.map(
                                              (subMetric, subIndex) => (
                                                <TableRow key={subIndex}>
                                                  <TableCell
                                                    component="th"
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                    scope="row"
                                                  >
                                                    {`${index + 1} (${
                                                      subMetric.metric_name.split(
                                                        ":"
                                                      )[0]
                                                    })`}
                                                    {/* {`(${subMetric.metric_name.substring(
                                                      0,
                                                      1
                                                    )})`} */}
                                                  </TableCell>
                                                  <TableCell
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {
                                                      subMetric.metric_name.split(
                                                        ":"
                                                      )[1]
                                                    }
                                                    {subMetric.metric_desc}
                                                  </TableCell>
                                                  <TableCell
                                                    className={
                                                      classes.statusCell
                                                    }
                                                  >
                                                    <Box
                                                      display="flex"
                                                      alignItems="center"
                                                      bgcolor={
                                                        true
                                                          ? "#EBF6EF"
                                                          : "#E9E9E9"
                                                      }
                                                      // p={1}
                                                      style={{
                                                        width: "120px",
                                                        padding: "5px",
                                                      }}
                                                      borderRadius="8px"
                                                    >
                                                      <Box
                                                        borderRadius="50%"
                                                        height={10}
                                                        width={10}
                                                        bgcolor={
                                                          is_approver
                                                            ? subMetric.is_approved
                                                              ? "#5BB37F"
                                                              : "#EBD947"
                                                            : !is_approver &&
                                                              subMetric.is_approved
                                                            ? "#5BB37F"
                                                            : subMetric.is_awaiting_approval
                                                            ? "#EBD947"
                                                            : "#EBD947"
                                                        }
                                                      />
                                                      <Box
                                                        style={{
                                                          paddingLeft: "2px",
                                                          fontSize: "10px",
                                                        }}
                                                      >
                                                        {/* {subMetric.is_awaiting_approval
                                                          ? subMetric.is_approved
                                                            ? 'Approved'
                                                            : 'Awaiting Approval'
                                                          : 'Yet to be Assigned'} */}
                                                        {/* {is_approver
                                                          ? subMetric.is_approved
                                                            ? "Approved"
                                                            : "Awaiting Approval"
                                                          : 
                                                            subMetric.is_approved
                                                          ? "Approved"
                                                          : subMetric.is_awaiting_approval
                                                          ? "Awaiting Approval"
                                                          : "In Progress"} */}

                                                        {subMetric?.is_approved &&
                                                        subMetric?.is_awaiting_approval
                                                          ? "Approved"
                                                          : subMetric?.is_awaiting_approval &&
                                                            !subMetric?.is_approved
                                                          ? "Awaiting Approval"
                                                          : "In Progress"}
                                                      </Box>
                                                    </Box>
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    style={{ padding: 0 }}
                                                  >
                                                    <IconButton
                                                      size="small"
                                                      onClick={() => {
                                                        // getdetailedMetrics(
                                                        //   login.token,
                                                        //   subMetric._id,
                                                        //   assessment_id
                                                        // );
                                                        // console.log(
                                                        //   'subMetric',
                                                        //   subMetric
                                                        // );
                                                        setGuidelines(
                                                          subMetric?.guidelines
                                                        );
                                                        setFinalData({
                                                          open: true,
                                                          index: index,
                                                          data: props,
                                                          metric: subMetric,
                                                          subIndex: subIndex,
                                                        });
                                                        props.location.state
                                                          .role[0] === 0
                                                          ? setExplore(!explore)
                                                          : setOpen(true);
                                                        //  setExplore(!explore);
                                                      }}
                                                    >
                                                      <ArrowForwardIosIcon fontSize="small" />
                                                    </IconButton>
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                  width: "42px",
                                                  height: "30px",
                                                  padding: "5px",
                                                  border: "none",
                                                  backgroundColor: "#EBF1F8",
                                                  color: "#3374B9",
                                                  borderRadius: "5px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    border: "1px solid #3374B9",
                                                    backgroundColor: "#3374B9",
                                                    margin: "2px",
                                                    borderRadius: "5px",
                                                    width: "3px",
                                                    height: "18px",
                                                    fontWeight: "bold",
                                                  }}
                                                ></div>
                                                <div>
                                                  <Typography
                                                    style={{
                                                      padding:
                                                        "2px 5px 2px 2px",
                                                    }}
                                                  >
                                                    {index + 1}
                                                  </Typography>
                                                </div>
                                              </div>
                                            </TableCell>
                                            <TableCell
                                              style={{ fontSize: "13px" }}
                                            >
                                              {metric.metric_name}
                                              {metric.metric_desc}
                                            </TableCell>
                                            <TableCell
                                              className={classes.statusCell}
                                            >
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                                bgcolor={
                                                  true ? "#EBF6EF" : "#E9E9E9"
                                                }
                                                // p={1}
                                                style={{
                                                  width: "120px",
                                                  padding: "5px",
                                                }}
                                                borderRadius="8px"
                                              >
                                                <Box
                                                  borderRadius="50%"
                                                  height={10}
                                                  width={10}
                                                  bgcolor={
                                                    is_approver
                                                      ? metric.is_approved
                                                        ? "#5BB37F"
                                                        : "#EBD947"
                                                      : !is_approver &&
                                                        metric.is_approved
                                                      ? "#5BB37F"
                                                      : metric.is_awaiting_approval
                                                      ? "#EBD947"
                                                      : "#EBD947"
                                                  }
                                                  // color={
                                                  //   metric.is_awaiting_approval
                                                  //     ? metric.is_approved
                                                  //       ? '#5BB37F'
                                                  //       : '#15314E'
                                                  //     : '#EBD947'
                                                  // }
                                                />
                                                <Box
                                                  style={{
                                                    paddingLeft: "5px",
                                                    fontSize: "10px",
                                                  }}
                                                >
                                                  {/* {is_approver
                                                    ? metric.is_awaiting_approval
                                                      ? metric.is_approved
                                                        ? 'Approved'
                                                        : 'Awaiting Approval'
                                                      : 'In Progress..'
                                                    : !is_approver &&
                                                      metric.is_approved
                                                    ? 'Approved'
                                                    : 'Awaiting Approval'} */}
                                                  {/* {is_approver
                                                    ? metric.is_approved
                                                      ? "Approved"
                                                      : "Awaiting Approval"
                                                    : !is_approver &&
                                                      metric.is_approved
                                                    ? "Approved"
                                                    : metric.is_awaiting_approval
                                                    ? "Awaiting Approval"
                                                    : "In Progress"} */}

                                                  {metric?.is_approved &&
                                                  metric?.is_awaiting_approval
                                                    ? "Approved"
                                                    : metric?.is_awaiting_approval &&
                                                      !metric?.is_approved
                                                    ? "Awaiting Approval"
                                                    : "In Progress"}
                                                </Box>
                                              </Box>
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              style={{ pading: 0 }}
                                            >
                                              <IconButton
                                                size="small"
                                                // onClick={() =>
                                                //   setFinalData({ open: true, index: index, data: props })
                                                // }
                                                onClick={() => {
                                                  // console.log('metric', metric);
                                                  // console.log('metric', props.location.state.role[0]===0);
                                                  // getdetailedMetrics(login.token, metric._id, assessment_id);
                                                  setFinalData({
                                                    open: true,
                                                    index: index,
                                                    data: props,
                                                    metric: metric,
                                                    subIndex: 0,
                                                  });

                                                  setGuidelines(
                                                    metric?.guidelines
                                                  );
                                                  props.location.state
                                                    .role[0] === 0
                                                    ? setExplore(!explore)
                                                    : setOpen(true);
                                                  // setExplore(!explore)
                                                }}
                                              >
                                                <ArrowForwardIosIcon fontSize="small" />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </>
                                      // <SingleMetricReport
                                      //   {...props}
                                      //   setFinalData={setFinalData}
                                      //   metric={metric}
                                      //   index={index}
                                      //   key={index}
                                      // />
                                    )
                                  )
                                ) : filtered.length === 0 ? (
                                  <TableRow>
                                    <Typography style={{ textAlign: "center" }}>
                                      No Data Found
                                    </Typography>
                                  </TableRow>
                                ) : (
                                  filtered.length !== 0 &&
                                  filtered.map((metric, index) => (
                                    <>hw</>
                                    // <SingleMetricReport
                                    //   {...props}
                                    //   setFinalData={setFinalData}
                                    //   metric={metric}
                                    //   index={index}
                                    //   key={index}
                                    // />
                                  ))
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      ) : fieldData.assignedMetric === 0 ? (
                        <>
                          {" "}
                          <Card
                            variant="elevation"
                            elevation={0}
                            className={classes.CardLoader}
                          >
                            <CardContent className={classes.CardContentLoader}>
                              <CircularProgress className={classes.loader} />
                            </CardContent>
                          </Card>
                        </>
                      ) : (
                        <>
                          <Box className={classes.box}>
                            <Typography className={classes.typography}>
                              No Topic Assigned
                            </Typography>
                          </Box>
                        </>
                      )}
                    </div>
                    {open && (
                      <Threads
                        task={true}
                        main={false}
                        is_approver={is_approver}
                        {...props}
                        open={open}
                        setOpen={setOpen}
                        metric={{ ...finalData.metric }}
                        onClose={() => setOpen(false)}
                        isTaskSection={true}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {/* {console.log('finalData',finalData)} */}
                    {explore && (
                      <ExploreData
                        is_approver={is_approver}
                        explore={explore}
                        setExplore={setExplore}
                        finalData={finalData}
                        setFinalData={setFinalData}
                        fieldData={fieldData}
                        {...props}
                        open={open}
                        setOpen={setOpen}
                        guidelines={guidelines}
                        // index={finalData.index}
                      />
                    )}
                    {viewGuidelines && (
                      <Guidelines
                        guidelines={guidelines}
                        setViewGuideLines={setViewGuideLines}
                      />
                    )}
                  </>
                )}
              </>
            </Box>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
// {
/* <Card>
        <CardContent className="scroll" style={{ height: '80vh' }}>
          <div tabIndex="0" ref={scrollRef}></div>
          {fieldData.assignedMetric.length !== 0 && (
            <Box>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <TopicIcon style={{ fontSize: 40 }} />
                  <Typography style={{ fontWeight: '600', paddingLeft: 16 }}>
                    {fieldData.reportDataTask[assignMetricIndex].metric_name}
                  </Typography>
                </Box>
               
              </Box>
              {guidelines.length !== 0 && (
                <>
                  <Typography className={classes.blueText}>
                    Guidelines
                  </Typography>
                  {guidelines.map((guideline, index) => (
                    <Typography className={classes.grayText}>
                      {`${index + 1}. ${guideline}`}
                    </Typography>
                  ))}
                </>
              )}
              {fieldData.assignedMetric.map((metric, index) => (
                <>
                  <SingleMetricReport
                    {...props}
                    open={open}
                    setOpen={setOpen}
                    metric={metric}
                    index={index}
                  />
                </>
              ))}
              {fieldData.surveyType !== 0 &&
              fieldData.surveyResult.survey_data !== undefined ? (
                <>
                  {/* <CardContent className="scroll" style={{ height: '80vh' }}>
                {fieldData.surveyType === 1 && (
                  <SurveyTypeOne
                    surveyResult={fieldData.surveyResult}
                    setSurveyResult={setSurveyResult}
                    setSurveyData={setSurveyData}
                  />
                )}
                {fieldData.surveyType === 2 && (
                  <SurveyTypeTwo
                    surveyResult={fieldData.surveyResult}
                    etSurveyResult={setSurveyResult}
                    setSurveyData={setSurveyData}
                    role={role}
                  />
                )}
                {fieldData.surveyType === 3 && (
                  <SurveyTypeThree
                    surveyResult={fieldData.surveyResult}
                    setSurveyResult={setSurveyResult}
                    setSurveyData={setSurveyData}
                  />
                )}
                <Grid container>
                  <Grid item xs={10}></Grid>
                  <Grid item xs={2}>
                    <CustomButton
                      variant="contained"
                      color="secondary"
                      disabled={role === 1}
                      style={{ marginTop: 20 }}
                      onClick={() => {
                        upsetSurveyResult(
                          login.token,
                          fieldData.surveyResult.survey_data._id,
                          assessment_id,
                          fieldData.surveyData,
                          login.current_organisation
                        );
                      }}
                    >
                      Save
                    </CustomButton>
                  </Grid>
                </Grid>
               </CardContent> */
// }
//         </>
//       ) : (
// <CardContent className="scroll" style={{ height: '80vh' }}>
//   <ESGReportSkeleton />
// </CardContent>
//    ''
//        )}
//       </Box>
//    )}
//    </CardContent>
// </Card> */}
