import { useEffect, useState } from "react";

import { Tab, Tabs, MuiThemeProvider, Box } from "@material-ui/core";

import MainHeader from "../../screen/vendorAdminScreen/MainHeader";
import { appTheme } from "../riskAssessment/brmDetaails";
import AssignedTab from "./SelectedTaskComps/AssignedTab";
import CompletedTab from "./SelectedTaskComps/CompletedTab";
import { useDispatch, useSelector } from "react-redux";
import { getTaskAssignedSurveys } from "../../../actions/taskActions/TaskAction";
import InActiveTab from "./SelectedTaskComps/InactiveTab";

const SelectedTaskMain = () => {
  const dispatch = useDispatch();

  const { supplier_id, token } = useSelector((state) => state?.login);

  const { selectedCorporate } = useSelector((state) => state?.supplierAdmin);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    selectedCorporate &&
      dispatch(
        getTaskAssignedSurveys(token, supplier_id, selectedCorporate?._id)
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCorporate]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{ gap: 20, padding: "12px 0px" }}
    >
      {/* Header with Breadcrumbs */}
      <MainHeader mainCrumb={"My Task"} />

      {/* Main Section Tabs */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{ gap: 20, padding: "0px 20px" }}
      >
        <MuiThemeProvider theme={appTheme}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab
              disableRipple
              label={<span>Assigned</span>}
              style={{ textTransform: "capitalize" }}
            />
            <Tab
              disableRipple
              label={<span>Completed</span>}
              style={{ textTransform: "capitalize" }}
            />
            <Tab
              disableRipple
              label={<span>Inactive</span>}
              style={{ textTransform: "capitalize" }}
            />
          </Tabs>
        </MuiThemeProvider>

        {/* Render content for tabs*/}
        <Box>
          {tabValue === 0 && <AssignedTab />}
          {tabValue === 1 && <CompletedTab />}
          {tabValue === 2 && <InActiveTab />}
        </Box>
      </Box>

      {/* Table */}
    </Box>
  );
};
export default SelectedTaskMain;
