import React from "react";
import { connect } from "react-redux";
import AssesmentApproval from "../../components/sustainabilityReport/assesmentApproval";
import {
  getAssessmentData,
  updateDefineMateriality,
  updateDefineObjective,
  setCompanyLocation,
  setCompanyPincode,
  setCompanyAddress,
  setCompanyGeographic,
  setCompanyCapacity,
  setCompanyStartDate,
  setCompanyWebsite,
  setCompanyName,
  updateAssessmentApproval,
  setAllCompanyAddress,
  setBusinessWebsiteAddress,
  setNumberOfEmployee,
  setBusinessGeographic,
  setBusinessCapacityType,
  updateReportName,
  setCompanyEmail,
  setCompanyAddressWebsite,
  setCMPName,
  setCMPAddress,
  setCMPPincode,
  setCMPIndustry,
  setCMPPermanentEmployee,
  setCMPContractEmployee,
  setCMPCity,
  setCMPCountry,
  setAllLocation,
  setCompanyDescription,
  setSector,
  setIndustry,
  getCommentsToc,
  addCover,
  editToc,
  addHeaderFooter,
  setApproversReviewers,
  viewAssignedMembers,
  deleteAssignedMember,
  updateUserRole,
  getAssessmentPdf,
  setPdfBlob,
  setPdfProgress,
  resetPdfProgress,
  setPdfProgressCounter,
} from "../../actions/assessmentApproval/assessmentApproval";
import {
  getIndustries,
  getFrameworks,
} from "../../actions/sustainabilityReport/sustainabilityReportAction";
import { viewAllUsers } from "../../actions/generalSettings/generalSettingsAction";
import { assignMembers } from "../../actions/esgStrategy/esgStrategyAction";
import { getStakeholder } from "../../actions/materialityAction/materialityAction";
import {
  viewAllComments,
  viewComments,
  addCommentToc,
} from "../../actions/comments/commentsAction";
import { removeFile } from "../../actions/generalSettings/generalSettingsAction";
class AssesmentApprovalCont extends React.PureComponent {
  render() {
    return <AssesmentApproval {...this.props} />;
  }
}

const mapStateToProps = (store) => ({
  login: store.login,
  materialityAssessment: store.materialityAssessment,
  assesmentApproval: store.assesmentApproval,
  basicInfo: store.basicInfo,
  generalSettings: store.generalSettings,
  esgPlan: store.esgStrategy,
  comments: store.comments,
  loadingBar: store.loadingBar,
});

const mapDispatchToProps = (dispatch) => ({
  updateReportName: (token, assessment_id, step_no, report_name, jwt_hash) => {
    dispatch(
      updateReportName(token, assessment_id, step_no, report_name, jwt_hash)
    );
  },
  getIndustries: (sector_id) => {
    dispatch(getIndustries(sector_id));
  },
  setBusinessWebsiteAddress: (payload) => {
    dispatch(setBusinessWebsiteAddress(payload));
  },
  setCompanyEmail: (payload) => {
    dispatch(setCompanyEmail(payload));
  },
  setCompanyAddressWebsite: (payload) => {
    dispatch(setCompanyAddressWebsite(payload));
  },
  setNumberOfEmployee: (payload) => {
    dispatch(setNumberOfEmployee(payload));
  },
  setBusinessGeographic: (payload) => {
    dispatch(setBusinessGeographic(payload));
  },
  setBusinessCapacityType: (payload) => {
    dispatch(setBusinessCapacityType(payload));
  },
  setAllCompanyAddress: (payload) => {
    dispatch(setAllCompanyAddress(payload));
  },
  setAllLocation: (payload) => {
    dispatch(setAllLocation(payload));
  },
  updateAssessmentApproval: (assessment_id, assesmentApproval, token) => {
    dispatch(updateAssessmentApproval(assessment_id, assesmentApproval, token));
  },
  setCompanyName: (payload) => {
    dispatch(setCompanyName(payload));
  },
  setCompanyGeographic: (payload) => {
    dispatch(setCompanyGeographic(payload));
  },
  setCompanyCapacity: (payload) => {
    dispatch(setCompanyCapacity(payload));
  },
  setCompanyStartDate: (payload) => {
    dispatch(setCompanyStartDate(payload));
  },
  setCompanyWebsite: (payload) => {
    dispatch(setCompanyWebsite(payload));
  },
  setCompanyLocation: (payload) => {
    dispatch(setCompanyLocation(payload));
  },
  setCompanyPincode: (payload) => {
    dispatch(setCompanyPincode(payload));
  },
  setCompanyAddress: (payload) => {
    dispatch(setCompanyAddress(payload));
  },
  updateDefineObjective: (payload) => {
    dispatch(updateDefineObjective(payload));
  },
  updateDefineMateriality: (payload) => {
    dispatch(updateDefineMateriality(payload));
  },
  getAssessmentData: (token, assessment_id) => {
    dispatch(getAssessmentData(token, assessment_id));
  },
  setCMPName: (payload) => {
    dispatch(setCMPName(payload));
  },
  setCMPAddress: (payload) => {
    dispatch(setCMPAddress(payload));
  },
  setCMPPincode: (payload) => {
    dispatch(setCMPPincode(payload));
  },
  setCMPIndustry: (payload) => {
    dispatch(setCMPIndustry(payload));
  },
  setCMPPermanentEmployee: (payload) => {
    dispatch(setCMPPermanentEmployee(payload));
  },
  setCMPContractEmployee: (payload) => {
    dispatch(setCMPContractEmployee(payload));
  },
  setCMPCity: (payload) => {
    dispatch(setCMPCity(payload));
  },
  setCMPCountry: (payload) => {
    dispatch(setCMPCountry(payload));
  },
  setCompanyDescription: (payload) => {
    dispatch(setCompanyDescription(payload));
  },
  setSector: (payload) => {
    dispatch(setSector(payload));
  },
  setIndustry: (payload) => {
    dispatch(setIndustry(payload));
  },
  getFrameworks: (token) => {
    dispatch(getFrameworks(token));
  },
  getCommentsToc: (token, assessment_id, step_no) => {
    dispatch(getCommentsToc(token, assessment_id, step_no));
  },
  viewAllUsers: (token, organisation_id, keyword) => {
    dispatch(viewAllUsers(token, organisation_id, keyword));
  },
  getStakeholder: (token, assessment_id, step_no) => {
    dispatch(getStakeholder(token, assessment_id, step_no));
  },
  editToc: (
    token,
    name,
    toc_id,
    assessment_topic_id,
    step_no,
    visible,
    order,
    assessment_id,
    is_text_changed
  ) => {
    dispatch(
      editToc(
        token,
        name,
        toc_id,
        assessment_topic_id,
        step_no,
        visible,
        order,
        assessment_id,
        is_text_changed
      )
    );
  },
  assignMembers: (
    token,
    email_ids,
    step_no,
    assessment_id,
    organisation_id,
    topic_id,
    role,
    mailSubject,
    content
  ) => {
    dispatch(
      assignMembers(
        token,
        email_ids,
        step_no,
        assessment_id,
        organisation_id,
        topic_id,
        role,
        mailSubject,
        content
      )
    );
  },
  addCover: (
    token,
    assessment_id,
    step_no,
    front_text,
    back_text,
    back_img_front,
    logo_front,
    back_img_back,
    logo_back,
    jwt_hash,
    font,
    color,
    previously_selected_colors
  ) => {
    dispatch(
      addCover(
        token,
        assessment_id,
        step_no,
        front_text,
        back_text,
        back_img_front,
        logo_front,
        back_img_back,
        logo_back,
        jwt_hash,
        font,
        color,
        previously_selected_colors
      )
    );
  },
  viewAllComments: (token, assessment_id, step_no) => {
    dispatch(viewAllComments(token, assessment_id, step_no));
  },
  viewComments: (token, toc_id, assessment_topic_id, step_no) => {
    dispatch(viewComments(token, toc_id, assessment_topic_id, step_no));
  },
  addCommentToc: (
    token,
    toc_id,
    comment,
    assessment_topic_id,
    step_no,
    name,
    assessment_id
  ) => {
    dispatch(
      addCommentToc(
        token,
        toc_id,
        comment,
        assessment_topic_id,
        step_no,
        name,
        assessment_id
      )
    );
  },
  addHeaderFooter: (
    token,
    assessment_id,
    step_no,
    header_text,
    footer_text,
    logo,
    jwt_hash,
    page_no,
    is_page_no_position_left
  ) => {
    dispatch(
      addHeaderFooter(
        token,
        assessment_id,
        step_no,
        header_text,
        footer_text,
        logo,
        jwt_hash,
        page_no,
        is_page_no_position_left
      )
    );
  },
  setApproversReviewers: (payload) => {
    dispatch(setApproversReviewers(payload));
  },
  viewAssignedMembers: (token, assessment_id, step_no) => {
    dispatch(viewAssignedMembers(token, assessment_id, step_no));
  },
  deleteAssignedMember: (token, stakeholder_id, assessment_id, step_no) => {
    dispatch(
      deleteAssignedMember(token, stakeholder_id, assessment_id, step_no)
    );
  },
  updateUserRole: (token, stakeholder_id, role, assessment_id, step_no) => {
    dispatch(
      updateUserRole(token, stakeholder_id, role, assessment_id, step_no)
    );
  },
  removeFile: (token, assessment_id, step_no, url, field_name) => {
    dispatch(removeFile(token, assessment_id, step_no, url, field_name));
  },
  getAssessmentPdf: (token, assessment_id, jwt_hash, step_no) => {
    dispatch(getAssessmentPdf(token, assessment_id, jwt_hash, step_no));
  },
  setPdfBlob: (payload) => {
    dispatch(setPdfBlob(payload));
  },
  setPdfProgress: (payload) => {
    dispatch(setPdfProgress(payload));
  },
  resetPdfProgress: (token, assessment_id, step_no) => {
    dispatch(resetPdfProgress(token, assessment_id, step_no));
  },
  setPdfProgressCounter: (payload) => {
    dispatch(setPdfProgressCounter(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssesmentApprovalCont);
