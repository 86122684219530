import React, { useState, useEffect } from "react";
import { withStyles, makeStyles, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomButton from "../../UI/button/button";

const useStyles = makeStyles({
  sendBtn: {
    marginTop: 10,
  },
  Card: { height: "83vh", marginTop: 15 },
  typo: { fontWeight: "600", textAlign: "center" },
  accordionSummary: { marginTop: 10 },
  Box: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  Box1: {
    backgroundColor: "#fff",
    borderRadius: "50%",
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    lineHeight: 24,
  },
  AccordionDetails: { marginTop: 8 },
  Avatar: {
    color: "#3374B9",
    backgroundColor: "#3374B9",
    height: "8px",
    width: "8px",
    marginRight: "8px",
  },
  typo1: {
    color: "#9FA1AF",
    fontSize: "14px",
    lineHeight: "21px",
  },
  typo2: {
    color: "#9FA1AF",
    fontSize: "14px",
    lineHeight: "21px",
  },
});

const CommentsAccordion = withStyles({
  root: {
    "&:before": {
      display: "none",
    },
  },
})(MuiAccordion);
const CommentsAccordionSummary = withStyles({
  root: {
    backgroundColor: "#DDE9F6",
    minHeight: "64px",
    borderRadius: "5px",
    borderTop: "none",
  },
})(MuiAccordionSummary);

const CommentsCard = ({
  token,
  getCommentsToc,
  assessmentId,
  step_no,
  toc,
  comments,
  viewCommentsToc,
  addCommentToc,
  commentsObj,
  viewComments,
  sustainabilityReport,
  activeStep,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [showSendButton, setShowSendButton] = useState(false);
  const [tocId, setTocId] = useState("");
  const [comment, setComment] = useState("");

  const [localSustainablityReport, setLocalSustainablityReport] = useState([]);

  const [allComment, setAllComment] = useState([]);

  useEffect(() => {
    setLocalSustainablityReport(
      sustainabilityReport.tableOfContent.filter(
        (t) => (t.visible === true && t.page_no) || t.visible === false
      )
    );
  }, [sustainabilityReport]);

  useEffect(() => {
    function handleCommentFilter(c) {
      return localSustainablityReport.some((l) => c.name.includes(l.name));
    }
    const commentInit =
      commentsObj.commentToc &&
      commentsObj.commentToc.length > 0 &&
      commentsObj.commentToc.filter((c) => handleCommentFilter(c));

    setAllComment(commentInit);
  }, [localSustainablityReport, commentsObj.commentToc]);

  const handleAccordionChange =
    (panel, id, assessment_topic_id) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
      let assessmentTopicparam;
      if (assessment_topic_id) {
        assessmentTopicparam = assessment_topic_id;
      } else {
        assessmentTopicparam = "";
      }
      if (isExpanded) {
        viewComments(token, id, assessmentTopicparam, step_no);
      }
    };

  const handleNewCommentSubmit = (id, assessment_topic_id, toc_name) => {
    // comment.length !== 0 && setCommentTocId(id);
    const assessmentTopicParams = assessment_topic_id
      ? assessment_topic_id
      : "";
    const tocParam = assessment_topic_id ? "" : id;
    addCommentToc(
      token,
      id,
      comment,
      assessmentTopicParams,
      step_no,
      toc_name,
      assessmentId
    );
    setComment("");
    setShowSendButton(false);
    setTimeout(() => {
      viewComments(token, tocParam, assessmentTopicParams, step_no);
      viewCommentsToc(token, assessmentId, step_no);
    }, [200]);
  };

  useEffect(() => {
    viewCommentsToc(token, assessmentId, step_no);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   viewComments(token, tocId);
  // }, [tocId]);

  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const commentArr = activeStep === 4 ? commentsObj.commentToc : allComment;

  return (
    <Card className={classes.Card}>
      <CardContent>
        <Typography className={classes.typo}>Add Comment</Typography>
        <Box height="70vh" overflow="scroll" mt={1}>
          {commentArr.length > 0 &&
            commentArr
              .filter((toc) => toc.visible)
              .map((t) => (
                <CommentsAccordion
                  variant="elevation"
                  elevation={0}
                  key={toc.toc_id}
                  expanded={expanded === t.name}
                  onChange={handleAccordionChange(
                    t.name,
                    t.toc_id,
                    t.assessment_topic_id
                  )}
                  classes={{ root: classes.CommentsAccordion }}
                >
                  <CommentsAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={classes.accordionSummary}
                  >
                    <Box className={classes.Box}>
                      <Grid>
                        <Typography>{t.name}</Typography>
                      </Grid>
                      <Grid>
                        <Box className={classes.Box1}>{t.comments}</Box>
                      </Grid>
                    </Box>
                  </CommentsAccordionSummary>
                  <AccordionDetails className={classes.AccordionDetails}>
                    <Box display="flex" flexDirection="column" width="100%">
                      <Box>
                        {commentsObj.comments.map((comment, id) => {
                          return (
                            comment.toc_id === t.toc_id && (
                              <Box key={id}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  p="16px 16px 0 16px"
                                >
                                  <Box display="flex" alignItems="center">
                                    <Avatar className={classes.Avatar}></Avatar>
                                    <Typography className={classes.typo1}>
                                      {comment.user_name}
                                    </Typography>
                                  </Box>

                                  <Typography className={classes.typo2}>
                                    {formatDate(comment.created_on)}
                                  </Typography>
                                </Box>
                                <Box p="16px">
                                  <Box pb="10px">{comment.comment}</Box>
                                </Box>
                              </Box>
                            )
                          );
                        })}
                      </Box>
                      <Box>
                        <CustomTextfield
                          name={t.name.substring(0, 4)}
                          fullWidth
                          size="small"
                          id="show-reply"
                          variant="outlined"
                          label="Add Reply"
                          value={comment}
                          onFocus={() => setShowSendButton(true)}
                          onChange={(e) =>
                            setComment(e.target.value.trimStart())
                          }
                        />
                        {showSendButton && comment && (
                          <CustomButton
                            endIcon={<Icon>send</Icon>}
                            variant="outlined"
                            className={classes.sendBtn}
                            color="primary"
                            onClick={() =>
                              handleNewCommentSubmit(
                                t.toc_id,
                                t.assessment_topic_id,
                                t.name
                              )
                            }
                          >
                            Send
                          </CustomButton>
                        )}
                      </Box>
                    </Box>
                  </AccordionDetails>
                </CommentsAccordion>
              ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CommentsCard;
