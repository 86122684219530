import React from "react";
import { connect } from "react-redux";
import MaterialityAssesment from "../../components/sustainabilityReport/materialityAssesment";
import {
  getTopics,
  upsertAssessmentTopic,
  addCustomTopic,
  getSelectedTopic,
  setTopicTab,
  addStakeholder,
  getTopicData,
  getMaterialityMapData,
  getStakeholder,
  editStakeholder,
  deleteStakeholder,
  deleteAssessmentTopic,
  deleteAllAssessmentTopic,
  selectAllTopics,
  addUser,
  setTopicFilter,
  setAssignedStakeholderData,
  uploadUserSheet,
  getAssignedMaterialityTopics,
  setIsSurveyCompleted,
} from "../../actions/materialityAction/materialityAction";

import { set_snack_bar } from "../../actions/snackbar/snackbar_action";

import { viewAllUsers } from "../../actions/generalSettings/generalSettingsAction";

export class MaterialityAssesmentCont extends React.PureComponent {
  render() {
    return <MaterialityAssesment {...this.props} />;
  }
}

export const mapStateToProps = (store) => ({
  login: store.login,
  materialityAssessment: store.materialityAssessment,
  basicInfo: store.basicInfo,
  generalSettings: store.generalSettings,
});

export const mapDispatchToProps = (dispatch) => ({
  getTopics: (token, assessment_id, limit, starting_after, filter) => {
    dispatch(getTopics(token, assessment_id, limit, starting_after, filter));
  },
  upsertAssessmentTopic: (
    token,
    assessment_id,
    topic_id,
    isMainSection,
    relevance,
    weightage,
    additional_params,
    file,
    topic_name,
    materialityAssessment,
    framework_id
  ) => {
    dispatch(
      upsertAssessmentTopic(
        token,
        assessment_id,
        topic_id,
        isMainSection,
        relevance,
        weightage,
        additional_params,
        file,
        topic_name,
        materialityAssessment,
        framework_id
      )
    );
  },
  addCustomTopic: (token, assessment_id, topic_name, parent_id) => {
    dispatch(addCustomTopic(token, assessment_id, topic_name, parent_id));
  },
  getSelectedTopic: (token, assessment_id, analysis) => {
    dispatch(getSelectedTopic(token, assessment_id, analysis));
  },
  setAssignedStakeholderData: (payload) => {
    dispatch(setAssignedStakeholderData(payload));
  },
  getTopicData: (token, assessment_topic_id) => {
    dispatch(getTopicData(token, assessment_topic_id));
  },
  getMaterialityMapData: (token, assessment_id, framework_id) => {
    dispatch(getMaterialityMapData(token, assessment_id, framework_id));
  },
  deleteAssessmentTopic: (
    token,
    assessment_topic_id,
    assessment_id,
    limit,
    starting_after,
    filter,
    topic_name
  ) => {
    dispatch(
      deleteAssessmentTopic(
        token,
        assessment_topic_id,
        assessment_id,
        limit,
        starting_after,
        filter,
        topic_name
      )
    );
  },
  deleteAllAssessmentTopic: (
    token,
    assessment_id,
    limit,
    starting_after,
    filter
  ) => {
    dispatch(
      deleteAllAssessmentTopic(
        token,
        assessment_id,
        limit,
        starting_after,
        filter
      )
    );
  },
  setTopicTab: (payload) => {
    dispatch(setTopicTab(payload));
  },

  addStakeholder: (
    token,
    assessment_id,
    organisation_id,
    user,
    emailSubject,
    stakeholderContent,
    step_no
  ) => {
    dispatch(
      addStakeholder(
        token,
        assessment_id,
        organisation_id,
        user,
        emailSubject,
        stakeholderContent,
        step_no
      )
    );
  },
  set_snack_bar: (status, message) => {
    dispatch(set_snack_bar(status, message));
  },
  viewAllUsers: (token, organisation_id, keyword) => {
    dispatch(viewAllUsers(token, organisation_id, keyword));
  },
  editStakeholder: (
    token,
    assessment_id,
    user_id,
    role,
    organisation,
    category,
    type,
    step_no
  ) => {
    dispatch(
      editStakeholder(
        token,
        assessment_id,
        user_id,
        role,
        organisation,
        category,
        type,
        step_no
      )
    );
  },
  getStakeholder: (token, assessment_id, step_no) => {
    dispatch(getStakeholder(token, assessment_id, step_no));
  },
  deleteStakeholder: (token, assessment_id, stakeholder_id, step_no) => {
    dispatch(deleteStakeholder(token, assessment_id, stakeholder_id, step_no));
  },
  selectAllTopics: (token, assessment_id, filter, framework_id) => {
    dispatch(selectAllTopics(token, assessment_id, filter, framework_id));
  },
  addUser: (
    token,
    organisation_id,
    name,
    email,
    role,
    organisation,
    category,
    type
  ) => {
    dispatch(
      addUser(
        token,
        organisation_id,
        name,
        email,
        role,
        organisation,
        category,
        type
      )
    );
  },
  setTopicFilter: (payload) => {
    dispatch(setTopicFilter(payload));
  },
  uploadUserSheet: (token, organisation_id, file) => {
    dispatch(uploadUserSheet(token, organisation_id, file));
  },
  getAssignedMaterialityTopics: (token, assessment_id) => {
    dispatch(getAssignedMaterialityTopics(token, assessment_id));
  },
  setIsSurveyCompleted: (payload) => {
    dispatch(setIsSurveyCompleted(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialityAssesmentCont);
