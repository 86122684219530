import React from "react";
import { useCubeQuery } from "@cubejs-client/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Line, Bar, Pie } from "react-chartjs-2";
import { useDeepCompareMemo } from "use-deep-compare";
import Typography from "@material-ui/core/Typography";
import Table from "rc-table";
import moment from "moment";
// import Table from "@material-ui/core/Table";
// import { useTable } from "react-table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
const dara = [
  {
    id: 0,
    name: "Total waste Recycled(in metric tonne) ",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Recycling.Recyled_value"],
        order: {
          "Recycling.Recyled_value": "desc",
        },
        dimensions: ["Recycling.Recycled_quantity"],
        timeDimensions: [],
      },
      chartType: "bar",
    },
    layout: {
      x: 0,
      y: 16,
      w: 6,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Recycling.Recycled_quantity"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 1,
    name: "Percentage of Female Board of Directors",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Percentage_Female_BOD.femaleBOD_value"],
        order: {
          "Percentage_Female_BOD.femaleBOD_value": "desc",
        },
        dimensions: ["Percentage_Female_BOD.percentageoffemaleBOD"],
        timeDimensions: [],
      },
      chartType: "pie",
    },
    layout: {
      x: 0,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Percentage_Female_BOD.percentageoffemaleBOD"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 2,
    name: "Waste generated",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Waste.total_waste_generated"],
        order: {
          "Total_Waste.total_waste_generated": "desc",
        },
        dimensions: ["Total_Waste.total_waste"],
        timeDimensions: [],
      },
      chartType: "bar",
    },
    layout: {
      x: 0,
      y: 0,
      w: 12,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Waste.total_waste"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 3,
    name: "Total Energy Generated",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Energy.Total_Energy"],
        order: {
          "Total_Energy.Total_Energy": "desc",
        },
        dimensions: ["Total_Energy.Energy"],
        timeDimensions: [],
      },
      chartType: "pie",
    },
    layout: {
      x: 8,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Energy.Energy"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 4,
    name: "Emission Intensity",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Emission.Total_Emission"],
        order: {
          "Total_Emission.Total_Emission": "desc",
        },
        dimensions: ["Total_Emission.Emission"],
        timeDimensions: [],
      },
      chartType: "bar",
    },
    layout: {
      x: 6,
      y: 16,
      w: 6,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Emission.Emission"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 5,
    name: "Total Energy Consumed (in joules or multipls)",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Energy_Consumption.Total_Energy_Consumption"],
        order: {
          "Total_Energy_Consumption.Total_Energy_Consumption": "desc",
        },
        dimensions: ["Total_Energy_Consumption.Energy_Consumption"],
        timeDimensions: [],
      },
      chartType: "pie",
    },
    layout: {
      x: 4,
      y: 8,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Energy_Consumption.Energy_Consumption"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 6,
    name: "Rehabilitation and Resettlement",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Rehabilitated.Total_Rehabilitated"],
        order: {
          "Rehabilitated.Total_Rehabilitated": "desc",
        },
        dimensions: ["Rehabilitated.Rehabilitated"],
        timeDimensions: [],
      },
      chartType: "pie",
    },
    layout: {
      x: 0,
      y: 24,
      w: 4,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Rehabilitated.Rehabilitated"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 7,
    name: "Minimum Wages Paid",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Average_Wages.Total_Wages"],
        order: {
          "Average_Wages.Total_Wages": "desc",
        },
        dimensions: ["Average_Wages.Wages"],
        timeDimensions: [],
      },
      chartType: "bar",
    },
    layout: {
      x: 4,
      y: 24,
      w: 8,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Average_Wages.Wages"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
  {
    id: 8,
    name: "Total Waste Genrated",
    vizState: {
      query: {
        limit: 5000,
        measures: ["Total_Waste_Generated_for_recycling.Total_Waste_Generated"],
        order: {
          "Total_Waste_Generated_for_recycling.Total_Waste_Generated": "desc",
        },
        dimensions: ["Total_Waste_Generated_for_recycling.Waste_Generated"],
        timeDimensions: [],
      },
      chartType: "bar",
    },
    layout: {
      x: 0,
      y: 32,
      w: 12,
      h: 8,
      minW: 4,
      minH: 8,
    },
    pivotConfig: {
      x: ["Total_Waste_Generated_for_recycling.Waste_Generated"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },
    chartColors: {
      backgroundColors: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(210, 79, 31, 0.2)",
        "rgba(154, 6, 203, 0.22)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColors: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(210, 79, 31, 1)",
        "rgba(154, 6, 203, 1)",
      ],
    },
  },
];

const COLORS_SERIES = [
  "#5b8ff9",
  "#5ad8a6",
  "#5e7092",
  "#f6bd18",
  "#6f5efa",
  "#6ec8ec",
  "#945fb9",
  "#ff9845",
  "#299796",
  "#fe99c3",
];
const PALE_COLORS_SERIES = [
  "#d7e3fd",
  "#daf5e9",
  "#d6dbe4",
  "#fdeecd",
  "#dad8fe",
  "#dbf1fa",
  "#e4d7ed",
  "#ffe5d2",
  "#cce5e4",
  "#ffe6f0",
];
const commonOptions = {
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
      position: "bottom",
    },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        maxRotation: 0,
        padding: 12,
        minRotation: 0,
      },
    },
  },
};
const useDrilldownCallback = ({
  datasets,
  labels,
  onDrilldownRequested,
  pivotConfig,
}) => {
  return React.useCallback(
    (elements) => {
      if (elements.length <= 0) return;
      const { datasetIndex, index } = elements[0];
      const { yValues } = datasets[datasetIndex];
      const xValues = [labels[index]];
      if (typeof onDrilldownRequested === "function") {
        onDrilldownRequested(
          {
            xValues,
            yValues,
          },
          pivotConfig
        );
      }
    },
    [datasets, labels, onDrilldownRequested]
  );
};

const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }

      return [...memo, column];
    }, []);
  }

  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {});

  function formatValue(value, { type, format } = {}) {
    if (value == undefined) {
      return value;
    }

    if (type === "boolean") {
      if (typeof value === "boolean") {
        return value.toString();
      } else if (typeof value === "number") {
        return Boolean(value).toString();
      }

      return value;
    }

    if (type === "number" && format === "percent") {
      return [parseFloat(value).toFixed(2), "%"].join("");
    }

    return value.toString();
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }

  return data.map(format);
};

const TableRenderer = ({ resultSet, pivotConfig }) => {
  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    const columns = resultSet.tableColumns(pivotConfig);
    return [
      columns,
      formatTableData(columns, resultSet.tablePivot(pivotConfig)),
    ];
  }, [resultSet, pivotConfig]);

  return (
    <Table
      style={{ border: "1px solid red", width: "100%" }}
      tableLayout="fixed"
      columns={tableColumns}
      data={dataSource}
    />
  );
};

function wrapText(text, baseWidth, multiplier) {
  const words = text.split(' ');
  const maxWidth = baseWidth + (text.length * multiplier);
  let lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = currentLine.length + word.length + 1; // +1 for the space
    if (width <= maxWidth) {
      currentLine += ' ' + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines.join('\n');
}

const LineChartRenderer = ({
  resultSet,
  pivotConfig,
  onDrilldownRequested,
  borderColor,
  lineChartCustomize,
  backgroundColor,
  chartX_AxisCustomize,
  chartY_AxisCustomize,
}) => {
  const colorBorder = borderColor;
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series(pivotConfig).map((s, index) => {
        return {
          label: s.title,
          data: s.series.map((r) => r?.value),
          yValues: [s.key],
          borderColor: colorBorder ? colorBorder[index] : COLORS_SERIES[index],
          pointRadius: lineChartCustomize?.pointRadius || 5,
          tension: lineChartCustomize?.tension || 0.3,
          pointHoverRadius: lineChartCustomize?.pointHoverRadius || 0,
          borderWidth: lineChartCustomize?.borderWidth || 2,
          pointStyle: lineChartCustomize?.pointStyle || "rectRounded",
          fill: false,
        };
      }),
    [
      resultSet,
      pivotConfig,
      colorBorder,
      lineChartCustomize?.pointRadius,
      lineChartCustomize?.tension,
      lineChartCustomize?.pointHoverRadius,
      lineChartCustomize?.borderWidth,
      lineChartCustomize?.pointStyle,
    ]
  );

  const originalLabels = resultSet
  .categories(pivotConfig)
  .map((c) => moment(c.xValues[1]).format("MMMM"))
  const baseWidth = 10; // Base width for the shortest labels
  const multiplier = 0.5; // Multiplier to adjust width based on label length
  const wrappedLabels = originalLabels.map((label) =>
    wrapText(label,  baseWidth, multiplier)
  );

  const data = {
    labels: wrappedLabels,
    datasets,
  };

  const options = {
    // cutout: d === 4 && '80%',
    // rotation: d === 4 && 270,
    // circumference: d === 4 && 180,
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        ticks: {
          display: true,
          align: "center",
          callback: function(value, index) {
            const label = this.getLabelForValue(index);
            return label.split('\n'); // Split the label into multiple lines
          },
        },
        grid: {
          display: true,
          drawOnChartArea: false,
        },
        title: chartX_AxisCustomize
          ? chartX_AxisCustomize
          : {
              display: true,
              text: "Time",
              align: "center",
              color: "#242424",
              font: {
                size: 15,
                family: "Poppins",
              },
            },
      },

      y: {
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          display: true,
          maxTicksLimit: 5,
          // callback: function (value) {
          //   return Number(value).toFixed(0); // This ensures no decimals are shown
          // },
          // stepSize: 1,
        },
        title: chartY_AxisCustomize
          ? chartY_AxisCustomize
          : {
              display: true,
              text: "Your Title",
              align: "center",
              color: "#242424",
              font: {
                size: 15,
                family: "Poppins",
              },
            },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      title: {
        font: {
          weight: 700,
        },
        display: true,
        position: "top",
        // text: title[0],
      },
      // tooltip: {
      //   callbacks: {
      //     label: function (context) {
      //       return (
      //         context?.dataset?.label + ": " + Number(context?.raw)?.toFixed(0)
      //       );
      //     },
      //   },
      // },
    },

    elements: {
      point: {
        radius: 5,
      },
    },
  };
  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    pivotConfig,
    onDrilldownRequested,
  });
  return (
    <Line
      type="line"
      data={data}
      options={options}
      getElementAtEvent={getElementAtEvent}
    />
  );
};

const BarChartRenderer = ({
  resultSet,
  pivotConfig,
  borderColor,
  onDrilldownRequested,
  backgroundColor,
  barChartCustomize,
  chartX_AxisCustomize,
  chartY_AxisCustomize,
}) => {
  const color = backgroundColor;
  const colorBorder = borderColor;

  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series(pivotConfig).map((s, index) => {
        return {
          label: s.series.map(
            (r) =>
              resultSet?.loadResponse?.results[0]?.annotation?.measures[r?.x]
                ?.shortTitle ??
              s?.shortTitle ??
              ""
          ),
          data: s.series.map((r) => r?.value),
          yValues: [s.key],
          borderColor: colorBorder
            ? colorBorder
            : [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(210, 79, 31, 1)",
                "rgba(154, 6, 203, 1)",
              ],
          backgroundColor: color
            ? color
            : [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                "rgba(210, 79, 31, 0.2)",
                "rgba(154, 6, 203, 0.22)",
              ],

          fill: false,
          borderWidth: barChartCustomize?.borderWidth || 1,
          barThickness: barChartCustomize?.barThickness || 100,
          borderRadius: barChartCustomize?.borderRadius || 5,

          pointRadius: 0,
        };
      }),
    [
      resultSet,
      pivotConfig,
      color,
      colorBorder,
      barChartCustomize?.borderRadius,
      barChartCustomize?.borderWidth,
      barChartCustomize?.barThickness,
    ]
  );


  

  const originalLabels = resultSet.categories(pivotConfig).map((c) => {
    return (
      resultSet?.loadResponse?.results[0]?.annotation?.measures[c?.x]
        ?.shortTitle || c?.x
    );
  });
  const baseWidth = 10; // Base width for the shortest labels
  const multiplier = 0.5; // Multiplier to adjust width based on label length
  const wrappedLabels = originalLabels.map((label) =>
    wrapText(label,  baseWidth, multiplier)
  );


  const data = {
    labels: wrappedLabels,
    datasets,
  };

  const options = {
    // cutout: d === 4 && '80%',
    // rotation: d === 4 && 270,
    // circumference: d === 4 && 180,
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        ticks: {
          display: true,
          align: "center",
          callback: function(value, index) {
            const label = this.getLabelForValue(index);
            return label.split('\n'); // Split the label into multiple lines
          },
        },
        grid: {
          display: true,
          drawOnChartArea: false,
        },
        title: chartX_AxisCustomize
          ? chartX_AxisCustomize
          : {
              display: false,
              text: "Your Title",
              align: "start",
              color: "red",
              font: {
                size: 15,
              },
            },
      },

      y: {
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          display: true,
          maxTicksLimit: 5,
          // callback: function (value) {
          //   return Number(value).toFixed(0); // This ensures no decimals are shown
          // },
          // stepSize: 1,
        },
        title: chartY_AxisCustomize
          ? chartY_AxisCustomize
          : {
              display: false,
              text: "Your Title",
              align: "center",
              color: "red",
              font: {
                size: 15,
              },
            },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      title: {
        font: {
          weight: 700,
        },
        display: true,
        position: "top",
        // text: title[0],
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context?.dataset?.label.length > 1
              ? Number(context?.raw)
              : context?.dataset?.label + ": " + Number(context?.raw);
          },
        },
      },
    },

    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    onDrilldownRequested,
    pivotConfig,
  });

  return (
    <Bar
      type="bar"
      data={data}
      options={options}
      getElementAtEvent={getElementAtEvent}
    />
  );
};
const AreaChartRenderer = ({
  resultSet,
  pivotConfig,
  onDrilldownRequested,
  borderColor,
  backgroundColor,
}) => {
  const color = backgroundColor;
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series(pivotConfig).map((s, index) => ({
        label: s.series.map(
          (r) =>
            resultSet?.loadResponse?.results[0]?.annotation?.measures[r?.x]
              ?.shortTitle ??
            s?.shortTitle ??
            ""
        ),
        data: s.series.map((r) => r?.value),
        yValues: [s.key],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: color ? color[index] : PALE_COLORS_SERIES[index],
        borderWidth: 0,
        fill: true,
        tension: 0,
      })),
    [resultSet, pivotConfig, color]
  );


  const originalLabels = resultSet.categories(pivotConfig).map((c) => {
    return (
      resultSet?.loadResponse?.results[0]?.annotation?.measures[c?.x]
        ?.shortTitle || c?.x
    );
  })
  const baseWidth = 10; // Base width for the shortest labels
  const multiplier = 0.5; // Multiplier to adjust width based on label length
  const wrappedLabels = originalLabels.map((label) =>
    wrapText(label,  baseWidth, multiplier)
  );


  const data = {
    labels: wrappedLabels,
    datasets,
  };


  
  // const options = {
  //   scales: {

  //     y: {
  //       stacked: true,
  //       ticks: {
  //         display: true,
  //         maxTicksLimit: 5,
  //         // callback: function (value) {
  //         //   return Number(value).toFixed(0); // This ensures no decimals are shown
  //         // },
  //         // stepSize: 1,
  //       },
  //     },
  //   },
  //   plugins: {
  //     tooltip: {
  //       callbacks: {
  //         label: function (context) {
  //           return context?.dataset?.label.length > 1
  //             ? Number(context?.raw)
  //             : context?.dataset?.label + ": " + Number(context?.raw);
  //         },
  //       },
  //     },
  //   },
  // };
  const options = {
    // cutout: d === 4 && '80%',
    // rotation: d === 4 && 270,
    // circumference: d === 4 && 180,
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        ticks: {
          display: true,
          align: "center",
          callback: function(value, index) {
            const label = this.getLabelForValue(index);
            return label.split('\n'); // Split the label into multiple lines
          },
        },
        grid: {
          display: true,
          drawOnChartArea: false,
        },
        // title: chartX_AxisCustomize
        //   ? chartX_AxisCustomize
        //   : {
        //       display: false,
        //       text: "Your Title",
        //       align: "start",
        //       color: "red",
        //       font: {
        //         size: 15,
        //       },
        //     },
      },

      y: {
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          display: true,
          maxTicksLimit: 5,
          // callback: function (value) {
          //   return Number(value).toFixed(0); // This ensures no decimals are shown
          // },
          // stepSize: 1,
        },
        // title: chartY_AxisCustomize
        //   ? chartY_AxisCustomize
        //   : {
        //       display: false,
        //       text: "Your Title",
        //       align: "center",
        //       color: "red",
        //       font: {
        //         size: 15,
        //       },
        //     },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      title: {
        font: {
          weight: 700,
        },
        display: true,
        position: "top",
        // text: title[0],
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context?.dataset?.label.length > 1
              ? Number(context?.raw)
              : context?.dataset?.label + ": " + Number(context?.raw);
          },
        },
      },
    },

    elements: {
      point: {
        radius: 0,
      },
    },
  };
  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    pivotConfig,
    onDrilldownRequested,
  });
  return (
    <Line
      type="area"
      data={data}
      options={options}
      getElementAtEvent={getElementAtEvent}
    />
  );
};
const PieChartRenderer = ({
  borderColor,
  backgroundColor,
  resultSet,
  pivotConfig,
  onDrilldownRequested,
  pieChartCustomize,
}) => {
  const color = backgroundColor;
  const colorBorder = borderColor;

  const optionsPie = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: pieChartCustomize?.cutout || 0,
    rotation: pieChartCustomize?.rotation || 0,
    circumference: pieChartCustomize?.circumference || 360,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "title[0]",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            console.log(context)
            return context?.label.split('\n');
          },
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series(pivotConfig).map((s) => ({
        label: s.series.map(
          (r) =>
            resultSet?.loadResponse?.results[0]?.annotation?.measures[r?.x]
              ?.shortTitle ??
            s?.shortTitle ??
            ""
        ),
        data: s.series.map((r) => r?.value),
        yValues: [s.key],

        backgroundColor: color
          ? color
          : [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(210, 79, 31, 0.2)",
              "rgba(154, 6, 203, 0.22)",
            ],
        hoverBackgroundColor: colorBorder
          ? colorBorder
          : [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(210, 79, 31, 0.2)",
              "rgba(154, 6, 203, 0.22)",
            ],
      })),
    [resultSet, pivotConfig, color, colorBorder]
  );

  
  const originalLabels =  resultSet.categories(pivotConfig).map((c) => {
    return (
      resultSet?.loadResponse?.results[0]?.annotation?.measures[c?.x]
        ?.shortTitle || c?.x
    );
  })
  const baseWidth = 10; // Base width for the shortest labels
  const multiplier = 0.5; // Multiplier to adjust width based on label length
  const wrappedLabels = originalLabels.map((label) =>
    wrapText(label,  baseWidth, multiplier)
  );


  const data = {
    labels: wrappedLabels,
    datasets,
  };

 
  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    pivotConfig,
    onDrilldownRequested,
  });
  return (
    <Pie
      type="pie"
      data={data}
      options={optionsPie}
      getElementAtEvent={getElementAtEvent}
    />
  );
};
const TypeToChartComponent = {
  line: ({
    resultSet,
    borderColor,
    backgroundColor,
    pivotConfig,
    onDrilldownRequested,
    lineChartCustomize,
    chartX_AxisCustomize,
    chartY_AxisCustomize,
  }) => {
    return (
      <LineChartRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        lineChartCustomize={lineChartCustomize}
        chartX_AxisCustomize={chartX_AxisCustomize}
        chartY_AxisCustomize={chartY_AxisCustomize}
      />
    );
  },
  bar: ({
    resultSet,
    borderColor,
    pivotConfig,
    onDrilldownRequested,
    backgroundColor,
    barChartCustomize,
    chartX_AxisCustomize,
    chartY_AxisCustomize,
  }) => {
    return (
      <BarChartRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        barChartCustomize={barChartCustomize}
        chartX_AxisCustomize={chartX_AxisCustomize}
        chartY_AxisCustomize={chartY_AxisCustomize}
      />
    );
  },
  area: ({
    resultSet,
    borderColor,
    backgroundColor,
    pivotConfig,
    onDrilldownRequested,
  }) => {
    return (
      <AreaChartRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
      />
    );
  },
  pie: ({
    resultSet,
    borderColor,
    backgroundColor,
    pivotConfig,
    onDrilldownRequested,
    pieChartCustomize,
  }) => {
    return (
      <PieChartRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        onDrilldownRequested={onDrilldownRequested}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        pieChartCustomize={pieChartCustomize}
      />
    );
  },
  number: ({ resultSet }) => {
    return (
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
        }}
      >
        {resultSet.seriesNames().map((s) => resultSet.totalRow()[s.key])}
      </Typography>
    );
  },
  table: ({ resultSet, pivotConfig }) => {
    return <TableRenderer resultSet={resultSet} pivotConfig={pivotConfig} />;
  },
};

const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({
    ...a,
    ...b,
  }));

const renderChart =
  (Component) =>
  ({
    resultSet,
    pivotConfig,
    backgroundColor,
    borderColor,
    barChartCustomize,
    chartX_AxisCustomize,
    chartY_AxisCustomize,
    lineChartCustomize,
    pieChartCustomize,
    error,
    ...props
  }) => {
    return (
      (resultSet && (
        <Component
          pivotConfig={pivotConfig}
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          resultSet={resultSet}
          barChartCustomize={barChartCustomize}
          chartX_AxisCustomize={chartX_AxisCustomize}
          chartY_AxisCustomize={chartY_AxisCustomize}
          lineChartCustomize={lineChartCustomize}
          pieChartCustomize={pieChartCustomize}
          {...props}
        />
      )) ||
      (error && error.toString()) || <CircularProgress />
    );
  };

const ChartRenderer = ({
  vizState = {},
  pivotConfig,
  backgroundColor,
  borderColor,
  barChartCustomize,
  chartX_AxisCustomize,
  chartY_AxisCustomize,
  lineChartCustomize,
  pieChartCustomize,
}) => {
  const { query, chartType, ...options } = vizState;

  const component = TypeToMemoChartComponent[chartType];

  const renderProps = useCubeQuery(query);

  return (
    component &&
    renderChart(component)({
      pivotConfig,
      backgroundColor,
      borderColor,
      barChartCustomize,
      chartX_AxisCustomize,
      chartY_AxisCustomize,
      lineChartCustomize,
      pieChartCustomize,
      ...options,
      ...renderProps,
    })
  );
};
export default ChartRenderer;
