import {
  REPORT,
  REPORT_VALUE,
  RESET_FIELD_DATA,
  SET_VIEW_SURVEY,
  SET_SURVEY_TYPE,
  SET_SURVEY_RESULT,
  SET_SURVEY_ID,
  SET_SURVEY_DATA,
  SET_CURRENT_METRIC_ID,
  SET_UPSERT,

  // New
  SET_TOPIC_METRICS,
  SET_REPORT_METRICS,
  SET_REPORT_TAB,
  SET_REVIEW_DATA,
  SET_UPSER_METRIC_DATA,
  SET_CURRENT_METRIC,
  SET_REPORT_DATA_TASK,
  SET_ASSIGNED_METRIC,
  SET_ASSIGNED_MEMBERS_FIELD,
  SET_FREEZE_LOADING,
  SET_FREEZE_UPLOADED,
  SET_METRIC_RESULT,
  SET_BLOCKCHAIN_HASHLINKS,
  SET_CURRENT_REVIEW_METRICS,
  SET_REVIEW_METRICS,
  SET_COLLECT_METRICS,
  SET_ASSIGNED_METRIC_USERS,SET_DISCLOSURE_SETTINGS_DATA,SET_ALL_STEP_5_USERS,
  SET_METRIC_CHATS,SET_CURRENT_DETAILED_METRIC,SET_DISCLOSURE_SETTINGS_RESPONSES,FILTER,SET_DISCLOSURE_SETTINGS_FILES,SET_DISCLOSURE_SETTINGS_VIEW_SUBMISSION
} from "../../constants/fielddata/fielddataConst";

const initial_state = {
  report: [],
  reportValue: [],
  viewSurvey: [],
  surveyResult: [],
  surveyData: [],
  surveyType: 0,
  surveyId: "",
  currentMetricId: "",
  upsert: false,

  // New
  topicMetric: [],
  metrics: [],
  collectMetrics: [],
  reportTab: 0,
  reviewData: [],
  isUpsertMetricData: false,
  currentMetric: "",
  currentDetailedMetric:{},
  reportDataTask: [],
  assignedMetric: {},
  assignedMembers: [],
  freezeLoading: false,
  freezeUploaded: false,
  metricResult: "",
  hashlinks: [],
  currentReviewMetric: '',
  reviewMetric: [],
  assignedMetricUsers: [],
  metricChats: [],
  filteredMetrics:[],
  disclosureSettingsresponses:"",
  disclosureSettingData:"",
  disclosureFiles:"",
  currentSubmission:"",
  allStep5Users:[]
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_CURRENT_DETAILED_METRIC:
      return (state = { ...state, currentDetailedMetric: action.payload });
    case SET_DISCLOSURE_SETTINGS_RESPONSES:
      return (state = { ...state, disclosureSettingsresponses: action.payload });
    case SET_DISCLOSURE_SETTINGS_DATA:
      return (state = { ...state, disclosureSettingData: action.payload });
    case SET_DISCLOSURE_SETTINGS_FILES:
      return (state = { ...state, disclosureFiles: action.payload });
    case SET_DISCLOSURE_SETTINGS_VIEW_SUBMISSION:
      return (state = { ...state, currentSubmission: action.payload });
    case SET_ALL_STEP_5_USERS:
      return (state = { ...state, allStep5Users: action.payload });
    case FILTER:
      return (state = { ...state, filteredMetrics: action.payload });
    case REPORT:
      return (state = { ...state, report: action.payload });
    case REPORT_VALUE:
      return (state = { ...state, reportValue: action.payload });
    case SET_VIEW_SURVEY:
      return (state = { ...state, viewSurvey: action.payload });
    case SET_SURVEY_RESULT:
      return (state = { ...state, surveyResult: action.payload });
    case SET_SURVEY_DATA:
      return (state = { ...state, surveyData: action.payload });
    case SET_SURVEY_TYPE:
      return (state = { ...state, surveyType: action.payload });
    case SET_SURVEY_ID:
      return (state = { ...state, surveyId: action.payload });
    case SET_CURRENT_METRIC_ID:
      return (state = { ...state, currentMetricId: action.payload });
    case SET_UPSERT:
      return (state = { ...state, upsert: action.payload });
    case SET_CURRENT_REVIEW_METRICS:
      return (state = { ...state, currentReviewMetric: action.payload });
    case SET_REVIEW_METRICS:
      return (state = { ...state, reviewMetric: action.payload });
    case RESET_FIELD_DATA:
      return (state = {
        ...state,
        report: [],
        reportValue: [],
        viewSurvey: [],
        surveyResult: [],
        surveyData: [],
        surveyType: 0,
        surveyId: "",
        currentMetricId: "",
        upsert: false
      });

    // New
    case SET_TOPIC_METRICS:
      return (state = { ...state, topicMetric: action.payload });
    case SET_REPORT_METRICS:
      return (state = { ...state, metrics: action.payload });
    case SET_COLLECT_METRICS:
      return (state = { ...state, collectMetrics: action.payload });
    case SET_REPORT_TAB:
      return (state = { ...state, reportTab: action.payload });
    case SET_REVIEW_DATA:
      return (state = { ...state, reviewData: action.payload });
    case SET_UPSER_METRIC_DATA:
      return (state = { ...state, isUpsertMetricData: action.payload });
    case SET_CURRENT_METRIC:
      return (state = { ...state, currentMetric: action.payload });
    case SET_REPORT_DATA_TASK:
      return (state = { ...state, reportDataTask: action.payload });
    case SET_ASSIGNED_METRIC:
      return (state = { ...state, assignedMetric: action.payload });
    case SET_ASSIGNED_MEMBERS_FIELD:
      return (state = { ...state, assignedMembers: action.payload });
    case SET_FREEZE_LOADING:
      return (state = { ...state, freezeLoading: action.payload });
    case SET_FREEZE_UPLOADED:
      return (state = { ...state, freezeUploaded: action.payload });
    case SET_METRIC_RESULT:
      return (state = { ...state, metricResult: action.payload });
    case SET_BLOCKCHAIN_HASHLINKS:
      return (state = { ...state, hashlinks: action.payload });
    case SET_ASSIGNED_METRIC_USERS:
      return (state = { ...state, assignedMetricUsers: action.payload });
    case SET_METRIC_CHATS:
      return (state = { ...state, metricChats: action.payload });
    default:
      return state;
  }
}
