import React from "react";



import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import TopicCards from "./TopicCard";
import DataSetsCardTitle from "../DataSetsCardTitle";



function DataSetLeft() {

  return (
    <Paper
      elevation={3}
      style={{
        backgroundColor: "#FEFEFE",
        height: "100%",
      }}
    >
      <DataSetsCardTitle title={"Categories/ Topics"} hasMoreIcon={false} />

      
      <Box style={{ height: "75%",  }}>
        <TopicCards />
      </Box>
    </Paper>
  );
}

export default DataSetLeft;
