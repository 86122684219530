import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";

const YearRangePicker = ({
  open,
  onClose,
  currentRange,
  handleRangeData,
  singleYear = false, // toggle for allowing selection of single year only
}) => {
  const currentYear = new Date().getFullYear();
  const [startYear, setStartYear] = useState(currentYear);
  const [endYear, setEndYear] = useState();
  const [yearRange, setYearRange] = useState([]);

  if (!currentRange) {
    currentRange = `${currentYear}-`;
  }

  // ---------- inner functions ----------

  useEffect(() => {
    currentRange && setStartYear(currentRange?.split("-")[0]);
    currentRange && setEndYear(currentRange?.split("-")[1]);
  }, [currentRange]);

  const handleYearClick = (year) => {
    if (startYear && endYear) {
      setStartYear(year);
      setEndYear();
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
    } else if (singleYear && startYear + 1 !== year) {
      setStartYear(year);
    } else if (year !== startYear) {
      setEndYear(year);
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };

  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const limit = currentYear - 1000; // 1000 years
    const newStartYear = yearRange[0] - 9;
    const data = [];
    if (newStartYear >= limit) {
      for (let i = 0; i < 9; i++) {
        data.push(newStartYear + i);
      }
      setYearRange(data);
    } else {
      alert("Cannot go back further");
    }
  };

  const handleYearForward = () => {
    const limit = currentYear + 1000; // 1000 years
    const newStartYear = yearRange[0] + 9;
    const data = [];
    if (newStartYear <= limit) {
      for (let i = 0; i < 9; i++) {
        data.push(yearRange[0] + 9 + i);
      }
      setYearRange(data);
    } else {
      alert("Cannot increase further");
    }
  };

  const handleSubmit = () => {
    const selectedRange = `${startYear}-${endYear}`;
    handleRangeData(selectedRange);
    setStartYear(currentYear);
    setEndYear();
    onClose();
  };

  // for setting current years range
  useEffect(() => {
    const data = [];
    if (!yearRange) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setYearRange(data);
    } else {
      for (let i = 0; i < 9; i++) {
        data.push(currentRange.split("-")[0] - 3 + i);
      }
      setYearRange(data);
    }
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent style={{ width: "40em" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{
              fontSize: "16px",
              cursor: "pointer",
              fontWeight: 600,
              marginLeft: "15px",
              marginBottom: "15px",
            }}
          >{`${startYear || ""}-${endYear || ""}`}</Typography>
          <Typography
            onClick={handleYearBack}
            style={{
              fontSize: "22px",
              cursor: "pointer",
              fontWeight: 600,
              marginLeft: "15px",
              marginBottom: "15px",
            }}
          >{`<`}</Typography>
          <Typography
            onClick={handleYearForward}
            style={{
              fontSize: "22px",
              cursor: "pointer",
              fontWeight: 600,
              marginLeft: "15px",
              marginBottom: "15px",
            }}
          >{`>`}</Typography>
          <Typography
            onClick={() => {
              setStartYear("");
              setEndYear("");
            }}
            style={{
              fontSize: "16px",
              cursor: "pointer",
              fontWeight: 600,
              marginLeft: "15px",
              marginBottom: "15px",
              float: "right",
            }}
          >{`Clear`}</Typography>
        </div>

        <Grid
          container
          item
          xs={12}
          md={12}
          gap={1}
          spacing={2}
          style={{
            padding: "2% 1% 4% 1%",
            // // marginLeft: '2%',
          }}
        >
          {yearRange?.map((year, idx) => {
            return (
              <Grid
                key={idx}
                item
                xs={4}
                md={4}
                align="center"
                style={{
                  // margin:'6px 8px',
                  // border: "1px solid blue",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <Typography
                  onClick={() => handleYearClick(year)}
                  style={{
                    fontWeight: 500,
                    padding: "8px 5px",
                    border: range(year) ? "1px solid #3374B9" : "none",
                    borderRadius: "5px",
                    backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                  }}
                >
                  {year}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!startYear || !endYear}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default YearRangePicker;
