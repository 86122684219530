import { makeStyles } from "@material-ui/core";

export const addCompanyStyle = makeStyles((theme) => ({
  hidden: {
    display: "flex",
    position: "fixed",
    marginLeft: "25px",
    marginTop: "25px",
  },
  hiddenContainer: {
    overflow: "hidden",
    backgroundColor: "#F5F6F8",
  },
  hiddenItem: {
    marginTop: "200px",
  },
  hiddenTypography: {
    color: "white",
  },
  hiddenBody1: {
    color: "white",
    fontSize: "18px",
  },
  hiddenBody: {
    color: "rgba(255, 255, 255, 0.45)",
  },
  hiddenItem2: {
    marginTop: "70px",
    marginLeft: "80px",
    alignSelf: "center",
  },
  hiddenImage1: {
    position: "absolute",
    top: 450,
    left: 10,
    width: "70px",
  },
  hiddenImage2: {
    position: "absolute",
    top: 120,
    left: 255,
    width: "70px",
  },
  hiddenAnchor:{
    textDecoration: "none"
  },
  hiddenUl:{
    cursor: "pointer",
    display: "flex",
    textDecoration: "none",
    listStyle: "none",
    fontSize: 14,
    marginLeft: 25,
  },
  hiddenImageLogo:{
    width: 200
  }
}));
