import {
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
// import link from "../../../images/link.png";
import pdfImg from "../../../images/PDFIcon.png";
import user from "../../../images/userIconBlue.png";
import doc from "../../../images/DocBlue.png";
import clock from "../../../images/clock.svg";
import moment from "moment";
import { MdMoreVert } from "react-icons/md";
import { FiDownload } from "react-icons/fi";

export function SurveyDocument({
  file,
  handleFileRemove,
  publicDocuments,
  ...props
}) {
  // const reportData = [
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  // ];

  const fileRemove = (value) => {
    const filterFile = file.filter((v) => v.name !== value.name);
    handleFileRemove(filterFile);
  };

  // state for dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  // state for selected file from dropdown
  const [selectedFile, setSelectedFile] = useState(null);

  const handleClick = (event, file) => {
    setAnchorEl(event.currentTarget);

    setSelectedFile(file);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedFile(null);
  };

  const handleDownload = () => {
    if (selectedFile) {
      window.open(selectedFile.url, "_blank");
    }
    handleClose();
  };

  return (
    <div
      className="scroll"
      style={{ marginTop: 18, paddingBottom: 75, height: "60vh" }}
    >
      <Grid
        container
        item
        md={12}
        sm={12}
        xs={12}
        style={{
          marginTop: "10px",
          backgroundColor: "#EBF1F8",
          padding: "3px",
          borderRadius: "5px",
        }}
      >
        <Grid item md={6} xs={6}>
          <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
            Publications
          </Typography>
        </Grid>

        <Grid item md={2} xs={2} align="center">
          <Typography style={{ fontSize: "13px" }}>Uploaded By</Typography>
        </Grid>
        <Grid item md={2} xs={2} align="center">
          <Typography style={{ fontSize: "13px" }}>Upload Time</Typography>
        </Grid>

        <Grid item md={1} xs={1} align="center">
          <Typography style={{ fontSize: "13px" }}>Action</Typography>
        </Grid>
      </Grid>
      {publicDocuments &&
        publicDocuments.length > 0 &&
        publicDocuments.map((file, idx) => (
          <Grid
            key={idx}
            container
            item
            md={12}
            sm={12}
            xs={12}
            style={{
              marginTop: "10px",
              padding: "5px",
              borderBottom: "1px solid grey",
              borderRadius: "2px",
              alignItems: "center",
            }}
          >
            <Grid
              item
              md={6}
              xs={6}
              container
              style={{ flexWrap: "nowrap", alignItems: "center" }}
            >
              <img
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
                src={pdfImg}
                alt="pdf"
              />
              <div>
                <Typography
                  style={{
                    fontSize: "13px",
                    marginLeft: "5px",
                    width: "12em",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {file.name || "publication name"}
                </Typography>
              </div>
            </Grid>

            <Grid
              item
              md={2}
              xs={2}
              // container
              align="center"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "20px", height: "20px", marginRight: "0px" }}
                src={user}
                alt="pdf"
              />
              <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                {file?.uploaded_by || "username"}
              </Typography>
            </Grid>
            <Grid
              item
              md={2}
              xs={2}
              container
              align="center"
              style={{ flexWrap: "nowrap", alignItems: "center" }}
            >
              <img
                style={{ width: "20px", height: "20px", marginRight: "5px" }}
                src={clock}
                alt="pdf"
              />
              <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                {moment(file.upload_time).format("ll")}
              </Typography>
            </Grid>

            <Grid item md={1} xs={1} align="center">
              <IconButton onClick={(event) => handleClick(event, file)}>
                <MdMoreVert
                  style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: 8,
                    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
                    marginTop: "45px",
                  },
                }}
              >
                <MenuItem
                  onClick={handleDownload}
                  style={{
                    padding: "10px 20px",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#333",
                  }}
                >
                  <FiDownload
                    style={{ marginRight: "8px", color: "#32A05F" }}
                  />
                  Download
                </MenuItem>
              </Menu>
            </Grid>
            {/* <Grid item md={1} xs={1} align="center">
      <Icon style={{ alignSelf: "right" }}>more_vert</Icon>
    </Grid> */}
          </Grid>
        ))}
    </div>
  );
}

function PublicReports({ file, handleFileRemove, publicDocuments, ...props }) {
  // const reportData = [
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  // ];

  const fileRemove = (value) => {
    const filterFile = file.filter((v) => v.name !== value.name);
    handleFileRemove(filterFile);
  };

  // state for dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  // state for selected file from dropdown
  const [selectedFile, setSelectedFile] = useState(null);

  const handleClick = (event, file) => {
    setAnchorEl(event.currentTarget);

    setSelectedFile(file);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedFile(null);
  };

  const handleDownload = () => {
    if (selectedFile) {
      window.open(selectedFile.file_url, "_blank");
    }
    handleClose();
  };

  return (
    <div
      className="scroll"
      style={{ marginTop: 18, paddingBottom: 75, height: "60vh" }}
    >
      <Grid
        container
        item
        md={12}
        sm={12}
        xs={12}
        style={{
          marginTop: "10px",
          backgroundColor: "#EBF1F8",
          padding: "3px",
          borderRadius: "5px",
        }}
      >
        <Grid item md={3} xs={3}>
          <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
            Publications
          </Typography>
        </Grid>
        <Grid item md={2} xs={2} align="center">
          <Typography style={{ fontSize: "13px" }}>Document Type</Typography>
        </Grid>
        <Grid item md={2} xs={2} align="center">
          <Typography style={{ fontSize: "13px" }}>Uploaded By</Typography>
        </Grid>
        <Grid item md={2} xs={2} align="center">
          <Typography style={{ fontSize: "13px" }}>Upload Time</Typography>
        </Grid>
        <Grid item md={2} xs={2} align="center">
          <Typography style={{ fontSize: "13px" }}>Status</Typography>
        </Grid>
        <Grid item md={1} xs={1} align="center">
          <Typography style={{ fontSize: "13px" }}>Action</Typography>
        </Grid>
      </Grid>
      {publicDocuments &&
        publicDocuments.length > 0 &&
        publicDocuments.map((file) => (
          <Grid
            container
            item
            md={12}
            sm={12}
            xs={12}
            style={{
              marginTop: "10px",
              padding: "5px",
              borderBottom: "1px solid grey",
              borderRadius: "2px",
              alignItems: "center",
            }}
          >
            <Grid
              item
              md={3}
              xs={3}
              container
              style={{ flexWrap: "nowrap", alignItems: "center" }}
            >
              <img
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
                src={pdfImg}
                alt="pdf"
              />
              <div>
                <Typography
                  style={{
                    fontSize: "13px",
                    marginLeft: "5px",
                    width: "12em",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {file.file_name || "publication name"}
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    marginLeft: "5px",
                    color: "grey",
                  }}
                >
                  {file.year || "publication year"}
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              md={2}
              xs={2}
              container
              align="center"
              style={{ flexWrap: "nowrap", alignItems: "center" }}
            >
              <img
                style={{ width: "16px", height: "16px" }}
                src={doc}
                alt="doc"
              />
              <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                {file.type || "doc type"}
              </Typography>
            </Grid>
            <Grid
              item
              md={2}
              xs={2}
              // container
              align="center"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "20px", height: "20px", marginRight: "0px" }}
                src={user}
                alt="pdf"
              />
              <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                {file?.user_name || "username"}
              </Typography>
            </Grid>
            <Grid
              item
              md={2}
              xs={2}
              container
              align="center"
              style={{ flexWrap: "nowrap", alignItems: "center" }}
            >
              <img
                style={{ width: "20px", height: "20px", marginRight: "5px" }}
                src={clock}
                alt="pdf"
              />
              <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                {moment(file.uploaded_on).format("ll")}
              </Typography>
            </Grid>

            <Grid item md={2} xs={2} align="center">
              <Typography
                style={{
                  color: !file?.KPI_process_completed ? "#8C7038" : "#32A05F",
                  backgroundColor: !file?.KPI_process_completed
                    ? "#FFEFD0"
                    : "#EBF6EF",
                  borderRadius: 12,
                  fontSize: 12,
                  padding: "3px 8px",
                  width: "fit-content",
                  // margin: "5px 8px",
                }}
              >
                {!file?.KPI_process_completed ? "Uploading" : "Uploaded"}
              </Typography>
            </Grid>
            {/* <Grid item md={2} xs={2} align="center">
           <Typography style={{ fontSize: "13px" }}>{file.link}</Typography>

              <a
                href={file?.file_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ width: "21px", cursor: "pointer" }}
                  src={link}
                  alt="link"
                />
              </a> 
            </Grid>*/}
            <Grid item md={1} xs={1} align="center">
              <IconButton onClick={(event) => handleClick(event, file)}>
                <MdMoreVert
                  style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: 8,
                    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.30)",
                    marginTop: "45px",
                  },
                }}
              >
                <MenuItem
                  onClick={handleDownload}
                  style={{
                    padding: "10px 20px",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#333",
                  }}
                >
                  <FiDownload
                    style={{ marginRight: "8px", color: "#32A05F" }}
                  />
                  Download
                </MenuItem>
              </Menu>
            </Grid>

            {/* <Grid item md={1} xs={1} align="center">
      <Icon style={{ alignSelf: "right" }}>more_vert</Icon>
    </Grid> */}
          </Grid>
        ))}
    </div>
  );
}

export default PublicReports;
