import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography';
import Tour from "reactour";
import CustomButton from './../../UI/button/button';
import { useHistory } from "react-router-dom";
import HelperButton from './../../widgets/helperButton/helperButton';

function OnboardinTour() {
  let history = useHistory();
  const [isTourOpen, setIsTourOpen] = useState(true)

  const handleClose = () => {
    setIsTourOpen(false)
    history.push('/get_started')
  }

  const tourConfig = [
    {
      selector: '[data-tut="reactour__que"]',
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Que
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            If you have any doubts on each step you can activate the que to get more info during that particular steps and also a demo video.
          </Typography>
          <CustomButton variant='outlined' color='primary' style={{ marginTop: 15 }} onClick={handleClose}>
            skip
          </CustomButton>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__assessment"]',
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Assessment
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            For is where you prepare the reports and based on the criteria the reports can be generated.
          </Typography>
          <CustomButton variant='outlined' color='primary' style={{ marginTop: 15 }} onClick={handleClose}>
            skip
          </CustomButton>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__task"]',
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Task
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Task is the place where any tasks assigned to other team members or to yourself could be seen and acted on.
          </Typography>
          <CustomButton variant='outlined' color='primary' style={{ marginTop: 15 }} onClick={handleClose}>
            skip
          </CustomButton>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__generalSetting"]',
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            General Settings
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Settings from personal informations, company portals IOT Networks connectivities can be handled over here
          </Typography>
          <CustomButton variant='outlined' color='primary' style={{ marginTop: 15 }} onClick={handleClose}>
            skip
          </CustomButton>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__profile"]',
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Profile - Personal/ Company
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Profile card could be used to switch between companies to have a hazzsle free operations between them.
          </Typography>
          <CustomButton variant='contained' color='primary' style={{ marginTop: 15 }} onClick={handleClose}>
            go to steps
          </CustomButton>
        </div>
      )
    },
  ]

  return (
    <div>
      <HelperButton />
      <Tour
        onRequestClose={handleClose}
        steps={tourConfig}
        isOpen={isTourOpen}
        rounded={5}
      />
    </div>
  )
}

export default React.memo(OnboardinTour)