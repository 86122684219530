import {
    SET_FRAMEWORK_ID,
  SET_CATEGORY_ID,
  RESET_TOPIC
} from '../../constants/topics/topicConstants'

const initial_state = {
    frameworkId: '',
    categoryId:''
}

export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_FRAMEWORK_ID:
            return state = { ...state, frameworkId: action.payload }
        case SET_CATEGORY_ID:
            return state = {...state, categoryId:action.payload}    
      case RESET_TOPIC:
        return state = {
          ...state,
          frameworkId: '',
          categoryId: ''
        }
        default:
            return state;
    }
}