import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core';
import CustomButton from '../../UI/button/button';
import CustomTextfield from '../../UI/textfield/textfield';

const useStyles = makeStyles({
  blueText: {
    fontWeight: '600',
    color: '#3374B9',
    padding: '10px 0px',
  },
  Typography: { fontWeight: '600', padding: '15px 0px' },
  Card: { marginBottom: '32px' },
  grid: { marginTop: 20 },
  grid1: { paddingTop: 20 },
  Typography1: {
    fontWeight: '600',
    color: '#3374B9',
  },
  CustomTextfield: { backgroundColor: 'white' },
  CustomTextfield1: { width: '100%' },
  TableCell: { minWidth: 100 },
  TableCell1: { minWidth: 150 },
  Box1: { display: 'flex', alignItems: 'center' },
  Typography2: { fontWeight: '600', margin: '0px 5px' },
  Box2:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  }
});

const UpdateDialogueContent = (props) => {
  const classes = useStyles();
  const {
    dataType,
    metricResult,
    updateMetricResult,
    metricTitle,
    metricDesc,
    token,
    setIsSelectSubMetricsOpen,
    selectedSubmetricId,
    assessment_id,
    selectedMetricId,
    selectedTopicId,
  } = props;
  return (
    <>
      <Typography className={classes.Typography}>
        {metricTitle}
        {metricDesc}
      </Typography>
      {metricResult.length > 0 &&
        metricResult.map((metric, i) => (
          <Card className={classes.Card}>
            <CardContent>
              {dataType === 'Type 1' && metric.length !== 0 && (
                <>
                  {metric.data.map((res, i2) => {
                    return (
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        className={classes.grid}
                      >
                        <Grid item xs={8}>
                          <Typography className={classes.Typography1}>
                            {res.row}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <CustomTextfield
                            margin="dense"
                            variant="outlined"
                            className={classes.CustomTextfield}
                            value={res.data}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              )}
              {dataType === 'Type 2' && metric.length !== 0 && (
                <>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    className={classes.grid1}
                  >
                    <Grid item xs={12}>
                      <Paper>
                        <TableContainer>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center"></TableCell>
                                {metric.data[0].columns &&
                                  metric.data[0].columns.map((col, index) => (
                                    <TableCell
                                      key={index}
                                      align="center"
                                      className={classes.TableCell}
                                    >
                                      {col.column}
                                    </TableCell>
                                  ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {metric.data.map((row, rowIndex) => {
                                return (
                                  <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell align="center">
                                      <Typography>{row.row}</Typography>
                                    </TableCell>
                                    {row.columns &&
                                      row.columns.map((column, colIndex) => {
                                        return (
                                          <TableCell
                                            key={colIndex}
                                            align="center"
                                          >
                                            <CustomTextfield
                                              size="small"
                                              variant="outlined"
                                              className={
                                                classes.CustomTextfield1
                                              }
                                              value={
                                                metric.data[rowIndex].columns[
                                                  colIndex
                                                ].data
                                              }
                                            />
                                          </TableCell>
                                        );
                                      })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} sm={10}></Grid>
                  </Grid>
                </>
              )}
              {dataType === 'Type 3' && (
                <>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    className={classes.grid1}
                  >
                    <Grid item xs={12}>
                      <Paper>
                        {
                          <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className={classes.TableCell1}
                                  ></TableCell>
                                  {metric.data[0].columns &&
                                    metric.data[0].columns.length !== 0 &&
                                    metric.data[0].columns.map(
                                      (column, index) => (
                                        <TableCell
                                          key={index}
                                          align="center"
                                          className={classes.TableCell1}
                                        >
                                          {column.column}
                                        </TableCell>
                                      )
                                    )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {metric.data.map((row, rowIndex) => {
                                  return (
                                    <TableRow hover role="checkbox">
                                      <TableCell align="center">
                                        <Typography>{row.row}</Typography>
                                      </TableCell>
                                      {row.columns &&
                                        row.columns.map((col, colIndex) => {
                                          return (
                                            <TableCell
                                              key={colIndex}
                                              align="center"
                                            >
                                              <CustomTextfield
                                                size="small"
                                                variant="outlined"
                                                className={
                                                  classes.CustomTextfield1
                                                }
                                                value={
                                                  metric.data[rowIndex].columns[
                                                    colIndex
                                                  ].data
                                                }
                                              />
                                            </TableCell>
                                          );
                                        })}
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        }
                      </Paper>
                    </Grid>
                  </Grid>
                </>
              )}

              <Box
                className={classes.Box2}
              >
                <Box className={classes.Box1}>
                  <Avatar>R</Avatar>
                  <Typography className={classes.Typography2}>
                    Posted by
                  </Typography>
                  <Typography className={classes.blueText}>
                    {metric.user_name}
                  </Typography>
                </Box>
                <CustomButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    updateMetricResult(
                      token,
                      assessment_id,
                      selectedSubmetricId,
                      i,
                      selectedMetricId,
                      selectedTopicId,
                      true
                    );
                    setIsSelectSubMetricsOpen(false);
                  }}
                >
                  Select
                </CustomButton>
              </Box>
            </CardContent>
          </Card>
        ))}
    </>
  );
};

export default UpdateDialogueContent;
