import { makeStyles } from "@material-ui/styles";

export const AssessmentTopUseStyles = makeStyles((theme) => ({
	assessmentGridContainer: {
		height: 60,
	},
	assessmentGridItem: {
		backgroundColor: "#3374B933",
		margin: "0px 0px 20px 20px",
		padding: "10px",
		display: "flex",
		borderRadius: 10,
	},
	assesmentDive: {
		backgroundColor: "#ffffff",
		borderRadius: "50%",
		marginRight: 15,
		width: 35,
		height: 35,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	assesmentBox: {
		width: "100%",
	},
}));
