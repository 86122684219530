import { makeStyles } from '@material-ui/core';

const contributorButtonStyles = makeStyles((theme) => ({
  card: {
    textAlign: 'right',
    position: 'fixed',
    bottom: 0,
    width: '94%',
    zIndex: 100,
  },
  link: { textDecoration: 'none' },
  customButton: { marginLeft: 20 },
}));
export default contributorButtonStyles;
