import { makeStyles } from '@material-ui/core/styles';

const signupCardUseStyles = makeStyles({
  root: {
    maxWidth: '705px',
    maxHeight: '784px',
    minHeight: '722px',
    border: 'none',
    borderRadius: '8px',
  },

  cardHeader: {
    fontWeight: 600,
  },
  tab: {
    fontSize: 12,
  },
  tabLabel: {
    textTransform: 'capitalize',
    fontSize: 14,
    fontFamily: 'Poppins',
  },
  jc: {
    justifyContent: 'flex-end',
    paddingTop: '20px',
  },
  cardcontent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  labelText: {
    color: '#9FA1AF',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: '400',
    paddingBottom: '16px',
  },
  checkboxLabelText: {
    color: '#9FA1AF',
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: '400',
  },
  signupBtn: {
    textTransform: 'none',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: '600',
    fontFamily: 'Poppins',
    maxWidth: '263px',
    width: '100%',
    minWidth: '100px',
    height: '43px',
  },
  codeTitle: {
    textTransform: 'capitalize',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: '500',
    color: '#242424',
  },
  didntReceive: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: '400',
    color: '#242424',
    paddingRight: '16px',
  },
  sendAgain: {
    textTransform: 'none',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: '400',
    fontFamily: 'Poppins',
  },
  freetrial: {
    fontSize: 32,
    lineHeight: '48px',
    fontWeight: '600',
    paddingLeft: '23px',
  },
  featureTitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: '600',
    color: '#3374B9',
  },
  featureSubTitle: {
    color: '#9FA1AF',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: '400',
  },
  inputFieldFont: {
    fontSize: '13px',
  },
  inputField: {
    border: ' 1px solid #DADBE6',
    borderRadius: '5px',
    marginBottom: 5,
  },
  inputMobileWidth: {
    width: 300,
  },
  inputMobile: {},
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputCountryCode: {
    width: 70,
    height: 40,
    marginRight: 25,
    marginTop: 10,
    padding: '0 12px',
  },
  displayFlex: {
    display: 'Flex',
    alignItems: 'center',
  },
  labelCheckbox: {
    fontSize: '12px !important',
    color: '#828282',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  rootTab: {
    '&$selected': {
      backgroundColor: '#FFFFFF',
      background: 'none',
      borderBottom: '3px solid #47BB76',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },
  },
  selected: {
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  cardBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.18)',
  },
  cardList: {
    display: 'flex',
    paddingBottom: '0px',
    paddingRight: '88px',
  },
  listTypo: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  listTypo1: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  listTypo2: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  costumTextField: {
    marginBottom: '12px',
  },
  inputStyle: {
    cursor: 'pointer',
  },
  a: { textDecoration: 'none' },

  otpSpan: {
    textDecoration: 'none',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
  },
  otpSeperator: { width: '16px' },

  costumBtn: { width: '415px', height: '48px' },
  btnBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '80px',
  },
});

export default signupCardUseStyles;
