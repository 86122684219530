import { makeStyles } from "@material-ui/core";

const sustainabilityReportPDFStyles = makeStyles((theme) => ({
	setupIcocn: {
		width: 45,
	},
	setupCard: {
		width: "100%",
		height: 600,
		position: "sticky",
		top: 50,
		padding: 15,
		overflowY: "scroll",
		scrollbarWidth: "none",
	},
	cardTitle: {
		paddingLeft: 5,
		fontWeight: "bold",
	},
	addCoverImage: {
		border: "2px dashed #DADBE6",
		borderRadius: 10,
		height: 200,
		width: 150,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
	},
	coverImage: {
		borderRadius: 10,
		height: 200,
		width: 150,
		"& img": {
			width: "100%",
			height: "100%",
			borderRadius: 10,
		},
		position: "relative",
	},
	ClearImage: {
		position: "absolute",
		right: 0,
	},
	sendBtn: {
		marginTop: 10,
	},
	tocTitle: {
		color: "#9FA1AF",
		fontSize: 16,
		lineHeight: "24px",
		fontWeight: "600",
		paddingLeft: 16,
	},
	tocContent: {
		fontSize: 16,
		lineHeight: "24px",
		fontWeight: "500",
		color: "#242424",
		textTransform: "capitalize",
		// border:"1px solid red",
		minHeight:"25px"
	},
	tocContentGrid: {
		maxWidth: "450px",
		borderRadius: "8px",
		padding: "16px",
		"&:hover": {
			backgroundColor: "#D6E3F1",
		},
	},
	tocInput: {
		backgroundColor: "#fff",
		"&:fieldset": {
			borderRadius: 15,
		},
	},
	inputBox: {
		color: "#3374B9",
		fontWeight: "600",
		padding: "4px",
	},
	shareGrid: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
	shareTypo: {
		paddingBottom: 5,
		borderBottom: "3px solid green",
		display: "flex",
		justifyContent: "center",
		fontWeight: "bold",
	},
	autoWidth: { width: "100%" },
	halfWidth: { width: "40%" },
	CustomTextfield: { marginTop: 10 },
	costumTextfield1: { marginTop: 5, color: "#9FA1AF" },
	grid1: { marginTop: 20 },
	grid2: { display: "flex", justifyContent: "flex-end" },
	grid3: { paddingRight: 10 },
	costumBtn: { paddingLeft: 50, paddingRight: 50 },
	grid4: { borderBottom: "1px solid #DADBE6" },
	typo1: {
		paddingBottom: 5,
		borderBottom: "3px solid green",
		display: "flex",
		justifyContent: "center",
		fontWeight: "bold",
	},
	typo2: { paddingBottom: "50px" },
	box: {
		border: "1px solid #D9DADB",
		paddingTop: "8px",
		paddingBottom: "8px",
		paddingLeft: "16px",
		borderRadius: "5px",
	},
	box6: {
		backgroundColor: "#fff",
		borderRadius: "50%",
		width: 32,
		height: 32,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: 16,
		lineHeight: 24,
	},
	costumBtn1: { paddingLeft: 45, paddingRight: 45 },
	box1: {
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
	},
	box2: {
		backgroundColor: "#fff",
		borderRadius: "50%",
		width: 32,
		height: 32,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: 16,
		lineHeight: 24,
	},
	accordionDetails: { marginTop: 8 },
	avatar: {
		color: "#3374B9",
		backgroundColor: "#3374B9",
		height: "8px",
		width: "8px",
		marginRight: "8px",
	},
	typo3: {
		color: "#9FA1AF",
		fontSize: "14px",
		lineHeight: "21px",
	},
	grid5: { height: "100%", marginLeft: 5 },
	grid6: { marginTop: -95 },
	arrow: { cursor: "pointer" },
	mainDiv: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	},
	mainDivShare: {
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column",
		width: "100%",
	},
	costunBtn2: { marginLeft: "auto" },
	subDiv: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		marginTop: -10,
	},
	costunBtn3: { marginLeft: "auto", zIndex: 1 },
	divider: {
		width: "150%",
		position: "relative",
		right: 70,
	},
	accord: { display: "felx", flexDirection: "column" },
	input: { display: "none" },
	cancell: { color: "white" },
	search: { color: "#3374B9" },
	typo4: { position: "relative", top: "7px", left: "4px" },
	box3: { paddingTop: 24, paddingLeft: 17 },
	grid7: { paddingBottom: 40 },
	typo5: { color: "#9FA1AF" },
	avatar1: { backgroundColor: " #DDE9F6" },
	div1: { wordBreak: "break-word" },
	box4: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "8px",
		overflow: "scroll",
	},
	box5: {
		display: "flex",
		alignItems: "center",
		flexGrow: "4",
	},
	typo6: { fontSize: 16 },
	typo7: { fontSize: 14 },
	typo8: {
		fontWeight: "bold",
	},
}));

export default sustainabilityReportPDFStyles;
