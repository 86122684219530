import React, { Children } from "react";
import * as PropTypes from "prop-types";
import {
  Button,
  Menu,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  MenuList,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";

// import {  Dropdown } from 'antd';

// import PlusIcon from './plus-icon.svg';

import styled from "styled-components";

const StyledButton = styled(Button)`
  font-size: 14px;
  height: 48px;
  line-height: 3.5;
  box-shadow: 0px 2px 12px rgba(67, 67, 107, 0.1);
  border: none;
  color: #43436b;
  //animation-duration: 0s;

  &:hover + a {
    display: block;
  }

  &:hover,
  &.ant-dropdown-open,
  &:focus {
    color: #43436b;
  }

  &:after {
    animation: 0s;
  }

  & > i {
    position: relative;
    top: 3px;
  }
`;

const SelectedFilterButton = styled(StyledButton)`
  && {
    height: 40px;
    line-height: 40px;
    box-shadow: none;
    border: 1px solid #ececf0;
    border-radius: 4px;
  }
`;

const NewButton = styled(StyledButton)`
  color: #3374b9;
  border: none;
  box-shadow: none;
  padding-right: 105px;
  &:hover {
    color: #3374b9;
    background-color: white;
    box-shadow: inherit;
  }
`;

const TimeGroupButton = styled(NewButton)`
  border: none;
  padding: 0;
`;

const PlusIconButton = styled.span`
  margin-top: 10px;
`;

// const ButtonDropdown = ({ overlay, type, ...childrenButton }) => {

//   let component;
//   if (type === "icon") {
//     component = <PlusIconButton>+</PlusIconButton>;
//   } else if (type === "selected") {
//     component = <StyledButton {...childrenButton} />;
//   } else if (type === "time-group") {
//     component = <TimeGroupButton {...childrenButton} />;
//   } else if (type === "selected-filter") {
//     component = <SelectedFilterButton {...childrenButton} />;
//   } else {
//     component = <NewButton {...childrenButton} />;
//   }

//   return (
//     <FormControl fullWidth>
//       <InputLabel id="demo-simple-select-label">Age</InputLabel>
//       <Select
//         overlay={overlay}
//         value={"component"}
//         // onChange={handleChange}
//         placement="bottomLeft"
//         trigger={["click"]}
//       >
//         {overlay}
//       </Select>
//     </FormControl>
//   );
// };

const ButtonDropdown = ({
  onClick,
  availableMembers,
  type,
  childrenButton,
  open,
  handleClick,
  children,
}) => {
  let component;
  if (type === "icon") {
    component = (
      <PlusIconButton>
        <AddIcon />
      </PlusIconButton>
    );
  } else if (type === "selected") {
    component = <StyledButton {...childrenButton} />;
  } else if (type === "time-group") {
    component = <TimeGroupButton {...childrenButton} />;
  } else if (type === "selected-filter") {
    component = <SelectedFilterButton {...childrenButton} />;
  } else {
    component = <NewButton {...childrenButton} />;
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => handleClick(e)}
      >
        {component}
      </Button>
      {children}
    </div>
  );
};

ButtonDropdown.propTypes = {
  overlay: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["new", "icon", "selected"]),
};

export default ButtonDropdown;
