import React, { useEffect } from "react";
import {
  Box,
  TableRow,
  TableCell,
  IconButton,
  Icon,
  Typography,
  Collapse,
} from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import AddIcon from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import DynamicTable from "../../../../../UI/dynamicTable/DynamicTable";
import CustomButton from "../../../../../UI/button/button";
import { Question } from "../../../../surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionList";
import QuestionBody from "../../../../surveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";
import { useDispatch, useSelector } from "react-redux";
import { updateAuditQuestionStatus } from "../../../../../../actions/supplierAdmin/supplierAdminActions";

const textStyle = (type) => {
  let width = "2em";
  if (type === "main") {
    width = "50em";
  }

  return {
    color: "#15314E",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    width: width,
    borderBottom: "none",
    padding: "10px",
  };
};
const headings = [
  { name: "Que.No", showSort: false },
  { name: "Questions", showSort: false },
  { name: "Status", showSort: false },
  { name: "View", showSort: false },
];
const data = [
  {
    _id: "65dc3ba9b0dc39a9437050aa1",
    bank_name: "AcquiBank Patners",
    no_of_surveys: 1,
    contact_person: "Shanya Natrajan",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa2",
    bank_name: "FlowSource",
    no_of_surveys: 2,
    contact_person: "Another User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa1",
    bank_name: "AcquiBank Patners",
    no_of_surveys: 1,
    contact_person: "Shanya Natrajan",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa2",
    bank_name: "FlowSource",
    no_of_surveys: 2,
    contact_person: "Another User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa1",
    bank_name: "AcquiBank Patners",
    no_of_surveys: 1,
    contact_person: "Shanya Natrajan",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa2",
    bank_name: "FlowSource",
    no_of_surveys: 2,
    contact_person: "Another User",
    status: "Approved",
  },
];

const options = ["APPROVED", "In Progress", "COMPLETED"];

const statusStyle = (status) => {
  let colorToUse = "#8C7038";
  let bgColorToUse = "#FFEFD0";

  if (status === "complete") {
    colorToUse = "#5BB37F";
    bgColorToUse = "#EBF6EF";
  } else if (status === "notassign") {
    colorToUse = "black";
    bgColorToUse = "lightGrey";
  }

  return {
    width: "max-content",
    minWidth: "9em",
    height: "32px",
    borderRadius: 20,
    textTransform: "capitalize",
    // padding: "8px 12px 8px 12px",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    backgroundColor: bgColorToUse,
    gap: "4px",
    borderBottom: "none",
  };
};
function AuditQuestionTable({ handleExploreQuestion, sectionQuestions }) {
  const dispatch = useDispatch();
  const { supplier_id, token, current_organisation } = useSelector(
    (state) => state?.login
  );
  const {
    selectedAssignSurvey,
    selectedCorporate,
    auditSelectedSection,
    auditSectionDetails,
    auditQuestionDetails,
  } = useSelector((state) => state?.supplierAdmin);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);

  // Initialize statuses for each row
  const initialStatuses = new Array(sectionQuestions.length).fill(0); // initial value {0, 1, 2}
  const [statuses, setStatuses] = React.useState(initialStatuses);

  useEffect(() => {
    setStatuses(sectionQuestions.map((q) => q.status));
  }, [sectionQuestions]);

  // Initialize open state for each row
  const initialOpenStates = new Array(sectionQuestions.length).fill(false);
  const [openStates, setOpenStates] = React.useState(initialOpenStates);

  const handleClick = (event, rowIndex) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowIndex);
  };

  const handleMenuItemClick = (index, question_id) => {
    dispatch(
      updateAuditQuestionStatus(
        token,
        supplier_id,
        selectedCorporate?._id,
        question_id,
        selectedAssignSurvey?.frequency_id,
        index,
        selectedAssignSurvey?.survey_id,
        selectedAssignSurvey?.version
      )
    );
    const newStatuses = [...statuses];
    newStatuses[selectedRow] = index;
    setStatuses(newStatuses);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const colorObj = [
    { color: "#154328", backgroundColor: "#BFE2CD" },
    { color: "#15314E", backgroundColor: "#EBF1F8" },
    { color: "#154328", backgroundColor: "#BFE2CD" },
  ];

  return (
    <DynamicTable headings={headings}>
      {sectionQuestions.map((rowData, rowIndex) => (
        <React.Fragment key={rowData?._id || rowIndex}>
          <TableRow>
            <TableCell align="center" style={textStyle()}>
              {rowIndex + 1}
            </TableCell>
            <TableCell style={textStyle("main")}>{rowData.question}</TableCell>
            <TableCell style={{ border: "none", padding: "0px", width: "9em" }}>
              <CustomButton
                variant="contained"
                size="small"
                endIcon={<Icon>expand_more</Icon>}
                style={{
                  textTransform: "capitalize",
                  ...colorObj[statuses[rowIndex] === "In Progress" ? 1 : 0],
                  boxShadow: "none",
                  borderRadius: 8,
                }}
                onClick={(event) => handleClick(event, rowIndex)}
                disabled={
                  statuses[rowIndex] === "In Progress" ||
                  statuses[rowIndex] === "COMPLETED"
                }
              >
                {statuses[rowIndex] ? statuses[rowIndex]?.toLowerCase() : ""}
              </CustomButton>

              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={selectedRow === rowIndex && Boolean(anchorEl)}
                onClose={handleClose}
                elevation={1}
              >
                {options
                  .filter((option) => option !== statuses[rowIndex])
                  .map((option, index) => (
                    <MenuItem
                      key={option || index}
                      selected={statuses[rowIndex] === option}
                      onClick={() =>
                        handleMenuItemClick(option, rowData?.question_id)
                      }
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                      // disabled
                    >
                      Change to {option?.toLowerCase()}
                    </MenuItem>
                  ))}
              </Menu>
            </TableCell>
            <TableCell
              align="center"
              style={{ border: "none", padding: "0px" }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  const newOpenStates = [...openStates];
                  newOpenStates[rowIndex] = !newOpenStates[rowIndex];
                  setOpenStates(newOpenStates);
                  setSelectedRow(rowData?._id);
                }}
                // disabled={statuses[rowIndex] !== "Approved"}
              >
                <AddIcon fontSize="small" style={{ color: "#3374B9" }} />
              </IconButton>
            </TableCell>
          </TableRow>
          {rowData?._id === selectedRow && (
            <TableRow style={{ backgroundColor: "#F6F6F6" }}>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
                colSpan={6}
              >
                <Collapse
                  in={openStates[rowIndex]}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box
                    elevation={1}
                    margin={1}
                    style={{
                      border: "1px solid #E9E9E9",
                      backgroundColor: "#FEFEFE",
                      borderRadius: "8px",
                      padding: "10px",
                      pointerEvents: "none",
                      // height: "70px",
                    }}
                  >
                    <QuestionBody
                      question={rowData?.question}
                      answers={
                        selectedAssignSurvey?.is_default ||
                        rowData.question_type === "Single Choice" ||
                        rowData.question_type === "Single Choice with Text" ||
                        rowData.question_type ===
                          "Single Choice with Text & Doc" ||
                        rowData.question_type ===
                          "Single Choice with Document" ||
                        rowData.question_type === "Table"
                          ? rowData?.answers
                          : null
                      }
                      questionType={rowData?.question_type}
                      responseAnswer={rowData?.approved_data?.answer || null}
                      removeEditOption={true}
                      submittedAnswer={rowData?.approved_data?.answer || null}
                    />

                    {rowData?.comment &&
                      rowData?.approved_data?.comment_value && (
                        <QuestionBody
                          questionType={"Comment box"}
                          questionId={rowData?.question?.question_id}
                          answers={rowData?.approved_data?.comment_value}
                          isFrom={"questionComment"}
                          comment_value={rowData?.approved_data?.comment_value}
                          removeEditOption
                        />
                      )}
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      ))}
    </DynamicTable>
  );
}

export default AuditQuestionTable;
