import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AssessmentReportTop from "../../../widgets/assessmentTop/assessmentReportTop";
import AssessmentApprovalPDF from "./assessmentApprovalPdf";

class AssesmentApprovalReport extends Component {
	componentDidMount = () => {
		// if (this.props.assesmentApproval.pdfBlob === '') {
		this.props.getAssessmentData(
			this.props.login.token,
			this.props.assessment_id
		);
		// }
	};

	render() {
		return (
			<>
				<Grid item xs={12}>
					<Box my={2}>
						<AssessmentReportTop {...this.props} />
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					className="scroll"
					style={{ height: "100vh", margin: 10 }}
				>
					<Box ml={1}>
						<AssessmentApprovalPDF {...this.props} />
					</Box>
				</Grid>
			</>
		);
	}
}

export default AssesmentApprovalReport;
