import React, { memo, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ReportTypeOne from "./reportTypeOne";
import ReportTypeTwo from "./reportTypeTwo";
import ReportTypeThree from "./reportTypeThree";
import ReportTypeOnePointOne from "./reportTypeOnePointOne";
import FinalSingleMetricReportHeader from "./finalSingleReportHeader";
import Threads from "../viewThreads/viewThreads";

const useStyles = makeStyles(() => ({
  Box: { display: "flex", flexDirection: "column", width: "99%" },
  Typography: { fontWeight: "600", fontSize: 14 },
}));

function FinalSingleMetricReport(props) {
  const {
    login,
    fieldData,
    metric,
    index,
    setIsUpsertMetricData,
    addMetricsData,
    assessment_id,
    removeFile,
    chats,
    viewAssignedMembers,
    guidelines,
  } = props;

  const classes = useStyles();

  const { currentDetailedMetric } = fieldData;
  const [open, setOpen] = useState(false);
  const [listOfUsers, setListOfUsers] = useState([]);

  useEffect(() => {
    viewAssignedMembers(login.token, assessment_id, 5, metric._id);
    if (fieldData && fieldData.currentDetailedMetric) {
      // console.log('admin',fieldData &&  fieldData.currentDetailedMetric && fieldData.currentDetailedMetric?.admins)
      fieldData.currentDetailedMetric.admins &&
        fieldData.currentDetailedMetric?.admins.map((admin) => {
          setListOfUsers((prev) => [...prev, admin]);
        });
      fieldData.currentDetailedMetric.approvers &&
        fieldData?.currentDetailedMetric?.approvers.map((approver) => {
          setListOfUsers((prev) => [...prev, approver]);
        });
      fieldData.currentDetailedMetric.assignees &&
        fieldData?.currentDetailedMetric?.assignees.map((assignee) => {
          setListOfUsers((prev) => [...prev, assignee]);
        });
    }
  }, []);

  return (
    <>
      <div className="scroll" style={{ height: "66vh" }}>
        <FinalSingleMetricReportHeader
          {...props}
          guidelines={guidelines}
          setOpen={() => setOpen(true)}
        />
        {currentDetailedMetric && currentDetailedMetric?.metrics_data && (
          <Box my={2}>
            <Box className={classes.Box}>
              <Typography className={classes.Typography}>
                {currentDetailedMetric?.metrics_data?.metric_name}
              </Typography>
              {currentDetailedMetric?.metrics_data?.survey_data?.survey_type ===
                1 && (
                <ReportTypeOne
                  surveyData={currentDetailedMetric.metrics_data.survey_data}
                  surveyResultData={currentDetailedMetric.metrics_data.data}
                  isUpsertMetricData={fieldData.isUpsertMetricData}
                  setIsUpsertMetricData={setIsUpsertMetricData}
                  addMetricsData={addMetricsData}
                  fieldData={fieldData}
                  login={login}
                  assessment_id={assessment_id}
                  metricId={currentDetailedMetric.metrics_data._id}
                  index={index}
                  review={false}
                  removeFile={removeFile}
                  currentDetailedMetric={currentDetailedMetric}
                />
              )}
              {currentDetailedMetric?.metrics_data?.survey_data?.survey_type ===
                1.1 && (
                <ReportTypeOnePointOne
                  surveyData={currentDetailedMetric.metrics_data.survey_data}
                  surveyResultData={currentDetailedMetric.metrics_data.data}
                  isUpsertMetricData={fieldData.isUpsertMetricData}
                  setIsUpsertMetricData={setIsUpsertMetricData}
                  addMetricsData={addMetricsData}
                  fieldData={fieldData}
                  login={login}
                  assessment_id={assessment_id}
                  metricId={currentDetailedMetric.metrics_data._id}
                  index={index}
                  review={false}
                  removeFile={removeFile}
                  currentDetailedMetric={currentDetailedMetric}
                />
              )}
              {currentDetailedMetric?.metrics_data?.survey_data?.survey_type ===
                2 && (
                <ReportTypeTwo
                  surveyData={currentDetailedMetric?.metrics_data?.survey_data}
                  surveyResultData={currentDetailedMetric?.metrics_data?.data}
                  isUpsertMetricData={fieldData?.isUpsertMetricData}
                  setIsUpsertMetricData={setIsUpsertMetricData}
                  addMetricsData={addMetricsData}
                  fieldData={fieldData}
                  login={login}
                  assessment_id={assessment_id}
                  metricId={currentDetailedMetric?.metrics_data?._id}
                  index={index}
                  review={false}
                  removeFile={removeFile}
                  parentId={currentDetailedMetric?.metrics_data?.parent_id}
                  currentDetailedMetric={currentDetailedMetric}
                />
              )}
              {currentDetailedMetric?.metrics_data?.survey_data?.survey_type ===
                3 && (
                <ReportTypeThree
                  surveyData={currentDetailedMetric.metrics_data.survey_data}
                  surveyResultData={currentDetailedMetric.metrics_data.data}
                  isUpsertMetricData={fieldData.isUpsertMetricData}
                  setIsUpsertMetricData={setIsUpsertMetricData}
                  addMetricsData={addMetricsData}
                  fieldData={fieldData}
                  login={login}
                  assessment_id={assessment_id}
                  metricId={currentDetailedMetric.metrics_data._id}
                  index={index}
                  review={false}
                  removeFile={removeFile}
                  parentId={currentDetailedMetric.metrics_data.parent_id}
                  currentDetailedMetric={currentDetailedMetric}
                />
              )}
            </Box>
          </Box>
        )}
      </div>
      {open && (
        <Threads
          task={false}
          main={true}
          {...props}
          open={open}
          setOpen={setOpen}
          metric={currentDetailedMetric.metrics_data}
          onClose={() => setOpen(false)}
          listOfUsers={[...new Set(listOfUsers)]}
          isTaskSection={false}
        />
      )}
    </>
  );
}

export default memo(FinalSingleMetricReport);
