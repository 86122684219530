import React, { useEffect, useState } from "react";

import StepLeft from "../../stepLayout/left/StepLeft";
import { Box, Typography } from "@material-ui/core";

import CustomButton from "../../../../../UI/button/button";
import SurveyList from "./SurveyList";
import AssignDrawer from "../assignDrawer/AssignDrawer";

import { MdOutlineAccessTime } from "react-icons/md";
import { BiDockLeft } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getSections } from "../../../../../../actions/supplier/supplierAction";
import { setSelectedAdminSection } from "../../../../../../actions/supplierAdmin/supplierAdminActions";

function Step2Left({
  contributors,
  approvers,
  handleContributers,
  handleApprovers,
  handleDeleteApprovers,
  handleDeleteContributers,
  toggleSingleItemUser,
  handleReset,
  users,
  surveyList,
  handleSelectSection,
  handleStatus,
}) {
  const dispatch = useDispatch();
  const { selectedAssignSurvey } = useSelector((state) => state?.supplierAdmin);
  const { sectionWiseData } = useSelector((state) => state?.supplier);
  const { token } = useSelector((state) => state.login);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleSelect = (section) => {
    setSelectedIndex(section?._id);
    dispatch(setSelectedAdminSection(section));
  };

  useEffect(() => {
    if (sectionWiseData?.length === 0) {
      dispatch(
        getSections(
          token,
          selectedAssignSurvey?.survey_id,
          selectedAssignSurvey?.version
        )
      );
    }

    if (sectionWiseData?.length > 0) {
      handleSelect(sectionWiseData[0])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionWiseData]);



  return (
    <StepLeft>
      <Box
        style={{
          display: "flex",
          // padding: "10px",
          justifyContent: "space-between",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
          Survey Listing
        </Typography>
        <CustomButton
          variant="text"
          color="primary"
          onClick={() => setIsDrawerOpen(true)}
          style={{ textTransform: "capitalize" }}
        >
          Assign Section
        </CustomButton>
      </Box>
      <Box
        style={{
          display: "flex",
          padding: "10px",
          justifyContent: "flex-start",
          gap: 10,
          alignItems: "center",
        }}
      >
        <MdOutlineAccessTime size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          {selectedAssignSurvey?.survey_freequency}
        </Typography>
        <BiDockLeft size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          {sectionWiseData?.length} Sections
        </Typography>
      </Box>
      <Box style={{ overflow: "scroll", height: "80%" }}>
        <SurveyList
          surveyList={sectionWiseData}
          handleSelectSection={handleSelect}
          selectedIndex={selectedIndex}
        />
      </Box>
      {isDrawerOpen && (
        <AssignDrawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={() => {
            setIsDrawerOpen(false);
            handleStatus("In Progress");
            handleReset();
          }}
          contributors={contributors}
          approvers={approvers}
          handleContributers={handleContributers}
          handleApprovers={handleApprovers}
          handleDeleteApprovers={handleDeleteApprovers}
          handleDeleteContributers={handleDeleteContributers}
          users={users}
          handleReset={handleReset}
          toggleSingleItemUser={toggleSingleItemUser}
          surveyList={sectionWiseData}
          resource={"section"}
        />
      )}
    </StepLeft>
  );
}

export default Step2Left;
