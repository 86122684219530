// import React from "react";
// import Grid from "@material-ui/core/Grid";
// const Dashboard = ({ children }) => (
//   <Grid
//     container
//     md={12}
//     // spacing={3}
//     style={{
//       padding: 24,
//       border:"1px solid red"
//     }}
//     // // justify="space-around"
//     // alignItems="flex-start"
//   >
//     <Grid item md={12} style={{border:"1px solid yellow"}}>
//       {children}
//     </Grid>
//   </Grid>
// );
// export default Dashboard;

import React, { useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { useDispatch } from "react-redux";
import "react-resizable/css/styles.css";
import { setNewChartsLayout } from "../../../../actions/datahubActions/datahubActions";

const ReactGridLayout = WidthProvider(RGL);

const Dashboard = ({
  dataStream_id,
  children,
  dashboardItems,
  token,
  selectedOrganization,
  updateDashboardLayout,
  isEdit,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const dispatch = useDispatch();

  const onLayoutChange = (newLayout) => {
    dispatch(setNewChartsLayout(newLayout));
    // !dataStream_id && updateDashboardLayout(token, selectedOrganization, newLayout, "tabName")
  };

  return (
    <ReactGridLayout
      margin={[12, 12]}
      containerPadding={[0, 0]}
      onDragStart={() => setIsDragging(true)}
      onDragStop={() => setIsDragging(false)}
      onResizeStart={() => setIsDragging(true)}
      onResizeStop={() => setIsDragging(false)}
      cols={12}
      rowHeight={40}
      onLayoutChange={onLayoutChange}
      isDragging={isEdit}
      isResizable={isEdit}
      isDraggable={isEdit}
      isDroppable={isEdit}
      isBounded
      draggableCancel=".delete-icon"
    >
      {children}
    </ReactGridLayout>
  );
};

export default Dashboard;
