import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import sideleft from "../../../images/sidebarLeft.png";
import sideright from "../../../images/sidebarRight.png";
import leaf from "../../../images/leaf.svg";
import social from "../../../images/social.svg";
import certificate from "../../../images/certificate.svg";
import logoWhite from "../../../images/ImpactGrows White Corrected.svg";
import user from "../../../images/Avatar.svg";
import SignupCard from "../../UI/signupCard/signupCard";
import Onboaring from "../../widgets/onboarding/onboarding";
import "../../../styles/getStarted.css";
import { Redirect } from "react-router-dom";
import { addCompanyStyle } from "./addCompanyStyle";

export default function GetStarted(props) {
  const classes = addCompanyStyle();

  const { getIndustries } = props;
  const [redirect] = useState(false);

  useEffect(() => {
    getIndustries("");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (redirect) {
    return <Redirect to="/drawer"></Redirect>;
  }
  return (
    <>
      <Hidden mdDown>
        <div
        className={classes.hidden}
         
        >
          <img style={{maxWidth:'145px'}} src={localStorage.getItem('logo') ? localStorage.getItem('logo') : logoWhite} alt="logo" />
        </div>
        <Grid
          container
          className={classes.hiddenContainer}
        >
          <Grid container item className={`blueDiv blueDivAnimation`}>
            <Grid container>
              <Grid
                item
                container
                justifyContent="center"
                className={classes.hiddenItem}
              >
                <Grid item xs={10}>
                  <img src={user} alt="user" className="avatar" />
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.hiddenTypography} variant="h6">
                    Alright, <br />
                    Let's Start
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  {props.login.sideMessage === "signup" && (
                    <Typography
                    className={classes.hiddenBody1} 
                      variant="body1"
                    >
                      On the right you Sign up section. Dont worry your data is
                      100% secure & safe
                    </Typography>
                  )}
                  {props.login.sideMessage === "firstStep" && (
                    <Typography
                    className={classes.hiddenBody1} 
                      variant="body1"
                    >
                      On the right you have the Section Split to get a better
                      output,<b>start from Basic company details</b>
                    </Typography>
                  )}
                  {props.login.sideMessage === "secondStep" && (
                    <Typography
                    className={classes.hiddenBody1} 
                      variant="body1"
                    >
                      These details help you fill out the Reportings much more
                      faster
                    </Typography>
                  )}
                  {props.login.sideMessage === "thirdStep" && (
                    <Typography
                    className={classes.hiddenBody1} 
                      variant="body1"
                    >
                      This involves frameworks required to make your reports
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={10}>
                  <Typography
                   className={classes.hiddenBody} 
                    variant="body1"
                  >
                    Know More &gt; &gt;
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={10}
                className={classes.hiddenItem2}
               
              >
                {props.login.sideImage === "leaf" && <img src={leaf} alt="" />}
                {props.login.sideImage === "social" && (
                  <img src={social} alt="" />
                )}
                {props.login.sideImage === "certificate" && (
                  <img src={certificate} alt="" />
                )}
              </Grid>
              <Grid item container xs={12}></Grid>
              <img
                src={sideleft}
                alt="left"
                className={classes.hiddenImage1}
              />
              <img
                src={sideright}
                alt="right"
                className={classes.hiddenImage2}
              />
              <a
                href="/tnc"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.hiddenAnchor}
              >
                <ul
                  className={classes.hiddenUl}
                >
                  <li className="footerList">FAQ</li>
                  <li className="footerList">Terms & Conditions</li>
                  <li className="footerList">Privacy Policy</li>
                </ul>
              </a>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={9}
          >
            <Grid item xs={7}>
              <Onboaring {...props} />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <img
             className={classes.hiddenImageLogo}
              src={
                "https://firebasestorage.googleapis.com/v0/b/nextstacks.appspot.com/o/imgrows%20email%20imgs%2FBlack%20(1).png?alt=media&token=34161443-0cc7-4dc7-bade-474969af51f8"
              }
              alt="logo"
            />
          </Grid>

          <Grid item xs={12}>
            <SignupCard props={props} shrink={true} />
            {props.login.showOnboarding && <Onboaring {...props} />}
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
}
