import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelIcon from "@material-ui/icons/Cancel";
import MenuItem from "@material-ui/core/MenuItem";
import CheckIcon from "@material-ui/icons/Check";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import ViewSubmissionChatThread from "./SampleViewSubmission";
import DisclosureSetting from "./disclosureSetting";
import io from "socket.io-client";
import UNIVERSAL from "../../../config/config";
import moment from "moment";
import CustomMenu from "../../UI/customMenu/CustomMenu";
import CustomTextfield from "../../UI/textfield/textfield";
import {
  FormControl,
  Icon,
  Menu,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import ViewBotSuggestion from "./viewBotSuggestion";
import ChatBotSuggestionDrawer from "../chatBotSuggestion/chatBotSuggestionDrawer";
import { getViewSuggestions } from "../../../actions/datahubActions/datahubActions";
import {
  getTaskApprovalChat,
  getTaskSubmittedQuestions,
  setContributedQuestionDetails,
  setSurveyChat,
  setSurveyChatMembers,
} from "../../../actions/taskActions/TaskAction";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    "& .MuiBackdrop-root": {
      backdropFilter: "blur(2px)",
      backgroundColor: "#63b6a921",
    },
    "& .MuiDrawer-paper": {
      width: "80%",
    },
  },
  drop: {
    width: "165px",
    color: "#5bb37f",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 500,
    letterSpacing: "0.005em",
    height: "33px",
    border: "none !important",
    backgroundColor: "#ebf6ef",
    borderRadius: "8px",
    // marginTop: '15px',
    "& .MuiSelect-root": {},
  },
  Menu: { marginTop: 35, marginRight: 40 },
  companyLogo: {
    border: "2px solid #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    zIndex: 1,
    "& img": {
      width: "100%",
      height: "100%",
    },
    position: "relative",
  },
  ClearImage: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 2,
  },
  fileDisplayContainer: {
    background: "#FEFEFE",
    borderRadius: "8px",
    border: "1px solid #E9E9E9",
    display: "flex",
    flexDirection: "row",

    alignItems: " center",
    maxWidth: "350px",
  },
  fileName: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 500,
    color: "#15314E",
    letterSpacing: "0.005em",
    paddingLeft: "8px",
  },
  headTypography: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    color: "#1C4066",
    letterSpacing: "0.001em",
  },
  headerTypography: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 500,
    color: "#878787",
    letterSpacing: "0.001em",
  },
  statusText: {
    fontSize: "11px",
    lineHeight: "16px",
    fontWeight: 500,
    letterSpacing: "0.005em",
  },
}));

const dropDownTheme = createTheme({
  "& .MuiSelect-root": { border: "2px solid green" },
});
let socket;
function Threads({
  open,
  onClose,
  fieldData,
  setOpen,
  task = true,
  main,
  ...props
}) {
  // chatboat
  // from redux
  const dispatch = useDispatch();
  const login = useSelector((state) => state?.login);

  const { surveyChats, selectedTaskAssignedSurvey, surveyChatMembers } =
    useSelector((state) => state.taskReducer);
  const { selectedAssignSurvey, selectedSection } = useSelector(
    (state) => state?.supplierAdmin
  );
  const {
    assessment_id,
    question,
    questionId,
    frequency_id,
    addChatParam,
    // login,
    viewChats,
    deleteFile,
    viewChatsInTask,
    listOfUsers,
    setMetricChats,
    getSubmissionDetails,
    is_approver = true,
    isTaskSection,
    viewAssignedMembers,
    changeStatus,
    getdetailedMetrics,
  } = props;

  const { selectedTaskQuestion } = useSelector((state) => state.taskReducer);

  const { selectedCorporate } = useSelector((state) => state?.supplierAdmin);

  const classes = useStyles();
  const [thread, setThread] = useState(false);
  const [isDisclosureSettingsOpen, setIsDisclosureSettingsOpen] =
    useState(false);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState("");

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [messageRef, setMessageRef] = React.useState(0);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [disclosureFile, setDisclosureFile] = useState("");
  const [disclosureFileUrl, setDisclosureFileUrl] = useState("");
  const [dataUrl, setDataUrl] = useState("");
  const [showFileContainer, setShowFileContainer] = useState(false);
  const [showFileMenu, setShowFileMenu] = useState(false);
  const [clickedFile, setClickedFile] = useState("");

  React.useEffect(() => {
    setMessageRef(
      document.getElementById("message-cont")?.offsetHeight
        ? document.getElementById("message-cont")?.offsetHeight
        : 0
    );
  }, [messages]);

  useEffect(() => {
    // console.log("------- View Chat Hit --------");

    if (!thread) {
      dispatch(
        getTaskApprovalChat(
          login?.token,
          login?.supplier_id,
          selectedCorporate?._id,
          selectedTaskAssignedSurvey?.survey_id,
          selectedTaskAssignedSurvey?.frequency_id,
          addChatParam?.section_id,
          question?.question_id
        )
      );
    }
  }, [thread]);

  useEffect(() => {
    return () => {
      dispatch(setSurveyChat([]));
      dispatch(setSurveyChatMembers([]));
      dispatch(setContributedQuestionDetails({}));
    };
  }, []);

  const submissionsRef = useRef(messages);
  const dataUrlRef = useRef(dataUrl);
  const messageContainer = useRef();

  const SUBMISSION = "submission";
  const APPROVAL = "approval";
  const MESSAGE = "message";
  const currentUserId = localStorage.getItem("user_id");

  let userNamesArray =
    fieldData?.assignedMembers &&
    fieldData?.assignedMembers.map((user) => user.name);

  const reader = (file) => {
    let finalResult = {};
    finalResult.name = file.name;
    finalResult.type = file.type;
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        finalResult.file = fileReader.result;
        resolve(finalResult);
      };
      fileReader.readAsDataURL(file);
    });
  };
  const handleSendMessageCallback = useCallback((message, file) => {
    // if (file) {
    //   let finalFileObj = {
    //     file: file.file,
    //     fileName: file.name,
    //     fileType: file.type,
    //   };
    //   return {
    //     user_token: login.token,
    //     organisation_id: login.current_organisation,
    //     message,
    //     assessment_id,
    //     metric_id: question._id,
    //     files: finalFileObj,
    //   };
    // } else {
    return {
      ...addChatParam,
      message,
      user_token: addChatParam?.token,
    };
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReceiveMessageCallback = useCallback((msg) => {
    setMessages((messages) => [...messages, msg]);
  }, []);

  const handleReceiveSubmissionCallback = useCallback((sub) => {
    if (
      sub.is_submission &&
      submissionsRef.current.some(
        (submission) => submission.chat_id === sub.chat_id
      )
    ) {
      setMessages((prev) =>
        prev.map((s) => {
          if (s.chat_id === sub.chat_id) {
            s.messaged_on = sub.messaged_on;
            s.replies.push(sub);
            return s;
          } else {
            return s;
          }
        })
      );
    } else {
      setMessages((prev) => [...prev, sub]);
    }
  }, []);

  const handleDeleteFileCallback = useCallback((deleteFileObj) => {
    if (!deleteFileObj.is_submission) {
      setMessages((prev) =>
        prev.map((msg) => {
          if (msg.chat_id === deleteFileObj.chat_id) {
            msg.file = "";
            return msg;
          } else {
            return msg;
          }
        })
      );
    } else {
      setMessages((prev) =>
        prev.map((msg) => {
          msg.replies.map((reply) => {
            if (reply.reply_id === deleteFileObj.chat_id) {
              reply.file = "";
              return reply;
            } else {
              return reply;
            }
          });
          return msg;
        })
      );
    }
  }, []);

  useEffect(() => {
    submissionsRef.current = messages;
  }, [messages]);
  useEffect(() => {
    dataUrlRef.current = dataUrl;
  }, [dataUrl]);

  const handleJoinRoomCallBack = useCallback(() => {
    return { questionId, frequency_id };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket = io.connect(UNIVERSAL.URL_FOR_SOCKET);
    // viewAssignedMembers(login.token, assessment_id, 5, question._id);
  }, []);

  useEffect(() => {
    return () => {
      socket.disconnect();
      //clearing the redux state to avoid showing previous metrics chat
      // setMetricChats([]);
    };
  }, []);

  useEffect(() => {
    if (isTaskSection) {
      viewChatsInTask(
        login.token,
        assessment_id,
        question._id,
        is_approver ? true : false
      );
    } else {
      // viewChats(login.token, assessment_id, question._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (surveyChats?.length > 0) {
      setMessages(surveyChats);
    }
  }, [surveyChats]);

  useEffect(() => {
    socket.on("supplier-message", handleReceiveMessageCallback);
    socket.on("supplier-submission", handleReceiveSubmissionCallback);
    socket.on("supplier-delete-file", handleDeleteFileCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileUpload = async (e) => {
    e.preventDefault();
    setDisclosureFile(e.target.files[0]);
    setDisclosureFileUrl(URL.createObjectURL(e.target.files[0]));
    setShowFileContainer(true);
    reader(e.target.files[0]).then((result) => setDataUrl(result));
  };

  const handleFileClear = () => {
    setDisclosureFile("");
    setDisclosureFileUrl("");
    setShowFileContainer(false);
  };

  const handleScroll = () => {
    messageContainer.current.scrollTop = messageContainer.current?.scrollHeight;
  };
  useEffect(() => {
    if (messageContainer.current) {
      handleScroll();
    }
  }, [
    messages,
    messageContainer,
    isDisclosureSettingsOpen,
    showFileContainer,
    thread,
  ]);

  useEffect(() => {
    socket.emit("supplier-join", handleJoinRoomCallBack());
  }, [assessment_id, question?._id]);

  const handleSendMessage = () => {
    if (!message && !disclosureFile) return;
    if (message || disclosureFile) {
      socket.emit(
        "supplier-sendMessage",
        handleSendMessageCallback(message, dataUrlRef.current)
      );
    }
    setMessage("");
    setDisclosureFile("");
    setShowFileContainer(false);
    setDataUrl("");
    setDisclosureFileUrl("");
  };

  const checkIsUrl = (text) => {
    if (
      new RegExp(
        "[a-zA-Zd]+://(w+:w+@)?([a-zA-Zd.-]+.[A-Za-z]{2,4})(:d+)?(/.*)?"
      ).test(text)
    ) {
      let links;
      let urls = text.match(/\bhttps?:\/\/\S+/gi);
      links = urls || false;
      return links;
    }
  };
  const getTimeStamp = (timeStamp) => {
    var FIVE_MINUTES = 5 * 60 * 1000;
    var duration = Date.now() - timeStamp;

    if (duration < FIVE_MINUTES) {
      return "Just Now";
    } else {
      return moment(timeStamp).format("lll");
    }
  };

  const handleShowFileMenu = (chat_id) => {
    setShowFileMenu((prev) => !prev);
    setClickedFile(chat_id);
  };

  const handleFileDelete = (chat_id, is_submission, main_chat_id) => {
    if (is_submission) {
      setMessages((prev) =>
        prev.map((s) => {
          if (s.chat_id === main_chat_id) {
            s.replies.map((r) => {
              if (r.reply_id === chat_id) {
                r.file = "";
                return r;
              } else {
                return r;
              }
            });
            return s;
          } else {
            return s;
          }
        })
      );

      deleteFile(login.token, "", chat_id, assessment_id, question._id);
    } else {
      setMessages((prev) =>
        prev.map((s) => {
          if (s.chat_id === chat_id) {
            s.file = "";
            return s;
          } else {
            return s;
          }
        })
      );
      deleteFile(login.token, chat_id, "", assessment_id, question._id);
    }

    setShowFileMenu(false);
  };

  const FileDisplayContainer = ({
    name,
    url,
    type,
    user_id,
    msg_id,
    is_submission,
    main_chat_id,
  }) => {
    return (
      <Box className={classes.fileDisplayContainer} px={1} py={1}>
        <AttachFileIcon fontSize="small" color="primary" />

        <Typography className={classes.fileName}>{name}</Typography>

        <div style={{ marginLeft: "auto" }}>
          <MoreVertIcon
            fontSize="small"
            onClick={() => handleShowFileMenu(msg_id)}
          />
          {clickedFile === msg_id && showFileMenu && (
            <CustomMenu
              downloadLink={url}
              handelClose={() => setShowFileMenu(false)}
              showDelete={user_id === currentUserId}
              onDelete={() =>
                handleFileDelete(msg_id, is_submission, main_chat_id)
              }
              onDownload={handleShowFileMenu}
            />
          )}
        </div>
      </Box>
    );
  };

  const RenderLinks = ({ links }) => {
    return links.map((link, index) => (
      <a
        key={index + link}
        style={{
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.004em",

          fontWeight: "400",
        }}
        href={link}
        rel="noreferrer"
        target="_blank"
      >
        {link}
      </a>
    ));
  };

  const listOfMessages = () => {
    return messages
      .sort((x, y) => x.messaged_on - y.messaged_on)
      .map((msg, index) => {
        let filtered = [];
        if (msg.replies && msg.replies.length > 0) {
          filtered = msg.replies.filter((message) => {
            return (
              message.type === APPROVAL
              // message.type === APPROVAL && message.message_by !== currentUserId
            );
          });
        }

        return (
          <div key={msg.chat_id}>
            {msg.is_submission ? (
              <>
                <Box
                  maxWidth="600px"
                  p={1}
                  marginBottom="10px"
                  bgcolor="#F6F6F6"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={2}
                >
                  <Avatar
                    src="https://imgrows.s3.us-east-2.amazonaws.com/publicfiles/Black_logo.png"
                    variant="rounded"
                    style={{ backgroundColor: "#fff" }}
                  />
                  <Box ml={0.4} paddingLeft="8px">
                    <Typography variant="caption">
                      New contribution from {msg.user_data.name} on Question{" "}
                      {question?.order && question.order}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  m={1}
                  bgcolor="#EBF1F8"
                  color="#A1BFDF"
                  p={2}
                  borderRadius={4}
                  border={2}
                  borderColor="#A1BFDF"
                >
                  <Box display="flex" alignItems="center">
                    <Box>
                      <FormatListBulletedIcon />
                    </Box>
                    <Box>
                      <List disablePadding dense>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar
                              src={
                                msg.user_data.profile_img &&
                                msg.user_data.profile_img.url
                                  ? msg.user_data.profile_img.url
                                  : `https://avatars.dicebear.com/api/initials/${msg.user_data.name}.svg?bold=1`
                              }
                            ></Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            style={{ color: "#000" }}
                            primary={
                              <>
                                <span
                                  style={{
                                    paddingRight: "8px",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.001em",
                                    color: "#242424",
                                    textTransform: "capitalize",
                                    fontWeight: "500",
                                  }}
                                >
                                  {msg.user_data._id === currentUserId
                                    ? "You"
                                    : msg.user_data.name}
                                </span>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    letterSpacing: "0.005em",
                                    color: "#AFAFAF",
                                    fontWeight: "500",
                                  }}
                                >
                                  {getTimeStamp(msg.messaged_on)}
                                </span>
                              </>
                            }
                          />
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                  {msg.replies &&
                    msg.replies.length > 0 &&
                    msg.replies.map((reply) => {
                      return (
                        <Box
                          key={reply.messaged_on}
                          display="flex"
                          alignItems="center"
                        >
                          <Box>
                            {reply.type === MESSAGE && (
                              <ChatBubbleOutlineIcon />
                            )}
                            {reply.type === SUBMISSION && (
                              <FormatListBulletedIcon />
                            )}
                            {reply.type === APPROVAL && <CheckIcon />}
                          </Box>
                          <Box>
                            <List disablePadding dense>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar
                                    src={
                                      reply?.user_data?.profile_img &&
                                      reply?.user_data?.profile_img.url
                                        ? reply?.user_data?.profile_img.url
                                        : `https://avatars.dicebear.com/api/initials/${reply?.user_data?.name}.svg?bold=1`
                                    }
                                  ></Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  style={{ color: "#000" }}
                                  primary={
                                    <>
                                      <span
                                        style={{
                                          paddingRight: "8px",
                                          fontSize: "14px",
                                          lineHeight: "20px",
                                          letterSpacing: "0.001em",
                                          color: "#242424",
                                          textTransform: "capitalize",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {reply?.user_data?._id === currentUserId
                                          ? "You"
                                          : reply?.user_data?.name}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          lineHeight: "16px",
                                          letterSpacing: "0.005em",
                                          color: "#AFAFAF",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {getTimeStamp(reply.messaged_on)}
                                      </span>
                                    </>
                                  }
                                  secondary={
                                    <>
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          lineHeight: "16px",
                                          letterSpacing: "0.004em",
                                          color: "#0F0F0F",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {reply.message}
                                      </span>
                                      {reply?.file !== "" ? (
                                        <FileDisplayContainer
                                          name={reply?.file.name}
                                          url={reply.file.url}
                                          type={reply.file.type}
                                          user_id={reply.user_data._id}
                                          msg_id={
                                            reply.reply_id
                                              ? reply.reply_id
                                              : reply._id
                                          }
                                          main_chat_id={msg.chat_id}
                                          is_submission={true}
                                        />
                                      ) : null}
                                    </>
                                  }
                                />
                              </ListItem>
                            </List>
                          </Box>
                        </Box>
                      );
                    })}
                  <Box mt={1} display="flex">
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => {
                        // getSubmissionDetails(
                        //   login.token,
                        //   msg.submission_id,
                        //   msg.user_data._id,
                        //   msg
                        // );
                        dispatch(
                          getTaskSubmittedQuestions(
                            addChatParam?.token,
                            addChatParam?.question_id,
                            addChatParam?.frequency_id,
                            msg
                          )
                        );
                        setSelectedSubmissionId(msg.chat_id);
                        setThread(true);
                      }}
                    >
                      {question?.is_approved || hasSubmitted
                        ? "View Approved Version"
                        : "View submission"}
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (
              <ListItem key={msg.messaged_on}>
                <ListItemAvatar>
                  <Avatar
                    src={
                      msg?.user_data?.profile_img &&
                      msg?.user_data?.profile_img.url
                        ? msg?.user_data?.profile_img?.url
                        : `https://avatars.dicebear.com/api/initials/${msg.user_data.name}.svg?bold=1`
                    }
                  ></Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <span
                        style={{
                          paddingRight: "8px",
                          fontSize: "14px",
                          lineHeight: "20px",
                          letterSpacing: "0.001em",
                          color: "#242424",
                          textTransform: "capitalize",
                          fontWeight: "500",
                        }}
                      >
                        {msg?.user_data?._id === currentUserId
                          ? "You"
                          : msg?.user_data?.name}
                      </span>
                      <span
                        style={{
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.005em",
                          color: "#AFAFAF",
                          fontWeight: "500",
                        }}
                      >
                        {getTimeStamp(msg.messaged_on)}
                      </span>
                    </>
                  }
                  secondary={
                    <>
                      {!checkIsUrl(msg.message) && (
                        <span
                          style={{
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.004em",
                            color: "#0F0F0F",
                            fontWeight: "400",
                          }}
                        >
                          {msg.message}
                        </span>
                      )}
                      {Array.isArray(checkIsUrl(msg.message)) && (
                        <>
                          <RenderLinks links={checkIsUrl(msg.message)} />
                        </>
                      )}

                      {msg?.file?.hasOwnProperty("url") && (
                        <FileDisplayContainer
                          name={msg.file.name}
                          url={msg.file.url}
                          type={msg.file.type}
                          user_id={msg.user_data._id}
                          msg_id={msg.chat_id}
                          is_submission={false}
                        />
                      )}
                    </>
                  }
                />
              </ListItem>
            )}
          </div>
        );
      });
  };

  let leftValue = 25;
  const getLeftOffsetValue = (index) => {
    if (index === 0) {
      return 0;
    } else if (index === 1) {
      return leftValue;
    } else if (index === 2) {
      return leftValue + 25;
    }
  };

  const [dropDownValue, setDropDownValue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    !task &&
      (question.is_completed
        ? setDropDownValue("Completed")
        : fieldData?.currentDetailedMetric &&
          fieldData?.currentDetailedMetric.metrics_data.is_approved
        ? setDropDownValue("Approved")
        : fieldData?.currentDetailedMetric &&
          fieldData?.currentDetailedMetric.metrics_data.is_awaiting_approval
        ? setDropDownValue("Awaiting Approval")
        : setDropDownValue(dropDownValue));
  }, []);

  useEffect(() => {
    if (!task) {
      if (dropDownValue === "Completed")
        changeStatus(login.token, assessment_id, question._id, "completed");
      if (dropDownValue === "Approved")
        changeStatus(login.token, assessment_id, question._id, "approved");
      if (dropDownValue === "Awaiting Approval") {
        changeStatus(
          login.token,
          assessment_id,
          question._id,
          "awaiting_approval"
        );
      }
    }
  }, [dropDownValue]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    e.target.innerText && setDropDownValue(e.target.innerText);
    setAnchorEl(null);
  };
  const [isViewBotSuggestion, setIsViewBotSuggestion] = useState(false);

  const handleCloseChatBoatDrawer = () => {
    setIsViewBotSuggestion(false);
  };
  // isViewBotSuggestion ? (

  // ) :
  return (
    <>
      {isViewBotSuggestion && (
        <ChatBotSuggestionDrawer
          isDrawerOpen={isViewBotSuggestion}
          handleDrawer={handleCloseChatBoatDrawer}
          chartSchema={question?.cubejs_schema2}
          currentOrganisation={login?.current_organisation}
          isHide={false}
        />
      )}
      <Drawer
        anchor="right"
        className={classes.backdrop}
        open={open}
        onClose={() => {
          setOpen(false);
          !task && getdetailedMetrics(login.token, question._id, assessment_id);
        }}
      >
        {thread ? (
          // <ViewBotSuggestion
          //   {...props}
          //   task={task}
          //   main={main}
          //   is_approver={is_approver}
          //   fieldData?={fieldData?}
          //   setThread={setThread}
          //   isViewBotSuggestion={setIsViewBotSuggestion}
          //   socket={socket}
          //   login={login}
          //   question={question}
          //   assessment_id={assessment_id}
          //   selectedSubmissionId={selectedSubmissionId}
          // />
          <ViewSubmissionChatThread
            {...props}
            task={task}
            main={main}
            is_approver={is_approver}
            fieldData={fieldData}
            setThread={setThread}
            socket={socket}
            login={login}
            question={question}
            assessment_id={assessment_id}
            selectedSubmissionId={selectedSubmissionId}
            questionId={questionId}
            frequency_id={frequency_id}
            addChatParam={addChatParam}
            setHasSubmitted={setHasSubmitted}
          />
        ) : isDisclosureSettingsOpen ? (
          <DisclosureSetting
            task={false}
            {...props}
            fieldData={fieldData}
            setIsDisclosureSettingsOpen={setIsDisclosureSettingsOpen}
          />
        ) : (
          <>
            <Box p={1}>
              <Grid container justifyContent="center">
                <Grid item xs={1}>
                  <Typography
                    variant="caption"
                    className={classes.headerTypography}
                  >
                    Ques No.
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="caption"
                    className={classes.headerTypography}
                  >
                    Question
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="caption"
                    className={classes.headerTypography}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="caption"
                    className={classes.headTypography}
                  >
                    {question?.order && question.order}
                    {question?.metric_name &&
                      !question.metric_name.includes(":") &&
                      question.metric_name.split(".")[0]}
                    {question?.metric_name &&
                      question.metric_name.includes(":") &&
                      question.metric_name.split(":")[0]}
                    {question?.metric_desc &&
                      question.metric_name.includes(":") &&
                      question.metric_desc.split(":")[0]}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="caption"
                    className={classes.headTypography}
                  >
                    {question?.question && question.question}
                    {question?.metric_name &&
                      question.metric_name.split(":")[1]}
                    {question?.metric_desc &&
                      question.metric_desc.split(":")[1]}
                    {question?.metric_name &&
                      !question.metric_name.includes(":") &&
                      `${
                        question.metric_name.split(".")[1] +
                        "." +
                        (question.metric_name.split(".")[2] &&
                          question.metric_name.split(".")[2])
                      }`}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        bgcolor={
                          task
                            ? "#EBF6EF"
                            : question.is_completed
                            ? "#EBF6EF"
                            : question.is_approved
                            ? "#EBF6EF"
                            : question.is_awaiting_approval
                            ? "#EBF6EF"
                            : question.in_progress
                            ? "#EBF6EF"
                            : "#E9E9E9"
                        }
                        color={
                          task
                            ? "#15314E"
                            : question.is_completed
                            ? "#5BB37F"
                            : question.is_approved
                            ? "#5BB37F"
                            : question.is_awaiting_approval
                            ? "#15314E"
                            : question.in_progress
                            ? "#15314E"
                            : "#9FA1AF"
                        }
                        p={1}
                        borderRadius="8px"
                        maxHeight="32px"
                        maxWidth={!task ? "167px" : " 150px"}
                      >
                        <Box
                          borderRadius="50%"
                          height={12}
                          width={12}
                          bgcolor={
                            task
                              ? question?.is_approved
                                ? "#5BB37F"
                                : question?.is_awaiting_approval
                                ? "#EBD947"
                                : "#EBD947"
                              : question?.is_completed
                              ? "#5BB37F"
                              : question?.is_approved
                              ? "#5BB37F"
                              : question?.is_awaiting_approval
                              ? "#EBD947"
                              : question?.in_progress
                              ? "#EBD947"
                              : "#9FA1AF"
                          }
                        />
                        <Box pl={1} className={classes.statusText}>
                          {Array?.isArray(selectedTaskQuestion) &&
                            selectedTaskQuestion?.find(
                              (q) => q?.question_id === question?.question_id
                            )?.status}
                          {/* {task
                            ? question?.status
                              ? `Approved`
                              : question?.is_awaiting_approval
                              ? "Awaiting Approval"
                              : question?.can_approve
                              ? "Awaiting Approval"
                              : "In Progress"
                            : fieldData?.currentDetailedMetric &&
                              fieldData?.currentDetailedMetric.metrics_data
                                .is_completed
                            ? `${dropDownValue}`
                            : fieldData?.currentDetailedMetric &&
                              fieldData?.currentDetailedMetric.metrics_data
                                .is_approved
                            ? `${dropDownValue}`
                            : fieldData?.currentDetailedMetric &&
                              fieldData?.currentDetailedMetric.metrics_data
                                .is_awaiting_approval
                            ? `${dropDownValue}`
                            : fieldData?.currentDetailedMetric &&
                              fieldData?.currentDetailedMetric.metrics_data
                                .in_progress
                            ? "In Progress"
                            : "Yet to be Assigned"} */}
                        </Box>
                        <Box>
                          {!task &&
                            (question?.is_completed ||
                              question?.is_approved) && (
                              <ExpandMoreIcon
                                style={{ cursor: "pointer", paddingTop: "2px" }}
                                onClick={(e) =>
                                  (question?.is_completed ||
                                    question?.is_approved) &&
                                  handleClick(e)
                                }
                              />
                            )}
                        </Box>
                      </Box>
                      {!task && (
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          style={{ top: "45px", left: "-90px", width: "280px" }}
                        >
                          <MenuItem value="Completed" onClick={handleClose}>
                            Completed
                          </MenuItem>
                          <MenuItem value="Approved" onClick={handleClose}>
                            Approved
                          </MenuItem>
                          <MenuItem
                            value="Awaiting Approval"
                            onClick={handleClose}
                          >
                            Awaiting Approval
                          </MenuItem>
                        </Menu>
                      )}
                    </Grid>
                    <Grid item xs={2} align="center">
                      {!task && (
                        <IconButton
                          size="small"
                          onClick={() => {
                            !task && setIsDisclosureSettingsOpen(true);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item xs={2} align="center">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setOpen(false);
                          !task &&
                            getdetailedMetrics(
                              login.token,
                              question._id,
                              assessment_id
                            );
                        }}
                      >
                        <ExitToAppIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box p={1}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={1}>
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      alignItems: "center",
                    }}
                  >
                    {surveyChatMembers &&
                      surveyChatMembers.map((user, index) => {
                        return (
                          <div
                            key={user?._id}
                            style={{
                              position: "absolute",
                              left: getLeftOffsetValue(index),
                            }}
                          >
                            <Avatar
                              style={{ border: "1px solid #EBF1F8" }}
                              src={
                                user?.profile_img &&
                                user.profile_img.url &&
                                user.profile_img.url
                              }
                            >
                              A
                            </Avatar>
                          </div>
                        );
                      })}
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="caption"
                    className={classes.headTypography}
                    style={{ marginLeft: "18px" }}
                  >
                    {` ${
                      // fieldData?.assignedMembers &&
                      // fieldData?.assignedMembers?.length
                      surveyChatMembers?.length
                    }
                    members`}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    select
                    value={"View all Responses"}
                    size="small"
                    variant="outlined"
                  >
                    <MenuItem value="View all Responses">
                      View all Responses
                    </MenuItem>
                  </TextField>
                  {task && (
                    <Button
                      onClick={() => {
                        setIsViewBotSuggestion(true);
                        dispatch(
                          getViewSuggestions(
                            login?.token,
                            login?.current_organisation,
                            question?.kpi_name,
                            question?.framework_id
                          )
                        );
                        // setThread(true);
                      }}
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        textTransform: "none",
                        marginLeft: "15px",
                      }}
                    >
                      view bot Suggestion
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <div
              className="scroll"
              ref={messageContainer}
              style={{ height: "90vh", background: "#FCFCFC" }}
            >
              <Box
                height={
                  messageRef === 0
                    ? "412.5px"
                    : parseInt(412.5 - messageRef) > 0
                    ? parseInt(412.5 - messageRef) + "px"
                    : "0px"
                }
              />
              {/* <Box px={1} align="center" pt={1}>
                <Box
                  width="90%"
                  p={1}
                  bgcolor="#F6F6F6"
                  display="flex"
                  alignItems="center"
                  borderRadius={2}
                  marginBottom="10px"
                >
                  <Avatar
                    src="https://imgrows.s3.us-east-2.amazonaws.com/publicfiles/Black_logo.png"
                    variant="rounded"
                    style={{ backgroundColor: "#fff" }}
                  />
                  <Box pl="8px">
                    <Typography variant="caption">
                      This conversation is between{" "}
                      {userNamesArray && userNamesArray.toString()} in this
                      disclosure. Share files and comments to complete it.
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
              <Box px={1} align="center">
                <Box display="flex" alignItems="center">
                  {/* <Box px={2} width="10%">
                  <Typography variant="caption">2 replies</Typography>
                </Box> */}
                  <Box px={2} width="100%">
                    <Divider />
                  </Box>
                </Box>
              </Box>
              <Box px={1} align="center" id="message-cont">
                <List disablePadding dense>
                  {listOfMessages()}
                </List>
              </Box>
              {/* <Box px={1} align="center">
              <Box display="flex" alignItems="center">
                <Box px={1} width="15%">
                  <Typography variant="caption">New messages</Typography>
                </Box>
                <Box width="85%">
                  <Divider />
                </Box>
              </Box>
            </Box> */}
              {/* <Divider /> */}
            </div>
            <div style={{ background: "#FCFCFC", width: "100%" }}>
              {showFileContainer && (
                <Box
                  width="90.50%"
                  mx={1}
                  px={1}
                  py={1}
                  style={{
                    border: "1px solid #c4c4c4",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 8px rgba(51, 116, 185, 0.25);",
                    background: "#FCFCFC",
                  }}
                >
                  {disclosureFile !== "" && (
                    <>
                      {disclosureFile.type === "image/jpeg" ? (
                        <div className={classes.companyLogo}>
                          <img src={disclosureFileUrl} alt="company-logo" />
                          <IconButton
                            onClick={handleFileClear}
                            className={classes.ClearImage}
                          >
                            <CancelIcon
                              style={{ color: "gray" }}
                              fontSize="small"
                              Box
                            />
                          </IconButton>
                        </div>
                      ) : (
                        <div>
                          <Chip
                            label={disclosureFile.name}
                            variant="outlined"
                            size="medium"
                            onDelete={handleFileClear}
                          />
                        </div>
                      )}
                    </>
                  )}
                </Box>
              )}
            </div>

            <Box px={1} py={1} align="center" style={{ background: "#FCFCFC" }}>
              <Grid container alignItems="center">
                <Grid item xs={11}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    multiline
                    minRows={4}
                    placeholder="Messages from this disclosure"
                    InputProps={{
                      endAdornment: (
                        <>
                          <input
                            id="contained-button-disclosureFile"
                            type="file"
                            onClick={(e) => (e.target.value = null)}
                            onChange={handleFileUpload}
                          />
                          <label htmlFor="contained-button-disclosureFile">
                            <Box
                              display="flex"
                              component="span"
                              alignItems="center"
                              style={{ cursor: "pointer" }}
                            >
                              <AttachFileIcon />
                            </Box>
                          </label>
                        </>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button onClick={() => handleSendMessage()} color="primary">
                    send
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Drawer>
    </>
  );
}

export default Threads;
