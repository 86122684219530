import React from "react";
import { connect } from "react-redux";
import ReportListingScreen from "../../components/screen/reportListing/reportListing";
import {
  changePasswordValidation,
  setChangePasswordDialog,
  getOrganizations,
  viewAssignedAssessments,
  viewAssessments,
  setAssessmentsPaginationPage,
} from "../../actions/surveyListing/surveyListingAction";
import { setLogin } from "../../actions/login/loginActions";
import { setPdfBlob } from "../../actions/assessmentApproval/assessmentApproval";
import { setPdfBlobs } from "../../actions/sustainabilityReport/sustainabilityReportAction";

export class ReportListingCont extends React.PureComponent {
  componentWillUnmount() {
    if (window?.location?.pathname !== "/report_listing/esg_report") {
      this.props.setPaginationPage(0);
    }
  }


  render() {
    return <ReportListingScreen {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    login: store.login,
    reportlisting: store.reportlisting,
    surveyListing: store.surveyListing,
    materialityAssessment: store.materialityAssessment,
    basicInfo: store.basicInfo,
    sustainabilityReport: store.sustainabilityReport,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setChangePasswordDialog: (payload) => {
      dispatch(setChangePasswordDialog(payload));
    },
    changePasswordValidation: (
      token,
      password,
      confPassword,
      organisation_id
    ) => {
      dispatch(
        changePasswordValidation(token, password, confPassword, organisation_id)
      );
    },
    viewAssessments: (
      token,
      surveyListing,
      current_organisation,
      is_ascending
    ) => {
      dispatch(
        viewAssessments(
          token,
          surveyListing,
          current_organisation,
          is_ascending
        )
      );
    },
    viewAssignedAssessments: (
      token,
      limit,
      startingAfter,
      surveyListing,
      current_organisation,
      keyword,
      is_ascending
    ) => {
      dispatch(
        viewAssignedAssessments(
          token,
          limit,
          startingAfter,
          surveyListing,
          current_organisation,
          keyword,
          is_ascending
        )
      );
    },
    getOrganizations: (payload, domain_name) => {
      dispatch(getOrganizations(payload, domain_name));
    },
    setLogin: (payload) => {
      dispatch(setLogin(payload));
    },
    setPdfBlob: (payload) => {
      dispatch(setPdfBlob(payload));
    },
    setPdfBlobs: (payload) => {
      dispatch(setPdfBlobs(payload));
    },
    setPaginationPage: (payload) => {
      dispatch(setAssessmentsPaginationPage(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportListingCont);
