import React, { useEffect, useState } from "react";
import StepLayOut from "../stepLayout";
import Step3Left from "./left/Step3Left";
import Step3Right from "./right/Step3Right";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuditQuestions,
  getAuditSections,
  setAuditQuestionDetails,
  setAuditSectionDetails,
  setSelectedSectionAudit,
} from "../../../../../actions/supplierAdmin/supplierAdminActions";

function SurveyStep3({ response }) {
  const dispatch = useDispatch();
  const { supplier_id, token, current_organisation } = useSelector(
    (state) => state?.login
  );
  const {
    selectedAssignSurvey,
    auditSelectedSection,
    auditSectionDetails,
    auditQuestionDetails,
    selectedCorporate
  } = useSelector((state) => state?.supplierAdmin);

  const {
    question_collected,
    questions_approved,
    questions_assigned,
    questions_completed,
    sections,
  } = auditSectionDetails || {};

  const [tabValue, setTabValue] = React.useState(0);

  const [selectedIndex, setSelectedIndex] = React.useState(1);



  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSelectSection = (section) => {
    setSelectedIndex(section?._id);

    dispatch(setSelectedSectionAudit(section));
  };

  useEffect(() => {
    if (!sections) {
      dispatch(
        getAuditSections(
          token,
          supplier_id,
          selectedCorporate?._id,
          selectedAssignSurvey?.survey_id,
          selectedAssignSurvey?.version,
          selectedAssignSurvey?.frequency_id
        )
      );
    }

    if(Array.isArray(sections) && sections?.length > 0 && !auditQuestionDetails){
      handleSelectSection(sections[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  useEffect(() => {
    auditSelectedSection &&
      dispatch(
        getAuditQuestions(
          token,
          supplier_id,
          selectedCorporate?._id,
          auditSelectedSection?._id,
          selectedAssignSurvey?.version,
          selectedAssignSurvey?.frequency_id
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditSelectedSection]);

  useEffect(() => {
    return () => {
      dispatch(setAuditSectionDetails(null));
      dispatch(setSelectedSectionAudit(null));
      dispatch(setAuditQuestionDetails(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StepLayOut
      left={
        <Step3Left
          surveyList={sections || []}
          handleSelectSection={handleSelectSection}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          question_collected={question_collected}
          questions_approved={questions_approved}
          questions_assigned={questions_assigned}
          questions_completed={questions_completed}
          selectedIndex={selectedIndex}
        />
      }
      right={
        <Step3Right
          sectionQuestions={auditQuestionDetails?.questions || []}
          questionsDetails={auditQuestionDetails}
          tabValue={tabValue}
        />
      }
    />
  );
}

export default SurveyStep3;
