import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import GaugeChart from "react-gauge-chart";
import { Doughnut, Pie } from "react-chartjs-2";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  cutout: "80%",
  rotation: 270,
  circumference: 180,
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

// const config = {
//   type: 'pie',
//   data: data,
//   options: {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//       title: {
//         display: true,
//         text: 'Chart.js Pie Chart'
//       }
//     }
//   },
// };


export default function PieChart({ dataHub }) {
   const data = {
    labels: ["Red", "Blue"],
    datasets: [
      {
        data: [dataHub.calculatedData['Percentage of Female Board of Directors'], 100],
        backgroundColor: ["rgba(214, 227, 241, 1)", "rgba(51, 116, 185, 1)"],
        borderColor: ["rgba(214, 227, 241, 1)", "rgba(51, 116, 185, 1)"],
        borderWidth: 1,
      },
    ],
  };
  console.log(
    dataHub.calculatedData
  );
  const chartStyle = {
    height: 200,
  };

  return (
    <Card
      style={{
        height: "180%",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        border: "2px solid #DADBE6",
      }}
    >
      <CardContent style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            width: "90%",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "24px",
              color: " #242424",
            }}
          >
            Percentage of Female Board of Directors
          </Typography>
          <div>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: " #242424",
              }}
            >
              Tank Capacity
            </Typography>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "21px",
                lineHeight: "24px",
                color: " #242424",
              }}
            >
              2000 Mt
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "center",
            alignItems: "center",
            height: "85%",
            marginTop: "30px",
          }}
        >
          {/* <GaugeChart
            id="gauge-chart1"
            nrOfLevels={1}
            style={chartStyle}
            colors={["rgba(51, 116, 185, 1)", "rgba(214, 227, 241, 1)"]}
            needleColor={"rgba(214, 227, 241, 1)"}
            textColor={"rgba(51, 116, 185, 1)"}
            arcsLength={[
              dataHub.calculatedData[
                "Percentage of Female Board of Directors"
              ] / 100,
              1 -
                dataHub.calculatedData[
                  "Percentage of Female Board of Directors"
                ] /
                  100,
            ]}
            percent={
              dataHub.calculatedData[
                "Percentage of Female Board of Directors"
              ] / 100
            }
          /> */}
          {/* <Doughnut data={data} options={options} /> */}
          <Pie data={data}    />
        </div>
      </CardContent>
    </Card>
  );
}
