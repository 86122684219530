import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import CustomButton from "../../UI/button/button";
import Tour from "reactour";

const SurveyListingTour = ({
  updateHelpFlow,
  token,
  isTourOpen,
  setIsTourOpen,
  setOpen,
}) => {
  const [currentTourStep, setCurrentTourStep] = useState(0);
  const [current, setCurrent] = useState(0);
  const tourContent = [
    {
      selector: '[data-tut="reactour__table_row"]',
      style: {
        minWidth: "560px",
        minHeight: "200px",
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            List of Assessments
          </Typography>
          <Typography style={{ fontSize: 14, marginTop: 25, color: "#9FA1AF" }}>
            In this section you will be to create and maintain all the different
            types of Assessments like GRI,SASB, etc. by clicking on Add New.
            Once you create an assessment you can access the created Assessment
            from the list
          </Typography>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__three_dots"]',
      style: {
        minWidth: "560px",
        minHeight: "200px",
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            Assessment Options
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Against each created assessment you will have some options available
            such as name change, duplication, activity log, etc. by clicking on
            the 3-dots icon.
          </Typography>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__action_drawer"]',
      style: {
        minWidth: "560px",
        minHeight: "200px",
      },
      content: ({ goTo }) => (
        <div style={{ width: "520px" }}>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            Proceed to the next step
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Please click the proceed or continue button to move on to the next
            step of the process. Do this once you're done with this step and all
            your changes will be saved.
          </Typography>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__get_started"]',
      style: {
        minWidth: "560px",
        minHeight: "200px",
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            Get Started
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section you will have access to all the walk-through videos and
            tutorials required for you to use the platform.
          </Typography>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__dashboard"]',
      style: {
        minWidth: "560px",
        minHeight: "200px",
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            Dashboard
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Create charts based on previously uploaded data and visualize them
            here. Access detailed information for each assessment and review the
            available tasks by clicking on any assessment.
          </Typography>
        </div>
      ),
    },

    {
      selector: '[data-tut="reactour__survery_listing"]',
      style: {
        minWidth: "560px",
        minHeight: "200px",
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            List of Assessments
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            In this section you will be to create and maintain all the different
            types of Assessments like GRI,SASB, etc. by clicking on Add New.
            Once you create an assessment you can access the created Assessment
            from the list
          </Typography>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__report_listing"]',
      style: {
        minWidth: "560px",
        minHeight: "200px",
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            Task List
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Individual assessments will be listed here if there are any tasks
            attached to them for you. Click on each assessment to go inside and
            view the task to complete it.
          </Typography>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__data_hub"]',
      style: {
        minWidth: "560px",
        minHeight: "200px",
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            Data Hub
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Upload Excel sheets, integrate with the survey API, and visualize
            uploaded data in chart form for individual KPIs.
          </Typography>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__supplier"]',
      style: {
        minWidth: "560px",
        minHeight: "200px",
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            Supplier
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Manage supplier-related assessments and tasks here. Click on each
            assessment to view detailed information and complete any associated
            tasks.
          </Typography>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__general_settings"]',
      style: {
        minWidth: "560px",
        minHeight: "200px",
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            General Settings
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section gives to access to all general settings of the platform
            such as personal profile, company profile, subscription plan,etc.
          </Typography>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__profile_card"]',
      style: {
        minWidth: "560px",
        minHeight: "200px",
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            Top Navigation
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section gives you access to the notifications, personal &
            company profile.
          </Typography>
        </div>
      ),
    },
  ];
  const SURVERY_LISTING = "survey_listing";
  const handleClose = () => {
    setIsTourOpen(false);
    updateHelpFlow(token, SURVERY_LISTING);
  };
  const handlePrevStep = () => {
    setCurrent((prev) => {
      let prevStep = prev < tourContent.length + 1 ? prev - 1 : prev;
      return prevStep;
    });
  };
  const handleNextStep = () => {
    setTimeout(() => {
      setCurrent((prev) => {
        let nextStep = prev < tourContent.length - 1 ? prev + 1 : prev;
        return nextStep;
      });
    }, 150);

    if (current === 1) {
      setOpen(true);
    }
    if (current === 2) {
      setOpen(false);
    }
  };
  return (
    <Tour
      steps={tourContent}
      isOpen={isTourOpen}
      onRequestClose={handleClose}
      rounded={5}
      showNumber={true}
      showNavigation={true}
      startAt={0}
      prevStep={handlePrevStep}
      nextStep={handleNextStep}
      goToStep={current}
      lastStepNextButton={
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => updateHelpFlow(token, SURVERY_LISTING)}
        >
          Next
        </CustomButton>
      }
      getCurrentStep={(curr) => {
        setCurrentTourStep(curr);
      }}
      prevButton={
        <CustomButton
          variant="contained"
          color="primary"
          disabled={current === 0}
        >
          Previous
        </CustomButton>
      }
      nextButton={
        <CustomButton variant="contained" color="primary">
          {currentTourStep === 1 && "Open"}
          {currentTourStep === 2 && "Close"}
          {currentTourStep !== 1 && currentTourStep !== 2 && "Next"}
        </CustomButton>
      }
    />
  );
};

export default SurveyListingTour;
