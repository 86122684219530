import UNIVERSAL from "../../config/config";
import {
  SET_ARTICLES,
  SET_CONTROVERSIES_DETAILS,
  SET_ESG_INSIGNTS,
  SET_ESG_SCORE,
  DEFAULT_SUPPLIER_USER_TOKEN,
  SET_SUPPLIER_UPLOADED_FILES,
} from "../../constants/supplier/esgDiagnosisConstant";
import { set_snack_bar } from "../snackbar/snackbar_action";

import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../shared/sharedActions";

const setEsgScore = (data) => {
  return { type: SET_ESG_SCORE, payload: data };
};

export const rest_get_esg_score = (isin) => {
  return async (dispatch) => {
    dispatch(set_loader);

    try {
      const data = encrypt({
        "user-token": DEFAULT_SUPPLIER_USER_TOKEN,
        isin,
      });

      const res = await fetch(
        `${UNIVERSAL.ESG_DIAGNOSIS_REST}/elastic_search2/get_company_risk_data`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: data }),
        }
      );

      const result = await res.json();

      if (result.status) {
        dispatch(setEsgScore(result?.result));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(unset_loader());
    }
  };
};

const setEsgInsights = (data) => {
  return { type: SET_ESG_INSIGNTS, payload: data };
};

export const rest_get_esg_insights = (isin) => {
  return async (dispatch) => {
    dispatch(set_loader);

    try {
      const data = encrypt({
        "user-token": DEFAULT_SUPPLIER_USER_TOKEN,
        isin,
        year: "2022-2023",
      });

      const res = await fetch(
        `${UNIVERSAL.ESG_DIAGNOSIS_REST}/elastic_search2/get_company_insights`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: data }),
        }
      );

      const result = await res.json();

      if (result.status) {
        dispatch(setEsgInsights(result?.result));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(unset_loader());
    }
  };
};

const setControversiesDetails = (data) => {
  return { type: SET_CONTROVERSIES_DETAILS, payload: data };
};

export const rest_get_controversies_details = (isin) => {
  return async (dispatch) => {
    dispatch(set_loader);

    try {
      const data = encrypt({
        "user-token": DEFAULT_SUPPLIER_USER_TOKEN,
        isin,
        year: "2022-2023",
      });

      const res = await fetch(
        `${UNIVERSAL.ESG_DIAGNOSIS_REST}/elastic_search2/get_company_controversy_details`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: data }),
        }
      );

      const result = await res.json();

      if (result.status) {
        dispatch(setControversiesDetails(result?.result));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(unset_loader());
    }
  };
};

const setArticles = (data) => {
  return { type: SET_ARTICLES, payload: data };
};

export const rest_get_articles = (isin, limit, skip, filter) => {
  return async (dispatch) => {
    dispatch(set_loader);

    try {
      const data = encrypt({
        "user-token": DEFAULT_SUPPLIER_USER_TOKEN,
        isin,
        year: "2022-2023",
        limit,
        skip,
        filter,
      });

      const res = await fetch(
        `${UNIVERSAL.ESG_DIAGNOSIS_REST}/elastic_search2/get_articles`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: data }),
        }
      );

      const result = await res.json();

      if (result.status) {
        dispatch(setArticles(result));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(unset_loader());
    }
  };
};

export function rest_get_vendor_uploaded_files(isin) {
  const data = encrypt({
    "user-token": DEFAULT_SUPPLIER_USER_TOKEN,
    isin,
  });

  return (dispatch) => {
    dispatch(set_loader());

    return fetch(
      UNIVERSAL.ESG_DIAGNOSIS_REST + "/organization/get_company_documents",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // dispatch(getPublicReports(token, organization_id));
        // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch({
          type: SET_SUPPLIER_UPLOADED_FILES,
          payload: responseJson?.result,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}

export function rest_upload_va_file_from_ba(
  token,
  isin,
  year,
  file,
  startdate,
  enddate,
  type,
  organization_id,
  source_url,
  user_name,
  requestId,
  isFrom
) {
  console.log("run API");
  var formData = new FormData();
  let data;
  type === "XBRL"
    ? (data = encrypt({
        "user-token": DEFAULT_SUPPLIER_USER_TOKEN,
        isin,
        year,
        startdate: startdate + "-01-01",
        enddate: enddate + "-12-31",
        type,
        organization_id,
        source_url,
        user_name,
        xbrl_url: file,
        application: "VENDOR",
      }))
    : (data = encrypt({
        "user-token": DEFAULT_SUPPLIER_USER_TOKEN,
        isin,
        year,
        startdate: startdate + "-01-01",
        enddate: enddate + "-12-31",
        type,
        organization_id,
        source_url,
        user_name,
        application: "VENDOR",
      }));

  console.log({
    token,
    isin,
    year,
    file,
    startdate: startdate + "-01-01",
    enddate: enddate + "-12-31",
    type,
    organization_id,
    source_url,
  });

  // console.log(file?.name);

  if (type !== "XBRL") {
    formData.append("data", data);
    formData.append("file", file);
  }

  return async (dispatch) => {
    dispatch(set_loader());

    return fetch(
      UNIVERSAL.ESG_DIAGNOSIS_REST + "/python_apis/create_company_esg_profile",
      type === "XBRL"
        ? {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data }),
          }
        : {
            method: "POST",
            body: formData,
          }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        // dispatch(getPublicReports(token, organization_id));

        // if (isFrom === "vendorAdmin" && responseJson?.status) {
        //   rest_upload_va_file_name(token, type, requestId, file);
        // }

        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}
