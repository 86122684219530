import { makeStyles } from "@material-ui/core";

const drawerListitemUseStyles = makeStyles({
    root: {
      '&$selected': {
        backgroundColor: '#c1daed8f',
        borderRadius: 10,
        "& .MuiListItemIcon-root": {
          color: "#0873d0"
        },
        '&:hover': {
          backgroundColor: '#c1daed8f',
          borderRadius: 10,
        }
      },
      '&:hover': {
        backgroundColor: '#c1daed8f',
        borderRadius: 10,
      }
    },
    selected: {
      backgroundColor: '#c1daed8f',
      borderRadius: 10
    },
  });
  
  export default drawerListitemUseStyles;