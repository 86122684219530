import React from "react";
import RiskAssessment from "../../widgets/riskAssessment/riskAssessment";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import VendorDrawer from "../../widgets/riskAssessment/vendorDrawer";
import { setCreateDrawerState } from "../../../actions/brm/BrmActions";

function SupplierPortfolioMain({ searchKeyword }) {
  const dispatch = useDispatch();

  const { hasDrawerOpen } = useSelector((state) => state.brmReducer);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        px: 5,
      }}
    >
      <RiskAssessment searchKeyword={searchKeyword} />

      {/* Drawer */}
      <VendorDrawer
        openDrawer={hasDrawerOpen}
        closeDrawer={() => dispatch(setCreateDrawerState(false))}
      />
    </Box>
  );
}

export default SupplierPortfolioMain;
