import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import SvgIcon from "@material-ui/core/SvgIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import CustomButton from "./../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Radio } from "@material-ui/core";
import graph from "../../../images/graph.png";
import HighCharts from "highcharts/highcharts";
import HightChartsReact from "highcharts-react-official";
import materialityAssessmentRightStyles from "./materialityAssessmentRightStyles";
import {
  Social,
  Human,
  Business,
  Environmental,
  Governance,
} from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import {
  relevance,
  topicImpactData,
  stakeHolderCategoryList,
} from "../../../constants/materialityConst/materialityConst";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

export default function MaterialityAssessmentRight(props) {
  const {
    materialityAssessment,
    login,
    assessment_id,
    getSelectedTopic,
    getStakeholder,
    editStakeholder,
    deleteStakeholder,
    deleteAssessmentTopic,
    deleteAllAssessmentTopic,
    setAssignedStakeholderData,
  } = props;

  const step_no = 2;
  const length = materialityAssessment.selectedTopicsData.length;

  useEffect(() => {
    getSelectedTopic(login.token, assessment_id);
  }, [length, login.token, assessment_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getStakeholder(login.token, assessment_id, step_no);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = materialityAssessmentRightStyles();

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [scatterData, setScatterData] = useState([]);

  const [isStakeholderDialogOpen, setIsStakeholderDialogOpen] = useState(false);
  const [currentStakeholderId, setCurrentStakeholderId] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");

  const [stakeHolderName, setStakeHolderName] = useState("");
  const [stakeHolderEmail, setStakeHolderEmail] = useState("");
  const [stakeHolderCategory, setStakeHolderCategory] = useState("");
  const [stakeHolderPosition, setStakeHolderPosition] = useState("");
  const [stakeHolderDesignation, setStakeHolderDesignation] = useState("");
  const [stakeHolderOrganization, setStakeHolderOrganization] = useState("");
  const [userProfileDialogOpen, setUserProfileDialogOpen] = useState(false);
  const [removeUserDialogOpen, setRemoveUserDialogOpen] = useState(false);

  // selected topic sort Array
  const sortArray = materialityAssessment.selectedTopicsData.sort(function (
    x,
    y
  ) {
    return x.index_no - y.index_no;
  });

  useEffect(() => {
    materialityAssessment.graphData.length > 0
      ? setScatterData(
          materialityAssessment.graphData.map((data) => {
            return {
              x: data.relevance,
              y: data.weightage,
              topic: data.topic_name,
            };
          })
        )
      : setScatterData([]);
  }, [materialityAssessment.graphData]);

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  const handleCloseEditStakeholder = () => {
    setIsStakeholderDialogOpen(false);
  };

  const TopicBar = (props) => {
    const { topicId, text, index, parentTopicName, parent_topic_id } = props;
    let typeOfTopic = materialityAssessment.masterTopics.filter(
      (main) => main._id === parent_topic_id
    );
    return (
      <>
        <div className={classes.topicBarContainer}>
          <div className={classes.topicBarContents}>
            {parentTopicName === "Social Capital" && (
              <Avatar className={classes.topicAvatarSocial}>{index}</Avatar>
            )}
            {parentTopicName === "Environment" && (
              <Avatar className={classes.topicAvatarEnvironmental}>
                {index}
              </Avatar>
            )}
            {parentTopicName === "Leadership & Governance" && (
              <Avatar className={classes.topicAvatarGovernance}>{index}</Avatar>
            )}
            {parentTopicName === "Human Capital" && (
              <Avatar className={classes.topicAvatarHuman}>{index}</Avatar>
            )}
            {parentTopicName === "Business Model & Innovation" && (
              <Avatar className={classes.topicAvatarBusiness}>{index}</Avatar>
            )}
            <div className={classes.whiteDivider}></div>
            <div style={{ maxWidth: 500 }}>
              <Typography>{text}</Typography>
            </div>
          </div>
          <div className={classes.iconContainer}>
            <IconButton
              name="removeTopic"
              onClick={() =>
                deleteAssessmentTopic(
                  login.token,
                  topicId,
                  assessment_id,
                  10,
                  0,
                  materialityAssessment.topicFilter,
                  text
                )
              }
              className={classes.iconColor}
            >
              <RemoveCircleRoundedIcon />
            </IconButton>
          </div>
        </div>
      </>
    );
  };

  const editStakeHolder = () => {
    return (
      <Dialog
        maxWidth="md"
        open={isStakeholderDialogOpen}
        onClose={handleCloseEditStakeholder}
      >
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.grid1}>
            <Grid item xs={2}>
              <Typography className={classes.Typography1}>
                Edit Stakeholder
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkName"
                  label="Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  value={stakeHolderName}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkRole"
                  label="Role"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={stakeHolderDesignation}
                  onChange={(e) => setStakeHolderDesignation(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkEmail"
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  value={stakeHolderEmail}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkOrg"
                  label="Organization"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={stakeHolderOrganization}
                  onChange={(e) => setStakeHolderOrganization(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkCategory"
                  label="Category"
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                  value={stakeHolderCategory}
                  onChange={(e) => setStakeHolderCategory(e.target.value)}
                >
                  {stakeHolderCategoryList.map((cat) => (
                    <MenuItem key={cat} value={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                </CustomTextfield>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkPosition"
                  label="Type"
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                  value={stakeHolderPosition}
                  onChange={(e) => setStakeHolderPosition(e.target.value)}
                >
                  {["Internal", "External"].map((cat) => (
                    <MenuItem key={cat} value={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                </CustomTextfield>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={handleCloseEditStakeholder}
            variant="outlined"
            size="small"
            color="primary"
            name="cancelBtn"
          >
            Cancel
          </CustomButton>
          <CustomButton
            name="updateStkBtn"
            className={classes.CustomButton1}
            onClick={handleEditStakeholder}
            variant="contained"
            size="small"
            color="primary"
            disabled={
              stakeHolderEmail === "" ||
              stakeHolderCategory === "" ||
              stakeHolderPosition === "" ||
              stakeHolderDesignation === "" ||
              stakeHolderOrganization === ""
            }
          >
            Update Stakeholder
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const surveyPreview = () => {
    return (
      <Dialog maxWidth="lg" open={isPreviewOpen} onClose={handleClosePreview}>
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.grid1}>
            <Grid item xs={2}>
              <Typography className={classes.Typography1}>
                Survey Preview
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {materialityAssessment.selectedTopicsData.map((item, index) => (
            <Grid key={index} container spacing={2}>
              <Grid item xs={12}>
                <Card
                  variant="elevation"
                  elevation={3}
                  className={classes.Card1}
                >
                  <CardContent>
                    <Grid container spacing={2} className={classes.Card1}>
                      <Grid item md={9} sm={8} xs={7}>
                        <Typography variant="h6">
                          {`${index + 1}. ${item.topic_name} `}
                        </Typography>
                      </Grid>
                    </Grid>
                    <div>
                      <Grid container className={classes.grid3}>
                        <Grid item xs={12} className={classes.grid4}>
                          <Typography variant="h6">
                            What's the impact of {item.topic_name} on{" "}
                            {item.parent_topic_name} ?
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Card variant="outlined">
                            <CardContent>
                              <Grid container spacing={1}>
                                {topicImpactData.map((topic, index) => {
                                  return (
                                    <Grid container item xs={12} key={index}>
                                      <Grid item xs={2} md={1}>
                                        <Radio
                                          className={classes.Radio}
                                          name={topic}
                                          disabled
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={9}
                                        md={10}
                                        className={classes.centerLeft}
                                      >
                                        <Typography>{topic}</Typography>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.grid3}>
                        <Grid item xs={12} className={classes.grid4}>
                          <Typography variant="h6">
                            How material is {item.topic_name} to the company ?
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Card variant="outlined">
                            <CardContent>
                              <Grid container spacing={1}>
                                {relevance.map((topic, index) => {
                                  return (
                                    <Grid container item xs={12} key={index}>
                                      <Grid item xs={2} md={1}>
                                        <Radio
                                          className={classes.Radio}
                                          disabled
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={9}
                                        md={10}
                                        className={classes.centerLeft}
                                      >
                                        <Typography>{topic}</Typography>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </div>

                    <Grid container className={classes.grid5}>
                      <Grid item xs={12}>
                        <Typography className={classes.Typography2}>
                          Risks to this topic
                        </Typography>
                        <CustomTextfield
                          variant="outlined"
                          multiline
                          minRows={3}
                          fullWidth
                          disabled
                          value=""
                        />
                        <Typography className={classes.Typography2}>
                          Opportunities to this topic
                        </Typography>
                        <CustomTextfield
                          variant="outlined"
                          multiline
                          minRows={3}
                          fullWidth
                          disabled
                          value=""
                        />
                      </Grid>
                    </Grid>
                    <Grid container className={classes.grid5}>
                      <Grid item xs={7}>
                        <Box width="70%" className={classes.Box1}>
                          <AttachFileIcon
                            className={classes.AttachFileIcon}
                            color="disabled"
                          />
                          <div className={classes.div1}>Upload file</div>
                          <input type="file" className={classes.input} />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
      </Dialog>
    );
  };

  const handleUserProfileClick = (stakeholder) => {
    setCurrentStakeholderId(stakeholder.stakeholder_id);
    setCurrentUserId(stakeholder.user_data._id);
    setStakeHolderName(stakeholder.user_data.name);
    setStakeHolderEmail(stakeholder.user_data.email);
    setStakeHolderDesignation(stakeholder.designation);
    setStakeHolderCategory(stakeholder.category);
    setStakeHolderPosition(stakeholder.type);
    setStakeHolderOrganization(stakeholder.organisation);
  };

  const handleEditStakeholder = () => {
    editStakeholder(
      login.token,
      assessment_id,
      currentUserId,
      stakeHolderDesignation,
      stakeHolderOrganization,
      stakeHolderCategory,
      stakeHolderPosition,
      step_no
    );
    setIsStakeholderDialogOpen(false);
  };

  // user profile dialog

  const handleCloseUserProfileDialog = () => {
    setUserProfileDialogOpen(false);
  };

  const userProfileDialog = () => {
    return (
      <Dialog open={userProfileDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.grid5}>
            <Grid item xs={3}>
              <Typography className={classes.Typography1}>
                User Profile Details
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="flex-start">
            <Grid item xs={3}>
              <Typography className={classes.Typography1}>
                Survey Status
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.grid6}>
            <Grid item xs={12} md={12} className={classes.grid7}>
              <CustomTextfield
                label="Survey Sent"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                inputProps={{ className: classes.surveyStatOrange1 }}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid8}>
              <CustomTextfield
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={stakeHolderName}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid8}>
              <CustomTextfield
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={stakeHolderDesignation}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid8}>
              <CustomTextfield
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={stakeHolderEmail}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid9}>
              <CustomTextfield
                label="Organization"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={stakeHolderOrganization}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid8}>
              <CustomTextfield
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={stakeHolderCategory}
              ></CustomTextfield>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid9}>
              <CustomTextfield
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={stakeHolderPosition}
              ></CustomTextfield>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid10}>
            <Grid item xs={3} md={12} className={classes.grid11}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={handleCloseUserProfileDialog}
              >
                Close
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  // remove user dialog

  const handleCloseRemoveDialog = (currentStakeholderId) => {
    setRemoveUserDialogOpen(false);
    deleteStakeholder(
      login.token,
      assessment_id,
      currentStakeholderId,
      step_no
    );
  };

  const removeUserDialog = () => {
    return (
      <Dialog open={removeUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.grid5}>
            <Grid item xs={3}>
              <Typography className={classes.Typography1}>
                Remove User
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={3} md={12} className={classes.grid7}>
              <Typography className={classes.Typography3}>
                Once you remove the user he will no longer have access to the
                survey but his survey data still be shared.
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start">
            <Grid item xs={3} className={classes.grid12}>
              <Typography className={classes.Typography4}>
                Survey Status
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.grid13}>
            <Grid item xs={12} md={12} className={classes.grid7}>
              <CustomTextfield
                label="Survey Sent"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                inputProps={{ className: classes.surveyStatOrange1 }}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid8}>
              <CustomTextfield
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                value={stakeHolderName}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid9}>
              <CustomTextfield
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={stakeHolderDesignation}
                disabled
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid8}>
              <CustomTextfield
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={stakeHolderEmail}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid9}>
              <CustomTextfield
                label="Organization"
                variant="outlined"
                size="small"
                fullWidth
                value={stakeHolderOrganization}
                disabled
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid8}>
              <CustomTextfield
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                value={stakeHolderCategory}
                disabled
              ></CustomTextfield>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid9}>
              <CustomTextfield
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                value={stakeHolderPosition}
                disabled
              ></CustomTextfield>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid10}>
            <Grid item xs={3} md={6} className={classes.grid14}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => setRemoveUserDialogOpen(false)}
              >
                Close
              </CustomButton>
            </Grid>
            <Grid item xs={3} md={6} className={classes.grid15}>
              <CustomButton
                color="primary"
                variant="contained"
                onClick={() => handleCloseRemoveDialog(currentStakeholderId)}
              >
                Remove User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const options = {
    chart: {
      type: "scatter",
      zoomType: "xy",

      plotBackgroundImage: graph,
      style: {
        fontFamily: "Poppins",

        backgroundColor: "#ddd",
      },
    },
    title: {
      text: "Importance to company Vs Topic's impact on  Environment/ Social/ Governance",
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      title: {
        text: "Importance to company",
      },
      min: 0.5,
      max: 5,
    },
    yAxis: {
      title: {
        text: "Topic's impact on  Environment/ Social/ Governance",
      },
      min: 0.5,
      max: 5,
      gridLineWidth: 0,
    },
    plotOptions: {
      scatter: {
        showInLegend: false,
        opacity: 0.8,
        jitter: {
          x: 0.04,
          y: 0.05,
        },
        marker: {
          radius: 8,
          symbol: "circle",
          fillColor: "#3374b9",
        },
        tooltip: {
          pointFormat: "{point.x}, {point.y}, {point.topic}",
          headerFormat: "",
        },
      },
    },
    series: [
      {
        data: scatterData,
      },
    ],
  };

  return (
    <>
      {removeUserDialogOpen && removeUserDialog()}
      {userProfileDialogOpen && userProfileDialog()}
      {isPreviewOpen && surveyPreview()}
      {isStakeholderDialogOpen && editStakeHolder()}
      <Card
        variant="elevation"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__materialRight"
      >
        <CardContent className={classes.CardContent}>
          {/* For Topics */}
          {materialityAssessment.topicTab === 0 && (
            <div>
              <>
                <Typography className={classes.Typography5}>
                  Selected topics
                </Typography>
                {materialityAssessment.selectedTopicsData.length === 0 ? (
                  <div className={classes.emptyTopicHeader}>
                    <Typography className={classes.Typography5}>
                      Select Topics From recommend to start
                    </Typography>
                  </div>
                ) : (
                  <div className={classes.topicHeader}>
                    <Grid container wrap="wrap" spacing={2}>
                      {materialityAssessment.masterTopics.map(
                        (topic, index) => {
                          let env = 0;
                          let social = 0;
                          let gover = 0;
                          let business = 0;
                          let human = 0;
                          for (
                            let index = 0;
                            index <
                            materialityAssessment.selectedTopicsData.length;
                            index++
                          ) {
                            if (
                              materialityAssessment.selectedTopicsData[index]
                                .parent_id === topic._id
                            ) {
                              if (topic.topic_name === "Environment") {
                                env++;
                              }
                              if (topic.topic_name === "Social Capital") {
                                social++;
                              }
                              if (
                                topic.topic_name === "Leadership & Governance"
                              ) {
                                gover++;
                              }
                              if (
                                topic.topic_name ===
                                "Business Model & Innovation"
                              ) {
                                business++;
                              }
                              if (topic.topic_name === "Human Capital") {
                                human++;
                              }
                            }
                          }
                          return (
                            <React.Fragment key={index}>
                              {topic.topic_name === "Environment" && (
                                <Grid item xs={6} md={4}>
                                  <div
                                    className={classes.signleTopicTypeContainer}
                                  >
                                    <Environmental className={classes.size} />
                                    <div className={classes.divider}></div>
                                    <Typography
                                      variant="body2"
                                      className={classes.topicTypeCount}
                                    >
                                      {env}
                                    </Typography>

                                    <Typography className={classes.Typography}>
                                      Environmental
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              {topic.topic_name === "Social Capital" && (
                                <Grid item xs={6} sm={3} md={4}>
                                  <div
                                    className={classes.signleTopicTypeContainer}
                                  >
                                    <Social className={classes.size} />
                                    <div className={classes.divider}></div>
                                    <Typography
                                      variant="body2"
                                      className={classes.topicTypeCount}
                                    >
                                      {social}
                                    </Typography>
                                    <Typography className={classes.Typography}>
                                      Social
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              {topic.topic_name ===
                                "Leadership & Governance" && (
                                <Grid item xs={6} sm={3} md={4}>
                                  <div
                                    className={classes.signleTopicTypeContainer}
                                  >
                                    <Governance className={classes.size} />
                                    <div className={classes.divider}></div>
                                    <Typography
                                      variant="body2"
                                      className={classes.topicTypeCount}
                                    >
                                      {gover}
                                    </Typography>
                                    <Typography className={classes.Typography}>
                                      Governance
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              {topic.topic_name ===
                                "Business Model & Innovation" && (
                                <Grid item xs={6} sm={3} md={4}>
                                  <div
                                    className={classes.signleTopicTypeContainer}
                                  >
                                    <Business className={classes.size} />
                                    <div className={classes.divider}></div>
                                    <Typography
                                      variant="body2"
                                      className={classes.topicTypeCount}
                                    >
                                      {business}
                                    </Typography>
                                    <Typography className={classes.Typography9}>
                                      Business model
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              {topic.topic_name === "Human Capital" && (
                                <Grid item xs={6} md={4} lg={4}>
                                  <div
                                    className={classes.signleTopicTypeContainer}
                                  >
                                    <Human className={classes.size} />
                                    <div className={classes.divider}></div>
                                    <Typography
                                      variant="body2"
                                      className={classes.topicTypeCount}
                                    >
                                      {human}
                                    </Typography>
                                    <Typography className={classes.Typography9}>
                                      Human capital
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                            </React.Fragment>
                          );
                        }
                      )}
                      <Grid item xs={6} md={4} className={classes.grid16}>
                        <Typography
                          name="removeAll"
                          onClick={() => {
                            deleteAllAssessmentTopic(
                              login.token,
                              assessment_id,
                              10,
                              0,
                              materialityAssessment.topicFilter
                            );
                            setAssignedStakeholderData([]);
                          }}
                          variant="body2"
                          className={classes.removeAllButton}
                        >
                          Remove All
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </>
              <div
                className="scroll"
                style={{ height: "54vh", paddingTop: 23 }}
              >
                {materialityAssessment.selectedTopicsData.length === 0
                  ? [1, 2, 3, 4, 5].map((item) => (
                      <div key={item} style={{ padding: "10px 0" }}>
                        <div className={classes.emptyTopicBar}></div>
                      </div>
                    ))
                  : materialityAssessment.selectedTopicsData.map(
                      (selected, index) => (
                        <Box
                          my={2}
                          key={index}
                          style={{ margin: 0, padding: "10px 0" }}
                        >
                          <TopicBar
                            text={selected.topic_name}
                            index={index + 1}
                            topicId={selected.assessment_topic_id}
                            parent_topic_id={selected.parent_id}
                            parentTopicName={selected.parent_topic_name}
                          />
                        </Box>
                      )
                    )}
              </div>
            </div>
          )}

          {/* For Stakeholders */}
          {materialityAssessment.topicTab === 1 && (
            <>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={2} md={8}>
                  <Typography className={classes.Typography5}>
                    {/* Details of the stakeholders */}
                    Selected Stakeholder
                  </Typography>
                </Grid>
                <Grid item md={4} className={classes.Typography6}>
                  <CustomButton
                    disabled={
                      materialityAssessment.assignedStakeholdersData.length > 0
                        ? false
                        : true
                    }
                    onClick={() => setIsPreviewOpen(true)}
                    className={classes.CustomButton3}
                    variant="outlined"
                    color="primary"
                  >
                    Preview Survey
                  </CustomButton>
                </Grid>
              </Grid>
              <div className="scroll" style={{ height: "60vh", marginTop: 23 }}>
                <div className={classes.mainDiv}>
                  {materialityAssessment.assignedStakeholdersData.length > 0 ? (
                    <TableContainer>
                      <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography className={classes.tableHeaderText}>
                                Name
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableHeaderText}>
                                Type
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableHeaderText}>
                                Status
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableHeaderText}>
                                Category
                              </Typography>
                            </TableCell>

                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {materialityAssessment.assignedStakeholdersData
                            .length > 0 &&
                            materialityAssessment.assignedStakeholdersData.map(
                              (stakeholder, id) => (
                                <TableRow key={id}>
                                  <TableCell component="th" scope="row">
                                    <Typography
                                      style={{ paddingTop: "4px" }}
                                      onClick={() => {
                                        handleUserProfileClick(stakeholder);
                                        setUserProfileDialogOpen(true);
                                      }}
                                      className={classes.boldText}
                                    >
                                      {stakeholder.user_data.name}{" "}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      className={classes.tableHeaderText1}
                                    >
                                      {stakeholder.type}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      className={classes.surveyStatOrange}
                                    >
                                      survey sent
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography className={classes.blueText}>
                                      {stakeholder.category}{" "}
                                    </Typography>
                                  </TableCell>

                                  <TableCell className={classes.TableCell}>
                                    <IconButton
                                      name="editStkHolder"
                                      onClick={() => {
                                        handleUserProfileClick(stakeholder);
                                        setIsStakeholderDialogOpen(true);
                                      }}
                                    >
                                      <CreateOutlinedIcon fontSize="small" />
                                    </IconButton>

                                    <IconButton
                                      onClick={() => {
                                        setRemoveUserDialogOpen(true);
                                        handleUserProfileClick(stakeholder);
                                      }}
                                      style={{ marginLeft: 5 }}
                                    >
                                      <HighlightOffIcon fontSize="small" />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={2} md={5}>
                        <Typography
                          style={{
                            fontWeight: "500",
                            marginTop: 120,
                            width: 400,
                          }}
                        >
                          {/* Details of the stakeholders */}
                          Add The Stakeholder List To Send Survey
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
            </>
          )}

          {/* For Analysis*/}
          {materialityAssessment.topicTab === 2 && (
            <div className="scroll" style={{ height: "66vh" }}>
              <Typography className={classes.Typography5}>
                Materiality Map
              </Typography>

              <Grid container alignItems="center" style={{ marginTop: 30 }}>
                <Grid item xs={3} sm={3}>
                  <Typography className={classes.blueText}>
                    Last updated Now
                  </Typography>
                </Grid>
                <Grid item xs={9} sm={9}>
                  <Grid container alignItems="center">
                    <Grid
                      item
                      sm={8}
                      md={7}
                      lg={8}
                      className={classes.updateCountContainer}
                    >
                      <div style={{ display: "flex", justifyContent: "right" }}>
                        <Typography className={classes.Typography7}>
                          {materialityAssessment.materialityMapResponded}
                        </Typography>
                        <Typography className={classes.tableHeaderText}>
                          Responded
                        </Typography>
                      </div>
                      <div className={classes.divider2}></div>
                      <div style={{ display: "flex", justifyContent: "right" }}>
                        <Typography className={classes.Typography7}>
                          {materialityAssessment.materialityMapTotalPart}
                        </Typography>
                        <Typography className={classes.tableHeaderText}>
                          participants
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item sm={4} md={5} lg={4}>
                      <div className={classes.activeContainer}>
                        <Typography className={classes.activeText}>
                          Survey Active
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <div
                    style={{
                      backgroundImage: "url(" + graph + ")",
                      backgroundPosition: "center",
                      backgroundSize: "75% 70%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <HightChartsReact
                      highcharts={HighCharts}
                      options={options}
                    />
                  </div>
                </Grid>
              </Grid>
              <div>
                <Typography className={classes.Typography8}>
                  Stakeholder Responses
                </Typography>
                <TableContainer>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            Role
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            Survey Status
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            Category
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {materialityAssessment.stakeholderData.map(
                        (stakeholder) => (
                          <TableRow key={stakeholder._id}>
                            <TableCell component="th" scope="row">
                              <Typography className={classes.boldText}>
                                {stakeholder.name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableBodyText}>
                                {stakeholder.position}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                className={
                                  stakeholder.survey !== "yet to respond"
                                    ? classes.surveyStatGreenFormap
                                    : classes.surveyStatOrangeForMap
                                }
                              >
                                {stakeholder.survey !== "yet to respond"
                                  ? " Completed"
                                  : "Yet to Respond"}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.blueText}>
                                {stakeholder.category}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
}
