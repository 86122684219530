import React from 'react';
import { connect } from 'react-redux';
import SingleESGReportScreen from '../../components/screen/singleESGReport/singleESGReport';
import { getSurveyResult } from './../../actions/fielddata/fieldDataAction';
import {
  viewAllComments,
  viewComments,
  addCommentToc
} from '../../actions/comments/commentsAction';
import {
  viewAssessmentTopic,
  getMetrics,
  setCurrentTopicId
} from './../../actions/sustainabilityReport/sustainabilityReportAction';
 
import { viewActivityLog } from './../../actions/surveyListing/surveyListingAction';
import {
  setComment,
  getComments,
  setAssignApproved,
  setTopicRole
} from './../../actions/reportListing/reportListingAction';
import {
  getReportDataTask,
  setReportDataTask,
  getAssignedMetric,
  addMetricsData
} from './../../actions/fielddata/fieldDataAction';
import { getMaterialityAssessmentTask } from '../../actions/materialityAction/materialityAction';
import {
  getTopicDetails,
  setCurrentTopic
} from '../../actions/esgStrategy/esgStrategyAction';
import { getAssignedMaturityMatrix } from '../../actions/maturityMatrix/maturityMatrixActions';

class SingleESGReportCont extends React.PureComponent {
  render() {
    return <SingleESGReportScreen {...this.props} />;
  }
}

const mapStateToProps = (store) => ({
  login: store.login,
  basicInfo: store.basicInfo,
  maturityMatrix:store.maturityMatrix,
  materialityAssessment: store.materialityAssessment,
  fieldData: store.fieldData,
  reportlisting: store.reportlisting,
  esgStrategy: store.esgStrategy,
  assesmentApproval: store.assesmentApproval,
  comments: store.comments,
  sustainabilityReport: store.sustainabilityReport,

});

const mapDispatchToProps = (dispatch) => ({
  // getComments: (token, assessment_id, step_no) => {
  //   dispatch(getComments(token, assessment_id));
  // },
  getAssignedMaturityMatrix:(token, assessment_id)=>{
    dispatch(getAssignedMaturityMatrix(token, assessment_id));
  },
  setComment: (payload) => {
    dispatch(setComment(payload));
  },
  setTopicRole: (payload) => {
    dispatch(setTopicRole(payload));
  },
  getSurveyResult: (token, metric_id, assessment_id) => {
    dispatch(getSurveyResult(token, metric_id, assessment_id));
  },
  viewAssessmentTopic: (token, assessment_id, topic_id) => {
    dispatch(viewAssessmentTopic(token, assessment_id, topic_id));
  },
  // getMetrics: (token, topic_id, assessment_id) => {
  //   dispatch(getMetrics(token, topic_id, assessment_id));
  // },
  viewActivityLog: (token, assessment_id, step_no) => {
    dispatch(viewActivityLog(token, assessment_id, step_no));
  },
  setAssignApproved: (token, assessment_id, approved, step_no) => {
    dispatch(setAssignApproved(token, assessment_id, approved, step_no));
  },
  getMaterialityAssessmentTask: (token, assessment_id, topic_id) => {
    dispatch(getMaterialityAssessmentTask(token, assessment_id, topic_id));
  },
  setReportDataTask: (payload) => {
    dispatch(setReportDataTask(payload));
  },
  getReportDataTask: (token, assessment_id,is_approval) => {
    dispatch(getReportDataTask(token, assessment_id,is_approval));
  },
  setCurrentTopicId: (payload) => {
    dispatch(setCurrentTopicId(payload));
  },
  getAssignedMetric: (token, assessment_id, metric_id,is_approval) => {
    dispatch(getAssignedMetric(token, assessment_id, metric_id,is_approval));
  },
  getTopicDetails: (token, assessment_topic_id, my_task_section) => {
    dispatch(getTopicDetails(token, assessment_topic_id, my_task_section));
  },
  setCurrentTopic: (payload) => {
    dispatch(setCurrentTopic(payload));
  },
  addMetricsData: (
    token,
    assessment_id,
    metric_id,
    parent_id,
    metric_data,
    comment,
    file,
    main_topic_ids,
    parent_metric_id,
    metric_name,
    metric_desc,p_id,
    is_approver,
    setRedo,
    setExplore
  ) => {
    dispatch(
      addMetricsData(
        token,
        assessment_id,
        metric_id,
        parent_id,
        metric_data,
        comment,
        file,
        main_topic_ids,
        parent_metric_id,
        metric_name,
        metric_desc,p_id,
        is_approver,
        setRedo,
        setExplore
      )
    );
  },
  viewAllComments: (token, assessment_id, step_no) => {
    dispatch(viewAllComments(token, assessment_id, step_no));
  },
  viewComments: (token, toc_id, assessment_topic_id, step_no) => {
    dispatch(viewComments(token, toc_id, assessment_topic_id, step_no));
  },
  addCommentToc: (token, toc_id, comment, assessment_topic_id, step_no,name,assessment_id) => {
    dispatch(
      addCommentToc(token, toc_id, comment, assessment_topic_id, step_no,name,assessment_id)
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleESGReportCont);
