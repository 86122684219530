import React, { useState } from "react";
import { Box, Icon, IconButton, Typography } from "@material-ui/core";

import StepRight from "../../stepLayout/right/StepRight";
import AuditQuestionTable from "./AufitQuestionTable";
import { NoDataComponent } from "../../step1/right/SurveyStakeHolderList";
import noUser from "../../../../../../images/Profile_Analysis.png";
import NoData from "../../../../NoData/NoData";

// icons
import { IoIosList } from "react-icons/io";
import { FiUserPlus, FiCheck, FiActivity } from "react-icons/fi";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";

function Step3Right({ tabValue, sectionQuestions, questionsDetails }) {
  const [hasExplore, setHasExplore] = useState(false);

  const handleExploreQuestion = (data) => {
    setHasExplore(true);
  };

  return (
    <StepRight>
      <Box
        style={{
          display: "flex",
          // padding: "10px",
          justifyContent: "space-between",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
          }}
        >
          {hasExplore && (
            <IconButton size="small">
              <Icon
                style={{ color: "#3374B9" }}
                onClick={() => setHasExplore(false)}
                fontSize="small"
              >
                arrow_back
              </Icon>
            </IconButton>
          )}
          {tabValue === 0 ? "Company Profile" : "Blockchain data record"}
        </Typography>
      </Box>

      {/* tab 0 render data */}

      {tabValue === 0 && (
        <Box
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "flex-start",
            gap: 20,
          }}
        >
          <Box display={"flex"} alignItems={"center"} style={{ gap: 10 }}>
            <IoIosList size={18} color={"#5C90C7"} />

            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {`${questionsDetails?.total_questions || 0} Questions`}
            </Typography>
          </Box>

          <Box display={"flex"} alignItems={"center"} style={{ gap: 10 }}>
            <FiUserPlus size={18} color={"#5C90C7"} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {`${
                questionsDetails?.questions_assigned || 0
              } Questions Assigned`}
            </Typography>
          </Box>

          <Box display={"flex"} alignItems={"center"} style={{ gap: 10 }}>
            <FiCheck size={18} color={"#5C90C7"} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {`${
                questionsDetails?.questions_completed || 0
              } Questions Completed`}
            </Typography>
          </Box>

          <Box display={"flex"} alignItems={"center"} style={{ gap: 10 }}>
            <FiActivity size={18} color={"#5C90C7"} />
            {questionsDetails?.status && (
              <Box style={{ ...statusStyle(questionsDetails?.status) }}>
                <DotIcon
                  style={{
                    widht: "10px",
                    height: "10px",
                  }}
                />
                <Typography
                  style={{
                    color: "#242424",
                    fontSize: "12px",
                    fontWeight: 400,
                    textTransform: "capitalize",
                  }}
                >
                  {`${questionsDetails?.status?.toLowerCase() || ""}`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {tabValue === 0 &&
        (sectionQuestions.length > 0 ? (
          <Box style={{ overflow: "scroll", height: "80%" }}>
            <AuditQuestionTable
              sectionQuestions={sectionQuestions}
              handleExploreQuestion={handleExploreQuestion}
            />
          </Box>
        ) : (
          <NoData message={"Please Select Section"} />
        ))}

      {/* tab 1 render data */}

      {tabValue === 1 && (
        <NoDataComponent
          handleButtonClick={() => console.log("click")}
          imgSrc={noUser}
          buttonTex={"Freeze this data"}
          mainTitle={
            "Once the Data is Frozen the corresponding Hash Link will appear over here. Each revision will have its own Hash link."
          }
        />
      )}
    </StepRight>
  );
}

export default Step3Right;

// ---------- styling -----------
const statusStyle = (status) => {
  let colorToUse = "#8C7038";
  let bgColorToUse = "#FFEFD0";

  if (status === "complete") {
    colorToUse = "#5BB37F";
    bgColorToUse = "#EBF6EF";
  }

  return {
    width: "max-content",
    minWidth: "6em",
    height: "32px",
    borderRadius: 8,
    textTransform: "capitalize",
    padding: "4px 8px 4px 8px",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    backgroundColor: bgColorToUse,
    gap: "2px",
  };
};
