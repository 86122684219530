import React, { useEffect, useState } from "react";
import SelfAssessmentResponse from "../selfAssessment/selfAssessmentResponse";
import {
  Button,
  Drawer,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { appbarStyles } from "../esgCompanyProfile/esgCompantProfile";
import TabPanel from "../../UI/tabPannel/tabPannel";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import CloseIcon from "@material-ui/icons/Close";
import git from "../../../images/git-commit.png";
import calendar from "../../../images/calendar.png";
import { response1 } from "../surveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
import { useDispatch, useSelector } from "react-redux";
import {
  getDefaultSurveyData,
  setDefaultSurveyData,
} from "actions/surveyListing/surveyListingAction";
import NoData from "../NoData/NoData";

function SurveyResponses(props) {
  // from redux
  const dispatch = useDispatch();
  const { surveyRespondedUsers } = useSelector((state) => state.supplier);
  const { supplierOrganizationDetails } = useSelector(
    (state) => state.brmReducer
  );
  const { token, current_organisation } = useSelector((state) => state.login);
  const { defaultSurveyData } = useSelector((state) => state.surveyListing);

  const classes = appbarStyles();
  const [responseTabNo, setResponseTabNo] = useState(0);
  const [viewVersion, setViewVersion] = useState(false);
  const versionList = (version, date, isCurrent) => {
    return (
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px 10px",
          border: "1px solid #E0E0E0",
          backgroundColor: isCurrent && "#EBF1F8",
          borderRadius: 8,
          margin: "6px 0px",
        }}
      >
        <Grid container md={5}>
          <img src={git} alt="" style={{ height: "20px" }} />
          <Typography
            style={{ fontWeight: 500, paddingLeft: 10, fontSize: 14 }}
          >
            {" "}
            {version}
          </Typography>
        </Grid>
        <Grid container md={4}>
          <img src={calendar} alt="" style={{ height: "20px" }} />
          <Typography
            style={{ fontSize: 14, fontWeight: 400, paddingLeft: 10 }}
          >
            {isCurrent ? "Current Version" : date}
          </Typography>
        </Grid>
        <Grid md={2} style={{ padding: isCurrent ? 18 : 0 }}>
          {!isCurrent && (
            <Button
              style={{
                textTransform: "none",
                color: "#3374B9",
              }}
              onClick={() => setViewVersion(!viewVersion)}
            >
              View
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };
  const version = () => {
    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        onClose={() => setViewVersion(false)}
        // variant="persistent"
        width="lg"
        open={viewVersion}
        style={{ position: "relative" }}
      >
        <div style={{ width: "550px", position: "relative", padding: 8 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "fit-content",
            }}
          >
            <Typography
              style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
            >
              Versions
            </Typography>
            <Typography
              onClick={() => setViewVersion(!viewVersion)}
              style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
            >
              <CloseIcon style={{ cursor: "pointer", color: "#3374b9" }} />
            </Typography>
          </div>

          <div className="scroll" style={{ marginTop: "1%", height: "85vh" }}>
            <CustomTextfield
              name="Search"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              label="Search"
              onChange={(e) => {
                // setSearchCompanyText(e.target.value);
                // onSearchHandler(e);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon style={{ color: "#3374B9" }} />
                  </InputAdornment>
                ),
              }}
              // value={searchCompanyText}
            />
            {[
              { version: "Version 5", date: "Jan 07,2024", isCurrent: true },
              { version: "Version 4", date: "Jan 07,2024", isCurrent: false },
              { version: "Version 3", date: "Jan 07,2024", isCurrent: false },
              { version: "Version 1", date: "Jan 07,2024", isCurrent: false },
            ].map((list) =>
              versionList(list.version, list.date, list.isCurrent)
            )}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 5,
            right: 5,
          }}
        >
          <Button
            style={{
              textTransform: "none",
              color: "#3374B9",
            }}
            onClick={() => setViewVersion(!viewVersion)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#3374B9",
              color: "white",
              padding: "3px 14px",
            }}
            onClick={() => setViewVersion(!viewVersion)}
          >
            Select Version
          </Button>
        </div>
      </Drawer>
    );
  };

  // --------- useEffects ---------

  useEffect(() => {
    dispatch(
      getDefaultSurveyData(
        token, // token
        supplierOrganizationDetails?._id, // supplier_id
        current_organisation // organization_id
      )
    );

    return () => {
      dispatch(setDefaultSurveyData([]));
    };
  }, []);


  // ------------------------------

  return (
    <div style={{ height: "63vh" }}>
      {viewVersion && version()}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            ESG Default Survey
          </Typography>
        </div>
        <div>
          {/* <CustomButton
            color="primary"
            style={{ textTransform: "none", fontSize: 13 }}
            onClick={() => {
              setViewVersion(true);
            }}
          >
            View Versions
          </CustomButton> */}
          {/* <InfoOutlinedIcon
            style={{ color: "#3374b9", marginRight: "12px", cursor: "pointer" }}
          />
          <ShareOutlinedIcon
            style={{ color: "#3374b9", marginRight: "5px", cursor: "pointer" }}
          /> */}
        </div>
      </div>

      <div>
        {/* <MuiThemeProvider theme={appTheme}>
          <AppBar position="static" color="inherit" className={classes.AppBar}>
            <Tabs
              value={responseTabNo}
              onChange={(e, newVal) => {
                setResponseTabNo(newVal);
              }}
              // onChange={handleTabChange}
            >
              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}>
                    Self Assessment Survey
                  </span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}>
                    Controversies Survey{" "}
                  </span>
                }
              />

              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}> Dimension Chat</span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Overall</span>}
              /> 
            </Tabs>
          </AppBar>
        </MuiThemeProvider> */}
        <TabPanel value={responseTabNo} index={0}>
          {defaultSurveyData && (
            <SelfAssessmentResponse
              defaultSurveyData={defaultSurveyData}
              organization_id={supplierOrganizationDetails?.organization_id[0]}
              user={surveyRespondedUsers}
            />
          )}
          {!defaultSurveyData && <NoData message={"No Response Found"} />}
        </TabPanel>
      </div>
    </div>
  );
}

export default SurveyResponses;
