import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Button, CardActions, Icon } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { addDashboardNewCharts } from "../../../../actions/datahubActions/datahubActions";

const DashboardItem = ({
  children,
  title,
  type,
  deleteDataHubChart,
  // token,
  id,
  selectedOrganization,
  onMoreClick,
  isEdit,
  chartId,
  tabType,
  dataStream_id
}) => {
  const dispatch = useDispatch();
  const { current_organisation, token } = useSelector((state) => state.login);
  const { selectedKpiType } = useSelector((state) => state.dataHub);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (hasRemove = false) => {
    if (hasRemove === true) {
      dispatch(
        addDashboardNewCharts(
          token,
          chartId,
          false,
          current_organisation,
          tabType,
          selectedKpiType
        )
      );
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  const handleClickOpenDrawer = () => {
    onMoreClick();
  };

  return (
    <Card
      // title={title}
      bordered={false}
      style={{
        height: "100%",
        width: "100%",
        marginBottom: "20px",
        padding: "10px",
        //     width: type.chartType === "bar" ? title === "Total Waste Genrated" ? "95%" : "65%" : "30%",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        border: "2px solid #DADBE6",
      }}
      // extra={<DashboardItemDropdown itemId={itemId} />}
    >
      <CardContent style={{ height: "100%", width: "100%" }}>
        {title && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography color="#242424" gutterBottom>
                {title}
              </Typography>
              {!isEdit  && !dataStream_id && (
                <MoreVertIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClickOpenDrawer()}
                />
              )}
              {isEdit && (
                <RemoveIcon
                  style={{ cursor: "pointer", color: "#C45446" }}
                  className="delete-icon"
                  onClick={() =>
                    // deleteDataHubChart(token, selectedOrganization, id)
                    handleClickOpen()
                  }
                />
              )}
            </div>
            <AlertDialog open={open} handleClose={handleClose} />
          </>
        )}
        {children}
      </CardContent>
    </Card>
  );
};
export default DashboardItem;

function AlertDialog({ open, handleClose }) {
  return (
    <div>
      <Dialog
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Remove Widget ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this widget from dasboard ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#FEFEFE", color: "#3374B9" }}
            variant="contained"
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleClose(true)}
            style={{ backgroundColor: "#C45446", color: "#F9EEED" }}
            color="alert"
          >
            Remove Widget
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
