import React from "react";
import { Box, TableRow, TableCell, IconButton } from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import DynamicTable from "../../../../../UI/dynamicTable/DynamicTable";

const textStyle = (type) => {
  let width = "2em";
  if (type === "main") {
    width = "50em";
  }

  return {
    color: "#15314E",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    width: width,
    borderBottom: "none",
    padding: "10px",
  };
};

const statusStyle = (status) => {
  let colorToUse = "#15314E";
  let bgColorToUse = "#EBF1F8";

  if (status === "Approved") {
    colorToUse = "#154328";
    bgColorToUse = "#BFE2CD";
  } else if (status === "Yet to Assign") {
    colorToUse = "#676767";
    bgColorToUse = "#E9E9E9";
  }

  return {
    width: "max-content",
    minWidth: "9em",
    height: "32px",
    borderRadius: 8,
    textTransform: "capitalize",
    // padding: "8px 12px 8px 12px",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    backgroundColor: bgColorToUse,
    gap: "4px",
    borderBottom: "none",
  };
};
function SurveyQuestionTable({
  handleExploreQuestion,
  sectionQuestions,
  status,
}) {
  const headings = [
    { name: "Que.No", showSort: false },
    { name: "Questions", showSort: false },
    { name: "Status", showSort: false },
    { name: "Explore", showSort: false },
  ];
  // const data = [
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa1",
  //     bank_name: "AcquiBank Patners",
  //     no_of_surveys: 1,
  //     contact_person: "Shanya Natrajan",
  //     status: "Yet to Assign",
  //   },
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa2",
  //     bank_name: "FlowSource",
  //     no_of_surveys: 2,
  //     contact_person: "Another User",
  //     status: "Yet to Assign",
  //   },
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa3",
  //     bank_name: "Other Bank",
  //     no_of_surveys: 3,
  //     contact_person: "More User",
  //     status: "Yet to Assign",
  //   },
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa3",
  //     bank_name: "Other Bank",
  //     no_of_surveys: 3,
  //     contact_person: "More User",
  //     status: "Yet to Assign",
  //   },
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa3",
  //     bank_name: "Other Bank",
  //     no_of_surveys: 3,
  //     contact_person: "More User",
  //     status: "Yet to Assign",
  //   },
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa3",
  //     bank_name: "Other Bank",
  //     no_of_surveys: 3,
  //     contact_person: "More User",
  //     status: "Yet to Assign",
  //   },
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa3",
  //     bank_name: "Other Bank",
  //     no_of_surveys: 3,
  //     contact_person: "More User",
  //     status: "Yet to Assign",
  //   },
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa3",
  //     bank_name: "Other Bank",
  //     no_of_surveys: 3,
  //     contact_person: "More User",
  //     status: "Yet to Assign",
  //   },
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa1",
  //     bank_name: "AcquiBank Patners",
  //     no_of_surveys: 1,
  //     contact_person: "Shanya Natrajan",
  //     status: "Yet to Assign",
  //   },
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa2",
  //     bank_name: "FlowSource",
  //     no_of_surveys: 2,
  //     contact_person: "Another User",
  //     status: "Yet to Assign",
  //   },
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa1",
  //     bank_name: "AcquiBank Patners",
  //     no_of_surveys: 1,
  //     contact_person: "Shanya Natrajan",
  //     status: "Yet to Assign",
  //   },
  //   {
  //     _id: "65dc3ba9b0dc39a9437050aa2",
  //     bank_name: "FlowSource",
  //     no_of_surveys: 2,
  //     contact_person: "Another User",
  //     status: "Yet to Assign",
  //   },
  // ];
  return (
    <DynamicTable headings={headings}>
      {sectionQuestions.map((rowData, rowIndex) => (
        <TableRow key={rowIndex}>
          <TableCell align="center" style={textStyle()}>
            {rowIndex + 1}
          </TableCell>
          <TableCell style={textStyle("main")}>{rowData?.question}</TableCell>
          <TableCell style={{ border: "none", padding: "0px" }}>
            <Box style={statusStyle(rowData?.status)}>
              <DotIcon
                style={{ height: "10px", fontWeight: 500, color: "#242424" }}
              />
              {rowData?.status}
            </Box>
          </TableCell>
          <TableCell align="center" style={{ border: "none", padding: "0px" }}>
            <IconButton
              size="small"
              onClick={() => handleExploreQuestion(rowData)}
            >
              <ArrowForwardIosIcon
                fontSize="small"
                style={{ color: "#3374B9" }}
              />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </DynamicTable>
  );
}

export default SurveyQuestionTable;
