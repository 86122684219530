import React from "react";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tabdiv: {
    background:
      "linear-gradient(91.69deg, rgba(71, 187, 118, 0.1) -14.88%, rgba(51, 116, 185, 0.1) 123.69%)",
    padding: 10,
    paddingLeft: 25,
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export const ShowDrawContent = ({
  onClickHandler,
  updateDrawerOption,
  showDrawer,
}) => {
  const classes = useStyles();

  const onClickUpdates = (text, option, showDrawerOption) => {
    onClickHandler(text);
    updateDrawerOption(option + 1);
    showDrawer(showDrawerOption);
  };
  return (
    <div style={{ width: "90%" }}>
      {["Documents Attached", "Activity Log"].map(
        (text, index) => (
          <div
            className={classes.tabdiv}
            key={index}
            onClick={() => {
              onClickUpdates(text, index, 1);
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h6" style={{ fontSize: 14, fontWeight: 600 }}>
              {text}
            </Typography>
            <ArrowForwardIcon />
          </div>
        )
      )}
    </div>
  );
};
