import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Stepper from "../UI/stepper/stepper";
import BasicInformationCont from "../../containers/basicInformation/basicInformationCont";
import AssesmentApprovalCont from "../../containers/assesmentApproval/assesmentApprovalCont";
import MaterialityAssesmentCont from "../../containers/materialityAssesment/materialityAssesmentCont";
import ESGStrategyCont from "../../containers/esgStrategy/esgStrategyCont";
import FieldDataCont from "../../containers/fieldData/fieldDataCont";
import SustainabilityReportCont from "../../containers/SustainabilityReport/SustainabilityReportCont";
import "../../styles/stepperScroll.css";
import ProceedButtonCont from "../../containers/proceedButtoon/proceedButtonCont";
import HelperDrawer from "./helperDrawer";

export default class SustainabilityReportMain extends React.PureComponent {
	componentDidMount = () => {
		this.props.resetAllAssessmentData();
	};

	componentWillUnmount = () => {
		this.props.setActiveStep(null);
	};

	render() {
		const assessment_id = this.props.location.state.assessment_id;

		return (
			<Grid container className="mainContainer">
				<Grid container item xs={12} spacing={2}>
					<Grid item xs={12} md={11} className="stepperScroll">
						<Box display="flex" alignItems="center">
							<Stepper
								activeStep={this.props.materialityAssessment.activeStep}
								setActiveStep={this.props.setActiveStep}
								fieldDataIsUpsert={this.props.fieldData.upsert}
								materialityAssessmentIsUpsert={
									this.props.materialityAssessment.isUpsert
								}
								esgStrategyIsUpsert={this.props.esgStrategy.isUpsert}
								assessment_id={this.props.location.state.assessment_id}
								login={this.props.login}
								basicInfo={this.props.basicInfo}
								updateBasicInfo={this.props.updateBasicInfo}
								loadingBar={this.props.loadingBar}
								assesmentApproval={this.props.assesmentApproval}
							/>
							<HelperDrawer
								activeStep={this.props.materialityAssessment.activeStep}
								setActiveStep={this.props.setActiveStep}
								isShowHelperDailogue={this.props.login.isShowHelperDailogue}
								topicTab={this.props.materialityAssessment.topicTab}
								setTopicTab={this.props.setTopicTab}
								setReportTab={this.props.setReportTab}
								reportTab={this.props.fieldData.reportTab}
								setHelperDailogue={this.props.setHelperDailogue}
								isTutorialCompleted={this.props.isTutorialCompleted}
								getHelpFlow={this.props.getHelpFlow}
								updateHelpFlow={this.props.updateHelpFlow}
								login={this.props.login}
							/>
						</Box>
					</Grid>
					{this.props.materialityAssessment.activeStep === 0 && (
						<BasicInformationCont assessment_id={assessment_id} />
					)}
					{this.props.materialityAssessment.activeStep === 1 && (
						<MaterialityAssesmentCont
							main={this.props}
							assessment_id={assessment_id}
						/>
					)}
					{this.props.materialityAssessment.activeStep === 2 && (
						<ESGStrategyCont assessment_id={assessment_id} />
					)}
					{this.props.materialityAssessment.activeStep === 3 && (
						<AssesmentApprovalCont assessment_id={assessment_id} />
					)}
					{this.props.materialityAssessment.activeStep === 4 && (
						<FieldDataCont
							assessment_id={this.props.location.state.assessment_id}
						/>
					)}
					{this.props.materialityAssessment.activeStep === 5 && (
						<SustainabilityReportCont
							assessment_id={this.props.location.state.assessment_id}
						/>
					)}
					<Grid item xs={11} md={11} style={{ marginTop: 70 }}>
						<ProceedButtonCont
							activeStep={this.props.materialityAssessment.activeStep}
							setActiveStep={this.props.setActiveStep}
							assessment_id={this.props.location.state.assessment_id}
						/>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}
