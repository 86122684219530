import React from "react";
import { connect } from "react-redux";
import DataHubSurveyScreen from "../../../components/screen/dataHubSurvey/dataHubSurveyScreen";
import { setImportFileData, getDataHubChart } from "../../../actions/datahubActions/datahubActions";
import AppDashBoard from "../../UI/dataHubCalculatedCharts/AppDashBoard";

export class DashBoardScreen extends React.PureComponent {
  render() {
    return <AppDashBoard {...this.props} />;
  }
}


export default (DashBoardScreen);
