import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CustomTextfield from "../../UI/textfield/textfield";
import useDebounce from "../../../customHooks/useDebouncec";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles({
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  TableCell: { minWidth: 150 },
  Grid: { marginTop: 20 },
  CustomTextfield: { width: "100%" },
  Box: { borderBottom: "2px solid #3374B9" },
  Box2: {
    borderBottom: "2px solid gray",
    cursor: "pointer",
  },
  pointer: { cursor: "pointer" },
  input: { display: "none" },
});

export default function ReportReviewTypeThree({
  surveyData,
  surveyResultData,
  isUpsertMetricData,
  setIsUpsertMetricData,
  addMetricsData,
  fieldData,
  login,
  file,
  comment,
  assessment_id,
  metricId,
  index,
  review,
  removeFile,
}) {
  const classes = useStyles();
  const [typeThreeData, setTypeThreeData] = useState([]);

  const [metricFile, setMetricFile] = useState(null);
  const [metricComment, setMetricComment] = useState("");

  useEffect(() => {
    if (file !== "") {
      setMetricFile(file);
    }
    if (comment !== "") {
      setMetricComment(comment);
    }
    return () => {
      setMetricFile(null);
      setMetricComment("");
    };
  }, [file, comment]);

  useEffect(() => {
    if (surveyResultData !== null) {
      setTypeThreeData(surveyResultData);
    } else {
      const data = surveyData.rows.map((r, idx) => {
        return {
          row: r,
          columns:
            surveyData.columns &&
            surveyData.columns.map((c) => {
              return {
                column: c,
                data: "",
              };
            }),
        };
      });
      setTypeThreeData(data);
    }
  }, [surveyResultData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e, rowIndex, colIndex) => {
    let value = e.target.value;
    setTypeThreeData((prevState) => {
      prevState[rowIndex].columns[colIndex].data = value;
      return [...prevState];
    });
  };

  useDebounce(
    () => {
      if (isUpsertMetricData) {
        addMetricsData(
          login.token,
          assessment_id,
          metricId,
          fieldData.currentReviewMetric._id,
          typeThreeData,
          metricComment,
          metricFile,
          "",
          fieldData.currentReviewMetric._id,
          fieldData.currentReviewMetric.metric_name,
          fieldData.currentReviewMetric.metric_desc
        );
      }
      setIsUpsertMetricData(false);
    },
    3000,
    [typeThreeData, metricComment, metricFile]
  );

  return (
    <>
      <Grid container justifyContent="space-evenly" className={classes.Grid}>
        <Grid item xs={12}>
          <Paper>
            {typeThreeData.length > 0 && (
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.TableCell}
                      ></TableCell>
                      {typeThreeData.length > 0 &&
                        typeThreeData[0].columns &&
                        typeThreeData[0].columns.map((column, index) => (
                          <TableCell
                            key={index}
                            align="center"
                            className={classes.TableCell}
                          >
                            {column.column}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {typeThreeData.length > 0 &&
                      typeThreeData.map((row, rowIndex) => {
                        return (
                          <TableRow hover role="checkbox">
                            <TableCell align="center">
                              <Typography>{row.row}</Typography>
                            </TableCell>
                            {row.columns &&
                              row.columns.map((col, colIndex) => {
                                return (
                                  <TableCell key={colIndex} align="center">
                                    <CustomTextfield
                                      name={`Text_type3${colIndex}`}
                                      size="small"
                                      variant="outlined"
                                      className={classes.CustomTextfield}
                                      value={
                                        typeThreeData[rowIndex].columns[
                                          colIndex
                                        ].data
                                      }
                                      onChange={(e) => {
                                        handleChange(e, rowIndex, colIndex);
                                        if (!isUpsertMetricData) {
                                          setIsUpsertMetricData(true);
                                        }
                                      }}
                                    />
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Box>
        <Box my={2}>
          <Typography className={classes.blueText}>Comments</Typography>
          <CustomTextfield
            name={`Comment_type3${metricId}`}
            label="Additional Comment"
            fullWidth
            multiline
            minRows={3}
            size="small"
            variant="outlined"
            value={metricComment}
            onChange={(e) => {
              setMetricComment(e.target.value);
              if (!isUpsertMetricData) {
                setIsUpsertMetricData(true);
              }
            }}
            // disabled={review}
          />
        </Box>
        <Box my={2}>
          {metricFile === null ? (
            <>
              <input
                id={metricId}
                type="file"
                index={index}
                onChange={(event) => {
                  setMetricFile(event.target.files[0]);

                  if (!isUpsertMetricData) {
                    setIsUpsertMetricData(true);
                  }
                }}
                className={classes.input}
              />
              <label htmlFor={metricId}>
                <Box display="flex" component="span" alignItems="center">
                  <AttachFileIcon color="disabled" />
                  <Box mx={2} className={classes.Box2} width="100%">
                    <Typography variant="caption" color="textSecondary">
                      Upload document
                    </Typography>
                  </Box>
                </Box>
              </label>
            </>
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <AttachFileIcon color="disabled" />
                <Box mx={2} className={classes.Box} width="100%">
                  <Typography variant="caption" color="primary">
                    {metricFile.name}
                  </Typography>
                </Box>
                <ClearIcon
                  color="primary"
                  className={classes.pointer}
                  onClick={() => {
                    removeFile(
                      login.token,
                      assessment_id,
                      5,
                      metricFile.url,
                      "file",
                      null,
                      null,
                      null,
                      metricId
                    );
                    setMetricFile(null);
                  }}
                  // disabled={review}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
