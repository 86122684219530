import React from "react";
import { connect } from "react-redux";
import AssetManagement from "../../components/widgets/reportListing/materialityAssessment/materialityAssessmentReport";
import {
  setRelevance,
  setTopicRelevance,
  setFile,
  setTopicsArray,
  getMetrics,
  setWeightage,
  viewAssessmentTopic,
  updateMaterialityAssessmentTopic,
  setMetrics,
  setCurrentTopicId,
} from "../../actions/sustainabilityReport/sustainabilityReportAction";
import { removeFile } from "../../actions/generalSettings/generalSettingsAction";
import {
  setRisk,
  setOpportunity,
  upsertAssessmentTopic,
  getMaterialityAssessmentTask,
  setIsSurveyCompleted,
  setTopicCompletedUpto,
} from "../../actions/materialityAction/materialityAction";

class MaterialityAssesmentReportCont extends React.PureComponent {
  render() {
    return <AssetManagement {...this.props} />;
  }
}

const mapStateToProps = (store) => ({
  login: store.login,
  materialityAssessment: store.materialityAssessment,
  basicInfo: store.basicInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setRelevance: (payload) => {
    dispatch(setRelevance(payload));
  },
  updateMaterialityAssessmentTopic: (
    user_token,
    assessment_id,
    topic_id,
    metrics,
    relevance,
    weightage,
    additional_params,
    file,
    organisation_id
  ) => {
    dispatch(
      updateMaterialityAssessmentTopic(
        user_token,
        assessment_id,
        topic_id,
        metrics,
        relevance,
        weightage,
        additional_params,
        file,
        organisation_id
      )
    );
  },
  viewAssessmentTopic: (token, assessment_id, topic_id) => {
    dispatch(viewAssessmentTopic(token, assessment_id, topic_id));
  },
  setWeightage: (payload) => {
    dispatch(setWeightage(payload));
  },
  setTopicRelevance: (payload) => {
    dispatch(setTopicRelevance(payload));
  },
  setRisks: (payload) => {
    dispatch(setRisk(payload));
  },
  setOpportunity: (payload) => {
    dispatch(setOpportunity(payload));
  },
  setFile: (payload) => {
    dispatch(setFile(payload));
  },
  setTopicsArray: (payload) => {
    dispatch(setTopicsArray(payload));
  },
  setMetrics: (payload) => {
    dispatch(setMetrics(payload));
  },
  getMetrics: (token, payload, assessment_id, topicName) => {
    dispatch(getMetrics(token, payload, assessment_id, topicName));
  },
  upsertAssessmentTopic: (
    token,
    assessment_id,
    topic_id,
    isMainSection,
    relevance,
    weightage,
    additional_params,
    file,
    topic_name,
    materialityAssessment,
    framework_id,
    nextTopic_id
  ) => {
    dispatch(
      upsertAssessmentTopic(
        token,
        assessment_id,
        topic_id,
        isMainSection,
        relevance,
        weightage,
        additional_params,
        file,
        topic_name,
        materialityAssessment,
        framework_id,
        nextTopic_id
      )
    );
  },
  setCurrentTopicId: (payload) => {
    dispatch(setCurrentTopicId(payload));
  },
  getMaterialityAssessmentTask: (token, assessment_id, topic_id) => {
    dispatch(getMaterialityAssessmentTask(token, assessment_id, topic_id));
  },
  removeFile: (
    token,
    assessment_id,
    step_no,
    url,
    field_name,
    assessment_topic_id
  ) => {
    dispatch(
      removeFile(
        token,
        assessment_id,
        step_no,
        url,
        field_name,
        assessment_topic_id
      )
    );
  },
  setIsSurveyCompleted: (payload) => {
    dispatch(setIsSurveyCompleted(payload));
  },
  setTopicCompletedUpto: (payload) => {
    dispatch(setTopicCompletedUpto(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialityAssesmentReportCont);
