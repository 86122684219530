import React, { useEffect, useState } from "react";
import ContributorButton from "../../UI/contributorButton/contributorButton";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Drawer from "@material-ui/core/Drawer";
import NotebookList from "./notebookList";
import notebook from "../../../images/notebook.svg";
import CommentsCard from "./commentsCard";
import MaterialityAssesmentReportCont from "../../../containers/esrReportListing/esgMaterialityAssessmentReportCont";
import EsgStrategyReportCont from "../../../containers/esrReportListing/esgStrategyReportCont";
import FieldDataSurveyReportCont from "../../../containers/fieldData/fieldDataSurveyReportCont";
import SustainablityContributorReportCont from "../../../containers/SustainabilityReport/sustainablityContributorReportCont";
import AssesmentApprovalReportCont from "../../../containers/assesmentApproval/assesmentApprovalReportCont";
import MaturityMatrixTaskCont from "../../../containers/maturityMatrixTask/maturityMatrixTaskCont";
import "../../../styles/hidescroll.css";
import { makeStyles } from "@material-ui/core";
import SurveyUp from "../../UI/maturityMatrix/SurveyUp";

export default function ESGReport(props) {
  const {
    login,
    location,
    getMaterialityAssessmentTask,
    getTopicDetails,
    setTopicRole,
    fieldData,
    getAssignedMetric,
    setCurrentTopicId,
    materialityAssessment,
    setCurrentTopic,
    addMetricsData,
    esgStrategy,
    getCommentsToc,
    assesmentApproval,
    viewAllComments,
    addCommentToc,
    comments,
    viewComments,
    reportlisting,
  } = props;

  // console.log(fieldData);

  const esgReportStyles = makeStyles(() => ({
    img: { marginTop: 50, cursor: "pointer", paddingLeft: 80 },
    mainDiv: { height: "400px", position: "relative" },
    drawer: {
      backgroundColor: "transparent",
      border: "none",
      position: "absolute",
      zIndex: "1",
    },
    mainGrid: { marginTop: 10 },
  }));
  const classes = esgReportStyles();

  const [isNotebookDrawerOpen, setIsNotebookDrawerOpen] = useState(true);

  //getting the first metric on load and changing the metric according to notebook selection
  const [assignMetricIndex, setAssignMetricIndex] = useState(0);
  // sort array for materiality topics
  // console.log('fieldData.reportDataTask',fieldData.reportDataTask)
  useEffect(() => {
    if (location.state.step_no === 5 && fieldData.reportDataTask.length > 0) {
      getAssignedMetric(
        login.token,
        location.state.assignment_id,
        fieldData.reportDataTask[0].sub_mettrics[0]._id,
        location.state.role[0] === 0 ? false : true
      );
    }
  }, [fieldData.reportDataTask]); // eslint-disable-line react-hooks/exhaustive-deps
  const toggleNotebookDrawer = () => {
    setIsNotebookDrawerOpen(!isNotebookDrawerOpen);
  };

  React.useEffect(() => {
    return props.setTopicRole("");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {

    // console.log("materialityAssessment", materialityAssessment);
    if (
      location.state.step_no === 2 &&
      // location.state.topicsData.length > 0 &&
      materialityAssessment.assignedTopics.length > 0 &&
      materialityAssessment.topicCompletedUpto !== null
    ) {
      // console.log("1", 1);

      getMaterialityAssessmentTask(
        login.token,
        location.state.assignment_id,
        materialityAssessment.assignedTopics[
          materialityAssessment.topicCompletedUpto
        ].topic_id
      );
      setCurrentTopicId(
        materialityAssessment.assignedTopics[
          materialityAssessment.topicCompletedUpto
        ].topic_id
      );
    }
    if (
      location.state.step_no === 3 &&
      location.state.role.length > 0 &&
      // location.state.metricsData.length > 0 &&
      esgStrategy.assignedTopics.length > 0
    ) {
      setCurrentTopic(esgStrategy.assignedTopics[0]);
      getTopicDetails(
        login.token,
        esgStrategy.assignedTopics[0].assessment_topic_id,
        true
      );
    }
    if (
      location.state.step_no === 4 ||
      location.state.step_no === 5 ||
      location.state.step_no === 6
    ) {
      setTopicRole(location.state.role[0]);
    }
  }, [materialityAssessment.assignedTopics, esgStrategy.assignedTopics]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location.state.step_no === 2) {
      //  setIsNotebookDrawerOpen(!isNotebookDrawerOpen);
    }
  }, [JSON.stringify(materialityAssessment.isSurveyCompleted)]);

  const activeStep = props.location.state.step_no;
  const assessment_id = props.location.state.assignment_id;
  const [explore, setExplore] = useState(false);
  return (
    <div id="drawer-container">
      <Grid container className={classes.mainGrid}>
        {(activeStep === 3 ||
          activeStep === 2 ||
          // activeStep === 3 ||
          activeStep === 5 ||
          activeStep === 1) && (
          <Grid item xs={activeStep === 5 ? 3 : 2}>
            <img
              src={notebook}
              alt="notebook"
              onClick={toggleNotebookDrawer}
              className={classes.img}
            />
            <div id="report-listing-drawer" className={classes.mainDiv}>
              {/* doubt */}
              <Drawer
                anchor="left"
                open={isNotebookDrawerOpen}
                onClose={toggleNotebookDrawer}
                variant="persistent"
                PaperProps={{
                  style: {
                    backgroundColor: "transparent",
                    border: "none",
                    position: "absolute",
                    zIndex: "1",
                  },
                }}
                ModalProps={{
                  container: document.getElementById("report-listing-drawer"),
                  style: { position: "absolute" },
                }}
              >
                <NotebookList
                  {...props}
                  explore={explore}
                  setExplore={setExplore}
                  setAssignMetricIndex={setAssignMetricIndex}
                  assignMetricIndex={assignMetricIndex}
                />
              </Drawer>
            </div>
          </Grid>
        )}
        <Grid item container xs={activeStep === 4 || activeStep === 6 ? 8 : 7}>
          {(activeStep === 2 ||
            activeStep === 3 ||
            activeStep === 5 ||
            activeStep === 1) && (
            <Grid item xs={11}>
              <Card
                style={{
                  height: activeStep === 3 ? "90vh" : "80vh",
                  marginBottom:
                    props.reportlisting.currentTopicRole === 1 && 140,
                }}
              >
                {activeStep === 1 && (
                  <MaturityMatrixTaskCont
                    {...props}
                    assessment_id={assessment_id}
                  />
                )}
                {activeStep === 2 && (
                  <MaterialityAssesmentReportCont
                    framework_id={location.state.framework_id}
                    assessment_id={assessment_id}
                    role={props.reportlisting.currentTopicRole}
                  />
                )}
                {activeStep === 3 && (
                  <EsgStrategyReportCont
                    assessment_id={assessment_id}
                    role={props.reportlisting.currentTopicRole}
                  />
                )}
                {activeStep === 5 && (
                  <FieldDataSurveyReportCont
                    explore={explore}
                    setExplore={setExplore}
                    assignMetricIndex={assignMetricIndex}
                    addMetricsData={addMetricsData}
                    assessment_id={location.state.assignment_id}
                    {...props}
                  />
                )}
              </Card>
            </Grid>
          )}
          {(activeStep === 4 || activeStep === 6) && (
            <Grid item xs={11}>
              {activeStep === 4 && (
                <AssesmentApprovalReportCont assessment_id={assessment_id} />
              )}
              {activeStep === 6 && (
                <SustainablityContributorReportCont
                  assessment_id={assessment_id}
                />
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={3}>
          {(activeStep === 4 || activeStep === 6) && (
            <CommentsCard
              step_no={activeStep}
              token={login.token}
              assessmentId={assessment_id}
              getCommentsToc={getCommentsToc}
              toc={assesmentApproval.tableOfContent}
              comments={assesmentApproval.comments}
              viewCommentsToc={viewAllComments}
              viewComments={viewComments}
              addCommentToc={addCommentToc}
              commentsObj={comments}
              sustainabilityReport={props.sustainabilityReport}
              activeStep={activeStep === 4 ? 4 : 6}
            />
          )}
        </Grid>
      </Grid>
      {/* {props.reportlisting.currentTopicRole === 1 && activeStep !== 5 && (
        <ContributorButton
          setAssignApproved={props.setAssignApproved}
          token={props.login.token}
          id={props.location.state.memberId}
          stepNo={activeStep}
          assessment_id={assessment_id}
        />
      )} */}
    </div>
  );
}
