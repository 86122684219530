import React, { useEffect, useRef, useState } from "react";
import TabPanel from "../../UI/tabPannel/tabPannel";
import CustomTextfield from "../../UI/textfield/textfield";
import PublicReports, { SurveyDocument } from "./publicReports";
import DataBreakdown from "../riskAssessment/DataBreakdown";
import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  Button,
  TextField,
} from "@material-ui/core";

import CalendarIcon from "@material-ui/icons/DateRange";

import Analytics from "../../../images/Analytics.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomButton from "../../UI/button/button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import { UserCard } from "../riskAssessment/riskUtilComponents";
import { appTheme } from "../riskAssessment/brmDetaails";
import { BlueBackgroundCard } from "../surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionList";

import YearRangePicker from "./documentsElements/YearRangePicker";
import {
  getCompanyDocuments,
  requestFileSupplier,
} from "../../../actions/brm/BrmActions";
import {
  rest_get_vendor_uploaded_files,
  rest_upload_va_file_from_ba,
} from "actions/supplier/esgDiagnosisActions";
import FileUploadDrawer from "./documentsElements/FileUploadDrawer";
import { getSurveyFiles } from "actions/supplier/supplierAction";
// import { addCompanyDetails } from "../../../redux/actions/vendorActions/vendorAction";

function Documents({
  showPeerDocs,
  companyName,
  setShowpeerDocs,
  brmData,
  getPublicReports,
  login,
  ...props
}) {
  // const riskEvaluator = useSelector((state) => state.riskEvaluator);
  const { members, supplierOrganizationDetails } = useSelector(
    (state) => state.brmReducer
  );
  const { supplierDocuments } = useSelector((state) => state?.esgDiagnosis);
  const { token, selectedOrganization, current_organisation } = useSelector(
    (state) => state.login
  );

  const { surveyDocument } = useSelector((state) => state.supplier);

  const docStyles = makeStyles((theme) => ({
    grid2: { marginTop: 15 },
    grid3: { display: "flex", justifyContent: "flex-end" },
    grid4: { paddingLeft: 75 },
    grid5: { paddingRight: 75 },
    grid6: { paddingRight: 10 },
    grid7: { marginTop: 20 },

    AppBar: { boxShadow: "none", maxHeight: "25px" },
    tabLabel: {
      textTransform: "capitalize",
      fontSize: 14,
      fontFamily: "Poppins",
    },
    tab: {
      fontSize: 12,
    },
    apiCont: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      border: "1px solid lightgrey",
      borderRadius: "8px",
    },
    emptyCont: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    emptyImg: {
      width: "40%",
    },
    text: { fontWeight: 500, paddingBottom: 5 },
    subText: { fontSize: 13, color: "#878787", paddingBottom: 8 },
    uploadBtn: {
      width: "20%",
      textTransform: "Capitalize",
      margin: "0px 5px",
    },

    endButton: {
      position: "fixed",
      bottom: 10,
      right: 10,
      cursor: "pointer",
      textTransform: "capitalize",
      marginRight: "5px",
      fontSize: "14px",
      padding: 10,
      color: "#fff",
      backgroundColor: "#3374B9",
      "&:hover": {
        backgroundColor: "#3374D9",
        boxShadow: "1px 1px 3px #3374B9",
      },
    },
  }));
  const classes = docStyles();
  const inputRef = useRef(null);

  const dispatch = useDispatch();

  const [reportsTab, setReportsTab] = useState(0);
  const [file, setFile] = React.useState([]);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);
  const [isrequestUpload, setIsRequestUpload] = useState(false);
  const [requestTabNo, setRequestTabNo] = useState(0);

  const [fileData, setFileData] = useState({});
  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");

  // States for categories
  const [categories, setCategories] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const [yearPicker, setYearPicker] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState({});
  const [searchText, setSearchText] = React.useState("");
  const [userIds, setUserIds] = React.useState([]);

  const handleAddCategory = () => {
    setCategories((prevCategories) => [...prevCategories, {}]);
  };

  const handleCategoryChange = (index, key, value) => {
    setCategoryData((prevData) => ({
      ...prevData,
      [index]: {
        ...prevData[index],
        [key]: value,
      },
    }));
  };

  const toggleYearPicker = () => {
    setYearPicker(!yearPicker);
  };

  const handleRangeData = (rangeData) => {
    handleCategoryChange(selectedCategoryIndex, "year", rangeData);
  };

  const handleFileUpload = (e) => {
    const uploadFile = Object.values(e.target.files);
    // console.log(uploadFile);
    uploadFile !== undefined &&
      setFileData((prev) => ({
        ...prev,
        file: uploadFile[0],
      }));
  };
  const handleFile = (e) => {
    const uploadFile = Object.values(e.target.files);
    uploadFile !== undefined && setFile([...file, ...uploadFile]);
  };

  useEffect(() => {
    reportsTab === 0 &&
      dispatch(
        rest_get_vendor_uploaded_files(supplierOrganizationDetails?.isin)
      );

    reportsTab === 1 &&
      dispatch(
        getSurveyFiles(
          token,
          current_organisation,
          supplierOrganizationDetails?._id
        )
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsTab]);
  // console.log(fileData);
  //   useEffect(()=>{
  //     const copyfile = new Blob([file], { type: "application/pdf" });
  //     //Build a URL from the file
  //     const fileURL = URL.createObjectURL(copyfile);
  //     //Open the URL on new Window
  //     //  const pdfWindow = window.open();
  //     //  pdfWindow.location.href = fileURL;
  //     console.log('s',fileURL)
  //   },[file])

  const handleFileRemove = (value) => {
    setFile(value);
  };
  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };
  useEffect(() => {
    // console.log("new mount", fileData.year);
    fileData.year && setStartYear(fileData.year?.split("-")[0]);
    fileData.year && setEndYear(fileData.year?.split("-")[1]);
  }, [fileData.year]);
  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  useEffect(() => {
    const data = [];
    if (!fileData.year) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (fileData.year) {
      for (let i = 0; i < 9; i++) {
        data.push(fileData.year.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [isDatePickerDialogOpen]);

  const handleCheckboxChange = (id) => {
    const isSelected = userIds.includes(id);

    if (!isSelected) {
      setUserIds([...userIds, id]);
    } else {
      setUserIds(userIds.filter((selectedId) => selectedId !== id));
    }
  };

  const handleAssignUser = () => {
    const outputArray = Object.keys(categoryData).map((key) => {
      return {
        category: categoryData[key].category,
        year: categoryData[key].year,
      };
    });

    dispatch(
      requestFileSupplier(
        token,
        supplierOrganizationDetails?._id,
        selectedOrganization?._id,
        userIds,
        outputArray
      )
    );
    setCategoryData({});
    setCategories([]);
    setIsRequestUpload(false);
    setIsUploadDrawerOpen(false);
    setUserIds([]);
  };

  const yearPickerDialog = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`${startYear || ""}-${endYear || ""}`}</Typography>
              <Typography
                onClick={handleYearBack}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`<`}</Typography>
              <Typography
                onClick={handleYearForward}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`>`}</Typography>
              <Typography
                onClick={() => {
                  setStartYear("");
                  setEndYear("");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                  float: "right",
                }}
              >{`Clear`}</Typography>
            </div>
            <div>
              <Grid
                container
                xs={12}
                md={12}
                gap={1}
                spacing={2}
                style={{
                  padding: "2% 1% 4% 1%",
                  // // marginLeft: '2%',
                }}
              >
                {yearRange.map((year) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      md={4}
                      align="center"
                      style={{
                        // margin:'6px 8px',
                        // border: "1px solid blue",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        onClick={() => handleYearClick(year)}
                        style={{
                          fontWeight: 500,
                          padding: "8px 5px",
                          border: range(year) ? "1px solid #3374B9" : "none",
                          borderRadius: "5px",
                          backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                        }}
                      >
                        {year}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              // variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => {
                setFileData((prev) => ({
                  ...prev,
                  yearOfAssessment: "",
                }));
                fileData.year && setStartYear(fileData.year.split("-")[0]);
                fileData.year && setEndYear(fileData.year.split("-")[1]);
                setIsDatePickerDialogOpen(false);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px", textTransform: "none" }}
              disabled={!startYear || !endYear}
              onClick={() => {
                setFileData((prev) => ({
                  ...prev,
                  year: `${startYear}-${endYear}`,
                }));
                setIsDatePickerDialogOpen(false);
              }}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  const fileUploadDrawer = () => {
    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        onClose={() => {
          setIsRequestUpload(false);
          setIsUploadDrawerOpen(false);
        }}
        // variant="persistent"
        width="lg"
        open={isUploadDrawerOpen}
      >
        <div style={{ width: "500px", padding: 10 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              {isrequestUpload ? "Request File" : "Upload File"}
            </Typography>
            <Typography
              onClick={() => {
                setIsRequestUpload(false);
                setIsUploadDrawerOpen(false);
              }}
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              <CloseIcon style={{ cursor: "pointer" }} />
            </Typography>
          </div>
          {isrequestUpload ? (
            <div>
              <MuiThemeProvider theme={appTheme}>
                <AppBar
                  position="static"
                  color="inherit"
                  className={classes.AppBar}
                >
                  <Tabs
                    value={requestTabNo}
                    onChange={(e, newVal) => {
                      setRequestTabNo(newVal);
                      // setReportsTab(0);
                    }}
                  >
                    <Tab
                      disableRipple
                      className={classes.tab}
                      label={
                        <span className={classes.tabLabel}>Participants</span>
                      }
                    />
                    <Tab
                      disableRipple
                      disabled={userIds.length === 0 ? true : false}
                      className={classes.tab}
                      label={
                        <span className={classes.tabLabel}>Document Type</span>
                      }
                    />
                  </Tabs>
                </AppBar>
              </MuiThemeProvider>
              <TabPanel value={requestTabNo} index={0}>
                <div style={{ marginTop: 40 }}>
                  <CustomTextfield
                    name="Search"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Search user by name or email Id or employee id"
                    onChange={(e) => {
                      // setSearchCompanyText(e.target.value);
                      // onSearchHandler(e);
                      setSearchText(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon style={{ color: "#3374B9" }} />
                        </InputAdornment>
                      ),
                    }}
                    // value={searchCompanyText}
                  />

                  {members
                    .filter(
                      (m) =>
                        m.name.includes(searchText) ||
                        m.email.includes(searchText)
                    )
                    .map((member) => (
                      <UserCard
                        key={member?._id}
                        member={{
                          name: member?.name,
                          email: member?.email,
                          role: member?.role,
                          surveySentStatus: member?.survey_sent,
                        }}
                        onClick={() => handleCheckboxChange(member?._id)}
                      />
                    ))}
                </div>
              </TabPanel>
              <TabPanel value={requestTabNo} index={1}>
                <div style={{ marginTop: 40 }}>
                  <BlueBackgroundCard heading="Click on add categories and select the required document type and the year." />

                  {categories.map((_, index) => (
                    <Grid container key={index} style={{ margin: "1.5em 0" }}>
                      <Grid md={6} style={{}}>
                        <CustomTextfield
                          name={`category-${index}`}
                          value={categoryData[index]?.category}
                          label="Select Type of Document"
                          variant="outlined"
                          size="small"
                          fullWidth
                          select
                          onChange={(e) =>
                            handleCategoryChange(
                              index,
                              "category",
                              e.target.value
                            )
                          }
                        >
                          {[
                            "Annual Report",
                            "ESG Report",
                            "GRI Report",
                            "XBRL",
                            "Other",
                          ].map((cat) => (
                            <MenuItem key={cat} value={cat}>
                              {cat}
                            </MenuItem>
                          ))}
                        </CustomTextfield>
                      </Grid>
                      <Grid md={6}>
                        <CustomTextfield
                          name={`selectYear-${index}`}
                          value={categoryData[index]?.year}
                          label={!categoryData[index]?.year && "Select Year"}
                          variant="outlined"
                          style={{ marginLeft: "1em", cursor: "pointer" }}
                          InputProps={{
                            style: {
                              pointerEvents: "none",
                            },
                            endAdornment: (
                              <CalendarIcon style={{ color: "#3374B9" }} />
                            ),
                          }}
                          size="small"
                          onClick={() => {
                            setSelectedCategory(categoryData[index]);
                            setSelectedCategoryIndex(index);
                            toggleYearPicker();
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <CustomButton
                    color="primary"
                    style={{ textTransform: "none" }}
                    onClick={handleAddCategory}
                  >
                    Add Category
                  </CustomButton>
                </div>
              </TabPanel>

              {/* submit button for Request File */}
              <Button
                variant="text"
                style={{
                  color: "#3374B9",
                  position: "fixed",
                  bottom: 10,
                  right: 160,
                  cursor: "pointer",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  padding: 10,
                }}
                onClick={() => {
                  setIsRequestUpload(false);
                  setIsUploadDrawerOpen(false);
                }}
              >
                Back
              </Button>
              <Button
                variant={"contained"}
                color="primary"
                className={classes.endButton}
                disabled={
                  !categoryData ||
                  JSON.stringify(categoryData).length === 2 ||
                  !categories ||
                  categories.length === 0
                }
                onClick={() => {
                  handleAssignUser();
                }}
              >
                Assign to Users
              </Button>
            </div>
          ) : (
            <div>
              <FormControl
                variant="outlined"
                style={{ width: "100%", margin: "15px 0px" }}
              >
                <InputLabel htmlFor="age-native-helper">
                  Select File Type
                </InputLabel>{" "}
                <Select
                  fullWidth
                  size="small"
                  // style={{
                  //   border: "1px solid #C0D4E9",
                  //   backgroundColor: "#FEFEFE",
                  //   borderRadius: "8px",
                  //   // height: "40px",
                  //   // margin: "15px 0px",
                  // }}
                  // inputProps={{
                  //   name: 'Select File Type',
                  //   id: 'age-native-helper',
                  // }}
                  label="Select Type Of Report"
                  // value={currentLink.controversy}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    setFileData((prev) => ({
                      ...prev,
                      type: value,
                    }));
                  }}
                  // input={<OutlinedInput />}
                >
                  {[
                    "Annual Report",
                    "ESG Report",
                    "GRI Report",
                    "XBRL",
                    "Other",
                  ].map((item) => {
                    return (
                      <MenuItem
                        key={item}
                        value={item}
                        style={{ backgroundColor: "white" }}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box
                my={2}
                onClick={() => setIsDatePickerDialogOpen(true)}
                style={{
                  border: "1px solid #00000050",
                  padding: 12,
                  borderRadius: 5,
                  cursor: "pointer",
                  minHeight: 45,
                }}
              >
                {fileData.year ? (
                  <Typography variant="body1" name="yearOfAssesment">
                    {`${fileData.year.split("-")[0]} - ${
                      fileData.year.split("-")[1]
                    }`}
                  </Typography>
                ) : (
                  <Typography
                    style={{ color: "#878282e6", fontFamily: "Roboto" }}
                  >
                    Year Of Assessment
                  </Typography>
                )}

                {/* <Typography variant="body1" name="yearOfAssesment">
                    {basicInfo.yearOfAssessment[0].startDate !== "" &&
                    basicInfo.yearOfAssessment[0].endDate !== ""
                      ? `${moment(
                          new Date(basicInfo.yearOfAssessment[0].startDate)
                        ).format("DD MMM yy")} - ${moment(
                          new Date(basicInfo.yearOfAssessment[0].endDate)
                        ).format("DD MMM yy")}`
                      : "Year Of Assessment"}
                  </Typography> */}
              </Box>
              {fileData?.type === "XBRL" ? (
                <TextField
                  style={{ margin: "15px 0px" }}
                  fullWidth
                  variant="outlined"
                  placeholder="XBRL URL"
                  onChange={(e) => {
                    setFileData((prev) => ({
                      ...prev,
                      file: e.target.value,
                    }));
                  }}
                />
              ) : (
                <Box
                  width="100%"
                  style={{
                    margin: "26px 5px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                >
                  <AttachFileIcon
                    style={{ backgroundColor: "#ced4da", padding: 4 }}
                    color="disabled"
                  />
                  <input
                    name="actUpload"
                    id="contained-button-activityFile"
                    type="file"
                    accept=".pdf"
                    ref={inputRef}
                    onChange={(e) => handleFileUpload(e)}
                    style={{ display: "none" }}
                    multiple
                  />
                  <div
                    style={{
                      borderBottom: "2px solid #ced4da",
                      marginLeft: 20,
                      width: "100%",
                    }}
                  >
                    {fileData?.file?.name ? fileData.file.name : "Upload file"}
                  </div>
                </Box>
              )}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Source URL"
                style={{ margin: "15px 0px" }}
                onChange={(e) => {
                  setFileData((prev) => ({
                    ...prev,
                    sourceUrl: e.target.value,
                  }));
                }}
              />

              <Typography
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  cursor: "pointer",
                  textTransform: "none",
                  color: "#3374b9",
                  marginRight: "5px",
                  fontSize: "14px",
                  padding: 10,
                }}
                onClick={() => {
                  setFile((prev) => {
                    let data = [...prev];
                    data.push(fileData?.file);
                    return data;
                  });

                  console.log("run API function");

                  // dispatch(
                  //   addCompanyDetails({
                  //     documentYear: fileData.year,
                  //     file: fileData.file,
                  //     startYear: fileData.year.split("-")[0],
                  //     endYear: fileData.year.split("-")[1],
                  //     type: fileData.type,
                  //     organizationId: brmData?.organizationDetails[0]?._id,
                  //   })
                  // );

                  console.log("run API function aftre");

                  setIsUploadDrawerOpen(false);
                  setFileData("");
                }}
              >
                Upload file
              </Typography>
            </div>
          )}
        </div>
      </Drawer>
    );
  };

  return (
    <div style={{ height: "63vh" }}>
      <FileUploadDrawer
        classes={classes}
        setIsRequestUpload={setIsRequestUpload}
        setIsUploadDrawerOpen={setIsUploadDrawerOpen}
        isUploadDrawerOpen={isUploadDrawerOpen}
        isrequestUpload={isrequestUpload}
        yearPickerDialog={yearPickerDialog}
        brmData={brmData}
      />
      {/* {isUploadDrawerOpen && fileUploadDrawer()} */}
      {/* {isDatePickerDialogOpen && yearPickerDialog()} */}

      <YearRangePicker
        open={yearPicker}
        onClose={toggleYearPicker}
        currentRange={selectedCategory?.year}
        handleRangeData={handleRangeData}
        singleYear={true}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {showPeerDocs && brmData?.brmSubTabNo === 2 && (
            <Icon>
              <ArrowBackIcon
                onClick={() => setShowpeerDocs(false)}
                style={{ color: "#3374B9", cursor: "pointer" }}
              />
            </Icon>
          )}
          <Typography
            style={{
              fontWeight: 500,
              fontSize: "16px",
              marginLeft: showPeerDocs ? "5px" : 0,
            }}
          >
            Documents 564
          </Typography>
        </div>

        {reportsTab === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // width: "20%",
            }}
          >
            {/* <Typography
              style={{
                textTransform: "none",
                color: "#3374b9",
                marginRight: "15px",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              Filter
            </Typography> */}

            {/* <input
            name="actUpload"
            id="contained-button-activityFile"
            type="file"
            accept=".pdf"
            onChange={() => setIsUploadDrawerOpen(!isUploadDrawerOpen)}
            style={{ display: "none" }}
            multiple
          />
          <label htmlFor="contained-button-activityFile"> */}
            {/* <CustomButton
            color="primary"
            style={{ textTransform: "none", padding: "3px 10px" }}
          >
            Request Files
          </CustomButton> */}
            {/* {console.log("button", isrequestUpload, isUploadDrawerOpen)} */}
            <Typography
              role="button"
              onClick={() => {
                setIsUploadDrawerOpen(!isUploadDrawerOpen);
                setIsRequestUpload(!isrequestUpload);
              }}
              style={{
                cursor: "pointer",
                textTransform: "none",
                color: "#3374b9",
                marginRight: "15px",
                fontSize: "14px",
              }}
            >
              Request File
            </Typography>
            <Typography
              role="button"
              onClick={() => {
                setIsUploadDrawerOpen(!isUploadDrawerOpen);
              }}
              style={{
                cursor: "pointer",
                textTransform: "none",
                color: "#3374b9",
                marginRight: "5px",
                fontSize: "14px",
              }}
            >
              Upload File
            </Typography>
            {/* </label> */}
          </div>
        )}
      </div>

      <MuiThemeProvider theme={appTheme}>
        <AppBar
          position="static"
          color="inherit"
          className={classes.AppBar}
          style={{ marginBottom: 30 }}
        >
          <Tabs
            value={reportsTab}
            onChange={(e, newVal) => {
              setReportsTab(newVal);
              // setReportsTab(0);
            }}
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={
                <span className={classes.tabLabel}>Company Documents</span>
              }
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Survey Documents</span>}
            />
          </Tabs>
        </AppBar>
      </MuiThemeProvider>
      <TabPanel value={reportsTab} index={0}>
        {supplierDocuments && supplierDocuments.length > 0 ? (
          <PublicReports
            {...props}
            publicDocuments={supplierDocuments}
            file={file}
            handleFileRemove={handleFileRemove}
          />
        ) : (
          <div className={classes.emptyCont}>
            <img className={classes.emptyImg} src={Analytics} alt="analytics" />
            <Typography className={classes.text}>
              Currently No File has Been Upladed To Showcase
            </Typography>
            <Typography className={classes.subText}>
              Upload file type (.xls, .pdf, .doc) Max size of 5 Mb
            </Typography>
            <Grid container justifyContent="center">
              <CustomButton
                className={classes.uploadBtn}
                // variant="contained"
                color="primary"
                onClick={() => setIsUploadDrawerOpen(!isUploadDrawerOpen)}
              >
                Upload File
              </CustomButton>
              <CustomButton
                className={classes.uploadBtn}
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsUploadDrawerOpen(!isUploadDrawerOpen);
                  setIsRequestUpload(true);
                }}
              >
                Request File
              </CustomButton>
            </Grid>
          </div>
        )}
      </TabPanel>
      <TabPanel value={reportsTab} index={1}>
        {surveyDocument && surveyDocument.length > 0 ? (
          <SurveyDocument
            {...props}
            publicDocuments={surveyDocument}
            file={file}
            handleFileRemove={handleFileRemove}
          />
        ) : (
          <div className={classes.emptyCont}>
            <img className={classes.emptyImg} src={Analytics} alt="analytics" />
            <Typography className={classes.text}>
              Currently No File has Been Upladed To Showcase
            </Typography>
          </div>
        )}
      </TabPanel>
    </div>
  );
}

export default Documents;
