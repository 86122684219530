import React, { useEffect, useState } from "react";

import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";

import CustomTextfield from "../../UI/textfield/textfield";
import { AssessmentApprovalUseStyles } from "./assessmentApprovalStyle";

function FontSelector({
  handleAccordioChange,
  expanded,
  font,
  handleFontComplete,
}) {
  const classes = AssessmentApprovalUseStyles();

  // font style
  const fontArr = [
    "Roboto",
    "Open Sans",
    "Lato",
    "Oswald",
    "Slabo 27px",
    "Roboto Condensed",
    "Montserrat",
    "Source Sans Pro",
    "Raleway",
    "PT Sans",
    "Poppins",
    "Barlow",
    "Zilla Slab",
    "Playfair Display",
    "Cantata One",
    "Merriweather",
  ];
  const [fontlist, setFontlist] = React.useState(null);
  const [fontsArr, setFontsArr] = React.useState(fontArr);
  const [searchTextFont, setSearchTextFont] = useState("");

  useEffect(() => {
    setFontlist(fontArr.indexOf(font));
  }, [font]);

  useEffect(() => {
    let filtered = fontArr.filter((font) => {
      return (
        typeof font === "string" &&
        font.toUpperCase().includes(searchTextFont.toUpperCase())
      );
    });
    setFontsArr(filtered);
  }, [searchTextFont]); // eslint-disable-line react-hooks/exhaustive-deps

  // font style
  return (
    <Accordion
      variant="elevation"
      elevation={0}
      expanded={expanded === `panel1_3`}
      onChange={handleAccordioChange(`panel1_3`)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography>Font style</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "felx", flexDirection: "column" }}>
        <CustomTextfield
          name="fntSearch"
          label="Search fonts"
          fullWidth
          size="small"
          variant="outlined"
          onChange={(e) => {
            setSearchTextFont(e.target.value);
          }}
        />

        <Box height={220} overflow="scroll">
          <List dense={false}>
            {fontsArr.map((font, index) => (
              <ListItem
                disableGutters
                key={index}
                className={
                  index === fontlist
                    ? classes.tocContentGridFontSelected
                    : classes.tocContentGridFont
                }
                onClick={() => {
                  setFontlist(fontlist === index ? null : index);
                  handleFontComplete(font);
                }}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      type="body2"
                      style={{
                        color: "#15314E",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontFamily: font,
                      }}
                    >
                      Sample font style
                    </Typography>
                  }
                  secondary={
                    <Typography
                      type="body2"
                      style={{
                        color: "#242424",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      {font}
                    </Typography>
                  }
                />
                {index === fontlist && (
                  <ListItemSecondaryAction>
                    <CheckIcon style={{ color: "#3374B9" }} />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default FontSelector;
