import React from "react";
import { connect } from "react-redux";
import BasicInformation from "../../components/sustainabilityReport/basicInformation";
import { updateBasicInfo } from "../../actions/proceedButton/proceedButtonAction";
import {
  setAssessmentName,
  setYearOfAssessment,
  setSustainibilityTeamMessage,
  setEsgGoal,
  setAboutReport,
  setDocument,
  setDefault,
  setCompanyName,
  setCompanyLogo,
  setCompanyLogoURI,
  setSectors,
  setCompanyDescription,
  setNumberOfEmployee,
  setCompanyEmail,
  setCompanyWebsite,
  setCompanyAddress,
  setCompanyLandmark,
  setCompanyPincode,
  setCompanyState,
  setCompanyCountry,
  setBranchAddress,
  setBranchLandmark,
  setBranchPincode,
  setBranchState,
  setBranchCountry,
  setBranchAddressArray,
  unsetBranchAddress,
  getAssessmentDetails,
  getFrameworkDetails,
  setFrameworkId,
  setBasicInfoRightTab,
  setBasicInfoTab,
  setReportingRegion,
  updateBasicAssessmentDetails,
  updateBasicCompanyDetails,
  getAllCountries,
  getSectorsByFramework,
  setNewYearOfAssessment
} from "../../actions/basicinfoAction/basicinfoAction";
import {
  removeFile,
  viewAllUsers
} from "../../actions/generalSettings/generalSettingsAction";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";
import {
  assignMembers,
  setFrameworkDefaultFilter
} from "../../actions/fielddata/fieldDataAction";
import {
  addUser,
  uploadUserSheet,
  editStakeholder
} from "../../actions/materialityAction/materialityAction";
import {
  getExternalUsers,
  getInternalMaturityMatrix,
  getMaturityMatrix,
  resetMaturityMatrix,
  setMaturityMatrixAssignedMembers,
  updateMaturityMatrix,
  viewAssignedMembers,
  viewAssignedMatrixUsers
} from "../../actions/maturityMatrix/maturityMatrixActions";
import {
  setFilteredFrameworks,
  viewFilteredFrameworks
} from "../../actions/frameworks/frameworkAction";

export class BasicInformationCont extends React.PureComponent {
  render() {
    return <BasicInformation {...this.props} />;
  }
}

export const mapStateToProps = (store) => ({
  login: store.login,
  basicInfo: store.basicInfo,
  maturityMatrix: store.maturityMatrix,
  generalSettings: store.generalSettings
});

export const mapDispatchToProps = (dispatch) => ({
  setDefault: (payload) => {
    dispatch(setDefault(payload));
  },
  setAssessmentName: (payload) => {
    dispatch(setAssessmentName(payload));
  },
  setYearOfAssessment: (payload) => {
    dispatch(setYearOfAssessment(payload));
  },
  setNewYearOfAssessment: (payload) => {
    dispatch(setNewYearOfAssessment(payload));
  },
  setSustainibilityTeamMessage: (payload) => {
    dispatch(setSustainibilityTeamMessage(payload));
  },
  setEsgGoal: (payload) => {
    dispatch(setEsgGoal(payload));
  },
  set_snack_bar: (status, message) => {
    dispatch(set_snack_bar(status, message));
  },
  setAboutReport: (payload) => {
    dispatch(setAboutReport(payload));
  },
  setDocument: (payload) => {
    dispatch(setDocument(payload));
  },
  setCompanyName: (payload) => {
    dispatch(setCompanyName(payload));
  },
  setCompanyLogo: (payload) => {
    dispatch(setCompanyLogo(payload));
  },
  setCompanyLogoURI: (payload) => {
    dispatch(setCompanyLogoURI(payload));
  },
  setSectors: (payload) => {
    dispatch(setSectors(payload));
  },
  setCompanyDescription: (payload) => {
    dispatch(setCompanyDescription(payload));
  },
  setNumberOfEmployee: (payload) => {
    dispatch(setNumberOfEmployee(payload));
  },
  setCompanyEmail: (payload) => {
    dispatch(setCompanyEmail(payload));
  },
  setCompanyWebsite: (payload) => {
    dispatch(setCompanyWebsite(payload));
  },
  setCompanyAddress: (payload) => {
    dispatch(setCompanyAddress(payload));
  },
  setCompanyLandmark: (payload) => {
    dispatch(setCompanyLandmark(payload));
  },
  setCompanyPincode: (payload) => {
    dispatch(setCompanyPincode(payload));
  },
  setCompanyState: (payload) => {
    dispatch(setCompanyState(payload));
  },
  setCompanyCountry: (payload) => {
    dispatch(setCompanyCountry(payload));
  },
  setBranchAddress: (payload) => {
    dispatch(setBranchAddress(payload));
  },
  setBranchLandmark: (payload) => {
    dispatch(setBranchLandmark(payload));
  },
  setBranchPincode: (payload) => {
    dispatch(setBranchPincode(payload));
  },
  setBranchState: (payload) => {
    dispatch(setBranchState(payload));
  },
  setBranchCountry: (payload) => {
    dispatch(setBranchCountry(payload));
  },
  setBranchAddressArray: (payload) => {
    dispatch(setBranchAddressArray(payload));
  },
  unsetBranchAddress: (payload) => {
    dispatch(unsetBranchAddress(payload));
  },
  set_snack_bar: (status, message) => {
    dispatch(set_snack_bar(status, message));
  },
  getAssessmentDetails: (token, assessment_id) => {
    dispatch(getAssessmentDetails(token, assessment_id));
  },
  getSectorsByFramework: (token, framework_id) => {
    dispatch(getSectorsByFramework(token, framework_id));
  },
  getFrameworkDetails: (token, id) => {
    dispatch(getFrameworkDetails(token, id));
  },
  setFrameworkId: (id) => {
    dispatch(setFrameworkId(id));
  },
  setFrameworkDefaultFilter: (id) => {
    dispatch(setFrameworkDefaultFilter(id));
  },
  setReportingRegion: (payload) => {
    dispatch(setReportingRegion(payload));
  },
  updateBasicInfo: (assessment_id, basicInfo, token, activeStep, isSave) => {
    dispatch(
      updateBasicInfo(assessment_id, basicInfo, token, activeStep, isSave)
    );
  },
  removeFile: (token, assessment_id, step_no, url, field_name) => {
    dispatch(removeFile(token, assessment_id, step_no, url, field_name));
  },
  setBasicInfoRightTab: (payload) => {
    dispatch(setBasicInfoRightTab(payload));
  },
  setBasicInfoTab: (payload) => {
    dispatch(setBasicInfoTab(payload));
  },
  addUser: (
    token,
    organisation_id,
    name,
    email,
    designation,
    organisation,
    category,
    type,
    weightage,
    assessment_id
  ) => {
    dispatch(
      addUser(
        token,
        organisation_id,
        name,
        email,
        designation,
        organisation,
        category,
        type,
        weightage,
        assessment_id
      )
    );
  },
  uploadUserSheet: (token, organisation_id, file) => {
    dispatch(uploadUserSheet(token, organisation_id, file));
  },
  editStakeholder: (
    token,
    assessment_id,
    currentUserId,
    designation,
    organisation,
    category,
    type,
    step_no,
    organisation_id,
    weightage
  ) => {
    dispatch(
      editStakeholder(
        token,
        assessment_id,
        currentUserId,
        designation,
        organisation,
        category,
        type,
        step_no,
        organisation_id,
        weightage
      )
    );
  },
  updateBasicAssessmentDetails: (token, assessment_id, basicInfo, isSave) => {
    dispatch(
      updateBasicAssessmentDetails(token, assessment_id, basicInfo, isSave)
    );
  },
  updateBasicCompanyDetails: (token, assessment_id, basicInfo) => {
    dispatch(updateBasicCompanyDetails(token, assessment_id, basicInfo));
  },
  viewAllUsers: (token, organisation_id, keyword) => {
    dispatch(viewAllUsers(token, organisation_id, keyword));
  },
  getAllCountries: (token) => {
    dispatch(getAllCountries(token));
  },
  setFilteredFrameworks: (arr) => {
    dispatch(setFilteredFrameworks(arr));
  },
  viewFilteredFrameworks: (token, country_id, organisation_id) => {
    dispatch(viewFilteredFrameworks(token, country_id, organisation_id));
  },
  // setAllSurvey:(data)=>{
  //   dispatch(setAllSurvey(data));
  // },
  // setChartSurvey:(data)=>{
  //   dispatch(setChartSurvey(data));
  // },
  getMaturityMatrix: (token) => {
    dispatch(getMaturityMatrix(token));
  },
  updateMaturityMatrix: (
    token,
    asessment_id,
    q_id,
    value,
    comment,
    question_no,
    organisation_id
  ) => {
    dispatch(
      updateMaturityMatrix(
        token,
        asessment_id,
        q_id,
        value,
        comment,
        question_no,
        organisation_id
      )
    );
  },
  assignMembers: (
    token,
    email_ids,
    step_no,
    assessment_id,
    organisation_id,
    mailSubject,
    content
  ) => {
    dispatch(
      assignMembers(
        token,
        email_ids,
        step_no,
        assessment_id,
        organisation_id,
        mailSubject,
        content
      )
    );
  },
  viewAssignedMembers: (token, assessment_id, step_no) => {
    dispatch(viewAssignedMembers(token, assessment_id, step_no));
  },
  setMaturityMatrixAssignedMembers: (token, assessment_id, step_no) => {
    dispatch(setMaturityMatrixAssignedMembers(token, assessment_id, step_no));
  },
  getInternalMaturityMatrix: (token, assessment_id) => {
    dispatch(getInternalMaturityMatrix(token, assessment_id));
  },
  getExternalUsers: (token, assessment_id) => {
    dispatch(getExternalUsers(token, assessment_id));
  },
  resetMaturityMatrix: () => {
    dispatch(resetMaturityMatrix());
  },
  viewAssignedMatrixUsers: (token, assessment_id, organisation_id) => {
    dispatch(viewAssignedMatrixUsers(token, assessment_id, organisation_id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicInformationCont);
