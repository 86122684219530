import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SvgIcon from "@material-ui/core/SvgIcon";
import MenuItem from "@material-ui/core/MenuItem";
import {
  makeStyles,
  withStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";

import CustomTextfield from "../../../UI/textfield/textfield";

import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

import CustomButton from "../../../UI/button/button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import moment from "moment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Currency from "../../../../utils/currency/currency.json";
// import { tempUnit } from '../../../../utils/unit/unitData';
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  addKpiBtn: {
    width: "100%",
    marginRight: "35px",
  },
  unitSelect: {
    height: "52px",
  },
  homeHeadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  emptyContainer: {
    backgroundColor: "#F7F9FC",
    height: 50,
    width: "100%",
    borderRadius: 10,
    margin: "15px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  relavanceBarContainer: {
    width: "100%",
    padding: 3,
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#DADBE655",
    },
  },
  topicBarContents: {
    display: "flex",
    alignItems: "center",
  },
  relavanceAvatar: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#fff",
    border: "1px solid #DADBE6",
    fontSize: 14,
    marginRight: 15,
  },
  input: {
    margin: "10px 0px",
    padding: 5,
    flex: 1,
    border: "1px solid #F1F3F4",
    "& .MuiChip-root": {
      color: "#77787B",
      backgroundColor: "#FFFFFF",
      borderRadius: "5%",
      border: "2px solid #e5e5e5",
    },
  },
  assignMetricContainer: {
    display: "flex",
    alignItems: "center",
    padding: "5px 5px",
  },
  assignMetricText: {
    fontSize: 13,
    whiteSpace: "nowrap",
    fontWeight: "600",
  },
  metricDivider: {
    width: 2,
    height: 22,
    backgroundColor: "#9FA1AF",
    borderRadius: 1,
    margin: "0px 10px",
  },
  suggestionChip: {
    justifyContent: "flex-start",
    color: "#77787B",
    backgroundColor: "#F5F6F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  },
}));

const AirbnbSlider = withStyles({
  root: {
    color: "#3374B9",
    marginTop: -14,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#3374B9",
    border: "1px solid currentColor",
    marginTop: -9,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      height: 7,
      width: 1.4,
      backgroundColor: "white",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
    border: 0,
    borderRadius: 8,
  },
  rail: {
    color: "#F5F6F8",
    opacity: 1,
    height: 3,
    border: 0,
    borderRadius: 8,
  },
})(Slider);

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

function AirbnbThumbComponenttwo(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

const suggestion = createTheme({
  palette: {
    primary: { main: "rgb(159, 161, 175)" },
    secondary: { main: "#47BB76" },
  },
  overrides: {
    MuiButton: {
      containedSecondary: {
        color: "#ffffff",
      },
      containedPrimary: {
        color: "#ffffff",
      },
      textPrimary: {
        "&:hover": {
          color: " #3374B9",
          backgroundColor: " #d8e7f7",
        },
      },
    },
  },
});

function TopicIcon(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_7080_118513)">
        <rect x="8" y="8" width="40" height="40" rx="20" fill="white" />
        <g clipPath="url(#clip0_7080_118513)">
          <path
            d="M32.998 28.834C32.2651 28.8315 31.5518 29.0714 30.9694 29.5164C30.387 29.9614 29.968 30.5865 29.7779 31.2944C29.5877 32.0023 29.6369 32.7532 29.9179 33.4301C30.1989 34.1071 30.6958 34.6722 31.3314 35.0373V37.3982C31.3313 37.517 31.3665 37.6333 31.4325 37.7321C31.4985 37.831 31.5924 37.908 31.7022 37.9535C31.812 37.999 31.9329 38.0109 32.0495 37.9877C32.1661 37.9645 32.2732 37.9073 32.3572 37.8232L32.998 37.1832L33.6389 37.8232C33.7229 37.9073 33.83 37.9645 33.9466 37.9877C34.0631 38.0109 34.184 37.999 34.2938 37.9535C34.4036 37.908 34.4975 37.831 34.5635 37.7321C34.6295 37.6333 34.6647 37.517 34.6647 37.3982V35.0373C35.3002 34.6722 35.7972 34.1071 36.0782 33.4301C36.3591 32.7532 36.4084 32.0023 36.2182 31.2944C36.028 30.5865 35.6091 29.9614 35.0267 29.5164C34.4442 29.0714 33.731 28.8315 32.998 28.834Z"
            fill="#3374B9"
          />
          <path
            d="M28 32.1667C27.999 31.2887 28.2294 30.4259 28.6682 29.6653C29.1069 28.9048 29.7384 28.2734 30.4989 27.8347C31.2595 27.396 32.1223 27.1656 33.0003 27.1667C33.8783 27.1678 34.7405 27.4003 35.5 27.8408V22.1667C35.4987 21.062 35.0593 20.003 34.2781 19.2218C33.497 18.4407 32.438 18.0013 31.3333 18H24.6667C23.562 18.0013 22.503 18.4407 21.7218 19.2218C20.9407 20.003 20.5013 21.062 20.5 22.1667V32.1667C20.5013 33.2713 20.9407 34.3304 21.7218 35.1115C22.503 35.8926 23.562 36.332 24.6667 36.3333H29.6667V35.8833C29.1426 35.4163 28.7231 34.8438 28.4359 34.2032C28.1487 33.5627 28.0001 32.8687 28 32.1667ZM24.6667 21.3333H31.3333C31.5543 21.3333 31.7663 21.4211 31.9226 21.5774C32.0789 21.7337 32.1667 21.9457 32.1667 22.1667C32.1667 22.3877 32.0789 22.5996 31.9226 22.7559C31.7663 22.9122 31.5543 23 31.3333 23H24.6667C24.4457 23 24.2337 22.9122 24.0774 22.7559C23.9211 22.5996 23.8333 22.3877 23.8333 22.1667C23.8333 21.9457 23.9211 21.7337 24.0774 21.5774C24.2337 21.4211 24.4457 21.3333 24.6667 21.3333ZM24.6667 24.6667H31.3333C31.5543 24.6667 31.7663 24.7545 31.9226 24.9107C32.0789 25.067 32.1667 25.279 32.1667 25.5C32.1667 25.721 32.0789 25.933 31.9226 26.0893C31.7663 26.2455 31.5543 26.3333 31.3333 26.3333H24.6667C24.4457 26.3333 24.2337 26.2455 24.0774 26.0893C23.9211 25.933 23.8333 25.721 23.8333 25.5C23.8333 25.279 23.9211 25.067 24.0774 24.9107C24.2337 24.7545 24.4457 24.6667 24.6667 24.6667ZM27.1667 29.6667H24.6667C24.4457 29.6667 24.2337 29.5789 24.0774 29.4226C23.9211 29.2663 23.8333 29.0543 23.8333 28.8333C23.8333 28.6123 23.9211 28.4004 24.0774 28.2441C24.2337 28.0878 24.4457 28 24.6667 28H27.1667C27.3877 28 27.5996 28.0878 27.7559 28.2441C27.9122 28.4004 28 28.6123 28 28.8333C28 29.0543 27.9122 29.2663 27.7559 29.4226C27.5996 29.5789 27.3877 29.6667 27.1667 29.6667Z"
            fill="#3374B9"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7080_118513"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.454902 0 0 0 0 0.72549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7080_118513"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7080_118513"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7080_118513">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(18 18)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
export default function ESGStrategyReport(props) {
  const {
    login,
    esgPlan,
    setTopicState,
    addActivity,
    editActivity,
    deleteActivity,
    addKPI,
    editKPI,
    deleteKPI,
    setIsEditActivity,
    setIsEditKPI,
    assessment_id,
    removeFile,
    addUnit,
    getUnit,
  } = props;

  const classes = useStyles();
  const [activityTitle, setActivityTitle] = useState("");
  const [activityestimatedPeriod, setActivityEstimatedPeriod] = useState([
    { startDate: "", endDate: "" },
  ]);
  const [activityBudget, setActivityBudget] = useState("");
  const [activityComment, setActivityComment] = useState("");
  const [activityFile, setActivityFile] = useState(null);
  const [currentActivityID, setCurrentActivityID] = useState("");
  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);

  const [kpiTitle, setKpiTitle] = useState("");
  const [kpiFile, setKpiFile] = useState(null);
  const [kpiEstimatedPeriod, setKpiEstimatedPeriod] = useState([
    { startDate: "", endDate: "" },
  ]);
  const [unit, setUnit] = useState(1);
  const [isDatePickerDialogOpen2, setisDatePickerDialogOpen2] = useState(false);
  const [baselineValue, setBaselineValue] = useState(0);
  const [targetValue, setTargetValue] = useState(0);
  const [unitValue, setUnitValue] = useState("");
  const [baseline, setBaseline] = useState(false);
  const [target, setTarget] = useState(false);
  const [kpiBudget, setKpiBudget] = useState("");
  const [kpiComment, setKpiComment] = useState("");
  const [currentKpiId, setCurrentKpiId] = useState("");
  const [suggestions, setSuggestions] = useState(true);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedActivityCurrency, setSelectedActivityCurrency] = useState("");
  const [selectedKpiCurrency, setSelectedKpiCurrency] = useState("");

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteKpiOrActivity, setDeleteKpiOrActivity] = useState({
    kpi_id: "",
    activity_id: "",
  });

  const ActivityBar = (props) => {
    const { index, activity } = props;
    return (
      <>
        <div className={classes.relavanceBarContainer}>
          <div className={classes.topicBarContents}>
            <Avatar className={classes.relavanceAvatar}>{index}</Avatar>
            <Typography>{activity.title}</Typography>
          </div>
          <div>
            <IconButton
              name="editActivity"
              style={{ color: "#000" }}
              onClick={() => handleEditActivity(activity)}
            >
              <CreateOutlinedIcon color="disabled" />
            </IconButton>
            <IconButton
              name="dltActivity"
              style={{ color: "#000" }}
              onClick={() => {
                setDeleteKpiOrActivity({
                  ...deleteKpiOrActivity,
                  activity_id: activity.activity_id,
                });
                setDeleteConfirmation(true);
              }}
            >
              <CancelOutlinedIcon color="disabled" />
            </IconButton>
          </div>
        </div>
      </>
    );
  };

  const KPIBar = (props) => {
    const { index, kpi } = props;
    return (
      <>
        <div className={classes.relavanceBarContainer}>
          <div className={classes.topicBarContents}>
            <Avatar className={classes.relavanceAvatar}>{index}</Avatar>
            <Typography>{kpi.title}</Typography>
          </div>
          <div>
            <IconButton
              name="editKpi"
              style={{ color: "#000" }}
              onClick={() => {
                handleEditKPI(kpi);
              }}
            >
              <CreateOutlinedIcon color="disabled" />
            </IconButton>
            <IconButton
              name="rmvKpi"
              style={{ color: "#000" }}
              onClick={() => {
                setDeleteKpiOrActivity({
                  ...deleteKpiOrActivity,
                  kpi_id: kpi.kpi_id,
                });
                setDeleteConfirmation(true);
              }}
            >
              <CancelOutlinedIcon color="disabled" />
            </IconButton>
          </div>
        </div>
      </>
    );
  };

  const deleteConfirm = () => {
    return (
      <>
        <Dialog open={deleteConfirmation}>
          <DialogTitle>
            <Grid
              container
              justifyContent="center"
              style={{ borderBottom: "1px solid #DADBE6" }}
            >
              <Grid item xs={4}>
                <Typography
                  style={{
                    paddingBottom: 5,
                    borderBottom: "3px solid green",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}
                >
                  Delete{" "}
                  {deleteKpiOrActivity.activity_id === "" ? "KPI" : "Activity"}{" "}
                  ?
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid sm={4}>
              <div style={{ width: 500 }}>
                <Typography style={{ padding: "35px" }}>
                  Do you Really want to Delete this{" "}
                  {deleteKpiOrActivity.activity_id === "" ? "KPI" : "Activity"}{" "}
                  ?
                </Typography>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "right" }}>
            <CustomButton
              variant="outlined"
              color="primary"
              onClick={() => {
                setDeleteConfirmation(false);
                setDeleteKpiOrActivity({ kpi_id: "", activity_id: "" });
              }}
            >
              Close
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px" }}
              onClick={() => {
                deleteKpiOrActivity.activity_id !== "" &&
                  deleteActivity(
                    login.token,
                    esgPlan.currentTopic.assessment_topic_id,
                    deleteKpiOrActivity.activity_id,
                    true,
                    esgPlan.currentTopic.topic_name,
                    assessment_id
                  );
                deleteKpiOrActivity.kpi_id !== "" &&
                  deleteKPI(
                    login.token,
                    esgPlan.currentTopic.assessment_topic_id,
                    deleteKpiOrActivity.kpi_id,
                    true,
                    esgPlan.currentTopic.topic_name,
                    assessment_id
                  );
                setDeleteConfirmation(false);
                setDeleteKpiOrActivity({ kpi_id: "", activity_id: "" });
              }}
            >
              Delete
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const datePickerDialogActivity = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent>
            <Typography>
              <DateRange
                onChange={(item) =>
                  setActivityEstimatedPeriod([item.selection])
                }
                ranges={[
                  {
                    startDate:
                      activityestimatedPeriod[0].startDate !== ""
                        ? activityestimatedPeriod[0].startDate
                        : new Date(),
                    endDate:
                      activityestimatedPeriod[0].endDate !== ""
                        ? activityestimatedPeriod[0].endDate
                        : addDays(new Date(), 0),
                    key: "selection",
                  },
                ]}
                months={2}
                direction="horizontal"
              />
            </Typography>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              variant="outlined"
              color="primary"
              onClick={() => {
                setIsDatePickerDialogOpen(false);
              }}
            >
              Close
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px" }}
              onClick={() => setIsDatePickerDialogOpen(false)}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const datePickerDialogKPI = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen2}>
          <DialogContent>
            <Typography>
              <DateRange
                onChange={(item) => setKpiEstimatedPeriod([item.selection])}
                ranges={[
                  {
                    startDate:
                      kpiEstimatedPeriod[0].startDate !== ""
                        ? kpiEstimatedPeriod[0].startDate
                        : new Date(),
                    endDate:
                      kpiEstimatedPeriod[0].endDate !== ""
                        ? kpiEstimatedPeriod[0].endDate
                        : addDays(new Date(), 0),
                    key: "selection",
                  },
                ]}
                months={2}
                direction="horizontal"
              />
            </Typography>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              variant="outlined"
              color="primary"
              onClick={() => {
                setisDatePickerDialogOpen2(false);
              }}
            >
              Close
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px" }}
              onClick={() => setisDatePickerDialogOpen2(false)}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleAddActivity = () => {
    setActivityTitle("");
    setActivityEstimatedPeriod([{ startDate: "", endDate: "" }]);
    setActivityBudget("");
    setActivityComment("");
    setActivityFile(null);
    setTopicState(1);
    setIsEditActivity(false);
    setSelectedActivityCurrency("");
  };

  const handleEditActivity = (activity) => {
    setCurrentActivityID(activity.activity_id);
    setActivityTitle(activity.title);
    if (
      activity.estimated_period[0].startDate !== "" &&
      activity.estimated_period[0].endDate !== ""
    ) {
      setActivityEstimatedPeriod([
        {
          startDate: new Date(activity.estimated_period[0].startDate),
          endDate: new Date(activity.estimated_period[0].endDate),
        },
      ]);
    } else {
      setActivityEstimatedPeriod([{ startDate: "", endDate: "" }]);
    }
    setActivityBudget(activity.budget);
    setActivityComment(activity.comments);
    setActivityFile(activity.file);
    setTopicState(1);
    setIsEditActivity(true);
    setSelectedActivityCurrency(activity.currency);
  };

  const handleActivityAddEdit = () => {
    if (!esgPlan.isEditActivity) {
      addActivity(
        login.token,
        esgPlan.currentTopic.assessment_topic_id,
        activityTitle,
        activityestimatedPeriod,
        activityBudget,
        activityComment,
        activityFile,
        assessment_id,
        true,
        selectedActivityCurrency,
        esgPlan.currentTopic.topic_name
      );
    } else {
      editActivity(
        login.token,
        esgPlan.currentTopic.assessment_topic_id,
        currentActivityID,
        activityTitle,
        activityestimatedPeriod,
        activityBudget,
        activityComment,
        activityFile,
        assessment_id,
        true,
        selectedActivityCurrency,
        esgPlan.currentTopic.topic_name
      );
    }
  };

  const handleAddKPI = () => {
    setKpiTitle("");
    setUnit(1);
    setUnitValue("");
    setBaselineValue(0);
    setTargetValue(0);
    setBaseline(false);
    setTarget(false);
    setKpiEstimatedPeriod([{ startDate: "", endDate: "" }]);
    setKpiBudget("");
    setKpiComment("");
    setKpiFile(null);
    setTopicState(2);
    setIsEditKPI(false);
    setSelectedKpiCurrency("");
  };

  const handleEditKPI = (kpi) => {
    setCurrentKpiId(kpi.kpi_id);
    setKpiTitle(kpi.title);
    setUnit(kpi.unit);
    setUnitValue(kpi.unit);
    setSelectedKpiCurrency(kpi.currency);
    if (kpi.unit === 3) {
      setBaseline(kpi.baseline);
      setTarget(kpi.target);
      setBaselineValue(0);
      setTargetValue(0);
    } else {
      setBaseline(false);
      setTarget(false);
      setBaselineValue(kpi.baseline);
      setTargetValue(kpi.target);
    }

    if (
      kpi.estimated_period[0].startDate !== "" &&
      kpi.estimated_period[0].endDate !== ""
    ) {
      setKpiEstimatedPeriod([
        {
          startDate: new Date(kpi.estimated_period[0].startDate),
          endDate: new Date(kpi.estimated_period[0].endDate),
        },
      ]);
    } else {
      setKpiEstimatedPeriod([{ startDate: "", endDate: "" }]);
    }

    setKpiBudget(kpi.budget);
    setKpiComment(kpi.comments);
    setKpiFile(kpi.file);
    setTopicState(2);
    setIsEditKPI(true);
  };

  const handleKpiAddEdit = () => {
    if (!esgPlan.isEditKPI) {
      addKPI(
        login.token,
        esgPlan.currentTopic.assessment_topic_id,
        kpiTitle,
        unit === 3 ? baseline : baselineValue,
        unit === 3 ? target : targetValue,
        unit === 1 ? unitValue : unit,
        kpiEstimatedPeriod,
        kpiBudget,
        kpiComment,
        kpiFile,
        assessment_id,
        true,
        selectedKpiCurrency,
        esgPlan.currentTopic.topic_name
      );
    } else {
      editKPI(
        login.token,
        esgPlan.currentTopic.assessment_topic_id,
        currentKpiId,
        kpiTitle,
        unit === 3 ? baseline : baselineValue,
        unit === 3 ? target : targetValue,
        unit === 1 || typeof unit === "string" ? unitValue : unit,
        kpiEstimatedPeriod,
        kpiBudget,
        kpiComment,
        kpiFile,
        assessment_id,
        true,
        selectedKpiCurrency,
        esgPlan.currentTopic.topic_name
      );
    }
    if (!esgPlan.units.includes(unitValue) && unitValue.trim() !== "") {
      addUnit(login.token, unitValue.trim());
    }
  };

  useEffect(() => {
    const currencyArr = Object.keys(Currency).map(function (k) {
      return Currency[k];
    });
    setCurrencyOptions(currencyArr);
    getUnit();
  }, [Currency]); // eslint-disable-line react-hooks/exhaustive-deps

  const disableAddUpdateKpiButton = () => {
    if (unit === 1 && (kpiTitle === "" || unitValue.trim() === "")) {
      return true;
    } else if (unit === 2 && kpiTitle === "") {
      return true;
    } else if (unit === 3 && kpiTitle === "") {
      return true;
    }
  };

  return (
    <>
      {deleteConfirmation && deleteConfirm()}
      {isDatePickerDialogOpen && datePickerDialogActivity()}
      {isDatePickerDialogOpen2 && datePickerDialogKPI()}
      <Box m={2}>
        {/* For Home */}
        {esgPlan.topicState === 0 && (
          <>
            {/* Title */}
            <div className={classes.homeHeadingContainer}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TopicIcon style={{ fontSize: 46 }} />
                <Typography style={{ fontWeight: "600" }}>
                  {esgPlan.currentTopic.topic_name}
                </Typography>
              </div>
            </div>

            <div className="scroll" style={{ height: "65vh" }}>
              {/* Activity Section */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: 25 }}
              >
                <Grid item>
                  <Typography style={{ fontWeight: "600" }}>
                    Activity
                  </Typography>
                </Grid>
                <Grid item>
                  <CustomButton
                    startIcon={<AddCircleOutlineIcon />}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleAddActivity}
                  >
                    Add Activity
                  </CustomButton>
                </Grid>
              </Grid>
              <Box my={2}>
                {esgPlan.topicDetails &&
                esgPlan.topicDetails.activity &&
                esgPlan.topicDetails.activity.length > 0 ? (
                  esgPlan.topicDetails.activity.map((activity, index) => (
                    <ActivityBar index={index + 1} activity={activity} />
                  ))
                ) : (
                  <div className={classes.emptyContainer}>
                    <Typography>No Activities added</Typography>
                  </div>
                )}
              </Box>

              {/* KPI Section */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: 25 }}
              >
                <Grid item>
                  <Typography style={{ fontWeight: "600" }}>
                    Key performance indicator
                  </Typography>
                </Grid>
                <Grid item>
                  <CustomButton
                    className={classes.addKpiBtn}
                    startIcon={<AddCircleOutlineIcon />}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleAddKPI}
                  >
                    Add KPI
                  </CustomButton>
                </Grid>
              </Grid>
              <Box my={2}>
                {esgPlan.topicDetails &&
                esgPlan.topicDetails.kpi &&
                esgPlan.topicDetails.kpi.length > 0 ? (
                  esgPlan.topicDetails.kpi.map((kpi, index) => (
                    <KPIBar index={index + 1} kpi={kpi} />
                  ))
                ) : (
                  <div className={classes.emptyContainer}>
                    <Typography>No Kpi Added</Typography>
                  </div>
                )}
              </Box>
            </div>
          </>
        )}

        {/* For Activity */}
        {esgPlan.topicState === 1 && (
          <>
            <Grid container alignItems="center">
              {/* Title and Back Button */}
              <Grid item xs={9} className={classes.homeHeadingContainer}>
                <Box display="flex" alignItems="center">
                  <IconButton onClick={() => setTopicState(0)}>
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography style={{ fontWeight: "600" }}>
                    Add Activity
                  </Typography>
                </Box>
              </Grid>

              {/* Add Button */}
              <Grid item xs={3}>
                <CustomButton
                  startIcon={<AddCircleOutlineIcon />}
                  variant="contained"
                  color="secondary"
                  size="small"
                  disabled={activityTitle === ""}
                  onClick={handleActivityAddEdit}
                >
                  {esgPlan.isEditActivity ? "Update Activity" : "Add Activity"}
                </CustomButton>
              </Grid>
            </Grid>

            <div className="scroll" style={{ height: "80vh" }}>
              <Grid container style={{ marginTop: 25 }}>
                {/* Title */}
                <Grid item xs={12}>
                  <Box my={2}>
                    <CustomTextfield
                      name="actTitle"
                      label="Activity Title"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={activityTitle}
                      onChange={(e) => setActivityTitle(e.target.value)}
                    />
                  </Box>
                </Grid>
                {/* suggesstions */}
                <Grid item xs={12} md={7}>
                  <Box my={2} style={{ margin: 0 }}>
                    <MuiThemeProvider theme={suggestion}>
                      {suggestions ? (
                        <Button
                          startIcon={<AddCircleOutlineIcon />}
                          // variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => setSuggestions(false)}
                        >
                          Some Suggestion Are Available
                        </Button>
                      ) : (
                        <Button
                          startIcon={<RemoveCircleOutlineOutlinedIcon />}
                          // variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => setSuggestions(true)}
                        >
                          Hide suggesstions
                        </Button>
                      )}
                    </MuiThemeProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}></Grid>
                {!suggestions && (
                  <>
                    <Grid item xs={12} md={12}>
                      <Box my={2} style={{ margin: 0 }}>
                        {esgPlan.topicDetails.activity_suggestions.map(
                          (suggestion, index) => (
                            <Chip
                              key={index}
                              className={classes.suggestionChip}
                              label={
                                <Typography
                                  style={{
                                    whiteSpace: "normal",
                                    fontSize: 11,
                                  }}
                                >
                                  {suggestion}
                                </Typography>
                              }
                              onClick={(e) => {
                                setActivityTitle(e.target.innerText);
                                setSuggestions(true);
                              }}
                            />
                          )
                        )}
                      </Box>
                    </Grid>
                  </>
                )}

                {/* Estimate Period */}
                <Grid item xs={12} md={6} sm={7}>
                  <Box
                    name="estDate"
                    my={2}
                    onClick={() => setIsDatePickerDialogOpen(true)}
                    style={{
                      border: "1px solid #00000050",
                      padding: 12,
                      borderRadius: 5,
                      cursor: "pointer",
                      height: 40,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1" style={{ color: "#9FA1AF" }}>
                      {activityestimatedPeriod[0].startDate !== "" &&
                      activityestimatedPeriod[0].endDate !== ""
                        ? `${moment(
                            new Date(activityestimatedPeriod[0].startDate)
                          ).format("DD MMM yy")} - ${moment(
                            new Date(activityestimatedPeriod[0].endDate)
                          ).format("DD MMM yy")}`
                        : "Estimated Period"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={6}></Grid>

                {/* Budget */}
                <Grid item xs={12} md={9}>
                  <Box display="flex" md={2}>
                    <CustomTextfield
                      name="actCurrSelector"
                      size="small"
                      select
                      fullWidth
                      label="Currency"
                      variant="outlined"
                      value={selectedActivityCurrency}
                    >
                      {currencyOptions.map((curr) => {
                        return (
                          <MenuItem
                            name={curr.code.substring(0, 4)}
                            onClick={() =>
                              setSelectedActivityCurrency(curr.code)
                            }
                            value={curr.code}
                          >
                            {`${curr.code} - ${curr.symbol}`}
                          </MenuItem>
                        );
                      })}
                    </CustomTextfield>

                    <CustomTextfield
                      name="actBudget"
                      style={{ marginLeft: "15px" }}
                      label="Budget"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={activityBudget}
                      onChange={(e) => setActivityBudget(e.target.value)}
                    />
                  </Box>
                </Grid>

                {/* Additional Comment */}
                <Grid item xs={12}>
                  <Box my={2}>
                    <CustomTextfield
                      name="actAddComment"
                      label="Additional Comment"
                      fullWidth
                      multiline
                      minRows={3}
                      size="small"
                      variant="outlined"
                      value={activityComment}
                      onChange={(e) => setActivityComment(e.target.value)}
                    />
                  </Box>
                </Grid>

                {/* File */}
                <Grid item xs={12} md={9}>
                  <Box my={2}>
                    {activityFile === "" || activityFile === null ? (
                      <>
                        <input
                          name="actUpload"
                          id="contained-button-activityFile"
                          type="file"
                          onChange={(event) =>
                            setActivityFile(event.target.files[0])
                          }
                          style={{ display: "none" }}
                        />
                        <label htmlFor="contained-button-activityFile">
                          <Box
                            display="flex"
                            component="span"
                            alignItems="center"
                            style={{ cursor: "pointer" }}
                          >
                            <AttachFileIcon color="disabled" />
                            <Box
                              mx={2}
                              style={{ borderBottom: "2px solid gray" }}
                              width="100%"
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Upload activity File
                              </Typography>
                            </Box>
                          </Box>
                        </label>
                      </>
                    ) : (
                      <>
                        <Box display="flex" alignItems="center">
                          <AttachFileIcon color="disabled" />
                          <Box
                            mx={2}
                            style={{ borderBottom: "2px solid #3374B9" }}
                            width="100%"
                          >
                            <Typography variant="caption" color="primary">
                              {activityFile.name}
                            </Typography>
                          </Box>
                          <ClearIcon
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              removeFile(
                                login.token,
                                assessment_id,
                                3,
                                activityFile.url,
                                "activity_file",
                                esgPlan.currentTopic.assessment_topic_id,
                                currentActivityID
                              );
                              setActivityFile(null);
                            }}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </div>
          </>
        )}

        {/* For KPI */}
        {esgPlan.topicState === 2 && (
          <>
            <Grid container alignItems="center">
              {/* Title and Back Button */}
              <Grid item xs={9} className={classes.homeHeadingContainer}>
                <Box display="flex" alignItems="center">
                  <IconButton onClick={() => setTopicState(0)}>
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography style={{ fontWeight: "600" }}>
                    Add Key Performance Indicator
                  </Typography>
                </Box>
              </Grid>

              {/* Add KPI Button */}
              <Grid item xs={3}>
                <CustomButton
                  startIcon={<AddCircleOutlineIcon />}
                  variant="contained"
                  color="secondary"
                  size="small"
                  disabled={disableAddUpdateKpiButton()}
                  onClick={handleKpiAddEdit}
                >
                  {esgPlan.isEditKPI ? "Update KPI" : "Add KPI"}
                </CustomButton>
              </Grid>
            </Grid>

            <div className="scroll" style={{ height: "65vh" }}>
              <Grid container style={{ marginTop: 25 }}>
                {/* Title */}
                <Grid item xs={12}>
                  <Box my={2}>
                    <CustomTextfield
                      name="kpiTitle"
                      label="KPI Title"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={kpiTitle}
                      onChange={(e) => setKpiTitle(e.target.value)}
                    />
                  </Box>
                </Grid>
                {/* suggesstions */}
                <Grid item xs={12} md={7}>
                  <Box my={2} style={{ margin: 0 }}>
                    <MuiThemeProvider theme={suggestion}>
                      {suggestions ? (
                        <Button
                          startIcon={<AddCircleOutlineIcon />}
                          // variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => setSuggestions(false)}
                        >
                          Some Suggestion Are Available
                        </Button>
                      ) : (
                        <Button
                          startIcon={<RemoveCircleOutlineOutlinedIcon />}
                          // variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => setSuggestions(true)}
                        >
                          Hide suggesstions
                        </Button>
                      )}
                    </MuiThemeProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}></Grid>
                {!suggestions && (
                  <>
                    <Grid item xs={12} md={12}>
                      <Box my={2} style={{ margin: 0 }}>
                        {esgPlan.topicDetails.kpi_suggestions.map(
                          (suggestion, index) => (
                            <Chip
                              key={index}
                              className={classes.suggestionChip}
                              label={
                                <Typography
                                  style={{
                                    whiteSpace: "normal",
                                    fontSize: 11,
                                  }}
                                >
                                  {suggestion}
                                </Typography>
                              }
                              onClick={(e) => {
                                setKpiTitle(e.target.innerText);
                                setSuggestions(true);
                              }}
                            />
                          )
                        )}
                      </Box>
                    </Grid>
                  </>
                )}

                {/* Unit Selector */}
                <Grid item xs={5}>
                  <Box my={2}>
                    <CustomTextfield
                      name="sltBaseType"
                      select
                      fullWidth
                      label="Data Type Picker"
                      size="small"
                      value={unit === 2 || unit === 3 ? unit : 1}
                      onChange={(event) => {
                        setUnit(event.target.value);
                      }}
                      variant="outlined"
                    >
                      <MenuItem
                        value={1}
                        onClick={() => {
                          setBaselineValue(0);
                          setTargetValue(0);
                          setUnitValue("");
                        }}
                      >
                        Numerical Type
                      </MenuItem>
                      <MenuItem
                        value={2}
                        onClick={() => {
                          setBaselineValue(0);
                          setTargetValue(0);
                        }}
                      >
                        Percentage
                      </MenuItem>
                      <MenuItem
                        value={3}
                        onClick={() => {
                          setBaselineValue("No");
                          setTargetValue("No");
                        }}
                      >
                        Yes / No
                      </MenuItem>
                    </CustomTextfield>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}></Grid>

                {/* Baseline */}
                <Grid item xs={4}>
                  <Typography
                    color="primary"
                    variant="body2"
                    style={{ marginLeft: 20, color: "#3374B9" }}
                  >
                    Baseline
                  </Typography>
                  <Box m={2}>
                    {(typeof unit === "string" || unit === 1) && (
                      <Card variant="outlined">
                        <CardContent>
                          <CustomTextfield
                            name="ntBaseLIne"
                            variant="outlined"
                            type="number"
                            value={baselineValue === "" ? 0 : baselineValue}
                            onChange={(event) => {
                              setBaselineValue(event.target.value);
                            }}
                          />
                        </CardContent>
                      </Card>
                    )}
                    {unit === 2 && (
                      <>
                        <Card variant="outlined" style={{ marginTop: 10 }}>
                          <CardContent>
                            <Typography variant="subtitle1">Score</Typography>
                            <Typography
                              name="prBaseLIne"
                              variant="h6"
                              style={{ fontWeight: 600, fontSize: 18 }}
                            >
                              {baselineValue}
                            </Typography>
                          </CardContent>
                        </Card>
                        <AirbnbSlider
                          ThumbComponent={AirbnbThumbComponent}
                          value={
                            typeof baselineValue === typeof 0
                              ? baselineValue
                              : 0
                          }
                          onChange={(event, newValue) => {
                            setBaselineValue(newValue);
                          }}
                        />
                      </>
                    )}
                    {unit === 3 && (
                      <Card variant="outlined">
                        <CardContent style={{ padding: "30px 15px" }}>
                          <Switch
                            name="ynBaseline"
                            checked={baseline}
                            onChange={(e) => {
                              setBaseline(e.target.checked);
                            }}
                            // name="checkedA"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          {baseline ? "Yes" : "No"}
                        </CardContent>
                      </Card>
                    )}
                  </Box>
                </Grid>

                {/* Targetline */}
                <Grid item xs={4}>
                  <Typography
                    color="primary"
                    variant="body2"
                    style={{ marginLeft: 20, color: "#3374B9" }}
                  >
                    Target
                  </Typography>
                  <Box m={2}>
                    {(typeof unit === "string" || unit === 1) && (
                      <Card variant="outlined">
                        <CardContent>
                          <CustomTextfield
                            name="ntTarget"
                            variant="outlined"
                            type="number"
                            value={targetValue === "" ? 0 : targetValue}
                            onChange={(event) => {
                              setTargetValue(event.target.value);
                            }}
                          />
                        </CardContent>
                      </Card>
                    )}
                    {unit === 2 && (
                      <>
                        <Card variant="outlined" style={{ marginTop: 10 }}>
                          <CardContent>
                            <Typography variant="subtitle1">Score</Typography>
                            <Typography
                              name="prTarget"
                              variant="h6"
                              style={{ fontWeight: 600, fontSize: 18 }}
                            >
                              {targetValue}
                            </Typography>
                          </CardContent>
                        </Card>
                        <AirbnbSlider
                          ThumbComponent={AirbnbThumbComponenttwo}
                          value={
                            typeof targetValue === "number" ? targetValue : 0
                          }
                          onChange={(event, newValue) => {
                            setTargetValue(newValue);
                          }}
                        />
                      </>
                    )}
                    {unit === 3 && (
                      <Card variant="outlined">
                        <CardContent style={{ padding: "30px 15px" }}>
                          <Switch
                            name="ynTarget"
                            checked={target}
                            onChange={(e) => {
                              setTarget(e.target.checked);
                            }}
                            // name="checkedA"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          {target ? "Yes" : "No"}
                        </CardContent>
                      </Card>
                    )}
                  </Box>
                </Grid>

                {/* Unit */}
                <Grid item xs={4}>
                  <Typography
                    color="primary"
                    variant="body2"
                    style={{ marginLeft: 20, color: "#3374B9" }}
                  >
                    Unit
                  </Typography>
                  <Box m={2}>
                    <Card variant="outlined">
                      <CardContent style={{ padding: "20px 15px" }}>
                        {(typeof unit === "string" || unit === 1) && (
                          <>
                            {/* <CustomTextfield
                           name="ynTarget"
                           select
                           fullWidth
                           label="Unit"
                           variant="outlined"
                         
                           value={unitValue}
                           onChange={(e) => setUnitValue(e.target.value)}
                         >
                           {tempUnit.map((unit) => {
                             return (
                               <MenuItem
                               key={unit} value={unit}
                               >
                                 {unit}
                               </MenuItem>
                             );
                           })}
                         </CustomTextfield> */}

                            <Autocomplete
                              freeSolo={true}
                              size="small"
                              className={classes.unitSelect}
                              // value={unitValue}
                              options={
                                esgPlan.units &&
                                esgPlan.units.map((unit) => unit.unit)
                              }
                              onChange={(event, newValue) => {
                                // setUnitValue(newValue);
                              }}
                              renderInput={(params) => (
                                <>
                                  <CustomTextfield
                                    {...params}
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    label="Unit"
                                    name="asgEmailStep3"
                                  />
                                </>
                              )}
                              inputValue={unitValue}
                              onInputChange={(event, newInputValue) => {
                                event && setUnitValue(newInputValue);
                              }}
                            />
                          </>
                        )}
                        {unit === 2 && (
                          <Typography style={{ color: "#9FA1AF" }}>
                            Percentage
                          </Typography>
                        )}
                        {unit === 3 && (
                          <Typography style={{ color: "#9FA1AF" }}>
                            Yes/No
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>

                {/* Estimate Period */}
                <Grid item xs={12} md={6}>
                  <Box
                    name="kpiEstDate"
                    my={2}
                    onClick={() => setisDatePickerDialogOpen2(true)}
                    style={{
                      border: "1px solid #00000050",
                      padding: 12,
                      borderRadius: 5,
                      cursor: "pointer",
                      height: 40,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1" style={{ color: "#9FA1AF" }}>
                      {kpiEstimatedPeriod[0].startDate !== "" &&
                      kpiEstimatedPeriod[0].endDate !== ""
                        ? `${moment(
                            new Date(kpiEstimatedPeriod[0].startDate)
                          ).format("DD MMM yy")} - ${moment(
                            new Date(kpiEstimatedPeriod[0].endDate)
                          ).format("DD MMM yy")}`
                        : "Estimated Period"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}></Grid>

                {/* Budget */}
                <Grid item xs={12} md={9}>
                  <Box display="flex" my={2}>
                    <CustomTextfield
                      name="kpiCurrSelector"
                      size="small"
                      select
                      fullWidth
                      label="Currency"
                      variant="outlined"
                      value={selectedKpiCurrency}
                    >
                      {currencyOptions.map((curr) => {
                        return (
                          <MenuItem
                            name={curr.code.substring(0, 4)}
                            onClick={() => setSelectedKpiCurrency(curr.code)}
                            value={curr.code}
                          >
                            {`${curr.code} - ${curr.symbol}`}
                          </MenuItem>
                        );
                      })}
                    </CustomTextfield>
                    <CustomTextfield
                      style={{ marginLeft: "15px" }}
                      name="kpiBudget"
                      label="Budget"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={kpiBudget}
                      onChange={(e) => setKpiBudget(e.target.value)}
                    />
                  </Box>
                </Grid>

                {/* Additional Comment */}
                <Grid item xs={12}>
                  <Box my={2}>
                    <CustomTextfield
                      name="kpiAddComment"
                      label="Additional Comment"
                      fullWidth
                      multiline
                      minRows={3}
                      size="small"
                      variant="outlined"
                      value={kpiComment}
                      onChange={(e) => setKpiComment(e.target.value)}
                    />
                  </Box>
                </Grid>

                {/* File */}
                <Grid item xs={12} md={9}>
                  <Box my={2}>
                    {kpiFile === "" || kpiFile === null ? (
                      <>
                        <input
                          name="kpiUpload"
                          id="contained-button-kpiFile"
                          type="file"
                          onChange={(event) =>
                            setKpiFile(event.target.files[0])
                          }
                          style={{ display: "none" }}
                        />
                        <label htmlFor="contained-button-kpiFile">
                          <Box
                            display="flex"
                            component="span"
                            alignItems="center"
                            style={{ cursor: "pointer" }}
                          >
                            <AttachFileIcon color="disabled" />
                            <Box
                              mx={2}
                              style={{ borderBottom: "2px solid gray" }}
                              width="100%"
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Upload KPI File
                              </Typography>
                            </Box>
                          </Box>
                        </label>
                      </>
                    ) : (
                      <>
                        <Box display="flex" alignItems="center">
                          <AttachFileIcon color="disabled" />
                          <Box
                            mx={2}
                            style={{ borderBottom: "2px solid #3374B9" }}
                            width="100%"
                          >
                            <Typography variant="caption" color="primary">
                              {kpiFile.name}
                            </Typography>
                          </Box>
                          <ClearIcon
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              removeFile(
                                login.token,
                                assessment_id,
                                3,
                                kpiFile.url,
                                "kpi_file",
                                esgPlan.currentTopic.assessment_topic_id,
                                "",
                                currentKpiId
                              );
                              setKpiFile(null);
                            }}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </Box>
    </>
  );
}
