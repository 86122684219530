import React, { useEffect, useState } from "react";

import {
  AppBar,
  createTheme,
  Grid,
  makeStyles,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import {
  setBrmMainTabNo,
  setBrmSubTabNo,
} from "../../../actions/brm/BrmActions";
import BrmDataLeft from "./brmDataLeft/brmDataLeft";
import BrmDataRight from "./brmDataRight.js/brmDataRight";
import ProceedButtonCard from "./ProceedButton";
import {
  rest_get_articles,
  rest_get_controversies_details,
  rest_get_esg_insights,
  rest_get_esg_score,
} from "../../../actions/supplier/esgDiagnosisActions";
import { getSupplierEsgRating, getSupplierSelfAssessmentScore } from "actions/supplierAdmin/supplierAdminActions";

export const appTheme = createTheme({
  palette: {
    secondary: {
      main: "#47BB76",
    },
  },
  topicname: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  selectTopicText: {
    color: "#9FA1AF",
    fontSize: 13,
    padding: "10px 0px",
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  containerSelectTopicText: {
    marginBottom: 7,
  },
  emptyTopicBar: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
  },
  relavanceAvatar: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#fff",
    border: "1px solid #DADBE6",
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
  stakholderTitle: {
    fontWeight: "600",
    marginLeft: 15,
    fontFamily: "Poppins",
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "capitalize",
  },
});

function BrmDetails(props) {
  const history = useHistory();
  // const location = useLocation();

  const useStyles = makeStyles((theme) => ({
    cardContainerDefault: {
      width: "100%",
      height: "100px",
      background: "#FDFDFD",
      border: "1px solid #C0D4E9",
      borderRadius: "8px",
      marginBottom: "16px",
    },

    mainHeadingDefault: {
      fontWeight: 500,
      fontSize: "16px",
      color: "#242424",
      lineHeight: "24px",
      letterSpacing: "0.0015em",
      marginBottom: 8,
    },

    subHeadingDefault: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#BBBBBB",
      letterSpacing: "0.0025em",
    },

    cardContent: {
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      // cursor: "pointer",
    },
    cardContainer: {
      backgroundColor: "#FFFFFF",
      // marginBottom: '15px',
      borderRadius: 5,
      height: "100%",
    },

    cardTypography: {
      fontWeight: "600",
    },
    AppBar: { boxShadow: "none" },
    tabLabel: {
      textTransform: "capitalize",
      fontSize: 14,
      fontFamily: "Poppins",
    },
    tab: {
      fontSize: 12,
    },
  }));
  const { supplierOrganizationDetails, brmMainTabNo } = useSelector(
    (state) => state.brmReducer
  );
  const { token, current_organisation } = useSelector((state) => state?.login);


  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [riskTabNo, setRiskTabNo] = useState(0);

  useEffect(() => {
    dispatch(setBrmMainTabNo(0));
    dispatch(setBrmSubTabNo(0));
    function handleWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleWidth);

    return () => {
      // cancel the subscription
      window.removeEventListener("resize", handleWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();


  return (
    <div style={{ overflow: "hidden" }}>
      <Grid container>
        <ArrowBack
          onClick={() => {
            history.goBack();
          }}
          style={{ cursor: "pointer" }}
        />
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          {supplierOrganizationDetails?.name}
        </Typography>
      </Grid>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          {/* evaluator tabs */}

          <Tabs
            value={brmMainTabNo}
            onChange={(e, newVal) => {
              dispatch(setBrmMainTabNo(newVal));
              dispatch(setBrmSubTabNo(0));
            }}
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Company Setup</span>}
            />

            <Tab
              disableRipple
              className={classes.tab}
              onClick={() => {}}
              label={<span className={classes.tabLabel}>Data Setup</span>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              onClick={() => {
                dispatch(rest_get_esg_score(supplierOrganizationDetails?.isin));
                dispatch(
                  rest_get_esg_insights(supplierOrganizationDetails?.isin)
                );
                dispatch(
                  rest_get_controversies_details(
                    supplierOrganizationDetails?.isin
                  )
                );
                dispatch(
                  rest_get_articles(
                    supplierOrganizationDetails?.isin,
                    10,
                    0,
                    "Positive"
                  )
                );

                dispatch(
                  getSupplierSelfAssessmentScore(
                    token,
                    supplierOrganizationDetails?._id,
                    current_organisation
                  )
                );
              }}
              label={<span className={classes.tabLabel}>ESG Diagnosis</span>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              onClick={() => {
                dispatch(
                  getSupplierEsgRating(
                    token,
                    supplierOrganizationDetails?._id,
                    current_organisation
                  )
                );
              }}
              label={<span className={classes.tabLabel}>ESG Risk</span>}
            />
          </Tabs>
        </AppBar>
      </MuiThemeProvider>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          // height: windowWidth >= 992 ? "76vh" : "91%",
          padding: 10,
        }}
      >
        <Grid item xs={12} sm={11} md={4} style={{ height: "75vh" }}>
          <BrmDataLeft
            // mainTabNo={mainTabNo}
            // setMainTabNo={setMainTabNo}
            {...props}
          />
        </Grid>
        <Grid item xs={12} sm={11} md={8} style={{ height: "75vh" }}>
          <BrmDataRight
            riskTabNo={riskTabNo}
            setRiskTabNo={setRiskTabNo}
            // organizaitonDetails={organizationDetails}
            // organizaitonId={organizaitonDetails[0]._id}
            {...props}
          />
        </Grid>
        <Grid item xs={11} md={11}>
          {<ProceedButtonCard {...props} />}
        </Grid>
      </Grid>
    </div>
  );
}

export default BrmDetails;
