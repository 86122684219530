import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PersonOutlineSharpIcon from '@material-ui/icons/PersonOutlineSharp';
import FormatListBulletedSharpIcon from '@material-ui/icons/FormatListBulletedSharp';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import Button from '../../UI/button/button';
import ViewSubmissionChatThread from './viewSubmissionChatThread';
import { getTimeStamp } from '../../../utils/reportData/reportDataRightUtils';

function Responses(props) {
  const { fieldData, login, assessment_id, getSubmissionDetails, metric } =
    props;
  const currentUserId = localStorage.getItem('user_id');
  // console.log('props',fieldData)
  const data = [
    {
      url: 'https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?w=2000',
      name: 'Karan kapoor',
      date: new Date(),
    },
    {
      url: 'https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/profile-photos-4.jpg',
      name: 'Somesh Nanda',
      date: new Date(),
    },
    {
      url: 'https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?b=1&s=170667a&w=0&k=20&c=MRMqc79PuLmQfxJ99fTfGqHL07EDHqHLWg0Tb4rPXQc=',
      name: 'Rajveer mishra',
      date: new Date(),
    },
    {
      url: 'https://cdn.pixabay.com/photo/2020/05/11/11/23/woman-5157666__340.jpg',
      name: 'Hardeep sushanth',
      date: new Date(),
    },
    {
      url: 'https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg',
      name: 'Bhargavi kirubasankar',
      date: new Date(),
    },
  ];
  const [approvedVersion, setApprovedVersion] = useState(false);
  const [submission, setSubmussion] = useState(false);
  const [ownSbmission, setOwnSubmission] = useState(false);
  // useEffect(() => {
  //   setApprovedVersion(
  //     props.fieldData.disclosureSettingsresponses.approved_data
  //   );
  // }, []);

  const viewSubmissionList = (item) => {
    // console.log('item',item)
    return (
      //   fieldData.disclosureSettingsresponses &&
      //   fieldData.disclosureSettingsresponses.all_submissions.map(
      // (item, index) => (
      <Box
        p={2}
        mb={2}
        // key={index}
        borderRadius={4}
        border={2}
        borderColor="#A1BFDF"
        bgcolor="#EBF1F8"
      >
        <Box display="flex" alignItems="center">
          <Box>
            <FormatListBulletedSharpIcon style={{ color: '#3374B9' }} />
          </Box>
          <Box mx={1}>
            <Avatar
              style={{ height: 30, width: 30 }}
              src={item.user_data.profile_img.url}
              alt="item-icon"
            />
          </Box>
          <Box mr={1}>
            <Typography variant="caption">{item.user_data.name}</Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="textSecondary">
            {getTimeStamp(item.added_at)}
            </Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => {
              // console.log('item',item)
              getSubmissionDetails(
                login.token,
                item.submission_id,
                item.user_id,
                item
              );
              setSubmussion(true);
            }}
          >
            View submission
          </Button>
        </Box>
      </Box>
    );
    //   )
    // );
  };

  const handleOwnSumussion = () => {
    setOwnSubmission(!ownSbmission);
    approvedVersion && setApprovedVersion(!approvedVersion);
    if (!ownSbmission) {
     let filtered = fieldData.disclosureSettingsresponses &&
              fieldData.disclosureSettingsresponses.all_submissions.filter((item)=>item.user_id===currentUserId)
    // console.log('item',filtered)
        getSubmissionDetails(
        login.token,
        filtered[0].submission_id,
        filtered[0].user_id,
        filtered.length>0&& filtered[0],
      );
      setSubmussion(true);
    } else setSubmussion(false);
  };
  //  let filtered= fieldData.disclosureSettingsresponses &&
  //     fieldData.disclosureSettingsresponses.all_submissions.filter((item) => {
  //       if(item.user_id === currentUserId) return item
  //     });
  //     console.log('filtered',!ownSbmission,filtered);
  // };

  return (
    <>
      <Box m={2}>
        <Box mt={4} mb={1}>
          <Typography variant="body2">Response</Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="caption" color="textSecondary">
            The responses tab has the control of how the responses can be seen
            and by whom.
          </Typography>
        </Box>
        <Divider />
        <Box my={2}>
          <Button
            style={{ backgroundColor: approvedVersion ? '#EBF1F8' : 'white' }}
            onClick={() => {
              setSubmussion(false);
              setOwnSubmission(false);
              setApprovedVersion(!approvedVersion)}}
            color="inherit"
            variant="outlined"
            size="small"
          >
            approved version
          </Button>
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            style={{
              marginLeft: 10,
              backgroundColor: ownSbmission ? '#EBF1F8' : 'white',
            }}
            endIcon={<PersonOutlineSharpIcon />}
            onClick={handleOwnSumussion}
          >
            View own submission
          </Button>
        </Box>
        {!submission ? (
          <Box height="62vh" overflow="scroll">
            {!approvedVersion ? (
              fieldData.disclosureSettingsresponses &&
              fieldData.disclosureSettingsresponses.all_submissions.map(
                (item) => viewSubmissionList(item)
              )
            ) : fieldData.disclosureSettingsresponses.approved_data ? (
              viewSubmissionList(
                fieldData.disclosureSettingsresponses.approved_data
              )
            ) : (
              <Typography>No data Approved Yet</Typography>
            )}
          </Box>
        ) : (
          <div>
            <ViewSubmissionChatThread
              {...props}
              task={false}
              setThread={setSubmussion}
              ownSbmission={ownSbmission}
              // socket={socket}
              login={props.login}
              metric={metric}
              assessment_id={props.assessment_id}
            />
          </div>
        )}
      </Box>
    </>
  );
}

export default Responses;
