import React from "react";
import SurveyStepper from "./SurveyStepper";
import MainHeader from "../../../../screen/vendorAdminScreen/MainHeader";

function StepHeader({ steps, activeStep }) {
  return (
    <>
      <MainHeader />
      <SurveyStepper steps={steps} activeStep={activeStep} />
    </>
  );
}

export default StepHeader;
