import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Box,
  Drawer,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  getSurveyOrganizations,
  getSurveyRespondedUser,
  getSurveyResponses,
  setSurveyResponse,
} from "../../../../../../actions/supplier/supplierAction";

// component imports
import { responsesData } from "../tempData";
import NoData from "../../../../NoData/NoData";
import ResponseDrawer from "./ResponseComponents/ResponseDrawer";

const useStyles = makeStyles(() => ({
  // table styles
  tableContainer: {
    boxShadow: "none",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRows: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    cursor: "pointer",
  },
}));

// ----------------- Main Render -----------------
const ResponsesTab = () => {
  const classes = useStyles();

  // from redux
  const dispatch = useDispatch();

  // state for drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { token } = useSelector((state) => state?.login);
  const { respondedOrganizations, selectedSurveyData } = useSelector(
    (state) => state?.supplier
  );

  // table headings
  const headings = ["Organisation Name", "Shared by", "Status", "Shared on"];

  useEffect(() => {
    dispatch(getSurveyOrganizations(token, selectedSurveyData?._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------- inner functions ---------------
  // handling drawer open and close
  const renderSurveyStatus = (frequency) => {
    const isCompleted = frequency.every((item) => item.is_completed);

    const status_class = isCompleted
      ? {
          padding: "8px 12px 8px 12px",
          borderRadius: "80px",
          display: "flex",
          alignItems: "center",
          width: "max-content",
          fontWeight: "500",
          color: "#154328",
          backgroundColor: "#BFE2CD",
        }
      : {
          padding: "8px 12px 8px 12px",
          borderRadius: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "max-content",
          fontWeight: "500",
          color: "#8C7038",
          backgroundColor: "#FFEFD0",
        };
    return (
      <Box style={status_class}>
        <FiberManualRecordIcon
          style={{ width: 10, height: 10, marginRight: 5 }}
        />
        {isCompleted ? "Completed" : "In Progress"}
      </Box>
    );
  };

  const handleDrawer = (_id) => {
    setDrawerOpen(!drawerOpen);
    // !drawerOpen &&
    //   dispatch(getSurveyRespondedUser(token, _id?.organization_id));
  };

  // Format date function
  const formatDate = (dateString) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  // -----------------------------------------------

  if (!respondedOrganizations || respondedOrganizations.length === 0) {
    return <NoData />;
  }

  return (
    <div style={{ padding: "20px" }}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {headings.map((heading, index) => (
                <TableCell key={index} className={classes.tableHead}>
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {respondedOrganizations?.map((response, index) => (
              <TableRow
                className={classes.tableRows}
                key={index}
                onClick={() => {
                  dispatch(setSurveyResponse(response));
                  handleDrawer(response);
                }}
              >
                <TableCell>{response.supplier_name}</TableCell>
                <TableCell>{response.shared_by}</TableCell>
                <TableCell>{renderSurveyStatus(response.frequency)}</TableCell>
                <TableCell>{formatDate(response.assigned_on)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Response Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawer}>
        <ResponseDrawer handleDrawer={handleDrawer} />
      </Drawer>
    </div>
  );
};
export default ResponsesTab;
