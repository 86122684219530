import React from 'react';
import { connect } from 'react-redux';
import AddCompany from '../../components/widgets/addCompany/addCompany';
import { getFrameworks } from '../../actions/sustainabilityReport/sustainabilityReportAction';
import {
  signup_with_email,
  setSideImage,
  setSideMessage,
  signupValidation,
  setSignupCard,
  setShowOnboarding,
  addOrganisationUnit,
  setLogin,
  updateOrganisationUnit,
  setIsNewCompany,
  setCompanyName,
} from '../../actions/login/loginActions';
import {
  getMainTopics,
  getIndustries
} from '../../actions/sustainabilityReport/sustainabilityReportAction';

export class OnboaringCon extends React.PureComponent {
  render() {
    return <AddCompany {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    login: store.login,
    basicInfo: store.basicInfo,
    materialityAssessment: store.materialityAssessment
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMainTopics: (token, frameworkId) => {
    dispatch(getMainTopics(token, frameworkId));
  },
  updateOrganisationUnit: (token, organisation_id, framework_id, topics) => {
    dispatch(
      updateOrganisationUnit(token, organisation_id, framework_id, topics)
    );
  },
  signup_with_email: (
    name,
    email,
    password,
    phone_no,
    company_name,
    company_type,
    employee_count
  ) => {
    dispatch(
      signup_with_email(
        name,
        email,
        password,
        phone_no,
        company_name,
        company_type,
        employee_count
      )
    );
  },
  signupValidation: (
    name,
    email,
    password,
    phone_no,
    checkBox,
    confirmPassword
  ) => {
    dispatch(
      signupValidation(
        name,
        email,
        password,
        phone_no,
        checkBox,
        confirmPassword
      )
    );
  },
  setSideImage: (payload) => {
    dispatch(setSideImage(payload));
  },
  setSideMessage: (payload) => {
    dispatch(setSideMessage(payload));
  },
  setSignupCard: (payload) => {
    dispatch(setSignupCard(payload));
  },
  setShowOnboarding: (payload) => {
    dispatch(setShowOnboarding(payload));
  },
  setLogin: (payload) => {
    dispatch(setLogin(payload));
  },
  addOrganisationUnit: (token, company_name, sector, employee_count) => {
    dispatch(addOrganisationUnit(token, company_name, sector, employee_count));
  },
  getFrameworks: (token) => {
    dispatch(getFrameworks(token));
  },
  getIndustries: (token) => {
    dispatch(getIndustries(token));
  },
  setIsNewCompany: (payload) => {
    dispatch(setIsNewCompany(payload));
  },
  setCompanyName: (payload) => {
    dispatch(setCompanyName(payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboaringCon);
