import {
  SET_ALL_ASSIGNMENT_DATA,
  UPDATE_DEFINE_OBJECTIVE,
  UPDATE_DEFINE_MATERIALITY,
  SET_COMPANY_ADDRESS,
  SET_COMPANY_PINCODE,
  SET_COMPANY_LOCATION,
  SET_COMPANY_GEOGRAPHIC,
  SET_COMPANY_CAPACITY,
  SET_COMPANY_START_DATE,
  SET_COMPANY_WEBSITE,
  SET_COMPANY_NAME,
  RESET_APPROVAL_DATA,
  SET_COMPANY_ALL_ADDRESS,
  SET_BUSINESS_WEBSITE_ADDRESS,
  SET_NUMBER_OF_EMPLOYEE,
  SET_BUSINESS_GEOGRAPHIC,
  SET_BUSINESS_CAPACITY_TYPE,
  SET_ASSESSMENT_SKELETONS,
  SET_COMPANY_EMAIL,
  SET_COMPANY_ADDRESS_WEBSITE,
  SET_CMP_NAME,
  SET_CMP_ADDRESS,
  SET_CMP_PINCODE,
  SET_CMP_INDUSTRY,
  SET_CPM_PERMANENT_EMPLOYEE,
  SET_CPM_CONTRACT_EMPLOYEE,
  SET_CPM_CITY,
  SET_CPM_COUNTRY,
  SET_COMPANY_ALL_LOCATION,
  SET_SECTOR,
  SET_INDUSTRY,
  SET_COMPANY_DESCRIPTION,
  SET_TOC,
  SET_LOADING_COMPLETED,
  SET_APPROVERS_REVIEWERS,
  SET_PDF_BLOB,
  SET_JWT_HASH,
  SET_PDF_PROGRESS,
  SET_PDF_PROGRESS_COUNTER
} from "../../constants/assessmentApproval/assessmentApprovalConst";
import { SET_COMMENTS } from "../../constants/sustainabilityReport/sustainabilityReportPdfConst";

const Intial_assessment = {
  companyName: "",
  fullAddress: "",
  location: "",
  addressPincode: "",
  email: "",
  companyWebsite: "",
  geographic: "",
  website: "",
  defineMateriality: [],
  defineObjective: [],
  companyAddress: [],
  locations: [],
  tableData: [],
  businessCapecityType: "",
  numberOfEmployee: "",
  businessGeographic: "",
  businessWebsiteAddress: "",
  frameWorkId: "",
  basicCompanyFile: "",
  logo: "",
  basicFile: "",
  topic: [],
  assessment_approval_report_name: "",
  assessmentSkeletons: false,
  company_description: "",
  sector: "",
  companyIndustry: "",
  start_date: "",
  locations_and_employees: [],
  name: "",
  address: "",
  pincode: "",
  industry: "",
  permanentEmployee: "",
  contractEmployee: "",
  city: "",
  country: "",
  sectorsList: [],
  materialityLegalDoc: "",
  voteData: [],
  loadingCompleted: false,

  // new
  basicData: {},
  graphData: [],
  stakeholderData: [],
  topicData: [],
  coverData: "",
  parentTopicData: [],
  headerFooterData: {},

  //toc and comments
  tableOfContent: [],
  comments: [],

  // Approver
  approver: "",
  approversReviewersList: [],
  pdfBlob: {},
  jwt_hash: "",
  pdfProgress: {},
  pdfProgressCounter: {},

  // font
  font:'',
  color:{},
  reportName:'',
  preSelectedColor:[]
};

export default function assessmentApproval(state = Intial_assessment, action) {
  switch (action.type) {
    case SET_COMPANY_EMAIL:
      return { ...state, email: action.payload };
    case SET_COMPANY_ADDRESS_WEBSITE:
      return { ...state, companyWebsite: action.payload };
    case SET_ASSESSMENT_SKELETONS:
      return { ...state, assessmentSkeletons: action.payload };
    case SET_PDF_BLOB:
      let pdfBlob = state.pdfBlob;
      pdfBlob[action.payload.id] = action.payload.pdfBlob;
      return { ...state, pdfBlob: pdfBlob };
    case SET_JWT_HASH:
      return { ...state, jwt_hash: action.payload };
    case SET_ALL_ASSIGNMENT_DATA:
      return {
        ...state,
        basicData: action.payload.company_logo,
        graphData: action.payload.graph_data,
        stakeholderData: action.payload.stakeholder_data,
        topicData: action.payload.topic_data,
        coverData: action.payload.cover_data,
        parentTopicData: action.payload.parent_topics,
        approver: action.payload.approver_data,
        headerFooterData: action.payload.header_footer_data,
        topic: action.payload.topics,
        font:action.payload.font,
        color:action.payload.color,
        reportName:action.payload.report_name,
        preSelectedColor:action.payload.previously_selected_colors,
      };
    case SET_CMP_NAME:
      return { ...state, name: action.payload };
    case SET_CMP_ADDRESS:
      return { ...state, address: action.payload };
    case SET_CMP_PINCODE:
      return { ...state, pincode: action.payload };
    case SET_CMP_INDUSTRY:
      return { ...state, industry: action.payload };
    case SET_CPM_PERMANENT_EMPLOYEE:
      return { ...state, permanentEmployee: action.payload };
    case SET_CPM_CONTRACT_EMPLOYEE:
      return { ...state, contractEmployee: action.payload };
    case SET_CPM_CITY:
      return { ...state, city: action.payload };
    case SET_CPM_COUNTRY:
      return { ...state, country: action.payload };

    case SET_COMPANY_DESCRIPTION:
      return { ...state, company_description: action.payload };
    case SET_SECTOR:
      return { ...state, sector: action.payload };
    case SET_INDUSTRY:
      return { ...state, companyIndustry: action.payload };

    case SET_BUSINESS_GEOGRAPHIC:
      return { ...state, businessGeographic: action.payload };
    case SET_BUSINESS_CAPACITY_TYPE:
      return { ...state, businessCapecityType: action.payload };
    case SET_NUMBER_OF_EMPLOYEE:
      return { ...state, numberOfEmployee: action.payload };
    case SET_BUSINESS_WEBSITE_ADDRESS:
      return { ...state, businessWebsiteAddress: action.payload };
    case SET_COMPANY_ALL_ADDRESS:
      state.companyAddress.splice(action.payload.index, 1, action.payload.data);
      return { ...state };
    case SET_COMPANY_ALL_LOCATION:
      state.locations_and_employees.splice(
        action.payload.index,
        1,
        action.payload.data
      );
      return { ...state };
    case SET_COMPANY_NAME:
      return { ...state, companyName: action.payload };
    case SET_COMPANY_ADDRESS:
      return { ...state, fullAddress: action.payload };
    case SET_COMPANY_PINCODE:
      return { ...state, addressPincode: action.payload };
    case SET_COMPANY_LOCATION:
      return { ...state, location: action.payload };
    case SET_COMPANY_GEOGRAPHIC:
      return { ...state, geographic: action.payload };
    case SET_COMPANY_CAPACITY:
      return { ...state, industry_type: action.payload };
    case SET_COMPANY_START_DATE:
      return { ...state, start_date: action.payload };
    case SET_COMPANY_WEBSITE:
      return { ...state, website: action.payload };
    case UPDATE_DEFINE_MATERIALITY:
      state.defineMateriality.splice(
        action.payload.index,
        1,
        action.payload.data
      );
      return { ...state };
    case UPDATE_DEFINE_OBJECTIVE:
      state.defineObjective.splice(
        action.payload.index,
        1,
        action.payload.data
      );
      return { ...state };
    case RESET_APPROVAL_DATA:
      return {
        ...state,
        companyName: "",
        fullAddress: "",
        location: "",
        addressPincode: "",
        geographic: "",
        industry_type: "",
        start_date: "",
        website: "",
        defineMateriality: [],
        defineObjective: [],
        companyAddress: [],
        tableData: [],
        tableOfContent: [],
        businessCapecityType: "",
        numberOfEmployee: "",
        businessGeographic: "",
        businessWebsiteAddress: "",
        frameWorkId: "",
        basicCompanyFile: "",
        logo: "",
        basicFile: "",
        topic: [],
        assessment_approval_report_name: ""
      };
    case SET_TOC:
      return { ...state, tableOfContent: action.payload };
    case SET_COMMENTS:
      return { ...state, comments: action.payload };
    case SET_LOADING_COMPLETED:
      return { ...state, loadingCompleted: action.payload };
    case SET_APPROVERS_REVIEWERS:
      return { ...state, approversReviewersList: action.payload };
    case SET_PDF_PROGRESS_COUNTER:
      let pdfProgressCounter = state.pdfProgressCounter;
      pdfProgressCounter[action.payload.id] = action.payload.counter;
      return {
        ...state,
        pdfProgressCounter: pdfProgressCounter
      };
    case SET_PDF_PROGRESS:
      let pdfProgress = state.pdfProgress;
      pdfProgress[action.payload.id] = action.payload.progress;
      return { ...state, pdfProgress: pdfProgress };
    default:
      return state;
  }
}
