import { makeStyles } from "@material-ui/styles";

export const AssessmentApprovalUseStyles = makeStyles((theme) => ({
	setupIcocn: {
		width: 45,
	},
	setupCard: {
		width: "100%",
		height: "100%",
		position: "sticky",
		padding: 15,
		scrollbarWidth: "none",
	},
	cardTitle: {
		paddingLeft: 5,
		fontWeight: "bold",
	},
	shareContentText: {
		color: "#9FA1AF",
		marginTop: 5,
	},
	addCoverImage: {
		border: "2px dashed #DADBE6",
		borderRadius: 10,
		height: 200,
		width: 150,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
	},
	coverImage: {
		borderRadius: 10,
		height: 200,
		width: 150,
		"& img": {
			width: "100%",
			height: "100%",
			borderRadius: 10,
		},
		position: "relative",
	},
	ClearImage: {
		position: "absolute",
		right: 0,
	},
	sendBtn: {
		marginTop: 10,
	},
	tocTitle: {
		color: "#9FA1AF",
		fontSize: 16,
		lineHeight: "24px",
		fontWeight: "600",
	},
	tocContent: {
		fontSize: 16,
		lineHeight: "24px",
		fontWeight: "500",
		color: "#242424",
		textTransform: "capitalize",
		minHeight:"25px"

	},
	tocContentGrid: {
		maxWidth: "450px",
		borderRadius: "8px",
		padding: "16px",
		"&:hover": {
			backgroundColor: "#D6E3F1",
		},
	},
	tocContentGridFont: {
		maxWidth: "100%",
		borderRadius: "8px",
		margin:'2px 0px',
		cursor:'pointer',

		paddingLeft:"4px",
		"&:hover": {
			backgroundColor: "#D6E3F1",
		},
	},
	tocContentGridFontSelected: {
		maxWidth: "100%",
		borderRadius: "8px",
		paddingLeft:"4px",
		margin:'2px',
		backgroundColor: "#D6E3F1",
		cursor:'pointer'
		
	},
	tocInput: {
		backgroundColor: "#fff",
		"&:fieldset": {
			borderRadius: 15,
		},
	},
	input: {
		color: "#3374B9",
		fontWeight: "600",
		padding: "4px",
	},
	shareGrid: {
		borderBottom: "1px solid #DADBE6",
		paddingBottom: 5,
	},
	shareTypography: {
		paddingBottom: 5,
		borderBottom: "3px solid green",
		display: "flex",
		justifyContent: "center",
		fontWeight: "bold",
	},
	shareGritItem: {
		width: "100%",
	},
	shareGritItem1: {
		width: "40%",
	},
	shareTextField: {
		marginTop: 10,
	},
	shareContent: {
		fontWeight: "bold",
	},
	shareDialogActionContainer: {
		marginTop: 20,
	},
	shareDialogActionItem: {
		display: "flex",
		justifyContent: "flex-end",
	},
	shareButtonGrid: {
		paddingRight: 10,
	},
	shareButton: {
		paddingLeft: 50,
		paddingRight: 50,
	},
}));

export default AssessmentApprovalUseStyles;
