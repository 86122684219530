import React from "react";
import dataSurvey from "../../../images/dataSurvey.png";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const NoData = ({ message }) => {
  return (
    <div>
      <Grid container>
        <Grid
          item
          md={12}
          xs={12}
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px",
          }}
        >
          <img
            src={dataSurvey}
            alt="noData"
            style={{
              marginTop: "6%",
              width: "560.96px",
              height: "360px",
            }}
          />
        </Grid>
      </Grid>

      <Typography
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "32px",
          textAlign: "center",
          textTransform: "capitalize",
          color: "#242424",
          padding: "1px 15px",
        }}
      >
        {message || "Import sheet files to procees the data."}
      </Typography>
    </div>
  );
};
export default NoData;
