export const SET_ALL_ASSIGNMENT_DATA = "SET_ALL_ASSIGNMENT_DATA";
export const UPDATE_DEFINE_MATERIALITY = "UPDATE_DEFINE_MATERIALITY";
export const UPDATE_DEFINE_OBJECTIVE = "UPDATE_DEFINE_OBJECTIVE";
export const SET_COMPANY_ADDRESS = "SET_COMPANY_ADDRESS";
export const SET_COMPANY_PINCODE = "SET_COMPANY_PINCODE";
export const SET_COMPANY_LOCATION = "SET_COMPANY_LOCATION";
export const SET_COMPANY_EMAIL = "SET_COMPANY_EMAIL";
export const SET_COMPANY_ADDRESS_WEBSITE = "SET_COMPANY_ADDRESS_WEBSITE";
export const SET_COMPANY_GEOGRAPHIC = "SET_COMPANY_GEOGRAPHIC";
export const SET_COMPANY_CAPACITY = "SET_COMPANY_CAPACITY";
export const SET_COMPANY_START_DATE = "SET_COMPANY_START_DATE";
export const SET_COMPANY_WEBSITE = "SET_COMPANY_WEBSITE";
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const RESET_APPROVAL_DATA = "RESET_APPROVAL_DATA";
export const SET_COMPANY_ALL_ADDRESS = "SET_COMPANY_ALL_ADDRESS";
export const SET_BUSINESS_GEOGRAPHIC = "SET_BUSINESS_GEOGRAPHIC";
export const SET_BUSINESS_CAPACITY_TYPE = "SET_BUSINESS_CAPACITY_TYPE";
export const SET_NUMBER_OF_EMPLOYEE = "SET_NUMBER_OF_EMPLOYEE";
export const SET_BUSINESS_WEBSITE_ADDRESS = "SET_BUSINESS_WEBSITE_ADDRESS";
export const SET_ASSESSMENT_SKELETONS = "SET_ASSESSMENT_SKELETONS";

export const SET_CMP_NAME = "SET_CMP_NAME";
export const SET_CMP_ADDRESS = "SET_CMP_ADDRESS";
export const SET_CMP_PINCODE = "SET_CMP_PINCODE";
export const SET_CMP_INDUSTRY = "SET_CMP_INDUSTRY";
export const SET_CPM_PERMANENT_EMPLOYEE = "SET_CPM_PERMANENT_EMPLOYEE";
export const SET_CPM_CONTRACT_EMPLOYEE = "SET_CPM_CONTRACT_EMPLOYEE";
export const SET_CPM_CITY = "SET_CPM_CITY";
export const SET_CPM_COUNTRY = "SET_CPM_COUNTRY";

export const SET_COMPANY_DESCRIPTION = "SET_COMPANY_DESCRIPTION";
export const SET_SECTOR = "SET_SECTOR";
export const SET_INDUSTRY = "SET_INDUSTRY";

export const SET_COMPANY_ALL_LOCATION = "SET_COMPANY_ALL_LOCATION";

export const SET_TOC = "SET_TOC";
export const SET_COMMENTS = "SET_COMMENTS";
export const SET_LOADING_COMPLETED = "SET_LOADING_COMPLETED";

export const SET_APPROVERS_REVIEWERS = "SET_APPROVERS_REVIEWERS";
export const SET_PDF_BLOB = "SET_PDF_BLOB";
export const SET_JWT_HASH = "SET_JWT_HASH";
export const SET_PDF_PROGRESS = "SET_PDF_PROGRESS";
export const SET_PDF_PROGRESS_COUNTER = "SET_PDF_PROGRESS_COUNTER";
