export const SET_ESG_SCORE = "SET_ESG_SCORE";
export const SET_ESG_INSIGNTS = "SET_ESG_INSIGNTS";
export const SET_CONTROVERSIES_DETAILS = "SET_CONTROVERSIES_DETAILS";
export const SET_ARTICLES = "SET_ARTICLES";
export const SET_SELF_ASSESSMENT_SCORE = "SET_SELF_ASSESSMENT_SCORE";
export const SET_CONTROVERSIES_SCORE = "SET_CONTROVERSIES_SCORE";
export const SET_FREQUENCIES = "SET_FREQUENCIES";
export const SET_COMPLETED_QUESTIONS = "SET_COMPLETED_QUESTIONS";
export const DEFAULT_SUPPLIER_USER_TOKEN  = "DEFAULT_SUPPLIER_USER_TOKEN";
export const SET_SUPPLIER_UPLOADED_FILES = "SET_SUPPLIER_UPLOADED_FILES"
