import {
  SET_CORPORATES,
  SET_CORPORATES_SHARE_SURVEY,
  SET_SELECTED_ASSIGN_SURVEY,
  SET_QUESTION_WITH_STATUS,
  SET_SELECTED_ADMIN_SECTION,
  SET_APPROVED_ANSWER_DATA,
  SET_AUDIT_SECTION_DETAILS,
  SET_AUDIT_SELECTED_SECTION,
  SET_AUDIT_QUESTION_DETAILS,
  SET_SELECTED_CORPORATE,
  SET_ISIN_DATA,
  SET_TOTAL_CORPORATES,
} from "../../constants/supplierAdmin/supplierAdminConstant";

const initial_state = {
  corporatesList: [],
  total_corporates: 0,

  selectedCorporate: null,

  assignSurveyList: [],
  completedSurveyList: [],
  inactiveSurveys:[],
  selectedAssignSurvey: null,
  questionWithStatus: [],
  selectedSection: null,
  questionApproved: 0,
  questionAssigned: 0,
  totalQuestion: 0,
  questionStatus: "",
  approvedAnswerData: {},
  auditSectionDetails: null,
  auditSelectedSection: null,
  auditQuestionDetails: null,

  isinData: [],
};

export default function supplierAdminReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_CORPORATES:
      return (state = { ...state, corporatesList: action.payload });
    case SET_TOTAL_CORPORATES:
      return (state = { ...state, total_corporates: action.payload });
    case SET_SELECTED_CORPORATE:
      return (state = { ...state, selectedCorporate: action.payload });
    case SET_CORPORATES_SHARE_SURVEY:
      return (state = {
        ...state,
        assignSurveyList: action.payload.assigned_surveys,
        completedSurveyList: action.payload.completed_surveys,
        inactiveSurveys: action.payload.inactive_surveys
      });
    case SET_SELECTED_ASSIGN_SURVEY:
      return (state = {
        ...state,
        selectedAssignSurvey: action.payload,
      });
    case SET_QUESTION_WITH_STATUS:
      return (state = {
        ...state,
        questionWithStatus: action.payload.questions,
        totalQuestion: action.payload.total_no_of_questions,
        questionApproved: action.payload.questions_approved,
        questionAssigned: action.payload.questions_assigned,
        questionStatus: action.payload.status,
      });

    case SET_SELECTED_ADMIN_SECTION:
      return (state = {
        ...state,
        selectedSection: action.payload,
      });

    case SET_APPROVED_ANSWER_DATA:
      return (state = {
        ...state,
        approvedAnswerData: action.payload,
      });

    case SET_AUDIT_SECTION_DETAILS:
      return (state = {
        ...state,
        auditSectionDetails: action.payload,
      });

    case SET_AUDIT_SELECTED_SECTION:
      return (state = {
        ...state,
        auditSelectedSection: action.payload,
      });

    case SET_AUDIT_QUESTION_DETAILS:
      return (state = {
        ...state,
        auditQuestionDetails: action.payload,
      });

    case SET_ISIN_DATA:
      return (state = { ...state, isinData: action.payload });

    default:
      return state;
  }
}
