import React from "react";
import { connect } from "react-redux";
import GetStarted from "../../components/screen/getStarted/getStarted";
import { createAssessment } from "../../actions/surveyListing/surveyListingAction";
import { setLogin } from "../../actions/login/loginActions";
import { changePasswordValidation } from "../../actions/surveyListing/surveyListingAction";
import { getCompanyLogo } from '../../actions/login/loginActions';

export class GetStartedCont extends React.PureComponent {
  render() {
    return <GetStarted {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    login: store.login,
    basicInfo: store.basicInfo,
    surveyListing: store.surveyListing
  };
};

const mapDispatchToProps = (dispatch) => ({
  createAssessment: (token, organisation_id) => {
    dispatch(createAssessment(token, organisation_id));
  },
  getCompanyLogo: (domain) => {
    dispatch(getCompanyLogo(domain));
  },
  changePasswordValidation: (token, password, name, organisation_id) => {
    dispatch(changePasswordValidation(token, password, name, organisation_id));
  },
  setLogin: (payload) => {
    dispatch(setLogin(payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GetStartedCont);
