import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import RadioButton from "../radioButton/radioButton";
import CustomTextfield from "../textfield/textfield";
import SurveyUp from "./SurveyUp";
import SurveySuccess from "../../../images/SurveySuccess.svg";
import CustomButton from "../button/button";

function SurveyTask(props) {
  const {
    getMaturityMatrix,
    login,
    maturityMatrix,
    assessment_id,
    updateMaturityMatrix,
  } = props;
  // const Classes =

  useEffect(() => {
    props.getMaturityMatrix(props.login.token);
    props.getAssignedMaturityMatrix(props.login.token, assessment_id);
  }, []);
  const [currenQuestion, setCurrentQuestion] = useState([]);
  const [currenAnswer, setCurrentanswer] = useState([]);
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const [collect, setCollect] = useState({
    question_id: "",
    value: "",
    comment: "",
  });
  //setting the taskAnswerslength as currentQuestionIndex to always get the unanswered questions on first render
  useEffect(() => {
    setCurrentQuestionId(
      maturityMatrix.taskAnswers.length === 40
        ? 39
        : maturityMatrix.taskAnswers.length
    );
  }, [maturityMatrix.taskAnswers.length]);
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);

  useEffect(() => {
    return () => {
      setCollect({ question_id: "", value: "", comment: "" });
    };
  }, []);
  useEffect(() => {
    maturityMatrix.taskQuestions.length > 0 &&
      setCurrentQuestion([maturityMatrix.taskQuestions[currentQuestionId]]);
    maturityMatrix.taskAnswers &&
      setCurrentanswer([maturityMatrix.taskAnswers[currentQuestionId]]);

    if (maturityMatrix.taskAnswers[currentQuestionId]) {
      setCollect({
        ...collect,
        ...maturityMatrix.taskAnswers[currentQuestionId],
      });
    } else {
      setCollect({ question_id: "", value: "", comment: "" });
    }
  }, [
    maturityMatrix.taskQuestions,
    currentQuestionId,
    maturityMatrix.taskAnswers,
  ]);

  useEffect(() => {
    if (
      maturityMatrix.taskQuestions.length ===
        maturityMatrix.taskAnswers.length &&
      maturityMatrix.taskAnswers.length - 1 === currentQuestionId
    ) {
      setIsSurveyCompleted(true);
    }
  }, [
    currentQuestionId,
    maturityMatrix.taskAnswers.length,
    maturityMatrix.taskQuestions.length,
  ]);
  const handleNext = () => {
    setCurrentQuestionId((prev) => (prev < 39 ? prev + 1 : 39));
  };
  const handlePrevious = () => {
    setCurrentQuestionId((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleRedoSurvey = () => {
    setCurrentQuestionId(0);
    setIsSurveyCompleted(false);
  };
  return (
    <div>
      <Card
        variant="outlined"
        style={{
          backgroundColor: "#FFFFFF",
          height: "80vh",
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="h6" style={{ fontWeight: "600" }}>
                Survey Section
              </Typography>
            </Grid>
          </Grid>
          {!isSurveyCompleted ? (
            <>
              <div className="scroll" style={{ height: "63vh" }}>
                <Grid
                  container
                  style={{ marginTop: "25px", marginBottom: "25px" }}
                >
                  <Grid item xs={12} md={12} sm={12}>
                    <Card variant="outlined">
                      <CardContent style={{ height: "100%" }}>
                        <Grid sm={12} md={12} lg={12} spacing={1}>
                          {maturityMatrix.taskQuestions.length > 0 &&
                            currenQuestion &&
                            currenQuestion?.map((que, i) => {
                              return (
                                <div key={i}>
                                  <br />
                                  <Grid
                                    container
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    style={{ width: "100%" }}
                                  >
                                    <Grid item md={1}>
                                      <Typography>
                                        Q{currentQuestionId + 1}.
                                      </Typography>
                                    </Grid>
                                    <Grid item md={11}>
                                      <Typography>{que.name}</Typography>
                                      <br></br>
                                      <hr></hr>
                                      <FormControl component="fieldset">
                                        <RadioGroup name={que._id + ""}>
                                          {que.ans.map((ans, j) => {
                                            return (
                                              <div key={j}>
                                                <FormControlLabel
                                                  control={
                                                    <RadioButton
                                                      checked={
                                                        ans[1] === collect.value
                                                      }
                                                      id={ans[1] + ""}
                                                      value={j}
                                                    />
                                                  }
                                                  label={ans[0]}
                                                  onChange={(e) => {
                                                    setCollect((prev) => {
                                                      return {
                                                        ...prev,
                                                        question_id: que._id,
                                                        value: ans[1],
                                                      };
                                                    });
                                                  }}
                                                />
                                              </div>
                                            );
                                          })}
                                        </RadioGroup>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </div>
                              );
                            })}
                        </Grid>
                      </CardContent>
                    </Card>
                    <Grid container spacing={1} style={{ marginTop: "25px" }}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Add A comment</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="commentText"
                          id="standard-multiline-static"
                          multiline
                          minRows={2}
                          variant="outlined"
                          style={{ width: "100%" }}
                          value={collect.comment}
                          onChange={(event) =>
                            setCollect((prev) => ({
                              ...prev,
                              comment: event.target.value,
                            }))
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <Grid
                lg={12}
                md={12}
                sm={12}
                style={{ padding: "15px", float: "right" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "15px" }}
                  onClick={() => {
                    handlePrevious();
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={collect.value === ""}
                  onClick={() => {
                    updateMaturityMatrix(
                      login.token,
                      assessment_id,
                      collect.question_id,
                      collect.value,
                      collect.comment,
                      currentQuestionId + 1,
                      localStorage.getItem("current_organisation")
                    );
                    handleNext();
                  }}
                >
                  {currentQuestionId === 39 ? "Submit" : "Submit & Next"}
                </Button>
              </Grid>
            </>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "100px",
                }}
              >
                <img
                  style={{ maxWidth: "400px", maxHeight: "400px" }}
                  src={SurveySuccess}
                  alt="survey_success_vector"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "30px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#0F0F0F",
                      paddingBottom: "13px",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "500",
                      letterSpacing: "0.0015rem",
                      textTransform: "capitalize",
                    }}
                  >
                    The survey has been Saved.
                  </Typography>
                  <Typography
                    style={{
                      color: "#0F0F0F",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "400",
                      letterSpacing: "0.0025em",
                      textTransform: "capitalize",
                    }}
                  >
                    If you wish to redo the survey please use the redo button.
                    Or go back to the task section.
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingTop: "30px",
                }}
              >
                <CustomButton
                  style={{ textTransform: "none", fontFamily: "Poppins" }}
                  variant="contained"
                  color="primary"
                  onClick={handleRedoSurvey}
                >
                  Redo Survey
                </CustomButton>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default SurveyTask;
