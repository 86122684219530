import { useState } from "react";
import { Drawer, Box, Stepper, Step, StepLabel } from "@material-ui/core";

import DrawerHeader from "./DrawerHeader";
import Step1Drawer from "./Step1Drawer";
import { useSelector } from "react-redux";
import CreatePortfolioDrawer from "./Step2Drawer";
import DrawerFooter from "./DrawerFooter";

const VendorDrawer = ({ openDrawer, closeDrawer }) => {
  const [activeSteps, setActiveSteps] = useState(1);

  // -------------- inner functions --------------

  const handleNextStep = () => {
    setActiveSteps(1);
  };

  const [vendorDetails, setVendorDetails] = useState({
    vendorName: "",
    vendorType: "",
    vendorWebsite: "",
    vendorLogo: "",
    address: [{ landmark: "", state: "" }],
    country_name: "",
    country_code: "",
    sector_id: "",
    industry_id: "",
    isin: "",
    operation: "",
    no_of_employees: 0,
    // supplier data
    supplierName: "",
    supplierWebsite: "",
    // ---------------
    revenue: [
      // {
      //   revenue: "",
      //   year: "",
      //   currency: "",
      //   figures: "",
      // },
    ],
    turnover: [
      // {
      //   turnover: "",
      //   year: "",
      //   currency: "",
      //   figures: "",
      // },
    ],
  });

  // ---------------------------------------------

  return (
    <Drawer anchor="right" open={openDrawer} onClose={closeDrawer}>
      {/* Main Container */}
      <Box
        style={{
          padding: "5px 0px",
          width: 1080,
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        {/* Drawer Header */}
        <DrawerHeader closeDrawer={closeDrawer} />

        {/* Drawer Steps */}
        {/* <Box
          style={{
            marginTop: "5px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stepper activeStep={activeSteps} style={{ width: "50em" }}>
            {["Basics", "Real-Time Details"].map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box> */}

        {/* Drawer Steps Data */}
        <Box
          className="scroll"
          style={{ marginTop: 18, height: "80vh", padding: "0px 20px" }}
        >
          {activeSteps === 0 ? (
            <Step1Drawer
              vendorDetails={vendorDetails}
              setVendorDetails={setVendorDetails}
            />
          ) : (
            <CreatePortfolioDrawer
              companyDetails={vendorDetails}
              setCompanyDetails={setVendorDetails}
            />
          )}
        </Box>
        <DrawerFooter
          companyDetails={vendorDetails}
          closeDrawer={closeDrawer}
          setCompanyDetails={setVendorDetails}
        />
      </Box>
    </Drawer>
  );
};
export default VendorDrawer;
