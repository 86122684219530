import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomTextfield from "../../UI/textfield/textfield";
import useDebounce from "../../../customHooks/useDebouncec";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles({
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  TableCell: { minWidth: 150 },
  Grid: { marginTop: 20 },
  CustomTextfield: { backgroundColor: "white" },
  Box: { borderBottom: "2px solid #3374B9" },
  Box2: {
    borderBottom: "2px solid gray",
    cursor: "pointer",
  },
  pointer: { cursor: "pointer" },
  input: { display: "none" },
  Typography: {
    fontWeight: "600",
    color: "#3374B9",
  },
});

export default function ReportReviewTypeOne({
  surveyData,
  surveyResultData,
  isUpsertMetricData,
  setIsUpsertMetricData,
  addMetricsData,
  fieldData,
  login,
  assessment_id,
  metricId,
  file,
  comment,
  index,
  review,
  removeFile,
}) {
  const classes = useStyles();
  const [typeOneData, setTypeOneData] = useState([]);

  const [metricFile, setMetricFile] = useState(null);
  const [metricComment, setMetricComment] = useState("");

  useEffect(() => {
    if (file !== "") {
      setMetricFile(file);
    }
    if (comment !== "") {
      setMetricComment(comment);
    }
    return () => {
      setMetricFile(null);
      setMetricComment("");
    };
  }, [file, comment]);

  useEffect(() => {
    if (surveyResultData !== null) {
      setTypeOneData(surveyResultData);
    } else {
      const data = surveyData.rows.map((col, idx) => {
        return {
          row: col,
          data: "",
        };
      });
      setTypeOneData(data);
    }
  }, [fieldData.currentReviewMetric, surveyResultData]); // eslint-disable-line react-hooks/exhaustive-deps

  useDebounce(
    () => {
      if (isUpsertMetricData) {
        addMetricsData(
          login.token,
          assessment_id,
          metricId,
          fieldData.currentReviewMetric._id,
          typeOneData,
          metricComment,
          metricFile,
          "",
          fieldData.currentReviewMetric._id,
          fieldData.currentReviewMetric.metric_name,
          fieldData.currentReviewMetric.metric_desc
        );
      }
      setIsUpsertMetricData(false);
    },
    3000,
    [typeOneData, metricComment, metricFile]
  );

  return (
    <>
      {typeOneData.map((data, i) => {
        return (
          <Grid
            container
            alignItems="center"
            flexDirection="row"
            display="flex"
            spacing={2}
            className={classes.Grid}
          >
            <Grid item xs={12}>
              <Typography className={classes.Typography}>{data.row}</Typography>

              <CustomTextfield
                name={`Text_type1${i}`}
                margin="dense"
                variant="outlined"
                className={classes.CustomTextfield}
                value={typeOneData[i].data}
                fullWidth
                minRows={3}
                multiline
                onChange={(e) => {
                  let value = e.target.value;
                  setTypeOneData((prevState) => {
                    typeOneData[i].data = value;
                    return [...prevState];
                  });
                  if (!isUpsertMetricData) {
                    setIsUpsertMetricData(true);
                  }
                }}
              />
            </Grid>
          </Grid>
        );
      })}
      <Box>
        <Box my={2}>
          <Typography className={classes.blueText}>Comments</Typography>
          <CustomTextfield
            name={`Comment_type1${metricId}`}
            label="Additional Comment"
            fullWidth
            multiline
            minRows={3}
            size="small"
            variant="outlined"
            value={metricComment}
            onChange={(e) => {
              setMetricComment(e.target.value);
              if (!isUpsertMetricData) {
                setIsUpsertMetricData(true);
              }
            }}
          />
        </Box>
        <Box my={2}>
          {metricFile === null ? (
            <>
              <input
                id={metricId}
                type="file"
                index={index}
                onChange={(event) => {
                  setMetricFile(event.target.files[0]);

                  if (!isUpsertMetricData) {
                    setIsUpsertMetricData(true);
                  }
                }}
                className={classes.input}
              />
              <label htmlFor={metricId}>
                <Box display="flex" component="span" alignItems="center">
                  <AttachFileIcon color="disabled" />
                  <Box mx={2} className={classes.Box2} width="100%">
                    <Typography variant="caption" color="textSecondary">
                      Upload document
                    </Typography>
                  </Box>
                </Box>
              </label>
            </>
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <AttachFileIcon color="disabled" />
                <Box mx={2} className={classes.Box} width="100%">
                  <Typography variant="caption" color="primary">
                    {metricFile.name}
                  </Typography>
                </Box>
                <ClearIcon
                  color="primary"
                  className={classes.pointer}
                  onClick={() => {
                    removeFile(
                      login.token,
                      assessment_id,
                      5,
                      metricFile.url,
                      "file",
                      null,
                      null,
                      null,
                      metricId
                    );
                    setMetricFile(null);
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
