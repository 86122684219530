import React, { useState, useEffect } from 'react';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

export default function NotebookList(props) {
  const {
    login,
    location,
    setTopicRole,
    materialityAssessment,
    esgStrategy,
    getMaterialityAssessmentTask,
    getTopicDetails,
    fieldData,
    getAssignedMetric,
    setCurrentTopicId,
    setAssignMetricIndex,
    setCurrentTopic,
    setExplore,
  } = props;
  // console.log(fieldData.reportDataTask)
  const [selected, setSelected] = useState(false);
  const [subSelected, setSubSelected] = useState(false);
  useEffect(() => {
    setSelected(0);
    setSubSelected(0);
  }, []);
  return (
    <Card
      variant="outlined"
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        scrollbarWidth: 'none',
        overflow: 'scroll',
      }}
    >
     
      <CardContent>
        {location.state.step_no === 2 &&
          materialityAssessment.assignedTopics.map(
            (s, i) =>
              s !== null && (
                <Grid
                  container
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color:
                      materialityAssessment.currentTopicId === s.topic_id
                        ? '#000000'
                        : '#77787B',
                    position: 'relative',
                    marginBottom: 25,
                  }}
                >
                  <Grid item xs={3}>
                    <Avatar
                      style={{
                        color: 'white',
                        backgroundColor:
                          materialityAssessment.currentTopicId === s.topic_id
                            ? '#000000'
                            : '#77787B',
                        width: 25,
                        height: 25,
                        marginRight: 10,
                      }}
                    >
                      <Typography
                        variant={
                          materialityAssessment.currentTopicId === s.topic_id
                            ? 'body1'
                            : 'body2'
                        }
                        style={{ color: 'white' }}
                      >
                        {i + 1}
                      </Typography>
                    </Avatar>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      variant={
                        materialityAssessment.currentTopicId === s.topic_id
                          ? 'body1'
                          : 'body2'
                      }
                      onClick={() => {
                        getMaterialityAssessmentTask(
                          login.token,
                          location.state.assignment_id,
                          s.topic_id
                        );
                        setSelected(i);
                        setCurrentTopicId(s.topic_id);
                      }}
                      style={{ cursor: 'pointer', paddingRight: 5 }}
                    >
                      {s.topic_name}
                    </Typography>
                  </Grid>
                </Grid>
              )
          )}
        {location.state.step_no === 3 &&
          esgStrategy.assignedTopics.map((s, i) => (
            <Grid
              container
              style={{
                display: 'flex',
                alignItems: 'center',
                color: selected === i ? '#000000' : '#77787B',
                position: 'relative',
                marginBottom: 25,
              }}
            >
              <Grid item xs={2}>
                <Avatar
                  style={{
                    color: 'white',
                    backgroundColor: selected === i ? '#000000' : '#77787B',
                    width: 25,
                    height: 25,
                    marginRight: 10,
                  }}
                >
                  <Typography
                    variant={selected === i ? 'body1' : 'body2'}
                    style={{ color: 'white' }}
                  >
                    {i + 1}
                  </Typography>
                </Avatar>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant={selected === i ? 'body1' : 'body2'}
                  onClick={() => {
                    getTopicDetails(login.token, s.assessment_topic_id, true);
                    setCurrentTopic(s);
                    setSelected(i);
                  }}
                  style={{ cursor: 'pointer', paddingRight: 5, paddingLeft: 5 }}
                >
                  {s.topic_name}
                </Typography>
              </Grid>
            </Grid>
          ))}
        {/* {console.log('location.state.step_no.......', location.state.step_no)} */}
        {location.state.step_no === 5 &&
          fieldData.reportDataTask.map((s, i) => (
            <Grid
              container
              style={{
                display: 'flex',
                alignItems: 'center',
                color: selected === i ? '#000000' : '#77787B',
                position: 'relative',
                marginBottom: 25,
              }}
            >
              {/* {console.log('s', s)} */}
              <Grid item xs={2}>
                <Avatar
                  style={{
                    color: 'white',
                    backgroundColor: selected === i ? '#000000' : '#77787B',
                    width: 25,
                    height: 25,
                    marginRight: 10,
                  }}
                >
                  <Typography
                    variant={selected === i ? 'body1' : 'body2'}
                    style={{ color: 'white' }}
                  >
                    {i + 1}
                  </Typography>
                </Avatar>
              </Grid>
              <Grid item xs={10}>
                {
                  <Typography
                    variant={selected === i ? 'body1' : 'body2'}
                    onClick={() => {
                      // console.log('m',i)
                      // getAssignedMetric(
                      //   login.token,
                      //   location.state.assignment_id,
                      //   s.metric_ids[i],
                      //   false
                      // );
                      // setSelected(i);
                      // setAssignMetricIndex(i);
                      // setTopicRole(s.role);
                    }}
                    style={{}}
                  >
                    {s.name}
                  </Typography>
                }
              </Grid>
          
              {s.sub_mettrics.length > 0 &&
                s.sub_mettrics.map((sm, j) => (
                  <Grid md={12}>
                    <Typography
                      variant={
                        selected === i && subSelected === j ? 'body1' : 'body2'
                      }
                      onClick={() => {
                        
                        getAssignedMetric(
                          login.token,
                          location.state.assignment_id,
                          sm._id,
                          location.state.role[0]===0 ? false : true
                        );
                        setSubSelected(j);
                        setSelected(i);
                        setExplore(false);
                        setAssignMetricIndex(i);
                        setTopicRole(sm.role);
                      }}
                      style={{
                        cursor: 'pointer',
                        cursor: 'pointer',
                        paddingLeft: 10,
                        paddingTop: 12,
                      }}
                    >
                      {sm.metric_name}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          ))}
        {/* {location.state.step_no === 5 &&
          fieldData.reportDataTask.map((s, i) => (
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === i ? "#000000" : "#77787B",
                position: "relative",
                marginBottom: 25
              }}
            >
              <Grid item xs={2}>
                <Avatar
                  style={{
                    color: "white",
                    backgroundColor: selected === i ? "#000000" : "#77787B",
                    width: 25,
                    height: 25,
                    marginRight: 10
                  }}
                >
                  <Typography
                    variant={selected === i ? "body1" : "body2"}
                    style={{ color: "white" }}
                  >
                    {i + 1}
                  </Typography>
                </Avatar>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant={selected === i ? "body1" : "body2"}
                  onClick={() => {
                    getAssignedMetric(
                      login.token,
                      location.state.assignment_id,
                      s.metric_id
                    );
                    setSelected(i);
                    setAssignMetricIndex(i);
                    setTopicRole(s.role);
                  }}
                  style={{ cursor: "pointer", paddingLeft: 10 }}
                >
                  {s.metric_name}
                </Typography>
              </Grid>
            </Grid>
          ))} */}
      </CardContent>
    </Card>
  );
}
