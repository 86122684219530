import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TableCell,
  TableContainer,
  Paper,
  Box,
  Typography,
  IconButton,
  TableRow,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Textfield from "components/UI/textfield/textfield";
import DynamicTable from "components/UI/dynamicTable/DynamicTable";
import { FiTrash, FiX } from "react-icons/fi";
import { setSurveyTableDocs } from "actions/surveyListing/surveyListingAction";
import CustomButton from "components/UI/button/button";

const ContributionTable = ({
  headers,
  handleTableData,
  submittedAnswer,
  is_completed,
}) => {
  const dispatch = useDispatch();
  const { tableTypeDocs } = useSelector((state) => state.surveyListing);

  const initializeRow = (headers, rowData = null) =>
    headers.map((header, colIndex) => ({
      colIndex: colIndex,
      colName: header.header,
      data: rowData ? rowData[colIndex]?.data : "",
    }));

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (submittedAnswer && submittedAnswer.length > 0) {
      const newRows = submittedAnswer.map((rowData) =>
        initializeRow(headers, rowData)
      );
      setRows(newRows);
    } else {
      setRows([initializeRow(headers)]);
    }
  }, [headers, submittedAnswer]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setSurveyTableDocs([]));
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [headers]);

  const handleAddRow = () => {
    const newRows = [...rows, initializeRow(headers)];
    setRows(newRows);
    handleTableData(newRows);
  };

  const handleInputChange = (rowIndex, colIndex, value) => {
    const newRows = [...rows];
    newRows[rowIndex][colIndex].data = value;
    setRows(newRows);
    handleTableData(newRows);
  };

  const handleDocumentChange = async (rowIndex, colIndex, file) => {
    const newRows = [...rows];
    newRows[rowIndex][colIndex].data = file;
    setRows(newRows);

    const newDocs = [
      ...tableTypeDocs,
      { fileData: file, rowIdx: rowIndex, colIdx: colIndex },
    ];
    await dispatch(setSurveyTableDocs(newDocs));
    handleTableData(newRows);
  };

  const handleDeleteDocument = async (rowIndex, colIndex) => {
    const newRows = [...rows];
    newRows[rowIndex][colIndex].data = "";
    setRows(newRows);

    const newDocs = tableTypeDocs.filter(
      (doc) => !(doc.rowIdx === rowIndex && doc.colIdx === colIndex)
    );
    await dispatch(setSurveyTableDocs(newDocs));
    handleTableData(newRows);
  };

  const handleDeleteRow = async (rowIndex) => {
    const newRows = rows.filter((_, index) => index !== rowIndex);
    setRows(newRows);

    // Update the indices of the documents in tableTypeDocs
    const newDocs = tableTypeDocs
      .filter((doc) => doc.rowIdx !== rowIndex)
      .map((doc) => {
        if (doc.rowIdx > rowIndex) {
          return { ...doc, rowIdx: doc.rowIdx - 1 };
        }
        return doc;
      });
    await dispatch(setSurveyTableDocs(newDocs));

    handleTableData(newRows);
  };

  const columnNames = headers.map((header) => ({
    name: header.header,
    type: header.type,
  }));

  return (
    <TableContainer
      component={Paper}
      style={{ overflowX: "auto", position: "relative", marginTop: 10 }}
    >
      <DynamicTable headings={columnNames}>
        {rows.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row.map(({ colIndex, colName, data }) => (
              <TableCell key={colIndex} style={{ minWidth: 150 }}>
                {headers[colIndex]?.type === "Document" ? (
                  data ? (
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">
                        {data.name?.slice(0, 34) + "..."}
                      </Typography>
                      {!is_completed && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleDeleteDocument(rowIndex, colIndex)
                          }
                        >
                          <FiX color="#E91E63" />
                        </IconButton>
                      )}
                    </Box>
                  ) : (
                    <>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        id={`file-input-${rowIndex}-${colIndex}`}
                        onChange={(e) =>
                          handleDocumentChange(
                            rowIndex,
                            colIndex,
                            e.target.files[0]
                          )
                        }
                      />
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          document
                            .getElementById(
                              `file-input-${rowIndex}-${colIndex}`
                            )
                            .click()
                        }
                      >
                        <AttachFileIcon color="disabled" />
                        <Box
                          mx={2}
                          style={{ borderBottom: "2px solid gray" }}
                          width="100%"
                        >
                          <label>
                            <Typography variant="caption" color="textSecondary">
                              Upload Document
                            </Typography>
                          </label>
                        </Box>
                      </Box>
                    </>
                  )
                ) : (
                  <Textfield
                    color="primary"
                    variant="outlined"
                    label={headers[colIndex]?.type}
                    size="small"
                    type={
                      headers[colIndex]?.type === "Number"
                        ? "number"
                        : headers[colIndex]?.type === "Email"
                        ? "email"
                        : "text"
                    }
                    value={data}
                    onChange={(e) =>
                      handleInputChange(rowIndex, colIndex, e.target.value)
                    }
                    style={{ width: "100%" }}
                    InputProps={{ readOnly: is_completed }}
                  />
                )}
              </TableCell>
            ))}
            <TableCell>
              {!is_completed && (
                <IconButton
                  size="small"
                  onClick={() => handleDeleteRow(rowIndex)}
                >
                  <FiTrash color="#E91E63" />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>
      <div
        style={{
          position: "sticky",
          bottom: 0,
          padding: 16,
          zIndex: 1,
        }}
      >
        {!is_completed && (
          <CustomButton
            color="primary"
            variant="contained"
            onClick={handleAddRow}
          >
            Add Row
          </CustomButton>
        )}
      </div>
    </TableContainer>
  );
};

export default ContributionTable;
