import React from "react";
import Router from "./containers/router/routerCont";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
export default class App extends React.Component {
  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </>
    );
  }
}
const theme = createTheme({
  typography: {
    fontFamily: ["poppins", "Poppins", "sans-serif"].join(","),
  },
});
