import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SurveyTypeOne from "../../fieldData/surveyTypeOne";
import SurveyTypeTwo from "../../fieldData//surveyTypeTwo";
import SurveyTypeThree from "../../fieldData//surveyTypeThree";
import SurveyTypeOnePointOne from "../../fieldData/surveyTypeOnePointOne";
import Threads from "../../viewThreads/viewThreads";

const SingleMetricReport = (props) => {
  const { suggestedData } = useSelector((state) => state.dataHub);
  const {
    login,
    fieldData,
    metric,
    index,
    setIsUpsertMetricData,
    addMetricsData,
    assessment_id,
    removeFile,
    open,
    setOpen,
    onsubmitObject,
    setOnSubmitObject,
    isSuggested,
  } = props;
  return (
    <Box my={2}>
      {!isSuggested && (
        <Grid
          md={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>
            {`${metric.metric_name} : ${metric.metric_desc}`}
          </Typography>
        </Grid>
      )}
      <Box style={{ display: "flex", flexDirection: "column", width: "99%" }}>
        {!isSuggested && (
          <Typography style={{ fontWeight: "600", fontSize: 14 }}>
            {metric.sub_metrics.length === 0 ? "" : metric.metric_name}
          </Typography>
        )}
        {metric.data_type === "Type 1" && (
          <SurveyTypeOne
            validation={metric.validations ? metric.validations : null}
            metric={metric}
            onsubmitObject={onsubmitObject}
            setOnSubmitObject={setOnSubmitObject}
            surveyData={metric.survey_data}
            surveyResultData={false ? suggestedData : metric.data}
            isUpsertMetricData={fieldData.isUpsertMetricData}
            setIsUpsertMetricData={setIsUpsertMetricData}
            addMetricsData={addMetricsData}
            fieldData={fieldData}
            login={login}
            assessment_id={assessment_id}
            metricId={metric._id}
            parentId={metric.parent_id}
            index={index}
            removeFile={removeFile}
            metric_name={metric.metric_name}
            metric_desc={metric.metric_desc}
            isSuggested={isSuggested}
          />
        )}
        {metric.data_type === "Type 1.1" && (
          <SurveyTypeOnePointOne
            validation={metric.validations ? metric.validations : null}
            metric={metric}
            onsubmitObject={onsubmitObject}
            setOnSubmitObject={setOnSubmitObject}
            surveyData={metric.survey_data}
            surveyResultData={false ? suggestedData : metric.data}
            isUpsertMetricData={fieldData.isUpsertMetricData}
            setIsUpsertMetricData={setIsUpsertMetricData}
            addMetricsData={addMetricsData}
            fieldData={fieldData}
            login={login}
            assessment_id={assessment_id}
            metricId={metric._id}
            parentId={metric.parent_id}
            index={index}
            removeFile={removeFile}
            metric_name={metric.metric_name}
            metric_desc={metric.metric_desc}
          />
        )}
        {metric.data_type === "Type 2" && (
          <SurveyTypeTwo
            validation={metric.validations ? metric.validations : null}
            metric={metric}
            onsubmitObject={onsubmitObject}
            setOnSubmitObject={setOnSubmitObject}
            surveyData={metric.survey_data}
            surveyResultData={metric.data}
            isUpsertMetricData={fieldData.isUpsertMetricData}
            setIsUpsertMetricData={setIsUpsertMetricData}
            addMetricsData={addMetricsData}
            fieldData={fieldData}
            login={login}
            assessment_id={assessment_id}
            metricId={metric._id}
            parentId={metric.parent_id}
            index={index}
            removeFile={removeFile}
            metric_name={metric.metric_name}
            metric_desc={metric.metric_desc}
          />
        )}
        {metric.data_type === "Type 3" && (
          <SurveyTypeThree
            validation={metric.validations ? metric.validations : null}
            metric={metric}
            calculate={metric.calculate ? metric.calculate : null}
            onsubmitObject={onsubmitObject}
            setOnSubmitObject={setOnSubmitObject}
            surveyData={metric.survey_data}
            surveyResultData={false ? suggestedData : metric.data}
            isUpsertMetricData={fieldData.isUpsertMetricData}
            setIsUpsertMetricData={setIsUpsertMetricData}
            addMetricsData={addMetricsData}
            fieldData={fieldData}
            login={login}
            assessment_id={assessment_id}
            metricId={metric._id}
            parentId={metric.parent_id}
            index={index}
            removeFile={removeFile}
            metric_name={metric.metric_name}
            metric_desc={metric.metric_desc}
            isSuggested={isSuggested}
          />
        )}
        {open && (
          <Threads
            task={true}
            main={false}
            {...props}
            open={open}
            metric={metric}
            setOpen={setOpen}
            onClose={() => setOpen(false)}
            isTaskSection={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default React.memo(SingleMetricReport);
