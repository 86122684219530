import { useEffect } from "react";

import { CardContent } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";

import proceedButtonUseStyles from "../../UI/proceedButtonCard/proceedButtonStyles";
import CustomButton from "../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  setBrmMainTabNo,
  setBrmSubTabNo,
} from "../../../actions/brm/BrmActions";

import {
  rest_get_articles,
  rest_get_controversies_details,
  rest_get_esg_insights,
  rest_get_esg_score,
} from "../../../actions/supplier/esgDiagnosisActions";

const VendorProceedButton = (props) => {
  const classes = proceedButtonUseStyles();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state?.login);

  const {
    supplierOrganizationDetails,
    organizationDetails,
    brmMainTabNo,
    brmSubTabNo,
  } = useSelector((state) => state.brmReducer);

  const history = useHistory();

  const location = useLocation();

  //   console.log(location, history)
  const firstRoute = `/${location.pathname.split("/")[1]}`;

  const currentOrgData = organizationDetails[0];

  useEffect(() => {
    if (brmMainTabNo === 2 && brmSubTabNo === 0) {
      dispatch(rest_get_esg_score(supplierOrganizationDetails?.isin));
      dispatch(rest_get_esg_insights(supplierOrganizationDetails?.isin));
      dispatch(
        rest_get_controversies_details(supplierOrganizationDetails?.isin)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brmMainTabNo]);

  return (
    <div className={classes.card}>
      <CardContent className={classes.cardContent}>
        <CustomButton
          name="exitBtn"
          color="primary"
          variant="text"
          style={{ textTransform: "capitalize" }}
          className={classes.costumButton}
          onClick={() => {
            if (brmMainTabNo === 0 && brmSubTabNo === 0) {
              history.push(firstRoute);
            } else if (
              brmMainTabNo === 0 ||
              brmMainTabNo === 1 ||
              brmMainTabNo === 2 ||
              brmMainTabNo === 3 ||
              brmMainTabNo === 4 ||
              brmMainTabNo === 5
            ) {
              if (brmSubTabNo === 0) {
                dispatch(setBrmMainTabNo(brmMainTabNo - 1));
              } else {
                dispatch(setBrmSubTabNo(brmSubTabNo - 1));
              }
            }
          }}
        >
          {brmMainTabNo === 0 && brmSubTabNo === 0 ? "Exit" : "Previous"}
        </CustomButton>

        <CustomButton
          name="proceedBtn"
          color="primary"
          variant="contained"
          data-tut="reactour__proceed"
          className={classes.checkmargin}
          //   disabled={!currentOrgData?.website}
          onClick={() => {
            // for tab 0
            if (brmMainTabNo === 0) {
              if (brmSubTabNo === 1) {
                dispatch(setBrmMainTabNo(brmMainTabNo + 1));
                dispatch(setBrmSubTabNo(0));
              } else {
                dispatch(setBrmSubTabNo(brmSubTabNo + 1));
              }
            }
            // for tab 1
            else if (brmMainTabNo > 0 && brmMainTabNo < 2) {
              if (brmSubTabNo === 2) {
                dispatch(setBrmMainTabNo(brmMainTabNo + 1));
                dispatch(setBrmSubTabNo(0));
              } else {
                dispatch(setBrmSubTabNo(brmSubTabNo + 1));
              }
            }
            // for tab 2
            else if (brmMainTabNo > 1 && brmMainTabNo < 3) {
              console.log("run");
              if (brmSubTabNo === 3) {
                console.log("run first");
                dispatch(setBrmMainTabNo(brmMainTabNo + 1));
                dispatch(setBrmSubTabNo(0));
              } else {
                console.log("run second");
                dispatch(setBrmSubTabNo(brmSubTabNo + 1));
              }
            }
            // for tab 5
            else if (brmMainTabNo === 3) {
              //   if (brmSubTabNo < 1) {
              //     dispatch(setBrmSubTabNo(brmSubTabNo + 1));
              //   } else {
              history?.goBack();
              // submit and exit function
              //   }
            }
          }}
        >
          {brmMainTabNo === 3 && brmSubTabNo === 0 ? "Save & Exit" : "Proceed"}
        </CustomButton>
      </CardContent>
    </div>
  );
};
export default VendorProceedButton;
