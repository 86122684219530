import React, { useEffect, useState } from 'react';

function ScrollToBottm() {
  const [toBottom, setToBottom] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 500) {
        setToBottom(true);
      } else {
        setToBottom(false);
      }
    });
  }, []);

const scrollDown=()=>{
    window.scrollTo({top:document.documentElement.scrollHeight-window.innerHeight , behavior:"smooth"})
}

  return <div>
 {toBottom && <button style={{position:"fixed",right:"50px",bottom:"55px",height:"25px",width:"25px",fontSize:"25px",fontSize:"45px" ,border:"none",backgroundColor:"white"}} onClick={scrollDown}>▼</button>} 
  </div>;
}

export default ScrollToBottm;
