import React from "react";
import { connect } from "react-redux";
import Snackbar from "../../components/snackbar/snackbar";

import {
  closeSnackBar
} from "../../actions/snackbar/snackbar_action";

export class SnackbarCont extends React.PureComponent {

  render() {
    return (
      <Snackbar {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    snackbar: store.snackbar
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    closeSnackBar: () => {
      dispatch(closeSnackBar());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarCont);
