import { addColumnRowsData, addRowsColumnData } from "./calculate";

function calculateFields(
	calculate,
	colIndex,
	rowIndex,
	typeThreeData,
	callBack
) {
	if (calculate) {
		if (calculate.method === "row&column") {
			for (let i = 0; i < calculate.keys.length; i++) {
				if (colIndex === rowIndex) {
					if (
						parseInt(calculate.keys[i]?.column) === colIndex &&
						parseInt(calculate.keys[i]?.row) === rowIndex
					) {
						const total = addColumnRowsData(
							rowIndex,
							0,
							calculate.keys[i]?.column,
							typeThreeData,
							"percent",
							"male&female"
						);
						if (!isNaN(total)) {
							if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
								callBack(
									{ target: { value: total } },
									rowIndex,
									parseInt(calculate.keys[i]?.column)
								);
							}
						}
					}
				} else {
					if (parseInt(calculate.keys[i]?.column) === colIndex) {
						if (i === 0) {
							const total = addColumnRowsData(
								rowIndex,
								0,
								calculate.keys[i]?.column,
								typeThreeData,
								"percent",
								"Employees"
							);
							if (!isNaN(total)) {
								if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
									callBack(
										{ target: { value: total } },
										rowIndex,
										parseInt(calculate.keys[i]?.column)
									);
								}
							}
						} else {
							const total = addColumnRowsData(
								rowIndex,
								calculate.keys[i - 1]?.column,
								calculate.keys[i]?.column,
								typeThreeData,
								"percent",
								"Employees"
							);
							if (!isNaN(total)) {
								if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
									callBack(
										{ target: { value: total } },
										rowIndex,
										parseInt(calculate.keys[i]?.column)
									);
								}
							}
						}
					} else {
						if (parseInt(calculate.keys[i]?.row) === rowIndex) {
							if ((colIndex + 1) % 3 !== 0) {
								if (i === 0) {
									const total = addRowsColumnData(
										0,
										calculate.keys[i]?.row,
										colIndex,
										typeThreeData,
										"add",
										"total"
									);
									if (!isNaN(total)) {
										if (
											total !== typeThreeData[rowIndex].columns[colIndex].data
										) {
											callBack(
												{ target: { value: total } },
												parseInt(calculate.keys[i]?.row),
												colIndex
											);
										}
									}
								} else {
									const total = addRowsColumnData(
										calculate.keys[i - 1]?.row,
										calculate.keys[i]?.row,
										colIndex,
										typeThreeData,
										"add",
										"total"
									);
									if (!isNaN(total)) {
										if (
											total !== typeThreeData[rowIndex].columns[colIndex].data
										) {
											callBack(
												{ target: { value: total } },
												parseInt(calculate.keys[i]?.row),
												colIndex
											);
										}
									}
								}
							}
						}
					}
				}
			}
		} else if (calculate.method === "row&column=male&female=top") {
			for (let i = 0; i < calculate.keys.length; i++) {
				if (parseInt(calculate.keys[i]?.column) === colIndex) {
					if (i === 0) {
						const total = addColumnRowsData(
							rowIndex,
							0,
							calculate.keys[i]?.column,
							typeThreeData,
							"percent",
							"Employees"
						);
						if (!isNaN(total)) {
							if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
								callBack(
									{ target: { value: total } },
									rowIndex,
									parseInt(calculate.keys[i]?.column)
								);
							}
						}
					} else {
						const total = addColumnRowsData(
							rowIndex,
							calculate.keys[i - 1]?.column,
							calculate.keys[i]?.column,
							typeThreeData,
							"percent",
							"Employees"
						);
						if (!isNaN(total)) {
							if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
								callBack(
									{ target: { value: total } },
									rowIndex,
									parseInt(calculate.keys[i]?.column)
								);
							}
						}
					}
				} else {
					if (parseInt(calculate.keys[i]?.row) === rowIndex) {
						if ((colIndex + 1) % 3 !== 0) {
							if (rowIndex === 0) {
								const total = addRowsColumnData(
									1,
									2,
									colIndex,
									typeThreeData,
									"add",
									"total&male-female-top"
								);

								console.log("total : - ", total);
								if (!isNaN(total)) {
									if (
										total !== typeThreeData[rowIndex].columns[colIndex].data
									) {
										callBack(
											{ target: { value: total } },
											parseInt(calculate.keys[i]?.row),
											colIndex
										);
									}
								}
							}
							if (rowIndex === 3) {
								const total = addRowsColumnData(
									4,
									5,
									colIndex,
									typeThreeData,
									"add",
									"total&male-female-top"
								);
								if (!isNaN(total)) {
									if (
										total !== typeThreeData[rowIndex].columns[colIndex].data
									) {
										callBack(
											{ target: { value: total } },
											parseInt(calculate.keys[i]?.row),
											colIndex
										);
									}
								}
							}
						}
					}
				}
			}
		} else if (calculate.method === "row&column=male&female") {
			for (let i = 0; i < calculate.keys.length; i++) {
				if (colIndex === rowIndex) {
					if (
						parseInt(calculate.keys[i]?.column) === colIndex &&
						parseInt(calculate.keys[i]?.row) === rowIndex
					) {
						const total = addColumnRowsData(
							rowIndex,
							0,
							calculate.keys[i]?.column,
							typeThreeData,
							"percent",
							"male&female"
						);
						if (!isNaN(total)) {
							if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
								callBack(
									{ target: { value: total } },
									rowIndex,
									parseInt(calculate.keys[i]?.column)
								);
							}
						}
					}
				}
				if (parseInt(calculate.keys[i]?.column) === colIndex) {
					const total = addColumnRowsData(
						rowIndex,
						0,
						calculate.keys[i]?.column,
						typeThreeData,
						"percent",
						"male&female"
					);
					if (!isNaN(total)) {
						if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
							callBack(
								{ target: { value: total } },
								rowIndex,
								parseInt(calculate.keys[i]?.column)
							);
						}
					}
				} else {
					if (parseInt(calculate.keys[i]?.row) === rowIndex) {
						if (colIndex % 2 !== 0 || colIndex === 0) {
							if (i === 0) {
								const total = addRowsColumnData(
									0,
									calculate.keys[i]?.row,
									colIndex,
									typeThreeData,
									"add",
									"total"
								);
								if (!isNaN(total)) {
									if (
										total !== typeThreeData[rowIndex].columns[colIndex].data
									) {
										callBack(
											{ target: { value: total } },
											parseInt(calculate.keys[i]?.row),
											colIndex
										);
									}
								}
							} else {
								const total = addRowsColumnData(
									calculate.keys[i - 1]?.row,
									calculate.keys[i]?.row,
									colIndex,
									typeThreeData,
									"add",
									"total"
								);
								if (!isNaN(total)) {
									if (
										total !== typeThreeData[rowIndex].columns[colIndex].data
									) {
										callBack(
											{ target: { value: total } },
											parseInt(calculate.keys[i]?.row),
											colIndex
										);
									}
								}
							}
						}
					}
				}
				if (rowIndex !== 2 && rowIndex !== 5) {
					if (colIndex === 0) {
						const maintotal = addColumnRowsData(
							rowIndex,
							0,
							0,
							typeThreeData,
							"total",
							"male&female"
						);

						if (!Number.isNaN(maintotal)) {
							if (
								maintotal !==
								parseInt(typeThreeData[rowIndex].columns[colIndex].data)
							) {
								callBack({ target: { value: maintotal } }, rowIndex, colIndex);
							}
						}
					}
				}
			}
		} else if (calculate.method === "row&column=employees") {
			for (let i = 0; i < calculate.keys.length; i++) {
				if (
					(colIndex === 2 ||
						colIndex === 4 ||
						colIndex === 7 ||
						colIndex === 9) &&
					(rowIndex === 2 || rowIndex === 5)
				) {
					if (parseInt(calculate.keys[i]?.column) === colIndex) {
						if (colIndex === 2 || colIndex === 4) {
							const total = addColumnRowsData(
								rowIndex,
								0,
								calculate.keys[i]?.column,
								typeThreeData,
								"percent",
								"male&female"
							);
							if (!isNaN(total)) {
								if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
									callBack(
										{ target: { value: total } },
										rowIndex,
										parseInt(calculate.keys[i]?.column)
									);
								}
							}
						} else {
							const total = addColumnRowsData(
								rowIndex,
								5,
								calculate.keys[i]?.column,
								typeThreeData,
								"percent",
								"male&female"
							);
							if (!isNaN(total)) {
								if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
									callBack(
										{ target: { value: total } },
										rowIndex,
										parseInt(calculate.keys[i]?.column)
									);
								}
							}
						}
					}
				} else {
					if (parseInt(calculate.keys[i]?.column) === colIndex) {
						if (colIndex === 2 || colIndex === 4) {
							const total = addColumnRowsData(
								rowIndex,
								0,
								calculate.keys[i]?.column,
								typeThreeData,
								"percent",
								"male&female"
							);
							if (!isNaN(total)) {
								if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
									callBack(
										{ target: { value: total } },
										rowIndex,
										parseInt(calculate.keys[i]?.column)
									);
								}
							}
						} else {
							const total = addColumnRowsData(
								rowIndex,
								5,
								calculate.keys[i]?.column,
								typeThreeData,
								"percent",
								"male&female"
							);
							if (!isNaN(total)) {
								if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
									callBack(
										{ target: { value: total } },
										rowIndex,
										parseInt(calculate.keys[i]?.column)
									);
								}
							}
						}
					} else {
						if (parseInt(calculate.keys[i]?.row) === rowIndex) {
							if (
								colIndex !== 2 &&
								colIndex !== 4 &&
								colIndex !== 7 &&
								colIndex !== 9 &&
								(rowIndex === 2 || rowIndex === 5)
							) {
								if (i === 0) {
									const total = addRowsColumnData(
										0,
										calculate.keys[i]?.row,
										colIndex,
										typeThreeData,
										"add",
										"total"
									);
									if (!isNaN(total)) {
										if (
											total !== typeThreeData[rowIndex].columns[colIndex].data
										) {
											callBack(
												{ target: { value: total } },
												parseInt(calculate.keys[i]?.row),
												colIndex
											);
										}
									}
								} else {
									const total = addRowsColumnData(
										calculate.keys[i - 1]?.row,
										calculate.keys[i]?.row,
										colIndex,
										typeThreeData,
										"add",
										"total"
									);
									if (!isNaN(total)) {
										if (
											total !== typeThreeData[rowIndex].columns[colIndex].data
										) {
											callBack(
												{ target: { value: total } },
												parseInt(calculate.keys[i]?.row),
												colIndex
											);
										}
									}
								}
							}
						}
					}
				}
			}
		} else if (calculate.method === "row&column=worker") {
			for (let i = 0; i < calculate.keys.length; i++) {
				if (
					(colIndex === 2 ||
						colIndex === 4 ||
						colIndex === 6 ||
						colIndex === 8 ||
						colIndex === 10) &&
					(rowIndex === 2 || rowIndex === 5)
				) {
					if (parseInt(calculate.keys[i]?.column) === colIndex) {
						const total = addColumnRowsData(
							rowIndex,
							0,
							calculate.keys[i]?.column,
							typeThreeData,
							"percent",
							"male&female"
						);
						if (!isNaN(total)) {
							if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
								callBack(
									{ target: { value: total } },
									rowIndex,
									parseInt(calculate.keys[i]?.column)
								);
							}
						}
					}
				} else {
					if (parseInt(calculate.keys[i]?.column) === colIndex) {
						const total = addColumnRowsData(
							rowIndex,
							0,
							calculate.keys[i]?.column,
							typeThreeData,
							"percent",
							"male&female"
						);
						if (!isNaN(total)) {
							if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
								callBack(
									{ target: { value: total } },
									rowIndex,
									parseInt(calculate.keys[i]?.column)
								);
							}
						}
					} else {
						if (parseInt(calculate.keys[i]?.row) === rowIndex) {
							if (
								colIndex !== 2 &&
								colIndex !== 4 &&
								colIndex !== 6 &&
								colIndex !== 8 &&
								colIndex !== 10 &&
								(rowIndex === 2 || rowIndex === 5)
							) {
								if (i === 0) {
									const total = addRowsColumnData(
										0,
										calculate.keys[i]?.row,
										colIndex,
										typeThreeData,
										"add",
										"total"
									);
									if (!isNaN(total)) {
										if (
											total !== typeThreeData[rowIndex].columns[colIndex].data
										) {
											callBack(
												{ target: { value: total } },
												parseInt(calculate.keys[i]?.row),
												colIndex
											);
										}
									}
								} else {
									const total = addRowsColumnData(
										calculate.keys[i - 1]?.row,
										calculate.keys[i]?.row,
										colIndex,
										typeThreeData,
										"add",
										"total"
									);
									if (!isNaN(total)) {
										if (
											total !== typeThreeData[rowIndex].columns[colIndex].data
										) {
											callBack(
												{ target: { value: total } },
												parseInt(calculate.keys[i]?.row),
												colIndex
											);
										}
									}
								}
							}
						}
					}
				}
			}
		} else {
			for (let i = 0; i < calculate.keys.length; i++) {
				if (parseInt(calculate.keys[i]?.column) === colIndex) {
					if (i === 0) {
						const total = addColumnRowsData(
							rowIndex,
							0,
							calculate.keys[i]?.column,
							typeThreeData,
							calculate.method,
							calculate.type
						);
						if (!isNaN(total)) {
							if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
								callBack(
									{ target: { value: total } },
									rowIndex,
									parseInt(calculate.keys[i]?.column)
								);
							}
						}
					} else {
						const total = addColumnRowsData(
							rowIndex,
							calculate.keys[i - 1]?.column,
							calculate.keys[i]?.column,
							typeThreeData,
							calculate.method,
							calculate.type
						);
						if (!isNaN(total)) {
							if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
								callBack(
									{ target: { value: total } },
									rowIndex,
									parseInt(calculate.keys[i]?.column)
								);
							}
						}
					}
				} else {
					if (parseInt(calculate.keys[i]?.row) === rowIndex) {
						if (i === 0) {
							const total = addRowsColumnData(
								0,
								calculate.keys[i]?.row,
								colIndex,
								typeThreeData,
								calculate.method,
								calculate.type
							);
							if (!isNaN(total)) {
								if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
									callBack(
										{ target: { value: total } },
										parseInt(calculate.keys[i]?.row),
										colIndex
									);
								}
							}
						} else {
							const total = addRowsColumnData(
								calculate.keys[i - 1]?.row,
								calculate.keys[i]?.row,
								colIndex,
								typeThreeData,
								calculate.method,
								calculate.type
							);
							if (!isNaN(total)) {
								if (total !== typeThreeData[rowIndex].columns[colIndex].data) {
									callBack(
										{ target: { value: total } },
										parseInt(calculate.keys[i]?.row),
										colIndex
									);
								}
							}
						}
					}
				}
			}
		}
	}
}

export default calculateFields;
