import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ReportSkeleton from "../../skeletons/reportSkeleton";
import LinearProgress from "@material-ui/core/LinearProgress";

const LinearProgressWithLabel = (props) => {
	return (
		<Box display="flex" alignItems="center" paddingBottom="8px">
			<Box width="100%" mr={1}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box minWidth={35}>
				<Typography>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
};
const SustainablityReportPDF = (props) => {
	const {
		sustainabilityReport,
		getSustainabilityReportPdf,
		setSustainabilityPdfProgress,
		login,
		assessment_id,
	} = props;

	const [blob, setBlob] = useState("");
	const [hideLoader, setHideLoader] = useState(false);

	useEffect(() => {
		if (
			sustainabilityReport.pdfProgress[assessment_id] === 0 ||
			!sustainabilityReport.pdfProgress.hasOwnProperty(assessment_id)
		) {
			getSustainabilityReportPdf(
				login.token,
				assessment_id,
				sustainabilityReport.jwt_has,
				6
			);
		}
	}, [assessment_id, sustainabilityReport.pdfProgress[assessment_id]]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (sustainabilityReport.pdfProgress[assessment_id] === 100) {
			setTimeout(() => setHideLoader(true), 500);
		}
	}, [sustainabilityReport.pdfProgress[assessment_id]]);

	useEffect(() => {
		return () => {
			if (sustainabilityReport.pdfProgress[assessment_id] === 100) {
				setSustainabilityPdfProgress({ id: assessment_id, progress: 0 });
			}
		};
	}, [sustainabilityReport.pdfProgress]);

	useEffect(() => {
		setBlob(sustainabilityReport.pdfBlob[assessment_id]);
	}, [login.token, assessment_id, sustainabilityReport.pdfBlob[assessment_id]]);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Box height={550}>
					{!hideLoader && (
						<LinearProgressWithLabel
							value={sustainabilityReport.pdfProgress[assessment_id]}
						/>
					)}
					{sustainabilityReport.assessmentSkeleton ? (
						<ReportSkeleton />
					) : (
						<iframe src={blob} width="100%" height="100%" title="pdf" />
					)}
				</Box>
			</Grid>
		</Grid>
	);
};

export default SustainablityReportPDF;
