import { makeStyles } from "@material-ui/core";

const fileLayoutStyles = makeStyles({
  root: {
    width: 220,
    background:
      'linear-gradient(91.69deg, rgba(71, 187, 118, 0.1) -14.88%, rgba(51, 116, 185, 0.1) 123.69%)',
    border: 'none',
    marginBottom: 12,
  },

  cardHeader: {
    fontWeight: 600,
    fontSize: 14,
  },
  cardContent: {
    paddingTop: 0,
  },
  boxContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 1,
    fontSize: '16px',
  },
  fileName: {
    marginLeft: 20,
    fontSize: 14,
  },
  link:{
    textDecoration: "none",
    color: "#000",
    cursor: "pointer",
  }
});

export default fileLayoutStyles;