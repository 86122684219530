import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import planStyles from "../plansStyles";
import calenderIcon from "../../../../images/calenderIcon.png";
import rupeeIcon from "../../../../images/rupeeIcon.png";
import GetAppIcon from "@material-ui/icons/GetApp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

function InvoiceCard(props) {
  const { generalSettings, sethovered, hovered } = props;
  const classes = planStyles();
  return (
    <div>
      {/* INVOICE HEAD*/}
      <Grid container md={12} sm={12} xs={12}>
        <Grid container md={4} style={{ padding: 28 }}>
          <Grid>
            <img
              style={{ width: "50px" }}
              src={calenderIcon}
              alt="calenderIcon"
            />
          </Grid>
          <Grid style={{ marginLeft: 8 }}>
            <Typography style={{ fontSize: 13, color: "grey" }}>
              {localStorage.getItem("status") === "ACTIVE"
                ? "Next invoice issue date"
                : "Last invoice issue date"}
            </Typography>
            <Typography
              style={{ fontSize: "20px", fontWeight: 600, color: "#15314E" }}
            >
              {generalSettings?.razorpay_invoice?.payment_amount > 0
                ? localStorage.getItem("status") === "TRIAL"
                  ? "--"
                  : localStorage.getItem("status") === "ACTIVE"
                  ? moment(
                      generalSettings?.razorpay_invoice?.next_payment_date
                    ).format("DD MMMM YYYY")
                  : moment(
                      generalSettings?.razorpay_invoice?.last_payment_date
                    ).format("DD MMMM YYYY")
                : "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container md={4} style={{ padding: 28 }}>
          <Grid>
            <img style={{ width: "50px" }} src={rupeeIcon} alt="rupeeIcon" />
          </Grid>
          <Grid style={{ marginLeft: 8 }}>
            <Typography style={{ fontSize: 13, color: "grey" }}>
              Invoice total amount
            </Typography>
            <Typography
              style={{ fontSize: "20px", fontWeight: 600, color: "#15314E" }}
            >
              ₹{" "}
              {generalSettings?.razorpay_invoice?.payment_amount
                ?.toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") || 0}
            </Typography>
          </Grid>
        </Grid>
        <Grid md={4} container style={{ padding: 28 }}>
          <Grid>
            <Typography style={{ fontSize: 13, color: "grey" }}>
              Invoice sent to
            </Typography>
            <Typography
              style={{ fontSize: "15px", fontWeight: 600, color: "#15314E" }}
            >
              {generalSettings?.razorpay_invoice?.invoice_sent_to || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* INVOICE */}
      <Grid container md={12} sm={12} xs={12} className={classes.invoiceHeader}>
        <Grid item md={2} xs={2}>
          <Typography className={classes.invoicerow}>Date</Typography>
        </Grid>
        <Grid item md={4} xs={4}>
          <Typography className={classes.invoicerow}>Description</Typography>
        </Grid>
        <Grid item md={2} xs={2} align="right">
          <Typography className={classes.invoicerow}>Invoice Total</Typography>
        </Grid>

        <Grid item md={2} xs={2} align="center">
          <Typography className={classes.invoicerow}>Status</Typography>
        </Grid>
        <Grid item md={2} xs={2}>
          <Typography className={classes.invoicerow}>
            View / Download
          </Typography>
        </Grid>
      </Grid>
      <div
        className="scroll"
        style={{
          margin: "15px 0px",
          paddingBottom: "35px",
          height: "52vh",
        }}
      >
        {generalSettings?.razorpay_invoice &&
          generalSettings?.razorpay_invoice?.invoices &&
          generalSettings?.razorpay_invoice?.invoices.length > 0 &&
          generalSettings?.razorpay_invoice?.invoices.map((invoiceData) => (
            <Grid
              style={{ borderBottom: "1px solid #d7ebff", padding: "5px" }}
              onMouseEnter={() => sethovered(true)}
              onMouseLeave={() => sethovered(false)}
              container
              className={classes.invoiceRow}
            >
              <Grid item md={2} xs={2}>
                <Typography className={classes.invoicerow}>
                  {moment(invoiceData?.payment_date).format("lll")}
                </Typography>
              </Grid>
              <Grid item md={4} xs={4}>
                <Typography className={classes.invoicerow}>
                  {invoiceData?.description}
                  Enjoy Your Premium plan with benifits
                </Typography>
              </Grid>
              <Grid item md={2} xs={2} align="right">
                <Typography className={classes.invoicerow}>
                  ₹ {invoiceData?.amount}
                </Typography>
              </Grid>
              <Grid item md={2} xs={2} align="center">
                {/* <Typography className={classes.invoicerow}>
                  {invoiceData?.status === "paid" ? "Paid" : "Payment Pending"}
                </Typography> */}
                <div
                  style={{
                    padding: 5,
                    backgroundColor: "#EBF6EF",
                    borderRadius: "5px",
                    width: "60%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#5BB37F",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <Typography
                    style={{ textAlign: "center" }}
                    className={classes.invoicerow}
                  >
                    {invoiceData?.status === "paid"
                      ? "Completed"
                      : "Payment Pending"}
                  </Typography>
                </div>
              </Grid>
              <Grid container justifyContent="space-between" item md={2} xs={2}>
                <Grid md={7} xs={7} item>
                  <Typography
                    style={{
                      fontSize: "13px",
                      textAlign: "left",
                      color: "#3374b9",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(invoiceData?.short_url)}
                  >
                    View Invoice
                  </Typography>
                </Grid>
                {false && (
                  <Grid md={5} xs={5} item>
                    <GetAppIcon
                      className={
                        hovered
                          ? classes.invoiceIconVisible
                          : classes.invoiceIconHidden
                      }
                      onClick={() => {
                        let a = document.createElement("a");
                        a.href = invoiceData?.short_url;
                        // a.target='_blank'
                        a.download = "invoice.pdf";
                        a.click();
                        // a.remove();
                        // a.setAttribute('download','test.pdf')
                        //   const browser = await puppeteer.launch();
                        //   const page = await browser.newPage();
                        //   await page.goto(generalSettings?.razorpay_invoice &&
                        //       generalSettings?.razorpay_invoice?.items[0]
                        //         ?.short_url);
                        //   await page.pdf({ path: "doc.pdf" });
                        //   await page.screenshot({ path: "image.png" });
                        //   await browser.close();
                      }}
                    />
                    <MailOutlineIcon
                      className={
                        hovered
                          ? classes.invoiceIconVisible
                          : classes.invoiceIconHidden
                      }
                      style={{
                        marginLeft: "5px",
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        {/* {generalSettings?.razorpay_invoice &&
          generalSettings?.razorpay_invoice.length === 0 && (
            <Typography style={{ textAlign: "center" }}>No Invoices</Typography>
          )} */}
      </div>
    </div>
  );
}

export default InvoiceCard;
