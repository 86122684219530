import { useState } from "react";

import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";

// import RiskSurveyTop from "../../widgets/surveyComponents/Main/SurveyTop";
import RiskSurveyMain from "../../widgets/surveyComponents/Main/SurveyMain";
import RiskSurveyDrawer from "../../widgets/surveyComponents/Main/SurveyDrawer";
import { useDispatch, useSelector } from "react-redux";
import { setCreateDrawerState } from "../../../actions/brm/BrmActions";

const SupplierSurvey = ({ searchKeyword }) => {
  const dispatch = useDispatch();
  const { hasDrawerOpen } = useSelector((state) => state.brmReducer);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        px: 4,
      }}
    >
      {/* Main areas */}
      {/* <RiskSurveyTop handleDrawer={handleDrawer} /> */}
      <RiskSurveyMain searchKeyword={searchKeyword} />

      {/* Drawer */}
      <Drawer
        anchor="right"
        open={hasDrawerOpen}
        onClose={() => dispatch(setCreateDrawerState(false))}
      >
        <RiskSurveyDrawer
          handleDrawer={() => dispatch(setCreateDrawerState(false))}
        />
      </Drawer>
    </Box>
  );
};
export default SupplierSurvey;
