import React from 'react';
import { connect } from 'react-redux';
import SustainabilityReport from '../../components/widgets/reportListing/sustainablityReport/sustainablityReport';
import { getAssessmentReport } from '../../actions/sustainabilityReport/sustainabilityReportAction';
import { updateReportName } from '../../actions/assessmentApproval/assessmentApproval';
import { getSustainabilityReport } from '../../actions/sustainabilityReport/sustainabilityReportPdfAction';
import {
  getSustainabilityReportPdf,
  setPdfBlobs,
  setSustainabilityPdfProgress
} from '../../actions/sustainabilityReport/sustainabilityReportAction';

export class SustainabilityContributorReportCont extends React.PureComponent {
  render() {
    return <SustainabilityReport {...this.props} />;
  }
}

export const mapStateToProps = (store) => ({
  login: store.login,
  sustainabilityReport: store.sustainabilityReport,
  basicInfo: store.basicInfo,
  materialityAssessment: store.materialityAssessment,
  loadingBar: store.loadingBar
});

export const mapDispatchToProps = (dispatch) => ({
  updateReportName: (token, assessment_id, step_no, report_name) => {
    dispatch(updateReportName(token, assessment_id, step_no, report_name));
  },
  getAssessmentReport: (token, assessmentId) => {
    dispatch(getAssessmentReport(token, assessmentId));
  },
  getSustainabilityReport: (token, assessment_id) => {
    dispatch(getSustainabilityReport(token, assessment_id));
  },
  getSustainabilityReportPdf: (token, assessment_id, jwt_hash, step_no) => {
    dispatch(
      getSustainabilityReportPdf(token, assessment_id, jwt_hash, step_no)
    );
  },
  setPdfBlob: (payload) => {
    dispatch(setPdfBlobs(payload));
  },
  setSustainabilityPdfProgress: (payload) => {
    dispatch(setSustainabilityPdfProgress(payload));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SustainabilityContributorReportCont);
