export const SET_OVERALL_SURVEY='SET_OVERALL_SURVEY';
export const SET_ENVIRONMENT_SURVEY='SET_ENVIRONMENT_SURVEY';
export const SET_SURVEY_QUESTIONS='SET_SURVEY_QUESTIONS';
export const SET_MATURITY_MATRIX_TASK_QUESTIONS='SET_MATURITY_MATRIX_TASK_QUESTIONS';
export const SET_MATURITY_MATRIX_ASSIGNED_MEMBER='SET_MATURITY_MATRIX_ASSIGNED_MEMBER';
export const SET_INTERNAL_MATURITY_MATRIX='SET_INTERNAL_MATURITY_MATRIX';
export const SET_EXTERNAL_MATURITY_MATRIX='SET_EXTERNAL_MATURITY_MATRIX';
export const SET_MATURITY_MATRIX_NOT_ASSIGNED_USERS='SET_MATURITY_MATRIX_NOT_ASSIGNED_USERS';
export const SET_TASK_ANSWERS='SET_TASK_ANSWERS';
export const RESET_MATURITY_MATRIX='RESET_MATURITY_MATRIX';

