import React from "react";

import { Paper } from "@material-ui/core";

function StepRight({ children }) {
  return (
    <Paper  style={{ width: "100%",  padding:"10px", height:"100%",boxShadow: "0px 0px 8px 0px rgba(51, 116, 185, 0.25)" }}>
      {children}
    </Paper>
  );
}

export default StepRight;
