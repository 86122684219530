import { makeStyles } from '@material-ui/core';

const useStylesGettingStarted = makeStyles((theme) => ({
    typography: {
      fontWeight: 'bold',
      fontSize: 18,
      marginBottom: 20,
    },
    typography1: { marginTop: '20px' },
    typography2: { fontWeight: 'bold', marginLeft: 15 },
    card: { borderRadius: 25, width: 450, height: 250 },
    costumButton: { marginTop: 40, padding: '10px 80px 10px 80px' },
    gridCont: { backgroundColor: '#F5F6F8', height: '90vh' },
    gridCont2: { backgroundColor: '#F5F6F8' },
    demoDiv: { padding: 15, backgroundColor: '#ffffff', width: '90%' },
    demoGrid: { height: '85vh', overflowY: 'scroll', scrollbarWidth: 'none' },
    icon: { marginLeft: '-10px', marginTop: '-10px' },
    videoGrid: { padding: 25 },
    paper: {
      borderRadius: 25,
      width: '100%',
      height: 160,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },img1:{
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        borderRadius: 25,
      },
      img2:{ cursor: 'pointer', position: 'absolute'},
      title:{ marginTop: 15 }
  }));

export default useStylesGettingStarted;