import { makeStyles } from "@material-ui/core";

const planStyles = makeStyles(() => ({
  mainDiv: { padding: "26px 15px", paddingTop: "19px" },
  Typography: { fontWeight: "600", fontSize: 26 },
  Grid: { paddingTop: 50 },
  Grid1: { padding: 15, marginTop: 15 },
  Grid2: { padding: 15, marginTop: 25 },
  Grid3: { marginTop: 32 },
  Grid4: { marginTop: 170 },
  div: { display: "flex", alignItems: "center" },
  Card: { padding: 32, backgroundColor: "#3374B9", borderRadius: 15 },
  Card1: { padding: 32, backgroundColor: "#FFFFFF", borderRadius: 15 },
  Typography1: { fontSize: 28, color: "#ffffff" },
  Typography2: { color: "#ffffff" },
  Typography3: { color: "#FFFFFF73", marginTop: 15 },
  Typography4: { color: "#FFFFFF", marginLeft: 15 },
  Typography5: { fontSize: 28 },
  CustomButton: { paddingLeft: 32, paddingRight: 32 },
  AppBar: { boxShadow: "none" },
  tab: {
    fontSize: 12,
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: '15px',
    marginTop: "15px",
    borderRadius: 5,
    height: "100%",
    width: "100%",
  },
  cardContent: {
    padding: 10,
    width:'100%'
  },
  Upgrade: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
    width: "fit-content",
    borderBottom: "2px solid green",
    padding: "8px",
    alignItems: "center",
  },
  invoiceRow: {
    padding: "2px",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
  },
  invoiceIconHidden: {
    display: "none",
    cursor: "pointer",
    color: "#3374b9",
    fontSize: "18px",
    textAlign: "left",
  },
  invoiceIconVisible: {
    display: "inline-block",
    cursor: "pointer",
    color: "#3374b9",
    fontSize: "18px",
    textAlign: "left",
  },
  closeIcon: {
    paddingTop: "5px",
    color: "#3374b9",
  },
  ul: {
    listStyleType: "disc",
    marginLeft: "20px",
    color: "#3374b9",
  },
  li: {
    color: "black",
    fontWeight: 450,
    fontSize: 14,
  },
  payButton: {
    padding: 5,
    width: "140px",
    fontSize: 12,
    textTransform: "none",
    color: "white",
    backgroundColor: "#3374b9",
    float: "right",
    margin: "0px 10px 10px",
    "&:hover": {
      backgroundColor: "white",
      color: "#3374b9",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
    "&:disabled": {
      backgroundColor: "lightGrey",
      color: "grey",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
  upgradeButton: {
    padding: 5,
    width: "140px",
    fontSize: 12,
    textTransform: "none",
    color: "white",
    backgroundColor: "#3374b9",
    float: "right",
    margin: "0px 10px 10px",
    "&:hover": {
      backgroundColor: "white",
      color: "#3374b9",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
  invoiceHeader: {
    alignItems: "center",
    marginTop: "10px",
    backgroundColor: "#EBF1F8",
    padding: "10px",
    borderRadius: "5px",
  },
  invoicerow: { fontSize: "13px" },
  blueText: { fontSize: 15,marginTop:'10px' },
  expiredText: { fontWeight: "bold", fontSize: 18 } 
}));
export default planStyles;
