import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import "../../../styles/drawer.css";
import privacyUseStyles from "./privacyStyles";
import data from '../../../constants/policiesConst/privacyData'


export default function Privacy() {
  const classes = privacyUseStyles();

  const [expanded, setExpanded] = React.useState("");

  const onExpandChange = (title) => {
    if (expanded === title) {
      setExpanded("");
    } else {
      setExpanded(title);
    }
  };

  function changeBackground(e) {
    e.target.style.background = "#F5F6F8";
  }
  function normalBackground(e) {
    e.target.style.background = "white";
  }

  return (
    <>
      <Grid container className={classes.paddings}>
        <Grid item xs={12} className={classes.mainGrid}>
          <Grid container>
            <Grid item xs={3} md={3} className={classes.leftGrid}>
              <Typography
                variant="h6"
                color="primary"
                className={classes.texts}
              >
                <span
                  className={classes.name}
                  onMouseOver={changeBackground}
                  onMouseOut={normalBackground}
                >
                  General Terms
                </span>
              </Typography>
            </Grid>
            <Grid item xs={9} md={6}>
              <Grid
                item
                xs={12}
                className={classes.grid1}
              >
                <Typography variant="body2" className="desc">
                  Billionlives.in and its ImGrows application platform (each a “
                  <strong>Platform</strong>”) is a platform which allows the
                  users to plan their ESG roadmap and strategy and undertake
                  global sustainability reporting, without any kind of
                  intervention from BillionLives. BillionLives recognizes the
                  importance of privacy as well as the importance of maintaining
                  the confidentiality of personal information. This Privacy
                  Policy applies to all products and services provided by
                  BillionLives and sets out how BillionLives may collect, use
                  and disclose information in relation to Users of the Platform.
                </Typography>
                <br />
                <Typography variant="body2" className="desc">
                  User may use BillionLives’ services and products via a mobile
                  device either through mobile applications or mobile optimized
                  websites. This Privacy Policy also applies to such use of
                  BillionLives’ services and products.
                </Typography>
                <br />
                <Typography variant="body2" className="desc">
                  All capitalized terms not defined in this document shall have
                  the meanings ascribed to them in the Terms of Use of the
                  Platform, which can be found here. Contracting entity shall be
                  BillionLives Business Initiatives Private Limited (herein
                  after referred to as ‘<strong>BillionLives</strong>’ or ‘
                  <strong>us</strong>’ or ‘<strong>our</strong> ’).
                </Typography>
                <br />
                <br />
                <br />
              </Grid>
              <Grid item xs={9} md={6}>
                <div
                  className="accordion"
                  style={{ marginTop: "-20px", marginLeft: "-20px" }}
                >
                  {data.map((s) => (
                    <Accordion
                    className={classes.Accordion}
                    >
                      <AccordionSummary
                      className={classes.Accordion1}
                        expandIcon={
                          expanded !== s.title ? (
                            <Add />
                          ) : (
                            <Remove style={{ color: "#3374b9" }} />
                          )
                        }
                        aria-controls="panel1a-content"
                        onClick={() => onExpandChange(s.title)}
                      >
                        <Typography
                          id={expanded !== s.title ? "" : "question"}
                          className="que-div"
                        >
                          {s.title}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails className="content">
                        <Typography className="ans-div">
                          {s.desc.map((a) => {
                            return (
                              <ol key={a}>
                                <div>
                                  {a}
                                  <br />
                                  <br />
                                </div>
                              </ol>
                            );
                          })}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
