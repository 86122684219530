import React from "react";
import { connect } from "react-redux";
import EsgStrategyReport from "../../components/widgets/reportListing/esgStrategyReport/esgStrategyReport";
import {
  getSelectedTopics,
  getTopicDetails,
  setTopicState,
  setCurrentTopic,
  addActivity,
  editActivity,
  deleteActivity,
  addKPI,
  editKPI,
  deleteKPI,
  setIsEditActivity,
  setIsEditKPI,
  assignMembers,
  getUnit,
  addUnit
} from '../../actions/esgStrategy/esgStrategyAction';
import { removeFile } from '../../actions/generalSettings/generalSettingsAction';

class EsgStrategyReportCont extends React.PureComponent {
  render() {
    return <EsgStrategyReport {...this.props} />;
  }
}

const mapStateToProps = (store) => ({
  esgPlan: store.esgStrategy,
  login: store.login,
  materialityAssessment: store.materialityAssessment
});

const mapDispatchToProps = (dispatch) => ({
  getSelectedTopics: (token, assessment_id) => {
    dispatch(getSelectedTopics(token, assessment_id));
  },
  getTopicDetails: (token, assessment_topic_id, my_task_section) => {
    dispatch(getTopicDetails(token, assessment_topic_id, my_task_section));
  },
  setTopicState: (payload) => {
    dispatch(setTopicState(payload));
  },
  setCurrentTopic: (payload) => {
    dispatch(setCurrentTopic(payload));
  },
  setIsEditActivity: (payload) => {
    dispatch(setIsEditActivity(payload));
  },
  setIsEditKPI: (payload) => {
    dispatch(setIsEditKPI(payload));
  },
  addActivity: (
    token,
    assessment_topic_id,
    activity_title,
    estimated_period,
    budget,
    comments,
    file,
    my_task_section,
    assessment_id,
    currency,
    topic_name
  ) => {
    dispatch(
      addActivity(
        token,
        assessment_topic_id,
        activity_title,
        estimated_period,
        budget,
        comments,
        file,
        my_task_section,
        assessment_id,
        currency,
        topic_name
      )
    );
  },
  editActivity: (
    token,
    assessment_topic_id,
    activity_id,
    activity_title,
    estimated_period,
    budget,
    comments,
    file,
    my_task_section,
    assessment_id,
    currency,
    topic_name
  ) => {
    dispatch(
      editActivity(
        token,
        assessment_topic_id,
        activity_id,
        activity_title,
        estimated_period,
        budget,
        comments,
        file,
        my_task_section,
        assessment_id,
        currency,
        topic_name
      )
    );
  },
  deleteActivity: (
    token,
    assessment_topic_id,
    activity_id,
    my_task_section,
    topic_name,
    assessment_id
  ) => {
    dispatch(
      deleteActivity(
        token,
        assessment_topic_id,
        activity_id,
        my_task_section,
        topic_name,
        assessment_id
      )
    );
  },
  addKPI: (
    token,
    assessment_topic_id,
    kpi_title,
    baseline,
    target,
    unit,
    estimated_period,
    budget,
    comments,
    file,
    my_task_section,
    assessment_id,
    currency,
    topic_name
  ) => {
    dispatch(
      addKPI(
        token,
        assessment_topic_id,
        kpi_title,
        baseline,
        target,
        unit,
        estimated_period,
        budget,
        comments,
        file,
        my_task_section,
        assessment_id,
        currency,
        topic_name
      )
    );
  },
  getUnit:()=>{
    dispatch(getUnit())
  },
  addUnit:(token,unit)=>{
    dispatch(addUnit(token,unit))
  },
  editKPI: (
    token,
    assessment_topic_id,
    kpi_id,
    kpi_title,
    baseline,
    target,
    unit,
    estimated_period,
    budget,
    comments,
    file,
    my_task_section,
    assessment_id,
    currency,
    topic_name
  ) => {
    dispatch(
      editKPI(
        token,
        assessment_topic_id,
        kpi_id,
        kpi_title,
        baseline,
        target,
        unit,
        estimated_period,
        budget,
        comments,
        file,
        my_task_section,
        assessment_id,
        currency,
        topic_name
      )
    );
  },
  deleteKPI: (
    token,
    assessment_topic_id,
    kpi_id,
    my_task_section,
    topic_name,
    assessment_id
  ) => {
    dispatch(
      deleteKPI(
        token,
        assessment_topic_id,
        kpi_id,
        my_task_section,
        topic_name,
        assessment_id
      )
    );
  },
  assignMembers: (
    user_token,
    email_ids,
    step_no,
    assessment_id,
    organisation_id,
    topic_id
  ) => {
    dispatch(
      assignMembers(
        user_token,
        email_ids,
        step_no,
        assessment_id,
        organisation_id,
        topic_id
      )
    );
  },
  removeFile: (
    token,
    assessment_id,
    step_no,
    url,
    field_name,
    assessment_topic_id,
    activity_id,
    kpi_id
  ) => {
    dispatch(
      removeFile(
        token,
        assessment_id,
        step_no,
        url,
        field_name,
        assessment_topic_id,
        activity_id,
        kpi_id
      )
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EsgStrategyReportCont);
