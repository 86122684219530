import React from 'react'

import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';
export default function ESGReportSkeleton() {
  const useStyles=makeStyles(()=>({
    mainDiv:{ padding: 25 },
    mainDiv1:{ marginTop: 35 },
    Skeleton1:{ marginRight: 15, marginTop: 10 }
  }))
  const classes=useStyles();
  
  return (
    <div className={classes.mainDiv} >
      <Skeleton width='50%' height='50px' animation="wave" />
      <div className={classes.mainDiv1}>
        {
          [1, 2, 3, 4].map((item) => (
            <Skeleton className={classes.Skeleton1} width='80%' height='35px' animation="wave" />
          ))
        }
      </div>
      <Skeleton style={{ marginTop: 25 }} width='50%' height='50px' animation="wave" />
      <div style={{ marginTop: 35 }}>
        {
          [1, 2, 3, 4].map((item) => (
            <Skeleton className={classes.Skeleton1} width='80%' height='35px' animation="wave" />
          ))
        }
      </div>
    </div>
  )
}
