import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomButton from "../../UI/button/button";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import ClearIcon from "@material-ui/icons/Clear";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import countryList from "../../../utils/countries/countryList";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: '15px',
    borderRadius: 5,
    height: "100%",
  },
  headerText: {
    fontWeight: "600",
  },
  cardTypography: {
    fontWeight: "600",
  },
  logoSelecter: {
    border: "2px dashed #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& $addIcon": {
        color: "#3374B9",
      },
    },
  },
  addIcon: {
    color: "#DADBE6",
  },
  companyLogo: {
    border: "2px solid #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    zIndex: 1,
    "& img": {
      width: "100%",
      height: "100%",
    },
    position: "relative",
  },
  ClearImage: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 2,
  },
  addSectorContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  addBranchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  blueText: {
    color: "#3374B9",
    fontWeight: "600",
    fontSize: 14,
  },
  sectorChip: {
    justifyContent: "flex-start",
    color: "#77787B",
    backgroundColor: "#F5F6F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  },
  selectedSectorTitle: {
    color: "#77787B",
    fontSize: 14,
  },
  removeChipIcon: {
    display: "none",
  },
}));

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export default function BasicInformationRisght(props) {
  const classes = useStyles();

  const {
    basicInfo,
    setCompanyName,
    setCompanyLogo,
    setCompanyLogoURI,
    setCompanyDescription,
    setNumberOfEmployee,
    setCompanyEmail,
    setCompanyWebsite,
    setCompanyAddress,
    setCompanyLandmark,
    setCompanyPincode,
    setCompanyState,
    setCompanyCountry,
    setBranchAddress,
    setBranchLandmark,
    setBranchPincode,
    setBranchState,
    setBranchCountry,
    setBranchAddressArray,
    unsetBranchAddress,
    set_snack_bar,
    assessment_id,
    login,

    removeFile,
  } = props;
  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const [sectors, setsectors] = useState([]);
  const [previousSector, setPreviousSector] = useState(false);
  const isSectorSelected =
    sectors.length !== 0 &&
    sectors.map((sector) =>
      sector.industry_data.some((inds) => inds.isSelected)
    );
  const iscompleteButtonDisabled =
    Array.isArray(isSectorSelected) &&
    isSectorSelected.every((value) => value === false);
  useEffect(() => {
    if (basicInfo?.sectors && basicInfo.sectors.length > 0) {
      let masterSectors = [...basicInfo.masterSectors];
      masterSectors.map(
        (masterSec, masterSecIdx) =>
          basicInfo.sectors.some((sec) => sec.sector === masterSec._id) &&
          masterSec.industry_data.map((masterInd, masterIndIdx) =>
            basicInfo.sectors.map((basicSec) =>
              basicSec.industry.map(
                (basicInd) =>
                  masterInd._id === basicInd &&
                  (masterSectors[masterSecIdx].industry_data[
                    masterIndIdx
                  ].isSelected = true)
              )
            )
          )
      );
      setsectors(masterSectors);
    } else {
      setsectors(basicInfo.masterSectors);
    }
  }, [basicInfo.masterSectors, basicInfo.sector, previousSector]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUploadCompanyLogo = (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];
    setCompanyLogo(file);
    reader.onloadend = () => {
      setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleClearCompanyLogo = () => {
    removeFile(login.token, assessment_id, 1, basicInfo.companyLogoURI, "logo");
    setCompanyLogo("");
    setCompanyLogoURI("");
  };

  const handleCloseSectorDialog = () => {
    setIsSectorDialogOpen(false);
    setPreviousSector((preValue) => !preValue);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    var sectorL = [...sectors];
    sectorL[sectorIndex].industry_data[industryIndex].isSelected =
      !sectorL[sectorIndex].industry_data[industryIndex].isSelected;
    setsectors(sectorL);
  };

  const sectorIndustry = () => {
    return (
      <Dialog
        maxWidth="lg"
        open={isSectorDialogOpen}
        onClose={handleCloseSectorDialog}
      >
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Sector & Industry Selection
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: 900 }}>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>
                Select Sectors
              </Typography>
              <div style={{ marginTop: 15 }}>
                {sectors.map((sector, index) => (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel1${index}`}
                    onChange={handleChange(`panel1${index}`)}
                    name={sector.Sector}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        id={sector.Sector}
                      >
                        {sector.Sector}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {sector.industry_data.map((industry, index2) => (
                        <div
                          key={index2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => handleIndustryChange(index2, index)}
                        >
                          <CustomCheckBox
                            checked={industry.isSelected}
                            name={industry.Industry}
                          />
                          <Typography id={industry.Industry}>
                            {industry.Industry}
                          </Typography>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>Selected</Typography>
              <Card
                variant="outlined"
                style={{ minHeight: 350, width: "95%", marginTop: 15 }}
              >
                <CardContent>
                  {sectors.map((sector, index) => (
                    <div key={index} style={{ marginBottom: 8 }}>
                      {sector.industry_data.some((inds) => inds.isSelected) && (
                        <>
                          <Typography className={classes.selectedSectorTitle}>
                            {sector.Sector}
                          </Typography>
                          {sector.industry_data.map(
                            (industry, index2) =>
                              industry.isSelected && (
                                <Chip
                                  key={index2}
                                  className={classes.sectorChip}
                                  onDelete={() =>
                                    handleIndustryChange(index2, index)
                                  }
                                  deleteIcon={
                                    <RemoveCircleOutlinedIcon
                                      name={industry.Industry}
                                      className={classes.removeChipIcon}
                                    />
                                  }
                                  label={industry.Industry}
                                />
                              )
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={handleCloseSectorDialog}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              setIsSectorDialogOpen(false);
            }}
            variant="contained"
            color="primary"
            disabled={iscompleteButtonDisabled}
          >
            Complete
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const handleAddBranch = () => {
    if (basicInfo.branchAddress === "") {
      set_snack_bar(false, "Please Enter Address..");
    } else if (basicInfo.branchLandmark === "") {
      set_snack_bar(false, "Please Enter Landmark..");
    } else if (basicInfo.branchPincode.search(/^\d{6}$/) === -1) {
      set_snack_bar(false, "Please Enter Valid Pincode..");
    } else if (basicInfo.branchState === "") {
      set_snack_bar(false, "Please Enter State..");
    } else if (basicInfo.branchCountry === "") {
      set_snack_bar(false, "Please Select Country..");
    } else {
      setBranchAddressArray({
        branchAddress: basicInfo.branchAddress,
        branchLandmark: basicInfo.branchLandmark,
        branchPincode: basicInfo.branchPincode,
        branchState: basicInfo.branchState,
        branchCountry: basicInfo.branchCountry,
      });
      setBranchAddress("");
      setBranchLandmark("");
      setBranchPincode("");
      setBranchState("");
      setBranchCountry("");
    }
  };

  const LocationStore = (
    <>
      {basicInfo.branchAddressArray.length > 0 &&
        basicInfo.branchAddressArray.map((branch, index) => {
          return (
            <Box my={2} key={index}>
              <Paper>
                <Box display="flex" alignItems="center" p={1}>
                  <Box>
                    <Typography variant="caption" display="block">
                      Address : {branch.branchAddress}
                    </Typography>
                    <Typography variant="caption" display="block">
                      Landmark : {branch.branchLandmark}
                    </Typography>
                    <Typography variant="caption" display="block">
                      Pincode : {branch.branchPincode}
                    </Typography>
                    <Typography variant="caption" display="block">
                      State : {branch.branchState}
                    </Typography>
                    <Typography variant="caption" display="block">
                      Country : {branch.branchCountry}
                    </Typography>
                  </Box>
                  <Box ml="auto">
                    <IconButton onClick={() => unsetBranchAddress(index)}>
                      <ClearIcon color="primary" />
                    </IconButton>
                  </Box>
                </Box>
              </Paper>
            </Box>
          );
        })}
    </>
  );

  return (
    <>
      {isSectorDialogOpen && sectorIndustry()}
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__basicRight"
      >
        <CardContent style={{ padding: 10 }}>
          <Typography className={classes.cardTypography}>
            Basic Company Details
          </Typography>

          <div className="scroll" style={{ marginTop: 18, height: "63vh" }}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Box my={2}>
                  <CustomTextfield
                    name="compName"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Company Name"
                    value={basicInfo.companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box my={2}>
                  <Typography className={classes.headerText}>
                    Company Logo
                  </Typography>
                </Box>
                <Box my={2}>
                  {basicInfo.companyLogoURI === "" ? (
                    <>
                      <input
                        id="company-logo"
                        type="file"
                        onChange={(event) => handleUploadCompanyLogo(event)}
                        style={{ display: "none" }}
                        cursor="pointer"
                      />
                      <label htmlFor="company-logo">
                        <div className={classes.logoSelecter}>
                          <AddCircleOutlineIcon
                            name="addLogoIcon"
                            fontSize="large"
                            className={classes.addIcon}
                          />
                        </div>
                      </label>
                    </>
                  ) : (
                    <div className={classes.companyLogo}>
                      <img src={basicInfo.companyLogoURI} alt="company-logo" />
                      <IconButton
                        onClick={handleClearCompanyLogo}
                        className={classes.ClearImage}
                      >
                        <CancelIcon
                          style={{ color: "gray" }}
                          fontSize="small"
                        />
                      </IconButton>
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box my={2}>
                  <Typography className={classes.headerText}>
                    Sector & Industry
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} className={classes.addSectorContainer}>
                <CustomButton
                  onClick={() => {
                    setIsSectorDialogOpen(true);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Add Sector
                </CustomButton>
              </Grid>

              <Grid item xs={12} md={8}>
                <Box my={2}>
                  {sectors.length !== 0 ? (
                    sectors.map((sector, idx) => (
                      <div key={idx} style={{ marginBottom: 8 }}>
                        {sector.industry_data.some(
                          (inds) => inds.isSelected
                        ) && (
                          <>
                            <Typography className={classes.selectedSectorTitle}>
                              {sector.Sector}
                            </Typography>
                            {sector.industry_data.map(
                              (industry, index) =>
                                industry.isSelected && (
                                  <Chip
                                    key={index}
                                    className={classes.sectorChip}
                                    label={industry.Industry}
                                  />
                                )
                            )}
                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <Chip className={classes.sectorChip} label="Non Selected" />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box my={2}>
                  <CustomTextfield
                    name="compOperation"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                    size="small"
                    label="Company Operation"
                    value={basicInfo.companyDescription}
                    onChange={(e) => setCompanyDescription(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box my={2}>
                  <CustomTextfield
                    name="NumOfEmployee"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Number of Employees"
                    value={basicInfo.numberOfEmployees}
                    onChange={(e) => setNumberOfEmployee(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box my={2}>
                  <CustomTextfield
                    name="contactEmail"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Contact Email"
                    value={basicInfo.companyEmail}
                    onChange={(e) => setCompanyEmail(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box my={2}>
                  <CustomTextfield
                    name="website"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Website"
                    value={basicInfo.companyWebsite}
                    onChange={(e) => setCompanyWebsite(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={8}>
                <Box my={2}>
                  <Typography className={classes.headerText}>
                    Company Address
                  </Typography>
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="compAddress"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Street/Location"
                    value={basicInfo.companyAddress || ""}
                    onChange={(e) => setCompanyAddress(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <RoomOutlinedIcon fontSize="small" color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    variant="outlined"
                    name="compLandmark"
                    fullWidth
                    size="small"
                    label="Landmark"
                    value={basicInfo.companyLandmark || ""}
                    onChange={(e) => setCompanyLandmark(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="compPincode"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Pincode"
                    type="number"
                    value={basicInfo.companyPincode || ""}
                    onChange={(e) => setCompanyPincode(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="compState"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="State"
                    value={basicInfo.companyState || ""}
                    onChange={(e) => setCompanyState(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <Autocomplete
                    name="compCountry"
                    id="countryCode"
                    options={countryList}
                    getOptionLabel={(option) => option}
                    renderOption={(option) => option}
                    size="small"
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Country"
                        variant="outlined"
                      />
                    )}
                    value={basicInfo.companyCountry || ""}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setCompanyCountry(newValue);
                      }
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={8}>
                <Box
                  my={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography className={classes.headerText}>
                    Branch Office Address
                  </Typography>
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="branchAddress"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Street/Location"
                    value={basicInfo.branchAddress || ""}
                    onChange={(e) => setBranchAddress(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <RoomOutlinedIcon fontSize="small" color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="branchLandmark"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Landmark"
                    value={basicInfo.branchLandmark || ""}
                    onChange={(e) => setBranchLandmark(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="branchPincode"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Pincode"
                    type="number"
                    value={basicInfo.branchPincode || ""}
                    onChange={(e) => setBranchPincode(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="branchState"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="State"
                    value={basicInfo.branchState || ""}
                    onChange={(e) => setBranchState(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <Autocomplete
                    name="branchCountry"
                    id="countryCode"
                    options={countryList}
                    getOptionLabel={(option) => option}
                    renderOption={(option) => option}
                    size="small"
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Country"
                        variant="outlined"
                      />
                    )}
                    value={basicInfo.branchCountry || ""}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setBranchCountry(newValue);
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4} className={classes.addBranchContainer}>
                <CustomButton
                  name="addBranch"
                  size="medium"
                  onClick={handleAddBranch}
                  variant="outlined"
                  color="primary"
                >
                  Add Another Branch
                </CustomButton>
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={9}>
                {LocationStore}
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
