import React from "react";
import debounce from "lodash.debounce";

// mui
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

// components
import Textfield from "./textfield";
import surveyListingUseStyles from "../../screen/surveyListing/surveyListingStyles";

function SearchTextField({ searchClick }) {
  const classes = surveyListingUseStyles();

  const [searchChange, setSearchChange] = React.useState("");

  // Debounce the searchClick function
  const debouncedSearchClick = React.useMemo(
    () => debounce((query) => searchClick(query), 800), // Debounce delay set to 500 milliseconds
    [searchClick]
  );

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchChange(newValue);
    debouncedSearchClick(newValue);
  };

  return (
    <Textfield
      variant={"outlined"}
      color={"primary"}
      label={"Search"}
      type={"text"}
      value={searchChange}
      fullWidth
      size={"small"}
      onChange={handleSearchChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!searchChange}
              onClick={() => searchClick(searchChange)}
            >
              <SearchIcon className={classes.SearchIcon} size="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default React.memo(SearchTextField);
