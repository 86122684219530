import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import BasicInformationLeft from '../widgets/basicInformationLeft/basicInformationLeft';
import BasicInformationRisght from '../widgets/basicInformationRight/basicInformationRight';

function BasicInformation (props) {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    props.getAssessmentDetails(
      props.login.token,
      props.assessment_id,
      '1'
    );


    function handleWidth() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWidth);

    return () => {
      // cancel the subscription
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 992) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [windowWidth]);

  
  
    return (
      <Grid container spacing={2}    style={{display:'flex',  height:windowWidth >= 992 ? "76vh" : "91%", padding:10}}>
        <Grid item xs={12} sm={11} md={6}  style={{height: windowWidth >= 992 ? "100%" : "50%" }}>
          <BasicInformationLeft {...props} />
        </Grid>
        <Grid item xs={12} sm={11} md={6}  style={{height: windowWidth >= 992 ? "100%" : "50%" }}>
          <BasicInformationRisght {...props} />
        </Grid>
      </Grid>
    ) 
  }
  export default React.memo(BasicInformation);
