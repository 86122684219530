import { makeStyles } from "@material-ui/core/styles";
export const basicInformationTabStyles = makeStyles((theme) => ({
    cardContainerDefault: {
      width: "100%",
      height: "100px",
      background: "#FDFDFD",
      border: "1px solid #C0D4E9",
      borderRadius: "8px",
      marginBottom: "16px",
    },
  
    mainHeadingDefault: {
      fontWeight: 500,
      fontSize: "16px",
      color: "#242424",
      lineHeight: "24px",
      letterSpacing: "0.0015em",
      marginBottom: 8,
    },
  
    subHeadingDefault: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#BBBBBB",
      letterSpacing: "0.0025em",
    },
  
    cardContent: {
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      cursor: "pointer",
    },
  }));