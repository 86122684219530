import { Bar } from "react-chartjs-2";

const BarChart = ({ data }) => {
  const chartData = {
    labels: data?.map((item) => item.month),
    datasets: [
      {
        label: "Document Count",
        data: data?.map((item) => item.doc_count),
        backgroundColor: "#A1BFDF",
        borderWidth: 1,
        barThickness: 30,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Document Count by Month",
      },
    },
  };

  return (
    <div style={{ height: "100%" }}>
      <Bar data={chartData} options={options} height={200} />
    </div>
  );
};

export default BarChart;
