import React from "react";
const OptimizeInitialRender = ({ chunkSize, children }) => {
  const [renderedItemsCount, setRenderedItemsCount] = React.useState(chunkSize);

  const childrenArray = React.useMemo(
    () => React.Children.toArray(children),
    [children]
  );

  React.useEffect(() => {
    setRenderedItemsCount(chunkSize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenArray.length]);

  React.useEffect(() => {
    if (renderedItemsCount < childrenArray.length) {
      window.requestIdleCallback(
        () => {
          setRenderedItemsCount(
            Math.min(renderedItemsCount + chunkSize, childrenArray.length)
          );
        },
        { timeout: 200 }
      );
    }
  }, [renderedItemsCount, childrenArray.length, chunkSize]);

  return childrenArray.slice(0, renderedItemsCount);
};

export default OptimizeInitialRender;