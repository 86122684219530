import React from 'react';
import { connect } from 'react-redux';
import AssesmentApprovalReport from '../../components/widgets/reportListing/assessmentApproval/assessmentApprovalReport';
import {
  getAssessmentData,
  updateDefineMateriality,
  updateDefineObjective,
  setCompanyLocation,
  setCompanyPincode,
  setCompanyAddress,
  setCompanyGeographic,
  setCompanyCapacity,
  setCompanyStartDate,
  setCompanyWebsite,
  setCompanyName,
  updateAssessmentApproval,
  setAllCompanyAddress,
  setBusinessWebsiteAddress,
  setNumberOfEmployee,
  setBusinessGeographic,
  setBusinessCapacityType,
  updateReportName,
  setCompanyEmail,
  setCompanyAddressWebsite,
  setCMPName,
  setCMPAddress,
  setCMPPincode,
  setCMPIndustry,
  setCMPPermanentEmployee,
  setCMPContractEmployee,
  setCMPCity,
  setCMPCountry,
  setAllLocation,
  setCompanyDescription,
  setSector,
  setIndustry,
  getCommentsToc,
  getAssessmentPdf,
  setPdfBlob,
  setPdfProgress
} from '../../actions/assessmentApproval/assessmentApproval';
import {
  getIndustries,
  getFrameworks
} from '../../actions/sustainabilityReport/sustainabilityReportAction';

class AssesmentApprovalReportCont extends React.PureComponent {
  render() {
    return <AssesmentApprovalReport {...this.props} />;
  }
}

const mapStateToProps = (store) => ({
  login: store.login,
  materialityAssessment: store.materialityAssessment,
  assesmentApproval: store.assesmentApproval,
  basicInfo: store.basicInfo,
  loadingBar: store.loadingBar
});

const mapDispatchToProps = (dispatch) => ({
  updateReportName: (token, assessment_id, step_no, report_name) => {
    dispatch(updateReportName(token, assessment_id, step_no, report_name));
  },
  getIndustries: (sector_id) => {
    dispatch(getIndustries(sector_id));
  },
  setBusinessWebsiteAddress: (payload) => {
    dispatch(setBusinessWebsiteAddress(payload));
  },
  setCompanyEmail: (payload) => {
    dispatch(setCompanyEmail(payload));
  },
  setCompanyAddressWebsite: (payload) => {
    dispatch(setCompanyAddressWebsite(payload));
  },
  setNumberOfEmployee: (payload) => {
    dispatch(setNumberOfEmployee(payload));
  },
  setBusinessGeographic: (payload) => {
    dispatch(setBusinessGeographic(payload));
  },
  setBusinessCapacityType: (payload) => {
    dispatch(setBusinessCapacityType(payload));
  },
  setAllCompanyAddress: (payload) => {
    dispatch(setAllCompanyAddress(payload));
  },
  setAllLocation: (payload) => {
    dispatch(setAllLocation(payload));
  },
  updateAssessmentApproval: (assessment_id, assesmentApproval, token) => {
    dispatch(updateAssessmentApproval(assessment_id, assesmentApproval, token));
  },
  setCompanyName: (payload) => {
    dispatch(setCompanyName(payload));
  },
  setCompanyGeographic: (payload) => {
    dispatch(setCompanyGeographic(payload));
  },
  setCompanyCapacity: (payload) => {
    dispatch(setCompanyCapacity(payload));
  },
  setCompanyStartDate: (payload) => {
    dispatch(setCompanyStartDate(payload));
  },
  setCompanyWebsite: (payload) => {
    dispatch(setCompanyWebsite(payload));
  },
  setCompanyLocation: (payload) => {
    dispatch(setCompanyLocation(payload));
  },
  setCompanyPincode: (payload) => {
    dispatch(setCompanyPincode(payload));
  },
  setCompanyAddress: (payload) => {
    dispatch(setCompanyAddress(payload));
  },
  updateDefineObjective: (payload) => {
    dispatch(updateDefineObjective(payload));
  },
  updateDefineMateriality: (payload) => {
    dispatch(updateDefineMateriality(payload));
  },
  getAssessmentData: (token, assessment_id) => {
    dispatch(getAssessmentData(token, assessment_id));
  },
  setCMPName: (payload) => {
    dispatch(setCMPName(payload));
  },
  setCMPAddress: (payload) => {
    dispatch(setCMPAddress(payload));
  },
  setCMPPincode: (payload) => {
    dispatch(setCMPPincode(payload));
  },
  setCMPIndustry: (payload) => {
    dispatch(setCMPIndustry(payload));
  },
  setCMPPermanentEmployee: (payload) => {
    dispatch(setCMPPermanentEmployee(payload));
  },
  setCMPContractEmployee: (payload) => {
    dispatch(setCMPContractEmployee(payload));
  },
  setCMPCity: (payload) => {
    dispatch(setCMPCity(payload));
  },
  setCMPCountry: (payload) => {
    dispatch(setCMPCountry(payload));
  },
  setCompanyDescription: (payload) => {
    dispatch(setCompanyDescription(payload));
  },
  setSector: (payload) => {
    dispatch(setSector(payload));
  },
  setIndustry: (payload) => {
    dispatch(setIndustry(payload));
  },
  getFrameworks: (token) => {
    dispatch(getFrameworks(token));
  },
  getCommentsToc: (token, assessment_id, step_no) => {
    dispatch(getCommentsToc(token, assessment_id, step_no));
  },
  getAssessmentPdf: (token, assessment_id, jwt_hash, step_no) => {
    dispatch(getAssessmentPdf(token, assessment_id, jwt_hash, step_no));
  },
  setPdfBlob: (payload) => {
    dispatch(setPdfBlob(payload));
  },
  setPdfProgress: (payload) => {
    dispatch(setPdfProgress(payload));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssesmentApprovalReportCont);
