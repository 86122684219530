import { Box, Chip } from "@material-ui/core";

import CustomTextfield from "../../../UI/textfield/textfield";
import VendorLogo from "./DrawerComps/VendorLogo";
import VendorSector from "./DrawerComps/VendorSector";

const Step1Drawer = ({ vendorDetails, setVendorDetails }) => {
  return (
    // Main container
    <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {/* Vendor name and type  */}
     
        <Box >
          <label htmlFor="vendName">Supplier Name</label>
          <CustomTextfield
            id="vendName"
            name="vendName"
            variant="outlined"
            fullWidth
            size="small"
            // label="Vendor Name"
            onChange={(e) => {
              setVendorDetails((prev) => ({
                ...prev,
                vendorName: e.target.value,
              }));
            }}
          />
        </Box>
        {/* <Box>
          <label htmlFor="compType">Vendor Type</label>
          <Box
            id="compType"
            style={{ display: "flex", gap: "8px", marginTop: 5 }}
          >
            {["New Vendor", "Existing Vendor"].map((type) => (
              <Chip
                key={type}
                label={type}
                color={
                  vendorDetails.vendorType === type ? "primary" : "default"
                }
                clickable
                onClick={() => {
                  setVendorDetails((prev) => ({
                    ...prev,
                    vendorType: type,
                  }));
                }}
              />
            ))}
          </Box>
        </Box> */}

      {/* Vendor Website */}
      <Box >
        <label htmlFor="compWebsite">Supplier Website</label>
        <CustomTextfield
          id="compWebsite"
          name="compWebsite"
          variant="outlined"
          fullWidth
          size="small"
          onChange={(e) => {
            setVendorDetails((prev) => ({
              ...prev,
              vendorWebsite: e.target.value,
            }));
          }}
        />
      </Box>

      {/* Vendor Logo */}
      <VendorLogo setVendorDetails={setVendorDetails} />

      {/* Vendor Sector and Industry */}
      <VendorSector />
    </Box>
  );
};
export default Step1Drawer;
