import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Box,
  Avatar,
  Typography,
} from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import { IoIosArrowForward } from "react-icons/io";

import DynamicTable, {
  statusStyle,
  textStyle,
  useTableStyles,
} from "../../../UI/dynamicTable/DynamicTable";
import {
  selectedTaskData,
  selectedTaskHeadings,
  taskHeadings,
} from "../../../screen/taskScreen/taskTemp";
import { useDispatch, useSelector } from "react-redux";
import {
  SetSelectedSection,
  setTaskSelectedAssignSurvey,
} from "../../../../actions/taskActions/TaskAction";
import NoData from "../../NoData/NoData";
import moment from "moment";

const noBgStatusStyle = (status) => {
  let colorToUse = "#FFC250";

  if (status === "complete") {
    colorToUse = "#5BB37F";
  }

  return {
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    gap: "4px",
  };
};

const AssignedTab = () => {
  const { taskAssignSurveyList } = useSelector((state) => state?.taskReducer);

  const classes = useTableStyles();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  // Grouping tasks based on date
  const groupedTasks = selectedTaskData.reduce((acc, curr) => {
    acc[curr.date] = [...(acc[curr.date] || []), curr];
    return acc;
  }, {});

  const handleRowSelect = (row) => {
    dispatch(setTaskSelectedAssignSurvey(row));
    if (row?.survey_name) {
      history.push(
        `${location.pathname}/${row.survey_name.replace(/ /g, "_")}`
      );
    } else {
      history.push(`${location.pathname}/${row?.assignment_type}`);
    }
  };

  return (
    <Box>
      {taskAssignSurveyList.length > 0 && (
        <DynamicTable headings={selectedTaskHeadings}>
          {taskAssignSurveyList.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <TableRow>
                <TableCell style={{ border: "none" }} colSpan={6}>
                  {row?.date && (
                    <Typography
                      style={{
                        color: "#15314E",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {row?.date}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
              {row.surveys.map((rowData, idx) => {
                return (
                  <TableRow
                    key={idx}
                    className={classes.tableRowHover}
                    onClick={() => handleRowSelect(rowData)}
                  >
                    <TableCell style={{ ...textStyle("main") }}>
                      {rowData?.survey_name || "Data Request"}
                    </TableCell>
                    <TableCell style={{ ...textStyle() }}>
                      <Box>
                        <DotIcon
                          style={{
                            widht: "10px",
                            height: "10px",
                            color: "#FFCC66",
                          }}
                        />
                        {rowData.frequency_type}
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...textStyle() }}>
                      <Box>
                        <DotIcon
                          style={{
                            widht: "10px",
                            height: "10px",
                            color: "#FFCC66",
                          }}
                        />
                        {rowData.role === 0 ? "Contributor" : "Approval"}
                      </Box>
                    </TableCell>
                    {/*   <TableCell>
                      <Box
                        style={{ ...statusStyle(rowData.status.toLowerCase()) }}
                      >
                        <DotIcon style={{ widht: "10px", height: "10px" }} />
                        {rowData.status}
                      </Box>
                    </TableCell> */}
                    <TableCell style={{ ...textStyle() }}>
                      {moment(rowData.assigned_on).format("DD.MM.YY")}
                    </TableCell>
                    <TableCell style={textStyle()}>
                      <Box
                        display={"flex"}
                        justifyContent={"flex-start"}
                        sx={{ gap: 10 }}
                      >
                        <Avatar
                          src={
                            rowData?.profile_img?.url ||
                            rowData?.profile_img?.name
                          }
                          alt={rowData?.profile_img?.name}
                        />
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography style={textStyle("main")}>
                            {rowData?.assigned_by}
                          </Typography>
                          <Typography
                            style={{
                              color: "#AFAFAF",
                              fontWeight: 500,
                              fontSize: "11px",
                              lineHeight: "16px",
                            }}
                          >
                            {rowData?.designation}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </React.Fragment>
          ))}
        </DynamicTable>
      )}
      {taskAssignSurveyList.length === 0 && <NoData />}
      {/* <DynamicTable headings={selectedTaskHeadings}>
        {Object.entries(groupedTasks).map(([date, tasks]) => (
          <React.Fragment key={date}>
            <TableRow>
              <TableCell
                colSpan={7}
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#15314E",
                }}
              >
                {date}
              </TableCell>
            </TableRow>
            {tasks.map((rowData, index) => (
              <TableRow
                key={index}
                className={classes.tableRowHover}
                onClick={async () => {
                  await dispatch(
                    SetSelectedSection(rowData._id, rowData.action_required)
                  );
                  history.push(
                    `${location.pathname}/${rowData.task_type.replace(
                      / /g,
                      "_"
                    )}`
                  );
                }}
              >
                <TableCell style={textStyle("main")}>
                  {rowData.task_type}
                </TableCell>
                <TableCell>
                  <Box
                    style={noBgStatusStyle(
                      rowData.survey_frequency.toLowerCase()
                    )}
                  >
                    <DotIcon style={{ widht: "10px", height: "10px" }} />
                    <Typography style={textStyle("main")}>
                      {rowData.survey_frequency}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    style={noBgStatusStyle(
                      rowData.action_required.toLowerCase()
                    )}
                  >
                    <DotIcon style={{ widht: "10px", height: "10px" }} />
                    <Typography style={textStyle("main")}>
                      {rowData.action_required}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box style={statusStyle(rowData.status.toLowerCase())}>
                    <DotIcon style={{ widht: "10px", height: "10px" }} />
                    {rowData.status}
                  </Box>
                </TableCell>
                <TableCell style={textStyle()}>{rowData.sent_on}</TableCell>
                <TableCell style={textStyle()}>
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    sx={{ gap: 10 }}
                  >
                    <Avatar
                      src={
                        rowData.assigned_by.avatar || rowData.assigned_by.name
                      }
                      alt={rowData.assigned_by.name}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography style={textStyle("main")}>
                        {rowData.assigned_by.name}
                      </Typography>
                      <Typography
                        style={{
                          color: "#AFAFAF",
                          fontWeight: 500,
                          fontSize: "11px",
                          lineHeight: "16px",
                        }}
                      >
                        {rowData.assigned_by.designation}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell style={textStyle()}>
                  <IoIosArrowForward color="#374151" size={20} />
                </TableCell>
              </TableRow>
            ))}
          </React.Fragment>
        ))}
      </DynamicTable> */}
    </Box>
  );
};
export default AssignedTab;
