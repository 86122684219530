import { useState } from "react";

import CustomCheckBox from "../../../../../UI/checkBox/checkBox";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import briefcase from "../../../../../../images/briefcase.png";

import { Grid, Typography } from "@material-ui/core";
export const UserCard = ({ member, onClick, hideCheckbox = false }) => {
  const { name, email, role, designation } = member;
  const [checked, setChecked] = useState(false);

  const hadleClick = () => {
    setChecked(!checked);
    onClick();
  };
  return (
    <Grid
      container
      item
      md={12}
      onClick={hadleClick}
      style={{
        border: "1px solid #E0E0E0",
        borderRadius: 8,
        margin: "3px 0px",
        width: "100%",
        padding: 4,
        cursor: "pointer",
        backgroundColor: checked ? "#EBF1F8" : "",
      }}
      role="button"
    >
      <Grid item container md={12} style={{ width: "100%" }}>
        <Grid item md={10} container alignItems="center">
          {!hideCheckbox && (
            <CustomCheckBox
              color="secondary"
              checked={checked}
              disabled={false}
            />
          )}
          <Typography
            style={{
              fontSize: 13,
              fontWeight: 600,
              padding: hideCheckbox ? "3px 10px" : "3px 0px",
            }}
          >
            {name}
          </Typography>
        </Grid>
        {/* <Grid md={2} container alignItems="center" justifyContent="center">
          <img
            src={briefcase}
            alt="brief"
            style={{ color: "#505050", width: 22 }}
          />
          <Typography
            style={{ fontSize: 12, fontWeight: 400, padding: "3px 8px" }}
          >
            INF241
          </Typography>
        </Grid> */}
      </Grid>
      <Grid
        container
        item
        md={12}
        style={{ width: "100%", padding: "3px 0px" }}
      >
        <Grid item md={4} container alignItems="center">
          <MailOutlineIcon style={{ color: "#505050", marginLeft: "8px" }} />
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 400,
              padding: "3px 0px",
              color: "#505050",
              marginLeft: "8px",
            }}
          >
            {email}
          </Typography>
        </Grid>
        <Grid item md={4} container alignItems="center">
          <img
            src={briefcase}
            alt="brief"
            style={{ color: "#505050", width: 22 }}
          />
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 400,
              padding: "3px 0px",
              color: "#505050",
              marginLeft: "8px",
            }}
          >
            {role || designation}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
