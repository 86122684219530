import { makeStyles } from "@material-ui/core";

const getStartedStyles = makeStyles({
    sideBarTitle: {
      fontSize: 24,
      lineHeight: "36px",
      color: "#FFFFFF",
      fontWeight: "600",
      paddingTop: "16px",
      paddingBottom: "24px",
      textTransform: "capitalize",
    },
    sideBarSubTitle: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: "500",
      color: "#FFFFFF",
      textTransform: "capitalize",
    },
    mainDiv:{ overflowy: "scroll" },
    mainDiv1:{
        display: "flex",
        position: "fixed",
        marginLeft: "25px",
        marginTop: "25px",
        zIndex: "100",
        width:"13%"
      },
     mainGrid: {
        overflow: "hidden",
        backgroundColor: "#F5F6F8",
      },
     grid: { marginTop: "20px" },
     grid1: { marginTop: "25px" },
     grid2: { marginTop: "65px" },
typo:{ color: "white" },
Button:{
    textTransform: "capitalize",
    padding: "10px 12px",
    fontWeight: 600,
  },
  img1:{
    position: "absolute",
    top: 500,
    left: 200,
    width: "70px",
  },
  img2:{
    position: "absolute",
    top: 120,
    right: 200,
    width: "70px",
  },
  img3:{
    width: "88px",
    height: "88px",
  },
  grid3:{
    marginTop: "86px",
    flexDirection: "column",
  },
  grid4:{ marginTop: "auto" },
  grid5:{ alignItems: "center", padding: "5%" },
  ul:{
                        
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-evenly",
    textDecoration: "none",
    listStyle: "none",
    fontSize: 14,
   
  },link:{ textDecoration: "none", color: "#ffff !important", },


  });

  export default getStartedStyles;