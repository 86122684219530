/* eslint-disable no-useless-concat */
const createCubeJSSchema = ({
  index,
  kpiName,
  chartYCustom,
  measureField,
  subMeasureField,
  dimensionField,
  CURRENT_ORGANIZATION,
}) => {
  // example if measure = HC_percentage_of_workers_covered then subMeasureField = percentage_measure
  if (!subMeasureField || !dimensionField) {
    const shortName = measureField.split("_")[1];
    subMeasureField = `${shortName}_measure`;
    dimensionField = `${shortName}_dimension`;
  }
  const sampleCube = {
    id: index,
    name: kpiName,

    vizState: {
      query: {
        limit: 5000,
        measures: [`${measureField}.${subMeasureField}`],
        order: {
          [`${measureField}.date`]: "asc",
        },
        dimensions: [`${measureField}.${dimensionField}`],
        filters: [
          {
            member: `${measureField}.organisationid`,
            operator: "equals",
            values: [CURRENT_ORGANIZATION],
          },
        ],
        timeDimensions: [
          {
            dimension: `${measureField}.date`,
            granularity: "month",
          },
        ],
      },
      chartType: "line",
    },

    pivotConfig: {
      x: [`${measureField}.${dimensionField}`],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },

    chartX_AxisCustomize: {
      display: true,
      text: "Time",
      align: "center",
      color: "#242424",
      font: {
        size: 15,
        family: "Poppins",
      },
    },
    chartY_AxisCustomize: {
      display: true,
      text: `${chartYCustom}`,
      align: "center",
      color: "#242424",
      font: {
        size: 15,
        family: "Poppins",
      },
    },
  };
  return sampleCube;
};

const handleKpiDataWithOrganisation = (CURRENT_ORGANIZATION) => {
  const KPI_DATA = {
    Waste: [
      // section_c_principle_6_e_8_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Plastic",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_plastic",
        subMeasureField: "total_plastic",
        dimensionField: "total_plastic_waste",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total E-Waste",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_ewaste",
        subMeasureField: "total_ewaste",
        dimensionField: "total_ewaste_waste",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Biomedical",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_biomedical",
        subMeasureField: "total_biomedical",
        dimensionField: "total_biomedical_waste",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Construction and Demolition",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_const_and_demo",
        subMeasureField: "total_const_and_demo",
        dimensionField: "total_const_and_demo_waste",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Battery",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_battery",
        subMeasureField: "total_battery",
        dimensionField: "total_battery_waste",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Radioactive",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_radioactive",
        subMeasureField: "total_radioactive",
        dimensionField: "total_radioactive_waste",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Other Hazardous",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_other_hazardous",
        subMeasureField: "total_other_haza",
        dimensionField: "total_other_hazar_waste",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Other NonHazardous",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_other_nhazardous",
        subMeasureField: "total_other_nhaza",
        dimensionField: "total_other_nhaza_waste",
      }),

      // ----------------------------------------

      // section_c_principle_6_e_8_b_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY Total Waste Recovered - Recycled",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "cfy_total_waste_recovered_through_recycling",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY Total Waste Recovered - Re-used",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "cfy_total_waste_recovered_through_reusing",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY Total Waste Recovered - Other recovery operations",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "cfy_total_waste_recovered_through_othr_rec",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY Total Waste Recovered",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "cfy_total_waste_recovered",
      }),

      // ----------------------------------------

      // section_c_principle_6_e_8_c_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Waste Disposed - Incineration ",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "total_waste_disposed_by_incineration",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Waste Disposed - Landfilling",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "total_waste_disposed_by_landfilling",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Waste Disposed - Other disposal operations",
        chartYCustom: "Metric Tons",
        measureField:
          "Env_" + "total_waste_disposed_by_other_disposal_operations",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Waste Disposed",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "total_waste_disposed",
      }),

      // ----------------------------------------

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Hazardous",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_hazardous",
        subMeasureField: "total_haza",
        dimensionField: "total_haza_waste",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Quantity Recycled",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_quantity_recycled",
        subMeasureField: "total_quantity",
        dimensionField: "total_quantity_recycled",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Quantity Reused",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_quantity_reused",
        subMeasureField: "total_quantity",
        dimensionField: "total_quantity_reused",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Quantity Safely Disposed",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_quantity_safeldisposed",
        subMeasureField: "total_quantity",
        dimensionField: "total_safeldisposed",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Plastic Waste Recycling",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_plastic_waste_recycling",
        subMeasureField: "total_p",
        dimensionField: "total_w",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total E-Waste Recycling",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_e_waste_recycling",
        subMeasureField: "total_e",
        dimensionField: "total_e_waste",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Hazardous Waste Recycling",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_hazardous_waste_recycling",
        subMeasureField: "total_h",
        dimensionField: "total_h_waste",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Other Hazardous Waste Recycling",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "Total_others_waste_recycling",
        subMeasureField: "total_other",
        dimensionField: "total_other_waste",
      }),
    ],
    Energy: [
      // section_c_principle_6_l_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Electricity Consumption Renewable",
        chartYCustom: "Kilowatt-hours (kWh)",
        measureField: "Env_" + "total_elec_cons_r",
        subMeasureField: "total_elec",
        dimensionField: "total_elec_cons_r_loc",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Fuel Consumption Renewable",
        chartYCustom: "Gallons (gal)",
        measureField: "Env_" + "total_fuel_cons_from_r",
        subMeasureField: "total_fuel",
        dimensionField: "total_fuel_cons_r_loc",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Other Energy Consumption Renewable",
        chartYCustom: "Terajoules (TJ)",
        measureField: "Env_" + "total_energy_con_r_other",
        subMeasureField: "total_energy",
        dimensionField: "total_energy_r_other_loc",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Energy Renewable",
        chartYCustom: "MegaJoules (MJ)",
        measureField: "Env_" + "total_energy_r_loc",
        subMeasureField: "total_energy",
        dimensionField: "total_energy_r_loc",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Electricity Consumption Non-Renewable",
        chartYCustom: "Kilowatt-hours (kWh)",
        measureField: "Env_" + "total_elec_cons_nr",
        subMeasureField: "total_elec",
        dimensionField: "total_elec_cons_nr_loc",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Fuel Consumption Non-Renewable",
        chartYCustom: "Gallons (gal)",
        measureField: "Env_" + "total_fuel_cons_from_nr",
        subMeasureField: "total_fuel",
        dimensionField: "total_fuel_cons_nr_loc",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Other Energy Consumption Non-Renewable",
        chartYCustom: "Terajoules (TJ)",
        measureField: "Env_" + "total_energy_con_nr_other",
        subMeasureField: "total_energy",
        dimensionField: "total_energy_nr_other_loc",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Energy Non-Renewable",
        chartYCustom: "MegaJoules (MJ)",
        measureField: "Env_" + "total_energy_nr_loc",
        subMeasureField: "total_energy",
        dimensionField: "total_energy_nr_loc",
      }),

      // ----------------------------------------

      // section_c_principle_6_e_1_a_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Electricity Consumption",
        chartYCustom: "Joules",
        measureField: "Env_" + "total_electricity_consumption",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Fuel Consumption",
        chartYCustom: "Joules",
        measureField: "Env_" + "total_fuel_consumption",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Energy Consumption",
        chartYCustom: "Joules",
        measureField: "Env_" + "total_energy_consumption",
      }),

      // ----------------------------------------
    ],

    "Greenhouse Gas Emission": [
      // section_c_p6_e_6a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total S1 Emission",
        chartYCustom: "Metric Tons of CO2 equivalent",
        measureField: "Env_" + "total_scope_1_emissions",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total S2 Emission",
        chartYCustom: "Metric Tons of CO2 equivalent",
        measureField: "Env_" + "total_scope_2_emissions",
      }),

      // ----------------------------------------

      // section_c_principle_6_e_5_a_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total NOx Emission",
        chartYCustom: "Metric Tons of NOx equivalent",
        measureField: "Env_" + "total_nox_emissions",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total SOx Emission",
        chartYCustom: "Metric Tons of SOx equivalent",
        measureField: "Env_" + "total_sox_emissions",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total PM Emission",
        chartYCustom: "Metric Tons of PM equivalent",
        measureField: "Env_" + "total_pm_emissions",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total POP Emission",
        chartYCustom: "Metric Tons of POP equivalent",
        measureField: "Env_" + "total_pop_emissions",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total VOC Emission",
        chartYCustom: "Metric Tons of VOC equivalent",
        measureField: "Env_" + "total_voc_emissions",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total HAP Emission",
        chartYCustom: "Metric Tons of HAP equivalent",
        measureField: "Env_" + "total_hap_emissions",
      }),

      // ----------------------------------------
    ],
    Water: [
      // section_c_principle_6_l_2_a_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Surface Water Discharged - Without Treatment",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "total_water_dschrgd_surfacewater_notreatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Surface Water Discharged - With Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "total_water_discharged_surfacewater_withtreatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Groundwater Discharged - Without Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "total_water_dschgd_groundwater_withouttreatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Groundwater Discharged - With Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "total_water_discharged_groundwater_withtreatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Seawater Discharged - Without Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "total_water_discharged_seawater_withouttreatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Seawater Discharged - With Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "total_water_discharged_to_seawater_with_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Thirdparties Discharged - Without Treatment",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "total_water_dschrgd_thirdparties_notreatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Thirdparties Discharged - With Treatment",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "total_water_dschgd_thirdpartie_withtreatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Other Discharged - Without Treatment",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "total_water_dschrgd_other_without_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Other Discharged - With Treatment",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "total_water_dschrgd_other_with_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Water Discharged",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "total_water_discharged",
      }),

      // ----------------------------------------

      // section_c_principle_6_l_3_a_type_3 section_c_principle_6_e_3_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Surface Water Withdrawal",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "water_wtd_from_surface_water_src",
        subMeasureField: "total_water",
        dimensionField: "total_surface_water",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Groundwater Withdrawal",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "water_wtd_from_gwater_src",
        subMeasureField: "total_water",
        dimensionField: "total_gwater_wtd",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Third Party Water Withdrawal",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "water_wtd_from_tpwater_src",
        subMeasureField: "total_water",
        dimensionField: "total_tpwater",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Seawater Withdrawal",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "water_wtd_from_seawater",
        subMeasureField: "total_water",
        dimensionField: "total_sea_water",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Other Sources Water Withdrawal",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "water_wtd_from_other_src",
        subMeasureField: "total_water",
        dimensionField: "total_other_water",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Volume of Water Withdrawal",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "Total_volume_of_water_withdrawal",
        subMeasureField: "total_water",
        dimensionField: "total_water_withdrawal",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Volume of Water Consumption",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "Total_volume_of_water_consumption",
        subMeasureField: "total_water",
        dimensionField: "total_water_consumption",
      }),

      // ----------------------------------------

      // section_c_p6_l_3b_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Water Discharged into Surface Water - Without Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "cfy_total_water_dschrgd_into_surfacewater_no_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Water Discharged into Surface Water - With Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "cfy_total_water_dschrgd_into_surfacewater_with_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Water Discharged into Groundwater - Without Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "cfy_total_water_dschrgd_into_groundwater_no_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Water Discharged into Groundwater - With Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "cfy_total_water_dschrgd_into_groundwater_with_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Water Discharged into Seawater - Without Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "cfy_total_water_dschrgd_into_seawater_no_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Water Discharged into Seawater - With Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "cfy_total_water_dschrgd_into_seawater_with_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Water Discharged into Third Party - Without Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "cfy_total_water_dschrgd_into_thirdparties_no_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Water Discharged into Third Party - With Treatment",
        chartYCustom: "Kiloliters",
        measureField:
          "Env_" + "cfy_total_water_dschrgd_into_thirdparties_with_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Water Discharged into Other - Without Treatment",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "cfy_total_water_dschrgd_others_no_treatment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Water Discharged into Other - With Treatment",
        chartYCustom: "Kiloliters",
        measureField: "Env_" + "cfy_total_water_dschrgd_others_with_treatment",
      }),

      // ----------------------------------------
    ],

    "Workforce Health & safety": [
      // section_c_principle_3_e_1_a_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "total_male_permanent_employees_covered_by_health_insurance_value",
        dimensionField:
          "total_male_permanent_employees_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "total_female_permanent_employees_covered_by_health_insurance_value",
        dimensionField:
          "total_female_permanent_employees_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "total_permanent_employees_covered_by_health_insurance_value",
        dimensionField: "total_permanent_employees_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "total_male_non_permanent_employees_covered_by_health_insurance_value",
        dimensionField:
          "total_male_non_permanent_employees_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "total_female_non_permanent_employees_covered_by_health_insurance_value",
        dimensionField:
          "total_female_non_permanent_employees_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_non_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "total_non_permanent_employees_covered_by_health_insurance_value",
        dimensionField:
          "total_non_permanent_employees_covered_by_health_insurance",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "percentage_male_permanent_employees_covered_by_health_insurance_value",
        dimensionField:
          "percentage_male_permanent_employees_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Female Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "percentage_female_permanent_employees_covered_by_health_insurance_value",
        dimensionField:
          "percentage_female_permanent_employees_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "percentage_permanent_employees_covered_by_health_insurance_value",
        dimensionField:
          "percentage_permanent_employees_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_non_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "percentage_male_non_permanent_employees_covered_by_health_insurance_value",
        dimensionField:
          "percentage_male_non_permanent_employees_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "percentage_female_non_permanent_employees_covered_by_health_insurance_value",
        dimensionField:
          "percentage_female_non_permanent_employees_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_non_permanent_employees_covered_by_health_insurance",
        subMeasureField:
          "percentage_non_permanent_employees_covered_by_health_insurance_value",
        dimensionField:
          "percentage_non_permanent_employees_covered_by_health_insurance",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "total_male_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "total_male_permanent_employees_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Female Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "total_female_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "total_female_permanent_employees_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "total_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "total_permanent_employees_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "total_male_non_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "total_male_non_permanent_employees_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "total_female_non_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "total_female_non_permanent_employees_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_non_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "total_non_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "total_non_permanent_employees_covered_by_accident_insurance",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "percentage_male_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_male_permanent_employees_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Female Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "percentage_female_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_female_permanent_employees_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "percentage_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_permanent_employees_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_non_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "percentage_male_non_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_male_non_permanent_employees_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "percentage_female_non_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_female_non_permanent_employees_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_non_permanent_employees_covered_by_accident_insurance",
        subMeasureField:
          "percentage_non_permanent_employees_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_non_permanent_employees_covered_by_accident_insurance",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "total_male_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "total_male_permanent_employees_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Female Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "total_female_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "total_female_permanent_employees_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "total_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "total_permanent_employees_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "total_male_non_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "total_male_non_permanent_employees_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "total_female_non_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "total_female_non_permanent_employees_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_non_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "total_non_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "total_non_permanent_employees_covered_by_maternity_benefits",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_male_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_male_permanent_employees_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Female Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_female_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_female_permanent_employees_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_permanent_employees_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_non_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_male_non_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_male_non_permanent_employees_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_female_non_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_female_non_permanent_employees_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_non_permanent_employees_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_non_permanent_employees_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_non_permanent_employees_covered_by_maternity_benefits",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "total_male_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "total_male_permanent_employees_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Female Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "total_female_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "total_female_permanent_employees_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "total_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "total_permanent_employees_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "total_male_non_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "total_male_non_permanent_employees_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "total_female_non_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "total_female_non_permanent_employees_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_non_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "total_non_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "total_non_permanent_employees_covered_by_paternity_benefits",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_male_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_male_permanent_employees_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Female Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_female_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_female_permanent_employees_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_permanent_employees_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_non_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_male_non_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_male_non_permanent_employees_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_female_non_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_female_non_permanent_employees_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_non_permanent_employees_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_non_permanent_employees_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_non_permanent_employees_covered_by_paternity_benefits",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "total_male_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "total_male_permanent_employees_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Female Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "total_female_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "total_female_permanent_employees_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "total_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "total_permanent_employees_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "total_male_non_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "total_male_non_permanent_employees_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "total_female_non_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "total_female_non_permanent_employees_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_non_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "total_non_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "total_non_permanent_employees_covered_by_daycare_facilities",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_male_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_male_permanent_employees_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Female Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_female_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_female_permanent_employees_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_permanent_employees_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_non_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_male_non_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_male_non_permanent_employees_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_female_non_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_female_non_permanent_employees_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Employees Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_non_permanent_employees_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_non_permanent_employees_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_non_permanent_employees_covered_by_daycare_facilities",
      }),

      // ----------------------------------------

      // section_c_principle_3_e_1_b_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "total_male_permanent_workers_covered_by_health_insurance_value",
        dimensionField:
          "total_male_permanent_workers_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "total_female_permanent_workers_covered_by_health_insurance_value",
        dimensionField:
          "total_female_permanent_workers_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "total_permanent_workers_covered_by_health_insurance_value",
        dimensionField: "total_permanent_workers_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Non-Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "total_male_non_permanent_workers_covered_by_health_insurance_value",
        dimensionField:
          "total_male_non_permanent_workers_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Non-Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "total_female_non_permanent_workers_covered_by_health_insurance_value",
        dimensionField:
          "total_female_non_permanent_workers_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Non-Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_non_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "total_non_permanent_workers_covered_by_health_insurance_value",
        dimensionField:
          "total_non_permanent_workers_covered_by_health_insurance",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "percentage_of_male_permanent_workers_covered_by_health_insurance_value",
        dimensionField:
          "percentage_of_male_permanent_workers_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Female Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "percentage_of_female_permanent_workers_covered_by_health_insurance_value",
        dimensionField:
          "percentage_of_female_permanent_workers_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "percentage_of_permanent_workers_covered_by_health_insurance_value",
        dimensionField:
          "percentage_of_permanent_workers_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_non_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "percentage_of_male_non_permanent_workers_covered_by_health_insurance_value",
        dimensionField:
          "percentage_of_male_non_permanent_workers_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "percentage_of_female_non_permanent_workers_covered_by_health_insurance_value",
        dimensionField:
          "percentage_of_female_non_permanent_workers_covered_by_health_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Health Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_non_permanent_workers_covered_by_health_insurance",
        subMeasureField:
          "percentage_of_non_permanent_workers_covered_by_health_insurance_value",
        dimensionField:
          "percentage_of_non_permanent_workers_covered_by_health_insurance",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "total_male_permanent_workers_covered_by_accident_insurance_value",
        dimensionField:
          "total_male_permanent_workers_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "total_female_permanent_workers_covered_by_accident_insurance_value",
        dimensionField:
          "total_female_permanent_workers_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "total_permanent_workers_covered_by_accident_insurance_value",
        dimensionField: "total_permanent_workers_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "total_male_non_permanent_workers_covered_by_accident_insurance_value",
        dimensionField:
          "total_male_non_permanent_workers_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "total_female_non_permanent_workers_covered_by_accident_insurance_value",
        dimensionField:
          "total_female_non_permanent_workers_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_non_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "total_non_permanent_workers_covered_by_accident_insurance_value",
        dimensionField:
          "total_non_permanent_workers_covered_by_accident_insurance",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "percentage_of_male_permanent_workers_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_of_male_permanent_workers_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Female Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "percentage_of_female_permanent_workers_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_of_female_permanent_workers_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "percentage_of_permanent_workers_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_of_permanent_workers_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_non_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "percentage_of_male_non_permanent_workers_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_of_male_non_permanent_workers_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "percentage_of_female_non_permanent_workers_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_of_female_non_permanent_workers_covered_by_accident_insurance",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Accident Insurance",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_non_permanent_workers_covered_by_accident_insurance",
        subMeasureField:
          "percentage_of_non_permanent_workers_covered_by_accident_insurance_value",
        dimensionField:
          "percentage_of_non_permanent_workers_covered_by_accident_insurance",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "total_male_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField:
          "total_male_permanent_workers_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "total_female_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField:
          "total_female_permanent_workers_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "total_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField: "total_permanent_workers_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "total_male_non_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField:
          "total_male_non_permanent_workers_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "total_female_non_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField:
          "total_female_non_permanent_workers_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_non_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "total_non_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField:
          "total_non_permanent_workers_covered_by_maternity_benefits",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_of_male_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_of_male_permanent_workers_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Female Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_of_female_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_of_female_permanent_workers_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_of_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_of_permanent_workers_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_non_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_of_male_non_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_of_male_non_permanent_workers_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_of_female_non_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_of_female_non_permanent_workers_covered_by_maternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Maternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_non_permanent_workers_covered_by_maternity_benefits",
        subMeasureField:
          "percentage_of_non_permanent_workers_covered_by_maternity_benefits_value",
        dimensionField:
          "percentage_of_non_permanent_workers_covered_by_maternity_benefits",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "total_male_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField:
          "total_male_permanent_workers_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "total_female_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField:
          "total_female_permanent_workers_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "total_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField: "total_permanent_workers_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "total_male_non_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField:
          "total_male_non_permanent_workers_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "total_female_non_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField:
          "total_female_non_permanent_workers_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_non_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "total_non_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField:
          "total_non_permanent_workers_covered_by_paternity_benefits",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_of_male_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_of_male_permanent_workers_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Female Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_of_female_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_of_female_permanent_workers_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_of_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_of_permanent_workers_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_non_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_of_male_non_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_of_male_non_permanent_workers_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_of_female_non_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_of_female_non_permanent_workers_covered_by_paternity_benefits",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Paternity Benefits",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_non_permanent_workers_covered_by_paternity_benefits",
        subMeasureField:
          "percentage_of_non_permanent_workers_covered_by_paternity_benefits_value",
        dimensionField:
          "percentage_of_non_permanent_workers_covered_by_paternity_benefits",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "total_male_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField:
          "total_male_permanent_workers_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Female Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "total_female_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField:
          "total_female_permanent_workers_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "total_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField: "total_permanent_workers_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "total_male_non_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField:
          "total_male_non_permanent_workers_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "total_female_non_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField:
          "total_female_non_permanent_workers_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Male Non-Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_non_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "total_non_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField:
          "total_non_permanent_workers_covered_by_daycare_facilities",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_of_male_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_of_male_permanent_workers_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Female Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_of_female_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_of_female_permanent_workers_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_of_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_of_permanent_workers_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_non_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_of_male_non_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_of_male_non_permanent_workers_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_of_female_non_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_of_female_non_permanent_workers_covered_by_daycare_facilities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Male Non-Permanent Workers Covered by Day Care Facilities",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_non_permanent_workers_covered_by_daycare_facilities",
        subMeasureField:
          "percentage_of_non_permanent_workers_covered_by_daycare_facilities_value",
        dimensionField:
          "percentage_of_non_permanent_workers_covered_by_daycare_facilities",
      }),

      // ----------------------------------------

      // section_c_principle_3_e_2
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Employees Covered by PF",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_employees_covered_by_pf",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Employees Covered by Gratuity",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_employees_covered_by_gratuity",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Employees Covered by ESI",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_employees_covered_by_esi",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Employees Covered by Other Schemes",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_employees_covered_by_other_schemes",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Workers Covered by PF",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_workers_covered_by_pf",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Workers Covered by Gratuity",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_workers_covered_by_gratuity",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Workers Covered by ESI",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_workers_covered_by_esi",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Workers Covered by Other Schemes",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_workers_covered_by_other_schemes",
      }),

      // ----------------------------------------

      // section_c_p3_e_11_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Employees Lost Time Injury Frequency Rate (LTIFR)",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_emp_ltifr",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Employees Recordable Work-Related Injuries",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_emp_trir",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Employees No. of Fatalities",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_emp_no_of_fatalities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Employees High Consequence Work-Related Injury or Ill-Health",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_emp_no_of_high_consequence_injury",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Workers Lost Time Injury Frequency Rate (LTIFR)",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_workers_ltifr",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Workers Recordable Work-Related Injuries",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_workers_trir",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Workers No. of Fatalities",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_workers_no_of_fatalities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Workers High Consequence Work-Related Injury or Ill-Health",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_workers_no_of_high_consequence_injury",
      }),

      // ----------------------------------------

      //
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Other Board of Directors Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_other_bod_employees",
        subMeasureField: "total_other_bod",
        dimensionField: "total_other_bod_emp",
      }),

      // ----------------------------------------

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "No of Affected Employees/Workers Placed in Suitable Employment Safety Incident",
        chartYCustom: "Count",
        measureField: "HC_" + "no_afc_emp_wor_p_in_s_empt_sft_i",
        subMeasureField: "total_emp",
        dimensionField: "total_no_aff_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "No of Affected Employees/Workers rehabilitated Safety Incident",
        chartYCustom: "Count",
        measureField: "HC_" + "no_afc_emp_wor_rhd_sft_inct",
        subMeasureField: "total_emp_workers",
        dimensionField: "total_no_aff_emp_workers",
      }),

      // ----------------------------------------

      // section_c_principle_3_e_14_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Health and Safety Practices Assured",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_health_safety_practices_assured",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Working Condition Assured",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_working_condition_assured",
      }),

      // ----------------------------------------

      // section_c_principle_3_l_3_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Employees having suffered high consequence workrelated injury/ill-health/fatalities",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_emp_affected",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Workers having suffered high consequence workrelated injury/ill-health/fatalities",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_workers_affected",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Employees/Family Members rehabilitated and placed in suitable employment",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_emp_rehabilitated",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Workers/Family Members rehabilitated and placed in suitable employment",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_workers_rehabilitated",
      }),

      // ----------------------------------------

      // section_c_principle_3_l_5_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Value Chain Partners Accessed on Health & Safety",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_value_chain_partners_accessed_on_hs",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Value Chain Partners Accessed on Working Conditions",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_value_chain_partners_accessed_on_wc",
      }),

      // ----------------------------------------
    ],

    "Training & Awareness Programmes": [
      // section_c_p3_e8_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Employees Trained",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_male_emp_trained",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Employees Trained",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_female_emp_trained",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Employees Trained",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_emp_trained",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Workers Trained",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_male_workers_trained",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Workers Trained",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_female_workers_trained",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Workers Trained",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_workers_trained",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Employees Trained on Health and Safety",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_male_emp_trained_on_health_and_safety",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Employees Trained on Health and Safety",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_total_female_emp_trained_on_health_and_safety",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Employees Trained on Health and Safety",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_emp_trained_on_health_and_safety",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Workers Trained on Health and Safety",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_total_male_workers_trained_on_health_and_safety",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Workers Trained on Health and Safety",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_total_female_workers_trained_on_health_and_safety",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Workers Trained on Health and Safety",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_workers_trained_on_health_and_safety",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Employees Trained on Health and Safety",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_of_male_emp_trained_on_health_and_safety",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Employees Trained on Health and Safety",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_of_female_emp_trained_on_health_and_safety",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Employees Trained on Health and Safety",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_perc_of_emp_trained_on_health_and_safety",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Workers Trained on Health and Safety",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_of_male_workers_trained_on_health_and_safety",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Workers Trained on Health and Safety",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_of_female_workers_trained_on_health_and_safety",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Workers Trained on Health and Safety",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_of_workers_trained_on_health_and_safety",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Employees Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_male_emp_trained_on_skill_upgradation",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Employees Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_total_female_emp_trained_on_skill_upgradation",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Employees Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_emp_trained_on_skill_upgradation",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Workers Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_total_male_workers_trained_on_skill_upgradation",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Workers Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_total_female_workers_trained_on_skill_upgradation",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Workers Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_total_workers_trained_on_skill_upgradation",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Employees Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_of_male_emp_trained_on_skill_upgradation",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Employees Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_of_female_emp_trained_on_skill_upgradation",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Employees Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_perc_of_emp_trained_on_skill_upgradation",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Workers Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_of_male_workers_trained_on_skill_upgradation",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Workers Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_of_female_workers_trained_on_skill_upgradation",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Workers Trained on Skill Upgradation",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_of_workers_trained_on_skill_upgradation",
      }),

      // ----------------------------------------

      // section_c_principle_1_e_1_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Training and Awareness Programmes Held for Board of Directors",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_training_and_awareness_programmes_held_for_bod",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Training and Awareness Programmes Held for Key Managerial Personnel",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_training_and_awareness_programmes_held_for_kmp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Training and Awareness Programmes Held for Others",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_training_and_awareness_programmes_held_for_others",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Training and Awareness Programmes Held for Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_training_and_awareness_programmes_held_for_workmen",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of persons covered by the awareness programmes for Board of Directors",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_covered_bod",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of persons covered by the awareness programmes for Key Managerial Personnel",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_covered_kmp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of persons covered by the awareness programmes for Employees other than BoD and KMPs",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_covered_others",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of persons covered by the awareness programmes for Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_covered_workmen",
      }),

      // ----------------------------------------
    ],

    "Employee Diversity and Inclusion": [
      // section_a_4_18_a_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_permanent_employees",
        subMeasureField: "total_permanent_employees_value",
        dimensionField: "total_permanent_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Non-Permanent Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_non_permanent_employees",
        subMeasureField: "total_non_permanent_employees_value",
        dimensionField: "total_non_permanent_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_employees",
        subMeasureField: "total_employees_value",
        dimensionField: "total_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_permanent_workers",
        subMeasureField: "total_permanent_workers_value",
        dimensionField: "total_permanent_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Non-Permanent Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_non_permanent_workers",
        subMeasureField: "total_non_permanent_workers_value",
        dimensionField: "total_non_permanent_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "total_workers",
        subMeasureField: "total_workers_value",
        dimensionField: "total_workers",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_male_permanent_employees",
        subMeasureField: "total_male_permanent_employees_value",
        dimensionField: "total_male_permanent_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Non-Permanent Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_male_non_permanent_employees",
        subMeasureField: "total_male_non_permanent_employees_value",
        dimensionField: "total_male_non_permanent_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_male_employees",
        subMeasureField: "total_male_employees_value",
        dimensionField: "total_male_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_male_permanent_workers",
        subMeasureField: "total_male_permanent_workers_value",
        dimensionField: "total_male_permanent_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Non-Permanent Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_male_non_permanent_workers",
        subMeasureField: "total_male_non_permanent_workers_value",
        dimensionField: "total_male_non_permanent_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "total_male_workers",
        subMeasureField: "total_male_workers_value",
        dimensionField: "total_male_workers",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Permanent Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_male_permanent_employees",
        subMeasureField: "percentage_of_male_permanent_employees_value",
        dimensionField: "percentage_of_male_permanent_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Non-Permanent Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_male_non_permanent_employees",
        subMeasureField: "percentage_of_male_non_permanent_employees_value",
        dimensionField: "percentage_of_male_non_permanent_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_male_employees",
        subMeasureField: "percentage_of_male_employees_value",
        dimensionField: "percentage_of_male_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Permanent Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_male_permanent_workers",
        subMeasureField: "percentage_of_male_permanent_workers_value",
        dimensionField: "percentage_of_male_permanent_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Non-Permanent Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_male_non_permanent_workers",
        subMeasureField: "percentage_of_male_non_permanent_workers_value",
        dimensionField: "percentage_of_male_non_permanent_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_male_workers",
        subMeasureField: "percentage_of_male_workers_value",
        dimensionField: "percentage_of_male_workers",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Permanent Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_female_permanent_employees",
        subMeasureField: "total_female_permanent_employees_value",
        dimensionField: "total_female_permanent_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Non-Permanent Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_female_non_permanent_employees",
        subMeasureField: "total_female_non_permanent_employees_value",
        dimensionField: "total_female_non_permanent_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_female_employees",
        subMeasureField: "total_female_employees_value",
        dimensionField: "total_female_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Permanent Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_female_permanent_workers",
        subMeasureField: "total_female_permanent_workers_value",
        dimensionField: "total_female_permanent_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Non-Permanent Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_female_non_permanent_workers",
        subMeasureField: "total_female_non_permanent_workers_value",
        dimensionField: "total_female_non_permanent_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "total_female_workers",
        subMeasureField: "total_female_workers_value",
        dimensionField: "total_female_workers",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Permanent Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_female_permanent_employees",
        subMeasureField: "percentage_of_female_permanent_employees_value",
        dimensionField: "percentage_of_female_permanent_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Non-Permanent Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_female_non_permanent_employees",
        subMeasureField: "percentage_of_female_non_permanent_employees_value",
        dimensionField: "percentage_of_female_non_permanent_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_female_employees",
        subMeasureField: "percentage_of_female_employees_value",
        dimensionField: "percentage_of_female_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Permanent Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_female_permanent_workers",
        subMeasureField: "percentage_of_female_permanent_workers_value",
        dimensionField: "percentage_of_female_permanent_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Non-Permanent Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_female_non_permanent_workers",
        subMeasureField: "percentage_of_female_non_permanent_workers_value",
        dimensionField: "percentage_of_female_non_permanent_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_female_workers",
        subMeasureField: "percentage_of_female_workers_value",
        dimensionField: "percentage_of_female_workers",
      }),

      // ----------------------------------------

      // section_a_4_18_b_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" + "total_number_of_permanent_differently_abled_employees",
        subMeasureField: "total_permanent_differently_abled_employees_value",
        dimensionField: "total_permanent_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Non-Permanent Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" + "total_number_of_non_permanent_differently_abled_employees",
        subMeasureField:
          "total_non_permanent_differently_abled_employees_value",
        dimensionField: "total_non_permanent_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Differently Abled Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_differently_abled_employees",
        subMeasureField: "total_differently_abled_employees_value",
        dimensionField: "total_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Differently Abled Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" + "total_number_of_permanent_differently_abled_workers",
        subMeasureField: "total_permanent_differently_abled_workers_value",
        dimensionField: "total_permanent_differently_abled_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Non-Permanent Differently Abled Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" + "total_number_of_non_permanent_differently_abled_workers",
        subMeasureField: "total_non_permanent_differently_abled_workers_value",
        dimensionField: "total_non_permanent_differently_abled_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Differently Abled Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_differently_abled_workers",
        subMeasureField: "total_differently_abled_workers_value",
        dimensionField: "total_differently_abled_workers",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" + "total_number_of_male_permanent_differently_abled_employees",
        subMeasureField:
          "total_male_permanent_differently_abled_employees_value",
        dimensionField: "total_male_permanent_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Non-Permanent Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_differently_abled_employees",
        subMeasureField:
          "total_male_non_permanent_differently_abled_employees_value",
        dimensionField: "total_male_non_permanent_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" + "total_number_of_male_differently_abled_employees",
        subMeasureField: "total_male_differently_abled_employees_value",
        dimensionField: "total_male_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Permanent Differently Abled Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" + "total_number_of_male_permanent_differently_abled_workers",
        subMeasureField: "total_male_permanent_differently_abled_workers_value",
        dimensionField: "total_male_permanent_differently_abled_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Non-Permanent Differently Abled Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_male_non_permanent_differently_abled_workers",
        subMeasureField:
          "total_male_non_permanent_differently_abled_workers_value",
        dimensionField: "total_male_non_permanent_differently_abled_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Differently Abled Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "total_number_of_male_differently_abled_workers",
        subMeasureField: "total_male_differently_abled_workers_value",
        dimensionField: "total_male_differently_abled_workers",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Permanent Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_male_permanent_differently_abled_employees",
        subMeasureField:
          "percentage_of_male_permanent_differently_abled_employees_value",
        dimensionField:
          "percentage_of_male_permanent_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Non-Permanent Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_male_non_permanent_differently_abled_employees",
        subMeasureField:
          "percentage_of_male_non_permanent_differently_abled_employees_value",
        dimensionField:
          "percentage_of_male_non_permanent_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Differently Abled Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_male_differently_abled_employees",
        subMeasureField: "percentage_of_male_differently_abled_employees_value",
        dimensionField: "percentage_of_male_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Permanent Differently Abled Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_male_permanent_differently_abled_workers",
        subMeasureField:
          "percentage_of_male_permanent_differently_abled_workers_value",
        dimensionField:
          "percentage_of_male_permanent_differently_abled_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Non-Permanent Differently Abled Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_male_non_permanent_differently_abled_workers",
        subMeasureField:
          "percentage_of_male_non_permanent_differently_abled_workers_value",
        dimensionField:
          "percentage_of_male_non_permanent_differently_abled_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Male Differently Abled Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_male_differently_abled_workers",
        subMeasureField: "percentage_of_male_differently_abled_workers_value",
        dimensionField: "percentage_of_male_differently_abled_workers",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Permanent Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_permanent_differently_abled_employees",
        subMeasureField:
          "total_female_permanent_differently_abled_employees_value",
        dimensionField: "total_female_permanent_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Non-Permanent Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_differently_abled_employees",
        subMeasureField:
          "total_female_non_permanent_differently_abled_employees_value",
        dimensionField:
          "total_female_non_permanent_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" + "total_number_of_female_differently_abled_employees",
        subMeasureField: "total_female_differently_abled_employees_value",
        dimensionField: "total_female_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Permanent Differently Abled Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" + "total_number_of_female_permanent_differently_abled_workers",
        subMeasureField:
          "total_female_permanent_differently_abled_workers_value",
        dimensionField: "total_female_permanent_differently_abled_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Non-Permanent Differently Abled Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "total_number_of_female_non_permanent_differently_abled_workers",
        subMeasureField:
          "total_female_non_permanent_differently_abled_workers_value",
        dimensionField: "total_female_non_permanent_differently_abled_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Differently Abled Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" + "total_number_of_female_differently_abled_workers",
        subMeasureField: "total_female_differently_abled_workers_value",
        dimensionField: "total_female_differently_abled_workers",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Permanent Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_female_permanent_differently_abled_employees",
        subMeasureField:
          "percentage_of_female_permanent_differently_abled_employees_value",
        dimensionField:
          "percentage_of_female_permanent_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Non-Permanent Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_differently_abled_employees",
        subMeasureField:
          "percentage_of_female_non_permanent_differently_abled_employees_value",
        dimensionField:
          "percentage_of_female_non_permanent_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Differently Abled Employees",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_female_differently_abled_employees",
        subMeasureField:
          "percentage_of_female_differently_abled_employees_value",
        dimensionField: "percentage_of_female_differently_abled_employees",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Permanent Differently Abled Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_female_permanent_differently_abled_workers",
        subMeasureField:
          "percentage_of_female_permanent_differently_abled_workers_value",
        dimensionField:
          "percentage_of_female_permanent_differently_abled_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Non-Permanent Differently Abled Workers",
        chartYCustom: "Count",
        measureField:
          "HC_" +
          "percentage_of_female_non_permanent_differently_abled_workers",
        subMeasureField:
          "percentage_of_female_non_permanent_differently_abled_workers_value",
        dimensionField:
          "percentage_of_female_non_permanent_differently_abled_workers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage Female Differently Abled Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_female_differently_abled_workers",
        subMeasureField: "percentage_of_female_differently_abled_workers_value",
        dimensionField: "percentage_of_female_differently_abled_workers",
      }),

      // ----------------------------------------

      //
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Board of Directors Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_bod_employees",
        subMeasureField: "total_bod",
        dimensionField: "total_bod_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Board of Directors Employees",
        chartYCustom: "Count",
        measureField: "HC_" + "total_female_bod_employees",
        subMeasureField: "total_female_bod",
        dimensionField: "total_female_bod_emp",
      }),

      // ----------------------------------------

      // section_a_4_20_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Permanent Male Employees Turnover Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_perm_male_emp_turnover_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Permanent Male Workers Turnover Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_perm_male_workers_turnover_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Permanent Female Employees Turnover Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_perm_female_emp_turnover_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Permanent Female Workers Turnover Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_perm_female_workers_turnover_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Employees Turnover Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_perm_emp_turnover_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Workers Turnover Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_perm_workers_turnover_rate",
      }),

      // ----------------------------------------

      // section_c_principle_3_e_9_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Employee Reviewed",
        chartYCustom: "Count",
        measureField: "HC_" + "total_emp_male_reviewed",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Employee Reviewed",
        chartYCustom: "Count",
        measureField: "HC_" + "total_emp_female_reviewed",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Employee Reviewed",
        chartYCustom: "Count",
        measureField: "HC_" + "total_emp_reviewed",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Male Worker Reviewed",
        chartYCustom: "Count",
        measureField: "HC_" + "total_worker_male_reviewed",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Female Worker Reviewed",
        chartYCustom: "Count",
        measureField: "HC_" + "total_worker_female_reviewed",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Worker Reviewed",
        chartYCustom: "Count",
        measureField: "HC_" + "total_worker_reviewed",
      }),

      // ----------------------------------------
    ],

    "Product specification & clean fuel blends": [
      // section_c_principle_2_l_4
      // CFY - Re-Used
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Re-Used - Plastics (including packaging)",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "total_plastic_reused_in_current_financial_year",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Re-Used - E-waste",
        chartYCustom: "Metric Tons",
        measureField: "Env_" + "total_e_waste_reused_in_current_financial_year",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Re-Used - Hazardous waste",
        chartYCustom: "Metric Tons",
        measureField:
          "Env_" + "total_hazardous_waste_reused_in_current_financial_year",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Re-Used - Other waste",
        chartYCustom: "Metric Tons",
        measureField:
          "Env_" + "total_other_waste_reused_in_current_financial_year",
      }),

      // CFY - Recycled
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Recycled - Plastics (including packaging)",
        chartYCustom: "Metric Tons",
        measureField:
          "Env_" + "total_plastic_recycled_in_current_financial_year",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Recycled - E-waste",
        chartYCustom: "Metric Tons",
        measureField:
          "Env_" + "total_e_waste_recycled_in_current_financial_year",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Recycled - Hazardous waste",
        chartYCustom: "Metric Tons",
        measureField:
          "Env_" + "total_hazardous_waste_recycled_in_current_financial_year",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Recycled - Other waste",
        chartYCustom: "Metric Tons",
        measureField:
          "Env_" + "total_other_waste_recycled_in_current_financial_year",
      }),

      // CFY - Safely Disposed
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Safely Disposed - Plastics (including packaging)",
        chartYCustom: "Metric Tons",
        measureField:
          "Env_" + "total_plastic_safely_disposed_in_current_financial_year",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Safely Disposed - E-waste",
        chartYCustom: "Metric Tons",
        measureField:
          "Env_" + "total_e_waste_safely_disposed_in_current_financial_year",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Safely Disposed - Hazardous waste",
        chartYCustom: "Metric Tons",
        measureField:
          "Env_" +
          "total_hazardous_waste_safely_disposed_in_current_financial_year",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "CFY - Safely Disposed - Other waste",
        chartYCustom: "Metric Tons",
        measureField:
          "Env_" +
          "total_other_waste_safely_disposed_in_current_financial_year",
      }),
      // ----------------------------------------
    ],

    Complaints: [
      // section_a_7_23_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Complaints Filed by Communities",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_complaints_filed_by_communities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Complaints Filed by Investors other thank Shareholders",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_filed_by_ot_shareholders",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Complaints Filed by Shareholders",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_complaints_filed_by_shareholders",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Complaints Filed by Employees and Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_filed_by_emp_wrks",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Complaints Filed by Customers",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_complaints_filed_by_customers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Complaints Filed by Value Chain Partners",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_filed_by_vcp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Complaints Filed by Others",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_complaints_filed_by_others",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Pending Complaints by Communities",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_complaints_pending_by_communities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Pending Complaints by Investors other thank Shareholders",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_pending_by_ot_shareholders",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Pending Complaints by Shareholders",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_complaints_pending_by_shareholders",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Pending Complaints by Employees and Workers",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_pending_by_emp_wrks",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Pending Complaints by Customers",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_complaints_pending_by_customers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Pending Complaints by Value Chain Partners",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_pending_by_vcp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Pending Complaints by Others",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_of_complaints_pending_by_others",
      }),

      // ----------------------------------------

      // section_c_principle_3_e_13_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints on Working Conditions",
        chartYCustom: "Count",
        measureField: "HC_" + "wc_number_of_complaints_filed_curr_fy",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints on Health & Safety",
        chartYCustom: "Count",
        measureField: "HC_" + "hs_number_of_complaints_filed_curr_fy",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Complaints Pending Resolution on Working Conditions",
        chartYCustom: "Count",
        measureField: "HC_" + "wc_number_complaints_pending_end_of_cfy",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Pending Resolution on Health & Safety",
        chartYCustom: "Count",
        measureField: "HC_" + "hs_number_complaints_pending_end_of_cfy",
      }),

      // ----------------------------------------

      // section_c_principle_5_e_6_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Sexual Harassment Complaints Filed",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_sexual_harassment_complaints_filed",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Discrimination at Workplace Complaints Filed",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_discrimination_at_workplace_complaints_filed",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Child Labour Complaints Filed",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_child_labour_complaints_filed",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Forced Labour/Involuntary Labour Complaints Filed",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_forced_labour_involuntary_labour_complaints_filed",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Wages Complaints Filed",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_wages_complaints_filed",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Other Human Rights Related Complaints Filed",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_other_human_rights_related_issues_complaints_filed",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Sexual Harassment Complaints Pending",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_sexual_harassment_complaints_pending",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Discrimination at Workplace Complaints Pending",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_discrimination_at_workplace_complaints_pending",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Child Labour Complaints Pending",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_child_labour_complaints_pending",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Forced Labour/Involuntary Labour Complaints Pending",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_forced_labour_involuntary_labour_complaints_pending",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Wages Complaints Pending",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_wages_complaints_pending",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Other Human Rights Related Complaints Pending",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_other_human_rights_related_issues_complaints_pending",
      }),

      // ----------------------------------------

      // section_c_principle_9_e_3_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Received for Data Privacy",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_number_of_complaints_received_for_data_privacy",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Received for Advertising",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_number_of_complaints_received_for_advertising",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Received for Cyber-Security",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_number_of_complaints_received_for_cyber_security",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Complaints Received for Delivery of Essential Services",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_received_for_des",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Complaints Received for Restrictive Trade Practices",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_received_for_rtp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Received for Unfair Trade Practices",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_received_for_utp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Received for Other",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_number_of_complaints_received_for_others",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Pending for Data Privacy",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_number_of_complaints_pending_for_data_privacy",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Pending for Advertising",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_number_of_complaints_pending_for_advertising",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Pending for Cyber-Security",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_number_of_complaints_pending_for_cyber_security",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Complaints Pending for Delivery of Essential Services",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_pending_for_des",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Pending for Restrictive Trade Practices",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_pending_for_rtp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Pending for Unfair Trade Practices",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_num_complaints_pending_for_utp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Complaints Pending for Other",
        chartYCustom: "Count",
        measureField: "HC_" + "cfy_number_of_complaints_pending_for_others",
      }),

      // ----------------------------------------
    ],

    "Labour Relations": [
      // section_c_principle_3_e_5_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Male Permanent Employees Return to Work Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "male_permanent_employees_return_to_work_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Female Permanent Employees Return to Work Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "female_permanent_employees_return_to_work_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Employees Return to Work Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "permanent_employees_return_to_work_rate",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Male Permanent Employees Return to Retention Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "male_permanent_employees_retention_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Female Permanent Employees Return to Retention Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "female_permanent_employees_retention_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Employees Return to Retention Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "total_permanent_employees_retention_rate",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Male Permanent Workers Return to Work Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "male_permanent_workers_return_to_work_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Female Permanent Workers Return to Work Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "female_permanent_workers_return_to_work_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Workers Return to Work Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "permanent_workers_return_to_work_rate",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Male Permanent Workers Return to Retention Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "male_permanent_workers_retention_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Female Permanent Workers Return to Retention Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "female_permanent_workers_retention_rate",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Permanent Workers Return to Retention Rate",
        chartYCustom: "Count",
        measureField: "HC_" + "total_permanent_workers_retention_rate",
      }),

      // ----------------------------------------

      // section_c_principle_3_e_7
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Male Permanent Employees Part of Association or Union",
        chartYCustom: "Count",
        measureField:
          "HC_" + "male_perma_employees_part_of_association_or_union",
        subMeasureField: "total_measure",
        dimensionField: "total_dimension",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Female Permanent Employees Part of Association or Union",
        chartYCustom: "Count",
        measureField:
          "HC_" + "female_perma_employees_part_of_association_or_union",
        subMeasureField: "total_measure",
        dimensionField: "total_dimension",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Male Permanent Workers Part of Association or Union",
        chartYCustom: "Count",
        measureField: "HC_" + "male_perma_workers_part_of_association_or_union",
        subMeasureField: "total_measure",
        dimensionField: "total_dimension",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Female Permanent Workers Part of Association or Union",
        chartYCustom: "Count",
        measureField:
          "HC_" + "female_perma_workers_part_of_association_or_union",
        subMeasureField: "total_measure",
        dimensionField: "total_dimension",
      }),

      // ----------------------------------------
    ],

    "Legal Summary": [
      // section_c_principle_1_e_2_a_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Monetary Penalty/ Fine Amount",
        chartYCustom: "Amount (INR)",
        measureField: "HC_" + "fine_amount",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Monetary Settlement Amount",
        chartYCustom: "Amount (INR)",
        measureField: "HC_" + "settlement_amount",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Monetary Compounding fee Amount",
        chartYCustom: "Amount (INR)",
        measureField: "HC_" + "compounding_fee_amount",
      }),

      // ----------------------------------------

      // section_c_principle_7_e_1_a_type_1
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of affiliations with trade and industry chambers/ associations",
        chartYCustom: "Count",
        measureField: "HC_" + "num_affiliations_trade_and_industry_chambers",
        subMeasureField: "number_measure",
        dimensionField: "number_dimension",
      }),

      // ----------------------------------------
    ],

    "Security, Human rights & rights of indigenous peoples": [
      // section_c_principle_1_e_2_a_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Permanent Employees Trained on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_perm_emp_trained",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Non-Permanent Employees Trained on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_non_perm_emp_trained",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Employees Trained on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_emp_trained",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Permanent Workers Trained on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_perm_workers_trained",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Non-Permanent Workers Trained on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_non_perm_workers_trained",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Workers Trained on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_workers_trained",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Permanent Employees Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_perm_emp_covered",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Non-Permanent Employees Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_non_perm_emp_covered",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Employees Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_emp_covered",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Permanent Workers Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_perm_workers_covered",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Non-Permanent Workers Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_non_perm_workers_covered",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Workers Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "total_workers_covered",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Permanent Employees Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_prem_emp_covered",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Non-Permanent Employees Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_non_prem_emp_covered",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Employees Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_emp_covered",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Permanent Workers Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_prem_workers_covered",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Non-Permanent Workers Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_non_prem_workers_covered",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage Workers Covered on Human Rights Issues & Policy(ies)",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_workers_covered",
      }),

      // ----------------------------------------

      // section_c_principle_5_e_9_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Locations Assessed for Child Labour",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_locations_assessed_for_child_labour",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Locations Assessed for Forced Labour",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_locations_assessed_for_forced_labour",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Locations Assessed for Sexual Harassment",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_locations_assessed_for_sexual_harassment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Locations Assessed for Discrimination",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_of_locations_assessed_for_discrimination",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Locations Assessed for Wages",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_of_locations_assessed_for_wages",
      }),

      // ----------------------------------------

      // section_c_principle_5_l_4_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of Value Chain Partners Assessed for Sexual Harassment",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_assessed_for_sexual_harassment",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of Value Chain Partners Assessed for Discrimination at Workplace",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_assessed_for_discrimination",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Value Chain Partners Assessed for Child Labour",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_assessed_for_child_labour",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of Value Chain Partners Assessed for Forced/Involuntary Labour",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_assessed_for_forced_labour",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of Value Chain Partners Assessed for Wages",
        chartYCustom: "Count",
        measureField: "HC_" + "percentage_assessed_for_wages",
      }),

      // ----------------------------------------

      // section_c_p9_l_5a_type_1
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Instances of Data Breaches along-with Impact",
        chartYCustom: "Count",
        measureField: "HC_" + "number_of_instances_of_data_breaches",
      }),

      // ----------------------------------------

      // section_c_p9_l_5b_type_1
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of Data Breaches Involving Personally Identifiable Information of Customers",
        chartYCustom: "Count",
        measureField:
          "HC_" + "percentage_data_breaches_involving_pii_of_customers",
      }),

      // ----------------------------------------
    ],

    Wages: [
      //
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Wages Board of Directors Employees",
        chartYCustom: "Currency (USD)",
        measureField: "HC_" + "total_wages_bod_employees",
        subMeasureField: "total_wages_bod",
        dimensionField: "total_wages_bod_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Wages Key Management Personel Employees",
        chartYCustom: "Currency (USD)",
        measureField: "HC_" + "total_wages_kmp_employees",
        subMeasureField: "total_wages_kmp",
        dimensionField: "total_wages_kmp_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Wages Other Employees",
        chartYCustom: "Currency (USD)",
        measureField: "HC_" + "total_wages_other_employees",
        subMeasureField: "total_wages_other",
        dimensionField: "total_wages_other_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Wages Workers",
        chartYCustom: "Currency (USD)",
        measureField: "HC_" + "total_wages_workers_employees",
        subMeasureField: "total_wages_worker",
        dimensionField: "total_wages_other_worker",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Wages Employees",
        chartYCustom: "Currency (USD)",
        measureField: "HC_" + "total_wages_employees",
        subMeasureField: "total_wages",
        dimensionField: "total_wages_emp",
      }),

      // ----------------------------------------

      // section_c_principle_5_e_2_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Permanent Male Employees Minimum Wage",
        chartYCustom: "Amount",
        measureField: "HC_" + "permanent_male_employees_min_wage",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Permanent Female Employees Minimum Wage",
        chartYCustom: "Amount",
        measureField: "HC_" + "permanent_female_employees_min_wage",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Non-Permanent Male Employees Minimum Wage",
        chartYCustom: "Amount",
        measureField: "HC_" + "non_permanent_male_employees_min_wage",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Non-Permanent Male Employees Minimum Wage",
        chartYCustom: "Amount",
        measureField: "HC_" + "non_permanent_female_employees_min_wage",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Permanent Male Workers Minimum Wage",
        chartYCustom: "Amount",
        measureField: "HC_" + "permanent_male_workers_min_wage",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Permanent Female Workers Minimum Wage",
        chartYCustom: "Amount",
        measureField: "HC_" + "permanent_female_workers_min_wage",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Non-Permanent Male Workers Minimum Wage",
        chartYCustom: "Amount",
        measureField: "HC_" + "non_permanent_male_workers_min_wage",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Non-Permanent Female Workers Minimum Wage",
        chartYCustom: "Amount",
        measureField: "HC_" + "non_permanent_female_workers_min_wage",
      }),

      // ----------------------------------------
    ],
    Materials: [
      // section_c_p8_e_4_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of Input Materials Directly Sourced from MSMEs/ Small Producers",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_input_materials_directly_sourced_from_msme",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of Input Materials Directly Sourced from Within the District and Neighbouring Districts",
        chartYCustom: "Count",
        measureField:
          "HC_" + "cfy_perc_input_materials_directly_sourced_from_wd",
      }),

      // ----------------------------------------

      // section_c_p8_e_4_type_3
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Units Recalled for Voluntary Recall",
        chartYCustom: "Count",
        measureField: "HC_" + "number_units_recalled_for_voluntary_recall",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Units Recalled for Forced Recall",
        chartYCustom: "Count",
        measureField: "HC_" + "number_units_recalled_for_force_recall",
      }),

      // ----------------------------------------
    ],
  };

  return KPI_DATA;
};

export default handleKpiDataWithOrganisation;
