import React from "react";

import { Grid, Typography } from "@material-ui/core";
import ScoreChart from "./ScoreChart";

import { getColoredLogo } from "../../../utils/riskUtils/riskUtils";
// import { TopicData, TopicHeader } from "./esgComponents";

function EsgScore(props) {
  // const { scores } = props;
  // const { environment, social, governance } = scores?.scoreObject;
  const { scoreObject } = props?.scores || {};
  // const { environment, social, governance } = scores?.scoreObject;

  const TopicHeader = ({ topic, score }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          borderBottom: "1px solid lightblue",
          marginTop: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={getColoredLogo(topic)} alt="leaf" />
          <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
            {topic}
          </Typography>
        </div>
        <div>
          <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
            {score || `-`}
          </Typography>
        </div>
      </div>
    );
  };
  const TopicData = ({ topic, score }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "8px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{ fontSize: "14px", marginLeft: "5px", fontWeight: 400 }}
          >
            {topic}
          </Typography>
        </div>
        <div>
          <Typography style={{ fontSize: "16px", fontWeight: 400 }}>
            {score || `-`}
          </Typography>
        </div>
      </div>
    );
  };

  const dataNew = [
    {
      topic: "Environment",
      score: scoreObject?.environment?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Energy",
          score: scoreObject?.environment?.data_points?.energy?.toFixed(2),
        },
        {
          topic: "Water",
          score: scoreObject?.environment?.data_points?.water?.toFixed(2),
        },
        {
          topic: "Waste",
          score: scoreObject?.environment?.data_points?.waste?.toFixed(2),
        },
        {
          topic: "Analytic CO2",
          score: scoreObject?.environment?.data_points?.analytic?.toFixed(2),
        },
        {
          topic: "Green revenues",
          score: scoreObject?.environment?.data_points?.green?.toFixed(2),
        },
      ],
    },
    {
      topic: "Social",
      score: scoreObject?.social?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Diversity and Inclusion",
          score: scoreObject?.social?.data_points?.diversity?.toFixed(2),
        },
        {
          topic: "Human Rights",
          score: scoreObject?.social?.data_points?.humanRights?.toFixed(2),
        },
        {
          topic: "Career development and training",
          score: scoreObject?.social?.data_points?.career?.toFixed(2),
        },
        {
          topic: "Health and Safety",
          score: scoreObject?.social?.data_points?.health?.toFixed(2),
        },
        {
          topic: "Data privacy",
          score: scoreObject?.social?.data_points?.dataPrivacy?.toFixed(2),
        },
        {
          topic: "Responsible Marketing",
          score: scoreObject?.social?.data_points?.product?.toFixed(2),
        },
      ],
    },
    {
      topic: "Governance",
      score: scoreObject?.governance?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Structure",
          score: scoreObject?.governance?.data_points?.structure?.toFixed(2),
        },
        {
          topic: "Compensation",
          score: scoreObject?.governance?.data_points?.compensation?.toFixed(2),
        },
      ],
    },
    // {
    //   topic: "Business",
    //   score: 30,
    //   subTopic: [
    //     { topic: "Product Quality and Safety", score: 10 },
    //     { topic: "Customer Privacy and Data Protection", score: 6 },
    //     { topic: "Supply Chain Management", score: 28 },
    //     { topic: "Marketing and Advertising", score: 25 },
    //     { topic: "Innovation and Intellectual Property", score: 3},
    //   ],
    // },
    // {
    //   topic: "Human Capital",
    //   score: 7,
    //   subTopic: [
    //     { topic: "Work-Life Balance", score: 2 },
    //     { topic: "Employment Training & Development", score: 2 },
    //     { topic: "Diversity and Inclusion", score: 1 },
    //     { topic: "Employee engagement", score: 2 },
    //   ],
    // },
  ];
  return (
    <div style={{ paddingBottom: "18px", marginTop: "30px", width: "100%" }}>
      {" "}
      <Grid container item md={12}>
        <Grid item md={7} sm={12}>
          {/* <Typography
            style={{ fontSize: "13px", padding: "5px" }}
            component={Paper}
          >
            The score reflects the company's ESG performance and can guide
            stakeholders in making informed decisions. (Out of 100) 54
          </Typography> */}
          <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
            {dataNew.map((data, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    width: "49%",
                    borderRadius: 8,
                    backgroundColor: "#FBFBFB",
                    paddingBottom: 5,
                  }}
                >
                  <TopicHeader topic={data.topic} score={data.score} />
                  {data.subTopic.map((sub, index) => (
                    <TopicData
                      key={index}
                      topic={sub.topic}
                      score={sub.score}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </Grid>
        <Grid item md={5} sm={12}>
          {<ScoreChart scores={props?.scores} />}
        </Grid>
      </Grid>
    </div>
  );
}

export default EsgScore;
