import React from 'react'

import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';

export default function ListingPageSkeleton() {
  const useStyles=makeStyles(()=>({
    mainDiv:{ paddingLeft: 35, paddingTop: 45 },
    mainDiv1:{ marginTop: 15 },
    subDiv1:{ display: 'flex' },
    Skeleton:{ marginRight: 100 },
    Skeleton1:{ marginRight: 80, marginTop: 15 },
  }))
  const classes=useStyles();
  
  return (
    <div className={classes.mainDiv}>
      <div className={classes.subDiv1}>
        {
          [1, 2, 3, 4, 5].map((item) => (
            <Skeleton key={item} className={classes.Skeleton} width='150px' height='50px' animation="wave" />
          ))
        }
      </div>
      <div className={classes.mainDiv1}>
        {
          [1, 2, 3, 4, 5].map((item) => (
          
            <div key={item} className={classes.subDiv1} >
              {
                [1, 2, 3, 4, 5].map((item) => (
                  <Skeleton key={item} className={classes.Skeleton1} width='170px' height='40px' animation="wave" />
                ))
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

