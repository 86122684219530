import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { TopicCard } from "../../reportdataleft/reportDataLeft";
import { setSelectedTopic } from "../../../../actions/datahubActions/datahubActions";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EBF1F8",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EBF1F8",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EBF1F8",
    },
  },
});

// Main Topics for BRSR
const topicNameBRSR = [
  "Social Capital",
  "Environment",
  "Human Capital",
  "Business Model & Innovation",
  "Leadership & Governance",
];
// Sub Topics for BRSR
const topicArrBRSR = {
  "Social Capital": [
    "Security, Human rights & rights of indigenous peoples",
    "Complaints",
    "Wages",
    "Materials",
  ],
  Environment: ["Waste", "Greenhouse Gas Emission", "Energy", "Water"],
  "Human Capital": [
    "Workforce Health & safety",
    "Labour Relations",
    "Training & Awareness Programmes",
    "Employee Diversity and Inclusion",
  ],
  "Business Model & Innovation": ["Product specification & clean fuel blends"],
  "Leadership & Governance": ["Legal Summary"],
};

const arrBRSR = [
  "Security, Human rights & rights of indigenous peoples",
  "Complaints",
  "Wages",
  "Materials",
  "Waste",
  "Greenhouse Gas Emission",
  "Energy",
  "Water",
  "Workforce Health & safety",
  "Labour Relations",
  "Training & Awareness Programmes",
  "Employee Diversity and Inclusion",
  "Product specification & clean fuel blends",
  "Legal Summary",
];

// --------------------------------------------

// Main Topics for GRI
const topicNameGRI = [
  "Social Capital",
  "Environment",
  "Human Capital",
  "Business Model & Innovation",
  "Leadership & Governance",
];
// Sub Topics for GRI
const topicArrGRI = {
  "Social Capital": [
    "Security, Human rights & rights of indigenous peoples",
    "Wages",
    "Materials",
  ],
  Environment: [
    "Waste",
    "Greenhouse Gas Emission",
    "Energy",
    "Water",
    "Biodiversity",
    "Suppliers",
  ],
  "Human Capital": [
    "Workforce Health & safety",
    "Training & Awareness Programmes",
    "Employee Diversity and Inclusion",
    "Economic Performance",
    "Incidents",
  ],
  "Business Model & Innovation": ["Product specification & clean fuel blends"],
  "Leadership & Governance": ["Legal Summary", "Geographical Presence"],
};

const arrGRI = [
  "Security, Human rights & rights of indigenous peoples",
  "Wages",
  "Materials",

  "Waste",
  "Greenhouse Gas Emission",
  "Energy",
  "Water",
  "Biodiversity",
  "Suppliers",

  "Workforce Health & safety",
  "Training & Awareness Programmes",
  "Employee Diversity and Inclusion",
  "Economic Performance",
  "Incidents",
  "Product specification & clean fuel blends",
  "Legal Summary",
  "Geographical Presence",
];

function TopicCards() {
  const classes = useStyles();

  const dataHubStore = useSelector((state) => state.dataHub);

  const { selectedTopic, selectedKpiType } = dataHubStore;

  const dispatch = useDispatch();

  const [hoverTopic, setHoverTopic] = useState(null);
  const [keyWord, setKeyWord] = useState("");

  const topicNameObj =
    selectedKpiType === "BRSR" ? topicNameBRSR : topicNameGRI;

  const topicArr = selectedKpiType === "BRSR" ? topicArrBRSR : topicArrGRI;

  const searchTopicArr = selectedKpiType === "BRSR" ? arrBRSR : arrGRI;

  useEffect(() => {
    dispatch(
      setSelectedTopic("Security, Human rights & rights of indigenous peoples")
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cards = topicNameObj.map((topicName) => (
    <TopicCard topicMetric={{ topic_name: topicName }}>
      {topicArr[topicName].map((topic) => (
        <Box
          style={{
            borderBottom: "1px solid #EBF1F8",
            backgroundColor:
              hoverTopic === topic || selectedTopic === topic
                ? "#EBF1F8"
                : "#FBFBFB",
          }}
          onMouseEnter={() => setHoverTopic(topic)}
          onMouseLeave={() => setHoverTopic(null)}
          onClick={() => dispatch(setSelectedTopic(topic))}
        >
          <Typography
            style={{
              fontSize: "14px",
              color: "#15314E",
              padding: "15px",
              cursor: "pointer",
              fontWeight: 600,
            }}
          >
            {topic}
          </Typography>
        </Box>
      ))}
    </TopicCard>
  ));

  return (
    <>
      <Box
        style={{
          padding: "10px",
        }}
      >
        <TextField
          className={classes.root}
          size="small"
          type="text"
          label={
            <Typography
              style={{ color: "#3374B9", fontSize: "18px", marginTop: "-5px" }}
            >
              Search
            </Typography>
          }
          color="primary"
          variant="outlined"
          fullWidth={true}
          // value={""}
          onChange={(e) => setKeyWord(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: "#3374B9", fontSize: "30px" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box style={{ height: "95%", overflow: "scroll" }}>
        {keyWord &&
          searchTopicArr
            .filter((t) => t.toLowerCase().includes(keyWord.toLowerCase()))
            .map((topic) => (
              <Box
                style={{
                  borderBottom: "1px solid #EBF1F8",
                  backgroundColor:
                    hoverTopic === topic || selectedTopic === topic
                      ? "#EBF1F8"
                      : "#FBFBFB",
                }}
                onMouseEnter={() => setHoverTopic(topic)}
                onMouseLeave={() => setHoverTopic(null)}
                onClick={() => dispatch(setSelectedTopic(topic))}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#15314E",
                    padding: "15px",
                    cursor: "pointer",
                    fontWeight: 600,
                  }}
                >
                  {topic}
                </Typography>
              </Box>
            ))}
        {!keyWord && cards}
      </Box>
    </>
  );
}

export default TopicCards;
