import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import CustomTextfield from "../../UI/textfield/textfield";
import useDebounce from "../../../customHooks/useDebouncec";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";
import TypeThreeCalculation from "./validation/surveyTypeThreeCalculation";

const useStyles = makeStyles({
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  Grid: { paddingTop: 20 },
  CustomTextfield: { width: "100%" },
  input: { display: "none" },
  Box: {
    borderBottom: "2px solid gray",
    cursor: "pointer",
  },
  Box1: { borderBottom: "2px solid #3374B9" },
  TableCell: { minWidth: 150 },
});

const SurveyTypeThree = (props) => {
  const {
    surveyResultData,
    isUpsertMetricData,
    setIsUpsertMetricData,
    fieldData,
    login,
    assessment_id,
    metricId,
    index,
    parentId,
    removeFile,
    metric_name,
    metric_desc,
    metric,
    setOnSubmitObject,
    calculate,
    validation,
    isSuggested,
  } = props;

  const classes = useStyles();
  const [typeThreeData, setTypeThreeData] = useState([]);

  const [metricFile, setMetricFile] = useState(null);
  const [metricComment, setMetricComment] = useState("");
  useEffect(() => {
    if (props.metric.hasOwnProperty("comment")) {
      setMetricComment(props.metric.comment);
    } else {
      setMetricComment(
        fieldData.assignedMetric.final_data.map((m) => m.comment)[index]
      );
    }

    if (props.hasOwnProperty("file") && props.file !== "") {
      setMetricFile(props.metric.file);
    } else {
      setMetricFile(
        fieldData.assignedMetric.final_data.map((m) => m.file)[index]
      );
    }
  }, [fieldData.currentMetric]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let data = {};
    if (surveyResultData !== "") {
      setTypeThreeData(surveyResultData);
    } else {
      data = metric.survey_data.rows.map((r, idx) => {
        return {
          row: r,
          columns:
            metric.survey_data.columns &&
            metric.survey_data.columns.map((c) => {
              return {
                column: c,
                data: "",
              };
            }),
        };
      });
      setTypeThreeData(data);
    }
  }, [parentId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e, rowIndex, colIndex, autoFill) => {
    let value = autoFill ? e : e.target.value;

    setTypeThreeData((prevState) => {
      prevState[rowIndex].columns[colIndex].data = value.toString();
      return [...prevState];
    });
  };

  useDebounce(
    () => {
      setOnSubmitObject({
        token: login.token,
        assessment_id,
        metricId,
        currentMetric_id: fieldData.currentMetric._id,
        data_type: typeThreeData,
        metricComment,
        metricFile,
        metric_name,
        metric_desc,
        parent_id: parentId,
        parent_metric_id: metric.parent_metric_id,
      });
    },
    100,
    [typeThreeData, metricComment, metricFile]
  );

  return (
    <>
      <Grid container justifyContent="space-evenly" className={classes.Grid}>
        <Grid item xs={12}>
          <Paper>
            {typeThreeData.length > 0 && (
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.TableCell}
                      ></TableCell>
                      {typeThreeData.length > 0 &&
                        typeThreeData[0].columns &&
                        typeThreeData[0].columns.map((column, index) => (
                          <TableCell
                            key={index}
                            align="center"
                            className={classes.TableCell}
                          >
                            {column.column}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {typeThreeData.length > 0 &&
                      typeThreeData.map((row, rowIndex) => {
                        return (
                          <TableRow key={rowIndex} hover role="checkbox">
                            <TableCell align="center">
                              <Typography>{row.row}</Typography>
                            </TableCell>
                            {row.columns &&
                              row.columns.map((col, colIndex) => (
                                <TypeThreeCalculation
                                  validation={validation}
                                  typeThreeData={typeThreeData}
                                  colIndex={colIndex}
                                  classes={classes}
                                  rowIndex={rowIndex}
                                  handleChange={handleChange}
                                  setIsUpsertMetricData={setIsUpsertMetricData}
                                  calculate={calculate}
                                  isUpsertMetricData={isUpsertMetricData}
                                  key={colIndex}
                                />
                              ))}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
      {!isSuggested && (
        <Box>
          <Box my={2}>
            <Typography className={classes.blueText}>Comments</Typography>
            <CustomTextfield
              name={`Comment_type3${metricId}`}
              label="Additional Comment"
              fullWidth
              multiline
              minRows={3}
              size="small"
              variant="outlined"
              value={metricComment}
              onChange={(e) => {
                setMetricComment(e.target.value);
                if (!isUpsertMetricData) {
                  setIsUpsertMetricData(true);
                }
              }}
            />
          </Box>
          <Box my={2}>
            {metricFile === null || metricFile === "" ? (
              <>
                <input
                  id={metricId}
                  type="file"
                  index={index}
                  onChange={(event) => {
                    setMetricFile(event.target.files[0]);

                    if (!isUpsertMetricData) {
                      setIsUpsertMetricData(true);
                    }
                  }}
                  className={classes.input}
                />
                <label htmlFor={metricId}>
                  <Box display="flex" component="span" alignItems="center">
                    <AttachFileIcon color="disabled" />
                    <Box mx={2} className={classes.Box} width="100%">
                      <Typography variant="caption" color="textSecondary">
                        Upload document
                      </Typography>
                    </Box>
                  </Box>
                </label>
              </>
            ) : (
              <>
                <Box display="flex" alignItems="center">
                  <AttachFileIcon color="disabled" />
                  <Box mx={2} className={classes.Box1} width="100%">
                    <Typography variant="caption" color="primary">
                      {metricFile.name}
                    </Typography>
                  </Box>
                  <ClearIcon
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      removeFile(
                        login.token,
                        assessment_id,
                        5,
                        metricFile.url,
                        "file",
                        null,
                        null,
                        null,
                        metricId
                      );
                      setMetricFile(null);
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default React.memo(SurveyTypeThree);
