import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {
        align: "center",
      },
      grid: {
        display: true,
        drawOnChartArea: false,
      },
    },

    y: {
      grid: {
        display: true,
        drawBorder: false,
      },
      ticks: {
        maxTicksLimit: 5,
      },
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

function bgGradiendt(ctx, chartArea, scales) {
  const { top, bottom } = chartArea;
  //   const { x, y } = scales;
  const gradientBackground = ctx.createLinearGradient(0, top, 0, bottom);
  gradientBackground.addColorStop(0, "rgba(190, 210, 250, 0.43)");
  gradientBackground.addColorStop(1, "rgba(51, 116, 185, 0)");

  return gradientBackground;
}

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      data: [630, 788, 976, 698, 984, 30, 478, 305],
      borderWidth: 1,
      borderColor: "rgba(51, 116, 185, 1)",
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea, scales } = chart;
        if (!chartArea) {
          return null;
        }
        return bgGradiendt(ctx, chartArea, scales);
      },
      tension: 0.4,
    },
  ],
};

const AreaChart = () => {
  return (
    <Card
      style={{
        height: "100%",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        border: "2px solid #DADBE6",
      }}
    >
      <CardContent style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "24px",
              color: " #242424",
            }}
          >
            Carbon Emission (TDP)
          </Typography>
          <div>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: " #242424",
              }}
            >
              No. of Plants
            </Typography>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "21px",
                lineHeight: "24px",
                color: " #242424",
              }}
            >
              12
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "center",
            alignItems: "center",
            height: "85%",
          }}
        >
          <Bar options={options} data={data} />
        </div>
      </CardContent>
    </Card>
  );
};

export default AreaChart;
