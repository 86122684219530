import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import { ReactSortable, Sortable, MultiDrag, Swap } from "react-sortablejs";
import DeleteIcon from "@material-ui/icons/Delete";
import ChartColorsTransferList from "./chartColorTrasnferList";
import CloseIcon from "@material-ui/icons/Close";
import {
  Circle,
  rgbaStringToHsva,
  rgbaToHex,
  hsvaToHex,
  hsvaToHexa,
} from "@uiw/react-color";
import { Icon, IconButton, MenuItem, Select } from "@material-ui/core";
import { CustomDataInput } from "./SelectChartType";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();

  const {
    onClose,
    selectedValue,
    open,
    setBackGroundColor,
    backgroundColor,
    borderColor,
    setBorderColor,
  } = props;

  const [hex, setHex] = React.useState("#F44E3B");
  const [hex1, setHex1] = React.useState("#F44E3B");

  const handleClose = () => {
    onClose(selectedValue);
  };

  const colorArrHexa = backgroundColor.map((i) =>
    hsvaToHexa(rgbaStringToHsva(i))
  );

  const borderColorArrHexa = borderColor.map((i) =>
    hsvaToHexa(rgbaStringToHsva(i))
  );

  const [deletedId, setDeletedId] = React.useState(null);

  const [deletedIdBorder, setDeletedIdBorder] = React.useState(null);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="lg"
      fullWidth
    >
      {" "}
      <DialogTitle id="simple-dialog-title">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ fontWeight: 600, fontSize: 16 }}>
            {" "}
            Set Chart Colors
          </Typography>
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </DialogTitle>
      {/* <div style={{display:"flex", width:"100%",  justifyContent:"space-evenly"}}>
        <div>
          <h3> BackGround Colors</h3>
          <ReactSortable
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            list={backgroundColor}
            setList={(props) =>
              typeof deletedId === "number"
                ? setBackGroundColor(props.filter((c) => c.id !== deletedId))
                : setBackGroundColor(props)
            }
          >
            {backgroundColor.map((item, id) => (
              <List key={item.id}>
                <ListItem>
                  <Circle colors={[colorArrHexa[id]]} color={hex} />
                  <IconButton
                    color="action"
                    onClick={() => setDeletedId(item.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </List>
            ))}
          </ReactSortable>
        </div>
        <div>
          <h3> Border Colors</h3>
          <ReactSortable
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            list={borderColor}
            setList={(props) =>
              typeof deletedIdBorder === "number"
                ? setBorderColor(props.filter((c) => c.id !== deletedIdBorder))
                : setBorderColor(props)
            }
          >
            {borderColor.map((item, id) => (
              <List key={item.id}>
                <ListItem>
                  <Circle colors={[borderColorArrHexa[id]]} color={hex1} />
                  <IconButton
                    color="action"
                    onClick={() => setDeletedIdBorder(item.id)}
                  >
                  
                  </IconButton>
                </ListItem>
              </List>
            ))}
          </ReactSortable>
        </div>
      </div> */}
      <ChartColorsTransferList
        colorArrHexa={colorArrHexa.concat(borderColorArrHexa)}
        setBackGroundColors={setBackGroundColor}
        setBorderColors={setBorderColor}
      />
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo({
  backgroundColor,
  borderColor,
  setBorderColor,
  setBackGroundColor,
}) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const [state, setState] = useState("Edit Chart Colors");
  useEffect(() => {
    setState("Edit Chart Colors");
  }, []);
  return (
    <div>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        input={<CustomDataInput />}
        value={"Edit Chart Colors"}
        defaultValue={"Edit Chart Colors"}
        style={{ width: "100%" }}
      >
        <MenuItem value="Edit Chart Colors" onClick={handleClickOpen}>
          Edit Chart Colors
        </MenuItem>
      </Select>
      {/* <CustomDataInput variant="outlined" value='Edit Chart Colors' color="primary" onClick={handleClickOpen} /> */}
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Edit Chart Colors
      </Button> */}

      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        backgroundColor={backgroundColor}
        setBackGroundColor={setBackGroundColor}
        borderColor={borderColor}
        setBorderColor={setBorderColor}
      />
    </div>
  );
}
