import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SvgIcon from "@material-ui/core/SvgIcon";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CustomTextfield from "../../UI/textfield/textfield";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import CustomButton from "./../../UI/button/button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import moment from "moment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Currency from "../../../utils/currency/currency.json";
// import { tempUnit } from '../../../utils/unit/unitData';
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import esgPlanRightStyles from "./esgPlanRightStyles";
import { TopicIcon } from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import { Popper } from "@material-ui/core";

const AirbnbSlider = withStyles({
  root: {
    color: "#3374B9",
    marginTop: -14,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#3374B9",
    border: "1px solid currentColor",
    marginTop: -9,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      height: 7,
      width: 1.4,
      backgroundColor: "white",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
    border: 0,
    borderRadius: 8,
  },
  rail: {
    color: "#F5F6F8",
    opacity: 1,
    height: 3,
    border: 0,
    borderRadius: 8,
  },
})(Slider);

const suggestion = createTheme({
  palette: {
    primary: { main: "rgb(159, 161, 175)" },
    secondary: { main: "#47BB76" },
  },
  overrides: {
    MuiButton: {
      containedSecondary: {
        color: "#ffffff",
      },
      containedPrimary: {
        color: "#ffffff",
      },
      textPrimary: {
        "&:hover": {
          color: " #3374B9",
          backgroundColor: " #d8e7f7",
        },
      },
    },
  },
});

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

function AirbnbThumbComponenttwo(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

export default function ESGPlanRight(props) {
  const {
    login,
    esgPlan,
    setTopicState,
    addActivity,
    editActivity,
    deleteActivity,
    addKPI,
    editKPI,
    deleteKPI,
    setIsEditActivity,
    setIsEditKPI,
    assessment_id,
    assignMembers,
    viewAssignMembers,
    generalSettings,
    viewAllUsers,
    removeFile,
    deleteAssignedMember,
    getUnit,
    addUnit,
  } = props;

  const classes = esgPlanRightStyles();

  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const [activityTitle, setActivityTitle] = useState("");
  const [activityestimatedPeriod, setActivityEstimatedPeriod] = useState([
    { startDate: "", endDate: "" },
  ]);
  const [activityBudget, setActivityBudget] = useState("");
  const [activityComment, setActivityComment] = useState("");
  const [activityFile, setActivityFile] = useState(null);
  const [currentActivityID, setCurrentActivityID] = useState("");
  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);

  const [kpiTitle, setKpiTitle] = useState("");
  const [kpiFile, setKpiFile] = useState(null);
  const [kpiEstimatedPeriod, setKpiEstimatedPeriod] = useState([
    { startDate: "", endDate: "" },
  ]);
  const [unit, setUnit] = useState(1);

  const [isDatePickerDialogOpen2, setisDatePickerDialogOpen2] = useState(false);
  const [baselineValue, setBaselineValue] = useState(0);
  const [targetValue, setTargetValue] = useState(0);
  const [unitValue, setUnitValue] = useState("");
  const [baseline, setBaseline] = useState(false);
  const [target, setTarget] = useState(false);
  const [kpiBudget, setKpiBudget] = useState("");
  const [kpiComment, setKpiComment] = useState("");
  const [currentKpiId, setCurrentKpiId] = useState("");
  const [topicId, setTopicId] = useState([]);
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [keyword, setKeyword] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedActivityCurrency, setSelectedActivityCurrency] = useState("");
  const [selectedKpiCurrency, setSelectedKpiCurrency] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteKpiOrActivity, setDeleteKpiOrActivity] = useState({
    kpi_id: "",
    activity_id: "",
  });

  // console.log('unit',unit,unitValue)
  useEffect(() => {
    setCurrencyOptions(Currency);
    getUnit();
  }, []);

  useEffect(() => {
    viewAllUsers(login.token, login.current_organisation, keyword);
  }, [login.current_organisation]); // eslint-disable-line react-hooks/exhaustive-deps

  // suggestion button
  const [suggestions, setSuggestions] = useState(true);

  const ActivityBar = (props) => {
    const { index, activity } = props;
    return (
      <>
        <div className={classes.relavanceBarContainer}>
          <div className={classes.topicBarContents}>
            <Avatar className={classes.relavanceAvatar}>{index}</Avatar>
            <Typography>{activity.title}</Typography>
          </div>
          <div>
            <IconButton
              name="editActivity"
              className={classes.iconColor}
              onClick={() => handleEditActivity(activity)}
            >
              <CreateOutlinedIcon color="disabled" />
            </IconButton>
            <IconButton
              name="dltActivity"
              className={classes.iconColor}
              onClick={() => {
                setDeleteKpiOrActivity({
                  ...deleteKpiOrActivity,
                  activity_id: activity.activity_id,
                });
                setDeleteConfirmation(true);
              }}
            >
              <CancelOutlinedIcon color="disabled" />
            </IconButton>
          </div>
        </div>
      </>
    );
  };

  const KPIBar = (props) => {
    const { index, kpi } = props;
    return (
      <>
        <div className={classes.relavanceBarContainer}>
          <div className={classes.topicBarContents}>
            <Avatar className={classes.relavanceAvatar}>{index}</Avatar>
            <Typography>{kpi.title}</Typography>
          </div>
          <div>
            <IconButton
              className={classes.iconColor}
              onClick={() => {
                handleEditKPI(kpi);
              }}
              name="editKpi"
            >
              <CreateOutlinedIcon color="disabled" />
            </IconButton>

            <IconButton
              className={classes.iconColor}
              onClick={() => {
                setDeleteKpiOrActivity({
                  ...deleteKpiOrActivity,
                  kpi_id: kpi.kpi_id,
                });
                setDeleteConfirmation(true);
              }}
            >
              <CancelOutlinedIcon name="rmvKpi" color="disabled" />
            </IconButton>
          </div>
        </div>
      </>
    );
  };

  const deleteConfirm = () => {
    return (
      <>
        <Dialog open={deleteConfirmation}>
          <DialogTitle>
            <Grid
              container
              justifyContent="center"
              className={classes.mainGrid}
            >
              <Grid item xs={4}>
                <Typography className={classes.Typography2}>
                  Delete{" "}
                  {deleteKpiOrActivity.activity_id === "" ? "KPI" : "Activity"}{" "}
                  ?
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid sm={4}>
              <div className={classes.div1}>
                <Typography className={classes.Typography3}>
                  Do you Really want to Delete this{" "}
                  {deleteKpiOrActivity.activity_id === "" ? "KPI" : "Activity"}{" "}
                  ?
                </Typography>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <CustomButton
              variant="outlined"
              color="primary"
              onClick={() => {
                setDeleteConfirmation(false);
                setDeleteKpiOrActivity({ kpi_id: "", activity_id: "" });
              }}
            >
              Close
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              className={classes.CustomButton}
              onClick={() => {
                deleteKpiOrActivity.activity_id !== "" &&
                  deleteActivity(
                    login.token,
                    esgPlan.currentTopic.assessment_topic_id,
                    deleteKpiOrActivity.activity_id,
                    false,
                    esgPlan.currentTopic.topic_name,
                    assessment_id
                  );
                deleteKpiOrActivity.kpi_id !== "" &&
                  deleteKPI(
                    login.token,
                    esgPlan.currentTopic.assessment_topic_id,
                    deleteKpiOrActivity.kpi_id,
                    false,
                    esgPlan.currentTopic.topic_name,
                    assessment_id
                  );
                setDeleteConfirmation(false);
                setDeleteKpiOrActivity({ kpi_id: "", activity_id: "" });
              }}
            >
              Delete
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const datePickerDialogActivity = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent>
            <Box>
              <DateRange
                onChange={(item) =>
                  setActivityEstimatedPeriod([item.selection])
                }
                ranges={[
                  {
                    startDate:
                      activityestimatedPeriod[0].startDate !== ""
                        ? activityestimatedPeriod[0].startDate
                        : new Date(),
                    endDate:
                      activityestimatedPeriod[0].endDate !== ""
                        ? activityestimatedPeriod[0].endDate
                        : addDays(new Date(), 0),
                    key: "selection",
                  },
                ]}
                months={2}
                direction="horizontal"
              />
            </Box>
          </DialogContent>
          <DialogActions className={classes.DialogActions1}>
            <CustomButton
              name="closeDateBtn"
              variant="outlined"
              color="primary"
              onClick={() => {
                setIsDatePickerDialogOpen(false);
              }}
            >
              Close
            </CustomButton>
            <CustomButton
              name="setDateBtn"
              variant="contained"
              color="primary"
              className={classes.CustomButton}
              onClick={() => setIsDatePickerDialogOpen(false)}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const datePickerDialogKPI = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen2}>
          <DialogContent>
            <Typography>
              <DateRange
                onChange={(item) => setKpiEstimatedPeriod([item.selection])}
                ranges={[
                  {
                    startDate:
                      kpiEstimatedPeriod[0].startDate !== ""
                        ? kpiEstimatedPeriod[0].startDate
                        : new Date(),
                    endDate:
                      kpiEstimatedPeriod[0].endDate !== ""
                        ? kpiEstimatedPeriod[0].endDate
                        : addDays(new Date(), 0),
                    key: "selection",
                  },
                ]}
                months={2}
                direction="horizontal"
              />
            </Typography>
          </DialogContent>
          <DialogActions className={classes.DialogActions1}>
            <CustomButton
              name="closeDateBtn"
              variant="outlined"
              color="primary"
              onClick={() => {
                setisDatePickerDialogOpen2(false);
              }}
            >
              Close
            </CustomButton>
            <CustomButton
              name="setDateBtn"
              variant="contained"
              color="primary"
              className={classes.CustomButton}
              onClick={() => setisDatePickerDialogOpen2(false)}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleAddActivity = () => {
    setActivityTitle("");
    setActivityEstimatedPeriod([{ startDate: "", endDate: "" }]);
    setActivityBudget("");
    setActivityComment("");
    setActivityFile(null);
    setTopicState(1);
    setIsEditActivity(false);
    setSelectedActivityCurrency("");
  };

  const handleEditActivity = (activity) => {
    setCurrentActivityID(activity.activity_id);
    setActivityTitle(activity.title);

    if (
      activity.estimated_period[0].startDate !== "" &&
      activity.estimated_period[0].endDate !== ""
    ) {
      setActivityEstimatedPeriod([
        {
          startDate: new Date(activity.estimated_period[0].startDate),
          endDate: new Date(activity.estimated_period[0].endDate),
        },
      ]);
    } else {
      setActivityEstimatedPeriod([{ startDate: "", endDate: "" }]);
    }

    setActivityBudget(activity.budget);
    setActivityComment(activity.comments);
    setActivityFile(activity.file);
    setTopicState(1);
    setIsEditActivity(true);
    setSelectedActivityCurrency(activity.currency);
  };

  const handleActivityAddEdit = () => {
    if (!esgPlan.isEditActivity) {
      addActivity(
        login.token,
        esgPlan.currentTopic.assessment_topic_id,
        activityTitle,
        activityestimatedPeriod,
        activityBudget,
        activityComment,
        activityFile,
        assessment_id,
        false,
        selectedActivityCurrency,
        esgPlan.currentTopic.topic_name
      );
    } else {
      editActivity(
        login.token,
        esgPlan.currentTopic.assessment_topic_id,
        currentActivityID,
        activityTitle,
        activityestimatedPeriod,
        activityBudget,
        activityComment,
        activityFile,
        assessment_id,
        false,
        selectedActivityCurrency,
        esgPlan.currentTopic.topic_name
      );
    }
  };

  const handleAddKPI = (kpi) => {
    setUnitValue("");
    setKpiTitle("");
    setUnit(1);
    setBaselineValue(0);
    setTargetValue(0);
    setBaseline(false);
    setTarget(false);
    setKpiEstimatedPeriod([{ startDate: "", endDate: "" }]);
    setKpiBudget("");
    setKpiComment("");
    setKpiFile(null);
    setTopicState(2);
    setIsEditKPI(false);
    setSelectedKpiCurrency("");
  };

  const handleEditKPI = (kpi) => {
    setCurrentKpiId(kpi.kpi_id);
    setKpiTitle(kpi.title);
    setUnit(kpi.unit);
    setUnitValue(kpi.unit);

    if (kpi.unit === 3) {
      setBaseline(kpi.baseline === "" ? false : kpi.baseline);
      setTarget(kpi.target === "" ? false : kpi.target);
      setBaselineValue(0);
      setTargetValue(0);
    } else {
      setBaseline(false);
      setTarget(false);
      setBaselineValue(kpi.baseline === "" ? 0 : kpi.baseline);
      setTargetValue(kpi.target === "" ? 0 : kpi.target);
    }

    if (
      kpi.estimated_period[0].startDate !== "" &&
      kpi.estimated_period[0].endDate !== ""
    ) {
      setKpiEstimatedPeriod([
        {
          startDate: new Date(kpi.estimated_period[0].startDate),
          endDate: new Date(kpi.estimated_period[0].endDate),
        },
      ]);
    } else {
      setKpiEstimatedPeriod([{ startDate: "", endDate: "" }]);
    }

    setKpiBudget(kpi.budget);
    setKpiComment(kpi.comments);
    setKpiFile(kpi.file);
    setTopicState(2);
    setIsEditKPI(true);
    setSelectedKpiCurrency(kpi.currency);
  };

  const handleKpiAddEdit = () => {
    if (!esgPlan.isEditKPI) {
      addKPI(
        login.token,
        esgPlan.currentTopic.assessment_topic_id,
        kpiTitle,
        unit === 3 ? baseline : baselineValue,
        unit === 3 ? target : targetValue,
        unit === 1 ? unitValue : unit,
        kpiEstimatedPeriod,
        kpiBudget,
        kpiComment,
        kpiFile,
        assessment_id,
        false,
        selectedKpiCurrency,
        esgPlan.currentTopic.topic_name
      );
    } else {
      editKPI(
        login.token,
        esgPlan.currentTopic.assessment_topic_id,
        currentKpiId,
        kpiTitle,
        unit === 3 ? baseline : baselineValue,
        unit === 3 ? target : targetValue,
        unit === 1 || typeof unit === "string" ? unitValue : unit,
        kpiEstimatedPeriod,
        kpiBudget,
        kpiComment,
        kpiFile,
        assessment_id,
        false,
        selectedKpiCurrency,
        esgPlan.currentTopic.topic_name
      );
    }
    if (!esgPlan.units.includes(unitValue) && unitValue.trim() !== "") {
      addUnit(login.token, unitValue.trim());
    }
  };

  const handleAssignDialogCancel = () => {
    setIsAssignDialogOpen(false);
  };
  const handleAssignDialog = () => {
    assignMembers(
      login.token,
      emails,
      3,
      assessment_id,
      login.current_organisation,
      topicId
    );
    setIsAssignDialogOpen(false);
    setTopicId([]);
    setEmails([]);
  };

  const handleAssignDailogueOpen = () => {
    viewAssignMembers(
      login.token,
      assessment_id,
      3,
      esgPlan.currentTopic.topic_id
    );
    setIsAssignDialogOpen(true);
    setTopicId([esgPlan.currentTopic.topic_id]);
  };

  const disableAddKpiButton = () => {
    if (unit === 1 && (kpiTitle === "" || unitValue.trim() === "")) {
      return true;
    } else if (unit === 2 && kpiTitle === "") {
      return true;
    } else if (unit === 3 && kpiTitle === "") {
      return true;
    }
  };

  const CustomPopper = (props) => {
    return <Popper {...props} placement="bottom" />;
  };
  const assignTopics = () => {
    return (
      <Dialog
        maxWidth="lg"
        open={isAssignDialogOpen}
        onClose={handleAssignDialog}
      >
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography2}>
                Assign Activity / KPI
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div className={classes.div2}>
            <Typography className={classes.subText}>
              Select the sub metric you want to assign
            </Typography>
            <Paper elevation={0} className={classes.Paper}>
              <Autocomplete
                size="small"
                multiple
                value={emails}
                options={generalSettings.allUsers.map((user) => user.email)}
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ));
                }}
                onChange={(event, newValue) => {
                  setEmails(newValue);
                }}
                renderInput={(params) => (
                  <>
                    <CustomTextfield
                      {...params}
                      size="small"
                      variant="outlined"
                      fullWidth
                      label="Search By Email ID or Name"
                      name="asgEmailStep3"
                    />
                  </>
                )}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue === null ? " " : newInputValue);
                  setKeyword(newInputValue === null ? " " : newInputValue);
                }}
              />
            </Paper>
            {esgPlan.assignedMembers.length > 0 && (
              <Box className={classes.Box}>
                {esgPlan.assignedMembers.length > 0 &&
                  esgPlan.assignedMembers.map((user, index) => (
                    <Box
                      key={index}
                      my={1}
                      className={clsx(classes.user, classes.Box1)}
                    >
                      <Avatar
                        className={classes.Avatar}
                        src={
                          user.profile_img && user.profile_img.url
                            ? user.profile_img.url
                            : `https://avatars.dicebear.com/api/initials/${user.name}.svg?bold=1`
                        }
                      />
                      <Box className={classes.Box2}>
                        <Box className={classes.Box3}>
                          <Typography className={classes.Typography4}>
                            {user.name}
                          </Typography>
                          <Typography style={{ fontSize: 12 }}>
                            {user.email}
                          </Typography>
                        </Box>
                        <CustomButton
                          className={classes.CustomButton1}
                          onClick={() =>
                            deleteAssignedMember(
                              login.token,
                              user.stakeholder_id,
                              assessment_id,
                              3,
                              esgPlan.currentTopic.topic_id
                            )
                          }
                        >
                          Remove
                        </CustomButton>
                      </Box>
                    </Box>
                  ))}
              </Box>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={handleAssignDialogCancel}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            disabled={emails.length < 1}
            onClick={handleAssignDialog}
            variant="contained"
            color="primary"
          >
            Assign User
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div data-tut="reactour__esgRight" style={{ height: "100%" }}>
      {deleteConfirmation && deleteConfirm()}
      {isDatePickerDialogOpen && datePickerDialogActivity()}
      {isDatePickerDialogOpen2 && datePickerDialogKPI()}
      {assignTopics()}
      {esgPlan.currentTopic !== "" ? (
        <Card style={{ height: "100%" }} className={classes.cardContainer}>
          <CardContent
            className={clsx(classes.CardContent, classes.CardContent1)}
          >
            {/* For Home */}
            {esgPlan.topicState === 0 && (
              <>
                {/* Title */}
                <div className={classes.homeHeadingContainer}>
                  <div className={classes.Box1}>
                    <TopicIcon style={{ fontSize: 46 }} />
                    <Typography className={classes.Typography4}>
                      {esgPlan.currentTopic.topic_name}
                    </Typography>
                  </div>
                  <CustomButton
                    name="assignStk"
                    onClick={() => handleAssignDailogueOpen()}
                    startIcon={<PersonAddOutlinedIcon />}
                  >
                    Assign
                  </CustomButton>
                </div>

                <div className="scroll" style={{ height: "60vh" }}>
                  {/* Activity Section */}
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.grid2}
                  >
                    <Grid item>
                      <Typography className={classes.Typography4}>
                        Activity
                      </Typography>
                    </Grid>
                    <Grid item>
                      <CustomButton
                        name="addActivity"
                        startIcon={<AddCircleOutlineIcon />}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={handleAddActivity}
                      >
                        Add Activity
                      </CustomButton>
                    </Grid>
                  </Grid>
                  <Box my={2}>
                    {esgPlan.topicDetails &&
                    esgPlan.topicDetails.activity &&
                    esgPlan.topicDetails.activity.length > 0 ? (
                      esgPlan.topicDetails.activity.map((activity, index) => (
                        <ActivityBar
                          key={index}
                          index={index + 1}
                          activity={activity}
                        />
                      ))
                    ) : (
                      <div className={classes.emptyContainer}>
                        <Typography>No Activities added</Typography>
                      </div>
                    )}
                  </Box>

                  {/* add kpiKPI Section */}
                  <Grid
                    container
                    item
                    justifyContent="space-between"
                    className={classes.grid2}
                  >
                    <Grid>
                      <Typography className={classes.Typography4}>
                        Key performance indicator
                      </Typography>
                    </Grid>
                    <Grid>
                      <CustomButton
                        className={classes.addKpiBtn}
                        startIcon={<AddCircleOutlineIcon />}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={handleAddKPI}
                      >
                        Add KPI
                      </CustomButton>
                    </Grid>
                  </Grid>
                  <Box my={2}>
                    {esgPlan.topicDetails &&
                    esgPlan.topicDetails.kpi &&
                    esgPlan.topicDetails.kpi.length > 0 ? (
                      esgPlan.topicDetails.kpi.map((kpi, index) => (
                        <KPIBar key={index} index={index + 1} kpi={kpi} />
                      ))
                    ) : (
                      <div className={classes.emptyContainer}>
                        <Typography>No KPI added</Typography>
                      </div>
                    )}
                  </Box>
                </div>
              </>
            )}

            {/* For Activity */}
            {esgPlan.topicState === 1 && (
              <>
                <Grid container alignItems="center">
                  {/* Title and Back Button */}
                  <Grid
                    item
                    xs={9}
                    sm={9}
                    md={8}
                    lg={9}
                    className={classes.homeHeadingContainer}
                  >
                    <Box display="flex" alignItems="center">
                      <IconButton
                        onClick={() => {
                          setSuggestions(true);
                          setTopicState(0);
                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                      <Typography className={classes.Typography5}>
                        Add Activity
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Add Button */}
                  <Grid item xs={3} sm={3} md={4} lg={3}>
                    <CustomButton
                      startIcon={<AddCircleOutlineIcon />}
                      variant="contained"
                      color="secondary"
                      size="small"
                      disabled={activityTitle === ""}
                      onClick={handleActivityAddEdit}
                    >
                      {esgPlan.isEditActivity
                        ? "Update Activity"
                        : "Add Activity"}
                    </CustomButton>
                  </Grid>
                </Grid>

                <div className="scroll" style={{ height: "60vh" }}>
                  <Grid container className={classes.grid2}>
                    {/* Title */}
                    <Grid item xs={12}>
                      <Box my={2}>
                        <CustomTextfield
                          name="actTitle"
                          label="Activity Title"
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={activityTitle}
                          onChange={(e) => setActivityTitle(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    {/* suggesstions */}
                    {esgPlan.topicDetails.activity_suggestions ? (
                      <Grid item xs={12} md={7}>
                        <Box my={2} style={{ margin: 0 }}>
                          <MuiThemeProvider theme={suggestion}>
                            {suggestions ? (
                              <Button
                                startIcon={<AddCircleOutlineIcon />}
                                color="primary"
                                size="small"
                                onClick={() => setSuggestions(false)}
                              >
                                Some Suggestion Are Available
                              </Button>
                            ) : (
                              <Button
                                startIcon={<RemoveCircleOutlineOutlinedIcon />}
                                color="primary"
                                size="small"
                                onClick={() => setSuggestions(true)}
                              >
                                Hide suggesstions
                              </Button>
                            )}
                          </MuiThemeProvider>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={7}></Grid>
                    )}

                    <Grid item xs={12} md={5}></Grid>

                    {/* suggesstions points */}

                    {!suggestions &&
                      esgPlan.topicDetails.activity_suggestions && (
                        <>
                          <Grid item xs={12} md={12}>
                            <Box my={2} style={{ margin: 0 }}>
                              {esgPlan.topicDetails.activity_suggestions.map(
                                (suggestion, index) => (
                                  <Chip
                                    key={index}
                                    className={classes.suggestionChip}
                                    label={
                                      <Typography
                                        className={classes.Typography6}
                                      >
                                        {suggestion}
                                      </Typography>
                                    }
                                    onClick={(e) => {
                                      setActivityTitle(e.target.innerText);
                                      setSuggestions(true);
                                    }}
                                  />
                                )
                              )}
                            </Box>
                          </Grid>
                        </>
                      )}
                    {/* Estimate Period */}
                    <Grid item xs={12} md={6} sm={7} lg={5}>
                      <Box
                        my={2}
                        onClick={() => setIsDatePickerDialogOpen(true)}
                        className={classes.Box4}
                        name="estDate"
                      >
                        <Typography
                          variant="body1"
                          className={classes.Typography10}
                        >
                          {activityestimatedPeriod[0].startDate !== "" &&
                          activityestimatedPeriod[0].endDate !== ""
                            ? `${moment(
                                new Date(activityestimatedPeriod[0].startDate)
                              ).format("DD MMM yy")} - ${moment(
                                new Date(activityestimatedPeriod[0].endDate)
                              ).format("DD MMM yy")}`
                            : "Estimated Period"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sm={5} lg={7}></Grid>

                    {/* currency /  Budget */}
                    <Grid item xs={12} md={9}>
                      <Box display="flex" my={2}>
                        <CustomTextfield
                          name="actCurrSelector"
                          size="small"
                          select
                          fullWidth
                          label="Currency"
                          variant="outlined"
                          value={selectedActivityCurrency}
                        >
                          {currencyOptions.map((curr, index) => {
                            return (
                              <MenuItem
                                key={index}
                                name={curr.code.substring(0, 4)}
                                onClick={() =>
                                  setSelectedActivityCurrency(curr.code)
                                }
                                value={curr.code}
                              >
                                {`${curr.code} - ${curr.symbol}`}
                              </MenuItem>
                            );
                          })}
                        </CustomTextfield>

                        <CustomTextfield
                          name="actBudget"
                          className={classes.CustomTextfield}
                          label="Budget"
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={activityBudget}
                          onChange={(e) => setActivityBudget(e.target.value)}
                        />
                      </Box>
                    </Grid>

                    {/* Additional Comment */}
                    <Grid item xs={12}>
                      <Box my={2}>
                        <CustomTextfield
                          name="actAddComment"
                          label="Additional Comment"
                          fullWidth
                          multiline
                          minRows={3}
                          size="small"
                          variant="outlined"
                          value={activityComment}
                          onChange={(e) => setActivityComment(e.target.value)}
                        />
                      </Box>
                    </Grid>

                    {/* File */}
                    <Grid item xs={12} md={9}>
                      <Box my={2}>
                        {activityFile === "" || activityFile === null ? (
                          <>
                            <input
                              name="actUpload"
                              id="contained-button-activityFile"
                              type="file"
                              onChange={(event) =>
                                setActivityFile(event.target.files[0])
                              }
                              className={classes.input1}
                            />
                            <label htmlFor="contained-button-activityFile">
                              <Box
                                display="flex"
                                component="span"
                                alignItems="center"
                                className={classes.pointer}
                              >
                                <AttachFileIcon color="disabled" />
                                <Box
                                  mx={2}
                                  className={classes.BoxG}
                                  width="100%"
                                >
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    Upload activity File
                                  </Typography>
                                </Box>
                              </Box>
                            </label>
                          </>
                        ) : (
                          <>
                            <Box display="flex" alignItems="center">
                              <AttachFileIcon color="disabled" />
                              <Box mx={2} className={classes.Box6} width="100%">
                                <Typography variant="caption" color="primary">
                                  {activityFile.name}
                                </Typography>
                              </Box>
                              <ClearIcon
                                color="primary"
                                className={classes.pointer}
                                onClick={() => {
                                  removeFile(
                                    login.token,
                                    assessment_id,
                                    3,
                                    activityFile.url,
                                    "activity_file",
                                    esgPlan.currentTopic.assessment_topic_id,
                                    currentActivityID
                                  );
                                  setActivityFile(null);
                                }}
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}

            {/* For KPI */}
            {esgPlan.topicState === 2 && (
              <>
                <Grid container alignItems="center">
                  {/* Title and Back Button */}
                  <Grid item xs={9} className={classes.homeHeadingContainer}>
                    <Box display="flex" alignItems="center">
                      <IconButton
                        onClick={() => {
                          setSuggestions(true);
                          setTopicState(0);
                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                      <Typography className={classes.Typography4}>
                        Add Key Performance Indicator
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Add KPI Button */}
                  <Grid item xs={3}>
                    <CustomButton
                      startIcon={<AddCircleOutlineIcon />}
                      variant="contained"
                      color="secondary"
                      size="small"
                      disabled={disableAddKpiButton()}
                      onClick={handleKpiAddEdit}
                    >
                      {esgPlan.isEditKPI ? "Update KPI" : "Add KPI"}
                    </CustomButton>
                  </Grid>
                </Grid>

                <div className="scroll" style={{ height: "60vh" }}>
                  <Grid container className={classes.grid2}>
                    {/* Title */}
                    <Grid item xs={12}>
                      <Box my={2}>
                        <CustomTextfield
                          name="kpiTitle"
                          label="KPI Title"
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={kpiTitle}
                          onChange={(e) => setKpiTitle(e.target.value)}
                        />
                      </Box>
                    </Grid>

                    {/* suggesstions */}
                    {esgPlan.topicDetails.kpi_suggestions ? (
                      <Grid item xs={12} md={7}>
                        <Box my={2} style={{ margin: 0 }}>
                          <MuiThemeProvider theme={suggestion}>
                            {suggestions ? (
                              <Button
                                startIcon={<AddCircleOutlineIcon />}
                                color="primary"
                                size="small"
                                onClick={() => setSuggestions(false)}
                              >
                                Some Suggestion Are Available
                              </Button>
                            ) : (
                              <Button
                                startIcon={<RemoveCircleOutlineOutlinedIcon />}
                                color="primary"
                                size="small"
                                onClick={() => setSuggestions(true)}
                              >
                                Hide suggesstions
                              </Button>
                            )}
                          </MuiThemeProvider>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={7}></Grid>
                    )}

                    <Grid item xs={12} md={5}></Grid>

                    {/* suggesstions points */}

                    {!suggestions && esgPlan.topicDetails.kpi_suggestions && (
                      <>
                        <Grid item xs={12} md={12}>
                          <Box my={2} style={{ margin: 0 }}>
                            {esgPlan.topicDetails.kpi_suggestions.map(
                              (suggestion, index) => (
                                <Chip
                                  key={index}
                                  className={classes.suggestionChip}
                                  label={
                                    <Typography className={classes.Typography7}>
                                      {suggestion}
                                    </Typography>
                                  }
                                  onClick={(e) => {
                                    setKpiTitle(e.target.innerText);
                                    setSuggestions(true);
                                  }}
                                />
                              )
                            )}
                          </Box>
                        </Grid>
                      </>
                    )}
                    {/* Unit Selector */}
                    <Grid item xs={5}>
                      <Box my={2}>
                        <CustomTextfield
                          name="sltBaseType"
                          select
                          fullWidth
                          label="Data Type Picker"
                          size="small"
                          value={unit === 2 || unit === 3 ? unit : 1}
                          onChange={(event) => {
                            setUnit(event.target.value);
                          }}
                          variant="outlined"
                        >
                          <MenuItem
                            value={1}
                            onClick={() => {
                              setBaselineValue(0);
                              setTargetValue(0);
                              setUnitValue("");
                            }}
                          >
                            Numerical Type
                          </MenuItem>
                          <MenuItem
                            value={2}
                            onClick={() => {
                              setBaselineValue(0);
                              setTargetValue(0);
                            }}
                          >
                            Percentage
                          </MenuItem>
                          <MenuItem
                            value={3}
                            onClick={() => {
                              setBaselineValue("No");
                              setTargetValue("No");
                            }}
                          >
                            Yes / No
                          </MenuItem>
                        </CustomTextfield>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7}></Grid>

                    {/* Baseline */}
                    <Grid item xs={4}>
                      <Typography
                        color="primary"
                        variant="body2"
                        className={classes.Typography8}
                      >
                        Baseline
                      </Typography>
                      <Box m={2}>
                        {(typeof unit === "string" || unit === 1) && (
                          <Card variant="outlined">
                            <CardContent>
                              <CustomTextfield
                                name="ntBaseLIne"
                                variant="outlined"
                                type="number"
                                value={baselineValue === "" ? 0 : baselineValue}
                                onChange={(event) => {
                                  setBaselineValue(event.target.value);
                                }}
                              />
                            </CardContent>
                          </Card>
                        )}
                        {unit === 2 && (
                          <>
                            <Card variant="outlined" className={classes.Card}>
                              <CardContent>
                                <Typography variant="subtitle1">
                                  Score
                                </Typography>
                                <Typography
                                  name="prBaseLIne"
                                  variant="h6"
                                  className={classes.Typography9}
                                >
                                  {baselineValue}
                                </Typography>
                              </CardContent>
                            </Card>
                            <AirbnbSlider
                              ThumbComponent={AirbnbThumbComponent}
                              value={
                                typeof baselineValue === typeof 0
                                  ? baselineValue
                                  : 0
                              }
                              onChange={(event, newValue) => {
                                setBaselineValue(newValue);
                              }}
                            />
                          </>
                        )}
                        {unit === 3 && (
                          <Card variant="outlined">
                            <CardContent className={classes.CardContent2}>
                              <Switch
                                name="ynBaseline"
                                checked={baseline}
                                onChange={(e) => {
                                  setBaseline(e.target.checked);
                                }}
                                // name="checkedA"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                              {baseline ? "Yes" : "No"}
                            </CardContent>
                          </Card>
                        )}
                      </Box>
                    </Grid>

                    {/* Targetline */}
                    <Grid item xs={4}>
                      <Typography
                        color="primary"
                        variant="body2"
                        className={classes.Typography8}
                      >
                        Target
                      </Typography>
                      <Box m={2}>
                        {(typeof unit === "string" || unit === 1) && (
                          <Card variant="outlined">
                            <CardContent>
                              <CustomTextfield
                                name="ntTarget"
                                variant="outlined"
                                type="number"
                                value={targetValue === "" ? 0 : targetValue}
                                onChange={(event) => {
                                  setTargetValue(event.target.value);
                                }}
                              />
                            </CardContent>
                          </Card>
                        )}
                        {unit === 2 && (
                          <>
                            <Card variant="outlined" className={classes.Card}>
                              <CardContent>
                                <Typography variant="subtitle1">
                                  Score
                                </Typography>
                                <Typography
                                  name="prTarget"
                                  variant="h6"
                                  className={classes.Typography9}
                                >
                                  {targetValue}
                                </Typography>
                              </CardContent>
                            </Card>
                            <AirbnbSlider
                              ThumbComponent={AirbnbThumbComponenttwo}
                              value={
                                typeof targetValue === "number"
                                  ? targetValue
                                  : 0
                              }
                              onChange={(event, newValue) => {
                                setTargetValue(newValue);
                              }}
                            />
                          </>
                        )}
                        {unit === 3 && (
                          <Card variant="outlined">
                            <CardContent className={classes.CardContent2}>
                              <Switch
                                name="ynTarget"
                                checked={target}
                                onChange={(e) => {
                                  setTarget(e.target.checked);
                                }}
                                // name="checkedA"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                              {target ? "Yes" : "No"}
                            </CardContent>
                          </Card>
                        )}
                      </Box>
                    </Grid>

                    {/* Unit */}
                    <Grid item xs={4}>
                      <Typography
                        color="primary"
                        variant="body2"
                        className={classes.Typography8}
                      >
                        Unit
                      </Typography>
                      <Box m={2}>
                        <Card variant="outlined">
                          <CardContent className={classes.CardContent3}>
                            {(typeof unit === "string" || unit === 1) && (
                              <>
                                {/* <CustomTextfield
                                  name="ntUnitDrp"
                                  select
                                  fullWidth
                                  label="Unit"
                                  variant="outlined"
                                  value={unitValue}
                                  onChange={(e) => setUnitValue(e.target.value)}
                                >
                                  {tempUnit.map((unit) => {
                                    return (
                                      <MenuItem key={unit} value={unit}>
                                        {unit}
                                      </MenuItem>
                                    );
                                  })}
                                </CustomTextfield> */}

                                <Autocomplete
                                  className={classes.unitSelect}
                                  freeSolo={true}
                                  // value={unitValue}
                                  options={
                                    esgPlan.units.length > 0 &&
                                    esgPlan.units.map((unit) => unit.unit)
                                  }
                                  onChange={(event, newValue) => {
                                    // console.log('on Change',event && event.target.value)
                                  }}
                                  renderInput={(params) => (
                                    <>
                                      <CustomTextfield
                                        {...params}
                                        size="medium"
                                        variant="outlined"
                                        fullWidth
                                        label="Unit"
                                        name="asgEmailStep3"
                                      />
                                    </>
                                  )}
                                  inputValue={unitValue}
                                  onInputChange={(event, newInputValue) => {
                                    event && setUnitValue(newInputValue);
                                  }}
                                  PopperComponent={CustomPopper}
                                />
                              </>
                            )}
                            {unit === 2 && (
                              <Typography className={classes.Typography10}>
                                Percentage
                              </Typography>
                            )}
                            {unit === 3 && (
                              <Typography className={classes.Typography10}>
                                Yes/No
                              </Typography>
                            )}
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>

                    {/* Estimate Period */}
                    <Grid item xs={12} sm={6} md={6}>
                      <Box
                        my={2}
                        onClick={() => setisDatePickerDialogOpen2(true)}
                        className={classes.Box5}
                      >
                        <Typography
                          name="kpiEstDate"
                          variant="body1"
                          className={classes.Typography10}
                        >
                          {kpiEstimatedPeriod[0].startDate !== "" &&
                          kpiEstimatedPeriod[0].endDate !== ""
                            ? `${moment(
                                new Date(kpiEstimatedPeriod[0].startDate)
                              ).format("DD MMM yy")} - ${moment(
                                new Date(kpiEstimatedPeriod[0].endDate)
                              ).format("DD MMM yy")}`
                            : "Estimated Period"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}></Grid>

                    {/* Budget */}
                    <Grid item xs={12} md={9}>
                      <Box display="flex" my={2}>
                        <CustomTextfield
                          name="kpiCurrSelector"
                          size="small"
                          select
                          fullWidth
                          label="Currency"
                          variant="outlined"
                          value={selectedKpiCurrency}
                        >
                          {currencyOptions.map((curr, index) => {
                            return (
                              <MenuItem
                                key={index}
                                onClick={() =>
                                  setSelectedKpiCurrency(curr.code)
                                }
                                value={curr.code}
                                name={curr.code.substring(0, 4)}
                              >
                                {`${curr.code} - ${curr.symbol}`}
                              </MenuItem>
                            );
                          })}
                        </CustomTextfield>
                        <CustomTextfield
                          name="kpiBudget"
                          className={classes.CustomTextfield}
                          label="Budget"
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={kpiBudget}
                          onChange={(e) => setKpiBudget(e.target.value)}
                        />
                      </Box>
                    </Grid>

                    {/* Additional Comment */}
                    <Grid item xs={12}>
                      <Box my={2}>
                        <CustomTextfield
                          name="kpiAddComment"
                          label="Additional Comment"
                          fullWidth
                          multiline
                          minRows={3}
                          size="small"
                          variant="outlined"
                          value={kpiComment}
                          onChange={(e) => setKpiComment(e.target.value)}
                        />
                      </Box>
                    </Grid>

                    {/* File */}
                    <Grid item xs={12} md={9}>
                      <Box my={2}>
                        {kpiFile === "" || kpiFile === null ? (
                          <>
                            <input
                              name="kpiUpload"
                              id="contained-button-kpiFile"
                              type="file"
                              onChange={(event) =>
                                setKpiFile(event.target.files[0])
                              }
                              className={classes.input1}
                            />
                            <label htmlFor="contained-button-kpiFile">
                              <Box
                                display="flex"
                                component="span"
                                alignItems="center"
                                className={classes.pointer}
                              >
                                <AttachFileIcon color="disabled" />
                                <Box
                                  mx={2}
                                  className={classes.BoxG}
                                  width="100%"
                                >
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    Upload KPI File
                                  </Typography>
                                </Box>
                              </Box>
                            </label>
                          </>
                        ) : (
                          <>
                            <Box display="flex" alignItems="center">
                              <AttachFileIcon color="disabled" />
                              <Box mx={2} className={classes.Box6} width="100%">
                                <Typography variant="caption" color="primary">
                                  {kpiFile.name}
                                </Typography>
                              </Box>
                              <ClearIcon
                                color="primary"
                                className={classes.pointer}
                                onClick={() => {
                                  removeFile(
                                    login.token,
                                    assessment_id,
                                    3,
                                    kpiFile.url,
                                    "kpi_file",
                                    esgPlan.currentTopic.assessment_topic_id,
                                    "",
                                    currentKpiId
                                  );
                                  setKpiFile(null);
                                }}
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </CardContent>
        </Card>
      ) : (
        <Card style={{ height: "100%" }}>
          <CardContent className="scroll">
            <Box className={classes.Box7}>
              <Typography className={classes.Typography4}>
                {esgPlan.selectedTopics.length === 0
                  ? "Complete Materiality Assessment to initialise ESG plan."
                  : "Select A Topic To Begin With"}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
