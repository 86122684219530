import React from "react";
import Typography from "@material-ui/core/Typography";
import CustomTextfield from "../../../UI/textfield/textfield";
import { useDispatch } from "react-redux";
import useDebounce from "../../../../customHooks/useDebouncec";

function AxisLabelsComponents({ axisLabels, setAxisLabels }) {
  const [xValue, setXvalue] = React.useState(axisLabels?.x_labels);

  const [yValue, setYvalue] = React.useState(axisLabels?.y_labels);

  useDebounce(
    () => {
      setAxisLabels((pre) => ({
        ...pre,
        x_labels: xValue,
      }));
    },
    3000,
    [xValue]
  );

  useDebounce(
    () => {
      setAxisLabels((pre) => ({
        ...pre,
        y_labels: yValue,
      }));
    },
    3000,
    [yValue]
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
      <Typography style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}>
        Axis Labels
      </Typography>
      <CustomTextfield
        name="X-Axis"
        variant="outlined"
        fullWidth
        size="small"
        label="X-Axis Label"
        value={xValue}
        onChange={(e) => {
          setXvalue(e.target.value);
        }}
      />

      <CustomTextfield
        name="Y-Axis"
        variant="outlined"
        fullWidth
        size="small"
        label="Y-Axis Label"
        value={yValue}
        onChange={(e) => {
          setYvalue(e.target.value);
        }}
      />
    </div>
  );
}

export default AxisLabelsComponents;
