import React from "react";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

export default function HelperButton({ setIsDrawerOpen }) {
  const useStyles = makeStyles(() => ({
    Fab: {
      position: "fixed",
      right: 30,
      bottom: 30,
      backgroundColor: "#000000",
      color: "#ffffff",
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <Fab
        name="stepHelpPopup"
        size="small"
        className={classes.Fab}
        data-tut="reactour__que"
        onClick={() => setIsDrawerOpen(true)}
      >
        <Typography>?</Typography>
      </Fab>
    </div>
  );
}
