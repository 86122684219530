import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

// -------- stylings -----------
export const textStyle = (type) => {
  let weight = 400;
  if (type === "main") {
    weight = 500;
  }

  return {
    color: "#15314E",
    fontWeight: weight,
    fontSize: "14px",
    lineHeight: "20px",
  };
};

export const statusStyle = (status) => {
  let colorToUse = "#8C7038";
  let bgColorToUse = "#FFEFD0";

  if (status === "complete") {
    colorToUse = "#5BB37F";
    bgColorToUse = "#EBF6EF";
  }

  return {
    width: "max-content",
    minWidth: "9em",
    height: "32px",
    borderRadius: 20,
    textTransform: "capitalize",
    padding: "8px 12px 8px 12px",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    backgroundColor: bgColorToUse,
    gap: "4px",
  };
};

export const useTableStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  headingText: {
    color: "#15314E",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  tableRowHover: {
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#EBF1F8",
      cursor: "pointer",
    },
  },
});

const DynamicTable = ({ headings = [], children }) => {
  const classes = useTableStyles();
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (index) => {
    const clickedHeading = headings[index];
    if (clickedHeading.showSort) {
      setSortKey(index);
      setSortOrder(sortKey === index && sortOrder === "asc" ? "desc" : "asc");
    }
  };

  return (
    <Table className={classes.table} aria-label="dynamic table">
      <TableHead className={classes.tableHead}>
        <TableRow>
          {headings?.length > 0 &&
            headings?.map((heading, index) => (
              <TableCell key={index}>
                <Box className={classes.heading}>
                  <Typography className={classes.headingText}>
                    {heading?.name || heading}
                  </Typography>
                  {heading.showSort && (
                    <ExpandMoreRoundedIcon
                      onClick={() => handleSort(index)}
                      style={{
                        color: sortKey === index ? "#3374B9" : "#3374B9",
                        transform:
                          sortKey === index && sortOrder === "asc"
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                      }}
                    />
                  )}
                </Box>
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
};

export default DynamicTable;
