import React, { useState, useRef } from "react";
import CustomTextfield from "../../../UI/textfield/textfield";
import useDebounce from "../../../../customHooks/useDebouncec";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

function ValidSurveyType(props) {
  const {
    isUpsertMetricData,
    setIsUpsertMetricData,
    setOnSubmitObject,
    fieldData,
    login,
    assessment_id,
    metricId,
    index,
    validation,
    typeOneData,
    setTypeOneData,
    metricComment,
    metricFile,
    classes,
    metric_name,
    metric_desc,
    parentId,
    parent_metric_id,
  } = props;
  const refer = useRef(null);

  const [valid, setValid] = useState({
    error: false,
    helper_text: "",
  });

  const [currentIndex, setIndex] = React.useState(null);
  const [add, setAdd] = React.useState(false);

  useDebounce(
    () => {
      if (isUpsertMetricData && add) {
        setOnSubmitObject({
          token: login.token,
          assessment_id,
          metricId,
          currentMetric_id: fieldData.currentMetric._id,
          data_type: typeOneData,
          metricComment,
          metricFile,
          metric_name,
          metric_desc,
          parent_id: parentId,
          parent_metric_id,
        });
        setIndex(null);
        setAdd(false);
      }
    },
    100,
    [typeOneData, isUpsertMetricData]
  );

  function Dbounce() {
    setOnSubmitObject({
      token: login.token,
      assessment_id,
      metricId,
      currentMetric_id: fieldData.currentMetric._id,
      data_type: typeOneData,
      metricComment,
      metricFile,
      metric_name,
      metric_desc,
      parent_id: parentId,
      parent_metric_id,
    });
    setIndex(null);
  }

  function checkValid(valid, string) {
    if (string === "") {
      setValid({ error: false, helper_text: "" });
    } else {
      if (valid === "21-digit" && currentIndex === index) {
        if (string.length === 21) {
          Dbounce();
          setValid({ error: false, helper_text: "" });
        } else if (string.length > 21) {
          setValid({
            error: true,
            helper_text: "CIN number should not be more than 21 digits",
          });
        } else {
          setValid({
            error: true,
            helper_text: "CIN number must be of 21 digits",
          });
        }
      } else if (valid === "none" && currentIndex === index) {
        setAdd(true);
      } else {
        setAdd(true);
      }
    }
  }

  const callBack = React.useCallback(() => {
    if (validation === null && currentIndex === index) {
      setAdd(true);
    } else if (validation !== null && currentIndex === index) {
      checkValid(validation.schema, `${typeOneData[index].data}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOneData, currentIndex, index]);

  React.useEffect(() => {
    callBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOneData]);

  return (
    <>
      {validation && validation.type === "date" ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            format="yyyy"
            inputVariant="outlined"
            size="small"
            fullWidth
            views={["year"]}
            value={
              typeOneData[index].data === ""
                ? new Date()
                : typeOneData[index].data.toString()
            }
            onChange={(e) => {
              setTypeOneData((prevState) => {
                typeOneData[index].data = new Date(e).getFullYear();
                return [...prevState];
              });
              setIndex(index);
              if (!isUpsertMetricData) {
                setIsUpsertMetricData(true);
              }
            }}
          />
        </MuiPickersUtilsProvider>
      ) : (
        <CustomTextfield
          type={!validation ? "text" : validation.type}
          name={`Text_type1${index}`}
          margin="dense"
          onWheel={(e) => e.target.blur()}
          variant="outlined"
          className={classes.CustomTextfield}
          value={typeOneData[index].data}
          fullWidth
          error={valid.error}
          helperText={valid.helper_text}
          minRows={3}
          multiline={
            !validation
              ? true
              : validation.type === "number"
              ? false
              : validation.type === "date"
              ? false
              : true
          }
          inputRef={refer}
          onChange={(e) => {
            setTypeOneData((prevState) => {
              typeOneData[index].data = e.target.value;
              return [...prevState];
            });
            setIndex(index);
            if (!isUpsertMetricData) {
              setIsUpsertMetricData(true);
            }
          }}
        />
      )}
    </>
  );
}

export default React.memo(ValidSurveyType);
