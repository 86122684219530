import React from "react";
import {
  Box,
  Typography,
  IconButton,
  MenuItem,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// icons
import CloseIcon from "@material-ui/icons/Close";

// redux imports
import { useDispatch, useSelector } from "react-redux";
// import { addSurveyData } from "../../../../redux/actions/riskdashboard/riskDashboardActions";

import CustomTextfield from "../../../UI/textfield/textfield";
import { createSurvey } from "../../../../actions/supplier/supplierAction";

const SurveyDrawer = ({ handleDrawer }) => {
  // using redux
  const dispatch = useDispatch();

  const { token, current_organisation } = useSelector((state) => state.login);

  // const surveyTypes = ["Controversy Survey", "Self Assessment Survey"];

  // // data to be taken from redux/backend
  // const surveyCreatedBy = "Saad";
  // const surveyDated = "Jan 20, 2022";
  // const surveyStatus = true;

  const handleCreateSurvey = () => {
    // Get data from the form
    const surveyName = document.getElementById("surveyName").value;
    // const surveyType = document.getElementById("surveyType").value;
    const surveyDescription =
      document.getElementById("surveyDescription").value;

    // Create an object with survey data
    // const surveyData = {
    //   survey_name: surveyName,
    //   survey_type: null, //surveyType,
    //   survey_createdBy: surveyCreatedBy,
    //   survey_dated: surveyDated,
    //   survey_status: surveyStatus,
    //   survey_description: surveyDescription,
    // };

    // Dispatch the action to add survey data to the store
    dispatch(
      createSurvey(token, current_organisation, surveyName, surveyDescription)
    );

    // Reset the form or perform other actions as needed
    document.getElementById("surveyName").value = "";
    // document.getElementById("surveyType").value = "";
    document.getElementById("surveyDescription").value = "";

    // close drawer
    handleDrawer();
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: 700,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Drawer Header */}
      <Box
        sx={{
          boxShadow: "0px 2px 4px #3374B926",
          px: 2,
          py: 0.5,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: "600" }}>Create Survey</Typography>
          <IconButton
            onClick={() => {
              handleDrawer();
            }}
          >
            <CloseIcon style={{ color: "#3374B9" }} />
          </IconButton>
        </div>
      </Box>

      {/* Drawer Contents */}
      <Box
        sx={{
          p: 2,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div style={{ marginBottom: "20px" }}>
            <CustomTextfield
              id="surveyName"
              name="surveyName"
              variant="outlined"
              fullWidth
              size="small"
            />
          </div>

          {/* <div style={{ marginBottom: "20px" }}>
            <Typography variant="body2" style={{ marginBottom: "8px" }}>
              Survey Type
            </Typography>
            <Autocomplete
              id="surveyType"
              name="surveyType"
              options={surveyTypes}
              getOptionLabel={(option) => option}
              renderOption={(option) => <MenuItem>{option}</MenuItem>}
              size="small"
              renderInput={(params) => (
                <CustomTextfield {...params} variant="outlined" />
              )}
            />
          </div> */}

          <div style={{ marginBottom: "20px" }}>
            <Typography variant="body2" style={{ marginBottom: "8px" }}>
              Describe about the survey
            </Typography>
            <CustomTextfield
              id="surveyDescription"
              name="surveyDescription"
              variant="outlined"
              fullWidth
              multiline
              minRows={10}
              size="small"
            />
          </div>
        </div>

        {/* Drawer Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="text"
            style={{ color: "#3374B9", marginRight: "8px" }}
          >
            Save Details
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#3374B9", color: "#fff" }}
            onClick={handleCreateSurvey}
          >
            Create Survey
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SurveyDrawer;
