/* eslint-disable eqeqeq */
import React, { useState, useRef } from "react";
import Box from "@material-ui/core/Box";
import CustomTextfield from "../../../UI/textfield/textfield";
import useDebounce from "../../../../customHooks/useDebouncec";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

function ValidSurveyTypeOnePointOne(props) {
	const {
		isUpsertMetricData,
		setIsUpsertMetricData,
		setOnSubmitObject,
		fieldData,
		login,
		assessment_id,
		metricId,
		index,
		validation,
		typeOnePointOneData,
		setTypeOnePointOneData,
		metricComment,
		metricFile,
		classes,
		metric_name,
		metric_desc,
		parentId,
		parent_metric_id,
	} = props;
	const refer = useRef(null);

	useDebounce(
		() => {
			if (isUpsertMetricData) {
				setOnSubmitObject({
					token: login.token,
					assessment_id,
					metricId,
					currentMetric_id: fieldData.currentMetric._id,
					data_type: typeOnePointOneData,
					metricComment,
					metricFile,
					metric_name,
					metric_desc,
					parent_id: parentId,
					parent_metric_id,
				});
			}
		},
		100,
		[typeOnePointOneData, isUpsertMetricData]
	);

	const dataSplit = typeOnePointOneData[index].data.split(":");

	const [first, setFrist] = React.useState(dataSplit[0]);
	const [second, setSecond] = React.useState(dataSplit[1]);

	React.useEffect(() => {
		setTimeout(() => {
			let data = first;
			const res = typeof data === "string" ? data.split(".") : null;
			if (res && res.length > 1 && res[1].length > 2) {
				if (parseFloat(first).toFixed(2) != first) {
					setFrist(parseFloat(parseFloat(first).toFixed(2)));
				}
			}
		}, 200);
	}, [first]);

	React.useEffect(() => {
		setTimeout(() => {
			let data1 = second;
			const res2 = typeof data1 === "string" ? data1.split(".") : null;
			if (res2 && res2.length > 1 && res2[1].length > 2) {
				if (parseFloat(second).toFixed(2) != second) {
					setSecond(parseFloat(parseFloat(second).toFixed(2)));
				}
			}
		}, 200);
	}, [second]);

	React.useEffect(() => {
		const check = typeOnePointOneData[index].data.split(":");
		if (check[0] != first || check[1] != second) {
			setTypeOnePointOneData((prevState) => {
				typeOnePointOneData[index].data = `${first}:${second}`;
				return [...prevState];
			});

			if (!isUpsertMetricData) {
				setIsUpsertMetricData(true);
			}
		}
	}, [first, second]);

	return (
		<Box display="flex" alignItems="center">
			<CustomTextfield
				type="number"
				name={`Text_type1${index}`}
				margin="dense"
				onWheel={(e) => e.target.blur()}
				variant="outlined"
				className={classes.CustomTextfield}
				value={first}
				fullWidth
				inputRef={refer}
				onChange={(e) => setFrist(e.target.value)}
			/>
			<Box mx={1} fontSize={25} fontWeight={800}>
				:
			</Box>
			<CustomTextfield
				type="number"
				name={`Text_type1${index}`}
				margin="dense"
				onWheel={(e) => e.target.blur()}
				variant="outlined"
				className={classes.CustomTextfield}
				value={second}
				fullWidth
				inputRef={refer}
				onChange={(e) => setSecond(e.target.value)}
			/>
		</Box>
	);
}

export default React.memo(ValidSurveyTypeOnePointOne);
