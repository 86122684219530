import React from "react";

function ColorCubes({ color, selected, onClick }) {
  return (
    <div
      onClick={onClick}
      style={{
        width: 25,
        height: 25,
        borderRadius: 6,
        backgroundColor: color || "red",
        margin: 2,
        boxShadow: selected && `0px 0px 6px ${color || "red"}`,
        cursor:'pointer'
      }}
    />
  );
}

export default ColorCubes;
