import { useState, useRef, useEffect, useCallback } from "react";

// material ui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";

// icons
import { FiTrash } from "react-icons/fi";

// components
import CustomButton from "components/UI/button/button";
import Textfield from "components/UI/textfield/textfield";

const CreationTable = ({ headers, isEditing, handleTableData }) => {
  const [columns, setColumns] = useState(headers || []);
  const [focusIndex, setFocusIndex] = useState(null);
  const inputRefs = useRef([]);

  const updateTableData = useCallback(() => {
    handleTableData?.(columns);
  }, [columns, handleTableData]);

  useEffect(() => {
    if (focusIndex !== null) {
      inputRefs.current[focusIndex]?.focus();
      setFocusIndex(null); // Reset after focusing
    }
    updateTableData();
  }, [columns, updateTableData, focusIndex]);

  useEffect(() => {
    if (
      headers &&
      headers.length &&
      JSON.stringify(headers) !== JSON.stringify(columns)
    ) {
      setColumns(headers);
    }
  }, [headers]);

  const handleAddHeader = () => {
    setColumns((prev) => [
      ...prev,
      { header: `Header${prev.length + 1}`, type: "Text" },
    ]);
    setFocusIndex(columns.length); // Set focus to the new column
  };

  const handleColumnChange = (index, field, value) => {
    setColumns((prev) =>
      prev.map((col, i) => (i === index ? { ...col, [field]: value } : col))
    );
  };

  const handleDeleteColumn = (index) => {
    setColumns((prev) => prev.filter((_, i) => i !== index));
    setFocusIndex(index > 0 ? index - 1 : 0); // Set focus to the previous column if exists
  };

  return (
    <div style={{ position: "relative" }}>
      <TableContainer component={Paper} style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col, index) => (
                <TableCell
                  key={index}
                  style={{ minWidth: 150, position: "relative" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Textfield
                      color="primary"
                      variant="outlined"
                      size="small"
                      label={`Header ${index + 1}`}
                      value={col.header}
                      inputRef={(el) => (inputRefs.current[index] = el)}
                      onChange={(e) =>
                        handleColumnChange(index, "header", e.target.value)
                      }
                      style={{ flexGrow: 1 }}
                      disabled={!isEditing}
                    />
                    {isEditing && (
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDeleteColumn(index)}
                        style={{
                          position: "absolute",
                          top: 16,
                          right: 16,
                          transform: "translate(50%, -50%)",
                          backgroundColor: "#fff",
                        }}
                      >
                        <FiTrash color="#E91E63" />
                      </IconButton>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {columns.map((col, index) => (
                <TableCell key={index} style={{ minWidth: 150 }}>
                  <Select
                    value={col.type}
                    onChange={(e) =>
                      handleColumnChange(index, "type", e.target.value)
                    }
                    size="small"
                    displayEmpty
                    style={{ width: "100%" }}
                    disabled={!isEditing}
                  >
                    {["Text", "Number", "URL", "Email", "Document"].map(
                      (type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      {isEditing && (
        <div
          style={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            padding: 16,
            zIndex: 1,
          }}
        >
          <CustomButton
            color="primary"
            variant="contained"
            onClick={handleAddHeader}
          >
            Add Header
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default CreationTable;
