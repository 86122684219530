import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import CustomButton from "../../../UI/button/button";
import planStyles from "../plansStyles";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
function Plan(props) {
  const {
    generalSettings,
    setUpgradePlans,
    upgrade,
    login,
    plan,
    amount,
    next_payment_date,
    description,
    expireTag,
    active,
  } = props;
  const classes = planStyles();
  return (
    <Card
      variant="elevation"
      id="drawer-container"
      elevation={1}
      className={classes.cardContainer}
      // style={{
      //   backgroundColor: "#FFFFFF",
      //   borderRadius: 5,
      //   height: "100%",
      //   width: "100%",
      // }}
    >
      <CardContent className={classes.cardContent}>
        <Grid style={{ padding: "10px 10px 0px 10px" }}>
          {/* <CardContent className={classes.cardContent}> */}
          {/* <div>
            {upgrade && (
              <CustomButton
                className={classes.upgradeButton}
                onClick={() => setUpgradePlans(true)}
              >
                Upgrade Plan
              </CustomButton>
            )}
          </div> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: 18,
                display: "inline",
                margin: "0px 14px 0px 0px",
              }}
            >
              {plan}
            </Typography>
            {active && (
              <div
                style={{
                  padding: "4px 12px 4px 12px",
                  border: "1px solid #3374B9",
                  borderRadius: "8px",

                  backgroundColor: "#EBF1F8",
                }}
              >
                <Typography
                  style={{ fontSize: 14, fontWeight: 600, color: "#15314E" }}
                >
                  Current Plan
                </Typography>
              </div>
            )}
            {expireTag && (
              <div
                style={{
                  border: "1px solid yellow",
                  backgroundColor: "lightyellow",
                  display: "inline-flex",
                  borderRadius: "2px",
                }}
              >
                <ReportProblemOutlinedIcon style={{ color: "yellow" }} />
                <Typography
                  style={{
                    display: "inline-block",
                    fontWeight: 450,
                    fontSize: 12,
                    padding: "4px 4px 0px 4px",
                  }}
                >
                  {`  your plan Has expired  ${
                    !next_payment_date
                      ? ""
                      : moment(next_payment_date).format("ll")
                  }`}
                </Typography>
              </div>
            )}
            {login.isSubscriptionActive &&
              localStorage.getItem("status") === "ACTIVE" && (
                <div
                  style={{
                    border: "1px solid #95bdec",
                    backgroundColor: "#ebf1f8",
                    display: "inline-flex",
                    borderRadius: "2px",
                  }}
                >
                  <ReportProblemOutlinedIcon style={{ color: "#95bdec" }} />
                  <Typography
                    style={{
                      display: "inline-block",
                      fontWeight: 450,
                      fontSize: 12,
                      padding: "4px 4px 0px 4px",
                    }}
                  >
                    {`  your plan Will expire on  ${
                      !next_payment_date
                        ? ""
                        : moment(next_payment_date).format("ll")
                    }`}
                    {/* {`  your plan is about to expire on 
           ${moment().add(1, "W").format("ll")}`} */}
                  </Typography>
                </div>
              )}
          </div>
          {/* {console.log('amount',amount)} */}
          <Typography style={{ fontWeight: 600, fontSize: 26 }}>
            {amount !== "Free" && "₹"}
            {amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
            {amount !== "Free" && (
              <span
                style={{
                  fontWeight: 400,
                  fontSize: 13,
                  display: "inline",
                  color: "#3374b9",
                }}
              >
                /Month
              </span>
            )}
          </Typography>

          {/* {generalSettings?.razorpay_invoice &&
        generalSettings?.razorpay_invoice[0]?.paid &&
        localStorage.getItem("status") !== "TRIAL" ? (
          <Typography style={{ fontSize: 12, color: "grey" }}>
            {`  Currently you are on the Premium plan which is Active till 
         
           ${moment(
             localStorage.getItem("next_payment_date") &&
               localStorage.getItem("next_payment_date")
           )
             .add(1, "M")
             .format("ll")}`}
          </Typography>
        ) : (
          <Typography style={{ fontSize: 12, color: "grey" }}>
            Currently you are on the beta plan which is free since you've not
            opted for Premium plan
          </Typography>
        )} */}

          <Typography style={{ fontSize: 12, color: "grey" }}>
            {description}
          </Typography>

          {/* </CardContent> */}
          {false && (
            <CardContent
              style={{
                backgroundColor: "#EBF1F8",
                borderRadius: "5px",
              }}
            >
              {generalSettings?.razorpay_invoice &&
              generalSettings?.razorpay_invoice[0]?.paid ? (
                <ul className={classes.ul}>
                  <li>
                    <Typography className={classes.li}>
                      Max Upto 20 Users
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.li}>
                      Customer support
                    </Typography>
                  </li>

                  <li>
                    <Typography className={classes.li}>
                      Max Upto 10 Company Profiles
                    </Typography>
                  </li>
                </ul>
              ) : (
                <ul className={classes.ul}>
                  <li>
                    <Typography className={classes.li}>
                      Max Upto 20 Users
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.li}>
                      Customer support
                    </Typography>
                  </li>

                  <li>
                    <Typography className={classes.li}>
                      Max Upto 10 Company Profiles
                    </Typography>
                  </li>
                </ul>
              )}
            </CardContent>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Plan;
