export const REDIRECT_VALUE = "REDIRECT_VALUE";
export const SET_ASSESSMENT = "SET_ASSESSMENT";
export const SET_KEYWORD = "SET_KEYWORD";
export const SET_CHANGE_PASSWORD_DIALOG = "SET_CHANGE_PASSWORD_DIALOG";
export const SET_ACTIVITY_LOG = "SET_ACTIVITY_LOG";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_ORGANIZATION_DETAILS = "SET_ORGANIZATION_DETAILS";
export const SET_ASSESSMENT_STATUS = "SET_ASSESSMENT_STATUS";
export const SET_ASSIGNED_ASSESSMENT_STATUS = "SET_ASSIGNED_ASSESSMENT_STATUS";
export const SET_ASSIGNED_ASSESSMENTS = "SET_ASSIGNED_ASSESSMENTS";

export const SET_ASSESSMENTS_TOTAL_COUNT = "SET_ASSESSMENTS_TOTAL_COUNT";
export const SET_ASSIGNED_ASSESSMENTS_TOTAL_COUNT =
  "SET_ASSIGNED_ASSESSMENTS_TOTAL_COUNT";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_TOTAL_NOTIFICATIONS = "SET_TOTAL_NOTIFICATIONS";

export const SET_ASSESSMENTS_PAGINATION_PAGE =
  "SET_ASSESSMENTS_PAGINATION_PAGE";

export const SET_QUESTION_STATUS = "SET_QUESTION_STATUS";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_SURVEY_COMPLETED = "SET_SURVEY_COMPLETED";

const fqa = [
  {
    title: "How do I start an ESG Assessment ?",
    desc: "You can start your ESG Assessment by clicking Add New button. You will go through a 6  step process through which enable a company to Reflect & Assess their ESG Readiness, Plan & Approve their ESG Goals and finally help yougenerate your various sustainability reports. ",
  },
  {
    title: "How often do I have to do an ESG Assessment ?",
    desc: " It is recommended that any company cyclicly keep doing their ESG Assessments on yearly basis. This help company better understan how to keep improving the ESG Goals based on the changing regulations or standards. ",
  },
  // {
  //   title: 'Why should you provide materiality Assessment here ?',
  //   desc: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.'
  // },
  // {
  //   title: 'What is sustainability reports ?',
  //   desc: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.'
  // },
  // {
  //   title: 'Does ESG report require so all the infomation listed ?',
  //   desc: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.'
  // }
];

export const SET_COMPLETED_QUESTIONS = "SET_COMPLETED_QUESTIONS";

export const SET_DEFAULT_SURVEY_DATA = "SET_DEFAULT_SURVEY_DATA";

// survey type table
export const SET_TABLE_TYPE_DOCS = "SET_TABLE_TYPE_DOCS";

export default fqa;
