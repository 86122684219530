import React, { useRef } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Drawer from "@material-ui/core/Drawer";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import RadioButton from "../../UI/radioButton/radioButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import CustomChip from "../../UI/chip/chip";

const topicRelevanceData = [
  "Very Important",
  "Somewhat Important",
  "Neutral",
  "Not Very Important",
  "Not At All Important",
];

function AssetManagement() {
  const inputRef = useRef(null);

  // Drawer Toggle
  const [show, setShow] = React.useState(false);
  const [topics, setTopic] = React.useState("");
  const toggleDrawer = () => () => {
    setShow(!show);
  };
  const handleChange = (event) => {
    setTopic(event.target.value);
  };

  return (
    <Card
      variant="outlined"
      style={{
        marginBottom: "15px",
        position: "relative",
        height: "100%",
      }}
    >
      <CardContent>
        {/* Title, Chip and Icon */}
        <Grid container spacing={2} style={{ marginBottom: "10px" }}>
          <Grid item md={9} sm={8} xs={7}>
            <Typography variant="h6">
              Assest Management & Custody Activities
            </Typography>
          </Grid>
          <Grid item md={1} xs={1}>
            <Backdrop
              open={show}
              style={{ zIndex: 1199, position: "absolute" }}
            />
            <Drawer
              anchor="right"
              open={show}
              onClose={toggleDrawer()}
              PaperProps={{ style: { position: "absolute" } }}
              BackdropProps={{ style: { position: "absolute" } }}
              ModalProps={{
                container: document.getElementById("drawer-container"),
                style: { position: "absolute" },
              }}
              variant="persistent"
            >
              <div style={{ padding: "25px" }}>
                <Grid container justifyContent="flex-end">
                  <Grid item md={11} xs={10}>
                    <Typography variant="h6" style={{ marginLeft: "15px" }}>
                      Activity Log
                    </Typography>
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <IconButton onClick={toggleDrawer()}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Drawer>
          </Grid>
        </Grid>
        <div>
          {/* Metrics Assest Checkbox */}
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">Metrics</Typography>
            </Grid>
            <Grid item xs={10} style={{ padding: 10 }}>
              <Grid container>
                <Grid>
                  <Icon
                    style={{
                      fontSize: "10px",
                      marginTop: "18px",
                      marginRight: "10px",
                      color: "#3374B9",
                    }}
                  >
                    circle
                  </Icon>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body1" style={{ paddingTop: 10 }}>
                    FN-AC-270a.1: ( Number and (2) percentage of covered
                    employees with a record ofinvestment-related investigations,
                    consumer-initiated complaints, private civil litigations, or
                    →other regulatory proceedings
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid>
                  <Icon
                    style={{
                      fontSize: "10px",
                      marginTop: "18px",
                      marginRight: "10px",
                      color: "#3374B9",
                    }}
                  >
                    circle
                  </Icon>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body1" style={{ paddingTop: 10 }}>
                    FN-AC-270a.1: ( Number and (2) percentage of covered
                    employees with a record ofinvestment-related investigations,
                    consumer-initiated complaints, private civil litigations, or
                    →other regulatory proceedings
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Topic Relevance Button */}
          <Grid container style={{ marginTop: "25px" }}>
            <Grid style={{ marginTop: "5px", marginRight: "10px" }}>
              <CustomChip size="small" color="primary" label="1" />
            </Grid>
            <Grid item sm={9} xs={7}>
              <Typography variant="h6">Topic Relevance</Typography>
            </Grid>
          </Grid>
          {/* Topic Importance Checkboxs */}
          <Grid container style={{ marginTop: "25px" }}>
            <Grid item md={10} xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        height: "35px",
                      }}
                    >
                      <Typography variant="h6">
                        How Much Does This Topic Important To Company
                      </Typography>
                    </Grid>
                    {topicRelevanceData.map((topic, index) => {
                      return (
                        <>
                          <Grid container item xs={2} md={1}>
                            <RadioButton
                              name={topic}
                              value={topic}
                              onChange={handleChange}
                              checked={topics === topic}
                            />
                          </Grid>
                          <Grid item xs={10} md={11}>
                            <Typography>{topic}</Typography>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "25px" }}>
            <Grid style={{ marginTop: "5px", marginRight: "10px" }}>
              <CustomChip size="small" color="primary" label="2" />
            </Grid>
            <Grid item sm={9} xs={7}>
              <Typography variant="h6">Additional Parameters</Typography>
            </Grid>
          </Grid>

          {/* TextFields */}
          <Grid container spacing={2} style={{ marginTop: "25px" }}>
            <Grid item md={10} xs={12}>
              <TextField
                id="standard-multiline-static"
                label="Add In Risks of the topics"
                multiline
                minRows={2}
                style={{ width: "100%", backgroundColor: "#fff" }}
              />
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                id="standard-multiline-static"
                label="Add In Opportunity of the topics"
                multiline
                minRows={2}
                style={{ width: "100%", backgroundColor: "#fff" }}
              />
            </Grid>
          </Grid>

          {/* File Upload */}
          <Grid container style={{ marginTop: "25px", marginBottom: "15px" }}>
            <Grid item xs={7}>
              <Box
                width="70%"
                style={{
                  margin: "20px 0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AttachFileIcon
                  style={{ backgroundColor: "#adb5bd", padding: 4 }}
                  color="primary"
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                />
                <div
                  style={{
                    borderBottom: "2px solid #3374B9",
                    marginLeft: 20,
                    width: "100%",
                  }}
                ></div>
                <ClearIcon style={{ cursor: "pointer" }} color="primary" />
              </Box>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
}
export default React.memo(AssetManagement);
