import {
  SET_COMMENTS_TOC,
  SET_COMMENTS
} from '../../constants/comments/commentsCont';
import { set_snack_bar } from '../../actions/snackbar/snackbar_action';
import { set_loader, unset_loader } from '../../actions/loader/loader_action';
import encrypt from '../shared/sharedActions';
import UNIVERSAL from '../../config/config';

export const setCommentsToc = (payload) => {
  return {
    type: SET_COMMENTS_TOC,
    payload
  };
};

export const setComments = (payload) => {
  return {
    type: SET_COMMENTS,
    payload
  };
};

export const viewAllComments = (token, assessment_id, step_no) => {
  return (dispatch) => {
    const data = encrypt({
      'user-token': token ? token : localStorage.getItem('user_token'),
      assessment_id: assessment_id,
      step_no: step_no
    });
    return fetch(UNIVERSAL.BASEURL + '/comment/view_all_comments', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCommentsToc(responseJson.result));
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
};

export function viewComments(token, toc_id, assessment_topic_id, step_no) {
  return (dispatch) => {
    const data = encrypt({
      'user-token': token ? token : localStorage.getItem('user_token'),
      toc_id: toc_id,
      assessment_topic_id,
      step_no
    });
    return fetch(UNIVERSAL.BASEURL + '/comment/view_comments', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setComments(responseJson.results));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function addCommentToc(
  token,
  toc_id,
  comment,
  assessment_topic_id,
  step_no,name,assessment_id
) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      'user-token': token ? token : localStorage.getItem('user_token'),
      toc_id: toc_id,
      comment: comment,
      assessment_topic_id:assessment_topic_id,
      step_no,name,assessment_id
    });
    return fetch(UNIVERSAL.BASEURL + '/comment/add_comment', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json)
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewComments(token, toc_id));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
