import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import SearchTextField from "../../UI/textfield/searchTextField";

const TaskHeader = ({ setSearchKeyword }) => {
  const handleSearchClick = (value) => {
    setSearchKeyword(value);
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {/* My Task Text */}
      <Typography
        style={{
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#15314E",
        }}
      >
        My Task
      </Typography>

      {/* Search Field */}
      <Box>
        <SearchTextField searchClick={handleSearchClick} />
      </Box>
    </Box>
  );
};

export default TaskHeader;
