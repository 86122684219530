import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  TableRow,
  TableCell,
  makeStyles,
  Typography,
  Avatar,
} from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";

import moment from "moment";

import DynamicTable, {
  statusStyle,
  textStyle,
} from "../../../../UI/dynamicTable/DynamicTable";
import {
  assignedHeadings,
  assignedData,
} from "../../../../screen/vendorAdminScreen/tempData";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAssignSurvey } from "../../../../../actions/supplierAdmin/supplierAdminActions";
import NoData from "../../../NoData/NoData";
import React, { useEffect } from "react";
import {
  setActiveStep,
  setSurveyCompleted,
} from "actions/surveyListing/surveyListingAction";

// -------- stylings -----------

// Hover styles for TableRow
const useStyles = makeStyles({
  tableRowHover: {
    // border: "1px solid #E9E9E9",
    borderRadius: "8px",
    margin: 10,
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#EBF1F8",
      cursor: "pointer",
    },
  },
});

// ------------------------------

// -------- main render ---------
const AssignedTab = () => {
  const { assignSurveyList } = useSelector((state) => state?.supplierAdmin);
  const dispatch = useDispatch();
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const handleRowSelect = (row) => {
    dispatch(setSelectedAssignSurvey(row));
    history.push(`${location.pathname}/${row.survey_name.replace(/ /g, "_")}`);
  };

  useEffect(() => {
    dispatch(setSurveyCompleted(false));
    dispatch(setActiveStep(0));
  }, []);

  return (
    <Box p={2}>
      {/* Table */}
      {assignSurveyList.length > 0 && (
        <DynamicTable headings={assignedHeadings}>
          {assignSurveyList.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <TableRow>
                <TableCell style={{ border: "none" }} colSpan={6}>
                  {row?.date && (
                    <Typography
                      style={{
                        color: "#15314E",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {row?.date}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
              {row.surveys.map((rowData, idx) => {
                return (
                  <TableRow
                    key={idx}
                    className={classes.tableRowHover}
                    onClick={() => handleRowSelect(rowData)}
                  >
                    <TableCell style={{ ...textStyle("main") }}>
                      {rowData.survey_name}
                    </TableCell>
                    <TableCell style={{ ...textStyle() }}>
                      <Box>
                        <DotIcon
                          style={{
                            widht: "10px",
                            height: "10px",
                            color: "#FFCC66",
                          }}
                        />
                        {rowData.survey_freequency}
                      </Box>
                    </TableCell>
                    {/* <TableCell style={{ ...textStyle() }}>
                      {rowData.action_required}
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{ ...statusStyle(rowData.status.toLowerCase()) }}
                      >
                        <DotIcon style={{ widht: "10px", height: "10px" }} />
                        {rowData.status}
                      </Box>
                    </TableCell> */}
                    <TableCell style={{ ...textStyle() }}>
                      {moment(rowData.send_on).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell style={textStyle()}>
                      <Box
                        display={"flex"}
                        justifyContent={"flex-start"}
                        sx={{ gap: 10 }}
                      >
                        {/* <Avatar
                    src={rowData.assigned_by.avatar || rowData.assigned_by.name}
                    alt={rowData.assigned_by.name}
                  /> */}
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography style={textStyle("main")}>
                            {rowData.requested_by}
                          </Typography>
                          <Typography
                            style={{
                              color: "#AFAFAF",
                              fontWeight: 500,
                              fontSize: "11px",
                              lineHeight: "16px",
                            }}
                          >
                            {/* {rowData.assigned_by.designation} */}
                            role
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </React.Fragment>
          ))}
        </DynamicTable>
      )}
      {assignSurveyList.length === 0 && <NoData />}
    </Box>
  );
};
export default AssignedTab;
