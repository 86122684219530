import { useState } from "react";
import {
  MainHeader,
  MainTable,
} from "../../components/screen/vendorAdminScreen";

import SearchTextField from "../../components/UI/textfield/searchTextField";

const VendorAdmin = () => {
  const [searchKeyword, setSearchKeyword] = useState();

  const handleSearchClick = (value) => {
    setSearchKeyword(value);
  };

  return (
    <div>
      {/* main header from screen */}
      <MainHeader>
        <SearchTextField searchClick={handleSearchClick} />
      </MainHeader>

      {/* table from screen */}
      <MainTable searchKeyword={searchKeyword} />
    </div>
  );
};
export default VendorAdmin;
