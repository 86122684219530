import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { sectionWiseData as defaultData } from "../components/widgets/surveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
// import { sectionWiseData as defaultData } from "../components/widgets/riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
import { getQuestion, setQuestions } from "../actions/supplier/supplierAction";

const useInfiniteScroll = (scrollDivRef) => {
  const dispatch = useDispatch();
  const {
    sectionWiseData,
    selectedSection,
    sectionWiseQuestion,
    hasMoreData,
    offset,
    selectedSurveyData,
  } = useSelector((state) => state.supplier);
  const login = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  // const [offset, setOffset] = useState(0);
  useEffect(() => {
    const fetchData = () => {
      selectedSection &&
        dispatch(
          getQuestion(
            login?.token,
            sectionWiseData.find((s) => s.section_name === selectedSection)?._id,
            10,
            0,
            selectedSurveyData?.latest_version,
            sectionWiseQuestion
          )
        );
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection, sectionWiseData?.length]);

  const resetHookState = () => {
    dispatch({ type: "SET_OFFSET", payload: 0 });
    dispatch(setQuestions([]));
    dispatch({ type: "HAS_MORE_DATA", payload: true });
  };

  return { loading, resetHookState };
};

export default useInfiniteScroll;
