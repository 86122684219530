import { makeStyles } from "@material-ui/core";

const tncUseStyles = makeStyles((theme) => ({
    mainGrid: {
      marginTop: "260px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "260px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "230px",
      },
    },
    paddings: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    leftGrid: {
      marginLeft: "150px",
      paddingLeft: "0.5%",
      paddingTop: "80px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
      },
    },
    texts: {
      marginTop: "10px",
      fontFamily: "Poppins",
      fontWeight: "800",
      color: "rgba(51, 116, 185, 0.8)",
      fontStyle: "normal",
    },
    name: {
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingRight: "23px",
      marginLeft: "-19px",
      paddingLeft: "22px",
      borderRadius: "30px",
      cursor: "pointer",
    },
  }));
   export default tncUseStyles;