export const addColumnRowsData = (row, index1, Index2, loop, method, type) => {
	var total = 0;

	if (method === "add" && type === "total") {
		if (index1 === 0) {
			for (let i = index1; i < parseFloat(Index2); i++) {
				let data = isNaN(parseFloat(loop[row].columns[i].data))
					? 0
					: parseFloat(loop[row].columns[i].data);
				total = parseFloat(parseFloat(total) + parseFloat(data)).toFixed(2);

				total = parseFloat(total);
			}
		} else {
			for (let i = parseFloat(index1) + 1; i < parseFloat(Index2); i++) {
				let data = isNaN(parseFloat(loop[row].columns[i].data))
					? 0
					: parseFloat(loop[row].columns[i].data);

				total = parseFloat(parseFloat(total) + parseFloat(data)).toFixed(2);

				total = parseFloat(total);
			}
		}
	}

	if (method === "percent" && type === "male&female") {
		let data = isNaN(parseFloat(loop[row].columns[Index2 - 1].data))
			? 0
			: parseFloat(loop[row].columns[Index2 - 1].data);
		total = (100 * data) / parseFloat(loop[row].columns[index1].data);
		total = parseFloat(total).toFixed(2);

		total = parseFloat(total);
	}

	if (method === "total" && type === "male&female") {
		let data1 = isNaN(parseFloat(loop[row].columns[1].data))
			? 0
			: parseFloat(loop[row].columns[1].data);

		let data2 = isNaN(parseFloat(loop[row].columns[3].data))
			? 0
			: parseFloat(loop[row].columns[3].data);

		total = parseFloat(parseFloat(data1 + data2)).toFixed(2);

		total = parseFloat(total);

		return total;
	}

	if (method === "percent" && type === "Employees") {
		let data = isNaN(parseFloat(loop[row].columns[Index2 - 1].data))
			? 0
			: parseFloat(loop[row].columns[Index2 - 1].data);
		total = (100 * data) / parseFloat(loop[row].columns[Index2 - 2].data);

		total = parseFloat(total).toFixed(2);

		total = parseFloat(total);
	}
	return total;
};

export const addRowsColumnData = (row1, row2, col, loop, method, type) => {
	var total = 0;

	if (method === "add" && type === "total") {
		if (row1 === 0) {
			for (let i = parseFloat(row1); i < parseFloat(row2); i++) {
				let data = isNaN(parseFloat(loop[i].columns[col].data))
					? 0
					: parseFloat(loop[i].columns[col].data);
				total = parseFloat(parseFloat(total) + parseFloat(data)).toFixed(2);

				total = parseFloat(total);
			}
		} else {
			for (let i = parseFloat(row1) + 1; i < parseFloat(row2); i++) {
				let data = isNaN(parseFloat(loop[i].columns[col].data))
					? 0
					: parseFloat(loop[i].columns[col].data);
				total = parseFloat(parseFloat(total) + parseFloat(data)).toFixed(2);

				total = parseFloat(total);
			}
		}
	}

	if (method === "add" && type === "total&male-female-top") {
		let data1 = isNaN(parseFloat(loop[row1].columns[col].data))
			? 0
			: parseFloat(loop[row1].columns[col].data);
		let data2 = isNaN(parseFloat(loop[row2].columns[col].data))
			? 0
			: parseFloat(loop[row2].columns[col].data);
		total = parseFloat(parseFloat(data1) + parseFloat(data2)).toFixed(2);

		total = parseFloat(total);
	}

	return total;
};
