import { makeStyles } from "@material-ui/core";

const SustainabilityReportTopStyles = makeStyles(() => ({
	subGrid: {
		padding: "10px",
		borderRadius: 5,
		margin: "20px 10px 0px 10px",
		display: "flex",
		width: "auto",
		backgroundColor: "#3374B933",
	},
	listDiv: {
		backgroundColor: "#ffffff",
		borderRadius: "50%",
		marginRight: 15,
		width: 35,
		height: 35,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	boxWidth: { width: "100%" },
}));

export default SustainabilityReportTopStyles;
