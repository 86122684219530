import {
    SET_FRAMEWORK_NAME,
  RESET_FRAMEWORK
} from '../../constants/frameworks/frameworkConstants'

const initial_state = {
    frameworkName:''
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_FRAMEWORK_NAME:
      return (state = { ...state, name: action.payload })
    case RESET_FRAMEWORK:
      return (state = { ...state, frameworkName: '' })
    default:
      return state
  }
}