import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";
import {
  hasPublishError,
  publishDataStream,
  deletePublishedDatastream,
} from "../../../actions/datahubActions/datahubActions";

export default function PublishErrorDialog({
  hasPublisError,
  overlappingFiles,
  token,
  isDeleteDialog,
  handleDeleteDialog,
  fileName,
  handleDelete,
  currentOrganisation,
  isPublished,
}) {
  const dispatch = useDispatch();

  const handleClose = () => {
    if (isDeleteDialog) {
      handleDeleteDialog();
    } else {
      dispatch(hasPublishError(false));
    }
  };

  const handleProceed = () => {
    if (isDeleteDialog) {
      handleDelete();
      handleDeleteDialog();
    } else {
      dispatch(
        publishDataStream(
          token,
          overlappingFiles?.current_file?._id,
          overlappingFiles?.exact_dublicate_files[0]?._id,
          currentOrganisation
        )
      );
      handleClose();
    }
  };

  const TITLE = isDeleteDialog
    ? isPublished
      ? "Unpublish File"
      : "Delete File"
    : "Publish Error";

  const BUTTON = isDeleteDialog
    ? isPublished
      ? "Unpublish"
      : "Delete"
    : overlappingFiles?.exact_dublicate_files?.length > 0
    ? "Proceed"
    : "";

  const BUTTON_STYLE = isDeleteDialog
    ? { backgroundColor: "#C45446", color: "#F9EEED" }
    : overlappingFiles?.exact_dublicate_files?.length > 0 ?  { backgroundColor: "#3374B9", color: "#EBF1F8" } : {display:"none", visibilty:"hidden" } ;
  const overLappingFileNames =
    overlappingFiles?.exact_dublicate_files?.length > 0
      ? overlappingFiles?.exact_dublicate_files?.map((f) => f.file_name)
      : overlappingFiles?.existing_dublicate_file?.map((f) => f.file_name);

  const DIALOG_CONTENT = isDeleteDialog
    ? isPublished
      ? `Are you sure you want to unpublish the file name ${fileName}.`
      : `Are you sure you want to delete the file name ${fileName}. Once deleted all the data used with the file on the data hub will be updated.`
    : overlappingFiles?.exact_dublicate_files?.length > 0
    ? `The file you are trying to publish, ${
        overlappingFiles?.current_file?.file_name
      }. contains data that
    already exists in the system under the file name ${overLappingFileNames.join()}. Please review your file and make necessary modifications or
    click 'Proceed' to overwrite the existing data.`
    : `The file you are trying to publish, ${
        overlappingFiles?.current_file?.file_name
      }. contains data that
    already exists in the system under the file names ${overLappingFileNames.join()}. Please unpublish your files to publish this file.`;

  return (
    <div>
      <Dialog
        open={hasPublisError}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            style={{
              paddingBottom: 5,
              borderBottom: "3px solid green",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              width: "30%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {TITLE}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {DIALOG_CONTENT}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>

          <Button
            onClick={handleProceed}
            variant="contained"
            // color="primary"
            autoFocus
            style={BUTTON_STYLE}
          >
            {BUTTON}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
