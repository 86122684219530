import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import CustomChip from "../UI/chip/chip";
import moment from "moment";
import DefaultUser from "../../../src/images/Default_user.svg";
import { TableRow } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  starText: {
    color: "#000000",
  },
  photos: {
    height: "25px",
    width: "25px",
    marginRight: "10px",
  },
  starIcon: {
    transition: "transform .8s ease-in-out",
  },
});

function EsgReport(props) {
  const {
    reportlisting,
    getAssignedTopics,
    login,
    location,
    getAssignedMaterialityTopics,
  } = props;
  // console.log("reportlisting", reportlisting, location);
  useEffect(() => {
    getAssignedMaterialityTopics(login.token, location.state.assessment_id);
    getAssignedTopics(login.token, location.state.assessment_id);
  }, [login.token, location.state.assessment_id]);

  const assessment_name = props.location.state.assessment_name;
  const framework_id = props.location.state.framework_id;

  const [redirect, setRedirect] = React.useState(false);
  const [step_no, setStepNo] = React.useState(0);
  const [assignment_id, setAssignmentID] = React.useState(null);
  const [topicsData, setTtopicsData] = React.useState(null);
  const [metricsData, setMetricsData] = React.useState(null);
  const [reportData, setReportData] = React.useState(null);
  const [assigned_to, setAssignedId] = React.useState(null);
  const [assigned_by, setAssignedBy] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [memberId, setMemberId] = React.useState(null);
  const [title, setTitle] = React.useState("");

  const reportSections = [
    {
      name: "Maturity Matrix",
      step_no: 1,
    },
    {
      name: "Materiality assessment",
      step_no: 2,
    },
    {
      name: "ESG Strategy",
      step_no: 3,
    },
    {
      name: "Assessment Approval",
      step_no: 4,
    },
    {
      name: "Field Data",
      step_no: 5,
    },
    {
      name: "Field Data",
      step_no: 5,
    },
    {
      name: "Sustainability Report",
      step_no: 6,
    },
  ];

  const handleRedirect = (
    showSection,
    assessment_id,
    topicsData,
    // metricsData,
    reportData,
    assigned_to,
    assigned_by,
    role,
    assign_member_id,
    title
  ) => {
    setStepNo(showSection);
    setAssignmentID(assessment_id);
    setTtopicsData(topicsData);
    // setMetricsData(metricsData);
    setReportData(reportData);
    setAssignedId(assigned_to);
    setAssignedBy(assigned_by);
    setRole(role);
    setMemberId(assign_member_id);
    setRedirect(!redirect);
    setTitle(title);
  };

  const classes = useStyles();

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/report_listing/esg_report/single_report",
          state: {
            step_no: step_no,
            assignment_id: assignment_id,
            topicsData: topicsData,
            // metricsData: metricsData,
            framework_id: framework_id,
            reportData: reportData,
            assigned_to: assigned_to,
            assigned_by: assigned_by,
            role: role,
            memberId: memberId,
            assessment_name: assessment_name,
            title,
          },
        }}
      />
    );
  }
  // console.log("reportSections", reportSections);
  return (
    <>
      {reportSections.length > 0 &&
        reportSections !== undefined &&
        reportSections !== null &&
        reportSections !== "" &&
        reportSections?.map((s, index) => {
          var showSection =
            reportlisting.assessments.length === 0
              ? false
              : reportlisting.assessments.some((assign) => {
                  if (assign !== null) {
                    return assign.step_no === s.step_no;
                  }
                  return true;
                });
          if (showSection) {
            return (
              reportlisting.assessments[index] &&
              reportlisting.assessments[index].role?.length > 0 && (
                <Card
                  key={index}
                  style={{ margin: "1% 7%", cursor: "pointer" }}
                  onClick={() => {
                    handleRedirect(
                      s.step_no,
                      reportlisting.assessments[index].assessment_id,
                      reportlisting.assessments[index].topic_data,
                      // reportlisting.assessments[index].metric_data,
                      reportlisting.assessments[index].report_data,
                      reportlisting.assessments[index].assigned_to,
                      reportlisting.assessments[index].assigned_by,
                      reportlisting.assessments[index].role,
                      reportlisting.assessments[index]._id,
                      s.name
                    );
                  }}
                >
                  <Table size="small" className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell width="20%">
                          <Typography variant="h6">{s.name}</Typography>
                        </TableCell>
                        <TableCell width="26%">
                          <List>
                            <ListItem>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Last activity
                              </Typography>
                            </ListItem>
                            <ListItem className={classes.star}>
                              <Typography
                                variant="caption"
                                color="primary"
                                className={classes.starText}
                              >
                                {reportlisting.assessments[index]
                                  .last_activity &&
                                  reportlisting.assessments[
                                    index
                                  ].last_activity.substring(0, 100) + "..."}
                              </Typography>
                            </ListItem>
                          </List>
                        </TableCell>
                        <TableCell width="17%">
                          <List>
                            <ListItem>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Action Required
                              </Typography>
                            </ListItem>
                            {reportlisting.assessments[index].role?.map(
                              (r, i) => (
                                <ListItem key={i}>
                                  <CustomChip
                                    variant="outlined"
                                    color={
                                      s.step_no === 4 || s.step_no === 6
                                        ? r === 1
                                          ? "primary"
                                          : r === 2
                                          ? "secondary"
                                          : ""
                                        : r === 0
                                        ? "secondary"
                                        : "primary"
                                    }
                                    label={
                                      s.step_no === 4 || s.step_no === 6
                                        ? r === 1
                                          ? "Approver"
                                          : r === 2
                                          ? "Reviewer"
                                          : ""
                                        : r === 0
                                        ? "Contribution"
                                        : "Approval"
                                    }
                                  />
                                </ListItem>
                              )
                            )}
                          </List>
                        </TableCell>
                        <TableCell width="17%">
                          <List>
                            <ListItem>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Sent on
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography variant="subtitle2">
                                {reportlisting.assessments[index].assigned_on &&
                                  moment(
                                    reportlisting.assessments[index].assigned_on
                                  ).format("MM/DD/YYYY")}
                              </Typography>
                            </ListItem>
                          </List>
                        </TableCell>
                        <TableCell width="18%">
                          <List>
                            <ListItem>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Contributed by
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Avatar
                                src={
                                  reportlisting?.assessments[index]?.user_data
                                    ?.profile_img?.url || DefaultUser
                                }
                                variant="circular"
                                className={classes.photos}
                              />
                              <Typography variant="subtitle2">
                                {
                                  reportlisting?.assessments[index]?.user_data
                                    ?.name
                                }
                              </Typography>
                            </ListItem>
                          </List>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              )
            );
          }
          return true;
        })}
    </>
  );
}
export default React.memo(EsgReport);

/* 
<Table size="small" className={classes.table}>
                    <TableBody>
                      <TableCell component={"tr"} width="20%">
                        <Typography component={"td"} variant="h6" >
                          {s.name}
                        </Typography>
                      </TableCell>
                      <TableCell component={"tr"} width="26%">
                        <List component={"td"}>
                          <ListItem>
                            <Typography variant="caption" color="textSecondary">
                              Last activity
                            </Typography>
                          </ListItem>
                          <ListItem className={classes.star}>
                            <Typography
                              variant="caption"
                              color="primary"
                              className={classes.starText}
                            >
                              {reportlisting.assessments[index].last_activity &&
                                reportlisting.assessments[
                                  index
                                ].last_activity.substring(0, 100) + "..."}
                            </Typography>
                          </ListItem>
                        </List>
                      </TableCell>
                      <TableCell component={"tr"} width="17%">
                        <List component={"td"}>
                          <ListItem>
                            <Typography variant="caption" color="textSecondary">
                              Action Required
                            </Typography>
                          </ListItem>
                          {reportlisting.assessments[index].role?.map(
                            (r, i) => {
                              // console.log('d',s.step_no,r,s.step_no === 4 || s.step_no === 6 ? (r === 1 ? 'Approver': r === 2 ? 'Reviewer':''): r === 0 ? "Contribution" : "Approval" )
                              return (
                                <ListItem key={i}>
                                  <CustomChip
                                    variant="outlined"
                                    color={
                                      s.step_no === 4 || s.step_no === 6
                                        ? r === 1
                                          ? "primary"
                                          : r === 2
                                          ? "secondary"
                                          : ""
                                        : r === 0
                                        ? "secondary"
                                        : "primary"
                                    }
                                    // color={r === 0 ? "secondary" : "primary"}
                                    label={
                                      s.step_no === 4 || s.step_no === 6
                                        ? r === 1
                                          ? "Approver"
                                          : r === 2
                                          ? "Reviewer"
                                          : ""
                                        : r === 0
                                        ? "Contribution"
                                        : "Approval"
                                    }
                                  ></CustomChip>
                                </ListItem>
                              );
                            }
                          )}
                        </List>
                      </TableCell>
                      <TableCell component={"tr"} width="17%">
                        <List component={"td"}>
                          <ListItem>
                            <Typography variant="caption" color="textSecondary">
                              Sent on
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="subtitle2">
                              {reportlisting.assessments[index].assigned_on &&
                                moment(
                                  reportlisting.assessments[index].assigned_on
                                ).format("MM/DD/YYYY")}
                            </Typography>
                          </ListItem>
                        </List>
                      </TableCell>
                      <TableCell component={"tr"} width="18%">
                        <List component={"td"}>
                          <ListItem>
                            <Typography variant="caption" color="textSecondary">
                              Contributed by
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Avatar
                              src={
                                reportlisting?.assessments[index]?.user_data
                                  ?.profile_img?.url || DefaultUser
                              }
                              variant="circular"
                              className={classes.photos}
                            />
                            <Typography variant="subtitle2">
                              {
                                reportlisting?.assessments[index]?.user_data
                                  ?.name
                              }
                            </Typography>
                          </ListItem>
                        </List>
                      </TableCell>
                    </TableBody>
                  </Table>
*/
