import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import TextField from "../../UI/textfield/textfield";

import { AssessmentTopUseStyles } from "./assessmentTopStyle";

function AssessmentTop(props) {


  const classes = AssessmentTopUseStyles();
  const ref = useRef();

  const [access, setAccess] = useState(false);
  const [assessment_name, setAssessment] = React.useState(
    props.assesmentApproval.reportName
  );
  // console.log('name', props.assesmentApproval.reportName)
  useEffect(() => {
    if (props.assesmentApproval.reportName !== "") {
      setAssessment(props.assesmentApproval.reportName);
    } else {
      setAssessment("Untitled");
    }
  }, [props.assesmentApproval.reportName]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (access && ref.current && !ref.current.contains(e.target)) {
        setAccess(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [access]);

  const handelUpdate = () => {
    props.updateReportName(
      props.login.token,
      props.assessment_id,
      4,
      assessment_name,
      props.assesmentApproval.jwt_hash
    );
    setAccess(false);
  };

  return (
    <Grid container>
      <Grid item md={7} className={classes.assessmentGridContainer}>
        <Box className={classes.assessmentGridItem}>
          <div className={classes.assesmentDive}>
            <Icon>list_alt</Icon>
          </div>
          {access === true ? (
            <>
              {assessment_name === props.assesmentApproval.reportName ? (
                <Box
                  display="flex"
                  alignItems="center"
                  ref={ref}
                  className={classes.assesmentBox}
                >
                  <TextField
                    placeholder="Assessment"
                    value={assessment_name}
                    size="small"
                    variant="outlined"
                    onChange={(event) => setAssessment(event.target.value)}
                    fullwidth
                    className={classes.assesmentBox}
                  />
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  onBlur={() => handelUpdate()}
                  className={classes.assesmentBox}
                >
                  <TextField
                    name="pdfName"
                    placeholder="Assessment"
                    value={assessment_name}
                    size="small"
                    variant="outlined"
                    onChange={(event) => setAssessment(event.target.value)}
                    fullwidth
                    className={classes.assesmentBox}
                  />
                </Box>
              )}
            </>
          ) : (
            <Typography variant="h6" onClick={() => setAccess(true)}>
              {assessment_name}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default AssessmentTop;
