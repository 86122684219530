import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  ListItem,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { brmRightStyles } from "../riskAssessment/brmDataRight.js/brmRightStyle";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
// import GoogleMaps from "./googleMaps";

import { FiList, FiSidebar, FiUser } from "react-icons/fi";

import { useDispatch, useSelector } from "react-redux";

import {
  BlueBackgroundCard,
  ResponseSectionListLeft,
} from "../surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionList";
import {
  Question,
  Wrapper,
} from "../surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionList";
// import {
//   getQuestion,
//   getSurveySectionQuestion,
// } from "../../../redux/actions/riskdashboard/riskDashboardActions";
import { initialData2 } from "../../../utils/riskUtils/riskUtils";

import { sectionWiseData as defaultData } from "../surveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";

import clipboard from "../../../images/clipboard.png";
import Close from "@material-ui/icons/Close";
import RadioButton from "../../UI/radioButton/radioButton";
import {
  getPublishedSurveys,
  getQuestion,
  getSections,
  setQuestions,
  setSection,
  setSelectedSection,
  setSelectedSurvey,
} from "../../../actions/supplier/supplierAction";
import NoData from "../NoData/NoData";
import { SET_PUBLISHED_SURVEY_LIST } from "constants/supplier/supplierConstant";

export const ViewSurveySelect = ({
  viewListing,
  setViewListing,
  list,
  handleListSelect,
  surveySelect,
}) => {
  const classes = brmRightStyles();

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      onClose={() => setViewListing(false)}
      // variant="persistent"
      width="lg"
      open={viewListing}
      style={{ position: "relative" }}
    >
      <div
        style={{ width: "500px", position: "relative", overflowX: "hidden" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
            boxShadow: "0px 1px 3px 1px #3374B926",
            marginBottom: 10,
          }}
        >
          <Typography
            style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
          >
            Survey Select
          </Typography>
          <Typography
            onClick={() => setViewListing(!viewListing)}
            style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
          >
            <Close style={{ cursor: "pointer", color: "#3374b9" }} />
          </Typography>
        </div>

        {/* <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
            padding: 5,
          }}
        >
          Pick the type of survey that has to be sent to the vendor.
        </Typography> */}

        {list.map((v, i) => {
          return (
            <ListItem
              key={i + v._id}
              className={classes.onListHover}
              onClick={() => {
                handleListSelect(v);
              }}
              style={{
                width: "100%",
                padding: 10,
                margin: 5,
                gap: 5,
                backgroundColor: v._id !== surveySelect?._id ? "" : "#EBF1F8",
              }}
            >
              <Box width={"100%"}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    selected: true,
                  }}
                >
                  <RadioButton checked={v._id === surveySelect?._id} />
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"90%"}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#15314E",
                        padding: 5,
                      }}
                    >
                      {v.survey_name}
                    </Typography>

                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      style={{ gap: 10 }}
                    >
                      <FiUser size={18} color={"#505050"} />
                      <Typography
                        style={{
                          color: "#505050",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                      >
                        {v?.user_name}
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  style={{ gap: 20, padding: "5px 10px" }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    style={{ gap: 10 }}
                  >
                    <FiSidebar size={18} color={"#505050"} />
                    <Typography
                      style={{
                        color: "#505050",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {v?.no_of_sections} Sections
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    style={{ gap: 10 }}
                  >
                    <FiList size={18} color={"#505050"} />
                    <Typography
                      style={{
                        color: "#505050",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {v?.no_of_questions} Questions
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </ListItem>
          );
        })}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 5,
          right: 5,
        }}
      >
        <Button
          style={{
            textTransform: "none",
            color: "#3374B9",
            padding: "3px 10px",
          }}
          onClick={() => setViewListing(!viewListing)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{
            textTransform: "none",
            backgroundColor: "#3374B9",
            color: "white",
            padding: "3px 10px",
          }}
          onClick={() => setViewListing(!viewListing)}
        >
          Save & Continue
        </Button>
      </div>
    </Drawer>
  );
};

function SurveyQuestions(props) {
  const {
    login,
    brmReducer,
    setShareDialogOpen,
    selectedSurveyId,
    // corporateSurveyQuestions,
  } = props;
  const classes = brmRightStyles();
  const dispatch = useDispatch();
  const { publishedSurveyList, sectionWiseData, sectionWiseQuestion } =
    useSelector((state) => state.supplier);

  const { companyDocuments, supplierOrganizationDetails } = useSelector(
    (state) => state.brmReducer
  );

  // const { sectionWiseData, selectedSection, sectionWiseQuestion } = useSelector(
  //   (state) => state.riskDashboard
  // );
  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);
  // const [questions, setQuestions] = useState([]);
  // const [controversiesQuestions, setControversiesQuestions] = useState(
  //   corporateSurveyQuestions || []
  // );
  const [viewListing, setViewListing] = useState(false);
  const [selected, setSelected] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");
  const [data, setData] = useState({
    type: "",
    question: "",
    options: [["", 0]],
  });
  const [value, setValue] = React.useState({});
  const [selectSurvey, setSurveySelect] = useState({});

  console.log(selectSurvey?._id, selectedSurveyId);

  // const listSurvey = ['survey1', 'survey2']

  // const location = useGeoLocation();
  const scroll = useRef();

  // console.log("location", location);

  useEffect(() => {
    dispatch(
      getPublishedSurveys(
        login?.token,
        login?.current_organisation,
        supplierOrganizationDetails?._id
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   sectionWiseData.length > 0 &&
  //     dispatch(
  //       getQuestion(
  //         login?.token,
  //         sectionWiseData.find((s) => s.title === selectedSection)?._id
  //       )
  //     );

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedSection, sectionWiseData.length]);

  // useEffect(() => {
  //   // getSurveyeQuestions(
  //   //   login.token,
  //   //   brmReducer?.organizationDetails[0]?._id ||
  //   //     localStorage.getItem("organization_id")
  //   // );
  //   setControversiesQuestions(corporateSurveyQuestions);
  // }, []);

  useEffect(() => {
    scroll?.current?.scrollTo(0, 0);
  }, [selected]);

  // const handleChange = (event, id) => {
  //   setValue((preValue) => ({ ...preValue, [id]: event.target.value }));
  // };

  // const handleAddquestion = () => {
  //   let question = {
  //     order: brmReducer?.surveyQuestions.length + 1,
  //     category: "custom question",
  //     type: data.type,
  //     question: data.question,
  //     answers: data.options,
  //   };
  //   // const copy = [...brmReducer?.surveyQuestions];
  //   // copy.push({
  //   //   order: brmReducer?.surveyQuestions.length + 1,
  //   //   category: "",
  //   //   type: data.type,
  //   //   question: data.question,
  //   //   answers: data.options,
  //   // });
  //   // addSurveyeQuestions(
  //   //   login.token,
  //   //   brmReducer?.organizationDetails[0]?._id ||
  //   //   localStorage.getItem("organization_id"),
  //   //   question
  //   // );
  //   // setQuestions(copy);
  //   setIsAddQuestionOpen(false);
  //   setData({
  //     type: "",
  //     question: "",
  //     options: [["", 0]],
  //   });
  // };

  // const addQuestionDialog = () => {
  //   return (
  //     <Dialog fullWidth open={isAddQuestionOpen} minWidth="md" maxWidth="md">
  //       <DialogTitle>
  //         <Grid
  //           style={{ display: "flex", justifyContent: "space-between" }}
  //           container
  //           justifyContent="center"
  //           className={classes.mainGrid}
  //         >
  //           {/* <Grid item xs={11}> */}
  //           <Typography style={{ fontWeight: 500 }}>Add Question</Typography>
  //           {/* </Grid> */}
  //           {/* <Grid  item xs={1}> */}
  //           <CloseOutlinedIcon onClick={() => setIsAddQuestionOpen(false)} />
  //           {/* </Grid> */}
  //         </Grid>
  //       </DialogTitle>
  //       <DialogContent>
  //         <Grid
  //           container
  //           direction="row"
  //           justifyContent="flex-end"
  //           alignItems="center"
  //           spacing={1}
  //           className={classes.grid2}
  //         >
  //           <Grid item md={12} className={classes.grid3}></Grid>
  //         </Grid>

  //         <Grid container spacing={2} className={classes.grid2}>
  //           <Grid item xs={12} md={12} className={classes.grid5}>
  //             <Typography
  //               style={{
  //                 fontSize: "14px",
  //                 marginBottom: "5px",
  //                 color: "#9A9A9A",
  //               }}
  //             >
  //               Type of Question
  //             </Typography>

  //             <CustomTextfield
  //               name="userCategory"
  //               label="Category"
  //               variant="outlined"
  //               size="small"
  //               fullWidth
  //               select
  //               value={data.type}
  //               onChange={(e) => {
  //                 setData((prev) => {
  //                   return { ...prev, type: e.target.value };
  //                 });
  //               }}
  //             >
  //               {[
  //                 "radio",
  //                 "textField",
  //                 "multi select",
  //                 "file",
  //                 // "location",
  //                 "date",
  //               ].map((cat) => (
  //                 <MenuItem key={cat} value={cat}>
  //                   {cat}
  //                 </MenuItem>
  //               ))}
  //             </CustomTextfield>
  //           </Grid>
  //           <Grid item xs={12} md={12} className={classes.grid4}>
  //             <Typography
  //               style={{
  //                 fontSize: "14px",
  //                 marginBottom: "5px",
  //                 color: "#9A9A9A",
  //               }}
  //             >
  //               Enter Question
  //             </Typography>
  //             <CustomTextfield
  //               name="userName"
  //               label="Question"
  //               variant="outlined"
  //               size="small"
  //               fullWidth
  //               value={data.question}
  //               onChange={(e) => {
  //                 setData((prev) => {
  //                   return { ...prev, question: e.target.value };
  //                 });
  //               }}
  //             />
  //           </Grid>
  //           {/* {data.type !== "textField" && (
  //             <Grid item xs={12} md={12} className={classes.grid5}>
  //               <Typography>option 1</Typography>
  //               <CustomTextfield
  //                 name="userRole"
  //                 label="option"
  //                 variant="outlined"
  //                 size="small"
  //                 fullWidth
  //                 value={data.options}
  //                 onChange={(e) => {
  //                   setData((prev, j) => {
  //                     const opt =[...prev.options]
  //                     return {
  //                       ...prev,
  //                       options: opt.push([e.target.value, j + 1]),
  //                     };
  //                   });
  //                 }}
  //               />
  //             </Grid>

  //           )} */}
  //           {(!data.type ||
  //             data.type === "radio" ||
  //             data.type === "multi select") &&
  //             data.options.map((option, index) => (
  //               <Grid item xs={12} md={12} className={classes.grid5}>
  //                 <Typography
  //                   style={{
  //                     fontSize: "14px",
  //                     marginBottom: "5px",
  //                     color: "#9A9A9A",
  //                   }}
  //                 >
  //                   option {`${index + 1}`}
  //                 </Typography>
  //                 <div style={{ display: "flex", alignItems: "center" }}>
  //                   <CustomTextfield
  //                     name="userRole"
  //                     label="option"
  //                     variant="outlined"
  //                     size="small"
  //                     fullWidth
  //                     value={option[0]}
  //                     onChange={(e) => {
  //                       const copy = { ...data };
  //                       const copyOptions = [...copy.options];
  //                       copyOptions[index][0] = e.target.value;
  //                       setData(copy);
  //                     }}
  //                   />
  //                   <RemoveCircleOutlineOutlinedIcon
  //                     style={{
  //                       color: "#3374b9",
  //                       display: "inline",
  //                       marginLeft: "5px",
  //                       cursor: "pointer",
  //                     }}
  //                     onClick={() => {
  //                       const copy = { ...data };
  //                       copy?.options.splice(index, 1);
  //                       setData(copy);
  //                     }}
  //                   />
  //                 </div>
  //               </Grid>
  //             ))}

  //           {(!data.type ||
  //             data.type === "radio" ||
  //             data.type === "multi select") && (
  //             <Button
  //               onClick={() => {
  //                 const copy = { ...data };
  //                 copy?.options.push(["", copy.options.length]);
  //                 setData(copy);
  //               }}
  //             >
  //               add option
  //             </Button>
  //           )}

  //           {data.type === "file" && (
  //             <Grid item xs={12} md={12} className={classes.grid4}>
  //               <Typography
  //                 style={{
  //                   fontSize: "14px",
  //                   marginBottom: "5px",
  //                   color: "#9A9A9A",
  //                 }}
  //               >
  //                 Select File
  //               </Typography>
  //               <CustomTextfield
  //                 name="userName"
  //                 variant="outlined"
  //                 type="file"
  //                 size="small"
  //                 fullWidth
  //                 value={""}
  //               />
  //             </Grid>
  //           )}
  //           {data.type === "date" && (
  //             <Grid item xs={12} md={12} className={classes.grid4}>
  //               <Typography
  //                 style={{
  //                   fontSize: "14px",
  //                   marginBottom: "5px",
  //                   color: "#9A9A9A",
  //                 }}
  //               >
  //                 Select File
  //               </Typography>
  //               <CustomTextfield
  //                 name="userName"
  //                 variant="outlined"
  //                 type="date"
  //                 size="small"
  //                 fullWidth
  //                 value={""}
  //               />
  //             </Grid>
  //           )}
  //           {/* {data.type === "location" && (
  //             <Grid item xs={12} md={12} className={classes.grid4}>
  //               <Typography
  //                 style={{
  //                   fontSize: "14px",
  //                   marginBottom: "5px",
  //                   color: "#9A9A9A",
  //                 }}
  //               >
  //                 Grant permission to access location
  //               </Typography>
  //               <Typography
  //                 style={{
  //                   fontSize: "14px",
  //                   marginBottom: "5px",
  //                   color: "#9A9A9A",
  //                 }}
  //               >
  //                 latitude: {location.coords.latitude}, longitude:{" "}
  //                 {location.coords.longitude}
  //               </Typography>
  //             </Grid>
  //           )} */}

  //           {/* <Grid item xs={12} md={12} className={classes.grid5}>
  //             <Typography>option 1</Typography>
  //             <CustomTextfield
  //               name="userRole"
  //               label="option"
  //               variant="outlined"
  //               size="small"
  //               fullWidth
  //               // value={role}
  //               // onChange={(e) => setRole(e.target.value)}
  //             />
  //           </Grid> */}
  //           <Grid item xs={12}></Grid>
  //           <Grid item xs={12}></Grid>
  //           {/* <Grid item xs={12} md={12} className={classes.grid4}>
  //             <CustomTextfield
  //               name="userEmail"
  //               label="Email"
  //               variant="outlined"
  //               size="small"
  //               fullWidth
  //               // value={email}
  //               // onChange={(e) => setEmail(e.target.value)}
  //             />
  //           </Grid> */}

  //           <Grid item xs={12} md={6} className={classes.grid4}></Grid>
  //         </Grid>
  //       </DialogContent>
  //       <DialogActions>
  //         <Grid container spacing={2} className={classes.grid7}>
  //           <Grid item xs={3} md={6} className={classes.grid3}>
  //             <CustomButton
  //               name="cancelBtn"
  //               color="primary"
  //               variant="outlined"
  //               onClick={() => setIsAddQuestionOpen(false)}
  //             >
  //               Cancel
  //             </CustomButton>
  //           </Grid>
  //           <Grid item xs={5} md={6} className={classes.grid6}>
  //             <CustomButton
  //               name="addNewBtn"
  //               color="primary"
  //               variant="contained"
  //               className={classes.CustomButton3}
  //               onClick={handleAddquestion}
  //               disabled={!data.question}
  //             >
  //               Add Question
  //             </CustomButton>
  //           </Grid>
  //         </Grid>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // };

  const handleListSelect = (value) => {
    setSurveySelect(value);
    dispatch(setSelectedSurvey(value));
    dispatch(setQuestions([]));
    setSelected("");
    dispatch(getSections(login?.token, value?._id, value?.published_version));
  };

  const handleGetQuestion = (value, description) => {
    setSectionDescription(description);
    dispatch(
      getQuestion(
        login?.token,
        value?._id,
        100,
        0,
        selectSurvey?.published_version,
        sectionWiseQuestion,
        false
      )
    );
  };

  useEffect(() => {
    if (publishedSurveyList?.length > 0 && sectionWiseData?.length === 0) {
      let getDefault = publishedSurveyList.find((s) => s?.default);
      handleListSelect(getDefault);
    }

    if (sectionWiseData?.length > 0 && sectionWiseQuestion?.length === 0) {
      setSelected(sectionWiseData[0]?.section_name);
      handleGetQuestion(
        sectionWiseData[0],
        sectionWiseData[0]?.section_description
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishedSurveyList, sectionWiseData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: SET_PUBLISHED_SURVEY_LIST,
        payload: [],
      });
      dispatch(setQuestions([]));
      dispatch(setSelectedSection(""));
      dispatch(setSection([]));
      dispatch(setSelectedSurvey(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ height: "63vh" }} className={classes.mainDiv}>
      {/* {isAddQuestionOpen && addQuestionDialog()} */}
      <ViewSurveySelect
        viewListing={viewListing}
        setViewListing={setViewListing}
        list={publishedSurveyList}
        handleListSelect={handleListSelect}
        surveySelect={selectSurvey}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Survey
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <Button
            // variant="outlined"
            size="small"
            // startIcon={<Add />}
            // color="primary"
            style={{
              color: "#3374b9",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={() => setIsAddQuestionOpen(!isAddQuestionOpen)}
          >
            Add Question
          </Button> */}
          <Button
            size="small"
            style={{
              color: "#3374b9",
              textTransform: "none",
              marginRight: "5px",
            }}
            onClick={() => setViewListing(!viewListing)}
          >
            <img
              src={clipboard}
              alt="docIcon"
              style={{ width: 22, marginRight: 5 }}
            />
            Survey Select
          </Button>
          <CustomButton
            size="small"
            color="primary"
            style={{
              textTransform: "none",
            }}
            onClick={() => setShareDialogOpen(true)}
            disabled={
              selectSurvey?._id === selectedSurveyId ||
              selectSurvey?.is_published
            }
          >
            {/* <Icon style={{ color: "#3374b9", cursor: "pointer" }}> */}
            <ShareOutlinedIcon style={{ marginRight: "5px" }} />{" "}
            {`${
              selectSurvey?.is_published ? "Already Shared" : "Share Survey"
            } `}
            {/* </Icon> */}
          </CustomButton>
        </div>
      </div>
      {sectionWiseData.length > 0 ? (
        <ResponseSectionListLeft
          users={sectionWiseData}
          selected={selected}
          setSelected={setSelected}
          hidesearch
          handleGetQuestion={handleGetQuestion}
        >
          <div
            className="scroll"
            ref={scroll}
            style={{ width: "78%", height: "59vh" }}
          >
            {/* {selected === 0 && ( */}
            <>
              {selected && (
                <BlueBackgroundCard
                  heading={selected}
                  subHeading={sectionDescription}
                />
              )}
              {sectionWiseQuestion?.map((question, idx) => {
                return (
                  <Question
                    key={idx}
                    question={question.question}
                    options={question.answers}
                    type={question.question_type}
                    weightage={question?.weightage}
                    hasComment={question?.comment}
                  />
                );
              })}
              {sectionWiseQuestion.length === 0 && (
                <NoData message={"please select a section"} />
              )}
            </>
          </div>
        </ResponseSectionListLeft>
      ) : (
        <NoData message={"please select a survey"} />
      )}
      <br /> <br />
      {/* </div> */}
    </div>
  );
}

export default SurveyQuestions;
