import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialityAssessmentLeft from "./../widgets/materialityAssessmentLeft/materialityAssessmentLeft";
import MaterialityAssessmentRight from "./../widgets/materialityAssessmentRight/materialityAssessmentRight";

function MaterialityAssesment(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleWidth() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWidth);

    return () => {
      // cancel the subscription
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 992) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [windowWidth]);

  return (
    <Grid
      container
      spacing={2}
      style={{ display: "flex",  height: windowWidth >= 992 ? "76vh" : "90%", padding: 10 }}
    >
      <Grid item xs={11} md={6} style={{ height: windowWidth >= 962 ? "100%" : "50%"  }}>
        <MaterialityAssessmentLeft {...props} />
      </Grid>
      <Grid item xs={11} md={6} style={{ height:windowWidth >= 962 ? "100%" : "50%" }}>
        <MaterialityAssessmentRight {...props} />
      </Grid>
    </Grid>
  );
}

export default React.memo(MaterialityAssesment);
