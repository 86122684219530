/* eslint-disable no-useless-concat */
const createCubeJSSchema = ({
  index,
  kpiName,
  chartYCustom,
  measureField,
  subMeasureField,
  dimensionField,
  CURRENT_ORGANIZATION,
}) => {
  // example if measure = HC_percentage_of_workers_covered then subMeasureField = percentage_measure
  if (!subMeasureField || !dimensionField) {
    const shortName = measureField.split("_")[1];
    subMeasureField = `${shortName}_measure`;
    dimensionField = `${shortName}_dimension`;
  }
  const sampleCube = {
    id: index,
    name: kpiName,

    vizState: {
      query: {
        limit: 5000,
        measures: [`${measureField}.${subMeasureField}`],
        order: {
          [`${measureField}.date`]: "asc",
        },
        dimensions: [`${measureField}.${dimensionField}`],
        filters: [
          {
            member: `${measureField}.organisationid`,
            operator: "equals",
            values: [CURRENT_ORGANIZATION],
          },
        ],
        timeDimensions: [
          {
            dimension: `${measureField}.date`,
            granularity: "month",
          },
        ],
      },
      chartType: "line",
    },

    pivotConfig: {
      x: [`${measureField}.${dimensionField}`],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },

    chartX_AxisCustomize: {
      display: true,
      text: "Time",
      align: "center",
      color: "#242424",
      font: {
        size: 15,
        family: "Poppins",
      },
    },
    chartY_AxisCustomize: {
      display: true,
      text: `${chartYCustom}`,
      align: "center",
      color: "#242424",
      font: {
        size: 15,
        family: "Poppins",
      },
    },
  };
  return sampleCube;
};

const handleKpiDataWithOrganisation = (CURRENT_ORGANIZATION) => {
  const KPI_DATA = {
    // ---------------Social Capital---------------

    "Security, Human rights & rights of indigenous peoples": [
      // Leadership_Govenance_3_205_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of operations assessed for risks related to corruption",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "total_number_of_oper_assessed_risks_related_corp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of operations assessed for risks related to corruption",
        chartYCustom: "Count",
        measureField: "Gri_" + "percentage_of_oper_assessed_risks_related_corp",
      }),

      // Leadership_Govenance_3_205_2_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of governance body members that the organization’s anti-corruption policies and procedures have been communicated to",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_num_gover_body_membrs_org_anticorrup_policies_procedures_comltd",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of governance body members that the organization’s anti-corruption policies and procedures have been communicated to",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_gover_body_membrs_org_anticorruption_policies_procedures_comltd",
      }),

      // Leadership_Govenance_3_205_2_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of employees that the organization’s anti-corruption policies and procedures have been communicated to",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "total_num_emp_orgs_anticorp_policies_and_procedures_comltd",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of employees that the organization’s anti-corruption policies and procedures have been communicated to",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_of_emp_orgs_anticorp_policies_and_procedures_comltd",
      }),

      // Leadership_Govenance_3_205_2_3_c
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of business partners that the organization’s anti-corruption policies and procedures have been communicated to",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_num_business_partners_orgs_anticorp_policies_and_procedures_commltd_to",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of business partners that the organization’s anti-corruption policies and procedures have been communicated to",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_business_partners_orgs_anticorp_policies_and_procedures_commltd_to",
      }),

      // Leadership_Govenance_3_205_3_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of confirmed incidents of corruption",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_number_of_cnfrm_incidents_of_corp",
        subMeasureField: "total_measure",
        dimensionField: "total_dimension",
      }),

      // Leadership_Govenance_3_205_3_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of confirmed incidents in which employees were dismissed or disciplined for corruption",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_num_cnfrm_incidents_emp_dismissed_or_disciplined_for_corp",
        subMeasureField: "total_measure",
        dimensionField: "total_dimension",
      }),

      // Leadership_Govenance_3_205_3_3_c
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of confirmed incidents when contracts with business partners were terminated or not renewed due to violations related to corruption",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_number_cnfrm_incidents",
        subMeasureField: "total_measure",
        dimensionField: "total_dimension",
      }),

      // Leadership_Govenance_3_205_3_4_d
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Number of Public legal cases regarding corruption brought against the organization or its employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_number_public_legal_cases",
        subMeasureField: "total_measure",
        dimensionField: "total_dimension",
      }),

      // Leadership_Govenance_3_206_1_1
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of legal actions pending or completed regarding anti-competitive behavior and violations of anti-trust and monopoly legislation in which the organization has been identified as a participant.",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_legal_actn_pend_compltd_regrd_anticmpttv_behavior_viotrust_mnply_legislation_org",
        subMeasureField: "total_measure",
        dimensionField: "total_dimension",
      }),

      // Socialcapital_411_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of identified incidents of violations involving the rights of indigenous peoples",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_identified_incdnt_violtin_involving_rights_indigenous_pples",
      }),

      // Socialcapital_411_1_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of identified incidents of violations involving the rights of indigenous peoples reviewed by the organization",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_identified_incdnt_violtn_involving_rights_indigenous_pples_rvwed_by_the_orgns",
      }),

      // Socialcapital_411_1_2_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of identified incidents of violations involving the rights of indigenous peoples where Remediation plans being implemented",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_identified_incdnt_violtn_involving_rights_indigenous_pples_remndtn_plans_impletd",
      }),

      // Socialcapital_411_1_2_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of identified incidents of violations involving the rights of indigenous peoples where Remediation plans that have been implemented, with results reviewed through routine internal management review processes",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_identified_incdnt_violtn_involving_rights_indigenous_pples_remndtn_plans_impletd_rslt_rvwd_internal_management",
      }),

      // Socialcapital_411_1_2_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of identified incidents of violations involving the rights of indigenous peoples which are no longer subject to action",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_identified_incdnt_violtn_involving_rights_indigenous_pples_no_longer_subject_to_action",
      }),

      // Socialcapital_413_2_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of operations in which workers’ rights to exercise freedom of association or collective bargaining may be violated or at significant risk",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_oprtn_wrkr_rights_exrse_freedom_association_collective_brgng_violated_significant_risk",
        subMeasureField: "number_oprtn_wrkr",
        dimensionField: "total_number",
      }),

      // Socialcapital_414_2_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of suppliers assessed for social impacts",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Number_of_suppliers_assessed_for_social_impacts",
        subMeasureField: "number_suppliers",
        dimensionField: "total_number",
      }),

      // Socialcapital_414_2_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of suppliers identified as having significant actual and potential negative social impacts",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_suppliers_identified_significant_actual_potential_negative_social_impacts",
        subMeasureField: "number_suppliers",
        dimensionField: "total_number",
      }),

      // Socialcapital_415_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total monetary value of financial and in-kind political contributions made directly and indirectly by the organization",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_monetary_financ_inkind_political_contri_made_direct_indirect_by_org",
      }),

      // Socialcapital_416_2_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of non-compliance with regulations resulting in a fine or penalty",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_of_incidents_of_noncompliance_with_regulations_resulting_fine_or_penalty",
        subMeasureField: "total_number_incidents",
        dimensionField: "total_number",
      }),

      // Socialcapital_416_2_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of non-compliance with regulations resulting in a warning",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_of_incidents_of_noncompliance_with_regulations_resulting_in_a_warning",
        subMeasureField: "total_number_incidents",
        dimensionField: "total_number",
      }),

      // Socialcapital_416_2_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of non-compliance with voluntary codes",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_of_incidents_of_noncompliance_with_voluntary_codes",
        subMeasureField: "total_number_incidents",
        dimensionField: "total_number",
      }),

      // Socialcapital_417_2_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of non-compliance with regulations concerning product and service information and labeling, resulting in a fine or penalty",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_of_incidents_of_noncompliance_rgltion_cncrning_prdct_and_srvce_infrmtn_lblng_rsltng_fine_penalty",
        subMeasureField: "total_number_incidents",
        dimensionField: "total_number",
      }),

      // Socialcapital_417_2_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of non-compliance with regulations concerning product and service information and labeling resulting in a warning",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_of_incidents_of_noncompliance_rgltion_cncrning_prdct_and_srvce_infrmtn_lblng_rsltng_warning",
        subMeasureField: "total_number_incidents",
        dimensionField: "total_number",
      }),

      // Socialcapital_417_2_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of non-compliance with voluntary codes concerning product and service information and labeling.",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_of_incidents_of_noncompliance_voluntary_codes_cncrning_prdct_and_srvce_infrmtn_lblng_rsltng",
        subMeasureField: "total_number_incidents",
        dimensionField: "total_number",
      }),

      // Socialcapital_417_3_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of non-compliance with regulations and/or voluntary codes concerning marketing communications resulting in a fine or penalty",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_of_incidents_of_noncompliance_rgltion_voluntary_codes_cncrning_mrkting_comnctn_rsltng_fine_penalty",
        subMeasureField: "total_number_incidents",
        dimensionField: "total_number",
      }),

      // Socialcapital_417_3_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of non-compliance with regulations and/or voluntary codes concerning marketing communications resulting in a warning",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_of_incidents_of_noncompliance_rgltion_voluntary_codes_cncrning_mrkting_comnctn_rsltng_warning",
        subMeasureField: "total_number_incidents",
        dimensionField: "total_number",
      }),

      // Socialcapital_417_3_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of non-compliance of voluntary codes concerning marketing communications resulting in a fine or penalty",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_of_incidents_of_noncompliance_of_voluntary_codes_cncrning_mrkting_comnctn_rsltng",
        subMeasureField: "total_number_incidents",
        dimensionField: "total_number",
      }),

      // Socialcapital_418_1_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of substantiated complaints received concerning breaches of customer privacy received from outside parties and substantiated by the organization",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Sstaind_cmpnt_rcved_cncrng_brches_cstmr_prvc_otsde_prt_sustain_by_org",
        subMeasureField: "total_cmpnt_rcved",
        dimensionField: "total_number",
      }),

      // Socialcapital_418_1_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of substantiated complaints received concerning breaches of customer privacy received from regulatory bodies",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Sstaind_cmpnt_rcved_cncrng_brches_cstmr_prvc_rcvd_frm_regulatory_bodies",
        subMeasureField: "total_cmpnt_rcved",
        dimensionField: "total_number",
      }),

      // Socialcapital_418_1_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of identified leaks, thefts, or losses of customer data.",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Identified_leaks_thefts_losses_of_customer_data",
        subMeasureField: "total_identified_leaks",
        dimensionField: "total_number",
      }),
    ],

    // ----------------------------------------

    Wages: [
      // Businessmodel_202_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Ratios of standard entry level wage of employees compared to local minimum wage",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "ratio_standard_entry_level_wage_of_employee_to_local_minimum_wage",
      }),

      // Businessmodel_202_1_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Ratios of standard entry level wage of workers compared to local minimum wage",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "ratio_standard_entry_level_wage_of_worker_to_local_minimum_wage",
      }),
    ],

    // ----------------------------------------

    Materials: [
      // Enviromental_301_1_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-renewable materials that are used to produce and package the organization’s primary products and services",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_weight_of_nonrenewable_materials_used_produce_package_org_primary_products_services",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Volume of non-renewable materials that are used to produce and package the organization’s primary products and services",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_volume_of_nonrenewable_materials_used_produce_package_org_primary_products_services",
        subMeasureField: "total_volume",
        dimensionField: "total_number",
      }),

      // Enviromental_301_1_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of renewable materials that are used to produce and package the organization’s primary products and services",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_weight_of_renewable_materials_used_produce_package_org_primary_products_services",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Volume of renewable materials that are used to produce and package the organization’s primary products and services",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_volume_of_renewable_materials_used_produce_package_org_primary_products_services",
        subMeasureField: "total_volume",
        dimensionField: "total_number",
      }),

      // Businessmodel_201_4_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total monetary value of financial assistance received by the organization from any government",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_monetary_value_financ_assist_recvd_by_the_org_from_any_gov",
      }),

      // Businessmodel_204_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of the procurement budget used for significant locations of operation that is spent on suppliers local to that operation (such as percentage of products and services purchased locally).",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_procurement_budgt_used_sgnificnt_locations_oper_spent_suppliers_local_oper",
      }),

      // Enviromental_301_1_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Volume of materials that are used to produce and package the organization’s primary products and services",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_volume_of_nonrenewable_materials_used_produce_package_org_primary_products_services",
        subMeasureField: "total_volume",
        dimensionField: "total_number",
      }),

      // Enviromental_301_2_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of recycled input materials used to manufacture the organization's primary products and services",
        chartYCustom: "Count",
        measureField: "Gri_" + "percentage_of_recycled_input_materials",
      }),

      // Enviromental_301_3_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of reclaimed products",
        chartYCustom: "Count",
        measureField: "Gri_" + "percentage_of_reclaimed_products",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of reclaimed packaging",
        chartYCustom: "Count",
        measureField: "Gri_" + "Percentage_of_reclaimed_packaging_materials",
      }),
    ],
    // ----------------------------------------

    // ---------------Environment---------------

    Waste: [
      // Enviromental_306_3_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total weight of waste generated in metric tons",
        chartYCustom: "Metric Tons",
        measureField: "Gri_" + "total_weight_of_waste_generated_in_metric_tons",
      }),

      // Enviromental_306_4_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total weight of waste diverted from disposal in metric tons",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_waste_diverted_from_disposal_in_metric_tons",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_4_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of hazardous waste diverted from disposal in metric tons",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_hazardous_waste_diverted_from_disposal_in_metric_tons",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of hazardous waste diverted from disposal by Preparation for reuse",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_hazardous_waste_diverted_from_disposal_by_preparation_for_reuse",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_4_2_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of hazardous waste diverted from disposal by Recycling",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_hazardous_waste_diverted_from_disposal_by_recycling",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_4_2_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of hazardous waste diverted from disposal by Other recovery operations",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_hazardous_waste_diverted_from_disposal_by_other_recovery_operations",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_4_3_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste diverted from disposal in metric tons",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_nonhazardous_waste_diverted_from_disposal_in_metric_tons",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_4_3_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste diverted from disposal by Preparation for reuse",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_nonhazardous_waste_diverted_from_disposal_by_preparation_for_reuse",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_4_3_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste diverted from disposal by Recycling",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_nonhazardous_waste_diverted_from_disposal_by_recycling",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste diverted from disposal by Other recovery operations",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_nonhazardous_waste_diverted_from_disposal_by_other_recovery_operations",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_4_4_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of hazardous waste diverted from onsite disposal",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_hazardous_waste_diverted_from_onsite_disposal",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste diverted from onsite disposal",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_nonhazardous_waste_diverted_from_onsite_disposal",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_4_4_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of hazardous waste diverted from offsite disposal",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_hazardous_waste_diverted_from_offsite_disposal",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste diverted from offsite disposal",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_nonhazardous_waste_diverted_from_offsite_disposal",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_5_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total weight of waste directed to disposal in metric tons",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_waste_directed_to_disposal_in_metric_tons",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_5_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of hazardous waste directed to disposal by Incineration (with energy recovery)",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_hazardous_waste_directed_to_disposal_by_incineration_with_energy_recovery",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_5_2_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of hazardous waste directed to disposal by Incineration (without energy recovery)",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_hazardous_waste_directed_to_disposal_by_incineration_without_energy_recovery",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_5_2_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of hazardous waste directed to disposal by Landfilling",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_hazardous_waste_directed_to_disposal_by_landfilling",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_5_2_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of hazardous waste directed to disposal by Other disposal operations",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_hazardous_waste_directed_to_disposal_by_other_disposal_operations",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_5_3_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste directed to disposal by Incineration (with energy recovery)",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_nonhazardous_waste_directed_to_disposal_by_incineration_with_energy_recovery",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_5_3_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste directed to disposal by Incineration (without energy recovery)",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_nonhazardous_waste_directed_to_disposal_by_incineration_without_energy_recovery",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_5_3_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste directed to disposal by Landfilling",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_nonhazardous_waste_directed_to_disposal_by_landfilling",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_5_3_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste directed to disposal by Other disposal operations",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "total_nonhazardous_waste_directed_to_disposal_by_other_disposal_operations",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_5_4_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total weight of hazardous waste directed to disposal onsite",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_hazardous_waste_directed_to_disposal_onsite",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste directed to disposal onsite",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_nonhazardous_waste_directed_to_disposal_onsite",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),

      // Enviromental_306_5_4_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total weight of hazardous waste directed to disposal offsite",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_hazardous_waste_directed_to_disposal_offsite",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total weight of non-hazardous waste directed to disposal offsite",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "total_nonhazardous_waste_directed_to_disposal_offsite",
        subMeasureField: "total_weight",
        dimensionField: "total_number",
      }),
    ],

    // ----------------------------------------

    "Greenhouse Gas Emission": [
      // Enviromental_305_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total direct (Scope 1) GHG emissions",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_direct_scope_1_ghg_emissions",
        subMeasureField: "total_scope",
        dimensionField: "total_number",
      }),

      // Enviromental_305_1_3_c
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Biogenic CO2 emissions from Scope 1 Emissions",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "total_biogenic_co2_emissions_from_scope_1_emissions",
        subMeasureField: "total_scope",
        dimensionField: "total_number",
      }),

      // Enviromental_305_2_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Scope 2 Emissions",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_scope_2_emissions",
        subMeasureField: "total_scope",
        dimensionField: "total_number",
      }),

      // Enviromental_305_3_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Scope 3 GHG emissions",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_scope_3_ghg_emissions",
        subMeasureField: "total_scope",
        dimensionField: "total_number",
      }),

      // Enviromental_305_3_3_c
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Biogenic CO2 emissions from Scope 3 Emissions",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "total_biogenic_co2_emissions_from_scope_3_emissions",
        subMeasureField: "total_scope",
        dimensionField: "total_number",
      }),

      // Enviromental_305_5_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total GHG emissions reduced as a direct result of reduction initiatives",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_ghg_emissions_reduced_as_a_direct_result_of_reduction_initiatives",
        subMeasureField: "total_scope",
        dimensionField: "total_number",
      }),

      // Enviromental_305_7_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total air emissions from NOx",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_air_emissions_from_nox",
        subMeasureField: "total_air",
        dimensionField: "total_number",
      }),

      // Enviromental_305_7_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total air emissions from SOx",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_air_emissions_from_sox",
        subMeasureField: "total_air",
        dimensionField: "total_number",
      }),

      // Enviromental_305_7_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total air emissions from Persistent organic pollutants (POP)",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Total_air_emissions_from_persistent_organic_pollutants_pop",
        subMeasureField: "total_air",
        dimensionField: "total_number",
      }),

      // Enviromental_305_7_1_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total air emissions from Volatile organic compounds (VOC)",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Total_air_emissions_from_volatile_organic_compounds_voc",
        subMeasureField: "total_air",
        dimensionField: "total_number",
      }),

      // Enviromental_305_7_1_v
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total air emissions from Hazardous air pollutants (HAP)",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Total_air_emissions_from_hazardous_air_pollutants_hap",
        subMeasureField: "total_air",
        dimensionField: "total_number",
      }),

      // Enviromental_305_7_1_vi
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total air emissions from Particulate matter (PM)",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_air_emissions_from_particulate_matter_pm",
        subMeasureField: "total_air",
        dimensionField: "total_number",
      }),

      // Enviromental_305_7_1_vii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total air emissions from Other standard categories of air emissions identified in relevant regulations",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_air_emissions_from_other_standard_categories_of_air_emissions_identified_in_relevant_regulations",
        subMeasureField: "total_air",
        dimensionField: "total_number",
      }),
    ],

    // ----------------------------------------

    Energy: [
      // Enviromental_302_1_3_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total electricity consumption within the organization",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_electricity_consption_within_org",
        subMeasureField: "total_electricity",
        dimensionField: "total_number",
      }),

      // Enviromental_302_1_3_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total heating consumption within the organization",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_heating_consption_within_org",
        subMeasureField: "total_heating",
        dimensionField: "total_number",
      }),

      // Enviromental_302_1_3_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total cooling consumption within the organization",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_cooling_consption_within_org",
        subMeasureField: "total_cooling",
        dimensionField: "total_number",
      }),

      // Enviromental_302_1_3_1_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total steam consumption within the organization",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_steam_consption_within_org",
        subMeasureField: "total_steam",
        dimensionField: "total_number",
      }),

      // Enviromental_302_1_4_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total electricity sold within the organization",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_electricity_sold_within_org",
        subMeasureField: "total_electricity",
        dimensionField: "total_number",
      }),

      // Enviromental_302_1_4_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total heating sold within the organization",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_heating_sold_within_org",
        subMeasureField: "total_heating",
        dimensionField: "total_number",
      }),

      // Enviromental_302_1_4_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total cooling sold within the organization",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_cooling_sold_within_org",
        subMeasureField: "total_cooling",
        dimensionField: "total_number",
      }),

      // Enviromental_302_1_4_1_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total steam sold within the organization",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_steam_sold_within_org",
        subMeasureField: "total_steam",
        dimensionField: "total_number",
      }),

      // Enviromental_302_1_5_e
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total energy consumption within the organization",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_energy_consptn_within_org",
      }),

      // Enviromental_302_2_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Energy consumption outside of the organization",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_energy_consptn_outside_org",
        subMeasureField: "total_energy",
        dimensionField: "total_number",
      }),

      // Enviromental_302_3_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Energy intensity ratio for the organization.",
        chartYCustom: "Count",
        measureField: "Gri_" + "energy_intensity_ratio_for_the_organization",
      }),

      // Enviromental_302_1_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total fuel consumption within the organization from non-renewable sources",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "total_fuel_consumption_from_nonrenewablesourcese",
        subMeasureField: "total_fuel",
        dimensionField: "total_number",
      }),

      // Enviromental_302_1_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total fuel consumption within the organization from renewable sources",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "total_fuel_consumption_from_renewable_sources_b",
        subMeasureField: "total_fuel",
        dimensionField: "total_number",
      }),

      // Enviromental_302_4_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Amount of reductions in energy consumption achieved as a direct result of conservation and efficiency initiatives,",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "amount_reduct_enrgy_consump_achieved_direct_reslt_conser_efficncy_initiative",
      }),

      // Enviromental_302_5_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Reductions in energy requirements of sold products and services achieved during the reporting period",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "reductions_enrgy_require_sold_products_srvces_achvd_in_reporting_period",
      }),
    ],

    Water: [
      // Enviromental_303_3_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water withdrawal from all areas from Surface water sources",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" + "Total_water_withdrawal_from_surface_water_sources",
        subMeasureField: "total_water_withdrawal",
        dimensionField: "total_number",
      }),

      // Enviromental_303_3_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water withdrawal from all areas from Groundwater sources",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" + "Total_water_withdrawal_from_groundwater_sources",
        subMeasureField: "total_water_withdrawal",
        dimensionField: "total_number",
      }),

      // Enviromental_303_3_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total water withdrawal from all areas from Seawater sources",
        chartYCustom: "Liters (L)",
        measureField: "Gri_" + "Total_water_withdrawal_from_seawater",
        subMeasureField: "total_water_withdrawal",
        dimensionField: "total_number",
      }),

      // Enviromental_303_3_1_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water withdrawal from all areas from Produced water sources",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" + "total_water_withdrawal_from_produced_water_sources",
        subMeasureField: "total_water_withdrawal",
        dimensionField: "total_number",
      }),

      // Enviromental_303_3_1_v
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water withdrawal from all areas from Third-party water sources",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" + "Total_water_withdrawal_from_third_party_water_source",
        subMeasureField: "total_water_withdrawal",
        dimensionField: "total_number",
      }),

      // Enviromental_303_3_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water withdrawal from all areas with water stress from Surface water sources",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" +
          "total_water_withdrawal_all_areas_water_stress_from_surface_water_sources",
      }),

      // Enviromental_303_3_2_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water withdrawal from all areas with water stress from Groundwater sources",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" +
          "total_water_withdrawal_all_areas_water_stress_from_groundwater_sources",
      }),

      // Enviromental_303_3_2_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water withdrawal from all areas with water stress from Seawater sources",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" +
          "total_water_withdrawal_all_areas_water_stress_from_seawater_sources",
      }),

      // Enviromental_303_3_2_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water withdrawal from all areas with water stress from Produced water sources",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" +
          "total_water_withdrawal_all_areas_water_stress_from_produced_water_sources",
      }),

      // Enviromental_303_3_2_v
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water withdrawal from all areas with water stress from Third-party water sources",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" +
          "total_water_withdrawal_all_areas_water_stress_from_thirdparty_water_sources",
      }),

      // Enviromental_303_3_3_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water withdrawal from Freshwater (≤1,000 mg/L Total Dissolved Solids)",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" + "total_water_withdrawal_from_freshwater_dissolved_solids",
      }),

      // Enviromental_303_3_3_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water withdrawal from Other water (>1,000 mg/L Total Dissolved Solids)",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" + "total_water_withdrawal_from_other_water_dissolved_solids",
      }),

      // Enviromental_303_4_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total water discharge to Surface water sources",
        chartYCustom: "Liters (L)",
        measureField: "Gri_" + "Total_water_discharged_to_surface_water",
        subMeasureField: "total_water_discharged",
        dimensionField: "total_number",
      }),

      // Enviromental_303_4_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total water discharge to Groundwater sources",
        chartYCustom: "Liters (L)",
        measureField: "Gri_" + "Total_water_discharged_to_groundwater",
        subMeasureField: "total_water_discharged",
        dimensionField: "total_number",
      }),

      // Enviromental_303_4_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total water discharge to Seawater sources",
        chartYCustom: "Liters (L)",
        measureField: "Gri_" + "Total_water_discharged_to_seawater",
        subMeasureField: "total_water_discharged",
        dimensionField: "total_number",
      }),

      // Enviromental_303_4_1_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total water discharge to Third-party water sources",
        chartYCustom: "Liters (L)",
        measureField: "Gri_" + "Total_water_discharged_thirdparties_sources",
        subMeasureField: "total_water_discharged",
        dimensionField: "total_number",
      }),

      // Enviromental_303_4_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water discharge to Freshwater (≤1,000 mg/L Total Dissolved Solids)",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" + "total_water_discharge_to_freshwater_dissolved_solids",
      }),

      // Enviromental_303_4_2_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water discharge to Other water (>1,000 mg/L Total Dissolved Solids)",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" + "total_water_discharge_to_other_water_dissolved_solids",
      }),

      // Enviromental_303_4_3_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water discharge to all areas with water stress by Freshwater (≤1,000 mg/L Total Dissolved Solids)",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" +
          "total_water_discharge_all_areas_water_stress_by_freshwater_dissolved_solids",
      }),

      // Enviromental_303_4_3_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total water discharge to all areas with water stress by Other water (>1,000 mg/L Total Dissolved Solids)",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" +
          "total_water_discharge_all_areas_water_stress_by_other_water_dissolved_solids",
      }),

      // Enviromental_303_4_4_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of incidents of non-compliance with discharge limits.",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "number_of_incidents_noncompliance_with_discharge_limits",
      }),

      // Enviromental_303_5_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total water consumption from all areas",
        chartYCustom: "Liters (L)",
        measureField: "Gri_" + "total_water_consumption_from_all_areas",
      }),

      // Enviromental_303_5_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total water consumption from all areas with water stress",
        chartYCustom: "Liters (L)",
        measureField:
          "Gri_" + "total_water_consumption_from_all_areas_with_water_stress",
      }),
    ],

    Biodiversity: [
      // Enviromental_304_1_1
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of operational site owned, leased, managed in, or adjacent to, protected areas and areas of high biodiversity value outside protected areas",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_optnl_site_ownd_leased_mangd_adjctnt_protd_areas_high_biodvsty_value_outside_protd",
        subMeasureField: "number_optnl_site_ownd",
        dimensionField: "total_number",
      }),

      // Enviromental_304_2_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of significant direct and indirect impacts on biodiversity with reference to Construction or use of manufacturing plants, mines, and transport infrastructure",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_biodvsty_refrnc_to_cnstr_manufac_plnts_mines_trnsprt_infrasctr",
        subMeasureField: "number_biodvsty_refrnc",
        dimensionField: "total_number",
      }),

      // Enviromental_304_2_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of significant direct and indirect impacts on biodiversity with reference to Pollution",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_direct_indirect_impct_on_biodvsty_refrnce_polution",
        subMeasureField: "number_impct_biodvsty_refrnce_polution",
        dimensionField: "total_number",
      }),

      // Enviromental_304_2_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of significant direct and indirect impacts on biodiversity with reference to Introduction of invasive species, pests, and pathogens",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_direct_indirect_impct_biodiversity_refrnce_intro_invasive_species_pests_and_pathogens",
        subMeasureField: "number_impct_biodiversity_refrnce_species",
        dimensionField: "total_number",
      }),

      // Enviromental_304_2_1_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of significant direct and indirect impacts on biodiversity with reference to Reduction of species",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_direct_indirect_impct_biodivsty_refrnce_reduction_species",
        subMeasureField: "number_impct_biodivsty_refrnce_reduction_species",
        dimensionField: "total_number",
      }),

      // Enviromental_304_2_1_vi
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of significant direct and indirect impacts on biodiversity with reference to Changes in ecological processes outside the natural range of variation",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_direct_indirect_impct_biodivsty_refrnce_changes_in_ecological_proces_outside_natural_range_ovartn",
        subMeasureField: "number_impct_biodivsty_refrnce_ecological",
        dimensionField: "total_number",
      }),

      // Enviromental_304_4_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of IUCN Red List species and national conservation list species with habitats in areas affected by the operations",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_num_iucn_red_list_species_national_conser_list_species_habitats_areas_affctd_by_oprtn",
      }),

      // Enviromental_305_6_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total Production of ODS in metric tons of CFC-11 (trichlorofluoromethane) equivalent",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_Production_ODS_metric_tons_CFC11_trichlorofluoromethane_equivalent",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total imports of ODS in metric tons of CFC-11 (trichlorofluoromethane) equivalent.",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_imports_ODS_metric_tons_CFC11_trichlorofluoromethane_equivalent",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total exports of ODS in metric tons of CFC-11 (trichlorofluoromethane) equivalent.",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_exports_ODS_metric_tons_CFC11_trichlorofluoromethane_equivalent",
      }),

      // Socialcapital_413_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of operations with implemented local community engagement, impact assessments, and/or development programs",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_of_operations_implemented_local_community_engagement_impact_assessments_development_programs",
      }),

      // Socialcapital_414_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of new suppliers that were screened using social criteria",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "number_of_new_suppliers_screened_using_environmental_criteria",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of new suppliers that were screened using social criteria",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_of_new_suppliers_screened_using_environmental_criteria",
      }),

      // Socialcapital_414_2_4_d
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of suppliers identified as having significant actual and potential negative social impacts with which improvements were agreed upon as a result of assessment.",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_of_suppliers_identified_as_having_significant_actual_and_potential_negative_social_impacts_with_which_improvements",
      }),

      // Socialcapital_414_2_5_e
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of suppliers terminated",
        chartYCustom: "Count",
        measureField: "Gri_" + "percentage_of_supplier_terminated",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total negative impact suppliers",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_negative_impact_suppliers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total terminated suppliers",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_terminated_suppliers",
      }),

      // Socialcapital_416_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of significant product and service categories for which health and safety impacts are assessed for improvement",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_significant_product_service_categories_health_safety_impacts_assessed_improvement",
      }),

      // Socialcapital_417_1_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of significant product or service categories covered by and assessed for compliance Requirements of information and labeling",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_significant_product_service_categories_covered_assessed_compliance_with_such_procedures",
      }),
    ],

    // ----------------------------------------

    Suppliers: [
      // Enviromental_308_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of new suppliers",
        chartYCustom: "Metric Tons",
        measureField: "Gri_" + "number_of_new_suppliers",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of new suppliers that were screened using environmental criteria",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" + "percentage_new_suppliers_screened_environmental_criteria",
      }),

      // Enviromental_308_2_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of suppliers assessed for environmental impacts",
        chartYCustom: "Metric Tons",
        measureField: "Gri_" + "Number_of_suppliers_assessed_envental_impacts",
        subMeasureField: "number_suppliers_envental_impacts",
        dimensionField: "total_number",
      }),

      // Enviromental_308_2_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of suppliers identified as having significant actual and potential negative environmental impacts",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "Number_of_suppliers_identified_hving_significant_actual_potential_negative_envmtal_impacts",
        subMeasureField: "number_suppliers_negative_envmtal_impacts",
        dimensionField: "total_number",
      }),

      // Enviromental_308_2_5_e
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of suppliers identified as having significant actual and potential negative environmental impacts with which relationships were terminated as a result of assessment",
        chartYCustom: "Metric Tons",
        measureField:
          "Gri_" +
          "Number_of_suppliers_identified_hvng_significant_actual_potential_negative_envmntal_impacts_relationship_termntd_rslt_asment",
        subMeasureField:
          "number_suppliers_identified_negative_envmntal_impacts_relationship_termntd",
        dimensionField: "total_number",
      }),
    ],
    // ----------------------------------------

    // ---------------HUMAN CAPITAL---------------

    "Workforce Health & safety": [
      // Humancapital_403_10_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of fatalities as a result of work-related ill health of employee",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Number_fatalities_result_workrelated_ill_health_emp",
        subMeasureField: "Number_fatalities_workrelated_ill_health_emp",
        dimensionField: "total_number",
      }),

      // Humancapital_403_10_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of cases of recordable work-related ill health of employee",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Number_cases_recordable_workrelated_ill_health_emp",
        subMeasureField: "number_cases_recordable_workrelated_ill_health_emp",
        dimensionField: "total_number",
      }),

      // Humancapital_403_10_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of fatalities as a result of work-related ill health of workers",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Number_fatalities_result_workrelated_ill_health_workr",
        subMeasureField: "Number_fatalities_workrelated_ill_health_work",
        dimensionField: "total_number",
      }),

      // Humancapital_403_10_2_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of cases of recordable work-related ill health of workers",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Number_cases_recordable_workrelated_ill_health_work",
        subMeasureField: "number_cases_recordable_workrelated_ill_health_",
        dimensionField: "total_number",
      }),

      // Humancapital_403_5_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of occupational health and safety training provided to workers",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "number_of_occupational_health_safety_training_provided_workers",
      }),

      // Humancapital_403_9_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of employee fatalities as a result of work-related injury",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "number_of_emp_fatalities_as_a_result_of_workrelated_injury",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Rate of employees fatalities as a result of work-related injury",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "rate_of_emp_fatalities_as_a_result_of_workrelated_injury",
      }),

      // Humancapital_403_9_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of high-consequence work-related injuries (excluding fatalities) of employees",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "number_of_emp_highconsequence_workrelated_injuries_excluding_fatalities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Rate of high-consequence work-related injuries (excluding fatalities) of employees",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "rate_of_emp_highconsequence_workrelated_injuries_excluding_fatalities",
      }),

      // Humancapital_403_9_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of recordable work-related injuries of employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "number_of_emp_recordable_workrelated_injuries",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of recordable work-related injuries of employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "rate_of_emp_recordable_workrelated_injuries",
      }),

      // Humancapital_403_9_1_v
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "The number of hours worked by employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "Number_hours_worked_emp",
        subMeasureField: "number_hours_worked_emp",
        dimensionField: "total_number",
      }),

      // Humancapital_403_9_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of worker fatalities as a result of work-related injury",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "number_of_wrkr_fatalities_as_a_result_of_workrelated_injury",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of worker fatalities as a result of work-related injury",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "rate_of_wrkr_fatalities_as_a_result_of_workrelated_injury",
      }),

      // Humancapital_403_9_2_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of high-consequence work-related injuries (excluding fatalities) of workers",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "number_wrkr_highconsequence_workrelated_injuries_excluding_fatalities",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Rate of high-consequence work-related injuries (excluding fatalities) of workers",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "rate_wrkr_highconsequence_workrelated_injuries_excluding_fatalities",
      }),

      // Humancapital_403_9_2_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of recordable work-related injuries of workers",
        chartYCustom: "Count",
        measureField: "Gri_" + "number_of_wrkr_recordable_workrelated_injuries",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of recordable work-related injuries of workers",
        chartYCustom: "Count",
        measureField: "Gri_" + "rate_of_wrkr_recordable_workrelated_injuries",
      }),
    ],

    // ----------------------------------------

    "Training & Awareness Programmes": [
      // Businessmodel_202_2_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of governance body members that have received training on anti-corruption",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "total_num_gover_body_members_received_training_on_anticorp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of governance body members that have received training on anti-corruption",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "percentage_gov_body_members_received_training_on_anticorp",
      }),

      // Leadership_Govenance_3_205_2_5_e
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of employees that have received training on anti-corruption",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_num_emp_received_training_on_anticorp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of employees that have received training on anti-corruption",
        chartYCustom: "Count",
        measureField: "Gri_" + "percentage_emp_received_training_on_anticorp",
      }),

      // Socialcapital_410_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of security personnel in the organization",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_of_security_personnel_who_have_received_formal_training",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of security personnel in the organization",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "number_of_security_personnel_in_the_organization",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of security personnel who have received formal training in the organization’s human rights policies or specific procedures and their application to security",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "number_of_security_personnel_who_have_received_formal_training_in_the_organization",
      }),
    ],

    // ----------------------------------------

    "Employee Diversity and Inclusion": [
      // Businessmodel_202_2_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of senior management that are hired from the local community",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_of_senior_management_hired_from_the_local_community",
      }),

      // Businessmodel_201_3_4_d
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of salary contributed by employer",
        chartYCustom: "Count",
        measureField: "Gri_" + "employer_contribution_percentage",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of salary contributed by employee",
        chartYCustom: "Count",
        measureField: "Gri_" + "employee_contribution_percentage",
      }),

      // Businessmodel_201_3_5_e
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Percentage of participation in retirement plans",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "percentage_of_participation_in_retirement_plans",
      }),

      // General_Discloser_2_30_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of total employees covered by collective bargaining agreements",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_of_total_emp_covered_collective_bargaining_agreements",
        subMeasureField: "total_emp",
        dimensionField: "total_number",
      }),

      // Human_capital_401_2_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of full-time employees provided with retirement provision",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Number_of_fulltime_emp_provided_retirement_provision",
        subMeasureField: "number_fulltime",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of full-time employees provided with stock ownership",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Number_of_fulltime_emp_provided_stock_ownership",
        subMeasureField: "number_fulltime",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of full-time employees provided with others benefits",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Number_of_fulltime_employees_provided_with_others_benefits",
        subMeasureField: "number_fulltime",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of full-time employees provided with disability and invalidity coverage",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_fulltime_emp_provided_with_disability_and_invalidity_coverage",
        subMeasureField: "number_fulltime",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of full-time employees provided with life insurance",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Number_of_full_time_employees_provided_with_life_insurance",
        subMeasureField: "number_fulltime",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of full-time employees provided with health care",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Number_of_full_time_employees_provided_with_health_care",
        subMeasureField: "number_fulltime",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of full-time employees provided with parental leave",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Number_of_full_time_employees_provided_with_parental_leave",
        subMeasureField: "number_fulltime",
        dimensionField: "total_number",
      }),

      // Humancapital_401_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total new employee hires b/w age group 30-50 years",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "hire_number_by_age_group_emps_btwn_3050_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total new employee hires over age group 50 years",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "hire_number_by_age_group_emps_over_50_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total new employee hires under age group 30 years",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "hire_number_by_age_group_emps_under_30_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total new employee hires by gender female",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_number_by_gender_female",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total new employee hires by gender male",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_number_by_gender_male",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total new employee hires by region south",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_number_by_region_south",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total new employee hires by region west",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_number_by_region_west",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total new employee hires by region east",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_number_by_region_east",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total new employee hires by region north",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_number_by_region_north",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of new employee hires b/w age group 30-50 years",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "hire_rate_by_age_group_emps_btwn_3050_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of new employee hires over age group 50 years",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_rate_by_age_group_emps_over_50_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of new employee hires under age group 30 years",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_rate_by_age_group_emps_under_30_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of new employee hires by gender female",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_rate_by_gender_female",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of new employee hires by gender male",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_rate_by_gender_male",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of new employee hires by region south",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_rate_by_region_south",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of new employee hires by region west",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_rate_by_region_west",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of new employee hires by region east",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_rate_by_region_east",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of new employee hires by region north",
        chartYCustom: "Count",
        measureField: "Gri_" + "hire_rate_by_region_north",
      }),

      // Humancapital_401_1_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of employee turnover b/w age group 30-50 years",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "turnover_number_by_age_group_emps_btwn_3050_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of employee turnover over age group 50 years",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "turnover_number_by_age_group_emps_over_50_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of employee turnover under age group 30 years",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "turnover_number_by_age_group_emps_under_30_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of employee turnover by gender female",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_number_by_gender_female",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of employee turnover by gender male",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_number_by_gender_male",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of employee turnover by region south",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_number_by_region_south",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of employee turnover by region west",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_number_by_region_west",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of employee turnover by region east",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_number_by_region_east",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of employee turnover by region north",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_number_by_region_north",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of employee turnover b/w age group 30-50 years",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "turnover_rate_by_age_group_emps_btwn_3050_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of employee turnover over age group 50 years",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "turnover_rate_by_age_group_emps_over_50_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of employee turnover under age group 30 years",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "turnover_rate_by_age_group_emps_under_30_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of employee turnover by gender female",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_rate_by_gender_female",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of employee turnover by gender male",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_rate_by_gender_male",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of employee turnover by region south",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_rate_by_region_south",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of employee turnover by region west",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_rate_by_region_west",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of employee turnover by region east",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_rate_by_region_east",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Rate of employee turnover by region north",
        chartYCustom: "Count",
        measureField: "Gri_" + "turnover_rate_by_region_north",
      }),

      // Humancapital_401_3_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of employees that were entitled to parental leave",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_number_of_emp_entitled_parental_leave",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Male employees that were entitled to parental leave",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Total_number_of_male_emp_entitled_to_parental_leave",
        subMeasureField: "total_number_male",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Female employees that were entitled to parental leave",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Total_number_of_female_emp_entitled_to_parental_leave",
        subMeasureField: "total_number_female",
        dimensionField: "total_number",
      }),

      // Humancapital_401_3_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of employees that took parental leave",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_number_emp_took_parental_leave",
        subMeasureField: "total_number_emp_took_parental_leave",
        dimensionField: "total_number",
      }),

      // Humancapital_401_3_3_c
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of employees that returned to work in the reporting period after parental leave ended",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_of_emp_returned_work_reporting_period_after_parental_leave_ended",
        subMeasureField:
          "total_number_emp_reporting_after_parental_leave_ended",
        dimensionField: "total_number",
      }),

      // Humancapital_401_3_4_d
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of employees that returned to work after parental leave ended that were still employed 12 months after their return to work",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_number_of_emp_returned_work_after_parental_leave_ended_still_empld_12_months_after_their_return_to_work",
        subMeasureField: "total_number_emp_returned",
        dimensionField: "total_number",
      }),

      // Humancapital_401_3_5_e
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Return to work rates of employees that took parental leave - Female",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "rate_of_return_work_emp_took_parental_leave_female",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Return to work rates of employees that took parental leave - Male",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "rate_of_return_work_emp_took_parental_leave_male",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Retention rates of employees that took parental leave - Female",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "rate_of_retention_emp_that_took_parental_leave_female",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Retention rates of employees that took parental leave - Male",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "rate_of_retention_emp_that_took_parental_leave_male",
      }),

      // Humancapital_403_8_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_emp_workr_not_emp_but_work_andor_wrkplce_cntrld_by_org",
        subMeasureField: "number_measure",
        dimensionField: "number_dimension",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_emp_workers_not_emp_but_whose_work_and_or_workplace_controlled_by_org",
      }),

      // Humancapital_403_8_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization, who are covered by such a system that has been internally audited",
        chartYCustom: "Count",
        measureField: "Gri_" + "number_of_non_employee_internal_audit",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization, who are covered by such a system that has been internally audited",
        chartYCustom: "Count",
        measureField: "Gri_" + "percentage_of_non_employee_internal_audit",
      }),

      // Humancapital_403_8_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization, who are covered by such a system that has been audited or certified by an external party",
        chartYCustom: "Count",
        measureField: "Gri_" + "number_of_non_employee_external_audit",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization, who are covered by such a system that has been audited or certified by an external party",
        chartYCustom: "Count",
        measureField: "Gri_" + "percentage_of_non_employee_external_audit",
      }),

      // Humancapital_403_9_2_v
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "The number of hours worked by workers",
        chartYCustom: "Count",
        measureField: "Gri_" + "Number_of_hours_worked_by_wrkr",
        subMeasureField: "number_hours_worked_wrkr",
        dimensionField: "total_number",
      }),

      // Humancapital_404_1_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Average hours of training that the organization’s employees have undertaken during the reporting period - Female",
        chartYCustom: "Count",
        measureField: "Gri_" + "avg_work_hours_by_gender_female",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Average hours of training that the organization’s employees have undertaken during the reporting period - Male",
        chartYCustom: "Count",
        measureField: "Gri_" + "avg_work_hours_by_gender_male",
      }),

      // Humancapital_404_1_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Average hours of training that the organization’s employees have undertaken during the reporting period - Board Member",
        chartYCustom: "Count",
        measureField: "Gri_" + "avg_work_hours_by_categroy_board_member",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Average hours of training that the organization’s employees have undertaken during the reporting period - Employee",
        chartYCustom: "Count",
        measureField: "Gri_" + "avg_work_hours_by_categroy_employee",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Average hours of training that the organization’s employees have undertaken during the reporting period - Key Management Personnel",
        chartYCustom: "Count",
        measureField: "Gri_" + "avg_work_hours_by_categroy_kmp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Average hours of training that the organization’s employees have undertaken during the reporting period - Workers",
        chartYCustom: "Count",
        measureField: "Gri_" + "avg_work_hours_by_categroy_workers",
      }),

      // Humancapital_404_3_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Percentage of total employees who received a regular performance and career development review during the reporting period",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "percentage_emp_rcvd_regular_performance_career_development_review_during_reporting_period",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of employees who received a regular performance and career development review during the reporting period",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "number_emp_rcvd_regular_performance_career_development_review_during_reporting_period",
      }),

      // Humancapital_405_1_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of male individuals within the organization’s governance bodies",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "number_of_male_indvdls_within_orgns_gvrnce_bodies",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of female individuals within the organization’s governance bodies",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "number_of_female_indvdls_within_orgns_gvrnce_bodies",
      }),

      // Humancapital_405_1_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of individuals within the organization’s governance bodies under 30 years old",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "number_indvdls_within_orgns_gvrnce_bodies_under_30_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of individuals within the organization’s governance bodies between 30-50 years old",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "number_indvdls_within_orgns_gvrnce_bodies_btwn_3050_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of individuals within the organization’s governance bodies over 50 years old",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "number_indvdls_within_orgns_gvrnce_bodies_over_50_years_old",
      }),

      // Humancapital_405_1_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of male employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_male_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of femal employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_female_emp",
      }),

      // Humancapital_405_1_2_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of employees under 30 years old",
        chartYCustom: "Count",
        measureField: "Gri_" + "number_of_emps_under_30_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of employees between 30-50 years old",
        chartYCustom: "Count",
        measureField: "Gri_" + "number_of_emps_btwn_3050_years_old",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of employees over 50 years old",
        chartYCustom: "Count",
        measureField: "Gri_" + "number_of_emps_over_50_years_old",
      }),

      // Humancapital_405_2_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Ratio of the basic salary of women to men for each Employee",
        chartYCustom: "Count",
        measureField: "Gri_" + "employee_basic_salary_ratio_female_to_male",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Ratio of the remuneration of women to men for each Employee",
        chartYCustom: "Count",
        measureField: "Gri_" + "employee_remuneration_ratio_female_to_male",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Ratio of the basic salary of women to men for each Key Management Personnel",
        chartYCustom: "Count",
        measureField: "Gri_" + "kmp_basic_salary_ratio_female_to_male",
        subMeasureField: "key_measure",
        dimensionField: "key_dimension",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Ratio of the remuneration of women to men for each Key Management Personnel",
        chartYCustom: "Count",
        measureField: "Gri_" + "kmp_remuneration_ratio_female_to_male",
        subMeasureField: "key_measure",
        dimensionField: "key_dimension",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Ratio of the basic salary of women to men for each Workers",
        chartYCustom: "Count",
        measureField: "Gri_" + "workers_basic_salary_ratio_female_to_male",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Ratio of the remuneration of women to men for each Workers",
        chartYCustom: "Count",
        measureField: "Gri_" + "workers_remuneration_ratio_female_to_male",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Ratio of the basic salary of women to men for each Board Member",
        chartYCustom: "Count",
        measureField: "Gri_" + "board_member_basic_salary_ratio_female_to_male",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Ratio of the remuneration of women to men for each Board Member",
        chartYCustom: "Count",
        measureField: "Gri_" + "board_member_remuneration_ratio_female_to_male",
      }),

      // General_Discloser_2_7_1
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of male employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_male_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of female employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_female_emp",
      }),

      // General_Discloser_2_7_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of permanent employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_permanent_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of permanent employees - male",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_permanent_male_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of permanent employees - female",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_permanent_female_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of permanent employees - south region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_pemanent_emp_south_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of permanent employees - north region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_pemanent_emp_north_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of permanent employees - west region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_pemanent_emp_west_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of permanent employees - east region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_pemanent_emp_east_region",
      }),

      // General_Discloser_2_7_2_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of temporary employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_temporary_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of temporary employees - male",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_temporary_male_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of temporary employees - female",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_temporary_female_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of temporary employees - south region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_temporary_emp_south_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of temporary employees - north region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_temporary_emp_north_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of temporary employees - west region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_temporary_emp_west_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of temporary employees - east region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_temporary_emp_east_region",
      }),

      // General_Discloser_2_7_2_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of non_guaranteed_hours employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_non_guaranteed_hours_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of non_guaranteed_hours employees - male",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_non_guaranteed_hours_male_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of non_guaranteed_hours employees - female",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_non_guaranteed_hours_female_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of non_guaranteed_hours employees - south region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_non_guaranteed_hours_emp_south_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of non_guaranteed_hours employees - north region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_non_guaranteed_hours_emp_north_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of non_guaranteed_hours employees - west region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_non_guaranteed_hours_emp_west_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of non_guaranteed_hours employees - east region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_non_guaranteed_hours_emp_east_region",
      }),

      // General_Discloser_2_7_2_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of full_time employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_full_time_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of full_time employees - male",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_full_time_male_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of full_time employees - female",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_full_time_female_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of full_time employees - south region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_full_time_emp_south_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of full_time employees - north region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_full_time_emp_north_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of full_time employees - west region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_full_time_emp_west_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of full_time employees - east region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_full_time_emp_east_region",
      }),

      // General_Discloser_2_7_2_v
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of part_time employees",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_part_time_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of part_time employees - male",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_part_time_male_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of part_time employees - female",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_part_time_female_emp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of part_time employees - south region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_part_time_emp_south_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of part_time employees - north region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_part_time_emp_north_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of part_time employees - west region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_part_time_emp_west_region",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of part_time employees - east region",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_part_time_emp_east_region",
      }),

      // General_Discloser_2_8_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of workers",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_number_of_workers",
        subMeasureField: "total_worker",
        dimensionField: "total_number_worker",
      }),
    ],

    // ----------------------------------------

    "Economic Performance": [
      // Businessmodel_201_1_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Revenues of the company",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_revenues_of_company",
        subMeasureField: "total_revenues",
        dimensionField: "total_number",
      }),

      // Businessmodel_201_1_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Expenses incurred by the company",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_expenses_incurred_by_company",
        subMeasureField: "total_expenses",
        dimensionField: "total_number",
      }),

      // Businessmodel_201_1_1_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total Retained value of the company.",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_retained_value_of_company",
        subMeasureField: "total_retained",
        dimensionField: "total_number",
      }),

      // General_Discloser_2_21_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Ratio of the annual total compensation for the organization’s highest-paid individual to the median annual total compensation for all employees",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "ratio_highest_paid_indivi_annual_compen_to_median_annual_compen",
      }),

      // General_Discloser_2_21_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Ratio of the percentage increase in annual total compensation for the organization’s highest-paid individual to the median percentage increase in annual total compensation for all employees",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "ratio_highest_paid_percentage_increas_to_median_percentage_increase",
      }),

      // General_Discloser_2_28_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of industry associations, other membership associations, and national or international advocacy organizations in which the business participates in a significant role",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "number_industry_business_participates_in_significant_role",
      }),
    ],

    // ----------------------------------------

    Incidents: [
      // Humancapital_406_1_1_a
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Total number of incidents of discrimination",
        chartYCustom: "Count",
        measureField: "Gri_" + "Total_number_incidents_discrimination",
        subMeasureField: "total_incidents_discrimination",
        dimensionField: "total_number",
      }),

      // Humancapital_406_1_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of discrimination reviewed by the organization",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "Total_number_incidents_discrimination_revwd_by_org",
        subMeasureField: "total_measure",
        dimensionField: "total_number",
      }),

      // Humancapital_406_1_2_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of discrimination where Remediation plans being implemented",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_incidents_discrimination_remediation_plans_implmnted",
        subMeasureField: "total_measure",
        dimensionField: "total_number",
      }),

      // Humancapital_406_1_2_iii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of discrimination where Remediation plans that have been implemented, with results reviewed through routine internal management review processes",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_incidents_discrimination_remediation_plans_implmntd_with_results_revwd_internal_management",
        subMeasureField: "total_measure",
        dimensionField: "total_number",
      }),
      // Humancapital_406_1_2_iv
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of incidents of discrimination that are no longer subject to action.",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_incidents_discrimination_no_longer_subject_to_action",
        subMeasureField: "total_measure",
        dimensionField: "total_number",
      }),

      // Humancapital_407_1_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of operations in which workers’ rights to exercise freedom of association or collective bargaining may be violated or at significant risk",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_oprtn_wrkr_rights_excis_freedom_asstion_clctv_brgning_violated_or_significant_risk",
        subMeasureField: "number_oprtn_wrkr",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of suppliers in which workers’ rights to exercise freedom of association or collective bargaining may be violated or at significant risk",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_spliers_wrkr_rights_excis_freedom_asstion_clctv_brgning_violated_or_significant_risk",
        subMeasureField: "number_spliers_wrkr",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Operations considered to have significant risk for incidents of forced or compulsory labor",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_oprtn_cncider_significant_risk_for_incidents_forced_compulsory_labor",
        subMeasureField: "number_oprtn_cncider",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of suppliers considered to have significant risk for incidents of forced or compulsory labor",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_spliers_cncider_significant_risk_for_incidents_forced_compulsory_labor",
        subMeasureField: "number_spliers_cncider",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Operations considered to have significant risk for incidents of child Labor",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_oprtn_cncider_significant_risk_for_incidents_child_labor",
        subMeasureField: "number_oprtn_cncider",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Suppliers considered to have significant risk for incidents of child Labor",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_spliers_cncider_significant_risk_for_incidents_child_labor",
        subMeasureField: "number_spliers_cncider",
        dimensionField: "total_number",
      }),

      // Humancapital_408_1_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Operations considered to have significant risk for incidents of young workers exposed to hazardous work",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_oprtn_cncider_significant_risk_for_incidents_young_wrker_expsd_hazardous_work",
        subMeasureField: "number_oprtn_cncider",
        dimensionField: "total_number",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Number of Suppliers considered to have significant risk for incidents of young workers exposed to hazardous work",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Number_of_spliers_cncider_significant_risk_for_incidents_young_wrker_expsd_hazardous_work",
        subMeasureField: "number_spliers_cncider",
        dimensionField: "total_number",
      }),
    ],

    // -------------------------------------------

    // ---------------Business Model & Innovation---------------
    "Product specification & clean fuel blends": [
      // General_Discloser_2_6_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Markets served by the company",
        chartYCustom: "Count",
        measureField: "Gri_" + "Number_markets_served_company",
        subMeasureField: "number_of_markets",
        dimensionField: "total_number",
      }),

      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of products & services of the company",
        chartYCustom: "Count",
        measureField: "Gri_" + "Number_of_product_and_services",
        subMeasureField: "pord_and_serv",
        dimensionField: "total_number",
      }),
    ],
    // -------------------------------------------

    // ---------------Leadership & Governance---------------
    "Legal Summary": [
      // General_Disclosure_2_16_2_b
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of critical concerns communicated to the highest governance body",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "total_critical_concerns_highest_governance_body",
      }),

      // General_Disclosure_2_27_1_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of significant instances of non-compliance with laws and regulations",
        chartYCustom: "Count",
        measureField: "Gri_" + "total_nc_with_laws_regulations",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of significant instances of non-compliance with laws and regulations for which fines were incurred",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "total_nc_with_laws_and_regulations_fines_were_incurred",
      }),

      // General_Disclosure_2_27_1_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of significant instances of non-compliance with laws and regulations for which non-monetary sanctions were incurred",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "Total_number_of_nc_laws_regulations_nonmonetary_sanctions_incurred",
        subMeasureField: "total_number_nc_laws_regulations_nonmonetary",
        dimensionField: "total_number",
      }),

      // General_Disclosure_2_27_2_i
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of significant instances of non-compliance with laws and regulations for which fines were incurred (current period)",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "total_nc_laws_regulations_fines_were_incurred_cp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total fines incurred for non-compliance with laws and regulations cp",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_fines_incurred_noncompliance_with_laws_and_regulations_cp",
      }),

      // General_Disclosure_2_27_2_ii
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total number of significant instances of non-compliance with laws and regulations for which fines were incurred(previous period)",
        chartYCustom: "Count",
        measureField:
          "Gri_" + "total_nc_laws_regulations_fines_were_incurred_pp",
      }),
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName:
          "Total fines incurred for non-compliance with laws and regulations pp",
        chartYCustom: "Count",
        measureField:
          "Gri_" +
          "total_fines_incurred_noncompliance_with_laws_and_regulations_pp",
      }),
    ],

    // -------------------------------------------

    "Geographical Presence": [
      // General_Discloser_2_1_4_d
      createCubeJSSchema({
        CURRENT_ORGANIZATION: CURRENT_ORGANIZATION,
        kpiName: "Number of Countries of operation.",
        chartYCustom: "Count",
        measureField: "Gri_" + "Number_of_country_operation",
        subMeasureField: "country_opr",
        dimensionField: "total_country_opr",
      }),
    ],

    // -------------------------------------------
  };

  return KPI_DATA;
};

export default handleKpiDataWithOrganisation;
