import React from "react";
import Card from "@material-ui/core/Card";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core";

export default function ReportSkeleton() {
  const useStyles = makeStyles(() => ({
    Card: {
      padding: 15,
    },
    mainDiv1: { display: "flex", justifyContent: "space-between" },
    mainDiv2: { marginTop: 35 },
    Skeleton1: { marginRight: 15, marginTop: 10 },
  }));
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.Card}>
      <div className={classes.mainDiv1}>
        <Skeleton width="50%" height="50px" animation="wave" />
        <Skeleton variant="circle" animation="wave" width={140} height={140} />
      </div>
      <div className={classes.mainDiv2}>
        {[1, 2].map((item) => (
          <Skeleton
            key={item}
            className={classes.Skeleton1}
            width="70%"
            height="35px"
            animation="wave"
          />
        ))}
      </div>
      <Skeleton width="500px" height="300px" animation="wave" />
      <div>
        {[1, 2].map((item) => (
          <Skeleton
            key={item}
            className={classes.Skeleton1}
            width="70%"
            height="35px"
            animation="wave"
          />
        ))}
      </div>
    </Card>
  );
}
