export const SET_CORPORATES = "SET_CORPORATES";
export const SET_TOTAL_CORPORATES = "SET_TOTAL_CORPORATES";
export const SET_CORPORATES_SHARE_SURVEY = "SET_CORPORATES_SHARE_SURVEY";
export const SET_SELECTED_ASSIGN_SURVEY = "SET_SELECTED_ASSIGN_SURVEY";
export const SET_QUESTION_WITH_STATUS = "SET_QUESTION_WITH_STATUS";
export const SET_SELECTED_ADMIN_SECTION = "SET_SELECTED_ADMIN_SECTION";
export const SET_APPROVED_ANSWER_DATA = "SET_APPROVED_ANSWER_DATA";
export const SET_AUDIT_SECTION_DETAILS = "SET_AUDIT_SECTION_DETAILS";
export const SET_AUDIT_SELECTED_SECTION = "SET_AUDIT_SELECTED_SECTION";
export const SET_AUDIT_QUESTION_DETAILS = "SET_AUDIT_QUESTION_DETAILS";
export const SET_ESG_RATING = "SET_ESG_RATING";

export const SET_SELECTED_CORPORATE = "SET_SELECTED_CORPORATE";

export const SET_ISIN_DATA = "SET_ISIN_DATA";
