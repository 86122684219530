import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CustomTextfield from "../../UI/textfield/textfield";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const useStyles = makeStyles({
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  grid: { paddingTop: 20 },
  CustomTextfield: { width: "100%" },
  Box1: {
    borderBottom: "2px solid gray",
    cursor: "pointer",
  },
  Box2: { borderBottom: "2px solid #3374B9" },
  pointer: { cursor: "pointer" },
});

export default function ReportTypeThree(props) {
  const {
    surveyData,
    surveyResultData,
    fieldData,
    metricId,
    index,
    currentDetailedMetric,
  } = props;
  // console.log("currentDetailedMetric 22", currentDetailedMetric);
  const classes = useStyles();
  const [typeThreeData, setTypeThreeData] = useState([]);

  const [metricFile, setMetricFile] = useState(null);
  const [metricComment, setMetricComment] = useState("");

  useEffect(() => {
    if (currentDetailedMetric.metrics_data.hasOwnProperty("comment")) {
      setMetricComment(currentDetailedMetric.metrics_data.comment);
    }

    if (
      currentDetailedMetric.metrics_data.hasOwnProperty("file") &&
      currentDetailedMetric.metrics_data.file !== ""
    ) {
      setMetricFile(currentDetailedMetric.metrics_data.file);
    }
  }, [currentDetailedMetric]);

  useEffect(() => {
    return () => {
      setMetricFile(null);
    };
  }, [fieldData.currentMetric]);

  useEffect(() => {
    if (surveyResultData) {
      setTypeThreeData(surveyResultData);
    } else {
      const data = surveyData.rows.map((r, idx) => {
        return {
          row: r,
          columns:
            surveyData.columns &&
            surveyData.columns.map((c) => {
              return {
                column: c,
                data: "",
              };
            }),
        };
      });
      setTypeThreeData(data);
    }
  }, [surveyResultData, fieldData.currentMetric, surveyData]);

  return (
    <>
      <Grid container justifyContent="space-evenly" className={classes.grid}>
        <Grid item xs={12}>
          <Paper>
            {typeThreeData.length > 0 && (
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ minWidth: 150 }}
                      ></TableCell>
                      {typeThreeData.length > 0 &&
                        typeThreeData[0].columns &&
                        typeThreeData[0].columns.map((column, index) => (
                          <TableCell
                            key={index}
                            align="center"
                            style={{ minWidth: 150 }}
                          >
                            {column.column}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {typeThreeData.length > 0 &&
                      typeThreeData.map((row, rowIndex) => {
                        return (
                          <TableRow key={rowIndex} hover role="checkbox">
                            <TableCell align="center">
                              <Typography>{row.row}</Typography>
                            </TableCell>
                            {row.columns &&
                              row.columns.map((col, colIndex) => {
                                return (
                                  <TableCell key={colIndex} align="center">
                                    <CustomTextfield
                                      name={`Text_type3${colIndex}`}
                                      size="small"
                                      variant="outlined"
                                      className={classes.CustomTextfield}
                                      value={
                                        typeThreeData[rowIndex].columns[
                                          colIndex
                                        ].data
                                      }
                                      disabled
                                    />
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Box>
        <Box my={2}>
          <Typography className={classes.blueText}>Comments</Typography>
          <CustomTextfield
            name={`Comment_type3${metricId}`}
            label="Additional Comment"
            fullWidth
            multiline
            minRows={3}
            size="small"
            variant="outlined"
            value={metricComment}
            disabled
          />
        </Box>
        <Box my={2}>
          {metricFile === null ? (
            <>
              <input
                id={metricId}
                type="file"
                index={index}
                disabled
                style={{ display: "none" }}
              />
              <label htmlFor={metricId}>
                <Box display="flex" component="span" alignItems="center">
                  <AttachFileIcon color="disabled" />
                  <Box mx={2} className={classes.Box1} width="100%">
                    <Typography variant="caption" color="textSecondary">
                      Upload document
                    </Typography>
                  </Box>
                </Box>
              </label>
            </>
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <AttachFileIcon color="disabled" />
                <Box mx={2} className={classes.Box2} width="100%">
                  <Typography variant="caption" color="primary">
                    {metricFile.name}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
