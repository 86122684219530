import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CustomButton from "../../../UI/button/button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import "../../../../styles/hidescroll.css";
import ClearIcon from "@material-ui/icons/Clear";
import ESGReportSkeleton from "../../skeletons/esgReportSkeleton";
import Radio from "@material-ui/core/Radio";
import SurveySuccess from "../../../../images/SurveySuccess.svg";

const useStyles = makeStyles((theme) => ({
  centerLeft: {
    display: "flex",
    marginLeft: 10,
    alignItems: "center",
  },
  divider: {
    border: "1px solid #F5F6F8",
  },
  assest: {
    marginLeft: "16px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  line: {
    border: "1px solid black",
    width: "15px",
    height: "0px",
  },
  circle: {
    marginLeft: "-40px",
    width: "7px",
    height: "7px",
    borderRadius: "50%",
    backgroundColor: "#3374B9",
  },
}));

const topicRelevanceData = [
  {
    id: 5,
    name: "Very Important",
  },
  {
    id: 4,
    name: "Somewhat Important",
  },
  {
    id: 3,
    name: "Neutral",
  },
  {
    id: 2,
    name: "Not Very Important",
  },
  {
    id: 1,
    name: "Not At All Important",
  },
];

const topicImpactData = [
  {
    id: 1,
    name: "No significant Impact",
  },
  {
    id: 2,
    name: "Minor Impact",
  },
  {
    id: 3,
    name: "Moderate Impact",
  },
  {
    id: 4,
    name: "Major Impact",
  },
  {
    id: 5,
    name: "Catastrophic Impact",
  },
];

export default function AssetManagement(props) {
  const inputRef = useRef(null);
  let scrollRef = useRef(null);
  const classes = useStyles();

  const {
    assessment_id,
    materialityAssessment,
    setRisks,
    setOpportunity,
    setFile,
    setWeightage,
    login,
    setRelevance,
    setMetrics,
    upsertAssessmentTopic,
    setCurrentTopicId,
    getMaterialityAssessmentTask,
    setIsSurveyCompleted,
    setTopicCompletedUpto,
    removeFile,
    framework_id,
  } = props;

  const [currentTopic, setCurrentTopic] = useState(null);

  useEffect(() => {
    setCurrentTopic(
      materialityAssessment.assignedTopics.find(
        (data) => data?.topic_id === materialityAssessment.currentTopicId
      )
    );
    !materialityAssessment.isSurveyCompleted && scrollRef.current.focus();
  }, [materialityAssessment.currentTopicId]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    return setMetrics([]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // proceed and privous button function

  const topic = materialityAssessment?.assignedTopics?.find(
    (data) => data?.topic_id === materialityAssessment?.currentTopicId
  );

  const id = materialityAssessment?.assignedTopics?.indexOf(topic);

  const proceed =
    materialityAssessment?.assignedTopics?.length - 1 === id ? id : id + 1;

  const previous = id === 0 ? id : id - 1;

  function handleProceed() {
    setCurrentTopicId(materialityAssessment?.assignedTopics[proceed]?.topic_id);
    upsertAssessmentTopic(
      login.token,
      assessment_id,
      materialityAssessment.currentTopicId,
      false,
      materialityAssessment.relevance,
      materialityAssessment.weightage,
      {
        risk: materialityAssessment.risks,
        opportunity: materialityAssessment.opportunity,
      },
      materialityAssessment.file,
      currentTopic.topic_name,
      materialityAssessment,
      framework_id,
      materialityAssessment?.assignedTopics[proceed]?.topic_id
    );
    if (materialityAssessment?.assignedTopics?.length - 1 === id) {
      setIsSurveyCompleted(true);
      setCurrentTopicId(null);
    } else {
      //   setTimeout(() => {
      // getMaterialityAssessmentTask(
      //   login.token,
      //   assessment_id,
      //   materialityAssessment.assignedTopics[proceed].topic_id
      // );
      //   }, 500);
    }

    window.scrollTo(0, 0);
  }

  function handlePrivous() {
    setCurrentTopicId(
      materialityAssessment?.assignedTopics[previous]?.topic_id
    );
    getMaterialityAssessmentTask(
      login.token,
      assessment_id,
      materialityAssessment?.assignedTopics[previous]?.topic_id
    );
  }

  const handleRedoSurvey = () => {
    setCurrentTopicId(materialityAssessment?.assignedTopics[0]?.topic_id);
    getMaterialityAssessmentTask(
      login.token,
      assessment_id,
      materialityAssessment?.assignedTopics[0]?.topic_id
    );
    setIsSurveyCompleted(false);
  };
  return materialityAssessment?.metrics?.length === 0 ? (
    <div>
      <Card
        variant="outlined"
        id="drawer-container"
        style={{
          backgroundColor: "#FFFFFF",
          marginBottom: "15px",
          position: "relative",
        }}
      >
        <CardContent>
          {!materialityAssessment.isSurveyCompleted ? (
            <>
              {/* Title, Chip and Icon */}
              <Grid container style={{ marginBottom: "0px" }}>
                <Grid item md={10} sm={10} xs={10}>
                  <Typography variant="h6">
                    {currentTopic && currentTopic.topic_name}
                    {/* {materialityAssessment.topicName} */}
                  </Typography>
                </Grid>
              </Grid>
              <div
                className="scroll"
                style={{ height: "80vh", paddingBottom: 30 }}
              >
                {/* Impact on business - drop down */}
                <div tabIndex="0" ref={scrollRef}></div>
                <Grid container style={{ marginTop: "25px" }}>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    {currentTopic && (
                      <Typography variant="h6">
                        What's the impact of {currentTopic.topic_name} on{" "}
                        {currentTopic.parent_topic_name} ?
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid container spacing={1}>
                          {topicImpactData.map((topic, index) => {
                            const { id, name } = topic;

                            return (
                              <Grid container item xs={12} key={index}>
                                <Grid item xs={2} md={1}>
                                  <Radio
                                    name={name.substring(0, 4)}
                                    style={{ color: "#3374B9" }} //for radio only
                                    value={id}
                                    checked={
                                      materialityAssessment.weightage === id
                                    }
                                    onChange={(e) =>
                                      setWeightage(parseInt(e.target.value))
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={9}
                                  md={10}
                                  className={classes.centerLeft}
                                >
                                  <Typography>{name}</Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                          <Grid item xs={12}>
                            <div className={classes.divider}></div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                {/* Topic Importance Checkboxs */}
                <Grid container style={{ marginTop: "25px" }}>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    {currentTopic && (
                      <Typography variant="h6">
                        How material is {currentTopic.topic_name} to the company
                        ?
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid container spacing={1}>
                          {topicRelevanceData
                            .map((topic, index) => {
                              const { id, name } = topic;

                              return (
                                <Grid container item xs={12} key={index}>
                                  <Grid item xs={2} md={1}>
                                    <Radio
                                      name={name.substring(0, 4)}
                                      style={{ color: "#3374B9" }} //for radio only
                                      value={id}
                                      checked={
                                        materialityAssessment.relevance === id
                                      }
                                      onChange={(e) =>
                                        setRelevance(parseInt(e.target.value))
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={9}
                                    md={10}
                                    className={classes.centerLeft}
                                  >
                                    <Typography>{name}</Typography>
                                  </Grid>
                                </Grid>
                              );
                            })
                            .reverse()}
                          <Grid item xs={12}>
                            <div className={classes.divider}></div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                {/* Risk and Opportunity */}
                <Grid container spacing={2} style={{ marginTop: "25px" }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Risks to the topic</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="riskText"
                      id="standard-multiline-static"
                      multiline
                      minRows={2}
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={materialityAssessment.risks}
                      onChange={(event) => setRisks(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Opportunity to the topic
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="oppText"
                      multiline
                      minRows={2}
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={materialityAssessment.opportunity}
                      onChange={(event) => setOpportunity(event.target.value)}
                    />
                  </Grid>
                </Grid>

                {/* File Upload and upsert */}
                <Grid
                  container
                  style={{ marginTop: "25px", marginBottom: "15px" }}
                >
                  <Grid item xs={12}>
                    {materialityAssessment.file === null &&
                    typeof materialityAssessment.file !== typeof "" ? (
                      <Box
                        width="70%"
                        style={{
                          margin: "20px 0px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          inputRef.current?.click();
                        }}
                      >
                        <AttachFileIcon
                          style={{ backgroundColor: "#ced4da", padding: 4 }}
                          color="disabled"
                        />
                        <div
                          style={{
                            borderBottom: "2px solid #ced4da",
                            marginLeft: 20,
                            width: "100%",
                          }}
                        >
                          Upload file
                        </div>
                        <input
                          name="fileUpload"
                          className={classes.inputCheck}
                          ref={inputRef}
                          type="file"
                          onChange={(e) => setFile(e.target.files[0])}
                          style={{ opacity: 0, width: 0 }}
                        />
                      </Box>
                    ) : (
                      <Box
                        width="70%"
                        style={{
                          margin: "20px 0px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <AttachFileIcon
                          style={{ backgroundColor: "#adb5bd", padding: 4 }}
                          color="primary"
                          onClick={() => {
                            inputRef.current?.click();
                          }}
                        />
                        <div
                          style={{
                            borderBottom: "2px solid #3374B9",
                            marginLeft: 20,
                            width: "100%",
                          }}
                        >
                          {materialityAssessment.file.length > 0 &&
                          materialityAssessment.file[0] !== ""
                            ? "file Uploaded"
                            : materialityAssessment?.file?.name}
                        </div>
                        <ClearIcon
                          style={{ cursor: "pointer" }}
                          color="primary"
                          onClick={() => {
                            removeFile(
                              login.token,
                              assessment_id,
                              2,
                              materialityAssessment?.file?.url,
                              "file",
                              currentTopic?.assessment_topic_id
                            );
                            setFile(null);
                          }}
                        />
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} style={{ paddingBottom: 50 }}>
                    <Box width="fit-content" ml="auto">
                      <CustomButton
                        color="primary"
                        size="large"
                        variant="contained"
                        disabled={props.role === 1}
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          handlePrivous();
                          scrollRef.current.focus();
                        }}
                      >
                        Previous
                      </CustomButton>

                      <CustomButton
                        color="primary"
                        size="large"
                        variant="contained"
                        disabled={props.role === 1}
                        onClick={() => {
                          if (
                            typeof materialityAssessment.relevance !==
                              "string" &&
                            typeof materialityAssessment.weightage !== "string"
                          ) {
                            handleProceed();
                          }
                          scrollRef.current.focus();
                        }}
                      >
                        Submit and Proceed
                      </CustomButton>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </>
          ) : (
            <div
              className="scroll"
              style={{ height: "80vh", paddingBottom: 30 }}
            >
              <Grid container style={{ marginBottom: "0px" }}>
                <Grid item md={10} sm={10} xs={10}>
                  <Typography variant="h6">Survey Completed</Typography>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "100px",
                }}
              >
                <img
                  style={{ maxWidth: "400px", maxHeight: "400px" }}
                  src={SurveySuccess}
                  alt="survey_success_vector"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "30px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#0F0F0F",
                      paddingBottom: "13px",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "500",
                      letterSpacing: "0.0015rem",
                      textTransform: "capitalize",
                    }}
                  >
                    The survey has been Saved.
                  </Typography>
                  <Typography
                    style={{
                      color: "#0F0F0F",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "400",
                      letterSpacing: "0.0025em",
                      textTransform: "capitalize",
                    }}
                  >
                    If you wish to redo the survey please use the redo button.
                    Or go back to the task section.
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingTop: "30px",
                }}
              >
                <CustomButton
                  style={{ textTransform: "none", fontFamily: "Poppins" }}
                  variant="contained"
                  color="primary"
                  onClick={handleRedoSurvey}
                >
                  Redo Survey
                </CustomButton>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  ) : (
    <Card
      variant="outlined"
      style={{
        backgroundColor: "#FFFFFF",
        height: "80vh",
      }}
    >
      <ESGReportSkeleton />
    </Card>
  );
}
