import Box from "@material-ui/core/Box";

// import { controversyChartData, defaultColors } from "./chartUtils/chartData";
import { useSelector } from "react-redux";

import BarChart from "./chartComponents/BarChart";

const ControversyChart = () => {
  const { controInsights } = useSelector((state) => state.esgStrategy);

  return (
    <Box sx={{ height: "300px", width: "100%" }}>
      <BarChart data={controInsights?.final_dates} />
    </Box>
  );
};
export default ControversyChart;
