import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  line: {
    width: 20,
    height: 0,
    borderBottom: "2px solid #242424",
    borderLeft: "2px solid #242424",
    [theme.breakpoints.down("sm")]: {
      height: 110,
    },
  },
  circle: {
    width: 7,
    height: 7,
    borderRadius: "50%",
    backgroundColor: "#3374B9",
    boxShadow: "0px 0px 0px 3px rgba(51, 116, 185, 0.1)",
    marginLeft: 20,
    marginTop: -4,
  },
  Card:{ width: 300 },
  CardContent:{
    padding: "0px",
    paddingBottom: "25px",
  },
  mainGrid:{
    backgroundColor: "#F0F6FD",
    height: "55px",
    display: "flex",
    alignItems: "center",
  },
  subGrid:{ paddingLeft: "15px" },
  FiberManualRecordIcon:{ fill: "#FFD064" },
 typo:{ marginLeft: 5 },
 mainGrid1:{ borderLeft: "2px solid #242424", marginLeft: 32 },
 grid1:{ paddingTop: "15px" },
 grid2:{ paddingTop: 35 },
 grid3:{ marginLeft: "-15px", paddingRight: "35px" },
 typo1:{ color: "#77787B" },
 typo2:{ marginTop: 25, marginLeft: 25 }
}));

export default function ActivityLog({ logs, name }) {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={11} md={10}>
          <Card variant="outlined"className={classes.Card}>
            <CardContent
            className={classes.CardContent}
            >
              <Grid
                container
                className={classes.mainGrid}
              >
                <Grid item xs={3} sm={2} className={classes.subGrid} >
                  <FiberManualRecordIcon
                    fontSize="large"
                    className={classes.FiberManualRecordIcon}
                  />
                </Grid>
                <Grid item xs={9} sm={7}>
                  <Typography variant="body2" className={classes.typo} >
                    {name && name.charAt(0).toUpperCase() + name.slice(1)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.mainGrid1}
              >
                {logs.map((item, index) => {
                  const { activity, created_on } = item;
                  return (
                    <Grid key={index} container className={classes.grid1}>
                      <Grid item xs={3} sm={2} className={classes.grid2}>
                        <div className={classes.line}></div>
                        <div className={classes.circle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        sm={10}
                        className={classes.grid3}
                      >
                        <Typography variant="body1">
                          Updated: {activity}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.typo1}
                        >
                          {moment(created_on).format("DD/MM/YYYY, HH:MM A")}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              {logs.length === 0 && (
                <Typography className={classes.typo1} >
                  No Activity
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
