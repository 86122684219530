import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Box from "@material-ui/core/Box";
import PlaceHolder from "../../../images/File.svg";
import {
  Business,
  Environmental,
  Governance,
  Human,
  Social,
} from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import esgLeftStyles from "./esgLeftStyle";

export default function ESGPlanLeft(props) {
  const { login, esgPlan, getTopicDetails, setCurrentTopic, setTopicState } =
    props;

  const classes = esgLeftStyles();

  const handleTopicClick = (subTopic) => {
    getTopicDetails(login.token, subTopic.assessment_topic_id, false);
    setCurrentTopic(subTopic);
    setTopicState(0);
  };

  const TopicCard = ({ topic }) => {
    const { topic_name, sub_topics } = topic;

    return (
      <div
        style={
          {
            // marginLeft: '16px',
            // marginRight: '16px',
            // marginBottom: '24px'
            // padding:10
          }
        }
      >
        <Card variant="elevation" className={classes.topicCardBoxShadow}>
          <CardContent style={{ padding: 0 }}>
            <div className={classes.mainTopicContainer}>
              {topic_name === "Social Capital" && <Social />}
              {topic_name === "Environment" && <Environmental />}
              {topic_name === "Leadership & Governance" && <Governance />}
              {topic_name === "Human Capital" && <Human />}
              {topic_name === "Business Model & Innovation" && <Business />}
              <Typography className={classes.mainTopicText}>
                {topic.topic_name}
              </Typography>
            </div>
            {topic_name === "Social Capital" && (
              <div className={classes.socialBorder}></div>
            )}
            {topic_name === "Environment" && (
              <div className={classes.environmentBorder}></div>
            )}
            {topic_name === "Leadership & Governance" && (
              <div className={classes.governanceBorder}></div>
            )}
            {topic_name === "Human Capital" && (
              <div className={classes.humanBorder}></div>
            )}
            {topic_name === "Business Model & Innovation" && (
              <div className={classes.businessBorder}></div>
            )}

            {sub_topics.map((subTopic, index) => (
              <div
                key={index}
                style={
                  esgPlan.currentTopic.topic_name === subTopic.topic_name
                    ? { backgroundColor: "rgb(189 213 239 / 52%)" }
                    : null
                }
                className={classes.topicContainer}
                onClick={() => handleTopicClick(subTopic)}
              >
                <AddBoxOutlinedIcon />

                <Typography
                  name={subTopic.topic_name.substring(0, 4)}
                  className={classes.topicText}
                >
                  {subTopic.topic_name}
                </Typography>
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
    );
  };

  useEffect(() => {
    return () => {
      setCurrentTopic("");
    };
  }, [esgPlan.selectedTopics]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card className={classes.cardContainer} data-tut="reactour__esgLeft">
        <CardContent className={classes.topicCardContent}>
          <Box style={{ padding: "10px 16px 8px 16px" }}>
            <Typography style={{ fontWeight: "600" }}>
              Topics selected
            </Typography>
          </Box>

          <div className="scroll">
            {esgPlan.selectedTopics.map((topic, i) => (
              <TopicCard key={`esgTopics` + i} topic={topic} />
            ))}
            {esgPlan.selectedTopics.length === 0 && (
              <Box className={classes.box}>
                <Typography className={classes.typography}>
                  No Data To Display
                </Typography>
                <img src={PlaceHolder} alt="" />
              </Box>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
}
