import { Box, Menu, MenuItem, Paper, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function StatusMenu(props) {
  const { metric, login, assessment_id, parent_id } = props;
  // console.log("mr=etric", metric);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMetricStatusChange, setCurrenyMetricStatusChange] =
    useState("");

  const handleClick = (event, metric) => {
    setAnchorEl(event.currentTarget);
    setCurrenyMetricStatusChange(metric);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    // console.log(currentMetricStatusChange);
    if (e.target.innerText) {
      if (e.target.innerText === "Completed")
        props.changeStatus(
          login.token,
          assessment_id,
          currentMetricStatusChange._id,
          "completed",
          parent_id,
          true
        );
      if (e.target.innerText === "Approved")
        props.changeStatus(
          login.token,
          assessment_id,
          currentMetricStatusChange._id,
          "approved",
          parent_id,
          true
        );
      if (e.target.innerText === "Awaiting Approval") {
        props.changeStatus(
          login.token,
          assessment_id,
          currentMetricStatusChange._id,
          "awaiting_approval",
          parent_id,
          true
        );
      }
    }
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        bgcolor={"#EBF6EF"}
        // p={1}
        style={{ width: "120px", padding: "5px" }}
        borderRadius="8px"
      >
        <Box
          borderRadius="50%"
          height={10}
          width={10}
          bgcolor={
            metric.omit
              ? "#E9E9E9"
              : metric.is_completed
              ? "#5BB37F"
              : metric.is_approved
              ? "#5BB37F"
              : metric.is_awaiting_approval
              ? "#EBD947"
              : "#EBD947"
          }
        />
        <Box
          style={{
            paddingLeft: "5px",
            fontSize: "10px",
          }}
        >
          {metric.is_completed
            ? "Completed"
            : metric.is_approved
            ? "Approved"
            : metric.is_awaiting_approval
            ? "Awaiting Approval"
            : " In progress"}
          {/* {metric.is_completed
                  ? `${dropDownValue}`
                  : metric.is_approved
                  ? `${dropDownValue}`
                  : metric.is_awaiting_approval
                  ? `${dropDownValue}`
                  : "In Progress"} */}
        </Box>
        {(metric.is_completed || metric.is_approved) && (
          <div style={{ width: metric.is_completed ? "28%" : "35%" }}>
            <ExpandMoreIcon
              focusable={true}
              style={{
                cursor: "pointer",
                width: "20px",
                height: "15px",
                float: "right",
              }}
              onClick={(e) => {
                (metric.is_completed || metric.is_approved) &&
                  handleClick(e, metric);
                // setSelectedMenuIndex(index);
              }}
            />
          </div>
        )}
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Approved</MenuItem>
        <MenuItem onClick={handleClose}>Completed</MenuItem>
        <MenuItem onClick={handleClose}>Awaiting Approval</MenuItem>
      </Menu>
    </div>
  );
}

export default StatusMenu;
