import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function PivotConfig({ availableMembers, updateMethods, setPivotConfiq, order, updateOrder }) {
 console.log("🚀 ~ file: pivotConfiq.js:35 ~ PivotConfig ~ availableMembers", availableMembers)
 
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [x, setLeft] = React.useState([0, 1, 2, 3]);
  const [y, setRight] = React.useState([4, 5, 6, 7]);

  React.useEffect(() => {
    typeof availableMembers !== "undefined" && setLeft(availableMembers.x);
    typeof availableMembers !== "undefined" && setRight(availableMembers.y);
  }, [availableMembers]);

  React.useEffect(() => {
    updateMethods.update({ ...availableMembers, x, y });
    setPivotConfiq({ ...availableMembers, x, y })
    
  }, [update]);

  const leftChecked = intersection(checked, x);
  const rightChecked = intersection(checked, y);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(y.concat(x));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(y.concat(leftChecked));
    setLeft(not(x, leftChecked));
    setChecked(not(checked, leftChecked));
    setUpdate((preValue) => !preValue);
  };

  const handleCheckedLeft = () => {
    setLeft(x.concat(rightChecked));
    setRight(not(y, rightChecked));
    setChecked(not(checked, rightChecked));
    setUpdate((preValue) => !preValue);
  };

  const handleAllLeft = () => {
    setLeft(x.concat(y));
    setRight([]);
  };

  const customList = (items) => (
    <Paper sx={{ width: 200, height: 230, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  // const customList = (items) => {
  //   const labelId = `transfer-list-item-${items}-label`;
  //   return (
  //     <Paper className={classes.paper}>
  //       <List dense component="div" role="list">
  //         <ListItem
  //           key={items}
  //           role="listitem"
  //           button
  //           onClick={handleToggle(items)}
  //         >
  //           <ListItemIcon>
  //             <Checkbox
  //               checked={checked.indexOf(items) !== -1}
  //               tabIndex={-1}
  //               disableRipple
  //               inputProps={{ "aria-labelledby": labelId }}
  //             />
  //           </ListItemIcon>
  //           <ListItemText id={items} primary={items} />
  //         </ListItem>

  //         <ListItem />
  //       </List>
  //     </Paper>
  //   );
  // };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList(x)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          {/* <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={x.length === 0}
            aria-label="move all y"
          >
            ≫
          </Button> */}
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected y"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected x"
          >
            &lt;
          </Button>
          {/* <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={y.length === 0}
            aria-label="move all x"
          >
            ≪
          </Button> */}
        </Grid>
      </Grid>
      <Grid item>{customList(y)}</Grid>
    </Grid>
  );
}
