import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { CustomDataInput } from "./SelectChartType";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "50%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function UpdateOrder({ order, updateOrder }) {
  const classes = useStyles();
  const [selectedOrder, setSelectedOrder] = React.useState("");
  const [selectedMeasure, setSelectedMeasure] = React.useState("");

  // updateOrder.update([[order[0]?.id, "asc"]]);

  const handleChangeDimension = (event) => {
    const value = event.target.value;
    setSelectedOrder(event.target.value);

    value !== "none"
      ? updateOrder.update([
          [
            order[0]?.id,
            selectedMeasure === "none" || selectedMeasure === ""
              ? "desc"
              : selectedMeasure,
          ],
          [order[1]?.id, value === "none" ? "asc" : value],
        ])
      : updateOrder.update([
          [
            order[0]?.id,
            selectedMeasure === "none" || selectedMeasure === ""
              ? "desc"
              : selectedMeasure,
          ]
        ]);
  };

  const handleChangeMeasures = (event) => {
    const value = event.target.value;

    setSelectedMeasure(event.target.value);
    value !== "none"
      ? updateOrder.update([
          [order[0]?.id, value === "none" ? "desc" : value],
          [
            order[1]?.id,
            selectedOrder === "none" || selectedOrder === ""
              ? "asc"
              : selectedOrder,
          ],
        ])
      : updateOrder.update([
          [
            order[1]?.id,
            selectedOrder === "none" || selectedOrder === ""
              ? "asc"
              : selectedOrder,
          ],
        ]);
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div style={{ display: "flex" }}>
          {/* <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Measures
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedMeasure}
              onChange={handleChangeMeasures}
              label="Select Order"
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"asc"}>Ascending Order</MenuItem>
              <MenuItem value={"desc"}>Descending Order</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl  style={{width:'100%'}}  variant="outlined" className={classes.formControl}>
            {/* <InputLabel id="demo-simple-select-outlined-label">
              Dimensions
            </InputLabel> */}
            <Select
              value={selectedOrder}
              onChange={handleChangeDimension}
              input={<CustomDataInput />}
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"asc"}>Ascending Order</MenuItem>
              <MenuItem value={"desc"}>Descending Order</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
