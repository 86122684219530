import { makeStyles } from "@material-ui/core";

const stepperUseStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },
    backButton: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    step_label_active: {
      fontSize: '14px',
      fontWeight: '600 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    }

  }));

  export default stepperUseStyles;

  