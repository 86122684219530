import UNIVERSAL from '../../config/config';
import {
  VIEW_MEMBERS_ASSESSMENT,
  SET_COMMENT_STORE,
  SET_COMMENT,
  SET_TOPIC_ROLE
} from '../../constants/reportListing/reportListingConst';
import { set_loader, unset_loader } from '../loader/loader_action';
import encrypt from '../shared/sharedActions';
import { set_snack_bar } from '../snackbar/snackbar_action';

export const setComment = (payload) => ({
  type: SET_COMMENT,
  payload: payload
});

export const setCommentStore = (payload) => ({
  type: SET_COMMENT_STORE,
  payload: payload
});

export const setViewMember = (payload) => ({
  type: VIEW_MEMBERS_ASSESSMENT,
  payload: payload
});
export const setTopicRole = (payload) => ({
  type: SET_TOPIC_ROLE,
  payload: payload
});

export const viewMemberAssign = (token, assessment_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      'user-token': token,
      assessment_id: assessment_id
    });

    return fetch(UNIVERSAL.BASEURL + '/assignMember/view_member_assign', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setViewMember(responseJson.result));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

// export const addComment = (token, comment, assessment_id, step_no) => {
//   return (dispatch) => {
//     dispatch(set_loader());

//     const data = encrypt({
//       'user-token': token,
//       comment: comment,
//       assessment_id: assessment_id,
//       step_no: step_no
//     });

//     return fetch(UNIVERSAL.BASEURL + '/comments/add_comment', {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({ data: data })
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.status) {
//           dispatch(getComments(token, assessment_id, step_no));
//           dispatch(set_snack_bar(responseJson.status, responseJson.message));
//         } else {
//           dispatch(set_snack_bar(responseJson.status, responseJson.message));
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       })
//       .finally(() => {
//         dispatch(unset_loader());
//       });
//   };
// };

// export const getComments = (token, assessment_id, step_no) => {
//   return (dispatch) => {
//     dispatch(set_loader());

//     const data = encrypt({
//       'user-token': token,
//       assessment_id: assessment_id,
//       step_no: step_no
//     });

//     return fetch(UNIVERSAL.BASEURL + '/comments/view_comments', {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({ data: data })
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.status) {
//           dispatch(setCommentStore(responseJson.result));
//           dispatch(setComment(''));
//         } else {
//           dispatch(setCommentStore([]));
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       })
//       .finally(() => {
//         dispatch(unset_loader());
//       });
//   };
// };

export const setAssignApproved = (token, assessment_id, approved, step_no) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      'user-token': token,
      assessment_id,
      approved,
      step_no
    });

    return fetch(UNIVERSAL.BASEURL + '/assessments/set_assign_approved', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
