import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EsgReportList from "../../widgets/esgReport";
import { Link } from "react-router-dom";
import "./esgReportListing.css";

function ESGReportListing(props) {
	const assessment_id = props.location.state.assessment_id;
	const assessment_name = props.location.state.assessment_name;

	// useEffect(() => {
	// 	props.viewMemberAssign(props.login.token, assessment_id);
	// }, []); // eslint-disable-line react-hooks/exhaustive-deps

 
  useEffect(() => {
    props.viewMemberAssign(props.login.token, assessment_id);
    props.setReportDataTask([])
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const { reportlisting } = props;
  // reportlisting.assessments[2].role.map((r, i) => {
  //   console.log('map',r,i)
  // })
  return (
    <div className="esgReportListingMainDiv scroll" style={{height: '89.5vh'}}>
      <Grid container>
        <Grid
          item
          xs={9}
          className='esgReportListingMainGrid'
        >
          <Link to="/report_listing"  className='esgReportListingLink' >
            <IconButton className='esgReportListingLinkIcon'>
              <ArrowBackIcon className='esgReportListingLinkArrow' />
            </IconButton>
          </Link>
          <Typography variant="h6">
            <b> {assessment_name === "" ? "Assessment-" + assessment_id.replace(/\w(?=\w{4})/g, "") : assessment_name } </b>
          </Typography>
        </Grid>
      </Grid>
      <EsgReportList reportlisting={reportlisting} {...props} />
      { reportlisting.assessments[0] === null &&
        reportlisting.assessments[1] === null &&
        reportlisting.assessments[2] === null &&
        reportlisting.assessments[3] === null &&
        reportlisting.assessments[4] === null && 
        reportlisting.assessments[5] === null && 
        reportlisting.assessments[6] === null && (
          <Typography
            variant="h6"
            className='esgReportListingTypography'
          >
            No Task Assigned To You For This Assessment
          </Typography>
        )}
    </div>
  );
}
export default React.memo(ESGReportListing);
