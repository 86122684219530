import {
  SET_ASSESSMENT_REPORT,
  SET_APPROVERS_REVIEWERS,
  SET_BLOB_URL,
  SET_ASSESSMENT_SKELETON,
  SET_JWT_HAS,
  SET_SUSTAINABILITY_PDF_PROGRESS,
  SET_SUSTAINABILITY_TOC,
  SET_SUSTAINABILITY_PROGRESS_COUNTER
} from "../../constants/sustainabilityReport/sustainabilityReportConst";
import {
  SET_COMMENTS,
  SET_TOC,
  SET_SUSTAINABILITY_REPORT,
  SET_LOADING_COMPLETED
} from "../../constants/sustainabilityReport/sustainabilityReportPdfConst";

let initial_state = {
  assessmentReport: [],
  sustainability_report_name: "",
  tableOfContent: [],
  comments: [],
  basicData: {},
  graphData: [],
  stakeholderData: [],
  parentTopicData: [],
  topicData: [],
  metricsData: [],
  coverData: "",
  headerFooterData: {},
  loadingCompleted: false,
  blockChain: [],
  approversReviewersList: [],
  frameWorkData: "",
  topic: [],
  approver: "",
  pdfBlob: {},
  assessmentSkeleton: false,
  jwt_has: "",
  pdfProgress: {},
  pdfProgressCounter: {},
    // font
    font:'',
    color:{},
    reportName:'',
    preSelectedColor:[]
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ASSESSMENT_REPORT:
      return {
        ...state,
        assessmentReport: action.payload,
        sustainability_report_name: action.payload.sustainability_report_name
          ? action.payload.sustainability_report_name
          : ""
      };
    case SET_SUSTAINABILITY_PDF_PROGRESS:
      let pdfProgress = state.pdfProgress;
      pdfProgress[action.payload.id] = action.payload.progress;
      return { ...state, pdfProgress: pdfProgress };
    case SET_SUSTAINABILITY_PROGRESS_COUNTER:
      let pdfProgressCounter = state.pdfProgressCounter;
      pdfProgressCounter[action.payload.id] = action.payload.counter;
      return {
        ...state,
        pdfProgressCounter: pdfProgressCounter
      };
    case SET_SUSTAINABILITY_TOC:
      return {
        ...state,
        tableOfContent: action.payload
      };
    case SET_BLOB_URL:
      let pdfBlob = state.pdfBlob;
      pdfBlob[action.payload.id] = action.payload.pdfBlob;
      return { ...state, pdfBlob: pdfBlob };
    case SET_JWT_HAS:
      return {
        ...state,
        jwt_has: action.payload
      };
    case SET_ASSESSMENT_SKELETON:
      return {
        ...state,
        assessmentSkeleton: action.payload
      };
    case SET_COMMENTS:
      return {
        ...state,
        comments: action.payload
      };
    case SET_LOADING_COMPLETED:
      return {
        ...state,
        loadingCompleted: action.payload
      };
    case SET_APPROVERS_REVIEWERS:
      return { ...state, approversReviewersList: action.payload };
    case SET_SUSTAINABILITY_REPORT:
      return {
        ...state,
        basicData: action.payload.company_logo,
        graphData: action.payload.graph_data,
        stakeholderData: action.payload.stakeholder_data,
        parentTopicData: action.payload.parent_topics,
        topicData: action.payload.topic_data,
        metricsData: action.payload.metrics_data,
        coverData: action.payload.cover_data,
        blockChain: action.payload.blockChain_data,
        headerFooterData: action.payload.header_footer_data,
        frameWorkData: action.payload.framework_data,
        topic: action.payload.topics,
        approver: action.payload.approver_data,
        font:action.payload.font,
        color:action.payload.color,
        reportName:action.payload.report_name,
        preSelectedColor:action.payload.previously_selected_colors,
      };
    default:
      return state;
  }
}
