import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Box,
  IconButton,
  Button,
  makeStyles,
  Grid,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import Pagination from "@material-ui/lab/Pagination";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

import DownloadWhiteSVG from "../../../images/downloadWhite.svg";
import {
  getDashboardSurvey,
  setSelectedDashboardSurvey,
} from "../../../actions/datahubActions/datahubActions";
import NoData from "../DatahubNoData/NoData";
import DataHubTabs from "components/screen/dataHub/dataHubTabs";
import SupplierSurvey from "components/screen/supplier/SupplierSurvey";
import SearchTextField from "components/UI/textfield/searchTextField";
import CustomButton from "components/UI/button/button";
import { setCreateDrawerState } from "actions/brm/BrmActions";
const useStyles = makeStyles(() => ({
  AppBar: {
    boxShadow: "none",
    // marginBottom: "20px",
    // marginTop: "auto",
    // border: "solid",
    width: "max-content",
    height: "100%",
    marginRight: "auto",
    // flex:1
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  activeTabLabel: {
    color: "#154328",
  },
  createButton: {
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374B9",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
  searchCont: {
    marginRight: 10,
  },
  headerIcon: { color: "#fff", paddingLeft: "5px" },

  // Save Button
  saveButton: {
    position: "absolute",
    bottom: "20px",
    right: "50px",
    textTransform: "none",
    width: "200px",
    height: "36px",
    color: "#fff",
    borderRadius: "8px",
    padding: "8px 16px 8px 16px",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374B9",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#3374b9",
    },
    secondary: {
      main: "#47BB76",
    },
  },
});

const columns = [
  { id: "name", label: "Survey Name" },
  { id: "lastUpdated", label: "Last Updated" },
  { id: "action", label: "Explore" },
];

function DataHubSheets() {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const rowsPerPage = 5;

  const history = useHistory();

  const dispatch = useDispatch();
  const { token, selectedOrganization } = useSelector((state) => state.login);
  const { dashboardSurvey } = useSelector((state) => state.dataHub);

  const [selectedTab, setSelectedTab] = React.useState(0);

  const [searchKeyword, setSearchKeyword] = useState("");

  const TABS_ARR = [
    { value: 0, tab: "External Survey" },
    { value: 1, tab: "Internal Survey" },
  ];

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (row) => {
    dispatch(setSelectedDashboardSurvey(row));
    history.push("/data_hub/survey/selected_survey");
  };

  useEffect(() => {
    dispatch(getDashboardSurvey(token, selectedOrganization?._id));
  }, []);

  if (dashboardSurvey?.length === 0) {
    return <NoData message={"No survey data found"} />;
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems="center">
        <Grid
          item
          md={8}
          xs={12}
          sm={12}
          style={{ marginTop: "10px", paddingLeft: "20px" }}
        >
          <DataHubTabs
            handleTabs={handleChange}
            value={selectedTab}
            tabs={TABS_ARR}
          />
        </Grid>
        {selectedTab === 1 && (
          <Grid
            item
            md={4}
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <SearchTextField
              searchClick={(keyword) => setSearchKeyword(keyword)}
            />

            <CustomButton
              onClick={() => {
                // setIsAddCompanyDrawer(true);
                dispatch(setCreateDrawerState(true));
              }}
              className={classes.createButton}
              size="medium"
              style={{
                marginLeft: "10px",
                whiteSpace: "nowrap",
                padding: "0 50px",
                marginRight: "20px",
              }}
            >
              <AddIcon
                className={classes.headerIcon}
                color="primary"
                variant="outlined"
              />
              {`Create Survey`}
            </CustomButton>
          </Grid>
        )}
      </Grid>
      <Box style={{ overflow: "scroll", height: "60vh" }}>
        {selectedTab === 0 && (
          <Box padding={"20px"}>
            <TableContainer>
              <Table>
                <TableHead style={{ backgroundColor: "#EBF1F8" }}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dashboardSurvey
                    ?.slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={index}
                          onClick={() => handleRowClick(row)}
                          style={{ cursor: "pointer" }}
                        >
                          {/* <TableCell padding="checkbox">
                        <Checkbox color="primary" />
                      </TableCell> */}
                          <TableCell>{row.survey_name}</TableCell>
                          <TableCell>
                            {moment(row.last_modified_on).format("DD MMM YYYY")}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              size={"small"}
                              // onClick={(e) => e.stopPropagation()}
                            >
                              <ChevronRightRoundedIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="flex-end" mt={5} mr={2}>
              <Pagination
                count={Math.ceil(dashboardSurvey.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
              />
            </Box>
          </Box>
        )}
        {selectedTab === 1 && (
           <Box paddingTop={"20px"} height={'100%'}>
            <SupplierSurvey searchKeyword={searchKeyword} />
           </Box>
            
        )}
      </Box>
    </ThemeProvider>
  );
}

export default React.memo(DataHubSheets);
