import React from "react";

import { Card, CardContent } from "@material-ui/core";

import { brmLeftStyles } from "./brmLeftStyle";

function LeftCard({ toMap = [], handleTabChange, subTabNo }) {
  const classes = brmLeftStyles();
  return toMap.map((data, index) => (
    <Card
      variant="outlined"
      className={classes.cardContainerDefault}
      onClick={() => handleTabChange(index)}
      key={data.id}
      style={{
        background: data.id === subTabNo ? "#EBF1F8" : "#FDFDFD",
        cursor: "pointer",
      }}
    >
      <CardContent className={classes.cardContent}>
        <div style={{ display: "flex" }}>
          <h1
            className={classes.mainHeadingDefault}
            style={{
              borderLeft: data.id === subTabNo && "4px solid #3374B9",
              paddingLeft: data.id === subTabNo && 5,
            }}
          >
            {data.mainHead}
          </h1>
          {/* {data.id < 1 && (
              <img
                style={{
                  width: "30px",
                  height: "30px",
                  marginLeft: "auto",
                }}
                src={tickGreen}
                alt=""
              />
            )} */}
        </div>
        <div style={{ overflowX: "scroll" }}>
          <h3
            className={classes.subHeadingDefault}
            style={{
              color: data.id === subTabNo ? "#3374b9" : "#BBBBBB",
            }}
          >
            {data.subHead}
          </h3>
        </div>
      </CardContent>
    </Card>
  ));
}

export default LeftCard;
