import React from 'react';
import { connect } from 'react-redux';
import SustainabilityReportMain from '../../components/sustainabilityReport/sustainabilityReportMain';
import {
  updateMaterialityAssessment,
  setActiveStep,
  resetAllAssessmentData
} from '../../actions/sustainabilityReport/sustainabilityReportAction';
import { updateBasicInfo } from '../../actions/proceedButton/proceedButtonAction';
import { setTopicTab } from '../../actions/materialityAction/materialityAction';
import { setReportTab } from '../../actions/fielddata/fieldDataAction';
import {
  setHelperDailogue,
  getHelpFlow,
  updateHelpFlow
} from '../../actions/login/loginActions';
import { setAssessmentsPaginationPage } from '../../actions/surveyListing/surveyListingAction';

export class SustainabilityReportMainCont extends React.PureComponent {
  componentDidMount(){
    this.props.setPaginationPage(this.props.surveyListing?.paginationPage);
  }
  componentWillUnmount() {
    this.props.setPaginationPage(this.props.surveyListing?.paginationPage);
  }
  render() {
    return <SustainabilityReportMain {...this.props} />;
  }
}

export const mapStateToProps = (store) => ({
  login: store.login,
  materialityAssessment: store.materialityAssessment,
  fieldData: store.fieldData,
  esgStrategy: store.esgStrategy,
  basicInfo: store.basicInfo,
  loadingBar:store.loadingBar,
  assesmentApproval: store.assesmentApproval,
  surveyListing: store.surveyListing,
});

export const mapDispatchToProps = (dispatch) => ({
  updateMaterialityAssessment: (payload) => {
    dispatch(updateMaterialityAssessment(payload));
  },
  setActiveStep: (step) => {
    dispatch(setActiveStep(step));
  },
  resetAllAssessmentData: () => {
    dispatch(resetAllAssessmentData());
  },
  updateBasicInfo: (assessment_id, basicInfo, token, activeStep, isSave) => {
    dispatch(
      updateBasicInfo(assessment_id, basicInfo, token, activeStep, isSave)
    );
  },
  setTopicTab: (payload) => {
    dispatch(setTopicTab(payload));
  },
  setReportTab: (payload) => {
    dispatch(setReportTab(payload));
  },
  setHelperDailogue: (payload) => {
    dispatch(setHelperDailogue(payload));
  },
  getHelpFlow: (token) => {
    dispatch(getHelpFlow(token));
  },
  updateHelpFlow: (token, step) => {
    dispatch(updateHelpFlow(token, step));
  },
  setPaginationPage: (payload) => {
    dispatch(setAssessmentsPaginationPage(payload));
  },
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SustainabilityReportMainCont);
