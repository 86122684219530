import { combineReducers } from "redux";
import login from "./login/login-reducer";
import snackbar from "./snackbar/snackbar_reducer";
import loader from "./loader/loader_reducer";
import businessUnit from "./businessUnit/businessUnitReducer";
import frameworks from "./frameworks/frameworkReducer";
import topic from "./topics/topicReducer";
import basicInfo from "./basicinfoReducer/basicinfoReducer";
import materialityAssessment from "./metarialityAssessment/metarialityAssessmentReducer";
import esgStrategy from "./esgStrategy/esgStrategyReducer";
import assesmentApproval from "./assesmentApproval/assesmentApprovalReducer";
import fieldData from "./fieldData/fieldDataReducer";
import sustainabilityReport from "./sustainabilityReport/sustainabilityReportReducer";
import surveyListing from "./surveyListing/surveyListingReducer";
import reportlisting from "./reportListing/reportListingReducer";
import generalSettings from "./generalSettings/generalSettingsReducer";
import comments from "./comments/commentsReducer";
import { loadingBarReducer } from "react-redux-loading-bar";
import maturityMatrix from "./maturityMatrix/maturityMatrixReducer";
import dataHub from "./datahub/datahubReducer";
import supplier from "./supplier/supplierReducer"
import brmReducer from "./brmReducer/BrmReducer"
import taskReducer from "./taskReducer/TaskReducer"
import supplierAdmin from "./supplierAdmin/supplierAdminReducer";
import esgDiagnosis from "./supplier/esgDiagnosisReducer"
export default combineReducers({
  login,
  snackbar,
  loader,
  businessUnit,
  frameworks,
  topic,
  basicInfo,
  materialityAssessment,
  esgStrategy,
  assesmentApproval,
  fieldData,
  sustainabilityReport,
  surveyListing,
  reportlisting,
  generalSettings,
  comments,
  loadingBar: loadingBarReducer,
  maturityMatrix,
  dataHub,
  supplier,
  brmReducer,
  taskReducer,
  supplierAdmin,
  esgDiagnosis
});
