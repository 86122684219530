export const SET_ELASTIC_ORGANIZATIONS = "GET_ELASTIC_ORGANIZATIONS";
export const SET_COMPANY_DATA = "GET_COMPANY_DATA";
export const SET_COMPANY_ID = "SET_COMPANY_ID";
export const SET_TOTAL_COMPANIES = "SET_TOTAL_COMPANIES";
export const SET_CONTROVERSY_LINKS = "SET_CONTROVERSY_LINKS";
export const SET_PAGINATION_PAGE = "SET_PAGINATION_PAGE";
export const SET_SURVEY_DATA = "SET_SURVEY_DATA";
export const SET_SELECTED_SURVEY = "SET_SELECTED_SURVEY";
export const SET_SURVEY_PAGINATION_PAGE = "SET_SURVEY_PAGINATION_PAGE";
export const SET_SELECTED_SURVEY_RESPONSE = "SET_SELECTED_SURVEY_RESPONSE";
export const SET_SELECTED_RES_COMPANY = "SET_SELECTED_RES_COMPANY";
export const SET_SURVEY_RESPONDED_USERS = "SET_SURVEY_RESPONDED_USERS";
export const SET_SECTION = "SET_SECTION";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_RESPONDED_ORGANIZATIONS = "SET_RESPONDED_ORGANIZATIONS";
export const SET_SELECTED_SECTION = "SET_SELECTED_SECTION";
export const SET_SUBMITTED_QUESTIONS = "SET_SUBMITTED_QUESTIONS";
export const SET_SELECTED_OPTION = "SET_SELECTED_OPTION";
export const SET_SELECTED_SECTION_TASK = "SET_SELECTED_SECTION_TASK";
export const SET_SELECTED_QUESTION = "SET_SELECTED_QUESTION";
export const SET_SURVEY_QUESTIONS = "SET_SURVEY_QUESTIONS";
export const SET_VERSION_HISTORY = "SET_VERSION_HISTORY";
export const SET_PUBLISHED_SURVEY_LIST = "SET_PUBLISHED_SURVEY_LIST";
export const SET_SUPPLIER_ACTIVE_TAB = "SET_SUPPLIER_ACTIVE_TAB";
export const SET_SURVEY_DOCUMENT = "SET_SURVEY_DOCUMENT";
export const SET_SURVEY_TOTAL = "SET_SURVEY_TOTAL";
