import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

// no data image
import NoDataImage from "../../../images/NoData.png";

const NoData = ({ message, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height:"100%"
      }}
    >
      <img
        src={NoDataImage}
        alt={"NoDataImage"}
        style={{ width: "100%", maxWidth: "25em", marginTop: "20px" }}
      />
      <Typography variant="h6">{message || "No Data Found"}</Typography>
      {children}
    </Box>
  );
};
export default NoData;