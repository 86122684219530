export const responsesData = [
  {
    organization_name: "NetHorizon",
    stakeholder_count: "20",
    response_count: "20",
    organization_dated: Date.now(),
  },
  {
    organization_name: "QuantumForge",
    stakeholder_count: "20",
    response_count: "8",
    organization_dated: Date.now(),
  },
  {
    organization_name: "NovoTechz",
    stakeholder_count: "20",
    response_count: "19",
    organization_dated: Date.now(),
  },
  {
    organization_name: "EliteInnovations",
    stakeholder_count: "20",
    response_count: "20",
    organization_dated: Date.now(),
  },
];

export const responseCompanies = [
  {
    _id: "ft4Fc5MH_cNpy82592bFDA",
    name: "Bessie Cooper",
    role: "Deputy Manager",
    status_no: 34,
    status: true,
    total_questions: 34,
    frequency: "Monthly",
    questions: [
      "How does your company measure and manage its energy consumption in data centers and offices?",
      "Are there initiatives in place to increase energy efficiency and reduce the environmental impact of operations?",
    ],
    weightage: [{ q1: 4 }, { q2: 4 }],
    selected_options: [{ q1: null }, { q2: 2 }],
    selected_date: "",
  },
  {
    _id: "NJIosi7q5Uv1yLm4hbZn7g",
    name: "Ralph Edwards",
    role: "Deputy Manager",
    status_no: 34,
    status: true,
    total_questions: 34,
    questions: [
      "How does your company measure and manage its energy consumption in data centers and offices?",
      "Are there initiatives in place to increase energy efficiency and reduce the environmental impact of operations?",
    ],
    weightage: [{ q1: 4 }, { q2: 4 }],
    selected_options: [{ q1: null }, { q2: null }],
    frequency: "Monthly",
  },
  {
    _id: "MS5JMsFw_c11Yn5G_VPC0Q",
    name: "Dianne Russell",
    role: "Team Lead",
    status_no: 34,
    status: false,
    total_questions: 34,
    questions: [
      "How does your company measure and manage its energy consumption in data centers and offices?",
      "Are there initiatives in place to increase energy efficiency and reduce the environmental impact of operations?",
    ],
    weightage: [{ q1: 4 }, { q2: 4 }],
    selected_options: [{ q1: 1 }, { q2: 2 }],
    frequency: "Half Yearly",
  },
  {
    _id: "rrpxFYWNopNRYIFz9utLwA",
    name: "Guy Hawkins",
    role: "Senior Manager",
    status_no: 34,
    status: true,
    total_questions: 34,
    questions: [
      "How does your company measure and manage its energy consumption in data centers and offices?",
      "Are there initiatives in place to increase energy efficiency and reduce the environmental impact of operations?",
    ],
    weightage: [{ q1: 4 }, { q2: 4 }],
    selected_options: [{ q1: null }, { q2: 4 }],
    frequency: "Monthly",
  },
  {
    _id: "QZl2AW9tu33E2Cg308CmIw",
    name: "Jane Cooper",
    role: "CEO",
    status_no: 34,
    status: true,
    total_questions: 34,
    questions: [
      "How does your company measure and manage its energy consumption in data centers and offices?",
      "Are there initiatives in place to increase energy efficiency and reduce the environmental impact of operations?",
    ],
    weightage: [{ q1: 4 }, { q2: 4 }],
    selected_options: [{ q1: null }, { q2: null }],
    frequency: "Half Yearly",
  },
  {
    _id: "1AtbYtptdYhK3Q7biI45NQ",
    name: "Robert Fox",
    role: "HR",
    status_no: 34,
    status: false,
    total_questions: 34,
    questions: [
      "How does your company measure and manage its energy consumption in data centers and offices?",
      "Are there initiatives in place to increase energy efficiency and reduce the environmental impact of operations?",
    ],
    weightage: [{ q1: 4 }, { q2: 4 }],
    selected_options: [{ q1: null }, { q2: null }],
    frequency: "Half Yearly",
  },
  {
    _id: "TeTEiB2TtBOTrm2-D-gWnw",
    name: "Courtney Henry",
    role: "Deputy Manager",
    status_no: 34,
    status: false,
    total_questions: 34,
    questions: [
      "How does your company measure and manage its energy consumption in data centers and offices?",
      "Are there initiatives in place to increase energy efficiency and reduce the environmental impact of operations?",
    ],
    weightage: [{ q1: 4 }, { q2: 4 }],
    selected_options: [{ q1: null }, { q2: null }],
    frequency: "Monthly",
  },
];
