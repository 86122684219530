import React, { memo } from "react";
import Box from "@material-ui/core/Box";
import ReportReviewTypeOne from "./reportReviewTypeOne";
import ReportReviewTypeTwo from "./reportReviewTypeTwo";
import ReportReviewTypeThree from "./reportReviewTypeThree";
import { makeStyles } from "@material-ui/core";
const SingleMetricReportReview = (props) => {
  const {
    login,
    fieldData,
    metric,
    index,
    setIsUpsertMetricData,
    addMetricsData,
    assessment_id,
    removeFile,
  } = props;

  const useStyles=makeStyles(()=>({
    Box:{ display: "flex", flexDirection: "column", width: "99%" },
  }))
  const classes= useStyles()
  if (!metric.sub_metrics || metric.sub_metrics.length === 0) {
    return (
      <Box className={classes.Box} >
        {metric.data_type === "Type 1" && (
          <ReportReviewTypeOne
            surveyData={metric.survey_data}
            surveyResultData={metric.data}
            isUpsertMetricData={fieldData.isUpsertMetricData}
            setIsUpsertMetricData={setIsUpsertMetricData}
            addMetricsData={addMetricsData}
            fieldData={fieldData}
            login={login}
            comment={metric.comment}
            file={metric.file}
            assessment_id={assessment_id}
            metricId={metric._id}
            index={index}
            review={false}
            removeFile={removeFile}
          />
        )}
        {metric.data_type === "Type 2" && (
          <ReportReviewTypeTwo
            surveyData={metric.survey_data}
            surveyResultData={metric.data}
            isUpsertMetricData={fieldData.isUpsertMetricData}
            setIsUpsertMetricData={setIsUpsertMetricData}
            addMetricsData={addMetricsData}
            fieldData={fieldData}
            login={login}
            comment={metric.comment}
            file={metric.file}
            assessment_id={assessment_id}
            metricId={metric._id}
            index={index}
            review={false}
            removeFile={removeFile}
          />
        )}
        {metric.data_type === "Type 3" && (
          <ReportReviewTypeThree
            surveyData={metric.survey_data}
            surveyResultData={metric.data}
            isUpsertMetricData={fieldData.isUpsertMetricData}
            setIsUpsertMetricData={setIsUpsertMetricData}
            addMetricsData={addMetricsData}
            fieldData={fieldData}
            login={login}
            comment={metric.comment}
            file={metric.file}
            assessment_id={assessment_id}
            metricId={metric._id}
            index={index}
            review={false}
            removeFile={removeFile}
          />
        )}
      </Box>
    );
  } else {
    return (
      <Box className={classes.Box}>
        {metric.sub_metrics.map((metric2) => {
          return (
            <>
              {metric2.data_type === "Type 1" && (
                <ReportReviewTypeOne
                  surveyData={metric2.survey_data}
                  surveyResultData={metric2.data}
                  isUpsertMetricData={fieldData.isUpsertMetricData}
                  setIsUpsertMetricData={setIsUpsertMetricData}
                  addMetricsData={addMetricsData}
                  fieldData={fieldData}
                  login={login}
                  comment={metric2.comment}
                  file={metric2.file}
                  assessment_id={assessment_id}
                  metricId={metric2._id}
                  index={index}
                  review={false}
                  removeFile={removeFile}
                />
              )}
              {metric2.data_type === "Type 2" && (
                <ReportReviewTypeTwo
                  surveyData={metric2.survey_data}
                  surveyResultData={metric2.data}
                  isUpsertMetricData={fieldData.isUpsertMetricData}
                  setIsUpsertMetricData={setIsUpsertMetricData}
                  addMetricsData={addMetricsData}
                  fieldData={fieldData}
                  login={login}
                  comment={metric2.comment}
                  file={metric2.file}
                  assessment_id={assessment_id}
                  metricId={metric2._id}
                  index={index}
                  review={false}
                  removeFile={removeFile}
                />
              )}
              {metric2.data_type === "Type 3" && (
                <ReportReviewTypeThree
                  surveyData={metric2.survey_data}
                  surveyResultData={metric2.data}
                  isUpsertMetricData={fieldData.isUpsertMetricData}
                  setIsUpsertMetricData={setIsUpsertMetricData}
                  addMetricsData={addMetricsData}
                  fieldData={fieldData}
                  login={login}
                  comment={metric2.comment}
                  file={metric2.file}
                  assessment_id={assessment_id}
                  metricId={metric2._id}
                  index={index}
                  review={false}
                  removeFile={removeFile}
                />
              )}
            </>
          );
        })}
      </Box>
    );
  }
};

export default memo(SingleMetricReportReview);
