import React from "react";

import { Card } from "@material-ui/core";

import { brmRightStyles } from "./brmRightStyle";
import BrmDataRightEvaluator from "./BrmRightEvaluator";

function BrmDataRight(props) {
  const classes = brmRightStyles();

 

  return (
    <div>
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
      >
        
        <BrmDataRightEvaluator {...props} />
      </Card>
    </div>
  );
}

export default BrmDataRight;
