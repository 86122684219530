import React from "react";
import TextField from "@material-ui/core/TextField";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

function CustomTextfield(props) {
  const colorTheme = createTheme({
    palette: {
      primary: { main: "#3374B9" },
      secondary: { main: "#47BB76" },
    },
  });

  return (
    <MuiThemeProvider theme={colorTheme}>
      <TextField {...props} />
    </MuiThemeProvider>
  );
}
export default React.memo(CustomTextfield);
