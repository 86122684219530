import { useState } from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";

const VendorLogo = ({ imageData, disableInputs, setVendorDetails }) => {
  const [vendorLogoURI, setVendorLogoURI] = useState("");

  // -------------- inner functions --------------
  const handleClearVendorLogo = () => {
    setVendorDetails((prev) => ({
      ...prev,
      vendorLogo: "",
    }));
    setVendorLogoURI("");
  };

  const handleUploadVendorLogo = (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];
    setVendorDetails((prev) => ({
      ...prev,
      vendorLogo: file,
    }));
    reader.onloadend = () => {
      setVendorLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // --------------------------------------------

  return (
    <Box my={2}>
      {/* Logo Title */}
      <Typography style={{ fontWeight: 600 }}>Supplier Logo</Typography>

      {/* Logo Container */}
      {vendorLogoURI || imageData?.url ? (
        <div
          style={{
            border: "2px solid #DADBE6",
            width: 120,
            height: 120,
            borderRadius: 5,
            padding: "20px",
            zIndex: 1,
            position: "relative",
          }}
        >
          <img
            src={vendorLogoURI || imageData?.url}
            alt={imageData?.name || "vendor-logo"}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
          <IconButton
            onClick={handleClearVendorLogo}
            style={{ position: "absolute", right: 0, top: 0, padding: 2 }}
            disabled={disableInputs}
          >
            <CancelIcon style={{ color: "gray" }} fontSize="small" />
          </IconButton>
        </div>
      ) : (
        <>
          <input
            id="vendor-logo"
            type="file"
            onChange={(event) => handleUploadVendorLogo(event)}
            style={{ display: "none" }}
            cursor="pointer"
            disabled={disableInputs}
          />
          <label htmlFor="vendor-logo">
            <div
              style={{
                border: "2px dashed #DADBE6",
                width: 120,
                height: 120,
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": {
                  "& $addIcon": {
                    color: "#3374B9",
                  },
                },
              }}
            >
              <AddCircleOutlineIcon
                name="addLogoIcon"
                fontSize="large"
                style={{ color: "#DADBE6" }}
              />
            </div>
          </label>
        </>
      )}
    </Box>
  );
};
export default VendorLogo;
