import React from "react";
import { Box, Typography, IconButton, MenuItem } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// icons
import MoreVertIcon from "@material-ui/icons/MoreVert";

// redux imports
import { useSelector } from "react-redux";

// component imports
import CustomTextfield from "../../../../../UI/textfield/textfield";

const BasicDetailsTab = () => {
  const surveyTypes = ["Controversy Survey", "Self Assessment Survey"];

  // from redux
  const { selectedSurveyData } = useSelector((state) => state.supplier);

  // --------------- inner functions ---------------
  // -----------------------------------------------

  return (
    <Box style={{ padding: "20px" }}>
      {/* Basic Details Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            color: "#15314E",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Basic Details
        </Typography>
        {/* <IconButton
          style={{
            border: "1px solid #32A05F",
            borderRadius: "8px",
            width: "36px",
            height: "36px",
          }}
        >
          <MoreVertIcon style={{ color: "#32A05F" }} />
        </IconButton> */}
      </Box>

      {/* Basic Details Fields */}
      <Box sx={{ p: 2 }}>
        <div style={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            style={{
              color: "#6C6C6C",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              marginBottom: "8px",
            }}
          >
            Survey Name
          </Typography>
          <CustomTextfield
            id="surveyName"
            name="surveyName"
            variant="outlined"
            fullWidth
            size="small"
            defaultValue={selectedSurveyData?.survey_name || ""}
          />
        </div>

        {/* <div style={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            style={{
              color: "#6C6C6C",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              marginBottom: "8px",
            }}
          >
            Survey Type
          </Typography>
          <Autocomplete
            id="surveyType"
            name="surveyType"
            options={surveyTypes}
            getOptionLabel={(option) => option}
            renderOption={(option) => <MenuItem>{option}</MenuItem>}
            size="small"
            renderInput={(params) => (
              <CustomTextfield {...params} variant="outlined" />
            )}
            value={selectedSurveyData?.survey_type || null}
          />
        </div> */}

        <div style={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            style={{
              color: "#6C6C6C",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              marginBottom: "8px",
            }}
          >
            Describe About The Survey
          </Typography>
          <CustomTextfield
            id="surveyDescription"
            name="surveyDescription"
            variant="outlined"
            fullWidth
            multiline
            minRows={5}
            size="small"
            defaultValue={selectedSurveyData?.survey_details || ""}
          />
        </div>
      </Box>
    </Box>
  );
};

export default BasicDetailsTab;
