import { makeStyles } from "@material-ui/core";
const drawerWidth = 240;
export  const ReportListingUseStyles = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      marginTop: 60,
      display: "flex",
      alignItems: "center",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "90%",
      marginLeft: 30,
      marginTop: 90,
    },
    appBarShift: {
      justifyContent: "flex-start",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerHeader: {
      backgroundColor: "#EBF1F8",
      textAlign: "center",
      width: "100%",
      padding: "20px 0px 50px 10px",
      marginBottom: 37,
    },
    tabdiv: {
      background:
        "linear-gradient(91.69deg, rgba(71, 187, 118, 0.1) -14.88%, rgba(51, 116, 185, 0.1) 123.69%)",
      padding: 10,
      width: "90%",
      marginBottom: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    label: {
      color: "#3374B9",
    },
    // new style added 
    main: {
      marginTop: 100,
     
    },
    svgImage: {
      paddingLeft: 45,
    },
    title: {
      textAlign: "left",
      marginLeft: 10,
    },
    titleText: {
      fontSize: 18,
    },
    searchField: {
      marginLeft: 30,
    },
    pagination: {
      marginLeft: 30,
    },
    noTaskContainer: {
      textAlign: "center",
      marginTop: 78,
    },
    noTaskContainerText: {
      color: " #9FA1AF",
      fontSize: "21px",
      fontWeight: "600",
      lineHeight: "32px",
    },
    passwordTextField:{
        width: "400px"
    },
    passwordButton:{
        width: "100%"
    }
  }));

  