import { useEffect } from "react";

// mui
import InterestMap from "../MapSection";
import ControversyChart from "../ChartSection";

// redux
import { useDispatch, useSelector } from "react-redux";

// components
import { BlueBackgroundCard } from "components/widgets/surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionList";
import { rest_get_contro_insights } from "actions/esgStrategy/esgStrategyAction";

const Insights = () => {
  const dispatch = useDispatch();
  const { supplierOrganizationDetails } = useSelector(
    (state) => state.brmReducer
  );

  useEffect(() => {
    dispatch(rest_get_contro_insights(supplierOrganizationDetails?.isin));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="scroll" style={{ height: "55vh", marginTop: "10px" }}>
      {/* chart section */}
      <BlueBackgroundCard heading={"Controversy over Time"} />
      <ControversyChart />

      {/* map section */}
      <BlueBackgroundCard heading={"Interest by Region"} />
      <InterestMap />
    </div>
  );
};
export default Insights;
