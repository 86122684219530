import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CustomButton from './../../UI/button/button';
import { Redirect } from 'react-router-dom';
import YouTube from 'react-youtube';
import Player from '../../../images/play-circle.png';
import CloseIcon from '@material-ui/icons/Close';
import videoOptions from '../../../constants/gettingStarted/gettingStartedConstants';
import useStylesGettingStarted from './gettingStartedStyles';
import ReportlistingPasswordDialog from '../reportListing/reportlistingPasswordDialog';

function GetStarted(props) {
  const classes = useStylesGettingStarted();

  const {
    login,
    createAssessment,
    surveyListing,
    setLogin,
    changePasswordValidation,
  } = props;
  const [currentTitle, setCurrentTitle] = useState(
    'Step 1 : Basic information'
  );
  const [currentVideoId, setCurrentVideoId] = useState('6ZZr9riOSls');
  const [isAllSteps, setIsAllSteps] = useState(false);
  const opts = {
    height: '250px',
    width: '450px',
    playerVars: {
      autoplay: 1,
    },
  };

  const onReady = (event) => {
    event.target.pauseVideo();
  };

  const handleVideo = (item) => {
    setCurrentTitle(item.title);
    setCurrentVideoId(item.videioId);
  };

  if (surveyListing.redirect_value && surveyListing.assessment_id) {
    return (
      <Redirect
        to={{
          pathname: '/sustainability_report',
          state: { assessment_id: surveyListing.assessment_id },
        }}
      />
    );
  }
  return (
    <>
      {surveyListing.changePasswordDialog && (
        <ReportlistingPasswordDialog
          changePasswordValidation={changePasswordValidation}
          setLogin={setLogin}
          surveyListing={surveyListing}
          login={login}
        />
      )}
      {!isAllSteps && (
        <Grid container className={classes.gridCont}>
          <Grid
            item
            container
            direction="column"
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="body1" className={classes.typography}>
                {currentTitle}
              </Typography>
              <Card elevation={3} className={classes.card}>
                <YouTube
                  videoId={currentVideoId}
                  opts={opts}
                  onReady={onReady}
                />
              </Card>
              <Typography variant="body2" className={classes.typography1}>
                Learn more about our tools
              </Typography>
            </Grid>
            <Grid item>
              <CustomButton
                name="proceedBtn"
                variant="contained"
                color="primary"
                className={classes.costumButton}
                onClick={() => {
                  createAssessment(login.token, login.current_organisation);
                }}
              >
                Proceed To Step 1
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      {isAllSteps && (
        <Grid container className={classes.gridCont2}>
          <Grid
            item
            container
            xs={12}
            md={6}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              container
              direction="column"
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="body1" className={classes.typography}>
                  {currentTitle}
                </Typography>
                <Card elevation={3} className={classes.card}>
                  <YouTube
                    videoId={currentVideoId}
                    opts={opts}
                    onReady={onReady}
                  />
                </Card>
                <Typography variant="body2" className={classes.typography1}>
                  Learn more about our tools
                </Typography>
              </Grid>
              <Grid item>
                <CustomButton
                  variant="contained"
                  color="primary"
                  className={classes.costumButton}
                  onClick={() => {
                    console.log('afsd');
                  }}
                >
                  lets start
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.demoDiv}>
              <Grid container spacing={3} className={classes.demoGrid}>
                <Grid item xs={10} md={11}>
                  <Typography className={classes.typography2}>
                    Demo video on Steps
                  </Typography>
                </Grid>
                <Grid item xs={2} md={1}>
                  <IconButton
                    className={classes.icon}
                    onClick={() => setIsAllSteps(!isAllSteps)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                {videoOptions.map((item) => {
                  return (
                    <Grid item xs={12} md={6} className={classes.videoGrid}>
                      <Paper elevation={4} className={classes.paper}>
                        <img
                          src={item.thumbnail}
                          alt=""
                          className={classes.img1}
                          onClick={() => {
                            handleVideo(item);
                          }}
                        />
                        <img
                          src={Player}
                          alt=""
                          className={classes.img2}
                          onClick={() => {
                            handleVideo(item);
                          }}
                        />
                      </Paper>
                      <Typography variant="body1" className={classes.title}>
                        {item.title}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}
export default React.memo(GetStarted);
