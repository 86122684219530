import React, { useEffect, useState, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import debounce from "lodash.debounce";
import CustomTextfield from "../../UI/textfield/textfield";
import SearchIcon from "@material-ui/icons/Search";
import ReportListing from "../../widgets/reportListing/reportListing";
import ListingPageSkeleton from "../../widgets/skeletons/listingPageSkeleton";
import Pagination from "@material-ui/lab/Pagination";
import MyTaskPlaceHolder from "../../../images/My Task.svg";
import { ReportListingUseStyles } from "./reportListingStyle";
import TaskFile from "../../../images/TaskFile.svg";
import ReportlistingPasswordDialog from "./reportlistingPasswordDialog";
import { useDispatch } from "react-redux";
import { setAssessmentsPaginationPage } from "../../../actions/surveyListing/surveyListingAction";

function ReportListingScreen(props) {
  const classes = ReportListingUseStyles();
  const dispatch = useDispatch();
  const {
    surveyListing,
    login,
    changePasswordValidation,
    getOrganizations,
    viewAssignedAssessments,
    setLogin,
  } = props;

  const limit = 5;
  const ascOrDes = false;
  const userType = localStorage.getItem("current_organisation_user_type");
  const [startingAfter, setStartingAfter] = useState(
    props?.surveyListing?.paginationPage === 0
      ? 0
      : props?.surveyListing?.paginationPage * limit - limit
  );
  const [pages, setPages] = useState(null);
  const [searchText, setSearchText] = useState("");

  
  useEffect(() => {
    setStartingAfter(
      props?.surveyListing?.paginationPage === 0
        ? 0
        : props?.surveyListing?.paginationPage * limit - limit
    );
  }, [props?.surveyListing?.paginationPage]);

  useEffect(() => {
    setPages(
      Math.ceil(props.reportlisting.totalAssignedAssessmentCount / limit)
    );
  }, [props.reportlisting.totalAssignedAssessmentCount]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    getOrganizations(login.token, window.location.hostname);
  }, [login.token, login.current_organisation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    
    viewAssignedAssessments(
      login.token,
      limit,
      startingAfter,
      surveyListing,
      login.current_organisation,
      surveyListing.keyword,
      ascOrDes
    );
  }, [ startingAfter, userType, login.current_organisation]);// eslint-disable-line react-hooks/exhaustive-deps
 

  const onSearchHandler = (e) => {
    setSearchText(e.target.value);
    debouncedFetchData(e.target.value);
  };

  //pagination function

  const handleChange = (event, value) => {
    setStartingAfter(value * limit - limit);
    dispatch(setAssessmentsPaginationPage(value));
    return;
  };

  const debouncedFetchData = useMemo(
    () =>
      debounce((query) => {
        viewAssignedAssessments(
          login.token,
          limit,
          startingAfter,
          surveyListing,
          login.current_organisation,
          query,
          false
        );
      }, 1000),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const NoTaskAdded = ({ MyTaskPlaceHolder }) => {
    return (
      <Grid container className={classes.noTaskContainer}>
        <Grid item xs={12}>
          <Typography className={classes.noTaskContainerText}>
            No Tasks Added
          </Typography>
          <img src={MyTaskPlaceHolder} alt="" />
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.main}>
      <Grid container alignItems="center" justifyContent="flex-start">
        <Grid item className={classes.svgImage}>
          <img src={TaskFile} alt="" />
        </Grid>
        <Grid item xs={6} md={2} className={classes.title}>
          <Typography variant="h6" className={classes.titleText}>
            Task
          </Typography>
        </Grid>
        <Grid item xs={11} md={4} className={classes.searchField}>
          <CustomTextfield
            size="small"
            type="text"
            label="Search"
            color="primary"
            variant="outlined"
            fullWidth={true}
            value={searchText}
            onChange={(e) => onSearchHandler(e)}
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="primary" size="small" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} className={classes.pagination}>
          <Pagination
            variant="outlined"
            color="primary"
            count={pages}
            page={
              props?.surveyListing?.paginationPage === 0
                ? 1
                : props?.surveyListing?.paginationPage
            }
            siblingCount={0}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent="center">
        {surveyListing.changePasswordDialog && (
          <ReportlistingPasswordDialog
            changePasswordValidation={changePasswordValidation}
            setLogin={setLogin}
            surveyListing={surveyListing}
            login={login}
          />
        )}

        {surveyListing.assignedAssessmentStatus ? (
          <ReportListing
            surveyListing={surveyListing}
            token={login.token}
            limit={limit}
            startingAfter={startingAfter}
            currentOrgainsation={login.current_organisation}
            viewAssignedAssessments={viewAssignedAssessments}
          />
        ) : (
          <div style={{ marginLeft: "-150px" }}>
            <ListingPageSkeleton />
          </div>
        )}
        {surveyListing.assignedAssessments.length === 0 && true && (
          <NoTaskAdded MyTaskPlaceHolder={MyTaskPlaceHolder} />
        )}
      </Grid>
    </div>
  );
}

export default React.memo(ReportListingScreen);
