import React, { useState } from "react";

import { Box } from "@material-ui/core";
import SupplierHeader from "./SupplierHeader";
import SupplierBody from "./SupplierSurvey";
import SupplierPortfolioMain from "./SupplierPortfolioMain";
import { useDispatch, useSelector } from "react-redux";
import { setSupplierActiveTab } from "../../../actions/supplier/supplierAction";

function SupplierMain() {
  const dispatch = useDispatch();
  const { supplierActiveTab } = useSelector((state) => state.supplier);

  const [searchKeyword, setSearchKeyword] = useState("");

  // const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (value) => {
    dispatch(setSupplierActiveTab(value));
    // setActiveTab(value);
  };

  return (
    <Box>
      <Box>
        <SupplierHeader
          activeTab={supplierActiveTab}
          handleActiveTab={handleActiveTab}
          source={supplierActiveTab === 0 ? "supplier" : "survey"}
          setSearchKeyword={setSearchKeyword}
        />
      </Box>
      <Box style={{ overflow: "scroll", height: "70vh" }}>
        {supplierActiveTab === 0 && (
          <SupplierPortfolioMain searchKeyword={searchKeyword} />
        )}
        {supplierActiveTab === 1 && (
          <SupplierBody searchKeyword={searchKeyword} />
        )}
      </Box>
    </Box>
  );
}

export default SupplierMain;
