export const DisableSaveNext = (questionType, currentQuestion) => {
  switch (questionType) {
    case "Multichoice":
      const disableButton =
        currentQuestion?.submitted_data &&
        !currentQuestion?.submitted_data?.some((data) => data.checked);

      return disableButton;

    default:
      const disableDefault =
        !currentQuestion?.submitted_data ||
        currentQuestion?.submitted_data === "";
      return disableDefault;
  }
};
