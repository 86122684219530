import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function bgGradiendt(ctx, chartArea, scales) {
  const { top, bottom } = chartArea;
  //   const { x, y } = scales;
  const gradientBackground = ctx.createLinearGradient(0, top, 0, bottom);
  gradientBackground.addColorStop(0, 'rgba(190, 210, 250, 0.43)');
  gradientBackground.addColorStop(1, 'rgba(51, 116, 185, 0)');

  return gradientBackground;
}

const EnvBarChart = ({ dataHub, d }) => {
  const title = [
    d === 1
      ? 'Total turnover of the company'
      : d === 2
      ? 'Total Number of Board of Directors'
      : d === 3
      ? 'Total electricity consumption'
      : d === 4
      ? 'Percentage of Female Board of Directors'
      : d === 5
      ? 'Total waste Recycled(in metric tonne)'
      : d === 6
      ? 'Total Energy Generated'
      : d === 7
      ? 'Emissions Intensity'
      : d === 8
      ? 'Waste Generated'
      : d === 9
      ? 'Total Energy consumed (in joules or Multipels)'
      : d === 10
      ? 'Minimum Wages Paid'
      : d === 11
      ? 'Rehabilitation and Resettlement'
      : d === 12
      ? 'Beneficiaries of CSR Projects'
      : d === 13
      ? 'Waste Generated'
      : d === 14
      ? 'Number of consumer complaints related to'
      : 'Total waste Generated Recycled(in metric tonne)',
  ];

  const options = {
    cutout: d === 4 && '80%',
    rotation: d === 4 && 270,
    circumference: d === 4 && 180,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          display: d === 4 ? false : true,
          align: 'center',
        },
        grid: {
          display: d === 4 ? false : true,
          drawOnChartArea: false,
        },
      },

      y: {
        grid: {
          display: d === 4 ? false : true,
          drawBorder: false,
        },
        ticks: {
          display: d === 4 ? false : true,
          maxTicksLimit: 5,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      title: {
        font: {
          weight: 700,
        },
        display: true,
        position: 'top',
        text: title[0],
      },
    },

    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const optionsPie = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title[0],
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const labels =
    d === 1
      ? ['Total turnover of the company']
      : d === 2
      ? ['Total Number of Board of Directors']
      : d === 3
      ? ['Total electricity consumption']
      : d === 4
      ? [
          'Percentage of Female Board of Directors',
          'Percentage of other Board of Directors',
        ]
      : d === 5
      ? ['Recycled', 'Re-used', 'Other recover']
      : d === 6
      ? [
          'Total Energy generated from non-renewable sources',
          'Total Energy generated from renewable sources',
        ]
      : d === 7
      ? ['Scope 1 Emissions', 'Scope 2 Emissions']
      : d === 8
      ? [
          'Plastic',
          'E-waste',
          'Bio-medical',
          'Construction',
          'Battery',
          'Radioactive',
          ['Other','Hazardous'],
          ['Other non-', 'hazardous'],
        ]
      : d === 9
      ? [
          'Total electricity consumption from renewable sources (A)',
          'Total fuel consumption from renewable sources (B)',
          'Energy consumption through from other renewable sources (C)',
        ]
      : d === 10
      ? [
          [
            'Average remuneration',
            '/salary/ wages',
            'of Board of',
            'Directors (BoD)',
          ],
          [
            'Average remuneration',
            '/salary/ wages',
            'of Key ',
            'Managerial Personne;',
          ],
          [
            'Average remuneration',
            '/salary/ wages',
            'of Employees other',
            'than BoD & KMP',
          ],
          ['Average remuneration', '/salary/ wages', 'of Workers'],
        ]
      : d === 11
      ? [
          'No. of Project Affected Families',
          'No of PAFs covered by Rehabilitation and Resettlement',
        ]
      : d === 12
      ? [
          'No. of persons benefitted from CSR Projects',
          'No of beneficiaries of CSR projects from vulnerable and marginalized groups',
        ]
      : d === 13
      ? [
          ['Total Plastics', '(including packaging)', 'waste generated'],
          ['Total E- ', 'waste generated'],
          ['Total Hazardous', 'waste generated'],
          ['Total Other', 'waste generated'],
        ]
      : d === 14
      ? [
          ['Data privacy'],
          [' Advertising'],
          [' Cyber-','security'],
          ['Delivery of', 'essential','services'],
          [' Restrictive', 'Trade','Practices'],
          [' Unfair', 'Trade','Practices'],
        ]
      : [
          ['Total Plastics', '(including','packaging)', 'waste recycled'],
          ['Total E-','waste', 'generated','recycled'],
          ['Total Hazardous', 'waste','generated ', 'recycled'],
          ['Total Other', 'waste','generated', 'recycled'],
        ];

  const bgColors =
    d === 1
      ? [ 'rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',]
      : d === 2
      ? [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ]
      : d === 3
      ? [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ]
      : [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ];
  // : d === 4
  // ? [
  //     dataHub['Percentage of Female Board of Directors'],
  //     100 - dataHub['Percentage of Female Board of Directors'],
  //   ]
  // : d === 5
  // ? [
  //     dataHub['Total waste recovered through Recycling'],
  //     dataHub['Total waste recovered through Re-use'],
  //     dataHub['Total waste recovered through Other recovery operations'],
  //   ]
  // : d === 6
  // ? [
  //     dataHub['Total Energy generated from non-renewable sources. '],
  //     dataHub['Total Energy generated from renewable sources. '],
  //   ]
  // : d === 7
  // ? [dataHub['Total Scope 1 Emissions'], dataHub['Total Scope 2 Emissions']]
  // : d === 8
  // ? [
  //     dataHub['Total Plastic Waste generated '],
  //     dataHub['Total E-waste generated'],
  //     dataHub['Total Bio-medical Waste generated'],
  //     dataHub['Total Construction and demolition Waste generated'],
  //     dataHub['Total Battery Waste generated'],
  //     dataHub['Total Radioactive Waste generated'],
  //     dataHub['Total Other Hazardous Waste generated'],
  //     dataHub['Total Other Non-Hazardous Waste generated'],
  //   ]
  // : d === 9
  // ? [
  //     dataHub['Total electricity consumption from renewable sources (A)'],
  //     dataHub['Total fuel consumption from renewable sources (B)'],
  //     dataHub[
  //       'Energy consumption through from other renewable sources (C)'
  //     ],
  //   ]
  // : d === 10
  // ? [
  //     dataHub[
  //       'Average remuneration/ salary/ wages of Board of Directors (BoD)'
  //     ],
  //     dataHub[
  //       'Average remuneration/ salary/ wages of Key Managerial Personnel'
  //     ],
  //     dataHub[
  //       'Average remuneration/ salary/ wages of Employees other than BoD and KMP'
  //     ],
  //     dataHub['Average remuneration/ salary/ wages of Workers'],
  //   ]
  // : d === 11
  // ? [
  //     dataHub['No. of Project Affected Families'],
  //     dataHub[
  //       'Percentage of PAFs covered by Rehabilitation and Resettlement'
  //     ],
  //   ]
  // : d === 12
  // ? [
  //     dataHub['No. of persons benefitted from CSR Projects'],
  //     dataHub[
  //       'Percentage of beneficiaries of CSR projects from vulnerable and marginalized groups'
  //     ],
  //   ]
  // : d === 13
  // ? [
  //     dataHub['Total Plastics (including packaging) waste generated'],
  //     dataHub['Total Hazardous waste generated'],
  //     dataHub['Total Other waste generated'],
  //   ]
  // : d === 14
  // ? [
  //     dataHub['Number of consumer complaints related to Data privacy'],
  //     dataHub['Number of consumer complaints related to Advertising'],
  //     dataHub['Number of consumer complaints related to Cyber-security'],
  //     dataHub[
  //       'Number of consumer complaints related to Delivery of essential services'
  //     ],
  //     dataHub[
  //       'Number of consumer complaints related to Restrictive Trade Practices'
  //     ],
  //     dataHub[
  //       'Number of consumer complaints related to Unfair Trade Practices'
  //     ],
  //   ]
  // : [
  //     dataHub['Total Plastics (including packaging) waste recycled'],
  //     dataHub['Total E-waste generated recycled'],
  //     dataHub['Total Hazardous waste generated recycled'],
  //     dataHub['Total Other waste generated recycled'],
  //   ];

  const dataSets =
    d === 1
      ? [dataHub['Total turnover of the company']]
      : d === 2
      ? [dataHub['Total Number of Board of Directors']]
      : d === 3
      ? [dataHub['Total electricity consumption (I=A+D)']]
      : d === 4
      ? [
          dataHub['Percentage of Female Board of Directors'],
          100 - dataHub['Percentage of Female Board of Directors'],
        ]
      : d === 5
      ? [
          dataHub['Total waste recovered through Recycling'],
          dataHub['Total waste recovered through Re-use'],
          dataHub['Total waste recovered through Other recovery operations'],
        ]
      : d === 6
      ? [
          dataHub['Total Energy generated from non-renewable sources. '],
          dataHub['Total Energy generated from renewable sources. '],
        ]
      : d === 7
      ? [dataHub['Total Scope 1 Emissions'], dataHub['Total Scope 2 Emissions']]
      : d === 8
      ? [
          dataHub['Total Plastic Waste generated '],
          dataHub['Total E-waste generated'],
          dataHub['Total Bio-medical Waste generated'],
          dataHub['Total Construction and demolition Waste generated'],
          dataHub['Total Battery Waste generated'],
          dataHub['Total Radioactive Waste generated'],
          dataHub['Total Other Hazardous Waste generated'],
          dataHub['Total Other Non-Hazardous Waste generated'],
        ]
      : d === 9
      ? [
          dataHub['Total electricity consumption from renewable sources (A)'],
          dataHub['Total fuel consumption from renewable sources (B)'],
          dataHub[
            'Energy consumption through from other renewable sources (C)'
          ],
        ]
      : d === 10
      ? [
          dataHub[
            'Average remuneration/ salary/ wages of Board of Directors (BoD)'
          ],
          dataHub[
            'Average remuneration/ salary/ wages of Key Managerial Personnel'
          ],
          dataHub[
            'Average remuneration/ salary/ wages of Employees other than BoD and KMP'
          ],
          dataHub['Average remuneration/ salary/ wages of Workers'],
        ]
      : d === 11
      ? [
          dataHub['No. of Project Affected Families'],
          dataHub[
            'Percentage of PAFs covered by Rehabilitation and Resettlement'
          ],
        ]
      : d === 12
      ? [
          dataHub['No. of persons benefitted from CSR Projects'],
          dataHub[
            'Percentage of beneficiaries of CSR projects from vulnerable and marginalized groups'
          ],
        ]
      : d === 13
      ? [
          dataHub['Total Plastics (including packaging) waste generated'],
          dataHub['Total E-waste generated'],
          dataHub['Total Hazardous waste generated'],
          dataHub['Total Other waste generated'],
        ]
      : d === 14
      ? [
          dataHub['Number of consumer complaints related to Data privacy'],
          dataHub['Number of consumer complaints related to Advertising'],
          dataHub['Number of consumer complaints related to Cyber-security'],
          dataHub[
            'Number of consumer complaints related to Delivery of essential services'
          ],
          dataHub[
            'Number of consumer complaints related to Restrictive Trade Practices'
          ],
          dataHub[
            'Number of consumer complaints related to Unfair Trade Practices'
          ],
        ]
      : [
          dataHub['Total Plastics (including packaging) waste recycled'],
          dataHub['Total E-waste generated recycled'],
          dataHub['Total Hazardous waste generated recycled'],
          dataHub['Total Other waste generated recycled'],
        ];

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        data: dataSets,
        borderWidth: 1,
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(210, 79, 31, 1)',
          'rgba(154, 6, 203, 1)',
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(210, 79, 31, 0.2)',
          'rgba(154, 6, 203, 0.22)'
        ],
        tension: 0.4,
      },
    ],
  };

  return (
    <Card
      style={{
        height: '100%',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.06)',
        borderRadius: '8px',
        border: '2px solid #DADBE6',
      }}
    >
      <CardContent style={{ height: '100%' }}>
        {d === 6 || d === 9 || d === 11 || d === 12 ? (
          <div
            style={{
              height: '100%',
            }}
          >
            <Pie data={data} options={optionsPie} />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              padding: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {d === 4 ? (
              <Doughnut data={data} options={options} />
            ) : d === 1 ? (
              <div>
                <Typography style={{ float: 'left' }}>
                  Total turnover of the company
                </Typography>
                <h1 style={{ textAlign: 'right' }}>
                  {dataHub['Total turnover of the company']}
                </h1>
              </div>
            ) : d === 2 ? (
              <div>
                <Typography style={{ float: 'left' }}>
                  Total Number of Board of Directors
                </Typography>
                <h1 style={{ textAlign: 'right' }}>
                  {dataHub['Total Number of Board of Directors']}
                </h1>
              </div>
            ) : d === 3 ? (
              <div>
                <Typography style={{ float: 'left' }}>
                  Total electricity consumption (in Joules)
                </Typography>
                <h1 style={{ textAlign: 'right' }}>
                  {dataHub['Total electricity consumption (I=A+D)']}
                </h1>
              </div>
            ) : (
              <Bar options={options} data={data} />
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default EnvBarChart;
