import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import moment from "moment";

// icons
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DocBlue from "images/DocBlue.png";

// redux imports
import { useDispatch, useSelector } from "react-redux";

import NoData from "../NoData/NoData";
import { setSelectedSection } from "../../../actions/supplier/supplierAction";
import {
  getSupplierOrgDetails,
  viewSupplierOrganization,
} from "../../../actions/brm/BrmActions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#5C90C7",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#3374B9",
      },
    },
    "& .MuiPaginationItem-root": {
      "&:hover": {
        color: "#fff",
        backgroundColor: "#A1BFDF",
      },
    },
  },

  tableContainer: {
    height: "60vh",
    boxShadow: "none",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRows: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    cursor: "pointer",
  },
  activeStatus: {
    padding: "8px 12px 8px 12px",
    borderRadius: "80px",
    display: "flex",
    alignItems: "center",
    width: "max-content",
    fontWeight: "500",
    color: "#154328",
    backgroundColor: "#BFE2CD",
  },
  inactiveStatus: {
    padding: "8px 12px 8px 12px",
    borderRadius: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "max-content",
    fontWeight: "500",
    color: "#8C7038",
    backgroundColor: "#FFEFD0",
  },
  actionButton: {
    fontWeight: "500",
    textTransform: "none",
    color: "#3374B9",
    width: "5rem",
  },
}));

const SurveyInMain = ({ searchKeyword }) => {
  const dispatch = useDispatch();
  const { supplierOrganizationList, total_supplier } = useSelector(
    (state) => state.brmReducer
  );
  const { current_organisation, token } = useSelector((state) => state.login);
  const classes = useStyles();
  const history = useHistory();

  const headings = ["Supplier Name", "Last Modified", "Country"];

  const [anchorEl, setAnchorEl] = useState(null);
  const [surveyOption, setSurveyOption] = useState(null);
  const [page, setPage] = useState(1);

  const limit = 7;

  const handleMenuToggle = (event, survey) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
    setSurveyOption(survey);
  };

  const handleMenuItemClick = (event) => {
    const menuItemText = event.currentTarget.textContent;

    switch (menuItemText) {
      case "Edit":
        console.log("Edit survey:", surveyOption);
        break;
      case "Duplicate":
        console.log("Duplicate survey:", surveyOption);
        break;
      case "Delete Survey":
        console.log("Deleting survey:", surveyOption);
        break;
      default:
        break;
    }

    handleMenuToggle();
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
  };

  const handleSurveyClick = (selectedSurvey) => {
    dispatch(getSupplierOrgDetails(token, selectedSurvey?._id));
    history.push(`/supplier/supplier_details`);
  };

  const fetchSupplierOrganizations = (page) => {
    const starting_after = (page - 1) * limit;
    dispatch(
      viewSupplierOrganization(
        token,
        current_organisation,
        searchKeyword,
        limit,
        starting_after
      )
    );
  };

  useEffect(() => {
    fetchSupplierOrganizations(page);
    dispatch(setSelectedSection(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchKeyword]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div style={{ height: "100%", position: "relative" }}>
      {!supplierOrganizationList || supplierOrganizationList.length === 0 ? (
        <NoData />
      ) : (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                {headings.map((heading, index) => (
                  <TableCell key={index} className={classes.tableHead}>
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {supplierOrganizationList.map((survey, index) => (
                <TableRow
                  className={classes.tableRows}
                  key={survey?._id}
                  onClick={() => handleSurveyClick(survey)}
                >
                  <TableCell>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{ gap: 20 }}
                    >
                      <img src={DocBlue} alt="DocBlue" height={20} width={20} />
                      {survey.name}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {moment(survey.last_modified_on).format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell>{survey.country_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Menu
            id="survey-actions-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuToggle}
            PaperProps={{
              style: {
                borderRadius: 8,
                width: 200,
              },
            }}
          >
            <MenuItem
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                marginBottom: "10px",
              }}
              onClick={(e) => handleMenuItemClick(e)}
            >
              <EditOutlinedIcon style={{ color: "#3374B9", marginRight: 8 }} />
              Edit
            </MenuItem>
            <MenuItem
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                marginBottom: "10px",
              }}
              onClick={(e) => handleMenuItemClick(e)}
            >
              <FileCopyOutlinedIcon
                style={{ color: "#3374B9", marginRight: 8 }}
              />
              Duplicate
            </MenuItem>
            <MenuItem
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#D0766B",
              }}
              onClick={(e) => handleMenuItemClick(e)}
            >
              <DeleteOutlinedIcon
                style={{ color: "#C45446", marginRight: 8 }}
              />
              Delete Survey
            </MenuItem>
          </Menu>
        </TableContainer>
      )}

      <Box style={{ position: "absolute", right: 10, bottom: 10 }}>
        <Pagination
          color="primary"
          classes={{ root: classes.root }}
          variant="outlined"
          count={Math.ceil(total_supplier / limit)}
          page={page}
          onChange={handlePageChange}
        />
      </Box>
    </div>
  );
};

export default SurveyInMain;
