import React, { useEffect, useLayoutEffect } from "react";
import BasicAssessmentDetails from "./basicAssessmentDetails";
import BasicCompanyDetails from "./basicCompanyDetails";
import StakeholderList from "./stakeholderList";

const BasicInfoTabRight = (props) => {
  const { basicInfo, viewAllUsers, login } = props;

  useEffect(() => {
    viewAllUsers(login.token, login.current_organisation, "");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: "100%" }}>
      {basicInfo.basicInfoRightTab === 0 && (
        <BasicAssessmentDetails {...props} />
      )}
      {basicInfo.basicInfoRightTab === 1 && <BasicCompanyDetails {...props} />}
      {basicInfo.basicInfoRightTab === 2 && <StakeholderList {...props} />}
    </div>
  );
};

export default BasicInfoTabRight;
