import React from "react";
import * as PropTypes from "prop-types";
// import { Menu } from "antd";
import { Menu, MenuItem, Select, Typography } from "@material-ui/core";
import { sortBy, prop } from "ramda";
import ButtonDropdown from "./ButtonDropdown"; // Can't be a Pure Component due to Dropdown lookups overlay component type to set appropriate styles
import { CustomDataInput } from "./SelectChartType";

const sortByTitle = sortBy(prop("title"));

const MemberMenu = (onClick, availableMembers) => {
  return (
    <>
      {availableMembers.length ? (
        sortByTitle(availableMembers).map((m) => (
          <MenuItem key={m.name} onClick={() => onClick(m)}>
            {m.title}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>No members found</MenuItem>
      )}
    </>
  );
};

// const MemberDropdown = ({ onClick, availableMembers, ...buttonProps }) => {
//   // <ButtonDropdown
//   //   overlay={memberMenu(onClick, availableMembers)}
//   //   {...buttonProps}
//   // >
//   //   <MemberMenu onClick={onClick} availableMembers={availableMembers} />
//   // </ButtonDropdown>
//   const [age, setAge] = React.useState("");

//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };

//   return (
//     <Select
//       labelId="demo-simple-select-label"
//       id="demo-simple-select"
//       value={age}
//       label="Age"
//       onChange={handleChange}
//     >
//       {availableMembers.length ? (
//         sortByTitle(availableMembers).map((m) => (
//           <MenuItem key={m.name} value={m.name} onClick={() => onClick(m)}>
//             {m.title}
//           </MenuItem>
//         ))
//       ) : (
//         <MenuItem disabled>No members found</MenuItem>
//       )}
//     </Select>
//   );
// };

const MemberDropdown = ({
  onClick,
  availableMembers,
  type,
  style,
  ...children
}) => {
  const sortByTitle = sortBy(prop("title"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <ButtonDropdown
      type={type}
      onClick={onClick}
      style={{ position: "relative" }}
      availableMembers={availableMembers}
      // overlay={memberMenu(onClick, availableMembers)}
      childrenButton={children}
      open={open}
      handleClick={handleClick}
    >
      <Menu
        style={{ minWidth: "300px", height: "60%", top: "57px" }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <CustomDataInput style={{padding:'5px 10px',width:'100%'}} type='text' /> */}
        {availableMembers.length ? (
          sortByTitle(availableMembers).map((m, i) => (
            <div style={{ width: "100%" }}>
              {/* {console.log("---", m.title.replace(m.shortTitle, "").replace(/\s+/g,' ').trim())}
              {console.log(
                "///",
                sortByTitle(availableMembers)[0].title.replace(
                  sortByTitle(availableMembers)[0].shortTitle,
                  ""
                ).replace(/\s+/g,' ').trim()
              )} */}
              {i > 0 &&
                m.title
                  .replace(m.shortTitle, "")
                  .replace(/\s+/g, " ")
                  .trim() !==
                  sortByTitle(availableMembers)
                    [i - 1].title.replace(
                      sortByTitle(availableMembers)[i - 1].shortTitle,
                      ""
                    )
                    .replace(/\s+/g, " ")
                    .trim() && (
                  <Typography
                    variant="inherit"
                    style={{
                      textWrap: "wrap",
                      padding: "2px 12px",
                      color: "#00000073",
                    }}
                  >
                    {m.title.replace(m.shortTitle, "")}
                  </Typography>
                )}
              {i === 0 && (
                <Typography
                  variant="inherit"
                  style={{
                    textWrap: "wrap",
                    padding: "2px 12px",
                    color: "#00000073",
                  }}
                >
                  {/* {console.log(Array.from(new Set(m.title.split(","))).toString())} */}
                  {/* {Array.from(new Set(m.title.split(","))).toString()} */}
                  {m.title.replace(m.shortTitle, "")}
                </Typography>
              )}
              <MenuItem
                onClick={() => {
                  console.log("click");
                  onClick(m);
                  handleClose();
                }}
                key={m.name}
              >
                <Typography
                  variant="inherit"
                  style={{ textWrap: "wrap", marginBottom: "5px" }}
                >
                  {m.shortTitle}
                </Typography>
              </MenuItem>
            </div>
          ))
        ) : (
          <MenuItem disabled>No members found</MenuItem>
        )}
      </Menu>
    </ButtonDropdown>
  );
};

MemberDropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  availableMembers: PropTypes.array.isRequired,
};
export default MemberDropdown;
