import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getReports,
  setReportValue,
  getViewSurvey,
  getSurveyResult,
  upsetSurveyResult,
  setSurveyResult,
  setSurveyType,
  setSurveyData,
  setIsUpsertMetricData,
  viewChats,
  viewChatsInTask,
  setMetricChats,
  getSubmissionDetails,
  approveSubmission,
  
} from "../../actions/fielddata/fieldDataAction";
import FieldDataSurveyReport from "./../../components/widgets/reportListing/fieldDataSurvey/fieldDataSurveyReport";
import { removeFile } from "../../actions/generalSettings/generalSettingsAction";
import { viewAssignedMembers } from "../../actions/fielddata/fieldDataAction";

export class FieldDataSurveyReportCont extends React.PureComponent {
  render() {
    return <FieldDataSurveyReport {...this.props} />;
  }
}

export const mapStateToProps = (store) => ({
  login: store.login,
  fieldData: store.fieldData,
  esgStrategy: store.esgStrategy
});

export const mapDispatchToProps = (dispatch) => ({
  getReports: (payload) => {
    dispatch(getReports(payload));
  },
  setReportValue: (payload) => {
    dispatch(setReportValue(payload));
  },
  // getViewSurvey: (token, report_id) => {
  //   dispatch(getViewSurvey(token, report_id));
  // },
  getSurveyResult: (token, metric_id, assessment_id) => {
    dispatch(getSurveyResult(token, metric_id, assessment_id));
  },
  setIsUpsertMetricData: (payload) => {
    dispatch(setIsUpsertMetricData(payload));
  },
  upsetSurveyResult: (
    token,
    survey_id,
    assessment_id,
    data,
    organisation_id
  ) => {
    dispatch(
      upsetSurveyResult(token, survey_id, assessment_id, data, organisation_id)
    );
  },
  setSurveyResult: (payload) => {
    dispatch(setSurveyResult(payload));
  },
  setSurveyType: (payload) => {
    dispatch(setSurveyType(payload));
  },
  setSurveyData: (payload) => {
    dispatch(setSurveyData(payload));
  },
  viewChats: (token, assessment_id, metric_id) => {
    dispatch(viewChats(token, assessment_id, metric_id));
  },
  viewChatsInTask: (token, assessment_id, metric_id,is_approval) => {
    dispatch(viewChatsInTask(token, assessment_id, metric_id,is_approval));
  },
  getSubmissionDetails: (token,submission_id, user_id,user) => {
    dispatch(
      getSubmissionDetails(token,submission_id,user_id ,user)
    );
  },
  setMetricChats: (payload) => {
    dispatch(setMetricChats([]));
  },
  viewAssignedMembers: (token, assessment_id, step_no, metric_id) => {
    dispatch(viewAssignedMembers(token, assessment_id, step_no, metric_id));
  },
  approveSubmission: (
    token,
    metric_id,
    assessment_id,
    user_id,
    organisation_id,
    chat_id,selected_index,parent_metric_id,
    is_approver
  ) => {
    dispatch(
      approveSubmission(
        token,
        metric_id,
        assessment_id,
        user_id,
        organisation_id,
        chat_id,selected_index,parent_metric_id,
        is_approver
      )
    );
  },
  removeFile: (
    token,
    assessment_id,
    step_no,
    url,
    field_name,
    assessment_topic_id,
    activity_id,
    kpi_id,
    metric_id
  ) => {
    dispatch(
      removeFile(
        token,
        assessment_id,
        step_no,
        url,
        field_name,
        assessment_topic_id,
        activity_id,
        kpi_id,
        metric_id
      )
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldDataSurveyReportCont);
