import { useState } from "react";
import { Box, Typography, Chip } from "@material-ui/core";

import CustomButton from "../../../../UI/button/button";
import SectorDialog from "./SectorDialog";

export const sectorData = [
  {
    _id: "6197687b956d95419441252a",
    Sector: "Transportation",
    industry_data: [
      {
        _id: "6197687b956d954194412577",
        Industry: "Road Transportation",
      },
      {
        _id: "6197687b956d954194412575",
        Industry: "Marine Transportation",
      },
      {
        _id: "6197687b956d954194412576",
        Industry: "Rail Transportation",
      },
      {
        _id: "6197687b956d954194412574",
        Industry: "Car Rental & Leasing",
      },
      {
        _id: "6197687b956d954194412570",
        Industry: "Airlines",
      },
      {
        _id: "6197687b956d954194412573",
        Industry: "Cruise Lines",
      },
      {
        _id: "6197687b956d954194412571",
        Industry: "Auto Parts",
      },
      {
        _id: "6197687b956d95419441256f",
        Industry: "Air Freight & Logistics",
      },
      {
        _id: "6197687b956d954194412572",
        Industry: "Automobiles",
      },
    ],
  },
  {
    _id: "6197687b956d954194412523",
    Sector: "Financials",
    industry_data: [
      {
        _id: "6197687b956d954194412544",
        Industry: "Consumer Finance",
      },
      {
        _id: "6197687b956d954194412545",
        Industry: "Security & Commodity Exchanges",
      },
      {
        _id: "6197687b956d954194412548",
        Industry: "Mortgage Finance",
      },
      {
        _id: "6197687b956d954194412546",
        Industry: "Investment Banking & Brokerage",
      },
      {
        _id: "6197687b956d954194412542",
        Industry: "Asset Management & Custody Activities",
      },
      {
        _id: "6197687b956d954194412543",
        Industry: "Commercial Banks",
      },
      {
        _id: "6197687b956d954194412547",
        Industry: "Insurance",
      },
    ],
  },
  {
    _id: "6197687b956d954194412524",
    Sector: "Health Care",
    industry_data: [
      {
        _id: "6197687b956d95419441254e",
        Industry: "Medical Equipment & Supplies",
      },
      {
        _id: "6197687b956d954194412549",
        Industry: "Biotechnology & Pharmaceuticals",
      },
      {
        _id: "6197687b956d95419441254a",
        Industry: "Health Care Distributors",
      },
      {
        _id: "6197687b956d95419441254d",
        Industry: "Managed Care",
      },
      {
        _id: "6197687b956d95419441254b",
        Industry: "Drug Retailers",
      },
      {
        _id: "6197687b956d95419441254c",
        Industry: "Health Care Delivery",
      },
    ],
  },
  {
    _id: "6197687b956d954194412521",
    Sector: "Extractives & Minerals Processing",
    industry_data: [
      {
        _id: "6197687b956d954194412538",
        Industry: "Oil & Gas – Refining & Marketing",
      },
      {
        _id: "6197687b956d954194412533",
        Industry: "Coal Operations",
      },
      {
        _id: "6197687b956d954194412534",
        Industry: "Oil & Gas – Exploration & Production",
      },
      {
        _id: "6197687b956d954194412535",
        Industry: "Iron & Steel Producers",
      },
      {
        _id: "6197687b956d954194412537",
        Industry: "Metals & Mining",
      },
      {
        _id: "6197687b956d954194412532",
        Industry: "Construction Materials",
      },
      {
        _id: "6197687b956d954194412536",
        Industry: "Oil & Gas – Midstream",
      },
      {
        _id: "6197687b956d954194412539",
        Industry: "Oil & Gas – Services",
      },
    ],
  },
  {
    _id: "6197687b956d954194412522",
    Sector: "Food & Beverage",
    industry_data: [
      {
        _id: "6197687b956d954194412541",
        Industry: "Tobacco",
      },
      {
        _id: "6197687b956d954194412540",
        Industry: "Restaurants",
      },
      {
        _id: "6197687b956d95419441253f",
        Industry: "Processed Foods",
      },
      {
        _id: "6197687b956d95419441253c",
        Industry: "Food Retailers & Distributors",
      },
      {
        _id: "6197687b956d95419441253b",
        Industry: "Agricultural Products",
      },
      {
        _id: "6197687b956d95419441253e",
        Industry: "Non-Alcoholic Beverages",
      },
      {
        _id: "6197687b956d95419441253a",
        Industry: "Alcoholic Beverages",
      },
      {
        _id: "6197687b956d95419441253d",
        Industry: "Meat, Poultry & Dairy",
      },
    ],
  },
  {
    _id: "6197687b956d954194412527",
    Sector: "Resource Transformation",
    industry_data: [
      {
        _id: "6197687b956d954194412561",
        Industry: "Industrial Machinery & Goods",
      },
      {
        _id: "6197687b956d95419441255d",
        Industry: "Aerospace & Defense",
      },
      {
        _id: "6197687b956d954194412560",
        Industry: "Electrical & Electronic Equipment",
      },
      {
        _id: "6197687b956d95419441255f",
        Industry: "Containers & Packaging",
      },
      {
        _id: "6197687b956d95419441255e",
        Industry: "Chemicals",
      },
    ],
  },
  {
    _id: "6197687b956d954194412520",
    Sector: "Consumer Goods",
    industry_data: [
      {
        _id: "6197687b956d95419441252f",
        Industry: "Household & Personal Products",
      },
      {
        _id: "6197687b956d954194412531",
        Industry: "Toys & Sporting Goods",
      },
      {
        _id: "6197687b956d95419441252d",
        Industry: "Building Products & Furnishings",
      },
      {
        _id: "6197687b956d95419441252c",
        Industry: "Appliance Manufacturing",
      },
      {
        _id: "6197687b956d95419441252e",
        Industry: "E-Commerce",
      },
      {
        _id: "6197687b956d95419441252b",
        Industry: "Apparel, Accessories & Footwear",
      },
      {
        _id: "6197687b956d954194412530",
        Industry: "Multiline and Specialty Retailers & Distributors",
      },
    ],
  },
  {
    _id: "6197687b956d954194412529",
    Sector: "Technology & Communications",
    industry_data: [
      {
        _id: "6197687b956d95419441256c",
        Industry: "Semiconductors",
      },
      {
        _id: "6197687b956d95419441256b",
        Industry: "Internet Media & Services",
      },
      {
        _id: "6197687b956d95419441256a",
        Industry: "Hardware",
      },
      {
        _id: "6197687b956d95419441256d",
        Industry: "Software & IT Services",
      },
      {
        _id: "6197687b956d95419441256e",
        Industry: "Telecommunication Services",
      },
      {
        _id: "6197687b956d954194412569",
        Industry:
          "Electronic Manufacturing Services & Original Design Manufacturing",
      },
    ],
  },
  {
    _id: "6197687b956d954194412528",
    Sector: "Services",
    industry_data: [
      {
        _id: "6197687b956d954194412568",
        Industry: "Professional & Commercial Services",
      },
      {
        _id: "6197687b956d954194412564",
        Industry: "Education",
      },
      {
        _id: "6197687b956d954194412563",
        Industry: "Casinos & Gaming",
      },
      {
        _id: "6197687b956d954194412567",
        Industry: "Media & Entertainment",
      },
      {
        _id: "6197687b956d954194412562",
        Industry: "Advertising & Marketing",
      },
      {
        _id: "6197687b956d954194412565",
        Industry: "Hotels & Lodging",
      },
      {
        _id: "6197687b956d954194412566",
        Industry: "Leisure Facilities",
      },
    ],
  },
  {
    _id: "6197687b956d954194412525",
    Sector: "Infrastructure",
    industry_data: [
      {
        _id: "6197687b956d954194412552",
        Industry: "Home Builders",
      },
      {
        _id: "6197687b956d954194412550",
        Industry: "Electric Utilities & Power Generators",
      },
      {
        _id: "6197687b956d954194412553",
        Industry: "Real Estate",
      },
      {
        _id: "6197687b956d95419441254f",
        Industry: "Engineering & Construction Services",
      },
      {
        _id: "6197687b956d954194412556",
        Industry: "Water Utilities & Services",
      },
      {
        _id: "6197687b956d954194412554",
        Industry: "Real Estate Services",
      },
      {
        _id: "6197687b956d954194412555",
        Industry: "Waste Management",
      },
      {
        _id: "6197687b956d954194412551",
        Industry: "Gas Utilities & Distributors",
      },
    ],
  },
  {
    _id: "6197687b956d954194412526",
    Sector: "Renewable Resources & Alternative Energy",
    industry_data: [
      {
        _id: "6197687b956d954194412557",
        Industry: "Biofuels",
      },
      {
        _id: "6197687b956d95419441255c",
        Industry: "Wind Technology & Project Developers",
      },
      {
        _id: "6197687b956d95419441255a",
        Industry: "Pulp & Paper Products",
      },
      {
        _id: "6197687b956d954194412558",
        Industry: "Fuel Cells & Industrial Batteries",
      },
      {
        _id: "6197687b956d954194412559",
        Industry: "Forestry Management",
      },
      {
        _id: "6197687b956d95419441255b",
        Industry: "Solar Technology & Project Developers",
      },
    ],
  },
];

const VendorSector = ({
  sector = sectorData,
  sectorId,
  industryId,
  disableInputs,
  setCompanyDetails,
}) => {
  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);
  const [sectors, setSectors] = useState(sector);

  const sectorChipStyle = {
    justifyContent: "flex-start",
    fontWeight: 600,
    color: "#15314E",
    backgroundColor: "#EBF1F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  };

  return (
    <Box my={2}>
      {/* Logo Title */}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: 600 }}>Sector & Industry</Typography>

        <CustomButton
          onClick={() => {
            setIsSectorDialogOpen(true);
          }}
          variant="text"
          color="primary"
          style={{ textTransform: "capitalize" }}
          disabled={disableInputs}
        >
          Add Industry & Sector
        </CustomButton>
      </Box>

      {/* sector chips */}
      <Box mt={1.5}>
        {sectors.length !== 0 ? (
          sectors.map((sector, idx) => (
            <div style={{ marginBottom: 8 }} key={idx}>
              {sector.industry_data.some((inds) => {
                return inds.isSelected || inds?._id === industryId;
              }) && (
                <>
                  <Typography style={{ color: "#77787B", fontSize: 14 }}>
                    {sector.Sector}
                  </Typography>
                  {sector.industry_data.map(
                    (industry, idx) =>
                      (industry.isSelected || industry?._id === industryId) && (
                        <Chip
                          key={idx}
                          style={sectorChipStyle}
                          label={industry.Industry}
                        />
                      )
                  )}
                </>
              )}
            </div>
          ))
        ) : (
          <Chip style={sectorChipStyle} label="Non Selected" />
        )}
      </Box>

      {/* sector dialog */}
      {isSectorDialogOpen && (
        <SectorDialog
          sectors={sectors}
          setSectors={setSectors}
          isSectorDialogOpen={isSectorDialogOpen}
          setIsSectorDialogOpen={setIsSectorDialogOpen}
          sectorChipStyle={sectorChipStyle}
          setCompanyDetails={setCompanyDetails}
        />
      )}
    </Box>
  );
};
export default VendorSector;
