import Box from "@material-ui/core/Box";

import ProgressHeader from "./RightComps/ProgressHeader";
import QuestionsSection from "./RightComps/QuestionsSection";
import { Question } from "../../../selfAssessment/selfAssessmentResponse";

const RightSections = ({
  question,
  progress,
  currentQues,
  activeTab,
  total_questions,
  questionComment,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"65vh"}
      maxWidth={"calc(100vw - 400px)"}
      sx={{ gap: 20, overflowY: "auto" }}
    >
      {/* Header */}
      <ProgressHeader
        title={activeTab?.section_name}
        sub={activeTab?.section_sub_name}
        progress={progress}
        totalQues={total_questions}
        currentQues={currentQues}
      />

      {/* Main */}
      {/* <Question question={question?.question} type={question?.question_type} /> */}

      <QuestionsSection
        question={question}
        currentQues={currentQues}
        activeTab={activeTab}
        questionComment={questionComment}
      />
    </Box>
  );
};
export default RightSections;
