import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Radio from '../../UI/radioButton/radioButton';
import { Avatar, Grid, Paper } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DoneIcon from '@material-ui/icons/Done';
import dragicon from '../../../images/dragicon.png';
function Approvers(props) {
  const {
    defaultApprover,
    setDefaultApprover,
    fieldData,
    setSortedApproversList,
  } = props;
  const handleChange = (event) => {
    setDefaultApprover(event.target.value);
  };
  const [state, setState] = useState([
    {
      user_id: '62722cdff0db010f08907000',
      order: 1,
      approved: false,
      selected_index: '',
    },
    {
      user_id: '62679d2f13c301878b32c815',
      order: 0,
      approved: false,
      selected_index: '',
    },
  ]);
  const [approverList, setApproverList] = useState([]);
  useEffect(() => {
    let sorted =fieldData.disclosureSettingData && fieldData.disclosureSettingData.approvers_list.sort((x,y)=>x.order - y.order ).map((user, i) => {
      for (let z = 0; z < fieldData.currentDetailedMetric.approvers.length; z++) {
        if (user.user_id === fieldData.currentDetailedMetric.approvers[z]._id) {
          return fieldData.currentDetailedMetric.approvers[z]
        };
      }
    });
    // console.log('g', sorted);
  sorted.length>0 &&  setApproverList(sorted);
    props.fieldData.disclosureSettingData &&
      setDefaultApprover(
        props.fieldData.disclosureSettingData.approvers.toString()
      );
  }, [props.fieldData.disclosureSettingData.approvers]);
  const draggedItem = useRef(null);
  const dragdOverItem = useRef(null);
 


  const handleSort = () => {
    const usersCopy = [...approverList];
    // console.log('usersCopy 1', usersCopy);
    const draggedCont = usersCopy.splice(draggedItem.current, 1)[0];
    // console.log('draggedCont', draggedItem.current, draggedCont);
    // console.log('usersCopy 2', usersCopy);
    const draggedOverCont = usersCopy.splice(
      dragdOverItem.current,
      0,
      draggedCont
    )[0];
    // console.log('draggedOverCont', dragdOverItem.current, draggedOverCont);

    // console.log('usersCopy 3', usersCopy);
    draggedItem.current = null;
    dragdOverItem.current = null;
    let obj = {};
    usersCopy.forEach((user, i) => {
      obj[user._id] = i;
    });
    setSortedApproversList(obj);
    setApproverList(usersCopy);
  };

  return (
    <>
      <Box m={2}>
        <Box mt={4} mb={1}>
          <Typography variant="body2">Approvers</Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="caption" color="textSecondary">
            The responses tab has the control of how the responses can be seen
            and by whom.
          </Typography>
        </Box>
        <Divider />
        <Box mb={2} />
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={defaultApprover}
            onChange={handleChange}
          >
            <FormControlLabel
              value="0"
              control={<Radio />}
              label={
                <>
                  <Typography>Approve by any</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Amoung all the approver any one has to approve to complete
                    the disclosure.
                  </Typography>
                </>
              }
            />
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={
                <>
                  <Typography>Approve by All</Typography>
                  <Typography variant="caption" color="textSecondary">
                    The disclosure can be approved only when all the approvers
                    have confirmed it.
                  </Typography>
                </>
              }
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label={
                <>
                  <Typography>Approve in sequence</Typography>
                  <Typography variant="caption" color="textSecondary">
                    The approvers has to go though each one individually based
                    on their ranking.
                  </Typography>
                </>
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {defaultApprover == 2 && <Box m={2}>
        <Typography style={{ fontSize: '14px' }} color="textSecondary">
          Sequence Of Approvers
        </Typography>
        {approverList.map((user, i) => (
          <Grid container style={{ marginTop: 12 }}>
            <Grid
              style={{
                padding: '4px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Grid
                style={{
                  width: '30px',
                  height: '30px',
                  alignItems: 'center',
                  backgroundColor: '#EBF6EF',
                  textAlign: 'center',
                  borderRadius: '50%',
                }}
              >
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    padding: '5px',
                  }}
                >
                  {i + 1}
                </Typography>
              </Grid>
              <Grid
                style={{
                  position: 'relative',
                  right: '28px',
                  top: ' 35px',
                  rotate: '90deg',
                }}
              >
                <ArrowRightAltIcon style={{ color: '#EBF6EF' }} />
              </Grid>
            </Grid>
            <Paper
              draggable
              droppable
              //   onDragOver={(e) => e.preventDefault()}
              onDragEnd={(e) => {
                // console.log('drop', i);
                handleSort();
              }}
              onDragEnter={(e) => {
                // console.log('drag', i);
                dragdOverItem.current = i;
              }}
              onDragStart={(e) => {
                // console.log('start', i);
                draggedItem.current = i;
              }}
              style={{ width: '50%' }}
            >
              <Grid
                md={12}
                style={{
                  //   border: '1px solid grey',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px',cursor:'move'
                }}
              >
                <Grid md={1}>
                  <img style={{ paddingLeft: '5px' }} alt={'dragIcon'} src={dragicon} />
                  {/* <DragIndicatorIcon style={{color:'#AFAFAF'}} /> */}
                </Grid>
                <Grid md={1}>
                  <Avatar src={user.profile_img.url} alt="user" />
                </Grid>
                <Grid md={8}>
                  <Typography
                    style={{
                      fontSize: '16px',
                      padding: '0px 14px',
                      color: '#15314E',
                      fontWeight: 500,
                    }}
                  >
                    {user.name}
                  </Typography>

                  <Typography
                    color="textSecondary"
                    style={{
                      padding: '0px 14px',
                      fontSize: '12px',
                      fontWeight: 400,
                    }}
                  >
                    {user.designation}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
        <Grid container style={{ marginTop: 12, height: '50px' }}>
          <Grid
            style={{
              padding: '4px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid
              style={{
                width: '30px',
                height: '30px',
                alignItems: 'center',
                backgroundColor: '#32A05F',
                textAlign: 'center',
                borderRadius: '50%',
              }}
            >
              <DoneIcon
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  margin: '8px',
                  color: '#fff',
                }}
              />
            </Grid>
          </Grid>
          <Paper
            // draggable
            // droppable
            // //   onDragOver={(e) => e.preventDefault()}
            // onDragEnd={(e) => {
            //   console.log('drop', i);
            //   handleSort();
            // }}
            // onDragEnter={(e) => {
            //   console.log('drag', i);
            //   dragdOverItem.current = i;
            // }}
            // onDragStart={(e) => {
            //   console.log('start', i);
            //   draggedItem.current = i;
            // }}
            style={{
              width: '50%',
              marginLeft: '25px',
              backgroundColor: '#32A05F',
            }}
          >
            <Grid
              md={12}
              style={{
                //   border: '1px solid grey',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                padding: '8px',
              }}
            >
              <Grid md={12}>
                <Typography
                  style={{
                    fontSize: '16px',
                    padding: '5px',
                    color: '#fff',
                    fontWeight: 400,
                    textAlign: 'center',
                  }}
                >
                  Disclosure Approved
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Box>}
    </>
  );
}

export default Approvers;
