import React, { useState } from "react";
import {
  Box,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";

// icons
import SearchIcon from "@material-ui/icons/Search";

// redux imports
import { useDispatch } from "react-redux";
import {
  setResponseCompany,
  setSubmittedQuestions,
} from "../../../../../../../../actions/supplier/supplierAction";

// custom imports
import CustomTextfield from "../../../../../../../UI/textfield/textfield";

const DrawerLeft = ({
  selectedResponse,
  completedQuestions,
  setSelectedSection,
}) => {
  // from redux
  const dispatch = useDispatch();

  // state for search query
  const [searchQuery, setSearchQuery] = useState("");

  const handleCompanyClick = (company) => {
    dispatch(setSubmittedQuestions([], 0, false));
    dispatch(setResponseCompany(company));
    if (Boolean(setSelectedSection)) {
      setSelectedSection(company);
    }
  };

  // handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // filter completed questions based on search query
  const filteredQuestions = completedQuestions?.filter((section) =>
    section?.section_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        maxWidth: "200px",
        mt: 1,
      }}
    >
      {/* Search Component */}
      <CustomTextfield
        name="Search"
        variant="outlined"
        fullWidth
        size="small"
        type="text"
        label="Search"
        onChange={handleSearchChange}
        value={searchQuery}
        style={{ color: "#EBF1F8" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon style={{ color: "#3374B9" }} />
            </InputAdornment>
          ),
        }}
      />

      {/* Sub Companies Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "16px",
          maxHeight: "40em",
          overflowY: "scroll",
        }}
      >
        {filteredQuestions?.map((section) => (
          <Typography
            key={section?.section_id}
            onClick={() => handleCompanyClick(section, section.section_id)}
            style={{
              width: "100%",
              height: "38px",
              border: "0px 0px 1px 0px",
              borderRadius: "8px",
              padding: "8px",
              marginBottom: "10px",
              cursor: "pointer",
              backgroundColor:
                section?.section_id === selectedResponse?.section_id
                  ? "#EBF1F8"
                  : "transparent",
              fontWeight:
                section?.section_id === selectedResponse?.section_id
                  ? "600"
                  : "500",
              fontSize: "14px",
              lineHeight: "20px",
              color:
                section?.section_id === selectedResponse?.section_id
                  ? "#15314E"
                  : "#6C6C6C",
            }}
          >
            {section?.section_name}
          </Typography>
        ))}
        {filteredQuestions?.length === 0 && <CircularProgress />}
      </Box>
    </Box>
  );
};

export default DrawerLeft;
