import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box, LinearProgress } from "@material-ui/core";
import ReportSkeleton from "../../skeletons/reportSkeleton";

export default function AssessmentApprovalPDF(props) {
  const {
    login,
    assessment_id,
    assesmentApproval,
    getAssessmentPdf,
    setPdfProgress
  } = props;

  const [hideLoader, setHideLoader] = useState(false);

  const [blob, setBlob] = useState("");

  useEffect(() => {
    if (
      assesmentApproval.pdfProgress[assessment_id] === 0 ||
      !assesmentApproval.pdfProgress.hasOwnProperty(assessment_id)
    ) {
      getAssessmentPdf(
        login.token,
        assessment_id,
        assesmentApproval.jwt_hash,
        4
      );
    }
  }, [assessment_id, assesmentApproval.pdfProgress[assessment_id]]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      if (assesmentApproval.pdfProgress[assessment_id] === 100) {
        setPdfProgress({ id: assessment_id, progress: 0 });
      }
    };
  }, [assesmentApproval.pdfProgress]);

  useEffect(() => {
    if (assesmentApproval.pdfProgress[assessment_id] === 100) {
      setTimeout(() => {
        setHideLoader(true);
      }, 500);
    }
  }, [assesmentApproval.pdfProgress[assessment_id]]);

  useEffect(() => {
    setBlob(assesmentApproval.pdfBlob[assessment_id]);
  }, [login.token, assessment_id, assesmentApproval.pdfBlob[assessment_id]]);

  const LinearProgressWithLabel = (props) => {
    return (
      <Box display="flex" alignItems="center" paddingBottom="8px">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {props !== undefined && `${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Grid container spacing={2} style={{ height: 550 }}>
        <Grid item xs={12}>
          <>
            {!hideLoader && (
              <>
                <LinearProgressWithLabel
                  value={assesmentApproval.pdfProgress[assessment_id]}
                />
              </>
            )}
            {assesmentApproval.assessmentSkeletons ||
            blob === "" ||
            !hideLoader ? (
              <ReportSkeleton />
            ) : (
              <iframe src={blob} title="pdf" width="100%" height="100%" />
            )}
          </>
        </Grid>
      </Grid>
    </>
  );
}
