import {
  SET_SELECTED_MENU,
  SET_USER_IMAGE,
  SET_USER_NAME,
  SET_USER_POSITION,
  SET_USER_CONTACT,
  SET_USER_DISTRICT,
  SET_USER_LOCATION,
  SET_USER_PINCODE,
  SET_USER_STATE,
  SET_COMPANY_INFO,
  SET_SECTORS,
  SET_BRANCH_ADDRESS,
  UNSET_ADDRESS,
  PUSH_BRANCH_ADDRESS,
  SET_ALL_USERS,
  SET_MASTER_SECTOR,
  SET_SELECTED_SECTORS,
  SET_SUBSCRIPTION_DETAILS,
  SET_INVOICE_DETAILS,SET_PLAN_AMOUNT
} from '../../constants/generalSettings/generalSettings';

const initial_state = {
  selectedMenu: '',
  userName: '',
  userPosition: '',
  userImage: '',
  userLocation: '',
  userDistrict: '',
  userPincode: '',
  userState: '',
  userContact: '',
  companyInfo: {},
  sectors: [],
  branchAddresses: [],
  allUsers: [],
  masterSectors: [],
  selectedSectors: [],
  razorpay:'',
  razorpay_invoice:'',
  planAmount:0
};

export default function reducer(state = initial_state, action) {

  switch (action.type) {
    case SET_PLAN_AMOUNT:
      return (state = { ...state, planAmount:action.payload });
    case SET_SELECTED_MENU:
      return (state = { ...state, selectedMenu: action.payload });
    case SET_USER_NAME:
      return (state = { ...state, userName: action.payload });
    case SET_USER_POSITION:
      return (state = { ...state, userPosition: action.payload });
    case SET_USER_IMAGE:
      return (state = { ...state, userImage: action.payload });
    case SET_USER_LOCATION:
      return (state = { ...state, userLocation: action.payload });
    case SET_USER_DISTRICT:
      return (state = { ...state, userDistrict: action.payload });
    case SET_USER_PINCODE:
      return (state = { ...state, userPincode: action.payload });
    case SET_USER_STATE:
      return (state = { ...state, userState: action.payload });
    case SET_USER_CONTACT:
      return (state = { ...state, userContact: action.payload });
    case SET_COMPANY_INFO:
      return (state = { ...state, companyInfo: action.payload });
    case SET_SECTORS:
      return (state = { ...state, sectors: action.payload });
    case SET_MASTER_SECTOR:
      return (state = { ...state, masterSectors: action.payload });
    case SET_SELECTED_SECTORS:
      return (state = { ...state, selectedSectors: action.payload });
    case SET_BRANCH_ADDRESS:
      return (state = { ...state, branchAddresses: action.payload });
    case SET_SUBSCRIPTION_DETAILS:
      return (state = { ...state, razorpay:action.payload });
    case SET_INVOICE_DETAILS:
      return (state = { ...state, razorpay_invoice:action.payload });
    case UNSET_ADDRESS:
      state.branchAddresses.splice(action.payload, 1);
      return (state = { ...state });
    case PUSH_BRANCH_ADDRESS:
      state.branchAddresses.push(action.payload);
      return (state = { ...state });
    case SET_ALL_USERS:
      return (state = { ...state, allUsers: action.payload });
    default:
      return state;
  }
}
