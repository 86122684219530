import React from "react";
import { connect } from "react-redux";
import {
  createAssessment,
  viewAssessments,
  setKeyword,
  changePasswordValidation,
  viewActivityLog,
  setCurrentStep,
  setRedirect,
  getOrganizations,
  updateAssessmentName,
  deleteAssessments,
  duplicateReport,
  setAssessmentsPaginationPage
} from "../../actions/surveyListing/surveyListingAction";

import { setPdfBlob } from "../../actions/assessmentApproval/assessmentApproval";
import { setPdfBlobs } from "../../actions/sustainabilityReport/sustainabilityReportAction";
import { setActiveStep } from "../../actions/sustainabilityReport/sustainabilityReportAction";
import SurveyListingScreen from "../../components/screen/surveyListing/surveyListing";
import { updateReportName } from "../../actions/assessmentApproval/assessmentApproval";
import {
  getHelpFlow,
  updateHelpFlow,
  setLogin
} from "../../actions/login/loginActions";
import { getAllCountries } from "../../actions/basicinfoAction/basicinfoAction";
export class SurveyListingCont extends React.PureComponent {
  componentWillUnmount() {
    if (window?.location?.pathname !== "/sustainability_report") {
      this.props.setPaginationPage(0);
    }
  }
  render() {
    return <SurveyListingScreen {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    login: store.login,
    surveyListing: store.surveyListing
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    updateReportName: (token, assessment_id, step_no, report_name) => {
      dispatch(updateReportName(token, assessment_id, step_no, report_name));
    },
    createAssessment: (token, organisation_id) => {
      dispatch(createAssessment(token, organisation_id));
    },

    viewAssessments: (
      token,
      limit,
      startingAfter,
      surveyListing,
      current_organisation,
      keyword,
      is_ascending
    ) => {
      dispatch(
        viewAssessments(
          token,
          limit,
          startingAfter,
          surveyListing,
          current_organisation,
          keyword,
          is_ascending
        )
      );
    },
    setKeyword: (value) => {
      dispatch(setKeyword(value));
    },
    changePasswordValidation: (token, password, name, organisation_id) => {
      dispatch(
        changePasswordValidation(token, password, name, organisation_id)
      );
    },
    viewActivityLog: (user_token, assessment_id, step_no) => {
      dispatch(viewActivityLog(user_token, assessment_id, step_no));
    },
    setCurrentStep: (payload) => {
      dispatch(setCurrentStep(payload));
    },
    setRedirect: (payload) => {
      dispatch(setRedirect(payload));
    },
    setActiveStep: (step) => {
      dispatch(setActiveStep(step));
    },
    getOrganizations: (payload,domain_name) => {
      dispatch(getOrganizations(payload,domain_name));
    },
    updateAssessmentName: (
      token,
      assessment_id,
      assessment_name,
      surveyListing,
      limit,
      startingAfter,
      is_ascending,
      current_organisation
    ) => {
      dispatch(
        updateAssessmentName(
          token,
          assessment_id,
          assessment_name,
          surveyListing,
          limit,
          startingAfter,
          is_ascending,
          current_organisation
        )
      );
    },
    deleteAssessments: (
      token,
      assessment_id,
      surveyListing,
      current_organisation,
      limit,
      startingAfter,
      is_ascending
    ) => {
      dispatch(
        deleteAssessments(
          token,
          assessment_id,
          surveyListing,
          current_organisation,
          limit,
          startingAfter,
          is_ascending
        )
      );
    },
    duplicateReport: (
      token,
      assessment_id,
      report_selected,
      surveyListing,
      assessment_name,
      limit,
      startingAfter,
      is_ascending,
      current_organisation
    ) => {
      dispatch(
        duplicateReport(
          token,
          assessment_id,
          report_selected,
          surveyListing,
          assessment_name,
          limit,
          startingAfter,
          is_ascending,
          current_organisation
        )
      );
    },
    getHelpFlow: (token) => {
      dispatch(getHelpFlow(token));
    },
    getAllCountries: (token) => {
      dispatch(getAllCountries(token));
    },
    updateHelpFlow: (token, step) => {
      dispatch(updateHelpFlow(token, step));
    },
    setPdfBlob: (payload) => {
      dispatch(setPdfBlob(payload));
    },
    setPdfBlobs: (payload) => {
      dispatch(setPdfBlobs(payload));
    },
    setLogin: (payload) => {
      dispatch(setLogin(payload));
    },
    setPaginationPage: (payload) => {
      dispatch(setAssessmentsPaginationPage(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyListingCont);
