import { makeStyles } from '@material-ui/core';

const esgLeftStyles = makeStyles((theme) => ({
    cardContainer: {
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        height: "100%",
      },
      search: {
        width: "90%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      search2: {
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      icon: {
        color: "transparent",
      },
      label: {
        color: "rgba(51, 116, 185, 1)",
      },
      mainTopicContainer: {
        display: "flex",
        alignItems: "center",
        padding: 10,
      },
      mainTopicText: {
        fontWeight: "600",
        fontSize: 16,
        lineHeight: "24px",
        paddingLeft: "20px",
      },
      environmentBorder: {
        backgroundColor: "#DAF1E4",
        height: 4,
        width: "100%",
      },
      socialBorder: {
        backgroundColor: "#EAD6F1",
        height: 4,
        width: "100%",
      },
      governanceBorder: {
        backgroundColor: "#D6E3F1",
        height: 4,
        width: "100%",
      },
      businessBorder: {
        backgroundColor: "#F1D6D6",
        height: 4,
        width: "100%",
      },
      humanBorder: {
        backgroundColor: "#F6F3DA",
        height: 4,
        width: "100%",
      },
      topicCardBoxShadow: {
        boxShadow: "0px 0px 24px rgba(51, 116, 185, 0.16)",
        "&:first-child": {
          marginTop: "32px",
        },
        marginLeft: "16px",
        marginRight: "16px",
        marginBottom: "24px",
      },
      topicCardContent: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 24,
        },
      },
      topicContainer: {
        display: "flex",
        alignItems: "center",
        padding: 15,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#D6E3F173",
        },
      },
      topicText: {
        fontWeight: "600",
        marginLeft: 10,
        fontSize: "16px",
        lineHeight: "24px",
      },
      box:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "50px",
      },
      typography:{
        color: " #9FA1AF",
        fontSize: "21px",
        fontWeight: "600",
        lineHeight: "32px",
      }
}));

export default esgLeftStyles;
