import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import DataHubBreadCrumbs from "./dataHubBreadCrumbs";
import DataHubTabs from "./dataHubTabs";
import DataHubButtons from "./dataHubButtons";
import DataHubSurvey from "../../widgets/dataHubSurvey/dataHubSurvey";
import DatahubApi from "../../widgets/dataHubApi/datahub_API";
import DataHubSheets from "../../widgets/dataHubSheets/dataHubSheets";
import DataSetsMain from "../../widgets/dataHubDataSets/DataSetsMain";
import { Box } from "@material-ui/core";

const TABS_ARR = [
  { value: 0, tab: "Sheets Import" },
  { value: 1, tab: "API Integration" },
  { value: 2, tab: "Survey" },
  { value: 3, tab: "Data Sets" },
];

function DataHubScreen(props) {
  const {
    match,
    history,
    login,
    addTags,
    dataHub,
    getTags,
    uploadImportFile,
    getImportFileList,
    deleteTags,
    getImportFileData,
    getMatatikaJobStatus,
    restartMatatikaJob,
  } = props;

  const pathNames = props.location.pathname.split("/").filter((x) => x);

  const { params } = match;
  const { page } = params;
  const { token, selectedOrganization } = login;
  const tabNameToIndex = {
    0: "sheets_import",
    1: "api_integration",
    2: "survey",
    3: "data_sets",
  };
  const indexToTabName = {
    sheets_import: 0,
    api_integration: 1,
    survey: 2,
    data_sets: 3,
  };

  const [selectedTab, setSelectedTab] = React.useState(indexToTabName[page]);
  const [tabName, setTabName] = React.useState("Sheets Import");
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isIntegrationDrawerOpen, setIsIntegrationDrawerOpen] = useState(false);

  const handleChange = (event, newValue) => {
    history.push(`/data_hub/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
    setTabName(event.target.innerText);
  };

  const uploadHandler = (e) => {
    // const file = e.target.files[0];
    // file !== undefined && setUploadFile((preValue) => [...preValue, file]);
    if (selectedTab === 0) {
      setIsDrawerOpen((preValue) => !preValue);
      !isDrawerOpen && getTags(token, selectedOrganization._id);
    } else if (selectedTab === 1) {
      setIsIntegrationDrawerOpen((preValue) => !preValue);
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Box
        // component="div"
        style={{
          backgroundColor: "#F3F4F4",
          minHeight: "91vh",
          margin: "-10px",
        }}
      >
        <DataHubBreadCrumbs tabName={tabName} pathNames={pathNames} />

        <Grid container alignItems="center">
          <Grid
            item
            md={7}
            xs={12}
            sm={12}
            style={{  marginTop: "10px", paddingLeft:"20px" }}
          >
            <DataHubTabs
              handleTabs={handleChange}
              value={selectedTab}
              tabs={TABS_ARR}
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <DataHubButtons uploadHandler={uploadHandler} value={selectedTab} />
          </Grid>
        </Grid>

        {selectedTab === 0 && (
          <DataHubSurvey
            login={login}
            addTags={addTags}
            dataHub={dataHub}
            isDrawerOpen={isDrawerOpen}
            handleDrawer={uploadHandler}
            uploadImportFile={uploadImportFile}
            getImportFileList={getImportFileList}
            deleteTags={deleteTags}
            getImportFileData={getImportFileData}
            getMatatikaJobStatus={getMatatikaJobStatus}
            restartMatatikaJob={restartMatatikaJob}
          />
        )}
        {selectedTab === 1 && (
          <DatahubApi
            handleDrawer={uploadHandler}
            isIntegrationDrawerOpen={isIntegrationDrawerOpen}
            setIsIntegrationDrawerOpen={setIsIntegrationDrawerOpen}
          />
        )}
        {/* Datahub Survey */}
        {selectedTab === 2 && <DataHubSheets />}
        {selectedTab === 3 && <DataSetsMain />}
      </Box>
    </Container>
  );
}

export default React.memo(DataHubScreen);
