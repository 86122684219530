import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import ProgressHeader from "./RightComps/ProgressHeader";
import SurveySuccess from "../../../../../images/SurveySuccess.svg";

const SectionComplete = ({ progress, total_questions, currentQues, activeTab }) => {
  // console.log(totalQues);
  return (
    <Box display={"flex"} flexDirection={"column"} sx={{ gap: 20 }}>
      {/* Header */}
      <ProgressHeader
        title={"Section Complete"}
        sub={"Thank you"}
        progress={progress}
        totalQues={total_questions}
        currentQues={currentQues}
      />

      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        sx={{ gap: 10 }}
      >
        <img
          src={SurveySuccess}
          alt="SurveyComplete"
          style={{ aspectRatio: "1:1", width: "35%" }}
        />
        <Typography
          style={{
            color: "#0F0F0F",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          This Section has been Saved.
        </Typography>
        <Typography
          style={{
            color: "#0F0F0F",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          If you wish to redo the survey please use the redo button. Or go back
          to the task section.
        </Typography>
      </Box>
    </Box>
  );
};
export default SectionComplete;
