import React from "react";
import { connect } from "react-redux";
import {
  getAssignedMaturityMatrix,
  getMaturityMatrix,
  setMaturityMatrixTaskQuestions,
  updateMaturityMatrix
} from "../../actions/maturityMatrix/maturityMatrixActions";
import SurveyTask from "../../components/UI/maturityMatrix/SurveyTask";

class MaturityMatrixTaskCont extends React.PureComponent {
  render() {
    return <SurveyTask {...this.props} />;
  }
}

const mapStateToProps = (store) => ({
  login: store.login,
  materialityAssessment: store.materialityAssessment,
  maturityMartix: store.maturityMartix,
  basicInfo: store.basicInfo
});

const mapDispatchToProps = (dispatch) => ({
  getMaturityMatrix: (token) => {
    dispatch(getMaturityMatrix(token));
  },
  setMaturityMatrixTaskQuestions: (token) => {
    dispatch(setMaturityMatrixTaskQuestions(token));
  },
  updateMaturityMatrix: (
    token,
    asessment_id,
    q_id,
    value,
    comment,
    question_no,
    organisation_id
  ) => {
    dispatch(
      updateMaturityMatrix(
        token,
        asessment_id,
        q_id,
        value,
        comment,
        question_no,
        organisation_id
      )
    );
  },
  getAssignedMaturityMatrix: (token, assessment_id) => {
    dispatch(getAssignedMaturityMatrix(token, assessment_id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaturityMatrixTaskCont);
