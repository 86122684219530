import React from "react";
import {
  Paper,
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  Container,
} from "@material-ui/core";
import DynamicTable from "components/UI/dynamicTable/DynamicTable";

const AnswersTable = ({ submittedAnswer, headers, columnNames }) => {
  return (
    <div style={{ overflowX: "auto", marginTop: "20px" }}>
      <Container component={Paper}>
        <DynamicTable headings={columnNames}>
          {submittedAnswer?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row?.map(({ colIndex, colName, data }, cellIndex) => (
                <TableCell key={cellIndex} style={{ minWidth: 150 }}>
                  <Box>
                    {headers[colIndex]?.type === "Document" ? (
                      <a
                        href={data?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data?.name || "-"}
                      </a>
                    ) : (
                      headers[colIndex]?.type !== "Document" &&
                      colIndex === cellIndex && (
                        <Typography>{data || "-"}</Typography>
                      )
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </DynamicTable>
      </Container>
    </div>
  );
};

export default AnswersTable;
