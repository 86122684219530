import { makeStyles } from "@material-ui/core";

 const generalSettingFormStyles =makeStyles(()=>({
    Typography:{
        paddingBottom: 5,
        borderBottom: "3px solid green",
        display: "flex",
        justifyContent: "center",
      },
      Typography1:{ fontWeight: "600", fontSize: 26 },
      CustomTextfield:{ width: 300 },
      CustomTextfield1:{ width: 350 },
      InputAdornment:{ cursor: "pointer" },
      CustomButton:{ paddingLeft: 50, paddingRight: 50 },
      grid:{ marginTop: 80 }, 
 }))
 export default generalSettingFormStyles