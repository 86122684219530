import React, { useState } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { makeStyles } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Link } from "react-router-dom";
import moment from "moment";

import CustomChip from "../../UI/chip/chip";

const drawerWidth = 400;

const useStyles = makeStyles((theme) => ({
  textFields: {
    width: "80%",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  table: {
    minWidth: 600,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  assignment_row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F0F6FD",
    },
  },
}));

export default function ReportListing(props) {
  const {
    surveyListing,
    token,
    currentOrganisation,
    viewAssignedAssessments,
    limit,
    startingAfter,
  } = props;
  const classes = useStyles();

  const [showAscDecIcon, setShowAscDecIcon] = useState(false);
  const [ascOrDes, setAscOrDes] = useState(true);

  const handleSorting = () => {
    setShowAscDecIcon(!showAscDecIcon);
    setAscOrDes(!ascOrDes);
    viewAssignedAssessments(
      token,
      limit,
      startingAfter,
      surveyListing,
      currentOrganisation,
      surveyListing.keyword,
      ascOrDes
    );
  };

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        style={{ marginLeft: "1rem" }}
      >
        <Grid
          item
          xs={10}
          style={{ marginTop: 40 }}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: props.open,
          })}
        >
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: "0px" }}>
                    <List>
                      <ListItem>
                        <Typography variant="h6" style={{ fontSize: 16 }}>
                          Recent documents
                        </Typography>
                      </ListItem>
                    </List>
                  </TableCell>
                  <TableCell
                    style={{ padding: "0px", cursor: "pointer" }}
                    onClick={handleSorting}
                  >
                    <List>
                      <ListItem>
                        <Typography
                          variant="h6"
                          style={{ fontSize: 16, paddingRight: "8px" }}
                        >
                          Last Modified
                        </Typography>
                        {showAscDecIcon ? (
                          <ArrowUpwardIcon
                            style={{
                              fontSize: "16px",
                              color: "#000000",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            style={{
                              fontSize: "16px",
                              color: "#000000",
                            }}
                          />
                        )}
                      </ListItem>
                    </List>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" style={{ fontSize: 16 }}>
                      Edited by
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" style={{ fontSize: 16 }}>
                      Status
                    </Typography>
                  </TableCell>
               
                </TableRow>
              </TableHead>

              <TableBody>
                {props.surveyListing.assignedAssessments.map((s, index) => {
                  let currentDate = new Date();
                  let myDate = new Date(s.modified_on);
                  let createdDate =
                    myDate.getDate() +
                    "." +
                    (myDate.getMonth() + 1) +
                    "." +
                    myDate.getFullYear();
                  let diff = Math.abs(
                    new Date(moment(currentDate).format("MM/DD/YYYY")) -
                      new Date(moment(myDate).format("MM/DD/YYYY"))
                  );
                  let diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
                  let assignmentIdName = `Assessment - ${s.assessment_id.replace(
                    /\w(?=\w{4})/g,
                    ""
                  )}`;
                  return (
                    <TableRow key={index} className={classes.assignment_row}>
                      <TableCell style={{ padding: "0px" }}>
                        <Link
                          to={{
                            pathname: "/report_listing/esg_report",
                            state: {
                              assessment_id: s.assessment_id,
                              assessment_name: s.assessment_name,
                              framework_id:s.framework_id
                            },
                          }}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            cursor: "pointer",
                          }}
                        >
                          <ListItem>
                            <Icon fontSize="small">feed</Icon>
                            <Typography
                              variant="h6"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                                fontSize: 14,
                              }}
                            >
                              {s.assessment_name === ""
                                ? assignmentIdName
                                : s.assessment_name}
                          
                            </Typography>
                          </ListItem>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{
                            pathname: "/report_listing/esg_report",
                            state: { assessment_id: s.assessment_id },
                          }}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            cursor: "pointer",
                          }}
                        >
                          {s.modified_on !== ""
                            ? createdDate + " - " + diffDays + " day(s) ago"
                            : "NA"}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{
                            pathname: "/report_listing/esg_report",
                            state: { assessment_id: s.assessment_id },
                          }}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            cursor: "pointer",
                          }}
                        >
                          {s.modified_by}
                        
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{
                            pathname: "/report_listing/esg_report",
                            state: { assessment_id: s.assessment_id },
                          }}
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            cursor: "pointer",
                          }}
                        >
                          <CustomChip
                            variant="outlined"
                            label={s.completed ? "Completed" : "In Progress"}
                            color={!s.completed ? "secondary" : "primary"}
                            avatar={
                              <Avatar
                                style={{
                                  color: !s.completed ? "#FFC250" : "#32A05F",
                                  backgroundColor: !s.completed
                                    ? "#FFC250"
                                    : "#32A05F",
                                  height: "12px",
                                  width: "12px",
                                  marginLeft: "10px",
                                }}
                              ></Avatar>
                            }
                          />
                        </Link>
                      </TableCell>
                     
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
