import encrypt from "../shared/sharedActions";
import UNIVERSAL from "../../config/config";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import {
  SET_CONTROVERSY_TAB_NO,
  SET_COUNTRIES,
  SET_DECLARATION_TAB_NO,
  SET_PROFILE_TAB_NO,
  SET_SECTOR_INDUSTRIES,
  SET_SELECTED_SECTOR_INDUSTRIES,
  SET_CREATE_DRAWER_STATE,
  SET_SUPPLIER_ORGANIZATIONS,
  SET_SUPPLIER_ORGANIZATIONS_DETAILS,
  SET_MEMBERS,
  SET_SUPPLIER_ORG_TOTAL,
} from "../../constants/brmConstants/brmConstants";
import { setAllUsers } from "actions/generalSettings/generalSettingsAction";
import { getSupplierEsgRating } from "actions/supplierAdmin/supplierAdminActions";
// import { viewMember } from "../member/member";

// new action
export function setCreateDrawerState(payload) {
  return {
    type: SET_CREATE_DRAWER_STATE,
    payload: payload,
  };
}

export function setSupplierOrganizations(payload) {
  return {
    type: SET_SUPPLIER_ORGANIZATIONS,
    payload: payload,
  };
}

export function setSupplierOrgDetails(payload) {
  return {
    type: SET_SUPPLIER_ORGANIZATIONS_DETAILS,
    payload: payload,
  };
}

export function createOrganization(
  token,
  organization_id,
  isin,
  name,
  supplierWebsite,
  sector_id,
  industry_id,
  country,
  website,
  operation,
  no_of_employees,
  company_address,
  revenue,
  turnover,
  logo
) {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      organization_id,
      isin,
      name,
      sector_id,
      industry_id,
      country,
      website,
      operation,
      no_of_employees,
      company_address,
      revenue,
      turnover,
    });

    var formData = new FormData();
    formData.append("data", data);
    formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/supplier/create_supplier", {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "content-type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          const limit = 7;
          const starting_after = 0;

          dispatch(
            viewSupplierOrganization(
              token,
              organization_id,
              "",
              limit,
              starting_after
            )
          );
          // dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function setSupplierOrgTotal(payload) {
  return {
    type: SET_SUPPLIER_ORG_TOTAL,
    payload: payload,
  };
}

export function viewSupplierOrganization(
  token,
  organization_id,
  keyword,
  limit,
  starting_after
) {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      organization_id,
      keyword,
      limit,
      starting_after,
    });

    // var formData = new FormData();
    // formData.append("data", data);
    // formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/supplier/view_suppliers", {
      // method: "POST",
      // // headers: {
      // //   Accept: "application/json",
      // //   "content-type": "application/json",
      // // },
      // body: formData,
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSupplierOrganizations(responseJson?.result));
          dispatch(setSupplierOrgTotal(responseJson?.total_suppliers));
          // dispatch(viewOrganization(token, bankId, rowsPerPage, 0));
          // dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getSupplierOrgDetails(token, supplier_id) {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      supplier_id,
    });

    // var formData = new FormData();
    // formData.append("data", data);
    // formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/supplier/get_supplier_details", {
      // method: "POST",
      // // headers: {
      // //   Accept: "application/json",
      // //   "content-type": "application/json",
      // // },
      // body: formData,
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSupplierOrgDetails(responseJson?.result));
          // dispatch(setSupplierOrganizations(responseJson?.result));
          // dispatch(viewOrganization(token, bankId, rowsPerPage, 0));
          // dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateSupplierOrganization(
  token,
  supplier_id,
  isin,
  name,
  sector_id,
  industry_id,
  country,
  website,
  operation,
  no_of_employees,
  company_address,
  revenue,
  turnover,
  logo
) {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      supplier_id,
      isin,
      name,
      sector_id,
      industry_id,
      country,
      website,
      operation,
      no_of_employees,
      company_address,
      revenue,
      turnover,
    });

    var formData = new FormData();
    formData.append("data", data);
    formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/supplier/update_supplier", {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "content-type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getSupplierOrgDetails(token, supplier_id));
          // dispatch(viewSupplierOrganization(token, organization_id, ""));
          // dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getCompanyDocuments(token, supplier_id, organization_id) {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
    });

    // var formData = new FormData();
    // formData.append("data", data);
    // formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/supplier/get_company_documents", {
      // method: "POST",
      // // headers: {
      // //   Accept: "application/json",
      // //   "content-type": "application/json",
      // // },
      // body: formData,
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson);
          // dispatch(setSupplierOrgDetails(responseJson?.result));
          // dispatch(setSupplierOrganizations(responseJson?.result));
          // dispatch(viewOrganization(token, bankId, rowsPerPage, 0));
          // dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function submitEsgRating(
  token,
  supplier_id,
  organization_id,
  risk_score,
  comment
) {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      risk_score,
      comment,
    });

    // var formData = new FormData();
    // formData.append("data", data);
    // formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/supplier/submit_esg_rating", {
      // method: "POST",
      // // headers: {
      // //   Accept: "application/json",
      // //   "content-type": "application/json",
      // // },
      // body: formData,
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getSupplierEsgRating(token, supplier_id, organization_id));
          // dispatch(setSupplierOrgDetails(responseJson?.result));
          // dispatch(setSupplierOrganizations(responseJson?.result));
          // dispatch(viewOrganization(token, bankId, rowsPerPage, 0));
          // dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function viewSupplierStakeHolder(token, supplier_id, organization_id) {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
    });

    // var formData = new FormData();
    // formData.append("data", data);
    // formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/supplier_user/view_stakeholders", {
      // method: "POST",
      // // headers: {
      // //   Accept: "application/json",
      // //   "content-type": "application/json",
      // // },
      // body: formData,
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch({
            type: SET_MEMBERS,
            payload: responseJson?.result,
          });

          dispatch(setAllUsers(responseJson.result));

          // dispatch(setSupplierOrgDetails(responseJson?.result));
          // dispatch(setSupplierOrganizations(responseJson?.result));
          // dispatch(viewOrganization(token, bankId, rowsPerPage, 0));
          // dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function deleteSupplierStakeHolder(token, user_id) {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      user_id,
    });

    // var formData = new FormData();
    // formData.append("data", data);
    // formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/supplier_user/remove_users ", {
      // method: "POST",
      // // headers: {
      // //   Accept: "application/json",
      // //   "content-type": "application/json",
      // // },
      // body: formData,
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch({
            type: SET_MEMBERS,
            payload: responseJson?.result,
          });

          // dispatch(setAllUsers(responseJson.result));

          // dispatch(setSupplierOrgDetails(responseJson?.result));
          // dispatch(setSupplierOrganizations(responseJson?.result));
          // dispatch(viewOrganization(token, bankId, rowsPerPage, 0));
          // dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function addSupplierStakeHolder(
  token,
  supplier_id,
  organization_id,
  name,
  email,
  designation,
  category,
  organization_name
) {
  return (dispatch) => {
    dispatch(set_loader());
    const url = window.location.origin;
    const domain_name = window.location.hostname;
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      name,
      email,
      designation,
      category,
      organization_name,
      url,
      domain_name,
    });

    // var formData = new FormData();
    // formData.append("data", data);
    // formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/supplier_user/add_stakeholder", {
      // method: "POST",
      // // headers: {
      // //   Accept: "application/json",
      // //   "content-type": "application/json",
      // // },
      // body: formData,
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            viewSupplierStakeHolder(token, supplier_id, organization_id)
          );
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function shareSurveySupplier(
  token,
  supplier_id,
  organization_id,
  survey_id,
  user_ids,
  frequency_type,
  version,
  shared_survey_date,
  survey_sent_time,
  reminder,
  is_default = false,
  frequency_count
) {
  return (dispatch) => {
    dispatch(set_loader());
    const url = window.location.origin;
    const domain_name = window.location.hostname;
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      survey_id,
      user_ids,
      frequency_type,
      version,
      shared_survey_date,
      survey_sent_time,
      reminder,
      is_default,
      frequency_count,
      url,
      domain_name,
    });

    // var formData = new FormData();
    // formData.append("data", data);
    // formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/supplier_user/share_survey", {
      // method: "POST",
      // // headers: {
      // //   Accept: "application/json",
      // //   "content-type": "application/json",
      // // },
      // body: formData,
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // if (responseJson.status) {

        // }
        dispatch(unset_loader());
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function requestFileSupplier(
  token,
  supplier_id,
  organization_id,
  user_ids,
  document_type
) {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      user_ids,
      document_type,
    });

    // var formData = new FormData();
    // formData.append("data", data);
    // formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/supplier_user/request_file", {
      // method: "POST",
      // // headers: {
      // //   Accept: "application/json",
      // //   "content-type": "application/json",
      // // },
      // body: formData,
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // if (responseJson.status) {

        // }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

// export function getSupplier StakeHolders(token, supplier_id, organization_id) {
//   return (dispatch) => {
//     dispatch(set_loader());

//     const data = encrypt({
//       "user-token": token,
//       supplier_id,
//       organization_id,
//     });

//     // var formData = new FormData();
//     // formData.append("data", data);
//     // formData.append("logo", logo);
//     return fetch(UNIVERSAL.BASEURL + "/supplier/get_company_documents", {
//       // method: "POST",
//       // // headers: {
//       // //   Accept: "application/json",
//       // //   "content-type": "application/json",
//       // // },
//       // body: formData,
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "content-type": "application/json",
//       },
//       body: JSON.stringify({
//         data: data,
//       }),
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.status) {
//           console.log(responseJson);
//           // dispatch(setSupplierOrgDetails(responseJson?.result));
//           // dispatch(setSupplierOrganizations(responseJson?.result));
//           // dispatch(viewOrganization(token, bankId, rowsPerPage, 0));
//           // dispatch(setOrganizationDetails(responseJson.result));
//         }
//         dispatch(unset_loader());
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
// }
// copy / older version

export const setCorporateAnswers = (payload) => {
  return {
    // type: SET_CORPORATE_ANSWERS,
    payload,
  };
};

export const shareSurvey = (token, organization_id, email) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id,
      email,
      url: "http://localhost:3001/corporate_questions",
    });

    return fetch(UNIVERSAL.BASEURL + "/assign_member/assign_member", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(viewMember(token, organization_id));
        }
        dispatch(unset_loader);
      });
  };
};
export function setBrmMainTabNo(payload) {
  return {
    type: "SET_BRM_MAIN_TAB_NO",
    payload: payload,
  };
}
export function setBrmSubTabNo(payload) {
  return {
    type: "SET_BRM_SUB_TAB_NO",
    payload: payload,
  };
}
export function setDeclarationTabNo(payload) {
  return {
    type: SET_DECLARATION_TAB_NO,
    payload: payload,
  };
}
export function setControversyTabNo(payload) {
  return {
    type: SET_CONTROVERSY_TAB_NO,
    payload: payload,
  };
}
export function setProfileTabNo(payload) {
  return {
    type: SET_PROFILE_TAB_NO,
    payload: payload,
  };
}

export const addCompanyBrm = (payload) => {
  return {
    type: "ADD_COMPANY_BRM",
    payload: payload,
  };
};
export const setCorporateAns = (payload) => {
  return {
    type: "SET_CORPORATE_ANS",
    payload: payload,
  };
};

export function setSectorIndustries(payload) {
  return {
    type: SET_SECTOR_INDUSTRIES,
    payload: payload,
  };
}
export function setSelectedSectorIndustries(payload) {
  return {
    type: SET_SELECTED_SECTOR_INDUSTRIES,
    payload: payload,
  };
}
export function setCountries(payload) {
  return {
    type: SET_COUNTRIES,
    payload: payload,
  };
}

export function getSectorIndustries(token) {
  const data = encrypt({
    "user-token": token,
  });

  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/organization/get_sector_industries", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSectorIndustries(responseJson.result));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function getCountries(token) {
  const data = encrypt({
    "user-token": token,
  });

  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/organization/get_countries", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCountries(responseJson.result));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
