import React from 'react';
import PropTypes from 'prop-types';
import {
  createTheme,
  makeStyles,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import stepperUseStyles from './stepperStyle'


const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    fontWeightRegular: 400
  }
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 9,
    left: 'calc(-50% + 4px)',
    right: 'calc(50% + 4px)'
  },
  active: {
    '& $line': {
      borderColor: '#F0F0F4'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#F0F0F4'
    }
  },
  line: {
    borderColor: '#F0F0F4',
    borderTopWidth: 6,
    borderRadius: 6,
    [theme.breakpoints.down('sm')]: {
      width: 0
    }
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: '#fff'
  },
  activeCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    padding: 2,
    zIndex: 1,
    borderRadius: '50%',
    borderColor: '#F0F0F4',
    borderWidth: 6,
    borderStyle: 'solid',
    backgroundColor: '#3374B9'
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#3374B9'
  },
  completed: {
    color: '#fff',
    backgroundColor: '#32A05F',
    zIndex: 1,
    borderRadius: '50%',
    borderColor: '#F0F0F4',
    borderWidth: 6,
    borderStyle: 'solid'
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed, icon } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed && <Check className={classes.completed} />}

      {active && <div className={classes.activeCircle}>{icon}</div>}

      {!completed && !active && <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool
};

function getSteps() {
  return [
    'Basic Information',
    'Materiality Assessment',
    'ESG Plan',
    'Assessment Approval',
    'Reporting Data',
    'Sustainability Report'
  ];
}

function CustomStepper(props) {
  const classes = stepperUseStyles();
  const steps = getSteps();
  const handlestep = (step) => {
    props.setActiveStep(step);
  };

  const {
    fieldDataIsUpsert,
    materialityAssessmentIsUpsert,
    esgStrategyIsUpsert,
    assessment_id,
    activeStep,
    login,
    basicInfo,
    updateBasicInfo,
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Stepper
          style={{
            backgroundColor: 'transparent',
            paddingBottom: 0,
            paddingTop: 5
          }}
          activeStep={props.activeStep}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                id="step_value"
                StepIconComponent={QontoStepIcon}
                classes={{
                  active: classes.step_label_active,
                  labelContainer: classes.step_label_container
                }}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (
                    !fieldDataIsUpsert &&
                    !materialityAssessmentIsUpsert &&
                    !esgStrategyIsUpsert
                  ) {
                    handlestep(index);
                  }
                  if (label === 'Materiality Assessment' && activeStep === 1) {
                    updateBasicInfo(
                      assessment_id,
                      basicInfo,
                      login.token,
                      activeStep,
                      true
                    );
                  }
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </ThemeProvider>
  );
}
export default React.memo(CustomStepper)
