import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import { DialogContent, Select } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import useDebounce from "../../../../../customHooks/useDebouncec";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Compact } from "@uiw/react-color";
import { CustomDataInput } from "./SelectChartType";
import CloseIcon from "@material-ui/icons/Close";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const [hex, setHex] = React.useState("#fff");
  const [xValue, setXvalue] = React.useState({
    display: true,
    text: "Your Title",
    align: "center",
    color: "red",
    font: {
      size: 15,
    },
  });

  const [yValue, setYvalue] = React.useState({
    display: true,
    text: "Your Title",
    align: "center",
    color: "red",
    font: {
      size: 15,
    },
  });

  const handleChangeXvalue = (e) => {
    const fieldName = e.target.name;
    setXvalue((preValue) => ({
      ...preValue,
      [e.target.name]:
        fieldName === "display"
          ? e.target.checked
          : fieldName === "font"
          ? { size: Number(e.target.value) }
          : e.target.value,
    }));
  };

  const handleChangeYvalue = (e) => {
    const fieldName = e.target.name;
    setYvalue((preValue) => ({
      ...preValue,
      [e.target.name]:
        fieldName === "display"
          ? e.target.checked
          : fieldName === "font"
          ? { size: Number(e.target.value) }
          : e.target.value,
    }));
  };

  useDebounce(
    () => {
      props.setChartX_AxisCustomize(xValue);
    },
    2000,
    [xValue]
  );

  useDebounce(
    () => {
      props.setChartY_AxisCustomize(yValue);
    },
    2000,
    [yValue]
  );

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (xValue) => {
    onClose(xValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="simple-dialog-title">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography  style={{ fontWeight: 600, fontSize: 18 }} > Customize Chart Axis Title</Typography>
          <CloseIcon style={{cursor:'pointer'}}onClick={handleClose} />
        </div>
      </DialogTitle>

      <DialogContent
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          style={{ marginRight: "10px", width: "10%" }}
        >
          X - Axis
        </Typography>

        <form className={classes.root} noValidate autoComplete="off">
          <FormControlLabel
            control={
              <Switch
                checked={xValue.display}
                onChange={handleChangeXvalue}
                name="display"
                color="primary"
              />
            }
            label="Display"
          />
        </form>

        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="textfield"
            id="outlined-basic"
            label="Set Axis Title"
            variant="outlined"
            name="text"
            value={xValue.text}
            onChange={handleChangeXvalue}
          />
        </form>

        <form
          className={classes.root}
          style={{ width: "20%" }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Set Title Position"
            variant="outlined"
            name="align"
            select
            fullWidth
            value={xValue.align}
            onChange={handleChangeXvalue}
          >
            {["start", "end", "center"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </form>

        <form
          className={classes.root}
          style={{ width: "20%" }}
          noValidate
          autoComplete="off"
        >
          <Typography>Set Title Color</Typography>

          {/* <MenuItem> */}
          <Compact
            color={hex}
            onChange={(color) => {
              setHex(color.hex);
              setXvalue((preValue) => ({
                ...preValue,
                color: color.hex,
              }));
            }}
          />
          {/* </MenuItem> */}
        </form>

        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Title Font Size"
            variant="outlined"
            name="font"
            value={xValue.font.size}
            onChange={handleChangeXvalue}
          />
        </form>
      </DialogContent>

      <DialogContent
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          style={{ marginRight: "10px", width: "10%" }}
        >
          Y - Axis
        </Typography>

        <form className={classes.root} noValidate autoComplete="off">
          <FormControlLabel
            control={
              <Switch
                checked={yValue.display}
                onChange={handleChangeYvalue}
                name="display"
                color="primary"
              />
            }
            label="Display"
          />
        </form>

        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="textfield"
            id="outlined-basic"
            label="Set Axis Title"
            variant="outlined"
            name="text"
            value={yValue.text}
            onChange={handleChangeYvalue}
          />
        </form>

        <form
          className={classes.root}
          style={{ width: "20%" }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Set Title Position"
            variant="outlined"
            name="align"
            select
            fullWidth
            value={yValue.align}
            onChange={handleChangeYvalue}
          >
            {["start", "end", "center"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </form>

        <form
          className={classes.root}
          style={{ width: "20%" }}
          noValidate
          autoComplete="off"
        >
          <Typography>Set Title Color</Typography>

          {/* <MenuItem> */}
          <Compact
            color={hex}
            onChange={(color) => {
              setHex(color.hex);
              setYvalue((preValue) => ({
                ...preValue,
                color: color.hex,
              }));
            }}
          />
          {/* </MenuItem> */}
        </form>

        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Title Font Size"
            variant="outlined"
            name="font"
            value={yValue.font.size}
            onChange={handleChangeYvalue}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function AxisTitleCustomization({
  setChartX_AxisCustomize,
  setChartY_AxisCustomize,
}) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (xValue) => {
    setOpen(false);
    setSelectedValue(xValue);
  };

  return (
    <div>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        input={<CustomDataInput />}
        value={"Edit Chart Axis Title"}
        defaultValue={"Edit Chart Axis Title"}
        style={{ width: "100%" }}
      >
        <MenuItem value="Edit Chart Axis Title" onClick={handleClickOpen}>
          Edit Chart Axis Title
        </MenuItem>
      </Select>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Customize Chart Axis Title
      </Button> */}
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        setChartX_AxisCustomize={setChartX_AxisCustomize}
        setChartY_AxisCustomize={setChartY_AxisCustomize}
      />
    </div>
  );
}
