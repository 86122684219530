import {
  SET_ARTICLES,
  SET_COMPLETED_QUESTIONS,
  SET_CONTROVERSIES_DETAILS,
  SET_CONTROVERSIES_SCORE,
  SET_ESG_INSIGNTS,
  SET_ESG_SCORE,
  SET_FREQUENCIES,
  SET_SELF_ASSESSMENT_SCORE,
  SET_SUPPLIER_UPLOADED_FILES
} from "../../constants/supplier/esgDiagnosisConstant";

const initialState = {
  esgScore: [],
  esgInsights: {},
  controversiesDetails: {},
  articles: [],
  selfAssessmentScore: [],
  controversiesScore: {},
  frequencies: [],
  completedQuestions: [],
  supplierDocuments:[]
};

export default function esgDiagnosisReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ESG_SCORE:
      return (state = { ...state, esgScore: action.payload });

    case SET_ESG_INSIGNTS:
      return (state = { ...state, esgInsights: action.payload });

    case SET_CONTROVERSIES_DETAILS:
      return { ...state, controversiesDetails: action.payload };

    case SET_ARTICLES:
      return { ...state, articles: action.payload };

    case SET_SELF_ASSESSMENT_SCORE:
      return { ...state, selfAssessmentScore: action.payload };

    case SET_CONTROVERSIES_SCORE:
      return { ...state, controversiesScore: action.payload };

    case SET_FREQUENCIES:
      return { ...state, frequencies: action.payload };

    case SET_COMPLETED_QUESTIONS:
      return { ...state, completedQuestions: action.payload };

      case SET_SUPPLIER_UPLOADED_FILES:
      return { ...state, supplierDocuments: action.payload };

    default:
      return state;
  }
}
