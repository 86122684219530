import {
  TableCell,
  Paper,
  Box,
  Typography,
  Container,
  TableRow,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Textfield from "components/UI/textfield/textfield";
import DynamicTable from "components/UI/dynamicTable/DynamicTable";
import AnswersTable from "./RegularTableComps/AnswersTable";

const RegularTable = ({ submittedAnswer, headers }) => {
  const columnNames = headers?.map((header) => ({
    name: header.header,
    type: header.type,
  }));

  // console.log(submittedAnswer);

  if (submittedAnswer && submittedAnswer.length > 0) {
    return (
      <AnswersTable
        submittedAnswer={submittedAnswer}
        headers={headers}
        columnNames={columnNames}
      />
    );
  }

  return (
    <Container component={Paper} style={{ overflowX: "auto" }}>
      <DynamicTable headings={columnNames}>
        {headers?.map((header, index) => (
          <TableRow key={index}>
            <TableCell key={index} style={{ minWidth: 150 }}>
              {header.type === "Document" ? (
                <TableCell
                  display="flex"
                  component="span"
                  style={{ cursor: "pointer", alignItems: "center" }}
                >
                  <AttachFileIcon color="disabled" />
                  <Box
                    mx={2}
                    style={{ borderBottom: "2px solid gray" }}
                    width="100%"
                  >
                    <Typography variant="caption" color="textSecondary">
                      Document
                    </Typography>
                  </Box>
                </TableCell>
              ) : (
                <Textfield
                  color="primary"
                  variant="outlined"
                  size="small"
                  label={header.type}
                  type={
                    header.type === "Number"
                      ? "number"
                      : header.type === "Email"
                      ? "email"
                      : "text"
                  }
                  style={{ width: "100%", pointerEvents: "none" }}
                  InputProps={{ readOnly: true }}
                />
              )}
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>
    </Container>
  );
};

export default RegularTable;
