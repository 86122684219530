import React from "react";

import { CardContent } from "@material-ui/core";

import TabPanel from "../../../UI/tabPannel/tabPannel";

import LeftCard from "./LeftCard";

import { useDispatch, useSelector } from "react-redux";

import { setBrmSubTabNo } from "../../../../actions/brm/BrmActions";

import { brmLeftStyles } from "./brmLeftStyle";

import {
  basicDataFirstTab,
  basicDataSecondTab,
  esgProfileTab,
  esgRisk,
} from "./leftTabData";

function BrmLeftEvaluator(props) {
  const { brmMainTabNo, brmSubTabNo } = useSelector(
    (state) => state.brmReducer
  );
  const classes = brmLeftStyles();
  const dispatch = useDispatch();
  const handleTabChange = (index) => {
    dispatch(setBrmSubTabNo(index));
  };

  return (
    <CardContent className={classes.cardContent}>
      <TabPanel value={brmMainTabNo} index={0}>
        <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
          <LeftCard
            toMap={basicDataFirstTab}
            handleTabChange={handleTabChange}
            subTabNo={brmSubTabNo}
          />
        </div>
      </TabPanel>

      <TabPanel value={brmMainTabNo} index={1}>
        <div className="scroll" style={{ marginTop: 0, height: "62.5vh" }}>
          <LeftCard
            toMap={basicDataSecondTab}
            handleTabChange={handleTabChange}
            subTabNo={brmSubTabNo}
          />
        </div>
      </TabPanel>
      <TabPanel value={brmMainTabNo} index={2}>
        <div className="scroll" style={{ marginTop: 0, height: "62.5vh" }}>
          <LeftCard
            toMap={esgProfileTab}
            handleTabChange={handleTabChange}
            subTabNo={brmSubTabNo}
          />
        </div>
      </TabPanel>
      <TabPanel value={brmMainTabNo} index={3}>
        <div className="scroll" style={{ marginTop: 0, height: "62.5vh" }}>
          <LeftCard
            toMap={esgRisk}
            handleTabChange={handleTabChange}
            subTabNo={brmSubTabNo}
          />
        </div>
      </TabPanel>
    </CardContent>
  );
}

export default BrmLeftEvaluator;
