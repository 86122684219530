import React, { useEffect, useState } from 'react';

function ScrollToTop() {
  const [toTop, setToTop] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 500) {
        setToTop(true);
      } else {
        setToTop(false);
      }
    });
  }, []);

const scrollUp=()=>{
    window.scrollTo({top:0,behavior:"smooth"})
}

  return <div>
      
 {toTop && <button style={{position:"fixed",right:"50px",bottom:"100px",height:"25px",width:"25px",fontSize:"25px",fontSize:"45px" ,border:"none",backgroundColor:"white"}} onClick={scrollUp}>▲</button>} 
  </div>;
}

export default ScrollToTop;
