import { Paper, Typography } from "@material-ui/core";
import { keywordDetails } from "./mitigationData";

function Mitigation({
  chartData: {
    vizState: {
      query: { measures } = {}, // Default to an empty object if query is undefined
    } = {}, // Default to an empty object if vizState is undefined
  } = {}, // Default to an empty object if chartData is undefined
}) {
  function findKeywordInMeasures(measures, keywords) {
    // Check if any keyword is included in the measures array
    return (
      keywords.find((keyword) =>
        measures.some((measure) =>
          measure.toLowerCase().includes(keyword.toLowerCase())
        )
      ) || null
    );
  }

  const returnMitigationData = (data, keywords) => {
    // Find the first keyword that matches a key in the data object
    const matchedKeyword = findKeywordInMeasures(data, keywords);
    console.log(measures, matchedKeyword);

    // If a match is found, return the corresponding array; otherwise, return an empty array
    return matchedKeyword ? keywordDetails[matchedKeyword] : [];
  };

  const keywords = [
    "waste",
    "water",
    "energy",
    "emissions",
    "amount",
    "product_recycled",
    "product_reused",
    "product_safely",
    "labourRelation1",
    "labourRelation2",
    "workForceHealthSafety5",
    "workForceHealthSafety6",
    "workForceHealthSafety7",
    "employeeDiversity3",
    "employeeDiversity4",
    "employeeDiversity2",
    "trainingAwareness",
    "labourRelation3",
    "employeeDiversity7",
    "employeeDiversity6",
    "Wages",
    "materials",
    "securityHuman6",
    "securityHuman5",
    "securityHuman4",
    "securityHuman3",
    "securityHuman2",
    "securityHuman1",
    "complain1",
    "complain2",
    "complain3",
    "complain4",
    "complain5",
    "complain6",
    "complain7",
    "employeeDiversity5",
  ];


  return (
    returnMitigationData(measures, keywords)?.length > 0 && (
      <div>
        <div style={{ padding: "18px 10px" }}>
          <Typography style={{ fontSize: "16px", fontWeight: 600 }}>
            Suggested Mitigation
          </Typography>
        </div>
        <div className="scroll" style={{ height: "60vh" }}>
          {returnMitigationData(measures, keywords).map((suggestion) => (
            <div
              style={{
                margin: "10px",
                borderRadius: "8px",
                boxShadow: " -1px 2px 25px -11px rgba(189,208,240,0.98)",
              }}
            >
              <Typography
                style={{
                  color: "#15314E",
                  fontSize: "14px",
                  padding: "12px 10px",
                  fontWeight: 600,
                }}
              >
                {suggestion?.title}
              </Typography>
              <Typography
                style={{
                  color: "#676767",
                  fontSize: "14px",
                  padding: "5px 10px 10px 10px",
                  fontWeight: 400,
                }}
              >
                {suggestion?.description}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    )
  );
}

export default Mitigation;
