import React, { memo, useState, useRef, useEffect } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import Tooltip from "@material-ui/core/Tooltip";
import Popper from "@material-ui/core/Popper";
import colorCalculator from "../mapUtils/colorCalculator";

// country data
import geoUrl from "./countries.json";
import { styled } from "@material-ui/core";

const MapChart = ({
  setTooltipContent,
  setTooltipPosition,
  countries,
  tooltipContent,
  tooltipPosition,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dummyElement = useRef(null);

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    if (dummyElement.current) {
      dummyElement.current.style.left = `${position.x}px`;
      dummyElement.current.style.top = `${position.y}px`;
    }
  }, [position]);

  const res = countries?.reduce((acc, cur) => acc + cur.doc_count, 0);

  return (
    <div id="map">
      <div
        ref={dummyElement}
        style={{ position: "absolute", top: 0, left: 0, height: 0, width: 0 }}
      ></div>
      <ComposableMap
        projection="geoMercator"
        data-tip=""
        onMouseDown={(e) => e.preventDefault()}
        projectionConfig={{
          scale: 100,
          center: [0, 40],
        }}
        height={450}
        style={{ width: "100%", height: "auto" }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const countryData = countries?.find(
                (data) => data.key === geo.properties.name
              );

              const interest = countryData
                ? `${Math.round((countryData?.doc_count / res) * 100)}%`
                : "0%";
              const fill = colorCalculator(interest);

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={fill}
                  onMouseEnter={(event) => {
                    setTooltipContent(`${geo.properties.name} - ${interest}`);
                    setPosition({ x: event.clientX, y: event.clientY });
                    handleTooltipOpen();
                  }}
                  onMouseMove={(event) => {
                    setPosition({ x: event.clientX, y: event.clientY });
                  }}
                  onMouseLeave={handleTooltipClose}
                  style={{
                    hover: {
                      stroke: "#3374B9",
                      outline: "none",
                    },
                    pressed: {
                      stroke: "#3374B9",
                      outline: "none",
                    },
                    default: {
                      outline: "none",
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>

      <Popper
        open={tooltipOpen}
        anchorEl={dummyElement.current}
        placement="top"
        disablePortal={true}
        modifiers={{
          offset: {
            enabled: true,
            offset: "0, 10",
          },
        }}
      >
        <StyledTooltip
          title={tooltipContent}
          open={tooltipOpen}
          placement="top"
          arrow
          onClose={handleTooltipClose}
        >
          <div />
        </StyledTooltip>
      </Popper>
    </div>
  );
};

export default memo(MapChart);

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
))(({ theme }) => ({
  backgroundColor: "#00000095",
  color: "#ffffff",
  fontSize: "0.8rem",
}));
