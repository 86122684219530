import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";

// comps
import CustomButton from "components/UI/button/button";

const YearPickerDialog = ({
  isDatePickerDialogOpen,
  setIsDatePickerDialogOpen,
  fileData,
  setFileData,
}) => {
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [yearRange, setyearRange] = useState([]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  // ---------- inner functions ----------
  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };

  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  // ---------------------------------------

  useEffect(() => {
    // console.log("new mount", fileData.year);
    fileData.year && setStartYear(fileData.year?.split("-")[0]);
    fileData.year && setEndYear(fileData.year?.split("-")[1]);
  }, [fileData.year]);

  useEffect(() => {
    const data = [];
    if (!fileData.year) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (fileData.year) {
      for (let i = 0; i < 9; i++) {
        data.push(fileData.year.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [isDatePickerDialogOpen]);

  return (
    <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
      <DialogContent style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{
              fontSize: "16px",
              cursor: "pointer",
              fontWeight: 600,
              marginLeft: "15px",
              marginBottom: "15px",
            }}
          >{`${startYear || ""}-${endYear || ""}`}</Typography>
          <Typography
            onClick={handleYearBack}
            style={{
              fontSize: "22px",
              cursor: "pointer",
              fontWeight: 600,
              marginLeft: "15px",
              marginBottom: "15px",
            }}
          >{`<`}</Typography>
          <Typography
            onClick={handleYearForward}
            style={{
              fontSize: "22px",
              cursor: "pointer",
              fontWeight: 600,
              marginLeft: "15px",
              marginBottom: "15px",
            }}
          >{`>`}</Typography>
          <Typography
            onClick={() => {
              setStartYear("");
              setEndYear("");
            }}
            style={{
              fontSize: "16px",
              cursor: "pointer",
              fontWeight: 600,
              marginLeft: "15px",
              marginBottom: "15px",
              float: "right",
            }}
          >{`Clear`}</Typography>
        </div>
        <div>
          <Grid
            container
            item
            xs={12}
            md={12}
            gap={1}
            spacing={2}
            style={{
              padding: "2% 1% 4% 1%",
              // // marginLeft: '2%',
            }}
          >
            {yearRange.map((year, idx) => {
              return (
                <Grid
                  key={idx}
                  item
                  xs={4}
                  md={4}
                  align="center"
                  style={{
                    // margin:'6px 8px',
                    // border: "1px solid blue",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    onClick={() => handleYearClick(year)}
                    style={{
                      fontWeight: 500,
                      padding: "8px 5px",
                      border: range(year) ? "1px solid #3374B9" : "none",
                      borderRadius: "5px",
                      backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                    }}
                  >
                    {year}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <CustomButton
          // variant="outlined"
          color="primary"
          style={{ textTransform: "none" }}
          onClick={() => {
            setFileData((prev) => ({
              ...prev,
              yearOfAssessment: "",
            }));
            fileData.year && setStartYear(fileData.year.split("-")[0]);
            fileData.year && setEndYear(fileData.year.split("-")[1]);
            setIsDatePickerDialogOpen(false);
          }}
        >
          Cancel
        </CustomButton>
        <CustomButton
          variant="contained"
          color="primary"
          style={{ padding: "6px 20px", textTransform: "none" }}
          disabled={!startYear || !endYear}
          onClick={() => {
            setFileData((prev) => ({
              ...prev,
              year: `${startYear}-${endYear}`,
            }));
            setIsDatePickerDialogOpen(false);
          }}
        >
          Set Period
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default YearPickerDialog;
