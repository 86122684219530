import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";

import { MainHeader } from "../../screen/vendorAdminScreen";
import TaskLeftPanel from "./TaskSurveyComps/TaskLeftPanel";
import TaskRightPanel from "./TaskSurveyComps/TaskRightPanel";
import DataRequest from "./DataRequestComps/DataRequest";
import { useDispatch, useSelector } from "react-redux";
import RedoSpecific from "./TaskSurveyComps/RightPanelComps/RedoSpecific";
import ApprovalRightMain from "./ApprovalComps/ApprovalRightMain";
import {
  getTaskAssignedQuestions,
  getTaskAssignedSections,
  setOriginalSelectedTaskQuestion,
  setSelectedSection,
  setSelectedTaskQuestion,
} from "../../../actions/taskActions/TaskAction";

const TaskSurveyMain = () => {
  const dispatch = useDispatch();
  const {
    selectedSection = [],
    taskType,
    selectedTaskAssignedSurvey,
    selectedTaskQuestion,
    selectedQuestionRedo,
  } = useSelector((state) => state.taskReducer);

  const { supplier_id, token } = useSelector((state) => state?.login);

  const { selectedCorporate } = useSelector((state) => state?.supplierAdmin);

  const location = useLocation();

  const isDataRequestRoute = location.pathname.includes("REQUEST");

  const [activeSurvey, setActiveSurvey] = useState(0);

  const [activeTab, setActiveTab] = useState(null);
  const [completedTab, setCompletedTab] = useState([]);
  useEffect(() => {
    selectedTaskAssignedSurvey &&
      !isDataRequestRoute &&
      dispatch(
        getTaskAssignedSections(
          token,
          supplier_id,
          selectedCorporate?._id,
          selectedTaskAssignedSurvey?.survey_id,
          selectedTaskAssignedSurvey?.frequency_id,
          selectedTaskAssignedSurvey?.version,
          selectedTaskAssignedSurvey?.role
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaskAssignedSurvey]);

  useEffect(() => {
    activeTab &&
      dispatch(
        getTaskAssignedQuestions(
          token,
          supplier_id,
          selectedCorporate?._id,
          activeTab?._id,
          selectedTaskAssignedSurvey?.frequency_id,
          selectedTaskAssignedSurvey?.version,
          selectedTaskAssignedSurvey?.role
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  // console.log(selectedTaskAssignedSurvey, selectedSection, activeTab, selectedTaskQuestion);

  useEffect(() => {
    !activeTab && setActiveTab(selectedSection[activeSurvey]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection]);

  useEffect(() => {
    return () => {
      setActiveTab(null);
      dispatch(setSelectedSection([]));
      dispatch(setSelectedTaskQuestion(null));
      dispatch(setOriginalSelectedTaskQuestion(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const completedTabIds = [];

    selectedSection &&
      selectedSection.length > 0 &&
      selectedSection.forEach((section) => {
        // Check if isSurveyComplete is true
        if (
          section?._id === selectedTaskQuestion?.section_id &&
          selectedTaskQuestion?.total_questions ===
            selectedTaskQuestion?.answers_submitted
        ) {
          completedTabIds.push(section._id);
        }
      });

    setCompletedTab((pre) => [...pre, ...completedTabIds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection, selectedTaskQuestion?.section_id]);

  // Effect to update activeSurvey when activeTab changes
  useEffect(() => {
    const index =
      selectedSection &&
      selectedSection.length > 0 &&
      selectedSection?.findIndex((section) => section?._id === activeTab?._id);
    if (index !== -1) {
      setActiveSurvey(typeof index === "number" ? index : 0);
    }
  }, [activeTab, selectedSection]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{ gap: 30, padding: "12px 0px" }}
    >
      {/* Header with Breadcrumbs */}
      <MainHeader mainCrumb={"My Task"} />

      {isDataRequestRoute ? (
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DataRequest />
        </Box>
      ) : (
        <Box
          display={"flex"}
          width={"98%"}
          height={"75vh"}
          sx={{ gap: 20, padding: "0px 20px" }}
        >
          {/* Left Panel */}
          <TaskLeftPanel
            selectedSection={selectedSection}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            completedTab={completedTab}
          />

          {/* Right Panel */}
          {selectedTaskAssignedSurvey?.role === 1 ? (
            <Box
              style={{
                width: "100%",
                height: "100%",
                boxShadow: "0px 0px 8px 0px #3374B940",
              }}
            >
              <ApprovalRightMain
                activeTab={activeTab}
                questions={selectedTaskQuestion || []}
              />
              {/* <RedoSpecific /> */}
            </Box>
          ) : (
            selectedTaskQuestion?.questions.length > 0 && (
              <TaskRightPanel
                selectedSection={selectedSection}
                activeSurvey={activeSurvey}
                setActiveSurvey={setActiveSurvey}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                completedTab={completedTab}
                setCompletedTab={setCompletedTab}
              />
            )
          )}
        </Box>
      )}
    </Box>
  );
};
export default TaskSurveyMain;
