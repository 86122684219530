import React from "react";

import { Card } from "@material-ui/core";

import { brmLeftStyles } from "./brmLeftStyle";

import BrmLeftEvaluator from "./BrmLeftEvaluator";

function BrmDataLeft(props) {
  const classes = brmLeftStyles();

  return (
    <div>
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__basicLeft"
      >
         <BrmLeftEvaluator {...props} />
      </Card>
    </div>
  );
}

export default BrmDataLeft;
