import {
  SET_EMAIL,
  SET_PASSWORD,
  SET_NAME,
  SET_LOGIN,
  SET_LOGOUT,
  BUSINESS_UNIT,
  SET_SIDE_IMAGE,
  SET_SIDE_MESSAGE,
  SET_SHOW_ONBOARDING,
  SET_SIGNUP_CARD,
  SET_TOKEN,
  SET_ON_BOARDING,
  SET_INDUSTRY_TYPES,
  SET_SIGNUP_NAME,
  SET_SIGNUP_EMAIL,
  SET_SIGNUP_PASSWORD,
  SET_SIGNUP_PHONE,
  SET_CURRENT_ORGANIZATION,
  SET_USER_PROFILE,
  SET_CURRENT_ORGANIZATION_USER_TYPE,
  SET_IS_ADD_NEW_COMP,
  SET_SELECTED_ORGANIZATION,
  SET_COMPANY_NAME,
  SET_SECTOR_DATA,
  SET_TOPICS_DATA,
  SET_FRAMEWORKS_DATA,
  SET_SIGNUP_STEPNO,
  SET_SIGNUP_PROCESS_NO,
  SET_VERSION_NO,
  SET_HELPER_DAILOGUE,
  SET_IS_TUTORIAL_COMPLETED,
  SET_TUTORIAL_COMPLETED_STEPS,
  SET_SIGNUP_LOGO,
  SET_IS_SUBSCRIPTION_ACTIVE,
} from "../../constants/login/ActionTypes";
import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../../actions/shared/sharedActions";
import { getFrameworks } from "../../actions/sustainabilityReport/sustainabilityReportAction";
import {
  setChangePasswordDialog,
  getOrganizations,
} from "../../actions/surveyListing/surveyListingAction";
import {
  emailValidation,
  passwordValidation,
} from "../regexValidation/regexValidation";
import UNIVERSAL from "../../config/config";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { setClearDashboardChart } from "../datahubActions/datahubActions";
import { getInvoice } from "../generalSettings/generalSettingsAction";

export function setLogin(payload) {
  return {
    type: SET_LOGIN,
    payload: payload,
  };
}

export function setSignupProcessNo(payload) {
  return {
    type: SET_SIGNUP_PROCESS_NO,
    payload: payload,
  };
}
export function setOrganizationCountNo(payload) {
  return {
    type: 'SET_ORGANIZATION_COUNT_NO',
    payload: payload,
  };
}

export function setToken(payload) {
  return {
    type: SET_TOKEN,
    payload: payload,
  };
}
export function setIsSubscriptionActive(payload) {
  return {
    type: SET_IS_SUBSCRIPTION_ACTIVE,
    payload: payload,
  };
}

export function setFrameworksData(payload) {
  return {
    type: SET_FRAMEWORKS_DATA,
    payload: payload,
  };
}

export function setSignupStepNo(payload) {
  return {
    type: SET_SIGNUP_STEPNO,
    payload: payload,
  };
}

export function setVersionNo(payload) {
  return {
    type: SET_VERSION_NO,
    payload,
  };
}

export function setEmail(email) {
  return {
    type: SET_EMAIL,
    payload: email,
  };
}

export function setCompanyName(payload) {
  return {
    type: SET_COMPANY_NAME,
    payload: payload,
  };
}

export function setTopicsData(payload) {
  return {
    type: SET_TOPICS_DATA,
    payload: payload,
  };
}

export function setSectorData(payload) {
  return {
    type: SET_SECTOR_DATA,
    payload,
  };
}

export function setPwd(password) {
  return {
    type: SET_PASSWORD,
    payload: password,
  };
}
export function setName(payload) {
  return {
    type: SET_NAME,
    payload: payload,
  };
}
export function setSideImage(password) {
  return {
    type: SET_SIDE_IMAGE,
    payload: password,
  };
}
export function setSideMessage(password) {
  return {
    type: SET_SIDE_MESSAGE,
    payload: password,
  };
}
export function setOnBoarding(payload) {
  return {
    type: SET_ON_BOARDING,
    payload: payload,
  };
}
export function setSignupCard(payload) {
  return {
    type: SET_SIGNUP_CARD,
    payload: payload,
  };
}

export function setSignuplogo(payload) {
  return {
    type: SET_SIGNUP_LOGO,
    payload: payload,
  };
}

export function setIndustryType(payload) {
  return {
    type: SET_INDUSTRY_TYPES,
    payload: payload,
  };
}

export function setSignupName(payload) {
  return {
    type: SET_SIGNUP_NAME,
    payload: payload,
  };
}
export function setSignupEmail(payload) {
  return {
    type: SET_SIGNUP_EMAIL,
    payload: payload,
  };
}
export function setSignupPassword(payload) {
  return {
    type: SET_SIGNUP_PASSWORD,
    payload: payload,
  };
}
export function setSignupPhone(payload) {
  return {
    type: SET_SIGNUP_PHONE,
    payload: payload,
  };
}
export function setCurrentOrganzation(payload) {
  return {
    type: SET_CURRENT_ORGANIZATION,
    payload: payload,
  };
}
export function setUserProfile(payload) {
  return {
    type: SET_USER_PROFILE,
    payload: payload,
  };
}
export function setCurrentOrganisationUserType(payload) {
  return {
    type: SET_CURRENT_ORGANIZATION_USER_TYPE,
    payload: payload,
  };
}

export function setIsNewCompany(payload) {
  return {
    type: SET_IS_ADD_NEW_COMP,
    payload: payload,
  };
}

export function setSelectedOraganization(payload) {
  return {
    type: SET_SELECTED_ORGANIZATION,
    payload: payload,
  };
}

export function setIsTutorialCompleted(payload) {
  return {
    type: SET_IS_TUTORIAL_COMPLETED,
    payload,
  };
}

export function setTutorialCompletedSteps(payload) {
  return {
    type: SET_TUTORIAL_COMPLETED_STEPS,
    payload,
  };
}

export function login_with_email(email, password) {
  const data = encrypt({
    email,
    password,
  });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/userDetails/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          localStorage.removeItem("logo");
          localStorage.removeItem("logo_styles");

          dispatch(
            setIsSubscriptionActive(
              responseJson.result.payment_status === "EXPIRED" ? false : true
            )
          );
          localStorage.setItem(
            "status_message",
            responseJson.result?.payment_message
          );
          localStorage.setItem("status", responseJson.result?.payment_status);
          localStorage.setItem(
            "next_payment_date",
            responseJson.result?.next_payment_date
          );
          // dispatch(
          //   getInvoice(
          // dispatch(
          //   getOrganizations(
          //     responseJson.result.user_token,
          //     window.location.hostname
          //   )
          // );

          dispatch(
            getOrganizations(
              responseJson.result.user_token,
              window.location.hostname
            )
          );
          dispatch(setIsTutorialCompleted(false));
          dispatch(setTutorialCompletedSteps(responseJson.result.help_flow));
          localStorage.setItem(
            "completed_steps",
            JSON.stringify(responseJson.result.help_flow)
          );
          responseJson.result?.payment_status === "EXPIRED" &&
            localStorage.setItem("location", "/general_settings");

          localStorage.setItem("user_token", responseJson.result.user_token);
          localStorage.setItem("user_type", responseJson.result.user_type);
          localStorage.setItem("userName", responseJson.result.name);
          localStorage.setItem("user_id", responseJson.result.user_id);
          if (responseJson.result.parent_organisation.length > 0) {
            localStorage.setItem(
              "parent_organisation",
              JSON.stringify(responseJson.result.parent_organisation)
            );
            localStorage.setItem(
              "current_organisation",
              responseJson.result.parent_organisation[0]
            );
          }
          if (responseJson.result.hasOwnProperty("profile_img")) {
            localStorage.setItem(
              "userProfile",
              JSON.stringify(responseJson.result.profile_img)
            );
          }
          localStorage.setItem("userEmail", email);
          localStorage.setItem(
            "signup_process_no",
            responseJson.result.signup_process
          );
          localStorage.setItem("version_no", responseJson.result.version_no);
          localStorage.setItem(
            "current_organisation_user_type",
            responseJson.result.user_type
          );
          localStorage.setItem(
            "supplier_id",
            responseJson.result.supplier_id || ""
          );
          localStorage.setItem(
            "supplier_data",
            JSON.stringify(responseJson.result.supplier_data) || ""
          );

          localStorage.setItem(
            "no_of_organizations",
            JSON.stringify(responseJson.result.no_of_organizations) || ""
          );
          dispatch(setLogin(responseJson.result));
          dispatch(
            setCurrentOrganzation(responseJson.result.parent_organisation[0])
          );
          dispatch(setSignuplogo(""));
        }
        if (responseJson.result) {
          if (responseJson.result.hasOwnProperty("onBoarding")) {
            if (!responseJson.result.onBoarding) {
              dispatch(setChangePasswordDialog(true));
            }
          }
        }
        dispatch(unset_loader());
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function logOut(token) {
  const data = encrypt({
    "user-token": token,
  });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/userDetails/logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          localStorage.clear();
          dispatch(setLogOut());
          dispatch(setClearDashboardChart());
        }
        dispatch(unset_loader());
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function setLogOut() {
  return {
    type: SET_LOGOUT,
  };
}

export function setShowOnboarding(payload) {
  return {
    type: SET_SHOW_ONBOARDING,
    payload: payload,
  };
}

export function setHelperDailogue(payload) {
  return {
    type: SET_HELPER_DAILOGUE,
    payload,
  };
}

export function signupValidation(
  name,
  email,
  password,
  phone_no,
  checkBox,
  confirmPassword
) {
  return (dispatch) => {
    if (name === "") {
      dispatch(set_snack_bar(true, "Please Enter Your Name"));
    } else if (emailValidation(email) === false) {
      dispatch(set_snack_bar(true, "Please Enter Valid Email Address"));
    } else if (passwordValidation(password) === false) {
      dispatch(
        set_snack_bar(
          true,
          "Please Enter 8 character Password(at least 1 uppercase, 1 lowercase, 1 special character and 1 number)"
        )
      );
    } else if (password !== confirmPassword) {
      dispatch(set_snack_bar(true, "Password Does Not Match"));
    } else if (false) {
      dispatch(
        set_snack_bar(
          true,
          "Please Enter a Valid Phone Number With Country Code"
        )
      );
    } else if (checkBox === false) {
      dispatch(set_snack_bar(true, "Please Accept Terms & Condition"));
    } else {
      dispatch(unset_loader());
      dispatch(setSignupCard(false));
      dispatch(setShowOnboarding(true));
      dispatch(setSignupPhone(phone_no));
      dispatch(setSignupPassword(password));
      dispatch(setSignupEmail(email));
      dispatch(setSignupName(name));
    }
  };
}

export function signup_with_email(
  name,
  email,
  password,
  phone_no,
  company_name,
  company_type,
  employee_count
) {
  const data = encrypt({ name, email, password, phone_no });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/userDetails/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader());
          localStorage.setItem("user_token", responseJson.result);
          localStorage.setItem("userName", name);
          localStorage.setItem("userEmail", email);
          localStorage.setItem("user_type", "SA");
          dispatch(setToken(responseJson.result));
          dispatch(
            addOrganisationUnit(company_name, company_type, employee_count)
          );
          dispatch(getFrameworks(localStorage.getItem("user_token")));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        dispatch(unset_loader());
      });
  };
}

export function addOrganisationUnit(
  token,
  company_name,
  country,
  employee_count
) {
  const data = encrypt({
    "user-token": token === "" ? localStorage.getItem("user_token") : token,
    company_name,
    country,
    employee_count,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/businessUnit/add_organisation_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setOrganisationUnit(responseJson.result));
          localStorage.setItem("current_organisation", responseJson.result[0]);
          localStorage.setItem("signup_process_no", 3);
          dispatch(setSignupStepNo(3));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export const updateOrganisationUnit = (
  token,
  organisation_id,
  framework_id,
  topics,
  sector
) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token === "" ? localStorage.getItem("user_token") : token,
      organisation_id,
      framework_id,
      topics,
      sector,
    });

    return fetch(UNIVERSAL.BASEURL + "/businessUnit/update_organisation_unit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getOrganizations(token, window.location.hostname));
          dispatch(setSignupProcessNo(3));
          dispatch(setIsTutorialCompleted(false));
          localStorage.setItem("completed_steps", JSON.stringify([]));
          localStorage.setItem("signup_process_no", 4);
          localStorage.getItem("current_organisation_user_type") === "A" &&
            localStorage.setItem("current_organisation_user_type", "SA");
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const forgetPassword = (email, domain_name) => {
  return (dispatch) => {
    dispatch(set_loader());

    let url = window.origin;
    let domain_name = window.location.hostname;
    const data = encrypt({
      email: email,
      domain_name,
      url,
    });
    return fetch(UNIVERSAL.BASEURL + "/userDetails/forgot_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export function setOrganisationUnit(details) {
  return {
    type: BUSINESS_UNIT,
    payload: details,
  };
}

export const resetForgetPassword = (password, reset_code) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      password,
      reset_code,
    });

    return fetch(UNIVERSAL.BASEURL + "/userDetails/reset_forgot_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export function signupWithEmail(name, email, password) {
  return (dispatch) => {
    let url = window.origin;
    let domain_name = window.location.hostname;
    dispatch(set_loader());
    const data = encrypt({
      name,
      email,
      password,
      url,
      domain_name,
    });

    return fetch(UNIVERSAL.BASEURL + "/userDetails/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setToken(responseJson.result));
          localStorage.setItem("userName", name);
          localStorage.setItem("userEmail", email);
          localStorage.setItem("user_token", responseJson.result);

          // set user type from here for signup for supplier
          localStorage.setItem("current_organisation_user_type", "SA");
          localStorage.setItem("user_type", "SA");
          dispatch(setSignupStepNo(1));
          dispatch(setSignupProcessNo(1));
          localStorage.setItem("signup_process_no", 1);
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((err) => console.log(err));
  };
}

export function verifyOtp(token, otp) {
  let url = window.origin;
  let domain_name = window.location.hostname;
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      otp,
      url,
      domain_name,
    });
    return fetch(UNIVERSAL.BASEURL + "/userDetails/verify_otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(setSignupStepNo(2));
          localStorage.setItem("signup_process_no", 2);
          dispatch(setSignupProcessNo(2));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((err) => console.log(err));
  };
}

export function resendOtp(token) {
  let url = window.origin;
  let domain_name = window.location.hostname;
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      url,
      domain_name,
    });
    return fetch(UNIVERSAL.BASEURL + "/userDetails/resend_otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((err) => console.log(err));
  };
}

export function getSectorData(token) {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token === "" ? localStorage.getItem("user_token") : token,
    });
    return fetch(UNIVERSAL.BASEURL + "/businessUnit/get_sector_industry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSectorData(responseJson.result));
        }
      })
      .catch((err) => console.log(err));
  };
}

export function getTopics(token, framework_id, sector) {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token === "" ? localStorage.getItem("user_token") : token,
      framework_id,
      sector,
    });
    console.log("sector", sector);
    return fetch(UNIVERSAL.BASEURL + "/businessUnit/get_topics", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setTopicsData(responseJson.result));
        }
      })
      .catch((err) => console.log(err));
  };
}

export function viewFrameworks(token) {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
    });
    return fetch(UNIVERSAL.BASEURL + "/framework/view_frameworks", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setFrameworksData(responseJson.result));
        }
      })
      .catch((err) => console.log(err));
  };
}

export const getVersionNo = () => {
  return (dispatch) => {
    fetch(UNIVERSAL.BASEURL + "/userDetails/get_version_no", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(setVersionNo(responseJson.version_no));
      })
      .catch((err) => console.log(err));
  };
};

export const getCompanyLogo = (domain) => {
  return (dispatch) => {
    const data = encrypt({
      domain_name: domain,
    });
    fetch(UNIVERSAL.BASEURL + "/businessUnit/get_domain_logo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          localStorage.setItem("logo", responseJson.result.logo);
          localStorage.setItem(
            "logo_styles",
            JSON.stringify(responseJson.result.logo_styles)
          );
          dispatch(setSignuplogo(responseJson.result));
        }
        // console.log(responseJson.result)
      })
      .catch((err) => console.log(err));
  };
};

export const getHelpFlow = (token) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
    });
    return fetch(UNIVERSAL.BASEURL + "/userDetails/get_help_flow", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setTutorialCompletedSteps(responseJson.result));
          localStorage.setItem(
            "completed_steps",
            JSON.stringify(responseJson.result)
          );
        }
      })
      .catch((err) => console.log(err));
  };
};

export const updateHelpFlow = (token, step) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      current_step: step,
    });
    return fetch(UNIVERSAL.BASEURL + "/userDetails/update_help_flow", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getHelpFlow(token));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const loginFromOtherSources = (token) => {
  return (dispatch) => {
    dispatch(set_loader());

    // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJhaWJoYXYucmF5QGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiQmFpYmhhdjEyM0AiLCJpYXQiOjE2NjA4ODUxMjh9.AkzIYl_090mYdbSwWKnnmUHO_56Yr1d1LrfRZ6nDNy0"
    const updatedData = encodeURIComponent(token);
    return fetch(
      UNIVERSAL.BASEURL + `/userDetails/auto_login?token=${updatedData}`,
      {
        method: "GET",
        headers: {
          // Accept: 'application/json',
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            getOrganizations(
              responseJson.result.user_token,
              window.location.hostname
            )
          );
          dispatch(setIsTutorialCompleted(false));
          dispatch(setTutorialCompletedSteps(responseJson.result.help_flow));
          localStorage.setItem(
            "completed_steps",
            JSON.stringify(responseJson.result.help_flow)
          );
          localStorage.setItem("user_token", responseJson.result.user_token);
          localStorage.setItem("user_type", responseJson.result.user_type);
          localStorage.setItem("userName", responseJson.result.name);
          if (responseJson.result.parent_organisation.length > 0) {
            localStorage.setItem(
              "parent_organisation",
              JSON.stringify(responseJson.result.parent_organisation)
            );
            localStorage.setItem(
              "current_organisation",
              responseJson.result.parent_organisation[0]
            );
          }
          if (responseJson.result.hasOwnProperty("profile_img")) {
            localStorage.setItem(
              "userProfile",
              JSON.stringify(responseJson.result.profile_img)
            );
          }
          localStorage.setItem("userEmail", responseJson.result.email);
          localStorage.setItem(
            "signup_process_no",
            responseJson.result.signup_process
          );
          localStorage.setItem("version_no", responseJson.result.version_no);
          localStorage.setItem(
            "current_organisation_user_type",
            responseJson.result.user_type
          );
          dispatch(setLogin(responseJson.result));
          dispatch(
            setCurrentOrganzation(responseJson.result.parent_organisation[0])
          );
        }
        if (responseJson.result) {
          if (responseJson.result.hasOwnProperty("onBoarding")) {
            if (!responseJson.result.onBoarding) {
              dispatch(setChangePasswordDialog(true));
            }
          }
        }
        dispatch(unset_loader());
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
