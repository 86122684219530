import { useEffect, useState } from "react";

import { Box, Typography, IconButton } from "@material-ui/core";

// icons
import CloseIcon from "@material-ui/icons/Close";

// component imports
// import DrawerLeft from "./DrawerSections/DrawerLeft";
import DrawerRight from "./DrawerSections/DrawerRight";

import NoData from "../../../../../NoData/NoData";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  getCompletedQuestions,
  setCompletedQuestions,
} from "actions/surveyListing/surveyListingAction";

const ResponseDrawer = ({ handleDrawer }) => {
  const dispatch = useDispatch();
  const { token, current_organisation } = useSelector((state) => state.login);
  const { completedQuestions } = useSelector((state) => state.surveyListing);
  const { selectedResponse, selectedSurveyData } = useSelector(
    (state) => state.supplier
  );

  // states
  const [currentFrequency, setCurrentFrequency] = useState(
    selectedResponse?.frequency[0]
  );

  useEffect(() => {
    if (currentFrequency?.frequency_id) {
      dispatch(
        getCompletedQuestions(
          token,
          selectedSurveyData?._id, // survey_id
          current_organisation, // organization_id
          currentFrequency?.frequency_id, // frequency_id
          selectedResponse?.supplier_id, // supplier_id
          currentFrequency?.version // version
        )
      );
    }
  }, [currentFrequency?.frequency_id]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "65vw",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {/* Drawer Header */}
      <Box
        sx={{
          boxShadow: "0px 2px 4px #3374B926",
          px: 2,
          py: 0.5,
          backgroundColor: "#FEFEFE",
          position: "sticky",
          top: 0,
          zIndex: "9999",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: "600", color: "#15314E" }}>
            {selectedResponse?.supplier_name}
          </Typography>
          <IconButton
            onClick={() => {
              handleDrawer();
            }}
          >
            <CloseIcon style={{ color: "#3374B9" }} />
          </IconButton>
        </div>
      </Box>

      {/* Drawer Contents */}
      <Box
        sx={{
          px: 2,
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          height: "100%",
        }}
      >
        {/* {surveyRespondedUsers?.length > 0 && (
          <>
            <DrawerLeft
              surveyRespondedUsers={surveyRespondedUsers}
              selectedResCompany={selectedResCompany}
            />
          </>
        )} */}
        <DrawerRight
          selectedResponse={selectedResponse}
          completedQuestions={completedQuestions}
          currentFrequency={currentFrequency}
          setCurrentFrequency={setCurrentFrequency}
        />
      </Box>
    </Box>
  );
};
export default ResponseDrawer;
