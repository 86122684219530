import { CookieBanner } from "@keepist/react-gdpr-cookie-banner";
import { de } from "date-fns/locale";
import "./cookie.css";
import { useState } from "react";

function Cookie() {
  const [cookieAccepted, setCookieAccepted] = useState(false);
  function deleteCookies() {
    console.log("hi");
    var allCookies = document.cookie.split(";");

    // The "expire" attribute of every cookie is
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    for (var i = 0; i < allCookies.length; i++)
      document.cookie =
        allCookies[i] + "=;expires=" + new Date(0).toUTCString();

    //displayCookies.innerHTML = document.cookie;

    //deleteCookies();
  }

  const handleAccept = () => {
    setTimeout(() => {
      setCookieAccepted(true);
      // console.log("cookies accepted");
      // console.log(cookieAccepted);
    }, 100);
  };

  return (
    <>
      {cookieAccepted ? null : (
        // <div> <button onClick={deleteCookies}>Delete Cookies</button></div>
        <div className="Cookie_box">
          <div>
            <img
              src="https://imgrows.s3.us-east-2.amazonaws.com/cookieFiles/Icon@2x.svg"
              alt="cookieIcon"
              className="image"
            />
          </div>
          <div>
            We Use third-party cookies in order to personalize your site
            experience. Please review our{" "}
            <a className="anchorTag" href="/tnc" target="_blank">
              Terms & conditions
            </a>{" "}
            &{" "}
            <a className="anchorTag" href="/tnc" target="_blank">
              privacy policies.
            </a>
          </div>
          <div>
            <CookieBanner
              message=""
              necessaryOptionText=""
              preferencesOptionText=""
              statisticsOptionText=""
              marketingOptionText=""
              //showAcceptSelectionButton = "true"
              acceptAllButtonText="Agree"
              styles={{
                dialog: {
                  backgroundColor: "transparent",
                  zIndex: "1",
                  width: "0px",
                },
                // policy: { color: "#2196F3", textDecoration: "none", display: "none" },
                // message: { color: "rgb(78, 77, 77)", marginLeft: "5px", marginTop: "20px", fontSize: "18px", display: "none" }
              }}
              onAccept={handleAccept}
              // onAcceptPreferences={() => {
              //   // load your preferences trackers here

              // }}
              // onAcceptStatistics={() => {
              //   // load your statistics trackers here

              // }}
              // onAcceptMarketing={() => {
              //   // load your marketing trackers here
              // }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Cookie;
