import { useHistory } from "react-router-dom";
import { Box, TableRow, TableCell } from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import { IoDocumentText } from "react-icons/io5";
import moment from "moment";

import DynamicTable, {
  statusStyle,
  textStyle,
  useTableStyles,
} from "../../UI/dynamicTable/DynamicTable";
import { taskData, taskHeadings } from "./taskTemp";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAssignOrganizationsTask } from "../../../actions/taskActions/TaskAction";
import NoData from "../../widgets/NoData/NoData";
import { setSelectedCorporate } from "actions/supplierAdmin/supplierAdminActions";
import { Pagination } from "@material-ui/lab";

// -------- main render ---------
const TaskMain = ({ searchKeyword }) => {
  const dispatch = useDispatch();
  const { supplier_id, token } = useSelector((state) => state?.login);
  const { taskAssigned, taskOrgsTotal } = useSelector(
    (state) => state?.taskReducer
  );

  const classes = useTableStyles();
  const history = useHistory();

  // states
  const [page, setPage] = useState(1);
  const limit = 6;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const fetchSurvey = (page) => {
    const starting_after = (page - 1) * limit;
    dispatch(
      getAssignOrganizationsTask(
        token,
        supplier_id,
        searchKeyword,
        limit,
        starting_after
      )
    );
  };

  useEffect(() => {
    fetchSurvey(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchKeyword]);

  return (
    <Box position={"relative"} height={"75vh"}>
      <Box maxHeight={"65vh"} overflow={"auto"}>
        {taskAssigned.length > 0 && (
          <DynamicTable headings={taskHeadings}>
            {taskAssigned.map((rowData, rowIndex) => (
              <TableRow
                key={rowIndex}
                className={classes.tableRowHover}
                onClick={() => {
                  dispatch(setSelectedCorporate(rowData));
                  history.push(
                    `/supplier_task/${rowData.company_name.replace(/ /g, "_")}`
                  );
                }}
              >
                <TableCell style={textStyle("main")}>
                  <Box display={"flex"} alignItems={"center"} sx={{ gap: 10 }}>
                    <IoDocumentText color="#5C90C7" size={20} />
                    {rowData.company_name}
                  </Box>
                </TableCell>
                <TableCell style={textStyle()}>
                  {moment(rowData.assigned_on).format("MMM DD, YYYY")}
                </TableCell>
                <TableCell style={textStyle()}>{rowData.assigned_by}</TableCell>
                {/* <TableCell>
                <Box style={statusStyle(rowData.status.toLowerCase())}>
                <DotIcon style={{ widht: "10px", height: "10px" }} />
                {rowData.status}
              </Box>
                status
              </TableCell> */}
              </TableRow>
            ))}
          </DynamicTable>
        )}
        {taskAssigned.length === 0 && <NoData />}
      </Box>

      {/* pagination */}
      <Box style={{ position: "absolute", right: 10, bottom: 10 }}>
        <Pagination
          color="primary"
          classes={{ root: classes.root }}
          variant="outlined"
          count={Math.ceil(taskOrgsTotal / limit)}
          page={page}
          onChange={handlePageChange}
        />
      </Box>
    </Box>
  );
};
export default TaskMain;
