import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import basicInfoRightStyles from "./basicInfoTabRightStyles";
import CustomButton from "../../UI/button/button";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import CustomTextfield from "../../UI/textfield/textfield";
import MenuItem from "@material-ui/core/MenuItem";
import { stakeHolderCategoryList } from "../../../constants/materialityConst/materialityConst";
import { emailValidation } from "../../../actions/regexValidation/regexValidation";
import AddIcon from "@material-ui/icons/Add";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import IconButton from "@material-ui/core/IconButton";
const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const StakeholderList = (props) => {
  const {
    assessment_id,
    addUser,
    set_snack_bar,
    uploadUserSheet,
    login,
    generalSettings,
    editStakeholder,
  } = props;
  const step_no = 1;
  const classes = basicInfoRightStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [weightage, setWeightage] = useState("");
  const [designation, setDesignation] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);
  const [updateUserDialogOpen, setUpdateUserDialogOpen] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const handleCloseAddNewDialog = () => {
    setAddNewUserDialogOpen(false);
  };
  const uploadHandler = (e) => {
    const file = e.target.files[0];
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alert("Please upload an excel file");
    } else {
      uploadUserSheet(login.token, login.current_organisation, file);
      setAddNewUserDialogOpen(false);
    }
  };
  const handleAddUser = () => {
    if (emailValidation(email)) {
      addUser(
        login.token,
        login.current_organisation,
        name,
        email,
        designation,
        organisation,
        category,
        type,
        weightage,
        assessment_id
      );
    } else {
      set_snack_bar(true, "Please enter valid email...");
    }

    setName("");
    setEmail("");
    setDesignation("");
    setType("");
    setOrganisation("");
    setCategory("");
  };
  const addNewUserDialog = () => {
    return (
      <Dialog open={addNewUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography3}>Add New</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            <Grid item md={12} className={classes.grid3}>
              <a
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQKbeO_-PkJKIXrH3qHW4ePLL-xxKtRkB8VwfSRlkcSbjrfjiYY6VSxWjQjQOunfGSptRXHTOfqfUxW/pub?output=xlsx"
                download
              >
                <CustomButton
                  name="downloadTemp"
                  startIcon={<GetAppRoundedIcon />}
                  variant="outlined"
                  color="primary"
                  className={classes.CustomButton1}
                >
                  download template
                </CustomButton>
              </a>
              <>
                <CustomButton
                  name="uploadTemp"
                  startIcon={<PublishRoundedIcon />}
                  className={classes.CustomButton2}
                  variant="outlined"
                  color="primary"
                  component="label"
                >
                  upload
                  <input
                    id="excel-upload"
                    type="file"
                    hidden
                    cursor="pointer"
                    onChange={(e) => uploadHandler(e)}
                  />
                </CustomButton>
              </>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userName"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userRole"
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userOrg"
                label="Organization"
                variant="outlined"
                size="small"
                fullWidth
                value={organisation}
                onChange={(e) => setOrganisation(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {stakeHolderCategoryList.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userPosition"
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {["Internal", "External"].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userWeightage"
                label="Weightage"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={weightage}
                onChange={(e) => setWeightage(e.target.value)}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((scale) => (
                  <MenuItem key={scale} value={scale}>
                    {scale}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={handleCloseAddNewDialog}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                disabled={
                  name === "" ||
                  email === "" ||
                  category === "" ||
                  type === "" ||
                  designation === "" ||
                  organisation === "" ||
                  weightage === ""
                }
                onClick={() => {
                  handleCloseAddNewDialog();
                  handleAddUser();
                }}
              >
                Add New User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };
  const handleCloseUpdateDialog = () => {
    setCurrentUserId("");
    setName("");
    setEmail("");
    setDesignation("");
    setCategory("");
    setType("");
    setOrganisation("");
    setWeightage("");
    setUpdateUserDialogOpen(false);
  };
  const handleEditStakeholder = () => {
    editStakeholder(
      login.token,
      assessment_id,
      currentUserId,
      designation,
      organisation,
      category,
      type,
      step_no,
      login.current_organisation,
      weightage
    );
    setCurrentUserId("");
    setName("");
    setEmail("");
    setDesignation("");
    setCategory("");
    setType("");
    setOrganisation("");
    setWeightage("");
    setUpdateUserDialogOpen(false);
  };
  const handleUserProfileClick = (user) => {
    setCurrentUserId(user._id);
    setName(user.name);
    setEmail(user.email);
    setDesignation(user.designation);
    setCategory(user.category);
    setType(user.type);
    setOrganisation(user.organisation);
    setWeightage(user.weightage);
  };

  const updateUserDialog = () => {
    return (
      <Dialog open={updateUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography3}>Edit User</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="stkName"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="stkrRole"
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="stkEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                disabled
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="stkOrg"
                label="Organization"
                variant="outlined"
                size="small"
                fullWidth
                value={organisation}
                onChange={(e) => setOrganisation(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="stkCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {stakeHolderCategoryList.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="stkPosition"
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {["Internal", "External"].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userWeightage"
                label="Weightage"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={weightage}
                onChange={(e) => setWeightage(e.target.value)}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((scale) => (
                  <MenuItem key={scale} value={scale}>
                    {scale}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={handleCloseUpdateDialog}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="updateStkBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={handleEditStakeholder}
                disabled={
                  email === "" ||
                  category === "" ||
                  type === "" ||
                  designation === "" ||
                  organisation === ""
                }
              >
                Update User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <>
      {addNewUserDialogOpen && addNewUserDialog()}
      {updateUserDialogOpen && updateUserDialog()}
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__basicRight"
      >
        <CardContent style={{ padding: 10 }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className={classes.cardTypography}>
              Company Stakeholders
            </Typography>
            <CustomButton
              name="addNewStk"
              variant="contained"
              color="primary"
              style={{ borderRadius: "8px", textTransform: "none" }}
              startIcon={<AddIcon />}
              onClick={() => setAddNewUserDialogOpen(true)}
            >
              Add New
            </CustomButton>
          </Box>
          <div className="scroll" style={{ marginTop: 20, height: "63vh" }}>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography className={classes.tableHeaderText}>
                        Name/Email
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.tableHeaderText}>
                        Category
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.tableHeaderText}>
                        Type
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.tableHeaderText}>
                        Edit
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generalSettings.allUsers.length > 0 ? (
                    generalSettings.allUsers.map((user, idx) => {
                      return (
                        <TableRow
                          key={idx}
                          className={classes.stakeholderTableRow}
                        >
                          <TableCell component="th" scope="row">
                            <Box>
                              <Typography
                                className={classes.tableBodyText}
                                style={{ color: "#242424" }}
                              >
                                {user.name}
                              </Typography>
                              <Typography className={classes.tableBodyText}>
                                {user.email}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.tableBodyText}>
                              {user.category}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.tableBodyText}>
                              {user.type}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.tableBodyText}>
                              <IconButton
                                name="editStkHolder"
                                onClick={() => {
                                  handleUserProfileClick(user);
                                  setUpdateUserDialogOpen(true);
                                }}
                              >
                                <CreateOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography>No user to add</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default StakeholderList;
