import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  makeStyles,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: "auto",
    maxWidth: "70em",
  },
  columnBG: {
    backgroundColor: "#ccc3",
  },
}));

function DataType2({ suggestedData }) {
  const classes = useStyles();

  if (!suggestedData || suggestedData.length === 0) {
    return <Typography>Loading Data...</Typography>;
  }

  const kpiNames = suggestedData[0].kpi_names;
  const data = suggestedData[0].data;

  return (
    <Paper>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Box
          style={{
            backgroundColor: "#EBF1F8",
            borderRadius: "5px",
            marginLeft: "10px",
          }}
        >
          <Typography
            style={{ padding: "5px 4px", fontSize: "16px", fontWeight: 600 }}
          >
            Disclosure Type-2
          </Typography>
        </Box>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.columnBG}></TableCell>
              {kpiNames.map((name, index) => (
                <TableCell
                  className={classes.columnBG}
                  align="left"
                  key={index}
                >
                  {name
                    .replace(/_/g, " ")
                    .replace(/^\w/, (c) => c.toUpperCase())}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((rowData, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell>Row{rowIndex + 1}</TableCell>
                {kpiNames.map((kpiName, columnIndex) => (
                  <TableCell key={columnIndex}>
                    {rowData[columnIndex] || ""}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default DataType2;
