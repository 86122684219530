import {
  SET_CATEGORY_ID,
  SET_FRAMEWORK_ID,
  RESET_TOPIC
} from "../../constants/topics/topicConstants";
import UNIVERSAL from '../../config/config'
import { set_loader, unset_loader } from '../../actions/loader/loader_action'

export function setCategoryId(payload) {
  return {
    type: SET_CATEGORY_ID,
    payload: payload
  }
}

export function setFrameworkId(payload) {
  return {
    type: SET_FRAMEWORK_ID,
    payload: payload
  }
}
export function resetTopic() {
  return {
    type: RESET_TOPIC
  }
}

export function getTopics(token) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + '/get_topics', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        'user-token': token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.log(error)
      })
  }
}