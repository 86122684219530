export const SET_BRM_MAIN_TAB_NO = "SET_BRM_MAIN_TAB_NO";
export const SET_BRM_SUB_TAB_NO = "SET_BRM_SUB_TAB_NO";
export const SET_SECTOR_INDUSTRIES = "SET_SECTOR_INDUSTRIES";
export const SET_SELECTED_SECTOR_INDUSTRIES = "SET_SELECTED_SECTOR_INDUSTRIES";
export const SET_ORGANIZATION_DETAILS = "SET_ORGANIZATION_DETAILS";
export const SET_ORGANIZATIONS_LIST = "SET_ORGANIZATIONS_LIST";
export const SET_ADD_VENDOR = "SET_ADD_VENDOR";
export const SET_MEMBERS = "SET_MEMBERS";

export const SET_DECLARATION_TAB_NO = "SET_DECLARATION_TAB_NO ";
export const SET_CONTROVERSY_TAB_NO = "SET_CONTROVERSY_TAB_NO ";
export const SET_PROFILE_TAB_NO = "SET_PROFILE_TAB_NO ";
export const SET_COUNTRIES = "SET_COUNTRIES ";

export const SET_COMPANY_DOCUMENTS = "SET_COMPANY_DOCUMENTS ";
export const SET_UPDATE_ORGS_LIST = "SET_UPDATE_ORGS_LIST ";

// new

export const SET_CREATE_DRAWER_STATE = "SET_CREATE_DRAWER_STATE";
export const SET_SUPPLIER_ORGANIZATIONS = "SET_SUPPLIER_ORGANIZATIONS";
export const SET_SUPPLIER_ORGANIZATIONS_DETAILS =
  "SET_SUPPLIER_ORGANIZATIONS_DETAILS";
export const SET_SUPPLIER_ORG_TOTAL = "SET_SUPPLIER_ORG_TOTAL";
